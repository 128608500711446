<template>
  <section class="section__primary mb-16">
    <h1 class="section__title">Informations de contact</h1>
    <div class="section__body">
      <div class="">
        <div class="form__group-label">E-mail</div>
        <div class="form__item">
          <input-box
            class_name="no-box"
            label="Email principal"
            placeholder="Email principal"
            name="email"
            disabled
            required
            v-bind:maxlength="100"
            v-bind:value="account_form.email"
            v-on:input="set_attribute($event, 'email')" />
        </div>
      </div>

      <div class="">
        <div class="form__group-label">Téléphone</div>
        <div class="form__item">
          <input-box
            class_name="no-box"
            label="Téléphone principal"
            placeholder="Téléphone principal"
            name="telephone"
            required
            v-bind:maxlength="20"
            v-bind:class="{ 'input-error': v$.accountForm.telephone.$error }"
            v-bind:value="account_form.telephone"
            v-on:input="set_attribute($event, 'telephone')"
            format="numeric" />
        </div>
        <div class="form__item">
          <input-box
            class_name="no-box"
            label="Téléphone secondaire"
            placeholder="Téléphone secondaire"
            name="telephoneExtra"
            not_required_label
            v-bind:maxlength="20"
            v-bind:value="account_form.telephoneExtra"
            v-on:input="set_attribute($event, 'telephoneExtra')"
            format="numeric" />
        </div>
      </div>

      <div class="">
        <div class="form__group-label">Adresse</div>
        <template
          v-for="inputItem in inputsMap.adressePostaleInputs"
          v-bind:key="inputItem.name">
          <div class="form__item">
            <input-box
              v-bind="inputItem"
              class_name="no-box"
              v-bind:class="{
                'input-error':
                  v$.accountForm[inputItem.name] &&
                  v$.accountForm[inputItem.name].$error
              }"
              v-bind:label="inputItem.placeholder"
              v-bind:format="inputItem.format"
              v-bind:value="account_form[inputItem.name]"
              v-on:input="set_attribute($event, inputItem.name)"
              v-bind:maxlength="inputItem.size"
              v-bind:required="
                v$.accountForm[inputItem.name] &&
                Object.prototype.hasOwnProperty.call(
                  v$.accountForm[inputItem.name],
                  'required'
                )
              "
              v-bind:not_required_label="inputItem.facultatif" />
          </div>
        </template>
        <div class="form__item">
          <select-box
            v-bind:value="pays.find((item) => account_form.pays === item.id)"
            v-on:input="(value) => set_attribute(value.id, 'pays')"
            v-bind:categories="pays"
            label="Pays"
            placeholder="Pays"
            no_capitalize_label
            name="pays"
            required
            tracking="id"
            class_name="no-box" />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  import { pays } from '@/assets/data/data_form';
  import inputBox from '@/components/utils/input-box.vue';
  import selectBox from '@/components/utils/select-box';

  const inputsMap = {
    adressePostaleInputs: [
      {
        name: 'identification',
        placeholder: 'Identification',
        size: 100,
        facultatif: true
      },
      {
        name: 'destService',
        placeholder: 'Dest./Service',
        size: 100,
        facultatif: true
      },
      {
        name: 'complement',
        placeholder: "Complément d'adresse",
        size: 38,
        facultatif: true
      },
      {
        name: 'numeroVoie',
        placeholder: 'N° et voie',
        size: 38
      },
      {
        name: 'mentionSpeciale',
        placeholder: 'Mention spéciale',
        size: 38,
        facultatif: true
      },
      {
        name: 'ville',
        placeholder: 'Ville',
        size: 100
      },
      {
        name: 'codePostal',
        placeholder: 'Code postal',
        size: 5,
        format: 'numeric'
      }
    ]
  };

  export default {
    components: {
      inputBox,
      selectBox
    },
    props: ['set_attribute', 'account_form', 'v$'],
    data: () => ({}),
    computed: {
      inputsMap() {
        return inputsMap;
      },
      pays: () => pays
    },
    methods: {}
  };
</script>
<style lang="scss" scoped></style>
