<template>
  <div
    class="support-title"
    v-if="params.value">
    <div class="w-full">
      <default-renderer
        v-bind:params="params"
        centered />
    </div>

    <div
      v-if="params.data?.provisoire || params.data?.obsolete"
      class="status-label-wrapper">
      <div
        v-if="params.data?.provisoire"
        class="status-label bg-info-500"></div>

      <div
        v-if="params.data?.obsolete"
        class="status-label bg-jinius-red"></div>
    </div>
  </div>
</template>
<script>
  import defaultRenderer from '@/components/utils/ag-grid/default-renderer';

  export default { components: { defaultRenderer } };
</script>
<style lang="scss" scoped>
  .support-title {
    @apply relative flex h-full w-full items-center truncate;

    .status-label-wrapper {
      @apply absolute right-0 top-0 mr-1 mt-1 flex flex-wrap space-x-0.5;

      .status-label {
        @apply flex h-2 w-2 cursor-pointer rounded-full shadow;
      }
    }
  }
</style>
