<template>
  <div class="p-[8px]">
    <template v-if="contrats.length">
      <refonte-table
        key="contrats-1"
        table_index="contrats-1"
        v-bind:data_etablissement="
          activeEntrepriseIds.length !== 1 ? 'clientDenomination' : undefined
        "
        v-bind:data_etablissement_siret="
          activeEntrepriseIds.length !== 1 ? 'clientSiret' : undefined
        "
        v-bind:columns="columns"
        v-bind:data="contrats" />
    </template>
    <refonte-search-results-empty v-else />

    <refonte-modal-file
      v-bind:show="isContratModalOpen"
      v-bind:on_close="() => setIsContratModalOpen(false)"
      title="Contrat"
      v-bind:files="contratFiles"
      v-bind:file_on_click_download="
        (contrat: Contrat) => downloadContrat(contrat?.clientId, contrat)
      "
      v-bind:build_upload_url_for_file="
        (contrat: Contrat) => getContratUploadUrl(contrat)
      "
      v-bind:file_on_click_upload_url="onClickUpload"
      file_signed_label="Signé" />
  </div>
</template>

<script setup lang="ts">
  import RefonteTable from '@/components/refonte/tables/refonte-table.vue';
  import { Contrat } from '@/types/gestion/contrats-types';
  import { useContratStore } from '@/stores/contrats';
  import { storeToRefs } from 'pinia';
  import IconDownload from '@/assets/img/refonte/icons/display/icon-download.svg?component';
  import IconUpload from '@/assets/img/refonte/icons/display/icon-upload.svg?component';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';
  import { useEntrepriseStore } from '@/stores/entreprise';
  import { downloadContrat } from '@/mixin/client_download__functions';
  import RefonteModalFile from '@/components/refonte/modals/refonte-modal-file.vue';
  import { ref } from 'vue';
  const props = withDefaults(
    defineProps<{
      refresh_rows: Function;
    }>(),
    {}
  );
  const isContratModalOpen = ref<boolean>(false);
  const contratFiles = ref([]);
  function setIsContratModalOpen(value: boolean) {
    isContratModalOpen.value = value;
  }

  const contratStore = useContratStore();
  const getStatutIcon = (contrat: Contrat) => {
    if (contrat.valide) {
      return IconDownload;
    } else if (contrat.disponible) {
      return IconUpload;
    } else {
      return undefined;
    }
  };
  const getContratUploadUrl = (contrat: Contrat) => {
    return `client/${contrat.clientId}/contrats/${contrat.id}/file`;
  };
  const handleContrat = (contrat: Contrat) => {
    if (contrat.valide) {
      downloadContrat(contrat?.clientId, contrat);
    } else if (contrat.disponible) {
      contratFiles.value = [
        {
          id: contrat.id,
          title: 'Contrat',
          ...contrat
        }
      ];
      setIsContratModalOpen(true);
    }
  };

  const { contrats } = storeToRefs(contratStore);

  const entrepriseStore = useEntrepriseStore();

  const { activeEntrepriseIds } = storeToRefs(entrepriseStore);

  const columns = [
    {
      content_index: 'periode',
      label: 'Période',
      min_width: 140,
      width: 200
    },
    {
      content_index: 'natureLabel',
      label: 'Nature',
      width: 80
    },
    {
      content_index: 'formateurNomPrenom',
      label: 'Formateur',
      min_width: 110,
      width: 150
    },
    {
      content_index: 'typeLabel',
      label: 'Type',
      width: 70
    },
    {
      content_index: 'formation',
      label: 'Formation',
      content_sub_text: (row: Contrat) => {
        return (
          row.modeLabel &&
          `${row.modeLabel === 'Intra' ? `${row.modeLabel} - ` : ''}${row.lieu ?? ''}`
        );
      },
      min_width: 150,
      width: 200
    },
    {
      content_index: 'organisme',
      label: 'Organisme de formation',
      min_width: 120,
      width: 170
    },
    {
      content_index: 'date',
      label: 'Date session',
      width: 100
    },
    {
      content_index: 'statutLabel',
      label: 'Contrat',
      width: 100,
      content_icon: getStatutIcon,
      on_click: handleContrat,
      on_click_disabled: (row: Contrat) => row.signe,
      class: (row: Contrat) => '!flex-grow-0'
    }
  ];
  const onClickUpload = () => {
    if (props.refresh_rows()) {
      props.refresh_rows();
    }
    setIsContratModalOpen(false);
  };
</script>

<style lang="scss" scoped></style>
