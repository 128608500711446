import { API } from '@/http-common.js';
import { Catalogue } from '@/types/catalogue/catalogue-types';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useCatalogueStore = defineStore('catalogue', () => {
    const catalogues = ref<Catalogue[]>([]);

    const fetchCatalogues = async () => {
        const response = await API.get('participant/catalogues', {
            headers: { 'SKIP-REDIRECTION': true }
        });
        if (response?.data) {
            catalogues.value = response.data;
        }
    };

    return {
        catalogues,
        fetchCatalogues
    };
});
