<template>
  <refonte-formations />
  <div class="mt-24">
    <refonte-formations-details />
  </div>
  <h2 class="mt-24">Design system</h2>
  <refonte-collapse
    class="mt-[12px]"
    title="Buttons">
    <refonte-card class="!gap-[8px]">
      <h4>Primary CTA</h4>
      <div class="flex flex-wrap items-center gap-3">
        <refonte-button>Appliquer</refonte-button>
        <refonte-button disabled>Appliquer</refonte-button>
      </div>
      <h4 class="mt-5">Primary CTA - Text + icon</h4>
      <div class="flex flex-wrap items-center gap-3">
        <refonte-button v-bind:icon_right="IconChevronDown">
          Conventions
        </refonte-button>
        <refonte-button
          disabled
          v-bind:icon_right="IconChevronDown">
          Conventions
        </refonte-button>
      </div>
      <h4 class="mt-5">Primary CTA - Small</h4>
      <div class="flex flex-wrap items-center gap-3">
        <refonte-button size="sm">Appliquer</refonte-button>
        <refonte-button
          size="sm"
          disabled
          >Appliquer</refonte-button
        >
      </div>
      <h4 class="mt-5">Secondary CTA</h4>
      <div class="flex flex-wrap items-center gap-3">
        <refonte-button
          variant="secondary"
          v-bind:icon_left="IconDate"
          v-bind:icon_right="IconChevronDown">
          Toute la période
        </refonte-button>
        <refonte-button
          variant="secondary"
          v-bind:icon_left="IconDate">
          Grouper par OF
        </refonte-button>
        <refonte-button
          variant="secondary"
          v-bind:icon_right="IconDate">
          Grouper par OF
        </refonte-button>
        <refonte-button
          variant="secondary"
          v-bind:icon_left="IconDate" />
      </div>
      <h4 class="mt-5">Text CTA</h4>
      <div class="flex flex-wrap items-center gap-3">
        <refonte-button variant="text">Effacer tout</refonte-button>
        <refonte-button
          variant="text"
          disabled
          >Effacer tout</refonte-button
        >
      </div>
      <h4 class="mt-5">Filter CTA</h4>
      <div class="flex flex-wrap items-center gap-3">
        <refonte-button variant="filter">+ de filtres</refonte-button>
        <refonte-button
          variant="filter"
          disabled
          >+ de filtres</refonte-button
        >
      </div>
      <h4 class="mt-5">Select CTA</h4>
      <div class="flex flex-wrap items-center gap-3">
        <refonte-button
          variant="select"
          v-bind:icon_left="IconDate">
          Grouper par OF
        </refonte-button>
      </div>
      <h4 class="mt-5">Tag CTA</h4>
      <div class="flex flex-wrap items-center gap-3">
        <refonte-button
          variant="tag"
          v-bind:icon_left="IconSend" />
      </div>
      <h4 class="mt-5">CTA avec tag</h4>
      <div class="flex flex-wrap items-center gap-3">
        <refonte-button
          variant="secondary"
          v-bind:icon_right="IconChevronRight">
          <template v-slot:tag>
            <refonte-tag variant="presentiel"> Présentiel </refonte-tag>
          </template>
          25 Clés dynamiques pour une application des NEPs
        </refonte-button>
      </div>
    </refonte-card>
  </refonte-collapse>
  <refonte-collapse
    class="mt-[12px]"
    title="Cards">
    <refonte-card class="!gap-[8px]">
      <h4>Card</h4>
      <div class="flex flex-col items-stretch gap-[12px]">
        <refonte-card class="!bg-refonte-pale-violet-2">
          Exemple card
        </refonte-card>
      </div>
    </refonte-card>
  </refonte-collapse>
  <refonte-collapse
    class="mt-[12px]"
    title="Collapses">
    <refonte-card class="!gap-[8px]">
      <h4>Collapse</h4>
      <div class="flex flex-col items-stretch gap-[12px]">
        <refonte-collapse
          class="!bg-refonte-pale-violet-2"
          v-bind:title_icon="IconParticipants"
          title="Participants">
          <template v-slot:headerSumUp>
            <refonte-details-header-item
              v-bind:icon_left="IconValidate"
              text="7/7 inscrits" />
            <refonte-details-header-item
              v-bind:icon_left="IconValidate"
              text="Tests de positionnement" />
            <refonte-details-header-item text="5/7 Conventions individuelles" />
            <refonte-details-header-item
              v-bind:icon_left="IconValidate"
              text="Certificats" />
            <refonte-details-header-item
              v-bind:icon_left="IconValidate"
              text="Évaluation" />
          </template>
          Contenu
        </refonte-collapse>
        <refonte-collapse
          class="!bg-refonte-pale-violet-2"
          title="Devenir CAC of the Year 2024">
          <template v-slot:headerTags>
            <refonte-tag v-bind:icon="IconFlagParcours">
              <refonte-number variant="count">5</refonte-number>
            </refonte-tag>
            <refonte-tag variant="cursus"> Cursus </refonte-tag>
          </template>
          Contenu
        </refonte-collapse>
      </div>
      <h4 class="mt-5">Collapse Section</h4>
      <div class="flex flex-col items-stretch gap-[12px]">
        <refonte-collapse-section title="Liste des Participants">
          Contenu
        </refonte-collapse-section>
      </div>
    </refonte-card>
  </refonte-collapse>
  <refonte-collapse
    class="mt-[12px]"
    title="Dates">
    <refonte-card class="!gap-[8px]">
      <h4>Date</h4>
      <div class="flex flex-col items-start gap-2">
        <refonte-date v-bind:dates="[new Date('12/31/2024')]" />
        <refonte-date v-bind:dates="[new Date()]" />
        <refonte-date
          v-bind:dates="[
            new Date('01/01/2024'),
            new Date('07/31/2024'),
            new Date('12/31/2024')
          ]" />
        <refonte-date
          v-bind:dates="[
            new Date('01/01/2024'),
            new Date(),
            new Date('07/31/2024'),
            new Date('12/31/2024')
          ]" />
      </div>
      <h4 class="mt-5">Date range</h4>
      <div class="flex flex-col items-start gap-2">
        <refonte-date-range
          v-bind:start_date="new Date('07/31/2024')"
          v-bind:end_date="new Date('12/31/2024')" />
        <refonte-date-range
          v-bind:start_date="new Date('01/01/2024')"
          v-bind:end_date="new Date('12/31/2024')" />
        <refonte-date-range
          v-bind:start_date="new Date()"
          v-bind:end_date="new Date('12/31/2024')" />
        <refonte-date-range
          v-bind:start_date="new Date('01/01/2024')"
          v-bind:end_date="new Date()" />
      </div>
      <h4 class="mt-5">Time</h4>
      <div class="flex flex-col items-start gap-2">
        <refonte-time
          start_time="8h30"
          end_time="18h" />
      </div>
    </refonte-card>
  </refonte-collapse>
  <refonte-collapse
    class="mt-[12px]"
    title="Details">
    <refonte-card class="!gap-[8px]">
      <h4>Details header item</h4>
      <div class="flex flex-col items-start gap-2">
        <refonte-details-header-item
          v-bind:icon_left="IconDate"
          text="Le 20/12/2023"
          additional_text="Journée entière" />
        <refonte-details-header-item
          v-bind:icon_left="IconLunch"
          text="Repas non compris" />
        <refonte-details-header-item text="Accessibilité">
          <IconLunch />
          <IconLunch />
          <IconLunch />
          <IconLunch />
          <IconLunch />
        </refonte-details-header-item>
      </div>
    </refonte-card>
  </refonte-collapse>
  <refonte-collapse
    class="mt-[12px]"
    title="Dropdowns">
    <refonte-card class="!gap-[8px]">
      <h4>Dropdown</h4>
      <div class="flex flex-wrap items-center gap-3">
        <refonte-dropdown>
          <refonte-button v-bind:icon_right="IconChevronDown">
            Conventions
          </refonte-button>
          <template v-slot:dropdownContent>
            <refonte-dropdown-item v-bind:icon="IconNotSigned">
              Conventions
            </refonte-dropdown-item>
            <refonte-dropdown-item v-bind:icon="IconSignedV2">
              Conventions
            </refonte-dropdown-item>
          </template>
        </refonte-dropdown>
        <refonte-dropdown>
          <refonte-button
            variant="secondary"
            v-bind:icon_left="IconDate"
            v-bind:icon_right="IconChevronDown">
            Toute la période
          </refonte-button>
          <template v-slot:dropdownContent>
            <refonte-dropdown-item> Toute la période </refonte-dropdown-item>
            <refonte-dropdown-item> Année en cours </refonte-dropdown-item>
            <refonte-dropdown-item> Année précédente </refonte-dropdown-item>
          </template>
        </refonte-dropdown>
        <refonte-filters-item>
          Statuts
          <template v-slot:dropdownContent>
            <refonte-dropdown-item v-bind:number="2490">
              Toutes
            </refonte-dropdown-item>
            <refonte-dropdown-item v-bind:number="190">
              Aujourd’hui / En cours
            </refonte-dropdown-item>
            <refonte-dropdown-item v-bind:number="31">
              À venir
            </refonte-dropdown-item>
            <refonte-dropdown-item v-bind:number="2246">
              Terminées
            </refonte-dropdown-item>
          </template>
        </refonte-filters-item>
        <refonte-filters-item
          v-bind:on_search="(value) => console.log(value)"
          v-bind:footer_button_on_click="() => console.log('Appliquer')"
          v-bind:footer_button_disabled="true">
          Type de formation
          <template v-slot:dropdownContent>
            <refonte-checkbox
              label="Cursus"
              v-bind:number="2" />
            <refonte-checkbox
              label="Conférence"
              v-bind:number="1" />
            <refonte-checkbox
              label="E-learning"
              v-bind:number="2" />
            <refonte-checkbox
              label="Présentiel"
              v-bind:number="12" />
            <refonte-checkbox
              label="Parcours"
              v-bind:number="1" />
            <refonte-checkbox
              label="Webinar"
              v-bind:number="3" />
            <refonte-checkbox
              label="Classe virtuelle"
              v-bind:number="4" />
          </template>
        </refonte-filters-item>
      </div>
    </refonte-card>
  </refonte-collapse>
  <refonte-collapse
    class="mt-[12px]"
    title="Filters">
    <refonte-card class="!gap-[8px]">
      <h4>Filters item</h4>
      <div class="flex flex-wrap items-center gap-3">
        <refonte-filters-item v-bind:on_search="(value) => console.log(value)">
          Type de formation
          <template v-slot:dropdownContent>
            <refonte-search-results-empty />
          </template>
        </refonte-filters-item>
        <refonte-filters-item
          v-bind:number="1"
          v-bind:on_search="(value) => console.log(value)">
          Type de formation
          <template v-slot:dropdownContent>
            <refonte-search-results-empty />
          </template>
        </refonte-filters-item>
      </div>
      <h4 class="mt-5">Filters applied item</h4>
      <div class="flex flex-wrap items-center gap-3">
        <refonte-filters-applied-item>E-learning</refonte-filters-applied-item>
      </div>
      <h4 class="mt-5">Filters</h4>
      <div>
        <refonte-filters>
          <template v-slot:default>
            <refonte-filters-item
              v-bind:on_search="(value) => console.log(value)">
              Formation
              <template v-slot:dropdownContent>
                <refonte-search-results-empty />
              </template>
            </refonte-filters-item>
            <refonte-filters-item
              v-bind:on_search="(value) => console.log(value)">
              Date formation
              <template v-slot:dropdownContent>
                <refonte-search-results-empty />
              </template>
            </refonte-filters-item>
            <refonte-filters-item
              v-bind:number="3"
              v-bind:on_search="(value) => console.log(value)">
              Participant
              <template v-slot:dropdownContent>
                <refonte-search-results-empty />
              </template>
            </refonte-filters-item>
            <refonte-filters-item
              v-bind:on_search="(value) => console.log(value)">
              Type
              <template v-slot:dropdownContent>
                <refonte-search-results-empty />
              </template>
            </refonte-filters-item>
            <refonte-filters-item
              v-bind:on_search="(value) => console.log(value)">
              Statut
              <template v-slot:dropdownContent>
                <refonte-search-results-empty />
              </template>
            </refonte-filters-item>
            <refonte-filters-item
              v-bind:on_search="(value) => console.log(value)">
              Cursus
              <template v-slot:dropdownContent>
                <refonte-search-results-empty />
              </template>
            </refonte-filters-item>
            <refonte-filters-item
              v-bind:on_search="(value) => console.log(value)">
              Mode
              <template v-slot:dropdownContent>
                <refonte-search-results-empty />
              </template>
            </refonte-filters-item>
            <refonte-filters-item
              v-bind:number="2"
              v-bind:on_search="(value) => console.log(value)">
              Dispensé par
              <template v-slot:dropdownContent>
                <refonte-search-results-empty />
              </template>
            </refonte-filters-item>
            <refonte-filters-item
              v-bind:on_search="(value) => console.log(value)">
              Catégorie
              <template v-slot:dropdownContent>
                <refonte-search-results-empty />
              </template>
            </refonte-filters-item>
          </template>
          <template v-slot:filtersApplied>
            <refonte-filters-applied-item
              >Salma BOUZIDI</refonte-filters-applied-item
            >
            <refonte-filters-applied-item
              >E-learning</refonte-filters-applied-item
            >
            <refonte-filters-applied-item>iFAURA</refonte-filters-applied-item>
            <refonte-filters-applied-item
              >Bordeaux</refonte-filters-applied-item
            >
            <refonte-filters-applied-item
              >Salma BOUZIDI</refonte-filters-applied-item
            >
            <refonte-filters-applied-item
              >Salma BOUZIDI</refonte-filters-applied-item
            >
            <refonte-filters-applied-item>iFAURA</refonte-filters-applied-item>
          </template>
        </refonte-filters>
      </div>
      <h4 class="mt-5">Filters tabs</h4>
      <div>
        <refonte-filters-tabs>
          <template v-slot:default>
            <refonte-filters-tabs-item
              label="Toutes"
              v-bind:number="2490"
              number_right
              selected />
            <refonte-filters-tabs-item
              label="Aujourd’hui / En cours"
              v-bind:number="3" />
            <refonte-filters-tabs-item
              label="À venir"
              v-bind:number="89" />
            <refonte-filters-tabs-item
              label="Terminées"
              v-bind:number="2380" />
          </template>
          <template v-slot:rightContent>
            <refonte-dropdown dropdown_align_right>
              <refonte-button
                variant="secondary"
                v-bind:icon_left="IconDate"
                v-bind:icon_right="IconChevronDown">
                Toute la période
              </refonte-button>
              <template v-slot:dropdownContent>
                <refonte-dropdown-item>
                  Toute la période
                </refonte-dropdown-item>
                <refonte-dropdown-item> Année en cours </refonte-dropdown-item>
                <refonte-dropdown-item>
                  Année précédente
                </refonte-dropdown-item>
              </template>
            </refonte-dropdown>
          </template>
        </refonte-filters-tabs>
      </div>
    </refonte-card>
  </refonte-collapse>
  <refonte-collapse
    class="mt-[12px]"
    title="Icons">
    <refonte-icons />
  </refonte-collapse>
  <refonte-collapse
    class="mt-[12px]"
    title="Inputs">
    <refonte-card class="!gap-[8px]">
      <h4>Label</h4>
      <div class="flex flex-wrap items-center gap-3">
        <refonte-label label="Période" />
      </div>
      <h4 class="mt-5">Checkbox</h4>
      <div class="flex flex-wrap items-center gap-3">
        <refonte-checkbox label="Tout sélectionner" />
      </div>
      <h4 class="mt-5">Checkbox rights</h4>
      <div class="flex flex-col items-start gap-3">
        <refonte-checkbox-rights
          label="Commandes"
          v-bind:rights="[
            'Accès aux commandes et téléchargement des bons de commande',
            'Autorisation de passer des commandes de formations au nom de la société '
          ]" />
      </div>
      <h4 class="mt-5">Switch</h4>
      <div class="flex flex-wrap items-center gap-3">
        <refonte-switch label="Vue Cursus/Parcours" />
      </div>
      <h4 class="mt-5">Radio</h4>
      <div class="flex flex-wrap items-center gap-3">
        <refonte-radio
          name="data"
          value="data1"
          label="Données simplifiées"
          v-bind:checked="radioChecked"
          v-on:input="changeRadioChecked" />
        <refonte-radio
          name="data"
          value="data2"
          label="Données simplifiées"
          v-bind:checked="radioChecked"
          v-on:input="changeRadioChecked" />
        <refonte-radio
          v-bind:disabled="true"
          name="data"
          value="data3"
          label="Données simplifiées"
          v-bind:checked="radioChecked"
          v-on:input="changeRadioChecked" />
      </div>
      <h4 class="mt-5">Search</h4>
      <div class="flex flex-wrap items-center gap-3">
        <refonte-search />
      </div>
      <h4 class="mt-5">Search results empty</h4>
      <div class="flex flex-wrap items-center gap-3">
        <refonte-search-results-empty />
      </div>
      <h4 class="mt-5">Datepickers</h4>
      <div class="flex flex-col items-start gap-[8px]">
        <refonte-label label="Période" />
        <refonte-datepicker
          placeholder="Début (JJ/MM/YYYY)"
          v-bind:value="undefined" />
      </div>
      <h4 class="mt-5">Input</h4>
      <div class="flex flex-col items-start gap-3">
        <refonte-input placeholder="Raison sociale" />
        <refonte-input
          placeholder="Raison sociale"
          value="DOKA Paris" />
      </div>
      <h4 class="mt-5">Select</h4>
      <div class="flex flex-col items-start gap-3">
        <refonte-select
          placeholder="Effectif"
          v-bind:options="[
            '1 à 50 salariés',
            '50 à 250 salariés',
            '250 à 1 000 salariés',
            '1 000 à 5 000 salariés',
            '5 000 à 10 000 salariés',
            '+10 000 salariés'
          ]" />
        <refonte-select
          placeholder="Effectif"
          value="+10 000 salariés"
          v-bind:options="[
            '1 à 50 salariés',
            '50 à 250 salariés',
            '250 à 1 000 salariés',
            '1 000 à 5 000 salariés',
            '5 000 à 10 000 salariés',
            '+10 000 salariés'
          ]" />
      </div>
    </refonte-card>
  </refonte-collapse>
  <refonte-collapse
    class="mt-[12px]"
    title="Items">
    <refonte-card class="!gap-[8px]">
      <h4>Item entities</h4>
      <div class="flex flex-wrap items-center gap-3">
        <refonte-item-entity
          v-bind:entity="{
            status: 'active',
            name: 'DOKA GRENOBLE & ASSOCIÉS and compagnie',
            siret: 'SIRET 850 092 287 00012',
            address: '69000 Briançon-Les-Chevreuses en Bry'
          }"
          class="!flex-grow-0" />
        <refonte-item-entity
          v-bind:entity="{
            status: 'deactivated',
            name: 'DOKA GRENOBLE & ASSOCIÉS and compagnie',
            siret: 'SIRET 850 092 287 00012',
            address: '69000 Briançon-Les-Chevreuses en Bry'
          }"
          class="!flex-grow-0" />
      </div>
      <h4 class="mt-5">Item all entities</h4>
      <div class="flex flex-wrap items-center gap-3">
        <refonte-item-all-entities
          v-bind:active_entities="67"
          v-bind:total_entities="70"
          class="!flex-grow-0" />
      </div>
      <h4 class="mt-5">Item participant</h4>
      <div class="flex flex-wrap items-center gap-3">
        <refonte-item-participant
          v-bind:participant="{
            status: 'active',
            name: 'Salma BOUZIDI',
            email: 'salma.bouzidi@doka-nantes.com'
          }"
          class="!flex-grow-0" />
        <refonte-item-participant
          v-bind:participant="{
            status: 'deactivated',
            name: 'Salma BOUZIDI',
            email: 'salma.bouzidi@doka-nantes.com'
          }"
          class="!flex-grow-0" />
      </div>
      <h4 class="mt-5">Item order</h4>
      <div class="flex flex-wrap items-center gap-3">
        <refonte-item-order
          v-bind:order="{
            status: 'active',
            name: 'Salma BOUZIDI',
            entity_name: 'DOKA GRENOBLE & ASSOCIÉS and compagnie',
            entity_siret: 'SIRET 850 092 287 00012'
          }"
          class="!flex-grow-0" />
        <refonte-item-order
          v-bind:order="{
            status: 'deactivated',
            name: 'Salma BOUZIDI',
            entity_name: 'DOKA GRENOBLE & ASSOCIÉS and compagnie',
            entity_siret: 'SIRET 850 092 287 00012'
          }"
          class="!flex-grow-0" />
      </div>
      <h4 class="mt-5">Item convention</h4>
      <div class="flex flex-col items-stretch gap-3">
        <refonte-item-convention
          v-bind:icon="IconConventionCollective"
          title="Convention collective"
          subtitle="Tous les participants"
          v-bind:on_click_download="
            () => console.log('Téléchargement convention')
          "
          v-bind:on_select="() => console.log('Selectionner')"
          convention_signed_date="Chargée le 31/09/2023"
          v-bind:on_click_download_signed="
            () => console.log('Téléchargement convention signée')
          " />
        <refonte-item-convention
          v-bind:icon="IconMyAccount"
          title="Conventions individuelles"
          subtitle="Par participant"
          v-bind:no_status="true" />
        <refonte-item-convention
          v-bind:selectable="true"
          participant_status="active"
          title="Salma BOUZIDI"
          subtitle="salma.bouzidi@doka-bastia.com"
          v-bind:on_click_download="
            () => console.log('Téléchargement convention')
          "
          v-bind:on_select="() => console.log('Sélectionner')" />
      </div>
      <h4 class="mt-5">Item details</h4>
      <div class="flex flex-col items-start gap-3">
        <refonte-item-details
          label="Droits activés"
          value="Formations, Commandes" />
      </div>
    </refonte-card>
  </refonte-collapse>
  <refonte-collapse
    class="mt-[12px]"
    title="Modals">
    <refonte-card class="!gap-[8px]">
      <h4>Modals</h4>
      <div class="flex flex-wrap items-center gap-3">
        <refonte-button
          v-bind:on_click="() => setIsWaitingConventionsModalOpen(true)">
          Conventions en attente
        </refonte-button>
        <refonte-button
          v-bind:on_click="() => setIsMassDownloadModalOpen(true)">
          Téléchargement en masse
        </refonte-button>
        <refonte-button
          v-bind:on_click="() => setIsNotSavedModificationsModalOpen(true)">
          Modifications non enregistrées
        </refonte-button>
        <refonte-button v-bind:on_click="() => setIsExportModalOpen(true)">
          Export de données
        </refonte-button>
        <refonte-button v-bind:on_click="() => setIsConventionsModalOpen(true)">
          Gestion des conventions
        </refonte-button>
      </div>
      <refonte-modal-waiting-conventions
        v-bind:show="isWaitingConventionsModalOpen"
        v-bind:on_close="() => setIsWaitingConventionsModalOpen(false)"
        v-bind:on_ok="() => setIsWaitingConventionsModalOpen(false)" />
      <refonte-modal-mass-download
        v-bind:show="isMassDownloadModalOpen"
        v-bind:on_close="() => setIsMassDownloadModalOpen(false)"
        v-bind:on_ok="() => setIsMassDownloadModalOpen(false)"
        v-bind:nb_files="17290"
        v-bind:nb_folders="2490" />
      <refonte-modal-not-saved-modifications
        v-bind:show="isNotSavedModificationsModalOpen"
        v-bind:on_close="() => setIsNotSavedModificationsModalOpen(false)"
        v-bind:on_save="() => setIsNotSavedModificationsModalOpen(false)"
        v-bind:on_ok="() => setIsNotSavedModificationsModalOpen(false)" />
      <refonte-modal-export
        v-bind:show="isExportModalOpen"
        v-bind:on_ok="() => setIsExportModalOpen(false)"
        v-bind:on_close="() => setIsExportModalOpen(false)"
        v-bind:nb_formations="12" />
      <refonte-modal-conventions
        v-bind:formations="[
          {
            title:
              'Évaluation financières : les indispensables pour maitriser les mouvements de capitaux',
            status: 'active',
            type: 'presentiel',
            typeLabel: 'Présentiel',
            count: '1/80',
            convention: '338/350'
          },
          {
            title: 'Actifs immobilisés en normes IFRS',
            status: 'active',
            type: 'presentiel',
            typeLabel: 'Présentiel',
            count: '1/12',
            convention: '350/350'
          },
          {
            title: 'Actualités 2023',
            status: 'active',
            type: 'presentiel',
            typeLabel: 'Présentiel',
            convention: '15/17'
          },
          {
            title: 'Actifs immobilisés en normes IFRS',
            status: 'active',
            type: 'presentiel',
            typeLabel: 'Présentiel',
            convention: '350/350'
          },
          {
            title: 'Actifs immobilisés en normes IFRS',
            status: 'active',
            type: 'presentiel',
            typeLabel: 'Présentiel',
            convention: '350/350'
          }
        ]"
        v-bind:show="isConventionsModalOpen"
        v-bind:on_ok="() => setIsConventionsModalOpen(false)"
        v-bind:on_close="() => setIsConventionsModalOpen(false)" />
    </refonte-card>
  </refonte-collapse>
  <refonte-collapse
    class="mt-[12px]"
    title="Numbers">
    <refonte-card class="!gap-[8px]">
      <h4>Number</h4>
      <div class="flex flex-wrap items-center gap-3">
        <refonte-number variant="filters">12</refonte-number>
        <refonte-number variant="segment">12</refonte-number>
        <refonte-number variant="count">12</refonte-number>
        <refonte-number variant="date">12</refonte-number>
      </div>
    </refonte-card>
  </refonte-collapse>
  <refonte-collapse
    class="mt-[12px]"
    title="Pastille">
    <refonte-card class="!gap-[8px]">
      <h4>Pastille</h4>
      <div class="flex flex-wrap items-center gap-3">
        <refonte-pastille status="active" />
        <refonte-pastille status="warning" />
        <refonte-pastille status="canceled" />
        <refonte-pastille status="urgent" />
        <refonte-pastille status="deactivated" />
        <refonte-pastille status="pending" />
        <refonte-pastille status="realised" />
        <refonte-pastille status="entreprise" />
        <refonte-pastille status="participant" />
        <refonte-pastille status="formateur" />
      </div>
    </refonte-card>
  </refonte-collapse>
  <refonte-collapse
    class="mt-[12px]"
    title="Separators">
    <refonte-card class="!gap-[8px]">
      <h4>Separator</h4>
      <div>
        <refonte-separator />
      </div>
      <h4 class="mt-5">Separator icons</h4>
      <div class="flex flex-wrap items-center gap-3">
        <IconSeparatorVertical />
        <IconSeparatorHorizontal />
      </div>
    </refonte-card>
  </refonte-collapse>
  <refonte-collapse
    class="mt-[12px]"
    title="Tables">
    <refonte-card class="!gap-[8px]">
      <h4>Table main items</h4>
      <div class="flex flex-wrap items-center gap-3">
        <refonte-table-main-item>
          <template v-slot:itemTags>
            <refonte-tag variant="e-learning"> E-Learning </refonte-tag>
          </template>
          25 Clés pour un applicaction dynamiques des NEP
        </refonte-table-main-item>
        <refonte-table-main-item status="active">
          <template v-slot:itemTags>
            <refonte-tag v-bind:icon="IconFlagParcours">
              <refonte-number variant="count">1/80</refonte-number>
            </refonte-tag>
            <refonte-tag variant="presentiel"> Présentiel </refonte-tag>
          </template>
          25 Clés pour un applicaction dynamiques des NEP
        </refonte-table-main-item>
        <refonte-table-main-item status="active">
          <template v-slot:itemTags>
            <refonte-tag variant="cursus"> Cursus </refonte-tag>
          </template>
          25 Clés pour un applicaction dynamiques des NEP
        </refonte-table-main-item>
        <refonte-table-main-item
          status="active"
          label="C08122300847 - confirmé le 11/12/2023">
          25 Clés pour un applicaction dynamiques des NEP
        </refonte-table-main-item>
        <refonte-table-main-item
          status="warning"
          label="en attente">
          Fabien DINOZZO
        </refonte-table-main-item>
        <refonte-table-main-item
          status="warning"
          label="Acompte - en attente">
          FA 392 0928828
        </refonte-table-main-item>
      </div>
      <h4 class="mt-5">Table items</h4>
      <div class="flex flex-wrap items-center gap-3">
        <refonte-table-item
          label="Label item"
          pending
          size="sm"
          v-bind:icon="IconTime">
          <template v-slot:content>Statuts of item</template>
        </refonte-table-item>
        <refonte-table-item
          label="Label item"
          size="sm">
          <template v-slot:content>Statuts of item</template>
        </refonte-table-item>
        <refonte-table-item
          label="Label item"
          v-bind:on_click="() => console.log('Statuts of item')"
          pending>
          <template v-slot:content>Statuts of item</template>
        </refonte-table-item>
        <refonte-table-item
          label="Label item"
          v-bind:on_click="() => console.log('Statuts of item')">
          <template v-slot:content>Statuts of item</template>
        </refonte-table-item>
      </div>
      <h4 class="mt-5">Table category checkbox</h4>
      <div class="flex flex-col items-stretch gap-3">
        <refonte-table-category-checkbox label="Aujourd'hui / en cours" />
        <refonte-table-category-checkbox
          with_background
          label="Aujourd'hui / en cours" />
      </div>
      <h4 class="mt-5">Table</h4>
      <div>
        <refonte-table
          table_index="formations-exemple"
          v-bind:selectable="true"
          v-bind:on_details="() => console.log('Details')"
          v-bind:columns="columns"
          v-bind:data="data1">
          <template v-slot:itemTags="{ rowIndex }">
            <refonte-tag v-bind:icon="IconFlagParcours">
              <refonte-number variant="count">{{ rowIndex }}</refonte-number>
            </refonte-tag>
          </template>
        </refonte-table>
      </div>
    </refonte-card>
  </refonte-collapse>
  <refonte-collapse
    class="mt-[12px]"
    title="Tags">
    <refonte-card class="!gap-[8px]">
      <h4>Tag</h4>
      <div class="flex flex-wrap items-center gap-3">
        <refonte-tag variant="presentiel"> Présentiel </refonte-tag>
        <refonte-tag variant="cursus"> Cursus </refonte-tag>
        <refonte-tag v-bind:icon="IconFlagParcours">
          <refonte-number variant="count">80</refonte-number>
        </refonte-tag>
        <refonte-tag v-bind:icon="IconFlagParcours">
          <refonte-number variant="count">1/80</refonte-number>
        </refonte-tag>
        <refonte-tag v-bind:icon="IconDate">
          <refonte-number variant="count">1/5</refonte-number>
        </refonte-tag>
        <refonte-tag variant="e-learning"> E-learning </refonte-tag>
        <refonte-tag variant="webinar"> Webinar </refonte-tag>
        <refonte-tag variant="classe-virtuelle"> Classe virtuelle </refonte-tag>
        <refonte-tag variant="conference"> Conférence </refonte-tag>
        <refonte-tag variant="parcours"> Parcours </refonte-tag>
        <refonte-tag variant="new"> Nouveau </refonte-tag>
      </div>
    </refonte-card>
  </refonte-collapse>
  <refonte-collapse
    class="mt-[12px]"
    title="Tooltips">
    <refonte-card class="!gap-[8px]">
      <h4>Tooltip</h4>
      <div class="flex flex-col items-start gap-2">
        <div
          v-tooltip="{
            content: '25 Clés pour un applicaction dynamiques des NEP'
          }">
          Example tooltip 1
        </div>
        <div
          v-tooltip="{
            content: `
            <div class='tooltip-content'>
              <div class='tooltip-content-text'>
                  25 Clés pour un applicaction dynamiques des NEP
              </div>
            </div>
          `
          }">
          Example tooltip 2
        </div>
        <div
          v-tooltip="{
            content: `
            <div class='tooltip-content'>
              <div class='tooltip-content-title'>
                  CURSUS : MISE À NIVEAU NORMES IFRS 2024
              </div>
              <div class='tooltip-content-text'>
                  25 Clés pour un applicaction dynamiques des NEP
              </div>
            </div>
          `
          }">
          Example tooltip 3
        </div>
      </div>
    </refonte-card>
  </refonte-collapse>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import IconSend from '@/assets/img/refonte/icons/action/icon-send.svg?component';
  import IconChevronDown from '@/assets/img/refonte/icons/display/icon-chevron-down.svg?component';
  import IconChevronRight from '@/assets/img/refonte/icons/display/icon-chevron-right.svg?component';
  import IconNotSigned from '@/assets/img/refonte/icons/display/icon-not-signed.svg?component';
  import IconSeparatorVertical from '@/assets/img/refonte/icons/display/icon-separator-vertical.svg?component';
  import IconSeparatorHorizontal from '@/assets/img/refonte/icons/display/icon-separator-horizontal.svg?component';
  import IconSignedV2 from '@/assets/img/refonte/icons/display/icon-signed-v2.svg?component';
  import IconDate from '@/assets/img/refonte/icons/information/icon-date.svg?component';
  import IconFlagParcours from '@/assets/img/refonte/icons/information/icon-flag-parcours.svg?component';
  import IconLunch from '@/assets/img/refonte/icons/information/icon-lunch.svg?component';
  import IconTime from '@/assets/img/refonte/icons/information/icon-time.svg?component';
  import IconValidate from '@/assets/img/refonte/icons/information/icon-validate.svg?component';
  import IconValidate20 from '@/assets/img/refonte/icons/information/icon-validate-20.svg?component';
  import IconWarning20 from '@/assets/img/refonte/icons/information/icon-warning-sign-20.svg?component';
  import IconParticipants from '@/assets/img/refonte/icons/menu/icon-trainees.svg?component';
  import IconConventionCollective from '@/assets/img/refonte/icons/users/icon-convention-collective.svg?component';
  import IconMyAccount from '@/assets/img/refonte/icons/users/icon-my-account.svg?component';
  import RefonteCard from '@/components/refonte/cards/refonte-card.vue';
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import RefonteTag from '@/components/refonte/tags/refonte-tag.vue';
  import RefonteNumber from '@/components/refonte/numbers/refonte-number.vue';
  import RefonteFiltersAppliedItem from '@/components/refonte/filters/refonte-filters-applied-item.vue';
  import RefonteFiltersItem from '@/components/refonte/filters/refonte-filters-item.vue';
  import RefonteFilters from '@/components/refonte/filters/refonte-filters.vue';
  import RefonteDropdown from '@/components/refonte/dropdowns/refonte-dropdown.vue';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';
  import RefonteDropdownItem from '@/components/refonte/dropdowns/refonte-dropdown-item.vue';
  import RefonteCheckbox from '@/components/refonte/inputs/refonte-checkbox.vue';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';
  import RefonteFiltersTabs from '@/components/refonte/filters/refonte-filters-tabs.vue';
  import RefonteFiltersTabsItem from '@/components/refonte/filters/refonte-filters-tabs-item.vue';
  import RefonteSwitch from '@/components/refonte/inputs/refonte-switch.vue';
  import RefonteTableItem from '@/components/refonte/tables/refonte-table-item.vue';
  import RefonteTableMainItem from '@/components/refonte/tables/refonte-table-main-item.vue';
  import RefonteTable from '@/components/refonte/tables/refonte-table.vue';
  import RefonteTableCategoryCheckbox from '@/components/refonte/tables/refonte-table-category-checkbox.vue';
  import RefonteDetailsHeaderItem from '@/components/refonte/details/refonte-details-header-item.vue';
  import RefonteCollapse from '@/components/refonte/collapse/refonte-collapse.vue';
  import RefonteItemParticipant from '@/components/refonte/items/refonte-item-participant.vue';
  import RefonteItemEntity from '@/components/refonte/items/refonte-item-entity.vue';
  import RefonteItemAllEntities from '@/components/refonte/items/refonte-item-all-entities.vue';
  import RefonteItemOrder from '@/components/refonte/items/refonte-item-order.vue';
  import RefonteDate from '@/components/refonte/dates/refonte-date.vue';
  import RefonteDateRange from '@/components/refonte/dates/refonte-date-range.vue';
  import RefonteTime from '@/components/refonte/dates/refonte-time.vue';
  import RefonteDatepicker from '@/components/refonte/inputs/refonte-datepicker.vue';
  import RefonteLabel from '@/components/refonte/inputs/refonte-label.vue';
  import RefonteRadio from '@/components/refonte/inputs/refonte-radio.vue';
  import RefonteModalWaitingConventions from '@/components/refonte/modals/refonte-modal-waiting-conventions.vue';
  import RefonteModalMassDownload from '@/components/refonte/modals/refonte-modal-mass-download.vue';
  import RefonteModalNotSavedModifications from '@/components/refonte/modals/refonte-modal-not-saved-modifications.vue';
  import RefonteModalExport from '@/components/refonte/modals/refonte-modal-export.vue';
  import RefonteModalConventions from '@/components/refonte/modals/refonte-modal-conventions.vue';
  import RefonteSearch from '@/components/refonte/inputs/refonte-search.vue';
  import RefonteItemConvention from '@/components/refonte/items/refonte-item-convention.vue';
  import RefontePastille from '@/components/refonte/pastille/refonte-pastille.vue';
  import RefonteCollapseSection from '@/components/refonte/collapse/refonte-collapse-section.vue';
  import RefonteItemDetails from '@/components/refonte/items/refonte-item-details.vue';
  import RefonteCheckboxRights from '@/components/refonte/inputs/refonte-checkbox-rights.vue';
  import RefonteIcons from '@/views/_espace/_refonte/design-system/refonte-icons.vue';
  import RefonteFormations from '@/views/_espace/_refonte/design-system/refonte-formations.vue';
  import RefonteFormationsDetails from '@/views/_espace/_refonte/design-system/refonte-formations-details.vue';
  import RefonteInput from '@/components/refonte/inputs/refonte-input.vue';
  import RefonteSelect from '@/components/refonte/inputs/refonte-select.vue';

  const columns = [
    {
      content_index: 'title',
      tags_count: 'count',
      tags_type: 'type',
      tags_type_label: 'typeLabel',
      start_time: 'startTime',
      end_time: 'endTime',
      status: (row: any) => row.status,
      width: 308
    },
    {
      content_index: 'participants',
      label_icon: IconParticipants,
      size: 'sm',
      width: 64,
      is_pending: (row: any) =>
        row['participants'] === undefined || row['participants'].startsWith('-')
    },
    {
      content_index: 'tests',
      label: 'Tests',
      size: 'sm',
      width: 64
    },
    {
      content_index: 'convention',
      content_icon: (row: any) => {
        if (row['convention'] === '90' || row['convention'] === '23/23') {
          return IconValidate20;
        } else if (row['convention'] === '128/350') {
          return IconWarning20;
        } else {
          return undefined;
        }
      },
      label: 'Convention/pif',
      width: 120,
      // eslint-disable-next-line no-console
      on_click: (rowIndex: number) => console.log(`Conventions ${rowIndex}`)
    },
    {
      content_index: 'certificats',
      content_icon: (row: any) =>
        row['certificats'] === 'En attente' ? IconTime : undefined,
      label: 'Certificats',
      width: 120,
      is_pending: (row: any) =>
        row['certificats'] === undefined || row['certificats'] === 'En attente'
    },
    {
      content_index: 'evaluation',
      label: 'Évaluation',
      width: 120
    },
    {
      content_index: 'categorie',
      label: 'catégorie',
      width: 120
    },
    {
      content_index: 'inter',
      label: 'Inter - dispensé par',
      width: 160
    }
  ];
  const data1 = [
    {
      title: 'Évaluation financières : les indispensables',
      status: 'active',
      type: 'presentiel',
      typeLabel: 'Présentiel',
      count: '1/5',
      startTime: '8h30',
      endTime: '18h',
      participants: '299',
      tests: '299',
      convention: '338/351',
      certificats: 'En attente',
      evaluation: '320/334',
      categorie: ' Formation professionnelle',
      inter: 'CNCC Formations'
    },
    {
      title: 'Actifs immobilisés en normes IFRS',
      type: 'e-learning',
      typeLabel: 'E-learning',
      participants: '90',
      tests: '88/90',
      convention: '90',
      certificats: '88/90',
      evaluation: '320/334',
      categorie: ' Formation professionnelle',
      inter: 'CNCC Formations'
    },
    {
      title: 'Actualité du secteur ESSMS',
      type: 'e-learning',
      typeLabel: 'E-learning',
      participants: '23',
      tests: '12/23',
      convention: '23/23',
      certificats: 'En attente',
      evaluation: '320/334',
      categorie: ' Formation professionnelle',
      inter: 'CNCC Formations'
    }
  ];

  const radioChecked = ref<string>('data1');
  const isWaitingConventionsModalOpen = ref<boolean>(false);
  const isMassDownloadModalOpen = ref<boolean>(false);
  const isNotSavedModificationsModalOpen = ref<boolean>(false);
  const isExportModalOpen = ref<boolean>(false);
  const isConventionsModalOpen = ref<boolean>(false);

  function changeRadioChecked(newValue: string) {
    radioChecked.value = newValue;
  }
  function setIsWaitingConventionsModalOpen(value: boolean) {
    isWaitingConventionsModalOpen.value = value;
  }
  function setIsMassDownloadModalOpen(value: boolean) {
    isMassDownloadModalOpen.value = value;
  }
  function setIsNotSavedModificationsModalOpen(value: boolean) {
    isNotSavedModificationsModalOpen.value = value;
  }
  function setIsExportModalOpen(value: boolean) {
    isExportModalOpen.value = value;
  }
  function setIsConventionsModalOpen(value: boolean) {
    isConventionsModalOpen.value = value;
  }
</script>

<style lang="scss" scoped></style>
