import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Pie } from 'vue-chartjs';

Chart.register(ChartDataLabels);

export default {
    extends: Pie,
    props: ['data', 'options'],
    mounted() {
        // this.chartData is created in the mixin.
        // If you want to pass options please create a local options object
        this.renderChart(this.data, {
            responsive: true,
            legend: { position: 'bottom' },
            plugins: {
                datalabels: {
                    formatter: (value, ctx) => {
                        const datasets = ctx.chart.data.datasets;

                        let percentage = 0;

                        if (
                            datasets.indexOf(ctx.dataset) ===
                            datasets.length - 1
                        ) {
                            const sum = datasets[0].data.reduce(
                                (a, b) => a + b,
                                0
                            );

                            percentage = Math.round((value / sum) * 100) + '%';

                            return percentage;
                        } else {
                            return percentage;
                        }
                    },
                    color: 'white',
                    textStrokeColor: 'black',
                    textStrokeWidth: 1,
                    textAlign: 'center',
                    font: {
                        weight: 'bold',
                        size: 12
                    }
                }
            },
            ...this.options
        });
    }
};
