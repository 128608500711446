import { ACTION_TYPES } from '@/store/constants/ActionTypes';
import { MUTATION_TYPES } from '@/store/constants/MutationTypes';

const state = {
    offset: null,
    scrolled_body: false,
    sidebar: true,
    showSidebar: false,
    showSearchModal: false,
    is_grid: {
        participant: false,
        entreprise: false,
        formateur: false,
        controleurDeStageEC: false,
        controleurDeStageCAC: false,
        maitreDeStage: false,
        stagiaire: false,
        stagiaireCAC: false
    }
};

const mutations = {
    async [MUTATION_TYPES.SET_MODAL](state, { name, value }) {
        state[name] = value;
    },
    async setFixedOffset(state, offset) {
        state.offset = offset;
    },
    async setScroll(state, scrolled_body) {
        state.scrolled_body = scrolled_body;
    },
    async setSidebar(state, sidebar) {
        state.sidebar = sidebar;
    },
    async setShowSidebar(state, showSidebar) {
        state.showSidebar = showSidebar;
    },
    async setIsGrid(state, { is_grid, current_space }) {
        state.is_grid[current_space] = is_grid;
    }
};

const actions = {
    [ACTION_TYPES.SET_MODAL]({ commit }, payload) {
        commit(MUTATION_TYPES.SET_MODAL, payload);
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
