<template>
  <div
    class="w-full lg:h-full"
    v-bind:style="[`min-height: var(--wrapper-height)`, cssVars]">
    <spinner
      v-if="loading"
      sizes="w-20 h-20"
      thickness="border-8" />

    <div
      v-if="!loading"
      class="flex h-full flex-col">
      <template v-if="formations_list && formations_list.length">
        <div
          ref="header_height"
          class="flex flex-wrap items-center justify-between sm:mb-8 lg:mb-0 lg:flex-nowrap">
          <h1 class="section__title mt-0 sm:mb-8">
            {{ title }}
          </h1>
          <div
            class="my-2 flex min-h-full w-full flex-wrap items-center justify-end space-y-2 sm:h-16 sm:flex-nowrap lg:space-y-0">
            <transition
              name="fade"
              v-bind:key="is_grid[current_space]"
              mode="out-in">
              <search-input-autocomplete
                v-show="!is_grid[current_space]"
                v-bind:items="itemsToDisplay"
                v-on:setted_result="(e) => fetchAutocompleteValue(e, false)" />
            </transition>

            <div
              v-if="has_formations"
              class="ml-6 flex items-stretch overflow-hidden rounded-md bg-core-200 text-accent-500 shadow-inner hover:bg-accent-200">
              <button
                v-on:click="toggleIsGrid"
                class="flex w-full items-center justify-center rounded-l px-3 py-1.5 transition"
                v-bind:class="[
                  !is_grid[current_space]
                    ? `bg-${espaces_list[current_space].color} text-white shadow-md`
                    : ''
                ]">
                <font-awesome-icon
                  icon="list"
                  size="lg"
                  fixed-width />
              </button>
              <button
                v-on:click="toggleIsGrid"
                class="flex w-full items-center justify-center rounded-r px-3 py-1.5 transition"
                v-bind:class="[
                  is_grid[current_space]
                    ? `bg-${espaces_list[current_space].color} text-white shadow-md`
                    : ''
                ]">
                <font-awesome-icon
                  icon="table"
                  size="lg"
                  fixed-width />
              </button>
            </div>
          </div>
        </div>
        <div
          v-if="!is_grid[current_space]"
          class="mb-5 flex w-full text-xl font-bold">
          Résultats:&nbsp;{{ formationsToDisplay.length }}
        </div>

        <template v-if="has_formations">
          <spinner v-if="loading_switch" />

          <template v-else-if="!is_grid[current_space]">
            <formation-libre-container
              ref="formation_libre_container"
              v-for="formation in formationsToDisplay"
              v-bind:key="formation.id"
              v-bind:formation="formation" />
          </template>

          <ag-grid-wrapper
            v-else-if="is_grid[current_space]"
            grid_height="calc(var(--wrapper-height) - var(--header-height) - var(--ag-header-height-offset))"
            v-bind:column_defs="columnDefs"
            v-bind:initial_data="grid_formations"
            v-bind:exportable="true" />
        </template>
        <template v-else>
          <div class="p-8 text-xl font-bold">Aucun résultat</div>
        </template>
      </template>
      <template v-else>
        <div class="flex w-full justify-center pt-5 text-lg font-bold">
          {{ no_result }}
        </div>
      </template>
    </div>
  </div>
</template>
<script>
  import agGridWrapper from '@/components/utils/ag-grid/ag-grid-wrapper';
  import formationLibreContainer from '@/components/utils/formation-libre-container';
  import searchInputAutocomplete from '@/components/utils/search-input-autocomplete.vue';
  import spinner from '@/components/utils/spinner.vue';
  import dates_helpers from '@/mixin/dates_helpers';
  import http_functions from '@/mixin/http_functions';
  import navigation_helpers from '@/mixin/navigation_helpers';
  import { createNamespacedHelpers } from 'vuex';

  const { mapState: mapStateEspace } = createNamespacedHelpers('espace');
  const { mapMutations: mapMutationsObserver, mapState: mapStateObserver } =
    createNamespacedHelpers('observer');

  export default {
    components: {
      spinner,
      agGridWrapper,
      formationLibreContainer,
      searchInputAutocomplete
    },
    mixins: [navigation_helpers, http_functions, dates_helpers],
    props: {
      title: { type: String },
      no_result: { type: String },
      formations_list: { type: Array },
      loading: { type: Boolean },
      current_space: { type: String }
    },
    data() {
      return {
        header_height: 0,
        loading_switch: false,
        formationsToDisplay: null,
        inscription: null
      };
    },
    computed: {
      ...mapStateEspace(['espaces_list']),
      ...mapStateObserver({ is_grid: (state) => state.is_grid }),
      cssVars() {
        return { '--header-height': `${this.header_height}px` };
      },
      has_formations() {
        return this.is_grid[this.current_space]
          ? this.formations_list && this.formations_list.length
          : this.formationsToDisplay && this.formationsToDisplay.length;
      },
      columnDefs() {
        if (this.has_formations) {
          return [
            {
              headerName: 'Titre',
              field: 'titre',
              cellRendererParams: {
                onclick: (e, params) => this.goToFiche(e, params)
              }
            },
            {
              headerName: 'Type',
              field: 'type',
              filterValueGetter: (params) => {
                return params.data?.type.label;
              }
            },
            {
              headerName: 'Mode',
              field: 'mode'
            },
            {
              headerName: 'Organisateur',
              field: 'organisateur'
            },
            {
              headerName: 'Date de début',
              field: 'sessionDateDebut'
            },
            {
              headerName: 'Date de fin',
              field: 'sessionDateFin'
            },
            {
              headerName: 'Lieu',
              field: 'lieu'
            },
            {
              headerName: 'Type de formation',
              field: 'formationLibre'
            },
            {
              headerName: 'Cycle stage',
              field: 'cycleStageCAC'
            }
          ];
        } else {
          return [];
        }
      },
      grid_formations() {
        const sub_formations = [];
        const format_for_grid = this.formations_list.map((item) => {
          const formated_item = {};

          Object.keys(item).forEach((key) => {
            if (['mode'].includes(key)) {
              formated_item[key] = item[key].label;
            } else if (key === 'type') {
              formated_item[key] = item[key];
              formated_item['typeId'] = item[key].id;
            } else if (key === 'formationLibreTypeId') {
              formated_item['formationLibre'] =
                item[key] && item[key] === 2
                  ? 'Session déléguée'
                  : 'Inscription SAFIR';
            } else {
              formated_item[key] = item[key];
            }
          });

          return formated_item;
        });

        format_for_grid.push(...sub_formations);

        return format_for_grid;
      }
    },
    watch: {
      $refs: {
        immediate: true,
        deep: true,
        handler(newValue) {
          setTimeout(() => {
            if (newValue.header_height) {
              this.header_height = newValue.header_height.clientHeight;
            }
          }, 300);
        }
      }
    },
    created() {
      this.fetchAutocompleteValue('', true);
    },
    methods: {
      ...mapMutationsObserver({ setIsGrid: 'setIsGrid' }),
      toggleIsGrid() {
        this.loading_switch = true;
        this.setIsGrid({
          is_grid: !this.is_grid[this.current_space],
          current_space: this.current_space
        });

        setTimeout(() => {
          this.loading_switch = false;
        }, 300);
      },
      goToFiche(event, params) {
        this.openFiche(event, params);
      },
      fetchformationsToDisplay(is_first) {
        const format_list = this.formations_list.map((formation) => {
          let produits = [];

          if (formation.isParcours) {
            produits =
              !formation.isSousProduit &&
              this.formations_list.filter(
                (s) =>
                  s.parcoursReference === formation.reference &&
                  s.commandeReference === formation.commandeReference &&
                  s.reference !== formation.reference
              );
          }

          if (formation.isCursus) {
            produits =
              !formation.isSousProduit &&
              this.formations_list.filter(
                (s) =>
                  s.cursusReference === formation.reference &&
                  s.commandeReference === formation.commandeReference &&
                  s.reference !== formation.reference
              );
          }

          return {
            ...formation,
            produits
          };
        });

        this.formationsToDisplay = format_list.filter(
          (formation) => !formation.isSousProduit
        );
        if (is_first) {
          this.itemsToDisplay = this.formationsToDisplay;
        }
      },
      fetchAutocompleteValue(search, is_first) {
        const fetchSearch = (formation) => {
          if (search.type) {
            return search.value.some(
              (e) => e[search.type] == formation[search.type]
            );
          } else if (typeof search.value == 'object' && !search.type) {
            return ['titre', 'reference', 'organisateur', 'lieu'].every(
              (type) => search.value[type] === formation[type]
            );
          } else {
            return true;
          }
        };

        if (this.formations_list) {
          this.fetchformationsToDisplay(is_first);
          this.formationsToDisplay =
            this.formationsToDisplay.filter(fetchSearch);
        }

        if (this.$refs.formation_libre_container) {
          this.$refs.formation_libre_container.forEach((el) => {
            if (el.collapse) {
              el.openCollapse();
            }
          });
        }

        const highlight_list = document.getElementsByClassName('highlight');

        [...highlight_list].forEach((item) => {
          item.replaceWith(...item.childNodes);
        });

        if (search && search.type && search.search_string) {
          const data_id = `[data_id='${search.type}']`;

          this.$nextTick(() => {
            const sanitizeHTML = function (str) {
              const temp = document.createElement('div');

              temp.textContent = str;

              return temp.innerHTML;
            };
            const escapeRegExp = (str) =>
              str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

            const elems = document.querySelectorAll(data_id);
            const elems_list = [...elems].filter((el) => {
              const regex = new RegExp(
                escapeRegExp(search.search_string),
                'gi'
              );

              return regex.test(el.innerText);
            });

            elems_list.forEach((node) => {
              const result = node.textContent;

              const index = result
                .toLowerCase()
                .indexOf(search.search_string.toLowerCase());
              const last_index = index + search.search_string.length;
              const slice = result.slice(index, last_index);

              const replace_match = new RegExp(
                escapeRegExp(search.search_string),
                'gi'
              );
              const replacer = `<span class="highlight">${sanitizeHTML(slice)}</span>`;

              node.innerHTML = node.innerHTML.replace(replace_match, replacer);
            });
          });
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  :deep(.highlight) {
    @apply bg-yellow-300;
  }
</style>
