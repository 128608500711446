<template>
  <modal-box
    v-if="apply_modal"
    v-on:close="closeAndResetModal"
    v-bind:width_class="'w-full max-w-screen-sm'"
    overflow_body>
    <template v-slot:modal-header>
      <h1 class="text-xl font-bold text-jinius-dark-green">
        Confirmation de votre candidature
      </h1>
    </template>

    <template v-slot:modal-body>
      <div
        v-if="showSameDate"
        class="flex w-full text-sm font-bold text-jinius-red">
        Attention, vous avez déjà postulé à une session planifiée le même jour.
      </div>

      <div class="flex w-full flex-col py-2 text-lg">
        <div class="mb-3 font-bold text-core-500">
          Vous souhaitez postuler pour l'animation de la session&nbsp;:
        </div>

        <div
          class="mx-2 mb-3 border-l-4 border-jinius-dark-green pb-3 pl-5 pt-2 font-bold">
          {{ `${session.titre}` }}
        </div>

        <div class="flex flex-wrap">
          <div class="px-10 pb-5 pt-4">
            <div class="mb-1 font-bold">
              <font-awesome-icon
                v-bind:icon="['fas', 'tag']"
                class="mr-1" />
              <span class="inline-flex space-x-2">
                <em class="font-normal text-accent-500">Réf&nbsp;:</em>
                <span>{{ session.reference }}</span>
              </span>
            </div>
            <div class="ml-2 border-l border-accent-400 pl-4 font-bold">
              <div v-if="dates">
                <font-awesome-icon
                  size="sm"
                  v-bind:icon="['far', 'calendar-alt']"
                  class="mr-2 text-accent-500" />
                <span class="">{{ dates }}</span>
              </div>
              <div v-if="session.lieuNom">
                <font-awesome-icon
                  size="sm"
                  icon="map-marker-alt"
                  class="mr-2 text-accent-500" />
                <span class="">{{ session.lieuNom }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="flex w-full py-2 font-bold text-jinius-dark-green">
          Souhaitez-vous continuer ?
        </div>
      </div>

      <transition name="slide-up">
        <div
          v-if="showMotivation"
          class="flex w-full py-2">
          <textarea-box
            label="Ajouter un commentaire (facultatif)"
            class_name="no-box"
            v-bind:maxlength="500"
            name="motivation"
            placeholder="Saisir votre commentaire..."
            v-bind:value="motivation"
            v-on:input="setMotivation"
            autogrow />
        </div>
      </transition>
    </template>
    <template v-slot:modal-footer>
      <div
        v-if="showMotivation"
        class="inline-flex w-full justify-center space-x-4">
        <button
          class="primary__button"
          v-on:click="applySession">
          Confirmer
        </button>
        <button
          class="secondary__button"
          v-on:click="closeAndResetModal">
          Annuler
        </button>
      </div>
      <div
        v-else
        class="inline-flex w-full justify-center space-x-4">
        <button
          class="primary__button"
          v-on:click="toggleMotivation">
          Postuler
        </button>
        <button
          class="secondary__button"
          v-on:click="closeAndResetModal">
          Annuler
        </button>
      </div>
    </template>
  </modal-box>
</template>
<script>
  import modalBox from '@/components/utils/modal-box.vue';
  import textareaBox from '@/components/utils/textarea-box';
  import { API } from '@/http-common';

  export default {
    components: {
      modalBox,
      textareaBox
    },
    props: ['apply_modal', 'onclose', 'session', 'fetch_navettes'],
    data: () => ({
      showMotivation: false,
      motivation: ''
    }),
    computed: {
      showSameDate() {
        return (
          this.session &&
          this.session.sessionDates &&
          this.session.sessionDates.length &&
          this.session.sessionDates.some((date) => date.hasCandidatures)
        );
      },
      dates() {
        return (
          this.session &&
          this.session.sessionDates &&
          this.session.sessionDates.length &&
          this.session.sessionDates.map((date) => date.date).join(', ')
        );
      }
    },
    methods: {
      closeAndResetModal() {
        this.motivation = '';
        this.showMotivation = false;
        this.onclose();
      },
      toggleMotivation() {
        this.showMotivation = true;
      },
      async applySession() {
        const body = {
          id: this.session.navetteSessionId,
          ...(this.motivation !== '' && { motivations: this.motivation })
        };

        await API.post(
          'formateur/postuler-candidature',
          body,
          this.$header_skip_redirection
        );
        await this.fetch_navettes();
        this.closeAndResetModal();
      },
      setMotivation(value) {
        this.motivation = value;
      }
    }
  };
</script>
<style lang="scss" scoped>
  .primary__button {
    @apply w-64 cursor-pointer rounded bg-core-900 px-5 py-3 text-center font-bold text-white shadow hover:bg-jinius-dark-green;
  }

  .secondary__button {
    @apply w-64 cursor-pointer rounded bg-core-300 px-5 py-3 text-center font-bold text-core-700 shadow hover:bg-core-200;
  }
</style>
