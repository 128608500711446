<template>
  <div class="rgpd-mentions-legales">
    <h1 class="rgpd-mentions-legales__titre">
      Politique RGPD / Mentions légales
    </h1>
    <div class="rgpd-mentions-legales__content">
      <div class="content__row justify-center">
        <div class="contact__wrapper">
          <div class="contact__info">
            <div class="info__column">
              <font-awesome-icon
                icon="headset"
                fixed-width />
              <span>01 59 03 12 61</span>
            </div>
            <div class="info__column">
              <font-awesome-icon
                v-bind:icon="['far', 'envelope']"
                fixed-width
                class="mt-1.5" />
              <span>assistance@jinius.fr</span>
            </div>
          </div>
        </div>
      </div>

      <div class="content__row">
        <div class="content__column content__column--left">
          <div
            class="column__wrapper"
            v-bind:class="{ 'column__wrapper--is-fixed': is_fixed }">
            <h2 class="column__title">Rubriques</h2>
            <ul
              class="list-inside list-[square] text-xl marker:text-jinius-blue">
              <li
                v-bind:key="`section-rgpd`"
                class="mb-1 first-letter:uppercase"
                v-on:click="(e) => scrollToSection(e, 'politique-rgpd')">
                <a v-bind:href="`#politique-rgpd`"> Politique RGPD </a>
              </li>
              <li
                v-bind:key="`section-mentions-legales`"
                class="mb-1 first-letter:uppercase"
                v-on:click="(e) => scrollToSection(e, 'mentions-legales')">
                <a v-bind:href="`#mentions-legales`"> Mentions légales </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="content__column content__column--right">
          <div class="column__wrapper">
            <h2
              class="column__title !text-5xl"
              ref="politique-rgpd">
              Politique RGPD
            </h2>
            <div class="inline-flex flex-wrap">
              <a
                target="_blank"
                v-bind:href="`${cdn_url}/rgpd-mentions-legales/Politique_de_protection_des_données_à_caractère_personnel_GIE_SAFIR__VF.pdf`"
                class="mr-6 mt-3 w-full space-x-2.5 rounded bg-core-100 py-3 pl-7 pr-8 font-bold !text-jinius-purple shadow transition hover:bg-core-100 hover:!text-blue-600 sm:w-auto lg:text-lg">
                <font-awesome-icon
                  icon="file-pdf"
                  size="lg"
                  fixed-width />
                <span
                  >Politique de protection des données à caractère
                  personnel</span
                >
              </a>
            </div>
            <rgpd-content />

            <h2
              class="column__title mt-8 !text-5xl"
              ref="mentions-legales">
              Mentions légales
            </h2>
            <div class="inline-flex flex-wrap">
              <a
                target="_blank"
                v-bind:href="`${cdn_url}/rgpd-mentions-legales/Mentions_légales_SAFIR-JINIUS_VF.pdf`"
                class="mr-6 mt-3 w-full space-x-2.5 rounded bg-core-100 py-3 pl-7 pr-8 font-bold !text-jinius-purple shadow transition hover:bg-core-100 hover:!text-blue-600 sm:w-auto lg:text-lg">
                <font-awesome-icon
                  icon="file-pdf"
                  size="lg"
                  fixed-width />
                <span>Mentions légales</span>
              </a>
            </div>
            <mentions-legales-content
              v-bind:scroll_to_section="scrollToSection" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import RgpdContent from '@/views/_espace/_rgpd/rgpd-content.vue';
  import MentionsLegalesContent from '@/views/_espace/_rgpd/mentions-legales-content.vue';
  import { map_domain_per_env } from '@/assets/data/data_maps';

  export default {
    components: {
      MentionsLegalesContent,
      RgpdContent
    },
    inject: ['target_wrapper'],
    data() {
      return {
        is_fixed: false
      };
    },
    computed: {
      cdn_url() {
        return `https://cdn.${map_domain_per_env[import.meta.env.VITE_CONTEXT]}`;
      }
    },
    mounted() {
      this.target_wrapper.addEventListener('scroll', this.handleScroll);
    },
    methods: {
      scrollToSection(e, id) {
        e.preventDefault();
        const offset = this.$refs[id].offsetTop;

        this.target_wrapper.scroll({
          top: offset - 80,
          behavior: 'smooth'
        });
      },
      handleScroll(e) {
        if (e.target.scrollTop > 250) {
          this.is_fixed = true;
        } else {
          this.is_fixed = false;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .rgpd-mentions-legales {
    @apply bg-white py-4;
    a {
      @apply text-jinius-blue hover:text-jinius-dark-green;
    }
    .rgpd-mentions-legales__titre {
      @apply mt-8 px-4 text-center text-5xl font-bold lg:text-7xl;
    }

    .rgpd-mentions-legales__content {
      @apply mx-auto flex w-full max-w-screen-2xl flex-wrap;
      .contact__wrapper {
        @apply flex w-full flex-wrap rounded-md border border-core-200 bg-core-100 px-6 py-8 shadow-md;
        .contact__title {
          @apply mb-7 w-full text-center text-3xl font-bold;
        }
        .contact__info {
          @apply flex w-full flex-wrap justify-around space-y-4 sm:flex-nowrap sm:space-y-0;
          .info__column {
            @apply flex w-full flex-auto items-center space-x-4 text-3xl sm:w-auto lg:justify-center;
          }
        }
      }
      .content__row {
        @apply mt-10 flex w-full flex-wrap;
        .content__column {
          @apply mb-8 flex w-full flex-col lg:mb-0;
          .column__wrapper {
            @apply px-6 lg:pl-2.5 lg:pr-5;
          }
          &.content__column--left {
            @apply lg:w-1/4;
            .column__wrapper {
              @apply relative flex w-full flex-col rounded-md border border-core-200 py-6 shadow-md transition-[top] duration-200 ease-in lg:top-[0px];

              &.column__wrapper--is-fixed {
                @apply lg:sticky lg:top-8;
              }
              @apply bg-core-100;
            }
          }
          &.content__column--right {
            @apply lg:w-3/4;
            .column__wrapper {
              @apply pb-12 lg:pl-8;
            }
          }
          .column__title {
            @apply mb-5 flex px-4 text-center text-4xl;
          }
        }
      }
    }
  }
</style>
