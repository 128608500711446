import { API } from '@/http-common';
import { ACTION_TYPES } from '@/store/constants/ActionTypes';
import { MUTATION_TYPES } from '@/store/constants/MutationTypes';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';
import qs from 'querystring-es3';

export const formInitialState = {
    obligationFormation: false,
    estDeclare: false,
    numeroDeclaration: null,
    estCerfieQualiopi: 'oui',
    dateQualiopi: null,
    anneeFormateur: null,
    diplomes: [],
    parcoursFormation: [],
    instances: [],
    competences: [],
    parcoursAnimation: [],
    formations: [],
    themesIds: [],
    themes: [],
    interventions: [],
    retrieved: false
};

const state = {
    savedCvForm: {},
    cvForm: formInitialState,
    themes: [],
    isFormateur: false,
    hasNavettes: false,
    hasAppreciations: false,
    hasContrats: false,
    cv_consent: []
};

export const listArrayToTransform = [
    'diplomes',
    'parcoursFormation',
    'instances',
    'competences',
    'parcoursAnimation',
    'formations',
    'interventions'
];

const mutations = {
    async [MUTATION_TYPES.SET_FORM_ATTRIBUTE](state, { name, value }) {
        state.cvForm = {
            ...state.cvForm,
            [name]: value
        };
    },
    async [MUTATION_TYPES.SET_CV](state, cv) {
        listArrayToTransform.forEach((item) => {
            const value = cv[item] ? JSON.parse(cv[item]) : undefined;

            cv[item] = value ? value : [];
        });
        const { dateDeNaissance, dateQualiopi } = cv;

        if (dateDeNaissance) {
            cv.dateDeNaissance = new Date(dateDeNaissance);
        }
        if (dateQualiopi) {
            cv.dateQualiopi = new Date(dateQualiopi);
        }
        cv.themes = state.themes.map((theme) => ({
            ...theme,
            checked: cv.themesIds && cv.themesIds.includes(theme.id)
        }));
        state.cvForm = {
            ...state.cvForm,
            ...cv,
            retrieved: true
        };
        state.savedCvForm = { ...state.cvForm };
    },
    async [MUTATION_TYPES.SET_CV_CONSENTEMENT](state, cv_consent) {
        state.cv_consent = cv_consent;
    },
    async [MUTATION_TYPES.SET_THEMES](state, themes) {
        state.themes = themes;
    },
    async [MUTATION_TYPES.SET_FORMATEUR_PROFILE](state, payload) {
        state.isFormateur = payload.formateur;
        state.hasNavettes = payload.candidature;
        state.hasAppreciations = payload.appreciations;
        state.hasContrats = payload.contrats;
    }
};

const actions = {
    async [ACTION_TYPES.GET_FORMATEUR_PROFILE]({ commit }) {
        const response = await API.get('formateur/profile');

        commit(MUTATION_TYPES.SET_FORMATEUR_PROFILE, response.data);
    },
    [ACTION_TYPES.SET_FORM_ATTRIBUTE]({ commit }, payload) {
        commit(MUTATION_TYPES.SET_FORM_ATTRIBUTE, payload);
    },
    async [ACTION_TYPES.GET_CV]({ commit }) {
        const response = await API.get(
            'formateur/cv',
            this.$header_skip_redirection
        );

        commit(MUTATION_TYPES.SET_CV, response.data);
    },
    async [ACTION_TYPES.GET_THEMES]({ commit }) {
        const response = await API.get(
            'formateur/themes',
            this.$header_skip_redirection
        );

        commit(MUTATION_TYPES.SET_THEMES, response.data);
    },
    async [ACTION_TYPES.GET_CV_CONSETEMENT]({ commit }) {
        const response = await API.get(
            'formateur/cv_consentement',
            this.$header_skip_redirection
        );

        commit(MUTATION_TYPES.SET_CV_CONSENTEMENT, response.data);
    },
    async [ACTION_TYPES.SEND_CV]({ commit }, payload) {
        const newPayload = { ...payload };

        listArrayToTransform.forEach((item) => {
            newPayload[item] = JSON.stringify(newPayload[item]);
        });
        delete newPayload.modifiedDate;
        delete newPayload.modifiedBy;
        if (newPayload.dateDeNaissance) {
            newPayload.dateDeNaissance = format(
                newPayload.dateDeNaissance,
                'yyyy-MM-dd',
                { locale: fr }
            );
        }
        if (newPayload.dateQualiopi) {
            newPayload.dateQualiopi = format(
                newPayload.dateQualiopi,
                'yyyy-MM-dd',
                { locale: fr }
            );
        }

        return API.put('formateur/cv', qs.stringify(newPayload), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                ...this.$header_skip_redirection.headers
            }
        });
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
