<template>
  <div class="flex w-full">
    <form
      v-if="!loading"
      ref="entiteForm"
      class="flex h-full w-full flex-col">
      <div
        v-if="entiteForm.actif === false"
        class="-mx-4 mt-3 bg-danger-100 px-6 py-4 lg:-mx-6 lg:-mt-6">
        <div class="text-center text-lg font-bold uppercase text-jinius-red">
          Entité désactivée
        </div>
        <div
          v-if="entiteForm.desactivationMotif"
          class="text-center">
          Motif : {{ entiteForm.desactivationMotif }}
        </div>
      </div>

      <declare-admin-modal
        v-bind:entite="getActiveEntreprise()"
        v-bind:modal_declaration="modal_declaration"
        v-bind:onclose="closeDeclarationModal"
        v-bind:refresh_entites="fetchProfile" />
      <template v-if="!disabledAll">
        <div class="flex w-full flex-col">
          <save-form-button
            class="mt-4"
            v-bind:class="
              entiteForm.actif === false ? 'entite__desactivee' : ''
            "
            v-bind:v$="v$"
            v-bind:submit="submit"
            is_espace_entreprise
            check_consent_text="Vous acceptez que les informations renseignées soient utilisées par les organismes de formation dans la gestion de vos commandes et l'amélioration du suivi de vos demandes." />
        </div>
        <div class="flex w-full flex-col">
          <div class="relative flex items-center lg:justify-end">
            <div
              v-if="entiteForm.actif !== false"
              class="desactiver__button mt-3 lg:mt-0"
              v-on:click="openDesactiverEntiteModal">
              <font-awesome-icon v-bind:icon="['fas', 'ban']" />
              <span class="ml-4">Désactiver cette entité</span>
            </div>
            <div
              v-else
              class="activer__button mt-3 lg:mt-0"
              v-on:click="activerEntite">
              <font-awesome-icon v-bind:icon="['fas', 'check']" />
              <span class="ml-4">Réactiver cette entité</span>
            </div>
          </div>
        </div>
      </template>

      <div>
        <informations-generales
          v-bind:v$="v$"
          v-bind:entite_form="entiteForm"
          v-bind:set_attribute="setEntiteForm"
          v-bind:disabled="disabledAll" />
        <informations-adresse
          v-bind:v$="v$"
          v-bind:entite_form="entiteForm"
          v-bind:set_attribute="setEntiteForm"
          v-bind:disabled="disabledAll" />
        <informations-inscription
          v-bind:v$="v$"
          v-bind:entite_form="entiteForm"
          v-bind:set_attribute="setEntiteForm"
          v-bind:disabled="disabledAll" />
        <informations-contact
          v-bind:v$="v$"
          v-bind:entite_form="entiteForm"
          v-bind:set_attribute="setEntiteForm"
          v-bind:disabled="disabledAll" />
      </div>
    </form>
    <spinner
      v-else
      sizes="w-20 h-20"
      thickness="border-8" />
    <desactiver-entite-modal
      v-bind:modal_show="modal_desactiver_entite"
      v-bind:onclose="closeDesactiverEntiteModal"
      v-bind:ondisable="desactiverEntite"
      v-bind:entite_form="entiteForm"
      v-bind:set_attribute="setEntiteForm" />
  </div>
</template>
<script>
  import saveFormButton from '@/components/utils/save-form-button.vue';
  import spinner from '@/components/utils/spinner.vue';
  import { ACTION_TYPES } from '@/store/constants/ActionTypes';
  import declareAdminModal from '@/views/_espace/_entreprise/_entreprise/_informations/declare-admin-modal.vue';
  import desactiverEntiteModal from '@/views/_espace/_entreprise/_entreprise/_informations/desactiver-entite-modal';
  import InformationsAdresse from '@/views/_espace/_entreprise/_entreprise/_informations/informations-adresse';
  import InformationsContact from '@/views/_espace/_entreprise/_entreprise/_informations/informations-contact';
  import InformationsGenerales from '@/views/_espace/_entreprise/_entreprise/_informations/informations-generales';
  import InformationsInscription from '@/views/_espace/_entreprise/_entreprise/_informations/informations-inscription';
  import { useVuelidate } from '@vuelidate/core';
  import {
    email,
    helpers,
    maxLength,
    minLength,
    required,
    requiredIf
  } from '@vuelidate/validators';
  import { createNamespacedHelpers } from 'vuex';

  const { mapState: mapStateClient, mapActions: mapActionsClient } =
    createNamespacedHelpers('client');

  export default {
    components: {
      InformationsGenerales,
      InformationsContact,
      InformationsAdresse,
      InformationsInscription,
      spinner,
      saveFormButton,
      declareAdminModal,
      desactiverEntiteModal
    },
    setup() {
      return { v$: useVuelidate() };
    },
    data: () => ({
      loading: true,
      modal_declaration: false,
      modal_desactiver_entite: false
    }),
    computed: {
      ...mapStateClient(['entiteForm', 'profile', 'clientType']),
      disabledAll() {
        const { activeEntrepriseId, entreprises } = this.profile;

        const clientContact = entreprises.find(
          (e) => e.entrepriseId === activeEntrepriseId
        );

        return !(
          clientContact.droitAdministrateur &&
          !clientContact.administrateurEnAttente
        );
      },
      minLength() {
        return this.entiteForm.valideSansSiret ? 0 : 14;
      },
      maxLength() {
        return this.entiteForm.valideSansSiret ? 20 : 14;
      }
    },
    watch: {
      async ['profile.activeEntrepriseId']() {
        await this.fetchEntreprise();
      }
    },
    async created() {
      await this.fetchEntreprise();
    },
    methods: {
      ...mapActionsClient([
        ACTION_TYPES.GET_ENTREPRISE_INFORMATIONS,
        ACTION_TYPES.SET_ENTITE_FORM_ATTRIBUTE,
        ACTION_TYPES.GET_ENTREPRISE_PROFILE,
        ACTION_TYPES.SEND_ENTREPRISE_INFORMATIONS,
        ACTION_TYPES.POST_ENTREPRISE_REACTIVER,
        ACTION_TYPES.POST_ENTREPRISE_DESACTIVER
      ]),
      getActiveEntreprise() {
        const { entreprises, activeEntrepriseId } = this.profile;

        return entreprises.find(
          (entreprise) => entreprise.entrepriseId === activeEntrepriseId
        );
      },
      closeDeclarationModal() {
        this.modal_declaration = false;
      },
      openDeclarationModal() {
        this.modal_declaration = true;
      },
      setEntiteForm(value, name) {
        this[ACTION_TYPES.SET_ENTITE_FORM_ATTRIBUTE]({
          name,
          value
        });
      },
      async fetchProfile() {
        this.loading = true;
        await this[ACTION_TYPES.GET_ENTREPRISE_PROFILE]();
        const activeEntreprise = this.getActiveEntreprise();

        if (activeEntreprise && activeEntreprise.administrateurEnAttente) {
          this.openDeclarationModal();
        }
        this.loading = false;
      },
      async fetchEntreprise() {
        this.loading = true;
        const clientId = this.profile.activeEntrepriseId;

        await this[ACTION_TYPES.GET_ENTREPRISE_INFORMATIONS]({ clientId });
        await this.fetchProfile();

        this.loading = false;
      },
      async submit() {
        this.loading = true;
        const clientId = this.profile.activeEntrepriseId;

        try {
          await this[ACTION_TYPES.SEND_ENTREPRISE_INFORMATIONS]({
            entiteForm: this.entiteForm,
            clientId
          });
          await this[ACTION_TYPES.GET_ENTREPRISE_INFORMATIONS]({ clientId });

          this.loading = false;

          return { isError: false };
        } catch (e) {
          this.loading = false;

          await this[ACTION_TYPES.GET_ENTREPRISE_INFORMATIONS]({ clientId });

          return {
            ...e.response,
            isError: true
          };
        }
      },
      closeDesactiverEntiteModal() {
        this.modal_desactiver_entite = false;
      },
      openDesactiverEntiteModal() {
        this.modal_desactiver_entite = true;
      },
      async desactiverEntite() {
        this.loading = true;
        const clientId = this.profile.activeEntrepriseId;

        try {
          await this[ACTION_TYPES.POST_ENTREPRISE_DESACTIVER]({
            body: { desactivationMotif: this.entiteForm.desactivationMotif },
            clientId
          });
          await this[ACTION_TYPES.GET_ENTREPRISE_INFORMATIONS]({ clientId });

          this.loading = false;

          return { isError: false };
        } catch (e) {
          this.loading = false;

          await this[ACTION_TYPES.GET_ENTREPRISE_INFORMATIONS]({ clientId });

          return {
            ...e.response,
            isError: true
          };
        }
      },
      async activerEntite() {
        this.loading = true;
        const clientId = this.profile.activeEntrepriseId;

        try {
          await this[ACTION_TYPES.POST_ENTREPRISE_REACTIVER]({ clientId });
          await this[ACTION_TYPES.GET_ENTREPRISE_INFORMATIONS]({ clientId });

          this.loading = false;

          return { isError: false };
        } catch (e) {
          this.loading = false;

          await this[ACTION_TYPES.GET_ENTREPRISE_INFORMATIONS]({ clientId });

          return {
            ...e.response,
            isError: true
          };
        }
      }
    },
    validations() {
      return {
        entiteForm: {
          siret: {
            minLength: minLength(this.minLength),
            maxLength: maxLength(this.maxLength),
            required: requiredIf(function () {
              return (
                this.clientType === 'siret' && !this.entiteForm.valideSansSiret
              );
            })
          },
          denomination: {
            maxLength: maxLength(100),
            required
          },
          identification: { maxLength: maxLength(100) },
          email: { email },
          numeroVoie: { required },
          ville: { required },
          codePostal: {
            required,
            maxLength: helpers.ref?.pays ? maxLength(25) : maxLength(5)
          },
          commandeProfilId: { required }
        }
      };
    }
  };
</script>
<style lang="scss" scoped>
  :deep(.card__separator) {
    @apply my-8 flex w-full text-primary-900 opacity-50;
  }
  .confirmed__button {
    @apply flex w-96 cursor-pointer items-center justify-center rounded px-8 py-3 text-lg shadow transition;

    &.confirmed__button--remain {
      @apply border border-jinius-dark-green text-jinius-dark-green hover:border-jinius-green;
    }
    &.confirmed__button--pick-catalogue {
      @apply bg-jinius-dark-green text-core-200 hover:bg-jinius-green hover:text-white;
    }
  }
  .desactiver__button {
    @apply z-10 flex w-full cursor-pointer items-center justify-center rounded bg-jinius-red px-6 py-3 leading-tight text-core-200 shadow hover:bg-jinius-red hover:text-white sm:w-auto lg:fixed lg:text-lg;
    @media (min-width: 1024px) {
      transform: translateY(56px);
    }
  }
  .activer__button {
    @apply z-10 flex w-full cursor-pointer items-center justify-center rounded bg-jinius-dark-green px-6 py-3 leading-tight text-core-200 shadow hover:bg-jinius-green hover:text-white sm:w-auto lg:fixed lg:text-lg;
    @media (min-width: 1024px) {
      transform: translateY(76px);
    }
  }
  .entite__desactivee {
    :deep(.save__button) {
      @media (min-width: 1024px) {
        transform: translateY(20px);
      }
    }
  }
</style>
