<template>
  <div
    class="session-card"
    v-bind:class="{ 'session-card--head-list': head_list }">
    <div class="card__container">
      <div
        class="card__container--anchor"
        v-bind:class="type_anchor_color(session.type[0].id)"></div>

      <div class="container__content">
        <div class="content__type">
          <div
            v-for="{ id, label } in session.type"
            v-bind:key="id"
            class="type__label"
            v-bind:class="type_bg_color(id)">
            <span>{{ label }}</span>
          </div>
        </div>

        <div class="content__title">
          {{ session.titre }}
        </div>

        <div class="flex flex-wrap items-center justify-between">
          <h2
            v-if="session.reference"
            class="content__subtitle">
            Réf : <span class="font-bold">{{ session.reference }}</span>
          </h2>
        </div>
        <div
          v-if="session.commandeReference"
          class="flex flex-wrap items-center justify-between">
          <h2 class="content__subtitle">
            Réf commande :
            <span class="font-bold">{{ session.commandeReference }}</span>
          </h2>
        </div>
        <div
          v-if="!session.plateformeDureeAcces && session.moduleElearning"
          class="flex flex-wrap items-center justify-between">
          <h2
            class="content__subtitle"
            v-if="session.moduleElearning.dateDebutAcces">
            {{
              `Accessible du ${session.moduleElearning.dateDebutAcces} ${session.moduleElearning.dateFinAcces ? `au ${session.moduleElearning.dateFinAcces}` : ''}`
            }}
          </h2>
        </div>

        <div
          class="flex flex-wrap items-center justify-between"
          v-if="session.plateformeDureeAcces && session.moduleElearning">
          <h2 class="content__subtitle">
            {{
              `Accessible pendant ${session.plateformeDureeAcces} jour${session.plateformeDureeAcces > 1 ? 's' : ''} ${!session.moduleElearning.showRetractation ? `(du ${session.moduleElearning.dateDebutAcces} ${session.moduleElearning.dateFinAcces ? `au ${session.moduleElearning.dateFinAcces}` : ''})` : ''}`
            }}
          </h2>
        </div>

        <div class="content__description">
          <div class="description__left">
            <div
              class="mb-4 flex flex-wrap items-center space-x-4 text-core-700">
              <div
                v-if="session.dureeMinutes || session.dureeJours"
                class="flex items-center space-x-1.5">
                <font-awesome-icon v-bind:icon="['far', 'clock']" />
                <span>
                  {{ formatMinutesToHours(session.dureeMinutes) }}&nbsp;({{
                    session.dureeJours
                  }}j)
                </span>
              </div>

              <div
                v-else
                v-on:click="() => go_to_details(session.reference)"
                class="flex flex-grow cursor-pointer flex-wrap items-center justify-end text-base text-core-500 hover:text-core-600">
                <font-awesome-icon icon="search" />
                <span class="ml-2">Afficher les détails de la session</span>
              </div>
            </div>

            <div
              v-if="
                session.lieuNom ||
                (session.sessionDates && session.sessionDates.length)
              "
              class="my-2 flex flex-wrap border-l-4 border-primary-800 bg-core-100 px-5 py-4 text-base text-primary-800 shadow-md">
              <div
                v-if="session.lieuNom"
                class="w-full">
                <span class="mb-1 w-6">
                  <font-awesome-icon
                    icon="map-marker-alt"
                    size="1x" />
                </span>

                <span class="mx-2 align-middle font-bold">
                  {{ session.lieuNom }}
                </span>
              </div>

              <div
                v-if="session.sessionDates && session.sessionDates.length"
                class="w-full">
                <span class="w-6">
                  <font-awesome-icon
                    v-bind:icon="['far', 'calendar-alt']"
                    size="1x" />
                </span>

                <span class="mx-2 align-middle font-bold">
                  {{ displayDates(session.sessionDates).join(', ') }}
                </span>
              </div>
            </div>
            <div
              class="mt-4 flex w-full"
              v-if="
                participant &&
                !session.produit &&
                session.elearning &&
                question_enabled
              ">
              <div
                v-on:click="open_elearning_question_modal"
                class="white-space-normal relative flex cursor-pointer items-center space-x-4 rounded bg-blue-600 px-6 py-2.5 text-base font-bold leading-none text-white shadow hover:bg-opacity-90">
                <font-awesome-icon
                  icon="user-friends"
                  size="lg" />
                <span>
                  {{
                    question_modal_anwsers_count(session) == 2 ||
                    (question_modal_questions_count(session) > 0 &&
                      session.elearningQuestion &&
                      !session.elearningQuestion.autoriserQuestion)
                      ? 'Visualiser les réponses reçues sur le module e-learning'
                      : 'Poser une question sur le module e-learning'
                  }}
                </span>
                <div
                  v-if="question_modal_anwsers_count(session)"
                  class="absolute -right-1.5 -top-1.5 flex h-5 w-5 items-center justify-center rounded-full bg-jinius-red text-xs font-bold text-white">
                  {{ question_modal_anwsers_count(session) }}
                </div>
              </div>
            </div>
            <div
              v-if="session.commentaires"
              class="mt-4">
              <message-box
                v-bind:source="session.commentaires"
                border_color="w-full border-gray-500" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { convertStringToDate } from '@/assets/data/ag_grid_fields_map';
  import {
    map_statut_fiche_colors,
    map_statut_fiche_labels,
    map_statut_session_colors,
    map_statut_session_labels,
    map_type_colors
  } from '@/assets/data/data_maps.js';
  import MessageBox from '@/components/utils/message-box';
  import dates_helpers from '@/mixin/dates_helpers';

  export default {
    components: { MessageBox },
    mixins: [dates_helpers],
    props: {
      session: {
        type: Object,
        default: null
      },
      parcours: {
        type: Boolean,
        default: false
      },
      head_list: {
        type: Boolean,
        default: false
      },
      participant: Boolean,
      go_to_details: Function,
      open_elearning_question_modal: Function
    },
    data() {
      return {
        map_type_colors,
        map_statut_session_colors,
        map_statut_session_labels,
        map_statut_fiche_labels,
        map_statut_fiche_colors
      };
    },
    computed: {
      question_enabled() {
        const { elearningQuestion } = this.session;

        return (
          elearningQuestion &&
          (elearningQuestion.questions.length > 0 ||
            elearningQuestion.autoriserQuestion)
        );
      }
    },
    methods: {
      question_modal_anwsers_count(session) {
        const { elearningQuestion } = session;

        return (
          elearningQuestion &&
          elearningQuestion.questions &&
          elearningQuestion.questions.filter((q) => q.reponse).length
        );
      },
      question_modal_questions_count(session) {
        const { elearningQuestion } = session;

        return (
          elearningQuestion &&
          elearningQuestion.questions &&
          elearningQuestion.questions.length
        );
      },
      type_anchor_color(id) {
        return `border-${map_type_colors[id]}`;
      },
      type_bg_color(id) {
        return `bg-${map_type_colors[id]}`;
      },
      statut_bg_color(typeId, id) {
        return typeId && typeId === 2
          ? `bg-${map_statut_fiche_colors[id]}`
          : `bg-${map_statut_session_colors[id]}`;
      },
      statut_label(typeId, id) {
        return typeId && typeId === 2
          ? map_statut_fiche_labels[id]
          : map_statut_session_labels[id];
      },
      displayDates(dates) {
        const sortedDates = dates
          .slice()
          .sort(
            (a, b) =>
              convertStringToDate(a.date).getTime() -
              convertStringToDate(b.date).getTime()
          );

        return sortedDates.map(
          (date) =>
            `${date.date}${date.matin && !date.aprem ? ' (matin)' : ''}${!date.matin && date.aprem ? ' (après-midi)' : ''}`
        );
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/css/utils/mixins.scss';

  .session-card {
    @apply mb-4 flex overflow-hidden rounded-lg bg-white shadow;

    &.session-card--head-list {
      @apply shadow-lg;
    }

    .card__container {
      @apply relative flex w-full items-center;

      .card__container--anchor {
        @apply absolute left-0 top-0 z-10 h-10 w-12 border-l-8 border-t-8;
      }

      .container__content {
        @apply relative h-full w-full bg-white py-5 pl-4 pr-3 tracking-tight shadow;

        .content__type {
          @apply absolute right-0 top-0 mr-2 mt-2 flex space-x-2;
          .type__label {
            @apply rounded-full px-3 py-0.5 text-xs font-bold lowercase text-core-100 shadow first-letter:capitalize;
          }
        }

        .content__title {
          @apply mb-2 mt-4 px-4 text-xl font-bold leading-tight;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          @supports (-webkit-line-clamp: 2) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }

        .content__subtitle {
          @apply my-0 px-4 text-base text-accent-500;
        }

        .content__description {
          @apply mt-2 flex flex-wrap items-end justify-end text-sm;

          .description__left {
            @apply flex-grow self-start pl-5 pr-3;
          }

          .description__right {
            @apply px-4;
          }
        }
      }
    }
  }
</style>
