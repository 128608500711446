<template>
  <formation-libre-list-wrapper
    v-bind:key="loading"
    v-bind:formations_list="formations"
    title="Formations Libres"
    no_result="Vous n'avez aucune formation libre."
    current_space="stagiaireCAC"
    v-bind:loading="loading" />
</template>
<script>
  import FormationLibreListWrapper from '@/components/utils/formation-libre-list-wrapper.vue';
  import { API } from '@/http-common';

  export default {
    components: { FormationLibreListWrapper },
    props: ['props'],
    data: () => ({
      loading: false,
      formations: null
    }),
    created() {
      this.fetchFormationLibres();
    },
    methods: {
      async fetchFormationLibres() {
        this.loading = true;
        const response = await API.get(
          'stage/stagiaire-cac/formations-libres',
          this.$header_skip_redirection
        );

        this.formations = response.data;
        this.loading = false;
      }
    }
  };
</script>
