<template>
  <refonte-modal
    v-bind:show="props.show"
    v-on:close="close"
    title="Ajouter un contact">
    <template v-slot:modalBody>
      <form v-on:submit.prevent="submit">
        <refonte-input
          placeholder="Nom"
          input_class="w-full"
          v-bind:maxlength="100"
          v-bind:value="userForm.nom"
          v-on:input="setUserForm($event, 'nom')"
          v-bind:required="true"
          v-bind:error="v$.userForm.nom.$error" />
        <refonte-input
          placeholder="Prénom"
          input_class="w-full"
          v-bind:maxlength="100"
          v-bind:value="userForm.prenom"
          v-on:input="setUserForm($event, 'prenom')"
          v-bind:required="true"
          v-bind:error="v$.userForm.prenom.$error" />
        <refonte-input
          placeholder="Email"
          input_class="w-full"
          v-bind:maxlength="100"
          v-bind:value="userForm.email"
          v-on:input="setUserForm($event, 'email')"
          v-bind:required="true"
          v-bind:error="v$.userForm.email.$error"
          error_message="Format invalide" />
      </form>
    </template>
    <template v-slot:modalFooter>
      <refonte-button
        variant="text"
        v-bind:on_click="close">
        Annuler
      </refonte-button>
      <refonte-button v-bind:on_click="submit"> Ajouter </refonte-button>
    </template>
  </refonte-modal>
</template>

<script setup lang="ts">
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import RefonteModal from '@/components/refonte/modals/refonte-modal.vue';
  import RefonteInput from '@/components/refonte/inputs/refonte-input.vue';
  import { computed, ref } from 'vue';
  import { useVuelidate } from '@vuelidate/core';
  import { email, required } from '@vuelidate/validators';

  const props = withDefaults(
    defineProps<{
      show: boolean;
      on_close: Function;
      on_submit?: Function;
      class?: string;
    }>(),
    {
      class: ''
    }
  );

  const userForm = ref<{
    nom?: string;
    prenom?: string;
    email?: string;
  }>({});

  const rules = computed(() => ({
    userForm: {
      nom: {
        required
      },
      prenom: {
        required
      },
      email: {
        email,
        required
      }
    }
  }));

  const v$ = useVuelidate(rules, { userForm });

  function close() {
    props.on_close();
    userForm.value = {};
    v$.value.$reset();
  }
  function setUserForm(value: string, name: 'nom' | 'prenom' | 'email') {
    userForm.value[name] = value;
  }
  function submit(e: Event) {
    e.preventDefault();
    v$.value.$touch();
    if (!v$.value.$invalid) {
      props.on_submit && props.on_submit(userForm.value);
      close();
    }
  }
</script>

<style lang="scss" scoped></style>
