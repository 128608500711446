<template>
  <div class="rgpd_content">
    <div class="rgpd_content__title">
      Politique de protection des données à caractère personnel
    </div>
    <div class="rgpd_content__block">
      SAFIR est un outil de gestion de la formation à destination des
      professions d’expert-comptable et de commissaire aux comptes mis en place
      par le Groupe d’Intérêt Economique (GIE) SAFIR dont le siège social est
      200-216 rue Raymond Losserand – 75680 Paris cedex 14.
    </div>
    <div class="rgpd_content__block">
      Cet outil de gestion de formation est mis à disposition des entités
      juridiques afin qu’elles puissent proposer leurs formations aux
      utilisateurs. Ces entités juridiques sont :
      <ul>
        <li>
          le Centre de Formation de la Profession Comptable (CFPC) dont le siège
          social est situé Immeuble Le Jour – 200-216 rue Raymond Losserand –
          75680 Paris cedex 14, représenté par Monsieur Laurent BENOUDIZ
          agissant en qualité de Président ;
        </li>
        <li>
          la CNCC services, dont le siège social est situé Immeuble Le Jour –
          200-216 rue Raymond Losserand – 75680 Paris cedex 14, représentée par
          Monsieur Yannick OLLIVIER agissant en sa qualité de Gérant ;
        </li>
        <li>
          les Instituts régionaux de formations (IRF) des Conseils régionaux de
          l’ordre des experts-comptables (CROEC) ;
        </li>
        <li>les Compagnies régionales des commissaires aux comptes (CRCC) ;</li>
        <li>
          Les formateurs délivrant des prestations de formation auprès des
          participants JINIUS.
        </li>
      </ul>
    </div>
    <div class="rgpd_content__block">
      En tant qu’éditeur de plateforme, le GIE concède à ces exploitants (dont
      la liste est précisée dans le présent document), le droit d’exploiter, par
      le biais de l’interface « Jinius » des données dites partagées. Ces
      données impliquent une responsabilité de traitement collective des
      exploitants de la plateforme.
    </div>
    <div class="rgpd_content__block">
      L’utilisateur du site consent à l’exploitation de ses données par les
      exploitants.
    </div>
    <div class="rgpd_content__block">
      Ces exploitants s’engagent à respecter la règlementation en vigueur
      applicable au traitement de données à caractère personnel et en
      particulier Règlement (UE) 2016/679 du Parlement européen et du Conseil du
      27 avril 2016 (ci-après RGPD), applicable à compter du 25 mai 2018, ainsi
      que la loi n°78-17 du 6 janvier 1978 dans sa version modifiée.
    </div>

    <div class="rgpd_content__section">
      <div class="rgpd_content__sub-title">1. Définitions</div>
      <div class="rgpd_content__block">
        Une donnée à caractère personnel : toute information relative à une
        personne physique identifiée identifiable directement ou indirectement à
        partir de cette donnée. Le traitement : toute opération ou ensemble
        d’opérations portant sur des données personnelles, quel que soit le
        procédé utilisé.
      </div>
      <div class="rgpd_content__block">
        Le Responsable conjoint de traitement : deux responsables du traitement
        (personnes morales) ou plus qui déterminent conjointement les finalités
        et les moyens du traitement.
      </div>
      <div class="rgpd_content__block">
        Les utilisateurs : sont les participants aux formations
        (experts-comptables, commissaires aux comptes et collaborateurs de
        cabinets, experts-comptables stagiaires), animateurs, concepteurs et
        formateurs qui accèdent à l’outil Jinius en créant leur espace privé.
      </div>
      <div class="rgpd_content__block">
        Les exploitants : le CFPC, la CNCC Services, les IRF, les CRCC, les
        prestataires informatiques et les sous-traitants.
      </div>
      <div class="rgpd_content__block">
        Le service : décrit la mise à disposition de l’outil de gestion de
        formation Jinius :
        <ul>
          <li>
            La mise à disposition des supports de formation des animateurs ;
          </li>
          <li>
            La mise à disposition des supports de formation des participants ;
          </li>
          <li>Inscription et paiement en ligne aux formations proposées ;</li>
          <li>
            La mise à disposition des données de satisfaction animateurs et
            participants ;
          </li>
          <li>
            La mise à disposition des données de validation des acquis des
            participants.
          </li>
        </ul>
      </div>
      <div class="rgpd_content__block">Jinius : Front office.</div>
      <div class="rgpd_content__block">Safir : Back office.</div>
    </div>
    <div class="rgpd_content__section">
      <div class="rgpd_content__sub-title">2. Les données collectées :</div>
      <div class="rgpd_content__sub-section">
        <div class="rgpd_content__sub-title">
          2.1 Les informations des utilisateurs recueillies sont :
        </div>
        <div class="rgpd_content__block">
          Informations personnelles :
          <ul>
            <li>Civilité ;</li>
            <li>Nom ;</li>
            <li>Prénom(s) ;</li>
            <li>Date de naissance.</li>
          </ul>
        </div>
        <div class="rgpd_content__block">
          Informations professionnelles :
          <ul>
            <li>Intitulé du poste ;</li>
            <li>Société ;</li>
            <li>Niveau de formation ;</li>
            <li>Rattachement CRCC ;</li>
            <li>N° adhérent ANECS et CEJECS ;</li>
            <li>
              N° d’identification à l’ordre (supra) pour les experts-comptables
              stagiaires ;
            </li>
            <li>N° d’identification AGLAE propre au stagiaire CAC.</li>
          </ul>
        </div>
        <div class="rgpd_content__block">
          Les informations de contact :
          <ul>
            <li>Adresse ;</li>
            <li>Numéros de téléphone ;</li>
            <li>Adresse de courrier électronique ;</li>
            <li>
              Données relatives aux transactions : numéro de transaction, détail
              de l’achat, du bien ou du service souscrit ;
            </li>
            <li>
              Services souscrits, quantité, montant, périodicité, adresse de
              livraison, historique de relation contractuelle, origine de la
              vente ou de la commande.
            </li>
          </ul>
        </div>
        <div class="rgpd_content__block">
          Les personnes concernées :
          <ul>
            <li>
              Propriétaire du compte quel que soit le profil d’utilisation :
              <ul>
                <li>Participants (jinius) ;</li>
                <li>Animateurs (jinius) ;</li>
                <li>Concepteurs (jinius) ;</li>
                <li>Administrateurs entreprise (jinius) ;</li>
                <li>Responsable de formation (jinius) ;</li>
                <li>Formateurs (jinius) ;</li>
                <li>Stagiaires (jinius) ;</li>
                <li>Contrôleurs de stage (jinius) ;</li>
                <li>Maitres de stage (jinius) ;</li>
                <li>Gestionnaires SAFIR (SAFIR) ;</li>
                <li>Administrateur entité SAFIR (SAFIR) ;</li>
                <li>
                  CFPC et CNCC-Formation (SAFIR – Périmètre des multi-entités) :
                  comptes des utilisateurs ;
                </li>
                <li>
                  Super administrateur multi-entités (Equipe support/projet)
                  (SAFIR).
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="rgpd_content__block">
          Ces données sont « collectives », c’est à dire qu’elles sont partagées
          entre les exploitants organismes de formation : chaque exploitant
          organisme de formation utilise en effet les mêmes fiches contacts.
        </div>
        <div class="rgpd_content__block">
          Ces fiches contacts sont insérées dans la base partagée SAFIR et sont
          partagées entre les exploitants organismes de formation. Les
          modifications faites par un exploitant organisme de formation
          s’appliquent automatiquement dans la base et s’imposent aux autres
          exploitants organismes de formation.
        </div>
        <div class="rgpd_content__block">
          En cas de demande de rectification ou d’effacement des données à
          caractère personnel par l’utilisateur, ces données à caractère
          personnel sont anonymisées dans la fiche contact et cette version
          restera visible pour tous les exploitants organismes de formation.
        </div>
      </div>
      <div class="rgpd_content__sub-section">
        <div class="rgpd_content__sub-title">
          2.2 La liste des exploitants qui auront accès aux données partagées :
        </div>
        <div class="rgpd_content__block">
          <ul>
            <li>
              Gestionnaires SAFIR (SAFIR-périmètre entité) : toutes les entités
              IRF, CRCC, CNCC, CFPC ;
            </li>
            <li>
              Administrateur entité SAFIR (SAFIR-périmètre entité) : toutes les
              entités IRF, CRCC, CNCC ;
            </li>
            <li>
              Gestionnaire et administrateurs CFPC et CNCC Formation (SAFIR
              -Périmètre des multi-entité) ;
            </li>
            <li>Xperteam ;</li>
            <li>
              Super administrateur multi-entités (Equipe support/projet) : CNCC
              Services / Substantiel / Reeliant
            </li>
          </ul>
        </div>
      </div>
      <div class="rgpd_content__sub-section">
        <div class="rgpd_content__sub-title">
          2.3 La liste des sous-traitants :
        </div>
        <div class="rgpd_content__block">
          <ul>
            <li>Reeliant ;</li>
            <li>Hébergeur SAFIR ;</li>
            <li>Send In Blue ;</li>
            <li>Xperteam ;</li>
            <li>GO to Webinar ;</li>
            <li>Monex ;</li>
            <li>Istya ;</li>
            <li>Substantiel Conseil.</li>
          </ul>
        </div>
        <div class="rgpd_content__block">
          Les responsables de traitement pourront être amenés à faire appel à
          d’autres sous-traitants pour mener les activités de traitement liées à
          l’utilisation de la plateforme.
        </div>
        <div class="rgpd_content__block">
          Les responsables de traitement veilleront à ce que le(s)
          sous-traitant(s) respecte(nt) la règlementation en vigueur applicable
          au traitement de données à caractère personnel, et en particulier, le
          règlement (UE) 2016/679 du Parlement européen et du Conseil du 27
          avril 2016 applicable à compter du 25 mai 2018 (« RGPD »), ainsi que
          la loi n°78-17 du 6 janvier 1978 dans sa version modifiée.
        </div>
        <div class="rgpd_content__block">
          Le(s) sous-traitant(s) mette(nt) en œuvre les mesures techniques et
          organisationnelles appropriées ayant pour objectif d’empêcher la
          destruction accidentelle ou illicite, la perte accidentelle,
          l’altération ou la communication ou l’accès non autorisé, de manière
          accidentelle ou illicite, des/aux données ainsi que contre toute autre
          forme de traitement illicite ou communication de données à des
          personnes non autorisées. Ces mesures portent notamment sur la
          sécurité et la confidentialité des données mais également sur
          l’organisation, la formation et la sensibilisation et toute mesure à
          même d’assurer le respect du RGPD.
        </div>
      </div>
    </div>
    <div class="rgpd_content__section">
      <div class="rgpd_content__sub-title">
        3. Les finalités du traitement des données :
      </div>
      <div class="rgpd_content__sub-section">
        <div class="rgpd_content__sub-title">
          3.1 Finalités principales de Jinius :
        </div>
        <div class="rgpd_content__block">
          <ul>
            <li>
              Permettre aux participants et aux formateurs l’accès à leurs
              informations de formations passées et à venir ainsi qu’aux
              contenus des formations en présentiels ou en ligne.
            </li>
            <li>
              Fournir aux cabinets un suivi de la gestion des formations de
              leurs collaborateurs (espace entreprise).
            </li>
          </ul>
        </div>
      </div>
      <div class="rgpd_content__sub-section">
        <div class="rgpd_content__sub-title">
          3.3 Finalités de traitement opérées par les exploitants qui ont accès
          aux données partagées :
        </div>
        <div class="rgpd_content__block">
          <ul>
            <li>
              Les données du compte Jinius sont partagés par les différentes
              entités SAFIR afin de bénéficier d’une mise à jour collective des
              informations de contacts : gains de temps et amélioration de la
              qualité des données de la base contact.
            </li>
          </ul>
        </div>
      </div>
      <div class="rgpd_content__sub-section">
        <div class="rgpd_content__sub-title">
          3.4 Finalités principales du traitement opéré par l’équipe projet et
          support technique :
        </div>
        <div class="rgpd_content__block">
          <ul>
            <li>Gestion des incidents</li>
            <li>Administration des comptes utilisateurs</li>
            <li>Tests et recettes avant mise en production</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="rgpd_content__section">
      <div class="rgpd_content__sub-title">
        4. Le lieu d’hébergement de vos données :
      </div>
      <div class="rgpd_content__block">
        <table class="w-full table-auto">
          <thead>
            <tr class="bg-core-300 text-core-900">
              <th class="border border-core-300 px-4 py-2">Applications</th>
              <th class="border border-core-300 px-4 py-2">Objet</th>
              <th class="border border-core-300 px-4 py-2">Hébergeur</th>
              <th class="border border-core-300 px-4 py-2">
                Lieu d’hébergement
              </th>
            </tr>
          </thead>
          <tbody class="text-core-600">
            <tr>
              <td class="border border-core-300 px-4 py-2">SAFIR</td>
              <td class="border border-core-300 px-4 py-2">
                Gestion des formations – back Office
              </td>
              <td class="border border-core-300 px-4 py-2">LBN / Accenture</td>
              <td class="border border-core-300 px-4 py-2">France</td>
            </tr>
            <tr>
              <td class="border border-core-300 px-4 py-2">Jinius</td>
              <td class="border border-core-300 px-4 py-2">
                Gestion des formations – Front Office
              </td>
              <td class="border border-core-300 px-4 py-2">LBN / Accenture</td>
              <td class="border border-core-300 px-4 py-2">France</td>
            </tr>
            <tr>
              <td class="border border-core-300 px-4 py-2">Send In Blue</td>
              <td class="border border-core-300 px-4 py-2">
                Envoie de mail et notification
              </td>
              <td class="border border-core-300 px-4 py-2">Google Cloud</td>
              <td class="border border-core-300 px-4 py-2">Union Européenne</td>
            </tr>
            <tr>
              <td class="border border-core-300 px-4 py-2">Xperteam</td>
              <td class="border border-core-300 px-4 py-2">Plateforme LMS</td>
              <td class="border border-core-300 px-4 py-2">Solution SaaS</td>
              <td class="border border-core-300 px-4 py-2">France</td>
            </tr>
            <tr>
              <td class="border border-core-300 px-4 py-2">Go TO Webinar</td>
              <td class="border border-core-300 px-4 py-2">
                Plateforme d’accès aux webinaires
              </td>
              <td class="border border-core-300 px-4 py-2">Solution SaaS</td>
              <td class="border border-core-300 px-4 py-2">Union Européenne</td>
            </tr>
            <tr>
              <td class="border border-core-300 px-4 py-2">MONEX</td>
              <td class="border border-core-300 px-4 py-2">
                Transaction paiement par CB
              </td>
              <td class="border border-core-300 px-4 py-2">Solution SaaS</td>
              <td class="border border-core-300 px-4 py-2">Union Européenne</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="rgpd_content__block">
        Les données à caractère personnel sont stockées sur des hébergeurs
        situés sur le territoire de l’Union Européenne et ne font pas l’objet
        d’un transfert hors de l’Union Européenne.
      </div>
      <div class="rgpd_content__block">
        En tout état de cause, si des transferts venaient à être réalisés en
        dehors de l’Union européenne, les responsables de traitement et les
        sous-traitants s’assureront qu’ils soient limités aux pays listés par la
        Commission Européenne comme ayant un niveau de protection adéquat à
        celui de l’Union européenne. Ces transferts seront encadrés conformément
        au chapitre V du RGPD.
      </div>
    </div>
    <div class="rgpd_content__section">
      <div class="rgpd_content__sub-title">
        5. La durée de conservation de vos données :
      </div>
      <div class="rgpd_content__block">
        Les données sont conservées, pour chacun des traitements énoncés dans la
        présente Politique, uniquement pour la durée nécessaire au regard de la
        finalité poursuivie par le traitement correspondant.
      </div>
      <div class="rgpd_content__block">
        <span class="underline">
          Traitement n°1 - Gestion des inscriptions :
        </span>
        <br />
        Durée de conservation : pendant toute la durée de la relation
        contractuelle puis 5 ans à compter de la cessation de la relation avec
        l’utilisateur.
      </div>
      <div class="rgpd_content__block">
        <span class="underline">
          Traitement n°2 - Gestion des paiements :
        </span>
        <br />
        Durée de conservation : 10 ans à compter de la clôture de l’exercice
        auquel les factures se rattachent.
      </div>
      <div class="rgpd_content__block">
        <span class="underline">
          Traitement n°3 - Gestion des réclamations et impayés :
        </span>
        <br />
        Durée de conservation : conservation pendant le temps nécessaire à la
        gestion du contentieux après la connaissance d’un litige, jusqu’à la
        prescription de l’action.
      </div>
      <div class="rgpd_content__block">
        <span class="underline">
          Traitement n°4 - Suivi qualité des participants aux formations et des
          formateurs afin de permettre l’amélioration des formations proposées
          via les questionnaires de satisfaction :
        </span>
        <br />
        Durée de conservation : 5 ans à compter de la fin de la session de
        formation.
      </div>
    </div>
    <div class="rgpd_content__section">
      <div class="rgpd_content__sub-title">6. Vos droits sur vos données :</div>
      <div class="rgpd_content__block">
        Vous disposez du droit :
        <ul>
          <li>D’accéder à vos données personnelles ;</li>
          <li>
            Le cas échéant, si vos données ne sont pas à jour, de les faire
            rectifier ;
          </li>
          <li>
            De retirer votre consentement si nous l’avons recueilli et qu’aucun
            autre fondement juridique ne justifie le traitement des données.
          </li>
        </ul>
      </div>
      <div class="rgpd_content__block">
        Dans les cas et limites prévus par le RGPD, vous pouvez également :
        <ul>
          <li>
            Vous opposer, pour des motifs légitimes (ou même sans avoir à
            justifier d’un tel motif, lorsque vos données sont utilisées à des
            fins de prospection), à l’utilisation de vos données ;
          </li>
          <li>Faire supprimer certaines données vous concernant ;</li>
          <li>Faire limiter l’usage de certaines de vos données ;</li>
          <li>
            Solliciter la portabilité de vos données que vous avez communiquées,
            afin qu’elles puissent être transmises à un tiers.
          </li>
        </ul>
      </div>
      <div class="rgpd_content__block">
        L’exercice de ces droits auquel aura été joint une copie de la pièce
        d’identité, peut être réalisé en écrivant à l'adresse suivante :
        protection.donnees@jinius.fr ou par voie postale à l'adresse suivante :
        GIE SAFIR - 200-216 rue Raymond Losserand – 75680 Paris cedex 14
      </div>
    </div>
    <div class="rgpd_content__section">
      <div class="rgpd_content__sub-title">
        7. Les mesures de sécurité et confidentialité mise en place
      </div>
      <div class="rgpd_content__block">
        Les mesures techniques de sécurité et de confidentialité actuellement
        mises en place sont les suivantes :
        <ul>
          <li>
            Utilisation de HTTPS avec toutes les bonnes mesures et bonnes
            pratiques associées suivant un haut niveau de qualité (impliquant le
            non-support des navigateurs web anciens)
          </li>
          <li>Utilisation d'OIDC pour l'authentification</li>
          <li>
            Gestion des cookies de sessions sur les domaines les plus restreints
            possibles pour éviter au maximum leur diffusion
          </li>
          <li>
            Vérification permanente des vulnérabilités de toutes les dépendances
            des applications
          </li>
          <li>
            Stratégie globale de non-traçage des utilisateurs, notamment par des
            dispositifs et services externes (Google Analytics, etc.), pour
            éviter la fuite d'informations et les vulnérabilités apportées par
            ces outils.
          </li>
        </ul>
      </div>
    </div>
    <div class="rgpd_content__section">
      <div class="rgpd_content__sub-title">
        8. Les cookies présents sur le site :
      </div>
      <div class="rgpd_content__block">
        Conformément à
        <a
          class="cursor-pointer underline"
          href="https://www.cnil.fr/fr/cookies-et-traceurs-que-dit-la-loi"
          target="_blank"
          >https://www.cnil.fr/fr/cookies-et-traceurs-que-dit-la-loi</a
        >
        pour toutes les applications de Jinius nous ne sommes pas tenus
        d'informer ni de recueillir le consentement des utilisateurs concernant
        l'utilisation de cookies traceurs ou de tout autre dispositif de
        traçage, car aucune des applications de Jinius ne fait de traçage
        d'aucune manière que ce soit. Seul des cookies strictement nécessaires
        au fonctionnement des applications sont utilisés.
      </div>
    </div>
    <div class="rgpd_content__section">
      <div class="rgpd_content__sub-title">
        9. Les obligations des responsables de traitement :
      </div>
      <div class="rgpd_content__block">
        Les membres du GIE, liés par une Convention d’usage, reconnaissent
        qu’ils ont une responsabilité collective sur les données à caractère
        personnel partagées.
      </div>
      <div class="rgpd_content__block">
        Cette responsabilité collective emporte un certain nombre d’obligations
        et notamment de veiller au respect des obligations édictées par le RGPD,
        en amont et pendant la durée du traitement.
      </div>
    </div>
  </div>
</template>

<script>
  export default {};
</script>

<style lang="scss" scoped>
  .rgpd_content {
    @apply w-full lg:p-6;
  }
  .rgpd_content__block {
    @apply mb-4 mt-8 pl-3 text-justify text-xl first-letter:uppercase;
  }
  .rgpd_content__title {
    @apply mb-4 mt-8 text-justify text-3xl font-bold first-letter:uppercase;
  }

  .rgpd_content__sub-title {
    @apply mb-4 mt-8 text-justify text-xl font-bold first-letter:uppercase;
  }
</style>
