<template>
  <div>
    <modal-box
      v-if="show_update_msg"
      width_class="max-w-screen-md w-full"
      v-on:close="reportNextUpdate()">
      <template v-slot:modal-header>
        <h1 class="text-xl text-core-700">Mise à jour de l'application</h1>
      </template>

      <template v-slot:modal-body>
        <div class="p-6 text-xl">
          <p class="mb-4">
            Une version plus récente de cette application est disponible.
          </p>
          <p class="font-bold">Souhaitez-vous mettre à jour ?</p>
        </div>
      </template>

      <template v-slot:modal-footer>
        <div class="inline-flex w-full justify-end space-x-4">
          <div
            class="w-64 cursor-pointer rounded bg-primary-900 px-5 py-3 text-center font-bold text-white shadow hover:bg-primary-800"
            v-on:click="refreshToUpdateApp()">
            Mettre à jour (Recommandé)
          </div>
          <div
            v-on:click="reportNextUpdate()"
            class="w-64 cursor-pointer rounded bg-core-300 px-5 py-3 text-center font-bold text-core-700 shadow hover:bg-core-200">
            Annuler
          </div>
        </div>
      </template>
    </modal-box>
  </div>
</template>

<script>
  import modalBox from '@/components/utils/modal-box.vue';
  import { checkUpdate } from '@/vue_app_check_update.js';
  export default {
    components: { modalBox },
    data() {
      return { show_update_msg: false };
    },
    created() {
      this.checkIsAppUpdated();
    },
    methods: {
      reportNextUpdate() {
        const delay = 1 * 60 * 1000; // 1min

        this.checkIsAppUpdated(delay);
        this.show_update_msg = false;
      },
      refreshToUpdateApp() {
        return location.reload(true);
      },
      checkIsAppUpdated(delay) {
        const initial_delay = delay;

        checkUpdate(
          import.meta.env.VITE_VERSION,
          () => (this.show_update_msg = true),
          initial_delay
        );
      }
    }
  };
</script>

<style lang="scss" scoped></style>
