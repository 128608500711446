import { map_domain_per_env } from '@/assets/data/data_maps.js';

const cdn_url = `https://cdn.${
    map_domain_per_env[import.meta.env.VITE_CONTEXT]
}`;

export const faq = [
    {
        id: 'connexion',
        titre: 'se connecter à --jinius--',
        ressources: [
            {
                url: `${cdn_url}/aide-en-ligne/Guides Jinius_ Se connecterAJinius_R4V1.1.pdf`,
                label: 'Guide Jinius - Comment  se connecter  à Jinius ?',
                fa_icon: ['far', 'file-pdf']
            }
        ],
        rubrique: [
            {
                question: 'comment  se connecter  à Jinius ? ',

                reponse:
                    'en vous connectant sur le catalogue en ligne de votre interlocuteur de formation habituel , vous avez accès en haut à droite au menu "Se connecter ". Il vous sera proposé de créer votre compte.'
            },

            {
                question:
                    "peut-on créer un compte générique d'équipe dans Jinius",

                reponse:
                    'votre compte Jinius doit être individuel et nominatif car il sert à vous transmettre vos documents personnels liés à vos formations pendant tout votre parcours professionnel. Vous devez donc pouvoir vous connecter à titre individuel à Jinius'
            },

            {
                question: 'comment puis-je réinitialiser mon mot de passe ? ',

                reponse:
                    'il vous suffira de cliquer sur "mot de passe oublié" lors de votre connexion et vous recevrez un mail à votre adresse mail de connexion, vous cliquer sur le lien du mail et vous allez pouvoir réinitialiser votre "mot de passe"'
            },

            {
                question:
                    "j'ai créé mon compte Jinius mais je n'arrive pas à accéder aux formations ? ",

                reponse:
                    "en vous connectant sur le catalogue en ligne de votre interlocuteur de formation habituel, vous pourrez accéder aux différentes formations proposées. Lorsque vous créez votre compte Jinius, un nouvel onglet s'ouvre dans votre navigateur. Le catalogue de formation reste accessible dans un onglet déjà ouvert de votre navigateur"
            },

            {
                question:
                    "j'ai créé mon compte Jinius mais je ne vois pas dans mon espace stagiaire ? ",

                reponse:
                    "si vous êtes stagiaire, vous avez du recevoir un mail vous indiquant comment vous connecter à votre espace stagiaire. Vérifiez bien l'adresse mail avec laquelle vous avez créé votre compte, elle doit correspondre à celle communiquée à votre service de stage afin de faire le lien avec le suivi de vos formations"
            },

            {
                question:
                    "j'ai créé mon compte Jinius mais je ne vois pas l'espace entreprise ? ",

                reponse:
                    "si vous êtes une entreprise, la connexion se fera avec le mail déclaré par votre administrateur lors de la création de votre espace entreprise. Si vous êtes une personne habilitée à passer des commandes au nom de l'entreprise, il vous suffit de vous connecter avec l'adresse mail déclarée par votre administrateur."
            },

            {
                question:
                    "si je change d'entreprise , comment changer l'adresse mail de mon compte Jinius ? ",

                reponse:
                    'vous pouvez modifier votre mot de passe Jinius en accédant à la rubrique informations personnelles de votre compte Jinius. Pour cela dans le menu "se connecter", sélectionner "mon compte". Vous pourrez modifier votre adresse mail dans l\'onglet  "informations personnelles&nbsp;/&nbsp;informations de contact"'
            },

            {
                question:
                    "si je change de cabinet est-ce que je garde l'historique de mes formations ?",

                reponse:
                    "oui vous gardez l'historique des formations que vous avez suivi dans votre espace participant "
            }
        ]
    },
    {
        id: 'espace_participant',
        titre: 'mon espace participant',
        ressources: [
            {
                url: `${cdn_url}/aide-en-ligne/Guides Jinius_ Espace Participants_R4V1.1.pdf`,
                label: 'Guide Jinius - Espace Participant',
                fa_icon: ['far', 'file-pdf']
            }
        ],
        rubrique: [
            {
                question:
                    "j'ai créé mon compte Jinius mais je ne vois pas  mon espace participant ? ",

                reponse:
                    'si vous êtes participant, vérifier si votre cabinet à passé une première commande en utilisant la même adresse que celle utilisée lors de la création de votre compte Jinius. Vérifiez également que vous avez complété votre société dans vos informations professionnelles.'
            },

            {
                question:
                    'je suis inscrit à une formation mais je ne trouve pas les informations de convocation ?',
                reponse: `<p>Allez dans votre espace <strong>PARTICIPANT</strong>, dans l'onglet Mes sessions/sessions prochaines. Positionnez vous&nbsp;sur la session concernée. Si elle est au statut <strong>"confirmé"</strong>,&nbsp;&nbsp;cliquez sur "<em>Afficher les&nbsp;détails de la session</em>" pour disposer :</p><ul><li>des informations d'organisation détaillées,</li><li>de votre convocation,</li><li>selon le type de formation des accès en distanciel,</li><li>des supports de formations selon les règles de mises à disposition.</li></ul><p><span class="fontColorNeutralSecondary">Si votre inscription est encore au statut "<strong>en attente"</strong> , vous aurez seulement accès à la fiche pédagogique.&nbsp;</span></p>
                <p>Si un test de positionnement est demandé, vous devrez l'avoir complété pour que la formation puisse être confirmée</p>`
            },
            {
                question: 'où trouvez mon attestation de formation ?',

                reponse: `<p>une fois que la session est terminée, allez dans votre espace participant, dans l'onglet "mes sessions/sessions terminées", sélectionnez la session concernée et cliquez sur "afficher les détails de la session".</p>

                <p>un bouton vous permettra de télécharger votre attestation,  si elle a été mise à disposition par votre interlocuteur de formation. À noter que le test de validation des acquis complété peut être une condition pour que l'attestation puisse vous être délivrée.</p>`
            },
            {
                question: 'où trouver mon test de validation des acquis ?',

                reponse:
                    'le test de validation des acquis se trouve dans l\'onglet "mes sessions&nbsp;/&nbsp;sessions terminées", allez sur la session concernée et cliquez sur "afficher les détails de la session'
            },
            {
                question:
                    'une fois dans mon espace Jinius, comment trouver mon examen ? ',

                reponse:
                    'une fois connecté, cliquez sur «sessions terminées » puis sur « afficher les détails de la session" relatif à votre session de formation'
            },

            {
                question: 'où trouver les supports de formation ? ',

                reponse: `<p>les supports de formation sont mis à disposition uniquement pour les sessions au statut "confirmé" et selon des délais variables en fonction du type de formation ou des règles définies par l'organisateur de la formation.</p>

                <p>Vous accédez à vos supports de formation dans mes sessions / prochaines sessions ou sessions terminées ",  sélectionnez la session concernée et cliquez sur "afficher les détails de la session". Si les supports sont disponibles, ils apparaitront dans la rubrique ressources et vous pourrez les télécharger.</p>

                <p>À noter, le tableau détaillé affiche le délai de disponibilité de chaque support de formation.</p>`
            },

            {
                question:
                    "j'ai réalisé mon module de e-learning mais je n'ai toujours pas accès au support de la formation en présentiel associée ?",

                reponse: `<p>les supports de formation des présentiels liés à un e-learning sont mis à disposition 6 jours avant la tenue du présentiel. Ils sont disponibles dans  mes sessions&nbsp;/&nbsp;prochaines sessions ".</p>
                
                <p>Sélectionnez la formation concernée et cliquez sur "afficher les détails de la session".</p>

                <p>Si les supports sont disponibles, ils apparaitront dans la rubrique ressources et vous pourrez les télécharger.</p>

                <p>À noter, les supports de formation des e-learning effectués sur l'ancienne plateforme de e-learning doivent être demandés à votre organisateur de formation.</p>`
            },

            {
                question: 'comment accéder aux formations en distanciel ? ',

                reponse: `<p>les liens d'accès aux formations en distanciel sont activables uniquement pour les formations dont les inscriptions sont confirmées. Vous trouverez le lien dans "mes sessions&nbsp;/&nbsp;prochaines sessions",  sélectionnez la session concernée et cliquez sur "afficher les détails de la session".</p>

                <p>Pour les formations aux e-learning, l'accès n'est possible qu'à partir de l'enregistrement du règlement de la commande par l'organisateur de la formation. L'accès sera visible au niveau de la liste de vos formations dans l'onglet "mes sessions/prochaines sessions" et également dans le détail des formations confirmées en cliquant sur "afficher les détails de la session".</p>`
            },
            {
                question:
                    'comment faire pour modifier le lieu ou la date de formation ? ',

                reponse:
                    "les modifications d'inscription correspondent à un changement dans la commande déjà passée. Veuillez vous rapprocher des personnes habilitées à effectuer des inscriptions dans votre société qui contactera son interlocuteur de formation."
            }
        ]
    },
    {
        id: 'espace_entreprise',
        titre: 'mon espace entreprise',
        ressources: [
            {
                url: `${cdn_url}/aide-en-ligne/Guides Jinius_ Espace Entreprise - Cration espace entreprise_R4V1.1.pdf`,
                label: 'Guide Jinius - Création de mon espace entreprise',
                fa_icon: ['far', 'file-pdf']
            },
            {
                url: `${cdn_url}/aide-en-ligne/Guides Jinius_ Espace Entreprise - Inscrire des participants_R4V1.1.pdf`,
                label: 'Guide Jinius - Comment inscrire des participants ?',
                fa_icon: ['far', 'file-pdf']
            }
        ],
        rubrique: [
            {
                question:
                    "j'ai créé mon compte Jinius mais je ne vois pas l'espace entreprise ? ",

                reponse:
                    "si vous êtes une entreprise, la connexion se fera avec le mail déclaré par votre administrateur lors de la création de votre espace entreprise. Si vous êtes une personne habilitée à passer des commandes au nom de l'entreprise, il vous suffit de vous connecter avec l'adresse mail déclarée par votre administrateur."
            },

            {
                question:
                    "je suis administrateur et je n'arrive pas à accéder à mon espace entreprise ",

                reponse:
                    "si vous avez créé l'espace entreprise avec votre adresse mail professionnelle, vérifiez que vous vous connectez avec votre compte Jinius avec cette même adresse."
            },

            {
                question:
                    'où se trouve ma convention et comment faire pour la retourner signée ?',

                reponse: `<p>Allez&nbsp;dans votre espace entreprise, sélectionnez&nbsp;la session concernée et cliquez sur "<em>Afficher les&nbsp;détails de la session</em>". Vous pourrez cliquer sur "détail" dans la colonne "convention" ,vous aurez deux lignes:</p>

                <ul><li>dans la rubrique "<span class="fontColorNeutralPrimary"><strong>convention à signer</strong></span>: télécharger et signer votre convention</li><li>dans la rubrique "<span class="fontColorNeutralPrimaryAlt"><strong>convention signée</strong></span>": <span class="fontColorNeutralPrimary">téléverser&nbsp;</span>votre convention signée</li></ul>

                <p><span class="fontColorNeutralSecondary">NB: cette opération s'effectue participant par participant si vous avez plusieurs inscrits.</span></p>`
            },
            {
                question:
                    "je suis Responsable de formation, et je ne retrouve pas l'attestation de formation de mes inscrits",

                reponse:
                    '<p>L’attestation est mise à disposition du participant dans son « espace participant ».<br>En revanche, en tant que gestionnaire formation du cabinet, vous avez accès au <strong>certificat de réalisation</strong> après la tenue de la session de formation.&nbsp;&nbsp;Vous la&nbsp;trouverez dans votre espace ENTREPRISE/ INSCRIPTIONS/&nbsp;Sessions Terminées en cliquant sur&nbsp;"<em>Afficher les&nbsp;détails de la session"</em></p>'
            },
            {
                question:
                    "je suis Responsable de formation, puis-je ajouter des participants qui n'ont pas encore créé leur compte Jinius ?",

                reponse: `<p>Vous pouvez ajouter,&nbsp;modifier ou supprimer des collaborateurs de votre entreprise en allant dans l'onglet MON ENTREPRISE / ANNUAIRE</p>

                <p>Pour ajouter un collaborateur, cliquez sur&nbsp; "Ajouter un contact" . S'il&nbsp;n'a&nbsp;pas encore de compte Jinius, il&nbsp;recevra un mail automatique à l'adresse que vous aurez déclarée l'invitant&nbsp;à se connecter à Jinius</p>

                <p>Vous pourrez également ajouter un participant sans compte Jinius lors de l'inscription en saisissant son adresse mail. Veillez à ce qu'il utilise cette même adresse lorsqu'il créera son compte Jinius.&nbsp;</p>`
            },
            {
                question:
                    "je suis responsable de formation, d'une organisation multi-entités qui évolue. Comment réaffecter les participants dans les bonnes entités ?",

                reponse: `<p>Vous devez être administrateur pour pouvoir créer ou désactiver une entreprise sous Jinius.&nbsp;Vous pouvez vous déclarer comme administrateurs Jinius de plusieurs entités&nbsp;avec le même compte Jinius. Dans ce cas, veillez à sélectionner la bonne entité en haut de votre espace lorsque vous effectuez des modifications.&nbsp;</p> 

                <p>Vous pouvez ajouter de nouvelles entités à partir de leur N° de SIRET</p> 
                <ol class='pt-6 pl-4 space-y-1 list-inside list-decimal'>
                    <li>créer la nouvelle entité dans Jinius en initiant une inscrption à partir du catalogue en ligne</li>
                    
                    <li>déclarez vous comme administrateur de la nouvelle entité</li>
                    
                    <li>déclarer les droits des personnes habilitées dans la nouvelle entité</li>
                    
                    <li>dès que votre espace entreprise est créé, ajouter les participants de la nouvelle entité en allant dans l'onglet mon&nbsp;entreprise&nbsp;/&nbsp;annuaire </li>
                    
                    <li>
                        sélectionner l'entité qui doit être modifiée ou désactivée&nbsp;:
                            <ul class='list-inside'>
                                <li>si vous êtes responsable de formation ou administrateur vous pourrez supprimer les participants que vous avez affectés à la nouvelle entité</li>
                                <li>si vous êtes administrateur, vous pourrez désactiver complètement l'entité qui n'a plus lieu d'être : onglet mon&nbsp;entreprise&nbsp;/&nbsp;informations generale puis cliquer sur</li>
                            </ul>
                    </li>
                </ol>`
            },
            {
                question:
                    "pourquoi me demande t'on si je veux autoriser une autre entité à  choisir ma société comme entité de facturation ?",

                reponse:
                    'dans certaines organisations de cabinet en réseau, le siège peut par exemple être en charge de  commander des formations, alors que ce sont les filiales qui sont facturées. Dans ce cas par exemple , chaque filiale va autoriser le siège à les choisir comme entité de formation.'
            }
        ]
    },
    {
        id: 'espace_stagiaire',
        titre: 'mon espace stagiaire (Expert-Comptable)',
        ressources: [
            {
                url: `${cdn_url}/aide-en-ligne/Guides Jinius_ Espace Stagiaire EC_R4V1.pdf`,
                label: 'Guide Jinius - Espace Stagiaire',
                fa_icon: ['far', 'file-pdf']
            }
        ],
        rubrique: [
            {
                question:
                    "j'ai créé mon compte Jinius mais je ne vois mon espace stagiaire ? ",

                reponse:
                    "si vous êtes stagiaire, vous avez du recevoir (ou allez recevoir) un mail vous indiquant comment vous connecter à votre espace stagiaire.  Vérifiez bien l'adresse mail avec laquelle vous avez créé votre compte, elle doit correspondre à celle communiquée à votre service de stage afin de faire le lien avec le suivi de vos formations stage."
            },
            {
                question:
                    "j'avais déjà un compte Jinius de créé mais pas avec mon adresse mail de stagiaire. Que dois faire ?",

                reponse:
                    "si vous souhaitez gérer toutes vos formations stage et formations professionnelles à partir d'un même compte Jinius, veuillez nous contacter à assistance@Jinius.fr pour effectuer la fusion de vos comptes. "
            },
            {
                question:
                    "j'ai reçu un lien pour m'inscrire aux formations mais je n'ai pas les droits pour m'inscrire aux formations de mon année de stage",

                reponse:
                    "les droits d'inscription aux formations sont définis par l'administrateur Jinius de votre cabinet ou les personnes habilitées. Vous pouvez vous inscrire directement si votre cabinet ou entreprise vous donne les droits dans Jinius. Sinon, veuillez leur transmettre votre lien d'inscription. Vous devrez être identifié avec votre adresse mail stagiaire dans l'annuaire de l'entreprise sous Jinius."
            },

            {
                question: 'où puis-je trouver ma convocation dans mon espace ?',

                reponse:
                    'vous trouverez votre convocation dans mes sessions/prochaines sessions, cliquez sur lien "afficher le détail de la session", dans la rubrique "ressources" '
            },

            {
                question:
                    "j'ai réalisé tous les modules sur l'ancienne plateforme, mais je ne retrouve pas l'avancement de mes modules effectués sur mon espace stagiaire Jinius",

                reponse: `<p>Les modules des cycles passés sont mis à votre disposition dans votre espace afin que vous puissiez y accéder à tout moment.</p>

                <p>Si vous avez effectué votre e-learning avant le 19 mars 2023 sur l'ancienne plateforme, le suivi de votre avancement est bien enregistré par votre service de stage.</p><br>
                <p>Sinon nous vous invitons à faire (ou à terminer) le e-learning concernée à partir de votre espace participant Jinius. Puis de faire copie d'écran de la progression du cours concerné en allant dans Mes formation/Formations terminées. Vous pourrez communiquer ce justificatif de réalisation à votre service de stage pour l'enregistrement de votre suivi et à votre IRF pour obtenir le support de formation lié au présentiel si besoin.</p>`
            },
            {
                question:
                    "j'ai crée mon compte stagiaire et j'accède à mon espace stagiaire mais je n'arrive pas à lancer mon e-learning. Que dois-je faire ?",

                reponse:
                    "le pif stage doit être téléchargé , signé et téléversé par vos soins afin de débloquer l'accès au e-learning du stage."
            },

            {
                question:
                    'mon module est en statut "en cours" alors que je l\'ai terminé ? ',

                reponse:
                    'nous vous invitons à vérifier si tous les chapitres de votre module sont "terminés" sinon  votre module restera en statut en "en cours".'
            }
        ]
    },
    {
        id: 'espace_formateur',
        titre: 'mon espace formateur',
        ressources: [
            {
                url: `${cdn_url}/aide-en-ligne/Guides Jinius_ Espace Formateur_R4V1.1.pdf`,
                label: 'Guide Jinius - Espace Formateur',
                fa_icon: ['far', 'file-pdf']
            }
        ],
        rubrique: [
            {
                question:
                    "j'ai créé mon compte Jinius mais je ne vois pas mon espace formateur ? ",

                reponse:
                    'votre espace formateur apparait dès lors que vous êtes confirmé à une première formation par votre interlocuteur de formation. Vous verrez alors les formations sur lesquels vous avez été retenu.'
            },

            {
                question:
                    'où puis-je trouver la synthèse des questionnaires de satisfaction ? ',

                reponse:
                    '<p>La synthèse des appréciations s\'affiche une fois que les participants auront renseigné leurs questionnaires de satisfaction participants. La synthèse de satisfaction de la session animée que vous avez animée est téléchargeable dans l\'onglet "mes sessions&nbsp;/&nbsp;sessions terminées" , en cliquant sur "afficher les détails de la session".</p>'
            },

            {
                question:
                    'où trouver les supports de mes prochaines formations ? ',

                reponse: `<p>les supports de formations sont mis à disposition uniquement pour les sessions au statut confirmée . Le délais de mise à disposition avant ou après la session de formation peut varier en fonction du type de formation ou de l'organisme de la formation.</p>

                <p>vous accédez à vos supports de formation dans "mes sessions&nbsp;/&nbsp;prochaines sessions" ou "sessions terminées ". Sélectionnez  la session concernée et cliquez sur "afficher les détails de la session". Si les supports sont disponibles ils apparaitront dans la rubrique ressources et vous pourrez les télécharger.</p>

                <p>À noter, le tableau détaillé affiche le délai de disponibilité de chaque support de formation.</p>`
            },
            {
                question: 'comment accéder aux formations en distanciel ? ',

                reponse:
                    'les liens d\'accès aux formations en distanciel sont activables uniquement pour les formations dont les sessions sont confirmées. Vous trouverez le lien dans "mes sessions&nbsp;/&nbsp;prochaines sessions",  sélectionnez la session concernée et cliquez sur "afficher les détails de la session".'
            },

            {
                question: "où trouvez mon attestation d'animation ?",

                reponse: `une fois que la session est terminée, se rendre dans votre espace formateur, dans l'onglet "mes sessions&nbsp;/&nbsp;sessions terminées", aller sur la session concernée et cliquez sur "afficher les détails de la session".

un bouton vous permettra de télécharger votre attestation d'animation,  si elle a été mise à disposition par l'organisateur de la formation.
`
            },
            {
                question:
                    'je ne retrouve pas la fiche de présence dans mon espace',

                reponse:
                    'la fiche de présence apparait dans votre espace formateur 24h avant la formation dans l\'onglet "mes sessions&nbsp;/&nbsp;sessions terminées". À noter que les fiches de présence à compléter sont proposées dans le cas où les fiches n\'auraient pas pu être mises à disposition dans les salles de formation.'
            }
        ]
    },
    {
        id: 'espace_maitre_stage',
        titre: 'mon espace controleur de stage',
        ressources: [
            {
                url: `${cdn_url}/aide-en-ligne/Guides Jinius_ Espace Contrleur Stage EC_R4V1.pdf`,
                label: 'Guide Jinius - Espace Contrôleur de Stage',
                fa_icon: ['far', 'file-pdf']
            }
        ]
    },
    {
        id: 'espace_maitre_stage',
        titre: 'mon espace maitre de stage',
        ressources: [
            {
                url: `${cdn_url}/aide-en-ligne/Guides Jinius_ Espace Maitre de stage EC_R4V1.pdf`,
                label: 'Guide Jinius - Espace Maitre de Stage',
                fa_icon: ['far', 'file-pdf']
            }
        ]
    }
];
