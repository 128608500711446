<template>
  <div class="p-[8px]">
    <template v-if="interlocuteurs.length">
      <refonte-table
        key="interlocuteurs-1"
        table_index="interlocuteurs-1"
        v-bind:data_etablissement="
          activeEntrepriseIds.length !== 1 ? 'clientDenomination' : undefined
        "
        v-bind:data_etablissement_siret="
          activeEntrepriseIds.length !== 1 ? 'clientSiret' : undefined
        "
        v-bind:columns="columns"
        v-bind:data="interlocuteurs" />
    </template>
    <refonte-search-results-empty v-else />
  </div>
  <refonte-modal-confirmation-suppression
    v-bind:show="isConfirmationSuppressionModalOpen"
    v-bind:on_close="() => closeConfirmationSuppressionModal()"
    v-bind:on_submit="() => delete_interlocuteur(selectedInterlocuteur)"
    title="Supprimer cet interlocuteur"
    v-bind:description="confirmationSuppressionModalDescription" />
</template>

<script setup lang="ts">
  import RefonteTable from '@/components/refonte/tables/refonte-table.vue';
  import { useInterlocuteurStore } from '@/stores/interlocuteurs';
  import { storeToRefs } from 'pinia';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';
  import { Interlocuteur } from '@/types/gestion/interlocuteurs-types';
  import IconValidate20Green from '@/assets/img/refonte/icons/information/icon-validate-20-green.svg?component';
  import IconFailed20Red from '@/assets/img/refonte/icons/information/icon-failed-20-red.svg?component';
  import IconModify from '@/assets/img/refonte/icons/action/icon-modify.svg?component';
  import IconClose from '@/assets/img/refonte/icons/display/icon-close.svg?component';
  import { useEntrepriseStore } from '@/stores/entreprise';
  import RefonteModalConfirmationSuppression from '@/components/refonte/modals/refonte-modal-confirmation-suppression.vue';
  import { ref } from 'vue';

  const props = withDefaults(
    defineProps<{
      update_interlocuteur: Function;
      delete_interlocuteur: Function;
      class?: string;
    }>(),
    {
      class: ''
    }
  );

  const columns = [
    {
      content_index: 'interlocuteurNomPrenom',
      label: 'Interlocuteur',
      min_width: 150,
      width: 180
    },
    {
      content_index: 'fonctionLabel',
      label: 'Fonction',
      min_width: 140,
      width: 170
    },
    {
      content_index: 'administrateurLabel',
      label: 'Administrateur',
      min_width: 113,
      width: 120,
      content_icon: (row: Interlocuteur) =>
        row.administrateurLabel === 'Oui'
          ? IconValidate20Green
          : IconFailed20Red
    },
    {
      content_index: 'droitInscriptionLabel',
      label: "Droit d'inscription",
      min_width: 97,
      width: 140,
      content_icon: (row: Interlocuteur) =>
        row.droitInscriptionLabel === 'Oui'
          ? IconValidate20Green
          : IconFailed20Red
    },
    {
      content_index: 'accesDocumentsLabel',
      label: 'Accès aux documents',
      min_width: 85,
      width: 160,
      content_icon: (row: Interlocuteur) =>
        row.accesDocumentsLabel === 'Oui'
          ? IconValidate20Green
          : IconFailed20Red
    },
    {
      content_index: 'telephone',
      label: 'Téléphone',
      width: 100
    },
    {
      content_index: 'email',
      label: 'E-mail',
      width: 176,
      class: () => 'table-item-with-content-word-break'
    },
    {
      content_index: 'modifiable',
      content_button_icon: (row: Interlocuteur) => IconModify,
      content_button_on_click: (row: Interlocuteur) =>
        props.update_interlocuteur(row),
      content_button_variant: (row: Interlocuteur) => 'success',
      content_button_title: (row: Interlocuteur) =>
        row.modifiable ? 'Modifier' : 'Modification impossible',
      content_button_disabled: (row: Interlocuteur) => !row.modifiable,
      width: 32
    },
    {
      content_index: 'supprimable',
      content_button_icon: (row: Interlocuteur) => IconClose,
      content_button_on_click: (row: Interlocuteur) => {
        confirmationSuppressionModalDescription.value = `Vous êtes sur le point de supprimer ${row.interlocuteurNomPrenom} rattaché à ${row.clientDenomination}. Voulez-vous continuer ?`;
        openConfirmationSuppressionModal(row);
      },
      content_button_variant: (row: Interlocuteur) => 'danger',
      content_button_title: (row: Interlocuteur) =>
        row.supprimable ? 'Supprimer' : 'Suppression impossible',
      content_button_disabled: (row: Interlocuteur) => !row.supprimable,
      width: 40
    }
  ];

  const interlocuteurStore = useInterlocuteurStore();

  const { interlocuteurs } = storeToRefs(interlocuteurStore);

  const entrepriseStore = useEntrepriseStore();

  const { activeEntrepriseIds } = storeToRefs(entrepriseStore);

  const isConfirmationSuppressionModalOpen = ref<boolean>(false);
  const confirmationSuppressionModalDescription = ref<string>();

  function openConfirmationSuppressionModal(row: Interlocuteur) {
    isConfirmationSuppressionModalOpen.value = true;
    selectedInterlocuteur.value = row;
  }
  function closeConfirmationSuppressionModal() {
    isConfirmationSuppressionModalOpen.value = false;
    confirmationSuppressionModalDescription.value = undefined;
  }
  const selectedInterlocuteur = ref<Interlocuteur>();
</script>

<style lang="scss" scoped></style>
