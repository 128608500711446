<template>
  <modal-box
    v-if="modal_show"
    width_class="w-120"
    v-on:close="close"
    v-bind:footer="false"
    overflow_body>
    <template v-slot:modal-header>
      <h1 class="text-xl">Personnes autorisées</h1>
    </template>

    <template v-slot:modal-body>
      <div v-if="loading">
        <spinner
          sizes="w-20 h-20"
          thickness="border-8" />
      </div>
      <template v-else>
        <template v-if="interlocutors.length">
          <div class="mb-4 text-lg font-bold">
            Liste des personnes autorisées à passer commande au nom de la
            société&nbsp;:
          </div>
          <ul>
            <template
              v-for="(interlocutor, index) in interlocutors"
              v-bind:key="`participant-${index}`">
              <li>
                {{ firstLetterCapitalize(interlocutor.prenom) }}
                {{ interlocutor.nom.toUpperCase() }} ({{ interlocutor.email }})
              </li>
            </template>
          </ul>
        </template>
        <template v-else>
          <div class="mb-4 text-lg">Aucune personne n'est autorisé.</div>
        </template>
      </template>
    </template>
  </modal-box>
</template>
<script>
  import modalBox from '@/components/utils/modal-box.vue';
  import spinner from '@/components/utils/spinner';
  import { API } from '@/http-common';
  import helpers_filters from '@/mixin/helpers_filters.js';
  import { createNamespacedHelpers } from 'vuex';
  const { mapState: mapStateClient } = createNamespacedHelpers('client');

  export default {
    components: {
      modalBox,
      spinner
    },
    mixins: [helpers_filters],
    props: {
      modal_show: Boolean,
      onclose: Function
    },
    data: () => ({
      loading: false,
      interlocutors: []
    }),
    computed: { ...mapStateClient(['profile']) },
    async created() {
      await this.fetchInterlocutors();
    },
    methods: {
      close() {
        this.onclose();
      },
      async fetchInterlocutors() {
        this.loading = true;
        const response = await API.get(
          `client/${this.profile.activeEntrepriseId}/contacts?droitInscription=true`,
          this.$header_skip_redirection
        );

        if (response && response.data) {
          this.interlocutors = response.data;
        }
        this.loading = false;
      }
    }
  };
</script>
<style lang="scss" scoped></style>
