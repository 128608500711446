<template>
  <modal-box
    v-if="modal_declaration"
    width_class="w-full max-w-screen-xl"
    bg_class="bg-core-200"
    v-on:close="onclose(!not_declared)"
    v-bind:footer="true"
    v-bind:overflow_body="true"
    no_click_outside>
    <template v-slot:modal-header>
      <h1 class="text-xl font-bold text-primary-800">
        Se déclarer comme Administrateur
      </h1>
    </template>
    <template v-slot:modal-body>
      <div class="w-full px-4">
        <div v-if="not_declared">
          <div v-if="entite.administrateurEnAttente">
            <message-box
              v-bind:source="`
              <p class='font-bold'>
                Les droits d'accès à votre plateforme d'inscription évoluent.
              </p>
              <p>
                Il est nécessaire de bien reconfirmer vos droits afin de poursuivre.
              </p>
              `"
              icon="warning"
              padding="space-x-4 px-8 py-6"
              border_color="border-jinius-red"
              font_style="text-lg text-jinius-red" />
          </div>
          <admin-form-upload
            v-bind:entite="entite"
            v-on:changed_declaration="updateDeclaration" />
        </div>
      </div>
    </template>

    <template v-slot:modal-footer>
      <div
        v-if="not_declared"
        class="flex w-full justify-center space-x-4">
        <button
          class="primary__button disabled:cursor-not-allowed disabled:bg-accent-400 disabled:hover:bg-accent-400"
          v-bind:disabled="!checkbox_declaration"
          v-on:click.prevent="sendDeclaration">
          Valider et poursuivre
        </button>
      </div>
    </template>
  </modal-box>
</template>

<script>
  import adminFormUpload from '@/components/utils/admin-form-upload.vue';
  import messageBox from '@/components/utils/message-box.vue';
  import modalBox from '@/components/utils/modal-box.vue';
  import { API } from '@/http-common';

  export default {
    components: {
      modalBox,
      messageBox,
      adminFormUpload
    },
    props: ['modal_declaration', 'entite', 'refresh_entites', 'onclose'],
    data() {
      return { checkbox_declaration: null };
    },
    computed: {
      not_declared() {
        return (
          (!this.entite.statutAdminValide &&
            !this.entite.droitAdministrateur) ||
          !this.entite.statutAdminValide
        );
      }
    },
    methods: {
      updateDeclaration(value) {
        this.checkbox_declaration = value;
      },
      async sendDeclaration() {
        const body = { consentement: true };

        try {
          await API.post(
            `client/${this.entite.entrepriseId}/administrateur/declaration`,
            body,
            this.$header_skip_redirection
          );
          this.refresh_entites();
          this.onclose();
        } catch (e) {
          const message =
            e.response.data.message ||
            'Une erreur inconnue est survenue, veuillez re-essayer ultérieurement';

          this.$toast.open({
            type: 'error',
            message
          });
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .primary__button {
    @apply min-w-[16rem] cursor-pointer rounded bg-primary-900 px-8 py-4 text-center font-bold text-white shadow hover:bg-primary-700;
  }

  .secondary__button {
    @apply min-w-[16rem] cursor-pointer rounded bg-core-300 px-5 py-4 text-center font-bold text-core-700 shadow hover:bg-core-200;
  }
</style>
