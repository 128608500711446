<template>
  <modal-box
    v-if="show_modal && content"
    v-on:close="onclose"
    v-bind:width_class="'w-full max-w-screen-xl'"
    v-bind:modal_body_class="'bg-core-100'"
    v-bind:footer="false"
    overflow_body>
    <template v-slot:modal-header>
      <div class="my-3 space-y-5">
        <h1 class="mb-2 text-3xl font-bold">
          <span>{{ content.prenom }} {{ content.nom }}</span>
          <span
            class="font-normal italic text-accent-500"
            v-if="content.email">
            - {{ content.email }}
          </span>
        </h1>
        <p
          class="mb-0 ml-1 border-l-2 border-core-500 pl-5 text-xl font-bold text-core-500">
          <template v-if="content.cycleIstya || content.cycleStageCAC">
            Suivi du cycle {{ content.cycleIstya || content.cycleStageCAC }}
          </template>
          <template v-else-if="content.anneeStageCAC">
            {{ content.anneeStageCAC }}
          </template>
        </p>
      </div>
    </template>

    <template v-slot:modal-body>
      <session-stagiaire-wrapper
        v-bind:sessions_list="content.sessions"
        v-bind:current_space="current_space"
        v-bind:loading="loading" />
    </template>
  </modal-box>
</template>
<script>
  import modalBox from '@/components/utils/modal-box.vue';
  import sessionStagiaireWrapper from '@/components/utils/session-stagiaire-wrapper.vue';

  export default {
    components: {
      modalBox,
      sessionStagiaireWrapper
    },
    props: ['show_modal', 'onclose', 'content', 'current_space'],
    data: () => ({ loading: false })
  };
</script>
<style lang="scss" scoped></style>
