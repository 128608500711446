<template>
  <div class="w-full lg:h-full">
    <spinner
      v-if="loading"
      sizes="w-20 h-20"
      thickness="border-8" />

    <div
      class="h-full w-full"
      v-if="!loading">
      <h1 class="section__title">Candidatures en cours</h1>
      <div class="flex h-full w-full flex-wrap-reverse lg:flex-wrap">
        <indicateurs v-bind:candidatures="candidatures" />
        <div class="mb-12 w-full">
          <ag-grid-wrapper
            v-bind:exportable="true"
            v-bind:filename="'candidatures'"
            v-bind:column_defs="[
              { headerName: 'Ref.', field: 'reference' },
              { headerName: 'Statut', field: 'statut' },
              { headerName: 'Navettes', field: 'navetteNom' },
              {
                headerName: 'Titre',
                field: 'titre',
                cellRendererParams: {
                  onclick: (e, params) => goToFiche(e, params)
                }
              },
              { headerName: 'Parcours', field: 'parcoursTitre' },
              { headerName: 'Lieu', field: 'lieuNom' },
              { headerName: 'Date(s)', field: 'sessionDates', sort: 'desc' },
              { headerName: 'Mode', field: 'mode' },
              { headerName: 'Organisateur', field: 'organisateur' },
              {
                headerName: 'Annuler',
                field: 'annuler',
                pinned: 'left',
                cellRenderer: 'actionCellRenderer',
                cellRendererParams: {
                  label: 'Renoncer',
                  onclick: (e, params) => openWindow(e, params)
                },
                editable: false,
                sortable: false,
                suppressHeaderMenuButton: true
              }
            ]"
            v-bind:initial_data="candidatures" />
        </div>
      </div>

      <cancel-modal
        v-bind:fetch_candidatures="fetchCandidatures"
        v-bind:cancel_modal="cancelModal"
        v-bind:onclose="onCancelModalClose"
        v-bind:session="session" />
    </div>
  </div>
</template>
<script>
  import agGridWrapper from '@/components/utils/ag-grid/ag-grid-wrapper';
  import spinner from '@/components/utils/spinner.vue';
  import { API } from '@/http-common';
  import navigation_helpers from '@/mixin/navigation_helpers';
  import indicateurs from '@/views/_espace/_formateur/_candidatures/indicateurs.vue';
  import cancelModal from '@/views/_espace/_formateur/_candidatures/_modal/cancel-modal';

  export default {
    components: {
      cancelModal,
      agGridWrapper,
      spinner,
      indicateurs
    },
    mixins: [navigation_helpers],
    data: () => ({
      cancelModal: false,
      loading: false,
      session: {},
      candidatures: []
    }),
    created() {
      this.fetchCandidatures();
    },
    methods: {
      async fetchCandidatures() {
        this.loading = true;
        const response = await API.get(
          'formateur/candidatures',
          this.$header_skip_redirection
        );

        this.candidatures = response.data;
        this.loading = false;
      },
      onCancelModalClose() {
        this.cancelModal = false;
      },
      openWindow(event, params) {
        this.session = { ...params.data };
        this.cancelModal = true;
      },
      goToFiche(event, params) {
        this.openFiche(event, params);
      }
    }
  };
</script>
<style lang="scss" scoped>
  .section__title {
    @apply mb-8 mt-2 #{!important};
  }
</style>
