<template>
  <modal-box
    v-if="show_modal"
    v-bind:width_class="'w-full max-w-screen-md'"
    v-on:close="onclose">
    <template v-slot:modal-header>
      <h1 class="text-xl font-bold text-core-900">Droit de rétractation</h1>
    </template>

    <template v-slot:modal-body>
      <div class="p-3 text-accent-500">
        <p class="mb-1">Période de mon droit de rétractation&nbsp;:</p>
        <p class="px-0.5 text-sm font-bold">
          Du {{ parseToFrDate(module_infos.dateDebutRetractation) }} au
          {{ parseToFrDate(module_infos.dateFinRetractation) }}
        </p>
      </div>
      <div
        class="mx-auto my-8 flex w-full max-w-2xl items-center justify-center font-bold">
        <input
          type="checkbox"
          class="flex cursor-pointer"
          id="checkbox-consent"
          v-model="consent" />
        <label
          for="checkbox-consent"
          class="flex cursor-pointer items-center justify-center p-3 text-justify leading-none hover:text-core-900">
          Je renonce à mon droit de rétractation et accède immédiatement au
          module e-learning.
        </label>
      </div>
    </template>
    <template v-slot:modal-footer>
      <div class="inline-flex w-full justify-center space-x-4">
        <button
          class="primary__button"
          type="submit"
          v-on:click="open_elearning_module"
          v-bind:disabled="!consent">
          Accéder à la formation
        </button>
        <button
          class="secondary__button"
          type="button"
          v-on:click="onclose">
          Fermer
        </button>
      </div>
    </template>
  </modal-box>
</template>
<script>
  import modalBox from '@/components/utils/modal-box.vue';
  import { format, parse } from 'date-fns';
  import fr from 'date-fns/locale/fr';

  export default {
    components: { modalBox },
    props: ['show_modal', 'onclose', 'open_elearning_module', 'module_infos'],
    data: () => ({ consent: false }),
    methods: {
      close() {
        this.onclose();
      },
      parseToFrDate(date) {
        const parsed = parse(date, 'dd/MM/yyyy', new Date());
        const formated = format(parsed, 'EEEE d MMMM yyyy', { locale: fr });

        return formated;
      }
    }
  };
</script>
<style lang="scss" scoped>
  .primary__button {
    @apply w-64 cursor-pointer rounded bg-jinius-dark-green px-5 py-3 text-center font-bold text-white shadow hover:bg-jinius-green;
    &:disabled {
      @apply cursor-not-allowed border-core-400 bg-core-200 text-core-500;
    }
  }

  .secondary__button {
    @apply w-64 cursor-pointer rounded bg-core-300 px-5 py-3 text-center font-bold text-core-700 shadow hover:bg-core-200;
  }
</style>
