import { API } from '@/http-common.js';

export default {
    methods: {
        formatUrlProtocol(url) {
            const formattedUrl = url.replace(/ /g, '');

            return formattedUrl.match(/^(http|https)?:/)
                ? formattedUrl
                : 'http://' + formattedUrl;
        },
        async downloadFile(url, nom) {
            const downloadUrl = `${baseURLApi}/${url}`;
            const fileLink = document.createElement('a');

            fileLink.href = downloadUrl;
            fileLink.setAttribute('download', nom);
            document.body.appendChild(fileLink);
            const simulateClick = function (elem) {
                // bubbles: false to avoid closing modal on download
                const evt = new MouseEvent('click', { bubbles: false });

                elem.dispatchEvent(evt);
            };

            simulateClick(fileLink);
            document.body.removeChild(fileLink);
        },
        async downloadBlobFile(url, nom) {
            const response = await API.get(url, {
                responseType: 'blob',
                ...this.$header_skip_redirection
            });
            const fileURL = window.URL.createObjectURL(
                new Blob([response.data])
            );
            const fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', nom);
            document.body.appendChild(fileLink);
            fileLink.click();
        }
    }
};

const baseURLApi = import.meta.env.VITE_API_SAFIR;
