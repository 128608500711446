export default {
    methods: {
        openFiche(event, params) {
            const {
                catalogueUrl,
                catalogueReference,
                catalogueHash,
                cataloguePubliee
            } = params.data;

            if (
                catalogueUrl &&
                (!catalogueHash || cataloguePubliee) &&
                catalogueReference
            ) {
                window.open(`${catalogueUrl}/fiche/${catalogueReference}`);
            } else if (catalogueUrl && catalogueHash) {
                window.open(`${catalogueUrl}/fiche/preview/${catalogueHash}`);
            }
        }
    }
};
