<template>
  <modal-box
    v-if="cancel_modal"
    v-on:close="close"
    v-bind:width_class="'w-full md:max-w-2xl'">
    <template v-slot:modal-header>
      <h1 class="text-xl font-bold text-core-900">Renoncer à ma candidature</h1>
    </template>

    <template v-slot:modal-body>
      <div class="font-bold text-accent-500">
        Vous êtes sur le point d'annuler votre candidature à l'animation de la
        session&nbsp;:
      </div>

      <div class="p-4">
        <p class="mb-3 font-bold leading-tight text-jinius-blue">
          {{ `${session.titre}` }}
        </p>
        <p class="mb-1 font-bold">
          <font-awesome-icon
            v-bind:icon="['fas', 'tag']"
            class="mr-1" />
          {{ `${session.reference}` }}
        </p>
        <div class="ml-2 border-l border-accent-400 pl-4 font-bold">
          <div v-if="dates">
            <font-awesome-icon
              size="sm"
              v-bind:icon="['far', 'calendar-alt']"
              class="mr-2 text-accent-500" />
            <span class="">{{ dates }}</span>
          </div>
          <div v-if="session.lieuNom">
            <font-awesome-icon
              size="sm"
              icon="map-marker-alt"
              class="mr-2 text-accent-500" />
            <span class="">{{ session.lieuNom }}</span>
          </div>
        </div>
      </div>

      <div class="my-1 flex w-full font-bold text-jinius-red">
        Souhaitez-vous continuer ?
      </div>
    </template>

    <template v-slot:modal-footer>
      <div class="inline-flex w-full justify-center space-x-4">
        <button
          class="primary__button primary__button--cancel"
          v-on:click="cancelCandidature">
          Renoncer
        </button>
        <button
          class="secondary__button"
          v-on:click="close">
          Annuler
        </button>
      </div>
    </template>
  </modal-box>
</template>
<script>
  import modalBox from '@/components/utils/modal-box.vue';
  import { API } from '@/http-common';
  export default {
    components: { modalBox },
    props: ['cancel_modal', 'onclose', 'session', 'fetch_candidatures'],
    data: () => ({
      showMotivation: false,
      motivation: ''
    }),
    computed: {
      dates() {
        return (
          this.session &&
          this.session.sessionDates &&
          this.session.sessionDates.length &&
          this.session.sessionDates.map((date) => date.date).join(', ')
        );
      }
    },
    methods: {
      close() {
        this.onclose();
      },
      async cancelCandidature() {
        this.close();
        const body = { id: this.session.navetteCandidatureId };

        await API.post(
          'formateur/renoncer-candidature',
          body,
          this.$header_skip_redirection
        );
        await this.fetch_candidatures();
      }
    }
  };
</script>
<style lang="scss" scoped>
  .primary__button.primary__button--cancel {
    @apply w-64 cursor-pointer rounded bg-core-900 px-5 py-3 text-center font-bold text-white shadow hover:bg-jinius-red;
  }
  .secondary__button {
    @apply w-64 cursor-pointer rounded bg-core-300 px-5 py-3 text-center font-bold text-core-700 shadow hover:bg-core-200;
  }
</style>
