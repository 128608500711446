<template>
  <sessions-list-wrapper
    v-bind:key="loading"
    v-bind:sessions_list="sessionsAnimated"
    title="Sessions terminées"
    no_result="Vous n'avez aucune session terminée."
    has_positionnement_modal
    is_sessions_terminees
    current_space="participant"
    v-on:fetch-sessions="fetchSessions"
    v-bind:loading="loading" />
</template>
<script>
  import sessionsListWrapper from '@/components/utils/sessions-list-wrapper.vue';
  import { API } from '@/http-common';

  export default {
    components: { sessionsListWrapper },
    data: () => ({
      loading: false,
      sessionsAnimated: null
    }),
    created() {
      this.fetchSessions();
    },
    methods: {
      async fetchSessions() {
        this.loading = true;
        const response = await API.get(
          'participant/sessions-terminees',
          this.$header_skip_redirection
        );

        this.sessionsAnimated = response.data;
        this.loading = false;
      }
    }
  };
</script>
