import resolveConfig from 'tailwindcss/resolveConfig';
import { reactive } from 'vue';
import { default as tailwindConfig } from '../tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);
const trimHasNumber = (val) => Number(val.replace('px', ''));

const screens_conf = fullConfig.theme.screens;
const screens_list = Object.keys(screens_conf);

const screens = {};

screens_list.forEach((key) => {
    screens[key] = trimHasNumber(screens_conf[key]);
});

const getBreakpoint = (width) => {
    const arr_screens = Object.entries(screens);
    const screen_checked = arr_screens.find((e) => width <= e[1]);
    const breakpoint_name = screen_checked
        ? screen_checked[0]
        : arr_screens[arr_screens.length - 1][0];

    return breakpoint_name;
};
const breakpoints = reactive({
    w: window.innerWidth,
    h: window.innerHeight,
    is: getBreakpoint(window.innerWidth),
    screens
});

window.addEventListener('resize', () => {
    breakpoints.w = window.innerWidth;
    breakpoints.h = window.innerHeight;
    breakpoints.is = getBreakpoint(window.innerWidth);
});

export default breakpoints;
