<template>
  <div class="w-full lg:h-full">
    <spinner
      v-if="loading"
      sizes="w-20 h-20"
      thickness="border-8" />
    <div v-if="!loading">
      <ag-grid-wrapper
        v-bind:column_defs="[
          { headerName: 'Nom', field: 'nom', filter: 'agTextColumnFilter' },
          {
            headerName: 'Prénom',
            field: 'prenom',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'E-mail',
            field: 'email',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'Année de stage',
            field: 'cycleIstya',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'Statut',
            field: 'statut',
            filter: 'agTextColumnFilter',
            cellRenderer: 'statutCellRenderer'
          },
          {
            headerName: 'Contrôleur de stage',
            field: 'controleur',
            filter: 'agTextColumnFilter',
            cellRenderer: 'textCellRenderer'
          },
          {
            headerName: 'Accès e-learning',
            field: 'accesElearning',
            filter: 'agTextColumnFilter',
            cellRenderer: 'textCenterCellRenderer'
          },
          {
            headerName: 'suivi',
            field: 'id',
            cellRenderer: 'buttonCellRenderer',
            cellRendererParams: {
              label: 'Détails',
              onclick: (e, params) => openDetailsModal(params)
            },
            sortable: false
          }
        ]"
        v-bind:initial_data="stagiairesMaitre" />
      <stagiaire-details-modal
        v-bind:show_modal="showDetailsModal"
        v-bind:onclose="closeDetailsModal"
        v-bind:content="detailsModalContent"
        current_space="maitreDeStage" />
    </div>
  </div>
</template>
<script>
  import AgGridWrapper from '@/components/utils/ag-grid/ag-grid-wrapper';
  import Spinner from '@/components/utils/spinner.vue';
  import { API } from '@/http-common';
  import stagiaireDetailsModal from '@/views/_espace/_stage/_modal/stagiaireDetailsModal.vue';

  export default {
    components: {
      AgGridWrapper,
      Spinner,
      stagiaireDetailsModal
    },
    data: () => ({
      loading: false,
      stagiairesMaitre: null,
      showDetailsModal: false,
      detailsModalContent: null
    }),
    async created() {
      await this.fetchStagiaires();
    },
    methods: {
      async fetchStagiaires() {
        this.loading = true;
        const response = await API.get(
          '/stage/maitre/stagiaires',
          this.$header_skip_redirection
        );

        this.stagiairesMaitre = response.data.map((s) => ({
          ...s,
          accesElearning: s.accesElearning ? 'Oui' : 'Non'
        }));
        this.loading = false;
      },
      async openDetailsModal(params) {
        const response = await API.get(
          `/stage/maitre/stagiaires/${params.node.data.contactId}/sessions`,
          this.$header_skip_redirection
        );

        this.detailsModalContent = {
          ...params.node.data,
          sessions: response.data
        };
        this.showDetailsModal = true;
      },
      closeDetailsModal() {
        this.showDetailsModal = false;
        this.detailsModalContent = null;
      }
    }
  };
</script>
<style lang="scss" scoped></style>
