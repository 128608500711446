<template>
  <refonte-modal
    v-bind:show="props.show"
    v-on:close="props.on_close"
    title="Contact"
    class="modal-lg">
    <template v-slot:modalBody>
      <div class="flex flex-col gap-[12px]">
        <contact-card v-bind:current_session="contact" />
      </div>
    </template>
  </refonte-modal>
</template>

<script setup lang="ts">
  import RefonteModal from '@/components/refonte/modals/refonte-modal.vue';
  import contactCard from '@/components/utils/contact-card.vue';

  const props = withDefaults(
    defineProps<{
      show: boolean;
      on_close: Function;
      contact: Object;
      class?: string;
    }>(),
    {
      class: ''
    }
  );
</script>

<style lang="scss" scoped></style>
