<template>
  <div>
    <modal-box
      v-if="modal_show"
      ref="modal_test_examen"
      width_class="w-full max-w-6xl"
      overflow_body
      fill_height
      is_survey
      survey_color="orange-500"
      survey_label="Examen"
      v-bind:no_close_btn="!introduction"
      v-on:close="close"
      v-on:close-survey="onclose"
      no_outside_click>
      <template v-slot:modal-header>
        <h1 class="px-6 text-3xl font-bold text-core-600">
          {{ session.titre }}
          <div
            class="mt-2 w-full border-l-4 pl-3 text-lg font-normal leading-none">
            {{ displayDates(session.sessionDates).join(', ') }}
          </div>
        </h1>

        <div
          v-if="!introduction && !reponse"
          class="flex w-full items-center justify-end px-10">
          <font-awesome-icon
            icon="clock"
            size="2x"
            v-bind:class="`${timesUp ? 'text-jinius-red' : 'text-core-600'}`" />
          <div
            v-bind:class="`ml-4 font-bold ${timesUp ? 'text-2xl text-jinius-red' : 'text-lg text-core-600'}`">
            {{ formattedTime }}
          </div>
        </div>
      </template>

      <template v-slot:modal-body>
        <template v-if="loading">
          <spinner
            sizes="w-20 h-20"
            thickness="border-8" />
        </template>
        <template v-else>
          <div
            v-if="reponse"
            class="space-y-8 p-4">
            <div
              v-if="timeExpired"
              class="my-4 text-2xl font-bold">
              Le temps imparti pour compléter l'examen est écoulé. Vos réponses
              ont été enregistrées.
            </div>
            <div class="my-4 text-2xl font-bold">Résultat</div>

            <div v-if="reponse && reponse.reussi">
              <message-box
                v-if="reponse.texteSiAcquis"
                v-bind:source="reponse.texteSiAcquis"
                border_color="w-full border-yellow-500"
                font_style="text-lg" />
            </div>

            <div v-if="reponse && !reponse.reussi">
              <message-box
                v-if="reponse.texteSiNonAcquis"
                v-bind:source="reponse.texteSiNonAcquis"
                border_color="w-full border-jinius-red"
                font_style="text-lg" />
            </div>

            <div v-if="reponse">
              <div class="my-4 text-2xl font-bold">Corrigé</div>

              <surveyjs-wrapper
                v-bind:json="reponse.survey"
                v-bind:data="reponse.reponsesJson" />
            </div>
          </div>

          <div
            class="flex w-full flex-wrap space-y-8 p-4"
            v-else-if="introduction">
            <div
              v-if="session.examen.instructions"
              class="mb-6 flex w-full flex-wrap space-y-6 px-6">
              <div class="flex w-full text-3xl font-bold text-core-600">
                Instructions
              </div>
              <div
                class="instructions-container flex w-full flex-wrap bg-accent-100 px-6 py-4 shadow"
                v-html="session.examen.instructions"></div>
            </div>
            <div
              v-if="session.examen.dureeMinutesEpreuve"
              class="flex w-full flex-wrap justify-center space-y-8 px-6">
              <div class="flex w-full text-3xl font-bold text-core-600">
                Durée de l'examen
              </div>
              <div
                class="inline-flex items-center justify-center space-x-8 p-6">
                <font-awesome-icon
                  icon="hourglass-start"
                  class="text-orange-500"
                  size="3x" />
                <div class="text-5xl font-bold text-core-600">
                  {{ formatMinutesToHours(session.examen.dureeMinutesEpreuve) }}
                </div>
              </div>
            </div>
          </div>
          <template v-else>
            <surveyjs-wrapper
              ref="surveyjs_wrapper"
              v-on:complete="complete"
              v-on:scroll_action="scrollModalToTop"
              v-bind:json="surveyText" />
          </template>
        </template>
      </template>

      <template v-slot:modal-footer>
        <template v-if="introduction">
          <template v-if="!examenStarted">
            <div
              class="mx-auto flex w-full items-center justify-center font-bold">
              <input
                type="checkbox"
                class="flex cursor-pointer"
                id="checkbox-consent"
                v-model="consent" />
              <label
                for="checkbox-consent"
                class="flex cursor-pointer items-center justify-center p-3 text-base leading-tight hover:text-core-900">
                Je confirme avoir pris connaissance des instructions de l'examen
                et du temps imparti pour le compléter.
              </label>
            </div>
            <div class="my-4 flex w-full justify-center space-x-4 text-lg">
              <button
                class="bg-white-500 w-64 cursor-pointer rounded border-2 border-orange-500 px-5 py-3 text-center font-bold text-orange-500 shadow hover:bg-orange-400 hover:text-white"
                v-on:click="onclose()">
                Annuler
              </button>

              <button
                class="w-64 cursor-pointer rounded bg-orange-500 px-5 py-3 text-center font-bold text-white shadow hover:bg-orange-400 disabled:pointer-events-none disabled:opacity-25"
                v-on:click="startExamen()"
                v-bind:disabled="!consent">
                Démarrer l'examen
              </button>
            </div>
          </template>
          <template v-else>
            <div class="flex w-full justify-center space-x-4 text-lg">
              <button
                class="w-64 cursor-pointer rounded bg-orange-500 px-5 py-3 text-center font-bold text-white shadow hover:bg-orange-400 disabled:pointer-events-none disabled:opacity-25"
                v-on:click="resumeExamen()">
                Reprendre l'examen
              </button>
            </div>
          </template>
        </template>

        <div
          v-if="surveyCatcher"
          class="inline-flex w-full justify-end space-x-4 text-lg">
          <div
            v-if="!surveyCatcher.isFirstPage && !completed"
            class="w-64 cursor-pointer rounded bg-teal-500 px-5 py-3 text-center font-bold text-white shadow hover:bg-teal-400"
            v-on:click="surveyWrapper.prevPage()">
            Précédent
          </div>

          <div
            v-if="!surveyCatcher.isLastPage && !completed"
            class="w-64 cursor-pointer rounded bg-teal-500 px-5 py-3 text-center font-bold text-white shadow hover:bg-teal-400"
            v-on:click="goToNextPage()">
            Suivant
          </div>

          <div
            v-if="surveyCatcher.isLastPage && !completed"
            class="w-64 cursor-pointer rounded bg-yellow-500 px-5 py-3 text-center font-bold text-white shadow hover:bg-yellow-400"
            v-on:click="surveyWrapper.completeLastPage()">
            Soumettre mes réponses
          </div>
          <div
            v-else-if="completed"
            class="w-64 cursor-pointer rounded bg-core-200 px-5 py-3 text-center font-bold text-core-600 shadow hover:bg-core-100"
            v-on:click="close()">
            Fermer
          </div>
        </div>
      </template>
    </modal-box>

    <modal-box
      v-if="modal_alert_close"
      v-bind:width_class="'w-full max-w-3xl'"
      overflow_body
      v-bind:header="true"
      no_outside_click
      no_close_btn>
      <template v-slot:modal-header>
        <h1 class="text-xl font-bold text-core-600">Fermer</h1>
      </template>
      <template v-slot:modal-body>
        <div class="p-6 text-lg">
          <p class="mb-2">Etes-vous sûr de vouloir fermer ce questionnaire ?</p>
          <p class="font-bold">Vos réponses ne seront pas enregistrées.</p>
        </div>
      </template>
      <template v-slot:modal-footer>
        <div class="flex w-full justify-between">
          <div
            class="w-64 cursor-pointer rounded bg-core-200 px-5 py-3 text-center font-bold text-core-600 shadow hover:bg-core-100"
            v-on:click="closeModalAlertClose()">
            Annuler et poursuivre
          </div>
          <div
            class="w-64 cursor-pointer rounded bg-jinius-red px-5 py-3 text-center font-bold text-white shadow hover:opacity-75"
            v-on:click="close()">
            Fermer le questionnaire
          </div>
        </div>
      </template>
    </modal-box>
  </div>
</template>
<script>
  import { convertStringToDate } from '@/assets/data/ag_grid_fields_map';
  import messageBox from '@/components/utils/message-box';
  import modalBox from '@/components/utils/modal-box.vue';
  import Spinner from '@/components/utils/spinner.vue';
  import SurveyjsWrapper from '@/components/utils/surveyjs-wrapper';
  import { API } from '@/http-common';
  import dates_helpers from '@/mixin/dates_helpers';
  import { parse } from 'date-fns';
  import frLocale from 'date-fns/locale/fr';
  export default {
    components: {
      SurveyjsWrapper,
      modalBox,
      Spinner,
      messageBox
    },
    mixins: [dates_helpers],
    props: ['modal_show', 'onclose', 'session', 'fetch_session'],
    data: () => ({
      introduction: true,
      modal_alert_close: false,
      consent: false,
      surveyText: {},
      loading: false,
      result: null,
      reponse: null,
      completed: false,
      surveyCatcher: null,
      surveyWrapper: null,
      stopwatch: 1000000,
      timeExpired: false
    }),
    computed: {
      formattedTime() {
        const date = new Date(null);

        date.setSeconds(this.stopwatch / 1000);
        const utc = date.toUTCString();

        return utc.substr(utc.indexOf(':') - 2, 8);
      },
      examenStarted() {
        return this.session.examen && this.session.examen.reponsesJson;
      },
      timesUp() {
        return this.stopwatch <= 60 * 1000;
      }
    },
    watch: {
      async ['session.participantId']() {
        await this.fetchEvaluation();
      }
    },
    methods: {
      start() {
        this.timer = setInterval(() => {
          if (this.stopwatch > 0) {
            this.stopwatch -= 1000;
          } else {
            this.complete(null);
            this.timeExpired = true;
          }
          if (
            this.session.examen.alertes &&
            this.session.examen.alertes
              .map((alerte) => alerte * 60 * 1000)
              .includes(this.stopwatch)
          ) {
            this.$toast.open({
              type: 'error',
              message: `Attention : il ne vous reste plus que ${this.stopwatch / (60 * 1000)} mn de temps pour compléter l'examen`
            });
          }
        }, 1000);
      },
      stop() {
        clearInterval(this.timer);
      },
      reset() {
        this.stopwatch = 1000;
      },
      fetchSurveyWrapper() {
        this.surveyCatcher = this.$refs.surveyjs_wrapper
          ? this.$refs.surveyjs_wrapper.survey
          : null;
        this.surveyWrapper = this.$refs.surveyjs_wrapper
          ? this.$refs.surveyjs_wrapper
          : null;
      },
      async close() {
        if (this.completed) {
          await this.fetch_session();
        } else {
          await this.endExamen();
        }
        this.completed = false;
        this.closeModalAlertClose();
        this.onclose();
      },
      async complete(result) {
        this.loading = true;
        const participantId = this.session.participantId;
        const isElearning = this.session.type && this.session.type.id === 2;
        const isCursus = this.session.type && this.session.type.id === 8;

        let participants;

        if (isCursus) {
          participants = 'cursusInscriptionId';
        } else if (isElearning) {
          participants = 'elearningParticipantId';
        } else {
          participants = 'sessionParticipantId';
        }

        await this.endExamen();
        const responseCorrige = await API.get(
          `participant/examen/reponse?${participants}=${participantId}`,
          this.$header_skip_redirection
        );

        if (responseCorrige && responseCorrige.data) {
          this.reponse = responseCorrige.data;
        }
        this.completed = true;
        this.loading = false;
      },

      async fetchEvaluation() {
        this.loading = true;
        if (this.session) {
          const participantId = this.session.participantId;
          const isElearning = this.session.type && this.session.type.id === 2;
          const isCursus = this.session.type && this.session.type.id === 8;

          let participants;

          if (isCursus) {
            participants = 'cursusInscriptionId';
          } else if (isElearning) {
            participants = 'elearningParticipantId';
          } else {
            participants = 'sessionParticipantId';
          }
          const response = await API.get(
            `participant/examen?${participants}=${participantId}`,
            this.$header_skip_redirection
          );

          if (response && response.data) {
            this.surveyText = response.data;
          }
        }
        this.loading = false;
      },
      displayDates(dates) {
        const sortedDates = dates
          .slice()
          .sort(
            (a, b) =>
              convertStringToDate(a.date).getTime() -
              convertStringToDate(b.date).getTime()
          );

        return sortedDates.map(
          (date) =>
            `${date.date}${date.matin && !date.aprem ? ' (matin)' : ''}${!date.matin && date.aprem ? ' (après-midi)' : ''}`
        );
      },
      openModalAlertClose() {
        this.modal_alert_close = true;
      },
      closeModalAlertClose() {
        this.modal_alert_close = false;
      },
      scrollModalToTop() {
        const target = this.$refs.modal_test_examen.$el.getElementsByClassName(
          'modal_body--is-survey'
        );

        return (target[0].scrollTop = 0);
      },
      async startExamen() {
        this.introduction = false;
        await this.fetchEvaluation();
        this.fetchSurveyWrapper();
        this.stopwatch = this.session.examen.dureeMinutesEpreuve * 60 * 1000;
        this.start();
        this.scrollModalToTop();
        await this.sendExamen();
      },
      async resumeExamen() {
        this.introduction = false;
        await this.fetchEvaluation();
        this.fetchSurveyWrapper();
        const dureeEpreuve =
          this.session.examen.dureeMinutesEpreuve * 60 * 1000;
        const dateCreation = parse(
          this.session.examen.dateCreation,
          'dd/MM/yyyy HH:mm:ss',
          new Date(),
          { locale: frLocale }
        );
        const tempsConsume = new Date() - dateCreation;

        this.stopwatch = dureeEpreuve - tempsConsume;
        this.start();
        this.scrollModalToTop();
        await this.sendExamen();
      },
      goToNextPage() {
        this.surveyWrapper.nextPage();
        this.scrollModalToTop();
        this.sendExamen();
      },
      async updateExamen(baseUrl) {
        const sentReponse = this.session.examen.reponsesJson;
        const participantId = this.session.participantId;
        const isElearning = this.session.type && this.session.type.id === 2;
        const isCursus = this.session.type && this.session.type.id === 8;

        let participants;

        if (isCursus) {
          participants = 'cursusInscriptionId';
        } else if (isElearning) {
          participants = 'elearningParticipantId';
        } else {
          participants = 'sessionParticipantId';
        }

        let data = this.surveyCatcher.data;

        if (sentReponse) {
          data = {
            ...sentReponse,
            ...this.surveyCatcher.data
          };
        }
        await API.post(
          `${baseUrl}?${participants}=${participantId}`,
          data,
          this.$header_skip_redirection
        );
      },
      async sendExamen() {
        await this.updateExamen('participant/examen');
      },
      async endExamen() {
        await this.updateExamen('participant/examen/end');
        this.stop();
      }
    }
  };
</script>
<style lang="scss" scoped>
  :deep(.instructions-container) {
    @apply whitespace-pre-wrap;

    p {
      @apply mb-1 flex w-full items-center;
    }
  }

  :deep(.sv-question__content) {
    td {
      @apply border px-2 py-1;
    }
  }
</style>
