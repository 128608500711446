<template>
  <div class="flex w-full flex-1">
    <radio-button
      v-bind:id="`yes${input_name}`"
      label="Oui"
      v-bind:checked="inputValue"
      v-bind:name="input_name"
      v-bind:value="true"
      v-bind:disabled="disabled"
      v-on:change="updateValue(true)" />
    <radio-button
      v-bind:id="`no${input_name}`"
      label="Non"
      v-bind:checked="inputValue === false"
      v-bind:name="input_name"
      v-bind:value="false"
      v-bind:disabled="disabled"
      v-on:change="updateValue(false)" />
  </div>
</template>
<script>
  import RadioButton from '@/components/utils/radio-button';
  export default {
    components: { RadioButton },
    props: ['input_name', 'input_value', 'update_value', 'disabled'],
    emits: ['change'],
    data() {
      return { inputValue: this.input_value };
    },
    watch: {
      input_value(val) {
        this.inputValue = val;
      }
    },
    methods: {
      updateValue(value) {
        this.inputValue = value;
        if (this.update_value) {
          this.update_value(value);
        }
        this.$emit('change', value);
      }
    }
  };
</script>
<style lang="scss" scoped>
  input[type='radio'] + label span {
    transition:
      background 0.2s,
      transform 0.2s;
  }

  input[type='radio'] + label span:hover,
  input[type='radio'] + label:hover span {
    transform: scale(1.2);
  }

  input[type='radio']:checked + label span {
    @apply bg-core-900;
    box-shadow: 0px 0px 0px 2px white inset;
  }

  input[type='radio']:checked + label {
    @apply text-core-900;
  }
</style>
