<template>
  <div class="p-[8px]">
    <template v-if="etablissements.length">
      <refonte-table
        key="etablissements-1"
        table_index="etablissements-1"
        data_etablissement="denomination"
        data_etablissement_siret="siret"
        v-bind:data_etablissement_status="
          (row: Etablissement) => (row.desactivable ? undefined : 'Désactivé')
        "
        v-bind:data_etablissement_pastille="
          (row: Etablissement) => (row.desactivable ? 'active' : 'deactivated')
        "
        v-bind:data_etablissement_width="350"
        v-bind:columns="columns"
        v-bind:data="etablissements" />
    </template>
    <refonte-search-results-empty v-else />
  </div>
  <refonte-modal-desactiver-etablissement
    v-bind:show="isDesactiverEtablissementModalOpen"
    v-bind:on_close="closeDesactiverEtablissementModal"
    v-bind:on_submit="desactiverEtablissement" />
  <refonte-modal-modifier-etablissement
    v-if="loadSelectedEtablissement"
    v-bind:show="isModifierEtablissementModalOpen"
    v-bind:activites="activites"
    v-bind:categories_juridiques="categoriesJuridiques"
    v-bind:facturation_etablissements="facturationEtablissements"
    v-bind:on_close="closeModifierEtablissementModalOpen"
    v-bind:on_submit="modifierEtablissement"
    v-bind:fetch_facturation="fetchEtablisssmentEtablissements"
    v-bind:active_etablissement="informations" />
</template>

<script setup lang="ts">
  import RefonteTable from '@/components/refonte/tables/refonte-table.vue';
  import { useEtablissementStore } from '@/stores/etablissements';
  import { storeToRefs } from 'pinia';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';
  import {
    Etablissement,
    EtablissementInformations
  } from '@/types/gestion/etablissements-types';
  import IconModify from '@/assets/img/refonte/icons/action/icon-modify.svg?component';
  import IconClose from '@/assets/img/refonte/icons/display/icon-close.svg?component';
  import IconCheck from '@/assets/img/refonte/icons/display/icon-check.svg?component';
  import IconParticipants from '@/assets/img/refonte/icons/menu/icon-trainees.svg?component';
  import IconInterlocuteurs from '@/assets/img/refonte/icons/menu/icon-user.svg?component';
  import { ref } from 'vue';
  import RefonteModalDesactiverEtablissement from '@/components/refonte/modals/refonte-modal-desactiver-etablissement.vue';
  import RefonteModalModifierEtablissement from '@/components/refonte/modals/refonte-modal-modifier-etablissement.vue';
  import { API } from '@/http-common';

  const props = withDefaults(
    defineProps<{
      disable_etablissement?: Function;
      enable_etablissement?: Function;
      update_etablissement?: Function;
    }>(),
    {}
  );

  const columns = [
    {
      content_index: 'adresse',
      label: 'Adresse',
      min_width: 200,
      width: 240
    },
    {
      content_index: 'inscriptionLabel',
      label: 'Inscription',
      min_width: 115,
      width: 120
    },
    {
      content_index: 'facturationAutresTiersLabel',
      label: 'Facturation Autres Tiers',
      min_width: 115,
      width: 180
    },
    {
      content_index: 'interlocuteurs',
      label: 'Interlocuteurs',
      content_icon: () => IconInterlocuteurs,
      content_icon_black: true,
      width: 120
    },
    {
      content_index: 'contacts',
      label: 'Contacts',
      content_icon: () => IconParticipants,
      content_icon_black: true,
      width: 90
    },
    {
      content_index: 'modifiable',
      content_button_icon: (row: Etablissement) => IconModify,
      content_button_on_click: (row: Etablissement) => {
        openModifierEtablissementModalOpen(row);
      },
      content_button_variant: (row: Etablissement) => 'success',
      content_button_title: (row: Etablissement) =>
        row.modifiable ? 'Modifier' : 'Modification impossible',
      content_button_disabled: (row: Etablissement) => !row.modifiable,
      width: 32
    },
    {
      content_index: 'desactivable',
      content_button_icon: (row: Etablissement) =>
        row.desactivable ? IconClose : IconCheck,
      content_button_on_click: (row: Etablissement) => {
        if (row.desactivable) {
          openDesactiverEtablissementModal(row);
        } else {
          reactiverEtablissement(row);
        }
      },
      content_button_variant: (row: Etablissement) =>
        row.desactivable ? 'danger' : 'success',
      content_button_title: (row: Etablissement) =>
        row.desactivable ? 'Désactiver' : 'Réactiver',
      width: 40
    }
  ];

  const loadSelectedEtablissement = ref<boolean>(false);
  const etablissementStore = useEtablissementStore();

  const { etablissements } = storeToRefs(etablissementStore);

  const isDesactiverEtablissementModalOpen = ref<boolean>(false);
  const isModifierEtablissementModalOpen = ref<boolean>(false);
  const selectedEtablissement = ref<Etablissement>();

  function closeDesactiverEtablissementModal() {
    isDesactiverEtablissementModalOpen.value = false;
    selectedEtablissement.value = undefined;
  }
  function openDesactiverEtablissementModal(row: Etablissement) {
    isDesactiverEtablissementModalOpen.value = true;
    selectedEtablissement.value = row;
  }
  function desactiverEtablissement(motif: string) {
    selectedEtablissement.value &&
      props.disable_etablissement &&
      props.disable_etablissement(selectedEtablissement.value, motif);
  }
  function reactiverEtablissement(row: Etablissement) {
    props.enable_etablissement && props.enable_etablissement(row);
  }

  function closeModifierEtablissementModalOpen() {
    isModifierEtablissementModalOpen.value = false;
    selectedEtablissement.value = undefined;
  }
  const openModifierEtablissementModalOpen = async (row: Etablissement) => {
    await fetchEtablissement(row.id);
    isModifierEtablissementModalOpen.value = true;

    selectedEtablissement.value = row;
  };

  const fetchEtablissement = async (id: number) => {
    loadSelectedEtablissement.value = false;
    await fetchEtablisssmentEtablissements(id);
    await fetchEtablissementInformations(id);
    await fetchInseeInformations();
    loadSelectedEtablissement.value = true;
  };

  const activites = ref([]);
  const categoriesJuridiques = ref([]);
  const facturationEtablissements = ref([]);
  const informations = ref({});
  const fetchInseeInformations = async () => {
    const aResponse = await API.get('gestion/insee/nafv2.json');
    if (aResponse && aResponse.data) {
      activites.value = aResponse.data;
    }
    const cjResponse = await API.get('gestion/insee/cj.json');
    if (cjResponse && cjResponse.data) {
      categoriesJuridiques.value = cjResponse.data;
    }
  };
  const fetchEtablisssmentEtablissements = async (id: number) => {
    const response = await API.get(`/client/${id}/etablissements`);
    if (response && response.data) {
      facturationEtablissements.value = response.data;
    }
  };
  const fetchEtablissementInformations = async (id: number) => {
    const response = await API.get(`/client/${id}/informations`);
    if (response && response.data) {
      informations.value = response.data;
    }
  };
  function modifierEtablissement(form: EtablissementInformations) {
    selectedEtablissement.value &&
      props.update_etablissement &&
      props.update_etablissement(form);
  }
</script>

<style lang="scss" scoped></style>
