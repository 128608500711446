import { createNamespacedHelpers } from 'vuex';
const { mapState: mapStateEspace } = createNamespacedHelpers('espace');

export default {
    computed: { ...mapStateEspace(['espaces_list']) },
    methods: {
        fetchNavigationComponents() {
            const { space_id } = this.$route.params;

            return this.espaces_list[space_id].rubriques;
        },
        fetchNavigationSubComponents() {
            const { space_id, sub_space_id } = this.$route.params;

            return this.espaces_list[space_id].rubriques[sub_space_id]
                .sous_rubriques;
        },
        fetchNavigationSubComponentsChildren() {
            const { space_id, sub_space_id, sub_children_id } =
                this.$route.params;

            return this.espaces_list[space_id].rubriques[sub_space_id]
                .sous_rubriques[sub_children_id];
        }
    }
};
