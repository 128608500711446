<template>
  <div>
    <div class="mb-5 ml-4 mt-4 border-l-2 pl-3 leading-none text-primary-900">
      Pour tout renseignement, veuillez contacter :
    </div>
    <h2 class="mb-4 mt-3 pl-12 text-xl font-bold text-primary-900">
      {{ displayContactName }}
    </h2>

    <div
      v-if="contact.telephone || contact.email"
      class="my-8 flex items-center">
      <div
        v-if="contact.telephone"
        class="flex w-1/2 flex-grow items-center justify-center px-5">
        <font-awesome-icon
          icon="phone-alt"
          flip="horizontal"
          size="3x"
          class="text-primary-900" />
        <div class="px-5 font-bold leading-snug">
          <div class="text-md uppercase text-accent-600">Par téléphone</div>
          <div class="text-2xl">
            <span>{{ contact.telephone }}</span>
          </div>
        </div>
      </div>

      <div
        v-if="contact.email"
        class="flex w-1/2 flex-grow items-center justify-center px-5"
        v-bind:class="{ 'border-l border-core-400': contact.telephone }">
        <font-awesome-icon
          icon="envelope"
          size="4x"
          class="text-primary-900" />
        <div class="truncate px-5 font-bold leading-snug">
          <div class="text-md uppercase text-accent-600">Par e-mail</div>
          <div class="truncate text-2xl italic hover:underline">
            <span class="break-words">
              <a
                v-tooltip="`${contact.email}`"
                v-bind:href="`mailto:${contact.email}`">
                {{ contact.email }}
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="flex flex-wrap items-center justify-center px-12 pb-6">
      <div class="text-md w-full font-bold uppercase text-accent-600">
        Aucune information de contact disponible
      </div>
      <div class="w-full text-sm">
        Veuillez vous rapprocher de votre organisme de formation
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: ['current_session'],
    computed: {
      contact() {
        return this.current_session;
      },
      displayContactName() {
        const c = this.contact;

        if (c.prenom && c.nom) {
          return `${c.prenom} ${c.nom.toUpperCase()}`;
        } else if (!c.prenom && c.nom) {
          return `${c.nom}`;
        } else {
          return 'Notre service formation';
        }
      }
    },
    created() {}
  };
</script>
<style lang="scss" scoped></style>
