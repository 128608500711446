<template>
  <div class="flex w-full flex-wrap space-y-6 sm:space-x-8">
    <h1
      class="mb-2 flex w-full items-center border-l-2 border-jinius-green pl-5 text-center text-2xl font-bold leading-none text-core-900">
      Informations
    </h1>

    <template v-for="inputItem in inputs">
      <message-box
        v-if="client_type === 'siret' && ['siret'].includes(inputItem.name)"
        v-bind:key="`${inputItem.name}-msg`"
        v-bind:source="`Si votre entreprise n'a pas de SIRET, veuillez contactez votre organisme de formation.`"
        border_color="w-full border-jinius-green" />

      <div
        v-if="!inputItem.type || inputItem.type === client_type"
        v-bind:key="inputItem.name"
        class="flex w-full">
        <!-- Il faut "v-bind:key" basée sur "value" (tel que ci-dessous) pour s'assurer du refresh lorsque l'utilisateur relance une recherche -->
        <input-box
          v-bind:key="
            ['siret'].includes(inputItem.name) &&
            !v$.clientForm[inputItem.name].$invalid &&
            !v$.clientForm[inputItem.name].$dirty
          "
          v-bind:value="client_form[inputItem.name]"
          v-bind:name="inputItem.name"
          v-bind:placeholder="
            inputItem.name === 'denomination' && client_type === 'particulier'
              ? `${inputItem.placeholder} (Nom Prénom, ...)`
              : inputItem.placeholder
          "
          v-bind:label="inputItem.placeholder"
          v-bind:maxlength="inputItem.maxLength"
          v-bind:disabled="
            ['denomination'].includes(inputItem.name)
              ? !(
                  v$.clientForm[inputItem.name] &&
                  'maxLength' in v$.clientForm[inputItem.name] &&
                  v$.clientForm[inputItem.name].$dirty
                )
              : disabledAll
          "
          v-on:input="set_attribute($event, [inputItem.name])"
          v-bind:required="inputItem.required"
          v-bind:mask="inputItem.mask"
          v-bind:class="{
            'input-error':
              v$.clientForm[inputItem.name] &&
              v$.clientForm[inputItem.name].$error
          }"
          class_name="no-box" />
      </div>
    </template>

    <template v-if="client_type === 'siret'">
      <div
        v-bind:class="`flex w-full items-center ${disabledAll ? 'cursor-not-allowed text-core-400' : ''}`">
        <font-awesome-icon
          class="flex"
          size="lg"
          icon="building" />
        <div class="flex w-full flex-wrap px-4 py-3">
          <div class="flex w-full font-bold">Etablissement siège</div>
          <yes-no-radio-buttons
            input_name="etablissementSiege"
            v-bind:disabled="disabledAll"
            v-bind:input_value="client_form.etablissementSiege"
            v-bind:update_value="
              (value) => set_attribute(value, 'etablissementSiege')
            " />
        </div>
      </div>
      <div class="flex w-full items-center">
        <select-box
          v-bind:value="
            trancheEffectifs.find(
              (trancheEffectif) =>
                client_form.trancheEffectifs === trancheEffectif.id
            )
          "
          v-on:input="(value) => set_attribute(value.id, 'trancheEffectifs')"
          v-bind:categories="trancheEffectifs"
          v-bind:disabled="disabledAll"
          placeholder="Tranche d'effectif"
          no_capitalize_label
          tracking="id"
          class_name="no-box" />
        <font-awesome-icon
          size="lg"
          class="text-core-900"
          v-tooltip="{
            content: `<ul>
                                <li>Unités non employeuses: pas de salarié au cours de l'année de référence et pas d'effectif au 31/12</li>
                                <li>0 salarié : n'ayant pas d'effectif au 31/12 mais ayant employé dels salariés au cours de l'année de référence</li>
                               </ul>`
          }"
          icon="question-circle" />
      </div>

      <div class="flex w-full items-center">
        <div class="flex w-full sm:w-1/4">
          <input-box
            class_name="no-box w-full flex"
            name="activite"
            placeholder="Activité"
            label="Activité"
            v-bind:maxlength="6"
            v-bind:value="client_form.activite"
            v-bind:disabled="disabledAll"
            v-on:input="set_attribute($event, 'activite')" />
        </div>

        <div class="flex h-full w-full pl-6 sm:w-3/4">
          <div
            v-if="client_form.activite"
            v-bind:class="`flex w-full items-center justify-center self-end rounded px-3 pb-0.5 pt-1 text-center text-xs font-bold uppercase italic shadow-inner sm:w-1/2 ${activites[client_form.activite] ? 'bg-jinius-green text-white' : 'bg-core-200 text-jinius-red'}`">
            {{
              activites[client_form.activite]
                ? activites[client_form.activite]
                : 'Code inexistant dans la nomenclature'
            }}
          </div>
        </div>
      </div>

      <div class="flex w-full items-center">
        <div class="flex w-full sm:w-1/4">
          <input-box
            v-bind:disabled="disabledAll"
            class_name="no-box w-full flex"
            name="categorieJuridique"
            placeholder="Catégorie juridique"
            label="Catégorie juridique"
            v-bind:maxlength="4"
            v-bind:value="client_form.categorieJuridique"
            v-on:input="set_attribute($event, 'categorieJuridique')" />
        </div>
        <div class="flex h-full w-full pl-6 sm:w-3/4">
          <div
            v-if="client_form.categorieJuridique"
            v-bind:class="`flex w-full items-center justify-center self-end rounded px-3 pb-1 pt-1.5 text-center text-xs font-bold uppercase italic shadow-inner sm:w-1/2 ${categoriesJuridiques[client_form.categorieJuridique] ? 'bg-jinius-green text-white' : 'bg-core-200 text-jinius-red'}`">
            {{
              categoriesJuridiques[client_form.categorieJuridique]
                ? categoriesJuridiques[client_form.categorieJuridique]
                : 'Catégorie inexistante'
            }}
          </div>
        </div>
      </div>
      <div class="flex w-full items-center">
        <select-box
          v-bind:value="
            territorialites.find(
              (territorialite) =>
                client_form.territorialiteId === territorialite.id
            )
          "
          v-on:input="(value) => set_attribute(value.id, 'territorialiteId')"
          v-bind:categories="territorialites"
          placeholder="Territorialité"
          no_capitalize_label
          tracking="id"
          v-bind:disabled="disabledAll"
          class_name="no-box" />
      </div>
    </template>
  </div>
</template>

<script>
  import { territorialites, trancheEffectifs } from '@/assets/data/data_form';
  import inputBox from '@/components/utils/input-box.vue';
  import messageBox from '@/components/utils/message-box.vue';
  import selectBox from '@/components/utils/select-box';
  import yesNoRadioButtons from '@/components/utils/yes-no-radio-buttons';
  import { ACTION_TYPES } from '@/store/constants/ActionTypes';
  import { createNamespacedHelpers } from 'vuex';

  const { mapState: mapStateClient, mapActions: mapActionsClient } =
    createNamespacedHelpers('client');

  export default {
    components: {
      inputBox,
      yesNoRadioButtons,
      selectBox,
      messageBox
    },
    props: {
      set_attribute: Function,
      client_form: Object,
      client_type: String,
      disabled_list: {
        type: Array,
        default: () => []
      },
      v$: Object
    },
    data() {
      return {};
    },
    computed: {
      trancheEffectifs: () => trancheEffectifs,
      territorialites: () => territorialites,
      disabledAll() {
        return this.disabled_list.includes(this.client_type);
      },
      ...mapStateClient(['activites', 'categoriesJuridiques']),
      inputs() {
        return [
          {
            name: 'siret',
            placeholder: this.client_form.valideSansSiret
              ? 'Équivalent SIRET'
              : 'SIRET',
            type: 'siret',
            required: !this.client_form.valideSansSiret,
            mask: this.client_form.valideSansSiret
              ? undefined
              : '000 000 000 00000'
          },
          {
            name: 'rna',
            placeholder: 'RNA',
            type: 'rna',
            disabled: true
          },
          {
            name: 'denomination',
            placeholder: 'Dénomination',
            required: true,
            maxLength: 100
          },
          {
            name: 'numeroTVAIntra',
            placeholder: 'Numéro de TVA Intra'
          }
        ];
      }
    },
    async created() {
      await this.fetchDataForm();
    },

    methods: {
      ...mapActionsClient([ACTION_TYPES.FETCH_INSEE_INFOS]),
      async fetchDataForm() {
        await this[ACTION_TYPES.FETCH_INSEE_INFOS]();
      }
    }
  };
</script>
<style lang="scss" scoped></style>
