<template>
  <div class="flex w-full flex-wrap space-y-6 sm:space-x-8">
    <h1
      class="mb-2 flex w-full items-center border-l-2 border-jinius-green pl-5 text-center text-2xl font-bold leading-none text-core-900">
      Contact
    </h1>

    <div
      v-for="inputItem in inputs"
      v-bind:key="inputItem.name"
      class="flex w-full">
      <input-box
        v-bind:disabled="disabledAll"
        class_name="no-box"
        v-bind:label="inputItem.placeholder"
        v-bind:name="inputItem.name"
        v-bind:placeholder="inputItem.placeholder"
        v-bind:maxlength="100"
        v-bind:value="client_form[inputItem.name]"
        v-on:input="set_attribute($event, [inputItem.name])" />
    </div>
  </div>
</template>
<script>
  import inputBox from '@/components/utils/input-box.vue';
  const inputs = [
    {
      name: 'telephone',
      placeholder: 'Téléphone (standard)'
    },
    {
      name: 'email',
      placeholder: 'E-mail contact'
    },
    {
      name: 'siteInternet',
      placeholder: 'Site internet'
    }
  ];

  export default {
    components: { inputBox },
    props: {
      set_attribute: Function,
      client_form: Object,
      client_type: String,
      disabled_list: {
        type: Array,
        default: () => []
      },
      v$: Object
    },
    computed: {
      inputs: () => inputs,
      disabledAll() {
        return this.disabled_list.includes(this.client_type);
      }
    }
  };
</script>
<style lang="scss" scoped></style>
