<template>
  <modal-box
    v-if="modal_show"
    v-bind:width_class="'w-full max-w-screen-md'"
    v-bind:fill_height="true"
    v-on:close="close"
    overflow_body>
    <template v-slot:modal-header>
      <div class="flex-grow text-left">
        <h1 class="mb-1 text-xl">Géolocalisation</h1>
        <p class="text-sm">{{ adresse }}</p>
      </div>
      <button
        v-on:click="flyToCoordinates"
        class="mx-3 rounded-md bg-accent-200 px-4 py-3 text-xs font-bold transition-colors hover:bg-core-200">
        Re-centrer la carte
      </button>
    </template>

    <template v-slot:modal-body>
      <div
        id="map"
        class="w--full h-full"></div>
    </template>
  </modal-box>
</template>
<script>
  import modalBox from '@/components/utils/modal-box.vue';
  import mapboxgl from 'mapbox-gl';
  import 'mapbox-gl/dist/mapbox-gl.css';

  export default {
    components: { modalBox },
    props: ['modal_show', 'coordinates', 'adresse'],
    emits: ['onclose'],
    data() {
      return {
        current_map: null,
        mapStyle:
          'https://openmaptiles.geo.data.gouv.fr/styles/osm-bright/style.json'
      };
    },
    watch: {
      modal_show: {
        immediate: true,
        async handler(newVal, OldVal) {
          if (newVal) {
            await this.$nextTick();
            this.initMap();
          }
        }
      }
    },
    methods: {
      close() {
        this.$emit('onclose');
      },
      async initMap() {
        mapboxgl.accessToken = import.meta.env.VITE_MAPBOX_TOKEN_API;

        const scale = new mapboxgl.ScaleControl({
          maxWidth: 80,
          unit: 'metric'
        });
        const map = new mapboxgl.Map({
          container: 'map',
          style: this.mapStyle,
          center: this.coordinates,
          zoom: 14
        })
          .addControl(new mapboxgl.NavigationControl())
          .addControl(new mapboxgl.FullscreenControl())
          .addControl(scale);
        // Create a default Marker and add it to the map.

        new mapboxgl.Marker().setLngLat(this.coordinates).addTo(map);

        this.current_map = map;
      },
      flyToCoordinates() {
        this.current_map.flyTo({
          center: this.coordinates,
          zoom: 14
        });
      }
    }
  };
</script>
<style lang="scss" scoped>
  :deep(.modal-container) {
    .modal-body {
      @apply p-0;
    }

    .modal-hr {
      display: none;
    }

    .modal-footer {
      @apply p-0;
    }
  }
</style>
