<template>
  <refonte-modal
    v-bind:show="props.show"
    v-on:close="props.on_close"
    title="Téléchargement en masse"
    v-bind:no_close_btn="true"
    v-bind:no_outside_click="true">
    <template v-slot:modalBody>
      <div class="flex flex-col gap-[12px]">
        <span>
          Les
          <b class="text-refonte-black">{{ props.nb_files }} conventions</b>
          seront groupées par formation dans un dossier compressé, soit
          <b class="text-refonte_blue-600">{{ props.nb_folders }} dossiers</b>
          en .zip.
        </span>
        <span>Que souhaitez-vous faire ?</span>
      </div>
    </template>
    <template v-slot:modalFooter>
      <refonte-button
        variant="text"
        v-bind:on_click="props.on_close">
        Revenir et ajuster
      </refonte-button>
      <refonte-button v-bind:on_click="props.on_ok">
        Télécharger {{ props.nb_folders }} dossiers
      </refonte-button>
    </template>
  </refonte-modal>
</template>

<script setup lang="ts">
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import RefonteModal from '@/components/refonte/modals/refonte-modal.vue';

  const props = withDefaults(
    defineProps<{
      nb_files: number;
      nb_folders: number;
      show: boolean;
      on_close: Function;
      on_ok: Function;
      class?: string;
    }>(),
    {
      class: ''
    }
  );
</script>

<style lang="scss" scoped></style>
