<template>
  <details-view
    v-if="!loading"
    v-bind:session="session"
    espace="entreprise"
    v-bind:go_to_details="goToDetails"
    v-bind:download_facture="downloadFacture"
    v-bind:fetch_session="fetchFormation"
    v-bind:refresh_session="refreshFormation"
    v-bind:client_id="profile.activeEntrepriseId"
    v-bind:convention_base_url="`client/${profile.activeEntrepriseId}/convention`"
    v-bind:pif_base_url="`client/${profile.activeEntrepriseId}/pif`"
    v-bind:certificats_url="`client/${profile.activeEntrepriseId}/commande/${commande_id}/formation/${reference}/certificats`"
    v-bind:download_all_base_url="`client/${profile.activeEntrepriseId}/commande/${commande_id}/formation/${reference}`"
    back_base_url="entreprise/inscriptions" />
  <spinner
    sizes="w-20 h-20"
    v-else
    thickness="border-8" />
</template>

<script>
  import spinner from '@/components/utils/spinner';
  import { API } from '@/http-common';
  import detailsView from '@/views/_espace/details';
  import { createNamespacedHelpers } from 'vuex';
  const { mapState: mapStateClient } = createNamespacedHelpers('client');

  export default {
    components: {
      detailsView,
      spinner
    },
    props: {},
    data() {
      return {
        loading: false,
        session: {}
      };
    },
    computed: {
      ...mapStateClient(['profile']),
      reference() {
        return this.$route.query.reference;
      },
      commande_id() {
        return this.$route.query.commande;
      },
      fiche() {
        return this.$route.query.fiche;
      }
    },
    watch: {
      async reference() {
        // Nécessaire dans un sous produit de parcours
        await this.fetchFormation();
      },
      async ['profile.activeEntrepriseId']() {
        await this.fetchFormation();
      }
    },
    async created() {
      await this.fetchFormation();
    },
    methods: {
      async fetchFormation() {
        this.loading = true;
        await this.refreshFormation();
        this.loading = false;
      },
      mapTestPositionnement(testDisponible, testReussi) {
        if (testReussi) {
          return 'Réussi';
        }
        if (testReussi === false) {
          return 'Non réussi';
        }
        if (testDisponible) {
          return 'Non réalisé';
        }

        return 'Test non requis';
      },
      mapTestValidationAcquis(testDisponible, testRealise) {
        if (testRealise) {
          return 'Réalisé';
        }
        if (testDisponible) {
          return 'Non réalisé';
        }

        return 'Test non requis';
      },
      formatSession(session) {
        return {
          ...session,
          participants: session.participants.map((participant) => ({
            ...participant,
            testPositionnement: this.mapTestPositionnement(
              session.testPositionnementId,
              participant.testPositionnementReussi
            ),
            testValidationAcquis: this.mapTestValidationAcquis(
              session.testValidationAcquisId,
              participant.testValidationAcquisRealise
            )
          }))
        };
      },
      async refreshFormation() {
        const clientId = this.profile.activeEntrepriseId;

        if (clientId) {
          try {
            const response = await API.get(
              `client/${clientId}/commande/${this.commande_id}/formation/${this.reference}/details`,
              this.$header_skip_redirection
            );

            this.session = this.formatSession(response.data);
          } catch (e) {
            this.$router.push('/entreprise');
          }
        }
      },
      goToDetails(reference) {
        if (reference) {
          this.$router.push(
            `/entreprise/details?reference=${reference}&commande=${this.commande_id}`
          );
        }
      },
      async downloadFacture(id, nom) {
        const clientId = this.profile.activeEntrepriseId;
        const response = await API.get(
          `client/${clientId}/facture/${id}/file`,
          {
            responseType: 'blob',
            ...this.$header_skip_redirection
          }
        );
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', nom);
        document.body.appendChild(fileLink);
        fileLink.click();
      }
    }
  };
</script>
<style lang="scss" scoped></style>
