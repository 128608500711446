<template>
  <div class="w-full lg:h-full">
    <spinner
      v-if="loading"
      sizes="w-20 h-20"
      thickness="border-8" />
    <div v-if="!loading">
      <div class="section__description">
        Les commandes ci-dessous correspondent à toutes les commandes réalisées
        passées pour votre compte.
      </div>
      <ag-grid-wrapper
        v-bind:column_defs="[
          {
            headerName: 'Référence',
            field: 'reference',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'Formation',
            field: 'formation',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'Date session',
            field: 'dateSession',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'Vendeur',
            field: 'vendeur',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'Payeur',
            field: 'payeur',
            filter: 'agTextColumnFilter'
          },
          { headerName: 'Total TTC', field: 'montantTtc' },
          { headerName: 'Confirmée le', field: 'dateCreation' },
          {
            headerName: 'Règlement',
            field: 'reglement',
            cellRendererParams: {
              onclick: (e, reglement) => openReglementModal(e, reglement)
            }
          },
          {
            headerName: 'Bon de commande',
            field: 'bonCommande',
            cellRenderer: 'buttonCellRenderer',
            cellRendererParams: {
              label: 'Télécharger',
              labelDisabled: 'Indisponible',
              onclick: (e, params) => downloadBonCommande(e, params)
            }
          },
          { headerName: 'Stage', field: 'familleStage' },
          {
            headerName: 'Formation professionnelle',
            field: 'familleFormationContinue'
          },
          {
            headerName: 'Préparation aux examens',
            field: 'famillePreparationExamen'
          }
        ]"
        v-bind:initial_data="commandes" />
    </div>
    <reglement-modal
      v-if="showReglementModal && reglement"
      v-bind:show_modal="showReglementModal"
      v-bind:reglement="reglement"
      v-bind:onclose="closeReglementModal" />
  </div>
</template>
<script>
  import AgGridWrapper from '@/components/utils/ag-grid/ag-grid-wrapper';
  import Spinner from '@/components/utils/spinner.vue';
  import { API } from '@/http-common';
  import ReglementModal from '@/views/_espace/_entreprise/_commandes/reglement-modal';

  export default {
    components: {
      AgGridWrapper,
      Spinner,
      ReglementModal
    },
    data: () => ({
      loading: false,
      commandes: [],
      showReglementModal: false,
      reglement: null
    }),
    created() {
      this.fetchCommandes();
    },
    methods: {
      async fetchCommandes() {
        this.loading = true;
        const response = await API.get(
          'participant/commandes',
          this.$header_skip_redirection
        );

        this.commandes = response.data.map((commande) => ({
          ...commande,
          familleStage: commande.familleStage ? 'Oui' : 'Non',
          familleFormationContinue: commande.familleFormationContinue
            ? 'Oui'
            : 'Non',
          famillePreparationExamen: commande.famillePreparationExamen
            ? 'Oui'
            : 'Non'
        }));
        this.loading = false;
      },
      openReglementModal(e, reglement) {
        this.reglement = reglement;
        this.showReglementModal = true;
      },
      closeReglementModal() {
        this.reglement = null;
        this.showReglementModal = false;
      },
      async downloadBonCommande(e, params) {
        const { bonCommandeNom, id } = params.data;
        const response = await API.get(`participant/commande/${id}/bon/file`, {
          responseType: 'blob',
          ...this.$header_skip_redirection
        });
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', bonCommandeNom);
        document.body.appendChild(fileLink);
        fileLink.click();
      }
    }
  };
</script>
<style lang="scss" scoped></style>
