<template>
  <modal-box
    v-if="show_modal"
    ref="modal_site"
    width_class="w-full max-w-6xl"
    overflow_body
    fill_height
    v-bind:footer="true"
    v-bind:copyright="false"
    v-on:close="close">
    <template v-slot:modal-header>
      <h1 class="text-xl text-jinius-blue">Sélectionnez un site</h1>
    </template>

    <template v-slot:modal-body>
      <div class="h-full w-full">
        <div class="mb-4 flex w-full justify-end">
          <input-box
            class_name="no-box"
            name="search"
            left_icon="search"
            placeholder="Rechercher par dénomination"
            v-bind:value="search"
            v-on:input="setSearch" />
        </div>
        <template v-if="sites && sites.length">
          <template
            v-for="site in sites"
            v-bind:key="site.id">
            <div
              v-on:click="() => setSite(site)"
              class="sm:flex-no-wrap my-2 flex cursor-pointer flex-wrap rounded bg-core-100 px-5 py-4 shadow hover:bg-core-200">
              <div class="w-full sm:w-2/4">
                <h5
                  v-if="site.denomination"
                  class="font-bold text-jinius-dark-green">
                  {{ site.denomination }}
                </h5>

                <p
                  v-if="site.adresse"
                  class="flex items-center pr-4">
                  <font-awesome-icon
                    icon="map-marker-alt"
                    size="sm"
                    class="mx-2 text-core-500" />
                  <span>{{ site.adresse }}</span>
                </p>
              </div>
              <div class="w-full sm:w-1/4">
                <p>
                  <span class="mr-2 font-bold"> SIRET </span>
                  <span v-if="site.siret">
                    {{ site.siret }}
                  </span>
                  <span v-else> N/A </span>
                </p>
              </div>
            </div>
          </template>
        </template>
        <template v-else-if="!sites.length">
          <div
            class="my-2 flex cursor-pointer rounded bg-core-100 px-5 py-4 shadow hover:bg-core-200">
            Aucun résultat...
          </div>
        </template>
        <template v-else>
          <div class="flex h-full items-center justify-center self-stretch">
            <spinner />
          </div>
        </template>
      </div>
    </template>

    <template v-slot:modal-footer> </template>
  </modal-box>
</template>
<script>
  import inputBox from '@/components/utils/input-box.vue';
  import modalBox from '@/components/utils/modal-box.vue';
  import spinner from '@/components/utils/spinner.vue';
  import { API } from '@/http-common';
  export default {
    components: {
      modalBox,
      spinner,
      inputBox
    },
    props: ['show_modal', 'onclose', 'set_site'],
    data: () => ({
      sites: [],
      allSites: [],
      search: null
    }),
    async created() {
      const response = await API.get(
        'formateur/agenda/sites',
        this.$header_skip_redirection
      );

      if (response && response.data) {
        this.sites = response.data;
        this.allSites = response.data;
      }
    },
    methods: {
      setSite(site) {
        this.set_site(site);
        this.close();
      },
      close() {
        this.onclose();
      },
      setSearch(value) {
        this.search = value;
        if (value.length >= 3) {
          this.sites = this.allSites.filter(
            (site) =>
              site.denomination &&
              site.denomination.toLowerCase().indexOf(value.toLowerCase()) > -1
          );
        } else {
          this.sites = this.allSites;
        }
      }
    }
  };
</script>
<style lang="scss" scoped>
  .search_button {
    @apply flex w-1/3 items-center justify-center self-end rounded bg-jinius-dark-green p-2 text-sm font-bold text-white shadow transition duration-150 hover:bg-jinius-green disabled:cursor-not-allowed disabled:bg-core-400 disabled:opacity-50;
  }
</style>
