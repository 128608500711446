<template>
  <div
    v-if="props.entity"
    class="refonte-item-entity"
    v-on:click="props.entity.actif && props.on_click"
    v-bind:class="`${!props.entity.actif ? 'item-deactivated' : ''} ${props.active ? 'item-selected' : ''} ${props.class}`">
    <div
      v-if="props.checkbox"
      class="item-entity-checkbox">
      <refonte-checkbox v-bind:value="props.active" />
    </div>
    <div class="item-entity-container">
      <div class="item-entity-content">
        <div
          class="item-entity-content-status"
          v-tooltip="{
            content: props.entity.actif
              ? 'L\'entité est active'
              : 'Entité désactivée'
          }">
          <refonte-pastille
            v-bind:status="props.entity.actif ? 'active' : 'deactivated'" />
        </div>
        <div class="item-entity-content-infos">
          <div class="item-entity-content-infos-name">
            {{ props.entity.denomination }}
          </div>
          <div
            v-if="props.entity.siret"
            class="item-entity-content-infos-siret">
            SIRET {{ formattedSiret }}
          </div>
        </div>
      </div>
      <div
        v-if="props.entity.codePostal || props.entity.ville"
        class="item-entity-address">
        <div class="item-entity-address-icon">
          <IconAddress />
        </div>
        <div class="item-entity-address-text">
          {{ `${props.entity.codePostal} ${props.entity.ville}` }}
        </div>
      </div>
      <div
        class="item-entity-rights"
        v-if="!props.light && props.admin">
        <div class="item-entity-rights-icon">
          <IconUser />
        </div>
        <div class="item-entity-rights-text">Administration</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import IconUser from '@/assets/img/refonte/icons/menu/icon-user.svg?component';
  import IconAddress from '@/assets/img/refonte/icons/information/icon-location.svg?component';
  import RefontePastille from '@/components/refonte/pastille/refonte-pastille.vue';
  import { computed } from 'vue';
  import { Entreprise } from '@/types/gestion/entreprise';
  import RefonteCheckbox from '@/components/refonte/inputs/refonte-checkbox.vue';

  const props = withDefaults(
    defineProps<{
      entity: Entreprise;
      on_click?: Function;
      admin?: boolean;
      class?: string;
      light?: boolean;
      active?: boolean;
      checkbox?: boolean;
    }>(),
    {
      class: '',
      light: false,
      admin: false,
      active: false,
      checkbox: false
    }
  );

  const formattedSiret = computed(() => {
    const siret = props.entity.siret;
    if (!siret) {
      return undefined;
    }
    return `${siret.slice(0, 3)} ${siret.slice(3, 6)} ${siret.slice(6, 9)} ${siret.slice(9, 14)}`;
  });
</script>

<style lang="scss" scoped>
  .refonte-item-entity {
    @apply flex flex-grow cursor-pointer self-stretch bg-refonte-white;
    gap: 4px;
    padding: 8px;
    border-radius: 8px;

    &:hover {
      @apply bg-refonte-pale-violet-2;
    }
    &.item-selected {
      @apply bg-refonte-pale-violet-2;
    }

    .item-entity-checkbox {
      @apply flex flex-col justify-center;

      :deep(.refonte-checkbox) {
        padding: 6px;

        .checkbox-background {
          @apply hidden;
        }
        .checkbox-checkmark {
          top: 0;
          left: 0;
        }
      }
    }
    .item-entity-container {
      @apply flex flex-grow flex-col items-start justify-center self-stretch;
      gap: 4px;

      .item-entity-content {
        @apply flex items-center self-stretch;
        gap: 2px;

        .item-entity-content-status {
          @apply flex items-start justify-center self-stretch;
          min-width: 16px;
          width: 16px;
          padding: 4px 0;
        }
        .item-entity-content-infos {
          @apply flex flex-grow flex-col items-start;
          gap: 2px;

          .item-entity-content-infos-name {
            @apply self-stretch overflow-hidden text-ellipsis text-refonte-black;
            font-size: 13px;
            font-weight: 400;
            line-height: 125%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
          }
          .item-entity-content-infos-siret {
            @apply self-stretch uppercase text-refonte-black-40;
            font-size: 12px;
            font-weight: 400;
            line-height: 120%;
          }
        }
      }
      .item-entity-address {
        @apply flex items-center self-stretch;
        gap: 2px;

        .item-entity-address-icon {
          @apply flex items-center justify-center;
          width: 16px;
          height: 16px;

          :deep(svg) {
            @apply text-refonte-black-60;
            width: 16px;
            height: 16px;
          }
        }
        .item-entity-address-text {
          @apply flex-grow overflow-hidden text-ellipsis text-refonte-black-60;
          font-size: 12px;
          font-weight: 400;
          line-height: 120%;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }
      }
      .item-entity-rights {
        @apply flex items-center self-stretch;
        gap: 2px;

        .item-entity-rights-icon {
          @apply flex items-center justify-center;
          width: 16px;
          height: 16px;

          :deep(svg) {
            @apply text-refonte_blue-600;
            width: 16px;
            height: 16px;
          }
        }
        .item-entity-rights-text {
          @apply flex-grow overflow-hidden text-ellipsis text-refonte_blue-600;
          font-size: 12px;
          font-weight: 400;
          line-height: 120%;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }
      }
      &.item-deactivated {
        .item-entity-content {
          .item-entity-content-infos {
            .item-entity-content-infos-name {
              @apply text-refonte_blue-200;
            }
            .item-entity-content-infos-siret {
              @apply text-refonte_blue-200;
            }
          }
        }
        .item-entity-address {
          .item-entity-address-icon {
            :deep(svg) {
              @apply text-refonte_blue-200;
            }
          }
          .item-entity-address-text {
            @apply text-refonte_blue-200;
          }
        }
        .item-entity-rights {
          .item-entity-rights-icon {
            :deep(svg) {
              @apply text-refonte_blue-200;
            }
          }
          .item-entity-rights-text {
            @apply text-refonte_blue-200;
          }
        }
      }
    }
    @media (max-width: 767.98px) {
      .item-entity-container {
        gap: 2px;

        .item-entity-content {
          .item-entity-content-status {
            padding: 2px 0;
          }
          .item-entity-content-infos {
            .item-entity-content-infos-name {
              font-size: 12px;
              font-weight: 500;
              line-height: 120%;
            }
            .item-entity-content-infos-siret {
              font-size: 11px;
              font-weight: 400;
              line-height: 125%;
            }
          }
        }
      }
    }
  }
</style>
