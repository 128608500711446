<template>
  <div class="flex h-full w-full flex-wrap items-center justify-center">
    <form
      ref="clientForm"
      v-on:submit.prevent="submit"
      class="mb-12 h-full space-y-12">
      <informations
        v-bind:v$="v$"
        v-bind:client_form="clientForm"
        v-bind:set_attribute="setClientForm"
        v-bind:client_type="clientType"
        v-bind:disabled_list="forceEnableClientForm ? [] : ['rna', 'siret']" />
      <adresse
        v-bind:v$="v$"
        v-bind:client_form="clientForm"
        v-bind:set_attribute="setClientForm"
        v-bind:client_type="clientType"
        v-bind:disabled_list="forceEnableClientForm ? [] : ['rna', 'siret']" />
      <contact
        v-bind:v$="v$"
        v-bind:client_form="clientForm"
        v-bind:set_attribute="setClientForm"
        v-bind:client_type="clientType"
        v-bind:disabled_list="forceEnableClientForm ? [] : ['rna', 'siret']" />
    </form>
  </div>
</template>
<script>
  import api_insee_helpers from '@/mixin/api_insee_helpers';
  import { ACTION_TYPES } from '@/store/constants/ActionTypes';
  import Adresse from '@/views/_espace/_account/_informations/_client/adresse';
  import Contact from '@/views/_espace/_account/_informations/_client/contact';
  import Informations from '@/views/_espace/_account/_informations/_client/informations';
  import { useVuelidate } from '@vuelidate/core';
  import {
    email,
    helpers,
    maxLength,
    minLength,
    required,
    requiredIf
  } from '@vuelidate/validators';
  import { createNamespacedHelpers } from 'vuex';

  const { mapState: mapStateClient, mapActions: mapActionsClient } =
    createNamespacedHelpers('client');

  export default {
    components: {
      Adresse,
      Informations,
      Contact
    },
    mixins: [api_insee_helpers],
    props: {
      onclose: Function,
      on_client_form_submit: Function
    },
    setup() {
      return { v$: useVuelidate() };
    },
    data: () => ({}),
    computed: {
      ...mapStateClient(['clientForm', 'clientType', 'forceEnableClientForm']),
      minLength() {
        return this.clientForm.valideSansSiret ? 0 : 14;
      },
      maxLength() {
        return this.clientForm.valideSansSiret ? 20 : 14;
      }
    },
    watch: {
      'clientForm.siret'(newValue, oldValue) {
        setTimeout(() => {
          this.v$.$touch();
          if (!this.v$.$invalid) {
            this.v$.$reset();
          }
        }, 500);
      }
    },
    mounted() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.v$.$reset();
      }
    },
    methods: {
      ...mapActionsClient([
        ACTION_TYPES.SET_CLIENT_FORM_ATTRIBUTE,
        ACTION_TYPES.SEND_CLIENT_FORM
      ]),
      setClientForm(value, name) {
        this[ACTION_TYPES.SET_CLIENT_FORM_ATTRIBUTE]({
          name,
          value
        });
      },
      async submit(e) {
        e.preventDefault();
        this.loading = true;
        this.v$.$touch();
        if (!this.v$.$invalid) {
          const response = await this[ACTION_TYPES.SEND_CLIENT_FORM]({
            clientForm: this.clientForm,
            clientType: this.clientType
          });

          if (response && response.data) {
            const data = response.data;

            this.on_client_form_submit(data);
          }
          this.v$.$reset();
          this.onclose();
        } else if (!this.v$.clientForm.denomination.maxLength) {
          this.$toast.open({
            type: 'error',
            message: 'Le champ "Dénomination" est trop long'
          });
        } else if (!this.v$.clientForm.identification.maxLength) {
          this.$toast.open({
            type: 'error',
            message: 'Le champ "Identification" est trop long'
          });
        } else {
          this.$toast.open({
            type: 'error',
            message: 'Merci de remplir tous les champs obligatoires'
          });
        }
        this.loading = false;
      }
    },
    validations() {
      return {
        clientForm: {
          siret: {
            minLength: minLength(this.minLength),
            maxLength: maxLength(this.maxLength),
            required: requiredIf(function () {
              return (
                this.clientType === 'siret' && !this.clientForm.valideSansSiret
              );
            })
          },
          denomination: {
            maxLength: maxLength(100),
            required
          },
          identification: { maxLength: maxLength(100) },
          email: { email },
          numeroVoie: { required },
          ville: { required },
          codePostal: {
            required,
            maxLength: helpers.ref?.pays ? maxLength(25) : maxLength(5)
          }
        }
      };
    }
  };
</script>
<style lang="scss" scoped>
  :deep(.card__separator) {
    @apply my-8 flex w-full text-core-900 opacity-50;
  }
</style>
