<template>
  <modal-box
    v-if="modal_show"
    v-bind:width_class="'w-full max-w-screen-md'"
    v-on:close="onclose"
    v-bind:footer="true">
    <template v-slot:modal-header>
      <h1 class="text-xl font-bold text-core-900">Désactiver cette entité</h1>
    </template>

    <template v-slot:modal-body>
      <div class="my-8 w-full px-4 text-center font-bold">
        Désactiver permet d'empêcher toute future commande/facture au nom de
        cette entité.<br />
        Vous pouvez désactiver une société par exemple lors d'une liquidation ou
        d'une fusion/absorption.<br />
        Vous ne perdrez pas l'historique des commandes passées.<br />
        Cette action est réversible.<br /><br />
        Souhaitez-vous désactiver cette entité ?
      </div>
      <textarea-box
        v-bind:value="
          entite_form.desactivationMotif ? entite_form.desactivationMotif : ''
        "
        v-bind:name="'desactivationMotif'"
        v-bind:placeholder="'Précisez un motif'"
        v-bind:label="'Motif'"
        v-bind:maxlength="500"
        v-on:input="set_attribute($event, 'desactivationMotif')"
        v-bind:rows="4" />
    </template>

    <template v-slot:modal-footer>
      <div class="inline-flex w-full justify-center space-x-4">
        <button
          class="primary__button"
          v-on:click="disable">
          Désactiver
        </button>
        <button
          class="secondary__button"
          v-on:click="onclose">
          Annuler
        </button>
      </div>
    </template>
  </modal-box>
</template>
<script>
  import modalBox from '@/components/utils/modal-box.vue';
  import textareaBox from '@/components/utils/textarea-box.vue';

  export default {
    components: {
      modalBox,
      textareaBox
    },
    props: [
      'modal_show',
      'onclose',
      'ondisable',
      'entite_form',
      'set_attribute'
    ],
    methods: {
      disable() {
        this.onclose();
        this.ondisable();
      }
    }
  };
</script>
<style lang="scss" scoped>
  .primary__button {
    @apply w-64 cursor-pointer rounded bg-jinius-red px-5 py-3 text-center font-bold text-white shadow hover:bg-jinius-red;
  }
  .secondary__button {
    @apply w-64 cursor-pointer rounded bg-core-300 px-5 py-3 text-center font-bold text-core-700 shadow hover:bg-core-200;
  }
</style>
