import { navigation_tree } from '@/router/navigation_tree.js';
import { createRouter, createWebHistory } from 'vue-router';

const routes = [...navigation_tree];

export const router = createRouter({
    history: createWebHistory(),
    mode: 'history',
    base: import.meta.env.BASE_URL,
    routes
});
