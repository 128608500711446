<template>
  <modal-box
    v-if="modal_show"
    width_class="w-96"
    v-on:close="close"
    v-bind:footer="false"
    overflow_body>
    <template v-slot:modal-header>
      <h1 class="text-xl">Participants</h1>
    </template>

    <template v-slot:modal-body>
      <template
        v-for="(participant, index) in participants"
        v-bind:key="`participant-${index}`">
        <div>
          {{
            `${participant.prenom && participant.nom ? `${participant.prenom} ${participant.nom}` : 'Réservation'} ${participant.statut ? `(${participant.statut.label})` : ''}`
          }}
        </div>
      </template>
    </template>
  </modal-box>
</template>
<script>
  import modalBox from '@/components/utils/modal-box.vue';

  export default {
    components: { modalBox },
    props: ['modal_show', 'onclose', 'participants'],
    data: () => ({}),
    methods: {
      close() {
        this.onclose();
      }
    }
  };
</script>
<style lang="scss" scoped></style>
