<template>
  <div class="w-full lg:h-full">
    <spinner
      v-if="loading"
      sizes="w-20 h-20"
      thickness="border-8" />

    <div
      v-if="!loading"
      class="flex h-full w-full flex-col">
      <template v-if="navettes && navettes.length > 0">
        <div
          v-for="(navette, index) in navettes"
          v-bind:key="`${navette.id}-title`"
          class="my-4 rounded bg-white shadow transition hover:shadow-lg">
          <div
            class="section__header"
            v-on:click="toggleExpand(navette.id)">
            <div class="flex flex-wrap items-center">
              <h1 class="section__title !w-full space-x-4">
                <font-awesome-icon
                  v-bind:icon="
                    expand_list[navette.id]
                      ? 'chevron-circle-down'
                      : 'chevron-circle-right'
                  "
                  size="sm" />
                <span style="word-break: break-word">
                  {{ navette.nom }}
                </span>
              </h1>

              <div class="w-full">
                <message-box
                  v-if="
                    navette.commentaires &&
                    navette.commentaires != '<p><br></p>'
                  "
                  border_color="border-jinius-green"
                  font_style="text-sm text-accent-500 leading-none"
                  v-bind:source="navette.commentaires" />
                <div
                  v-if="navette.minCandidatures && navette.maxCandidatures"
                  class="mt-2.5 flex w-full justify-end text-sm leading-none text-accent-500">
                  <span class="font-bold">
                    Nombre de candidatures recommandé&nbsp;:
                  </span>
                  <span>{{
                    `&nbsp;de ${navette.minCandidatures} à ${navette.maxCandidatures} candidatures`
                  }}</span>
                </div>
              </div>
            </div>
          </div>

          <transition name="fade">
            <div
              v-if="expand_list[navette.id]"
              class="w-full px-6">
              <ag-grid-wrapper
                v-bind:grid_height="'480px'"
                v-bind:force_dom_layout="true"
                v-bind:exportable="true"
                v-bind:filename="navette.nom"
                v-bind:column_defs="[
                  {
                    headerName: 'Postuler',
                    field: 'postuler',
                    cellRenderer: 'actionCellRenderer',
                    cellRendererParams: {
                      label: 'Postuler',
                      onclick: (e, params) => openWindow(e, params, navette.id)
                    },
                    editable: false,
                    sortable: false,
                    suppressHeaderMenuButton: true,
                    pinned: 'left'
                  },
                  { headerName: 'Ref.', field: 'reference' },
                  {
                    headerName: 'Titre',
                    field: 'titre',
                    cellRendererParams: {
                      onclick: (e, params) => goToFiche(e, params)
                    }
                  },
                  { headerName: 'Parcours', field: 'parcoursTitre' },
                  { headerName: 'Mode', field: 'mode' },
                  { headerName: 'Organisateur', field: 'organisateur' },
                  {
                    headerName: 'Date(s)',
                    field: 'sessionDates',
                    sort: 'desc'
                  },
                  { headerName: 'Lieu', field: 'lieuNom' }
                ]"
                v-bind:initial_data="navette.sessions" />
              <div
                v-if="hasOrganisateurOutreMer(navette.sessions)"
                class="w-full> mt-3 flex justify-end text-xs leading-none">
                <span class="">
                  * Cette session est programmée par un organisateur
                  d'Outre-mer, les dates et les horaires ont été fixés dans le
                  fuseau horaire de l'organisateur.
                </span>
              </div>
              <hr
                class="mx-auto mb-8 w-full max-w-sm border-core-400"
                v-bind:class="
                  index < navettes.length - 1
                    ? 'border-core-400'
                    : 'border-transparent'
                " />
            </div>
          </transition>
        </div>
      </template>

      <div
        v-if="navettes && navettes.length === 0"
        class="flex w-full justify-center pt-5 text-lg font-bold">
        Pas de navettes disponibles
      </div>
    </div>
    <apply-modal
      v-bind:fetch_navettes="fetchNavettesSessions"
      v-bind:apply_modal="applyModal"
      v-bind:onclose="onApplyModalClose"
      v-bind:session="session" />
  </div>
</template>
<script>
  import agGridWrapper from '@/components/utils/ag-grid/ag-grid-wrapper';
  import messageBox from '@/components/utils/message-box.vue';
  import spinner from '@/components/utils/spinner.vue';
  import { API } from '@/http-common';
  import navigation_helpers from '@/mixin/navigation_helpers';
  import applyModal from '@/views/_espace/_formateur/_candidatures/_modal/apply-modal';

  export default {
    components: {
      applyModal,
      agGridWrapper,
      spinner,
      messageBox
    },
    mixins: [navigation_helpers],
    data: () => ({
      applyModal: false,
      loading: false,
      session: {},
      navettes: [],
      expand_list: {}
    }),
    created() {
      this.fetchNavettesSessions();
    },
    methods: {
      async fetchNavettesSessions() {
        this.loading = true;
        const response = await API.get(
          'formateur/navettes-sessions',
          this.$header_skip_redirection
        );

        this.navettes = response.data;
        this.loading = false;
      },
      onApplyModalClose() {
        this.applyModal = false;
      },
      openWindow(event, params, navetteId) {
        this.session = {
          ...params.data,
          navetteId
        };
        this.applyModal = true;
      },
      goToFiche(event, params) {
        this.openFiche(event, params);
      },
      hasOrganisateurOutreMer(sessions) {
        return sessions.some((session) => session.organisateurOutreMer);
      },
      toggleExpand(id) {
        if (!this.expand_list[id]) {
          this.expand_list[id] = true;
        } else {
          this.expand_list[id] = !this.expand_list[id];
        }
      }
    }
  };
</script>
<style lang="scss" scoped>
  .commentaires {
    min-height: 5rem;
    @apply flex w-full break-all rounded bg-core-300 p-4 italic;
  }
  .section__header {
    @apply w-full flex-shrink cursor-pointer px-7 py-6;

    .section__title {
      @apply m-0 flex w-auto items-center border-none pl-0 text-sm text-accent-700 md:text-base lg:text-3xl #{!important};
    }

    &:hover {
      .section__title {
        @apply text-jinius-dark-green #{!important};
      }
    }
  }
</style>
