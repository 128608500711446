<template>
  <span class="cursor-pointer">
    <a v-bind:href="logoutFromOidcProvider">
      <text-button
        v-bind:label="'Se déconnecter'"
        size="lg"
        icon="lock"
        width="2rem"
        height="2rem"
        dark="false"
        label_margin="4"
        v-bind:class="classes" />
    </a>
  </span>
</template>

<script>
  import textButton from '@/components/utils/text-button.vue';

  export default {
    components: { textButton },
    props: {
      classes: {
        type: String,
        default() {
          return 'hover:bg-jinius-dark-green py-2 px-3';
        }
      }
    },
    computed: {
      logoutFromOidcProvider() {
        return `${import.meta.env.VITE_API_AUTH}/logout?to=${import.meta.env.VITE_AUTH_REDIRECT}`;
      }
    }
  };
</script>

<style lang="scss" scoped></style>
