<template>
  <div class="flex h-full w-full flex-wrap items-center justify-center px-4">
    <form
      v-if="!loading"
      ref="accountForm"
      class="flex h-full w-full flex-col">
      <div class="my-6 flex flex-wrap">
        <div class="w-full sm:w-1/2">
          <h1
            class="m-0 flex h-full items-center text-2xl font-bold uppercase text-accent-500 lg:text-5xl">
            Mon compte
          </h1>
        </div>
        <div class="my-4 w-full sm:my-0 sm:w-1/2">
          <save-form-button
            class="!justify-end"
            v-bind:v$="v$"
            v-bind:submit="submit"
            check_consent_text="Vous acceptez que les informations renseignées soient utilisées par les organismes de formation dans la gestion de vos commandes et l’amélioration du suivi de vos demandes."
            confirmed_additional_text="Vous pouvez à présent naviguer vers une autre page ou modifier à nouveau les informations personnelles de votre compte." />
        </div>
      </div>

      <div>
        <information-banner
          v-if="showBanner"
          v-bind:description="`${
            hasValidateContactInfo
              ? `<p class='mb-2'>N’oubliez pas de vérifier que vos informations personnelles sont à jour.</p>

            <p class='mb-2'>Les champs obligatoires sont marqués d’une (<span class='text-jinius-red'>*</span>).</p>`
              : `<p class='mb-2'>C’est votre première connexion ? Prenez quelques minutes pour compléter vos informations personnelles.</p>

            <p class='mb-2'>Les champs obligatoires sont marqués d’une (<span class='text-jinius-red font-bold text-xl'> * </span>).</p>`
          }`"
          icon="info-circle"
          title="Informations" />

        <informations-personnelles
          v-bind:v$="v$"
          v-bind:account_form="accountForm"
          v-bind:set_attribute="setAccountForm" />
        <informations-professionnelles
          v-bind:v$="v$"
          v-bind:account_form="accountForm"
          v-bind:set_attribute="setAccountForm" />
        <informations-contact
          v-bind:v$="v$"
          v-bind:account_form="accountForm"
          v-bind:set_attribute="setAccountForm" />
      </div>
    </form>
    <spinner
      v-else
      sizes="w-20 h-20"
      thickness="border-8" />
  </div>
</template>
<script>
  import informationBanner from '@/components/utils/information-banner';
  import saveFormButton from '@/components/utils/save-form-button.vue';
  import spinner from '@/components/utils/spinner.vue';
  import { ACTION_TYPES } from '@/store/constants/ActionTypes';
  import informationsContact from '@/views/_espace/_account/_informations/informations-contact';
  import informationsPersonnelles from '@/views/_espace/_account/_informations/informations-personnelles';
  import informationsProfessionnelles from '@/views/_espace/_account/_informations/informations-professionnelles';
  import { useVuelidate } from '@vuelidate/core';
  import { maxLength, required, requiredIf } from '@vuelidate/validators';
  import { createNamespacedHelpers } from 'vuex';

  const { mapState: mapStateAccount, mapActions: mapActionsAccount } =
    createNamespacedHelpers('account');

  const { mapState: mapStateObserver } = createNamespacedHelpers('observer');

  export default {
    components: {
      informationsPersonnelles,
      informationsProfessionnelles,
      informationsContact,
      spinner,
      informationBanner,
      saveFormButton
    },
    setup() {
      return { v$: useVuelidate() };
    },
    data: () => ({
      loading: true,
      showBanner: true
    }),
    computed: {
      ...mapStateAccount(['accountForm', 'hasValidateContactInfo']),
      ...mapStateObserver(['scrolled_body'])
    },
    async created() {
      await this.fetchContact();
    },
    methods: {
      ...mapActionsAccount([
        ACTION_TYPES.SET_ACCOUNT_FORM_ATTRIBUTE,
        ACTION_TYPES.GET_CONTACT,
        ACTION_TYPES.GET_RATTACHEMENT,
        ACTION_TYPES.SEND_CONTACT,
        ACTION_TYPES.GET_CONTACT_PROFILE,
        ACTION_TYPES.GET_CLUBS
      ]),
      setAccountForm(value, name) {
        this[ACTION_TYPES.SET_ACCOUNT_FORM_ATTRIBUTE]({
          name,
          value
        });
      },
      async fetchContact() {
        this.loading = true;
        await this[ACTION_TYPES.GET_RATTACHEMENT]();
        await this[ACTION_TYPES.GET_CLUBS]();
        await this[ACTION_TYPES.GET_CONTACT]();
        this.loading = false;
      },
      async submit() {
        this.loading = true;
        await this[ACTION_TYPES.SEND_CONTACT](this.accountForm);
        await this[ACTION_TYPES.GET_CONTACT_PROFILE]();
        await this[ACTION_TYPES.GET_CONTACT]();
        this.loading = false;
      }
    },
    validations() {
      return {
        accountForm: {
          nomUsage: { required },
          prenom: { required },
          societeLabel: { required },
          societe: { required },
          estInscritCRCC: { required },
          crcc: {
            required: requiredIf(function () {
              return this.accountForm.estInscritCRCC;
            })
          },
          estInscritCROEC: { required },
          croec: {
            required: requiredIf(function () {
              return this.accountForm.estInscritCROEC;
            })
          },
          rattachementAnecsCjec: { required },
          numAdherentAnecsCjec: {
            required: requiredIf(function () {
              return this.accountForm.rattachementAnecsCjec;
            }),
            maxLength: maxLength(14)
          },
          dateFinAdhesionAnecsCjec: {
            required: requiredIf(function () {
              return this.accountForm.rattachementAnecsCjec;
            })
          },
          telephone: { required },
          numeroVoie: { required },
          ville: { required },
          codePostal: { required }
        }
      };
    }
  };
</script>
<style lang="scss" scoped>
  :deep(.card__separator) {
    @apply my-8 flex w-full text-primary-900 opacity-50;
  }
</style>
