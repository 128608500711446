<template>
  <div class="flex h-full w-full">
    <div class="mb-6 flex w-full">
      <FullCalendar v-bind:options="calendarOptions" />
    </div>
    <event-display
      v-bind:agenda_list="agenda_list"
      v-bind:current_event="currentEvent"
      v-bind:modal_event="modal_event"
      v-bind:onclose="() => setModalEvent(false)"
      v-on:edit-indisponibilite="editIndisponibilite"
      v-on:event-delete="onEventDelete" />
    <form-unavailability
      v-bind:current_event="currentEvent"
      v-bind:modal_unavailability="modal_unavailability"
      v-bind:onclose="() => setModalUnavailability(false)"
      v-on:event-create="onEventCreate"
      v-on:event-update="onEventUpdate"
      v-bind:open_info_modal="openInfoModal" />
    <info-modal
      v-bind:show_modal="modal_info"
      v-bind:onclose="closeInfoModal" />
  </div>
</template>
<script>
  import { map_type_colors } from '@/assets/data/data_maps';
  import { tailwindsColors } from '@/assets/data/tailwind-config-resolver';
  import { API } from '@/http-common';
  import EventDisplay from '@/views/_espace/_formateur/_agenda/event-display';
  import formUnavailability from '@/views/_espace/_formateur/_agenda/form-unavailability';
  import InfoModal from '@/views/_espace/_formateur/_agenda/info-modal';
  import dayGridPlugin from '@fullcalendar/daygrid';
  import interactionPlugin from '@fullcalendar/interaction';
  import listPlugin from '@fullcalendar/list';
  import timeGridPlugin from '@fullcalendar/timegrid';
  import FullCalendar from '@fullcalendar/vue3';

  export default {
    components: {
      EventDisplay,
      FullCalendar,
      formUnavailability,
      InfoModal
    },
    data() {
      return {
        agenda_list: [],
        modal_info: false,
        modal_unavailability: false,
        modal_event: false,
        currentEvent: null,
        calendarOptions: {
          firstDay: 1,
          allDayText: 'Journée entière',
          locale: 'fr',
          noEventsContent: "Pas d'évènement à venir",
          headerToolbar: {
            left: 'prev,next today addUnavailability',
            center: 'title',
            right: 'timeGridDay,timeGridWeek,dayGridMonth,listWeek'
          },
          buttonText: {
            today: "Aujourd'hui",
            month: 'Mois',
            week: 'Semaine',
            day: 'Jour',
            list: 'Liste'
          },
          plugins: [
            dayGridPlugin,
            interactionPlugin,
            timeGridPlugin,
            listPlugin
          ],
          initialView:
            this.$breakpoints.w >= this.$breakpoints.screens.sm
              ? 'timeGridWeek'
              : 'listWeek',
          events: []
        }
      };
    },
    created() {
      this.fetchEvents();
      this.calendarOptions.customButtons = {
        addUnavailability: {
          text: 'Ajouter une indisponibilité',
          click: () => {
            this.setCurrentEvent(null);
            this.setModalUnavailability(true);
          }
        }
      };
      this.calendarOptions.eventClick = (eventClickInfo) =>
        this.handleEventClick(eventClickInfo);
      this.calendarOptions.eventChange = (eventChangeInfo) =>
        this.handleEventChange(eventChangeInfo);
    },
    methods: {
      setCurrentEvent(event) {
        this.currentEvent = event;
      },
      convertStringToDate(string) {
        const dateTime = string.split(' ');
        const dateParts = dateTime[0].split('/');
        const day = Number(dateParts[0]);
        const month = Number(dateParts[1]) - 1;
        const year = Number(dateParts[2]);

        const timeParts = dateTime[1].split(':');
        const hour = Number(timeParts[0]);
        const minute = Number(timeParts[1]);
        const second = Number(timeParts[2]);

        return new Date(year, month, day, hour, minute, second);
      },
      convert(event) {
        const {
          titre,
          lieu,
          adresse,
          dateDebut,
          dateFin,
          journeeEntiere,
          description,
          id,
          typeId,
          organisateurId,
          site,
          url,
          statutId
        } = event;

        const type = typeId && map_type_colors[typeId].split('-');
        const color_type = typeId && type[0];
        const color_type_value = typeId && type[1];

        return {
          eventId: id,
          title: titre,
          editable: !typeId && !organisateurId,
          // Setting event's backgroundColor
          ...(typeId
            ? { backgroundColor: tailwindsColors[color_type][color_type_value] }
            : { backgroundColor: tailwindsColors['jinius-red'] }),
          // Setting event's borderColor
          ...(typeId
            ? { borderColor: tailwindsColors[color_type][color_type_value] }
            : { borderColor: tailwindsColors['jinius-red'] }),
          ...(dateDebut && {
            start: this.convertStringToDate(dateDebut).toISOString()
          }),
          ...(dateFin && {
            end: this.convertStringToDate(dateFin).toISOString()
          }),
          lieu,
          ...(site && {
            siteId: site.id,
            site: site.denomination
          }),
          adresse,
          allDay: journeeEntiere,
          description,
          url,
          classNames: statutId === 3 ? ['event-cancel'] : []
        };
      },
      async fetchEvents() {
        const response = await API.get(
          'formateur/agenda',
          this.$header_skip_redirection
        );

        this.agenda_list = response.data;
        this.calendarOptions.events = response.data.map((r) => this.convert(r));
        if (this.currentEvent) {
          const event = this.calendarOptions.events.find(
            (e) => e.eventId === this.currentEvent.eventId
          );

          if (event) {
            this.currentEvent = { ...event };
          }
        }
      },
      async onEventCreate() {
        await this.fetchEvents();
      },
      async onEventUpdate() {
        await this.fetchEvents();
      },
      async onEventDelete() {
        await this.fetchEvents();
        this.modal_event = false;
      },
      setModalEvent(bool) {
        if (!this.modal_unavailability) {
          this.modal_event = bool;
        }
      },
      setModalUnavailability(bool) {
        this.modal_unavailability = bool;
      },
      closeInfoModal() {
        this.modal_info = false;
      },
      openInfoModal() {
        this.modal_info = true;
      },
      async editIndisponibilite(eventId) {
        const event = this.calendarOptions.events.find(
          (e) => e.eventId === eventId
        );

        if (event) {
          this.currentEvent = { ...event };
          this.setModalUnavailability(true);
        }
      },
      handleEventClick(eventClickInfo) {
        eventClickInfo.jsEvent.preventDefault();
        const eventId = eventClickInfo.event._def.extendedProps.eventId;
        const event = this.calendarOptions.events.find(
          (e) => e.eventId === eventId
        );

        if (event) {
          this.currentEvent = { ...event };
          this.setModalEvent(true);
        }
      },
      updateEvent(eventId, object) {
        const event = this.calendarOptions.events.find(
          (e) => e.eventId === eventId
        );

        this.calendarOptions.events = [
          ...this.calendarOptions.events.filter((e) => e.eventId !== eventId),
          {
            ...event,
            ...object
          }
        ];
      },
      async handleEventChange(eventChangeInfo) {
        const eventId = eventChangeInfo.event._def.extendedProps.eventId;
        const event = this.calendarOptions.events.find(
          (e) => e.eventId === eventId
        );
        const startDate = eventChangeInfo.event.start;

        startDate.setMinutes(0);
        const offset = startDate.getTimezoneOffset();
        const startLocaleDate = new Date(
          startDate.getTime() - offset * 60 * 1000
        );

        let endDate = eventChangeInfo.event.end;

        let endLocaleDate;

        if (!endDate || eventChangeInfo.event.allDay) {
          endDate = eventChangeInfo.event.start;
          endLocaleDate = new Date(endDate.getTime() - offset * 60 * 1000);
          endLocaleDate.setHours(endLocaleDate.getHours() + 1);
        } else {
          endDate.setMinutes(0);
          endLocaleDate = new Date(endDate.getTime() - offset * 60 * 1000);
        }
        const startArray = startLocaleDate.toISOString().split('T');
        const endArray = endLocaleDate.toISOString().split('T');
        const dateStart = startArray[0];
        const timeStart = startArray[1].split('.')[0];
        const dateEnd = endArray[0];
        const timeEnd = endArray[1].split('.')[0];
        const { title, lieu, description, url, adresse, siteId, site } = event;
        const body = {
          titre: title,
          lieu,
          adresse,
          dateDebut: dateStart,
          heureDebut: timeStart.split(':')[0] * 60,
          dateFin: dateEnd,
          heureFin: timeEnd.split(':')[0] * 60,
          // site
          description,
          url,
          siteId,
          site,
          journeeEntiere: eventChangeInfo.event.allDay
        };

        await API.put(
          `formateur/agenda/indisponibilite/${eventId}`,
          body,
          this.$header_skip_redirection
        );
        await this.fetchEvents();
      }
    }
  };
</script>
<style lang="scss" scoped>
  :deep(.fc) {
    @apply flex w-full;

    .fc-header-toolbar {
      @apply flex-wrap px-2 text-center text-xs lg:flex-nowrap lg:px-0 lg:text-left lg:text-base;
      .fc-toolbar-chunk {
        @apply my-1 w-full lg:w-auto;
      }
    }

    .fc-view-harness {
      @apply text-xs sm:text-base;
    }

    .fc-button {
      @apply border-core-900 bg-core-900 hover:border-jinius-green hover:bg-jinius-dark-green;
      &.fc-button-active {
        @apply border-jinius-green bg-jinius-dark-green;
      }
    }

    .fc-event-title {
      text-overflow: ellipsis;
    }

    .fc-event {
      &.event-cancel {
        border-color: lightgrey !important;
        background-color: lightgrey !important;
        background-image: repeating-linear-gradient(
          -45deg,
          transparent,
          transparent 10px,
          rgba(255, 255, 255, 0.5) 10px,
          rgba(255, 255, 255, 0.5) 25px
        );

        .fc-event-main {
          color: black !important;
        }
      }
    }
  }
</style>
