import filters_utils from '@/mixin/utils/filters_utils.js';

export default {
    methods: {
        formatParam(key, value) {
            if (!filters_utils.methods.hasValue(value)) {
                return '';
            }
            if (
                typeof value == 'string' ||
                typeof value == 'number' ||
                typeof value == 'boolean'
            ) {
                return value;
            }
            if (Array.isArray(value)) {
                return `${value.join(',')}`;
            }
        },
        formatParams(params, dateFields) {
            let newParams = { ...params };

            Object.keys(params).forEach((key) => {
                const value = params[key];

                if (value && dateFields.includes(key)) {
                    const offset = value.getTimezoneOffset();
                    const valueLocaleDate = new Date(
                        value.getTime() - offset * 60 * 1000
                    );

                    newParams = {
                        ...newParams,
                        [key]: valueLocaleDate.toISOString().split('T')[0]
                    };
                } else if (
                    filters_utils.methods.hasValue(value) &&
                    value !== '' &&
                    (!Array.isArray(value) || value.length > 0)
                ) {
                    newParams = {
                        ...newParams,
                        [key]: this.formatParam(key, params[key])
                    };
                } else {
                    delete newParams[key];
                }
            });
            return newParams;
        }
    }
};
