<template>
  <div class="aide-en-ligne">
    <h1 class="aide-en-ligne__titre">Aide en ligne</h1>
    <div class="aide-en-ligne__content">
      <div class="content__row justify-center">
        <div class="contact__wrapper">
          <div class="contact__info">
            <div class="info__column">
              <font-awesome-icon
                icon="headset"
                fixed-width />
              <span>01 59 03 12 61</span>
            </div>
            <div class="info__column">
              <font-awesome-icon
                v-bind:icon="['far', 'envelope']"
                fixed-width
                class="mt-1.5" />
              <span>assistance@jinius.fr</span>
            </div>
          </div>
        </div>
      </div>

      <div class="content__row">
        <div class="content__column content__column--left">
          <div
            class="column__wrapper"
            v-bind:class="{ 'column__wrapper--is-fixed': is_fixed }">
            <h2 class="column__title">Rubriques FAQ</h2>
            <ul
              class="list-inside list-[square] text-xl marker:text-jinius-blue">
              <template
                v-for="section in faq"
                v-bind:key="`rubrique-${section.id}`">
                <li
                  class="mb-1 first-letter:uppercase"
                  v-on:click="(e) => scrollToSection(e, section.id)">
                  <a v-bind:href="`#${section.id}`">
                    {{ section.titre.replace('--jinius--', '') }}
                    <template v-if="hasJiniusHint(section.titre)">
                      <logo-jinius />
                    </template>
                  </a>
                </li>
              </template>
            </ul>
          </div>
        </div>
        <div class="content__column content__column--right">
          <div class="column__wrapper">
            <h2 class="column__title !text-5xl">FAQ</h2>
            <template
              v-for="section in faq"
              v-bind:key="section.id">
              <div
                v-bind:ref="section.id"
                class="lg:p-6">
                <div
                  class="flex w-full flex-col flex-wrap space-y-6 lg:flex-row lg:items-center lg:space-y-0">
                  <div class="mb-3 flex w-full">
                    <h3
                      class="my-4 w-full border-b-4 border-jinius-dark-green pb-2 pr-8 text-3xl first-letter:uppercase">
                      {{ section.titre.replace('--jinius--', '') }}
                      <template v-if="hasJiniusHint(section.titre)">
                        <logo-jinius size="w-20" />
                      </template>
                    </h3>
                  </div>
                  <div
                    v-if="section.ressources"
                    class="inline-flex flex-wrap">
                    <template
                      v-for="(ressource, i) in section.ressources"
                      v-bind:key="i">
                      <a
                        target="_blank"
                        v-bind:href="ressource.url"
                        class="mb-3 mr-6 w-full space-x-2.5 rounded bg-core-100 py-3 pl-7 pr-8 font-bold shadow transition hover:bg-core-100 sm:w-auto lg:text-lg"
                        v-bind:class="displayRessourceColor(ressource.fa_icon)">
                        <font-awesome-icon
                          v-bind:icon="ressource.fa_icon"
                          size="lg"
                          fixed-width />
                        <span>{{ ressource.label }}</span>
                      </a>
                    </template>
                  </div>
                </div>

                <div
                  class="mt-8 text-justify text-xl"
                  v-for="(entry, i) in section.rubrique"
                  v-bind:key="i">
                  <div class="mb-4 font-bold first-letter:uppercase">
                    <span v-html="entry.question"></span>
                  </div>
                  <div class="pl-3 text-jinius-blue first-letter:uppercase">
                    <span v-html="entry.reponse"></span>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { faq } from '@/assets/data/faq.js';

  export default {
    inject: ['target_wrapper'],
    data() {
      return {
        faq,
        is_fixed: false
      };
    },
    mounted() {
      this.target_wrapper.addEventListener('scroll', this.handleScroll);
    },
    methods: {
      hasJiniusHint(value) {
        const regexp = /--jinius--/gi;

        return value.match(regexp);
      },
      scrollToSection(e, id) {
        e.preventDefault();
        const offset = this.$refs[id][0].offsetTop;

        this.target_wrapper.scroll({
          top: offset - 80,
          behavior: 'smooth'
        });
      },
      handleScroll(e) {
        if (e.target.scrollTop > 250) {
          this.is_fixed = true;
        } else {
          this.is_fixed = false;
        }
      },
      displayRessourceColor(icon) {
        let colors = '';

        const val = Array.isArray(icon) && icon[1].length ? icon[1] : icon;

        switch (val) {
          case 'file-pdf':
            colors = '!text-jinius-purple hover:!text-blue-600';
            break;
          case 'youtube':
            colors = '!text-rose-500 hover:!text-rose-600';
            break;
          default:
            break;
        }

        return colors;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .aide-en-ligne {
    @apply bg-white py-4;
    a {
      @apply text-jinius-blue hover:text-jinius-dark-green;
    }
    .aide-en-ligne__titre {
      @apply mt-8 px-4 text-center text-5xl font-bold lg:text-7xl;
    }

    .aide-en-ligne__content {
      @apply mx-auto flex w-full max-w-screen-2xl flex-wrap;
      .contact__wrapper {
        @apply flex w-full flex-wrap rounded-md border border-core-200 bg-core-100 px-6 py-8 shadow-md;
        .contact__title {
          @apply mb-7 w-full text-center text-3xl font-bold;
        }
        .contact__info {
          @apply flex w-full flex-wrap justify-around space-y-4 sm:flex-nowrap sm:space-y-0;
          .info__column {
            @apply flex w-full flex-auto items-center space-x-4 text-3xl sm:w-auto lg:justify-center;
          }
        }
      }
      .content__row {
        @apply mt-10 flex w-full flex-wrap;
        .content__column {
          @apply mb-8 flex w-full flex-col lg:mb-0;
          .column__wrapper {
            @apply px-6 lg:pl-2.5 lg:pr-5;
          }
          &.content__column--left {
            @apply lg:w-1/4;
            .column__wrapper {
              @apply relative flex w-full flex-col rounded-md border border-core-200 py-6 shadow-md transition-[top] duration-200 ease-in lg:top-[0px];

              &.column__wrapper--is-fixed {
                @apply lg:sticky lg:top-8;
              }
              @apply bg-core-100;
            }
          }
          &.content__column--right {
            @apply lg:w-3/4;
            .column__wrapper {
              @apply pb-12 lg:pl-8;
            }
          }
          .column__title {
            @apply mb-5 flex px-4 text-center text-4xl;
          }
        }
      }
    }
  }
</style>
