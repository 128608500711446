<template>
  <div
    class="picture__container overflow-hidden border border-core-400 shadow-sm"
    v-bind:class="class_name"
    v-on:click="triggerInput"
    v-bind:style="`width: ${max_width}px; height:${max_height}px`">
    <img
      v-if="image || default_image"
      v-bind:ref="image_ref"
      alt="Pas d'image"
      v-bind:src="image ? image : default_image"
      v-on:load="loadImage" />
    <template v-else>
      <font-awesome-icon
        icon="user-alt"
        class="text-core-900"
        size="6x" />
      <span class="px-4 text-center text-xs font-bold leading-none">
        Cliquez pour ajouter votre photo</span
      >
    </template>
    <input
      v-bind:ref="input_ref ? input_ref : name"
      hidden
      type="file"
      v-bind:name="name"
      v-on:change="onchange" />
    <div class="picture__edit">
      <font-awesome-icon
        class="picture"
        icon="camera"
        size="6x" />

      <div
        v-if="image != null"
        class="picture__delete"
        v-on:click="cancelPicture">
        <font-awesome-icon
          class="picture"
          icon="times"
          size="2x" />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: [
      'name',
      'input_ref',
      'image_ref',
      'max_width',
      'max_height',
      'default_image',
      'class_name'
    ],
    emits: ['load-image', 'cancel-image'],
    data() {
      return { image: null };
    },
    methods: {
      triggerInput(e) {
        this.$refs[this.name].click();
      },
      toBase64(file) {
        if (file) {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
          });
        }
      },
      async onchange(e) {
        const file = this.$refs[this.name].files[0];

        this.image = await this.toBase64(file);
      },
      async loadImage(e) {
        if (this.image) {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const MAX_WIDTH = this.max_width;
          const MAX_HEIGHT = this.max_height;
          const img = this.$refs.image;

          let { naturalWidth: width, naturalHeight: height } = this.$refs.image;

          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }

          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);
          this.image = canvas.toDataURL('image/png', 0.8);
          this.$emit('load-image', this.image);
        }
      },
      cancelPicture(e) {
        e.preventDefault();
        e.stopPropagation();
        this.image = null;
        this.$emit('cancel-image', this.image);
      }
    }
  };
</script>
<style lang="scss" scoped>
  .picture__container {
    position: relative;
    @apply cursor-pointer;
    .picture__edit {
      @apply absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center align-middle opacity-0 hover:opacity-100;
      transition: all 0.2s ease-in-out;
      background: rgba(255, 255, 255, 0.8);

      .picture__delete {
        @apply absolute right-0 top-0 mr-3 mt-1;
      }
    }

    .picture {
      @apply opacity-50;
      color: gray;

      &:hover {
        @apply opacity-75;
      }
    }
  }
</style>
