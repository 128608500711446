<template>
  <div class="exist-cell">
    <font-awesome-icon
      v-if="params.value"
      icon="check"
      class="text-green-500"
      size="lg" />

    <font-awesome-icon
      v-else-if="params.content"
      icon="question-circle"
      class="cursor-pointer text-blue-500 hover:text-core-700"
      size="lg"
      v-tooltip="{
        content: params.content,
        placement: 'right',
        distance: 20
      }" />
    <font-awesome-icon
      v-else
      icon="times"
      class="text-jinius-red"
      size="lg" />
  </div>
</template>
<script>
  export default {};
</script>
<style lang="scss" scoped>
  .exist-cell {
    @apply flex w-full items-center justify-center;

    .delete-cell__button {
      @apply w-full text-core-500 hover:text-jinius-red;
    }
  }
</style>
