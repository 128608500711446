<template>
  <refonte-modal
    v-bind:show="props.show"
    v-on:close="props.on_close"
    title="Conventions en attente"
    v-bind:no_close_btn="true"
    v-bind:no_outside_click="true">
    <template v-slot:modalBody>
      <div class="flex flex-col gap-[12px]">
        <span>
          Des conventions sont en attente de chargement.<br />
          En quittant maintenant, ces conventions ne seront pas importées dans
          votre espace.
        </span>
        <span>Que souhaitez-vous faire ?</span>
      </div>
    </template>
    <template v-slot:modalFooter>
      <refonte-button
        variant="text"
        v-bind:on_click="props.on_close">
        Revenir
      </refonte-button>
      <refonte-button v-bind:on_click="props.on_ok">
        Quitter quand même
      </refonte-button>
    </template>
  </refonte-modal>
</template>

<script setup lang="ts">
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import RefonteModal from '@/components/refonte/modals/refonte-modal.vue';

  const props = withDefaults(
    defineProps<{
      show: boolean;
      on_close: Function;
      on_ok: Function;
      class?: string;
    }>(),
    {
      class: ''
    }
  );
</script>

<style lang="scss" scoped></style>
