<template>
  <modal-box
    v-if="show_modal"
    width_class="w-full max-w-xl"
    v-bind:header="true"
    v-on:close="onclose">
    <template v-slot:modal-header>
      <h1 class="text-3xl font-bold text-core-600">
        Que souhaitez-vous télécharger ?
      </h1>
    </template>

    <template v-slot:modal-body>
      <div class="flex w-full flex-wrap items-center space-y-4 px-8 py-4">
        <div
          class="flex w-full"
          v-if="participantsHasCertificats">
          <radio-button
            id="certificats"
            label="Certificats de réalisation"
            v-bind:checked="choiceId == 'certificats'"
            name="choice"
            value="certificats"
            v-on:change="updateValue('certificats')" />
        </div>
        <div
          class="flex w-full"
          v-if="participantsHasConventions">
          <radio-button
            id="conventions"
            label="Conventions individuelles signées"
            v-bind:checked="choiceId == 'conventions'"
            name="choice"
            value="conventions"
            v-on:change="updateValue('conventions')" />
        </div>
      </div>
    </template>

    <template v-slot:modal-footer>
      <div class="flex w-full justify-center space-x-4">
        <div
          class="flex w-full cursor-pointer items-center justify-center whitespace-nowrap rounded bg-jinius-dark-green px-6 py-2.5 font-bold leading-none text-white shadow hover:bg-opacity-90"
          v-on:click="downloadDocuments">
          <font-awesome-icon
            icon="download"
            size="sm" />
          <span class="ml-3">Tout télécharger (.zip)</span>
        </div>
      </div>
    </template>
  </modal-box>
</template>
<script>
  import modalBox from '@/components/utils/modal-box.vue';
  import RadioButton from '@/components/utils/radio-button';

  export default {
    components: {
      modalBox,
      RadioButton
    },
    props: {
      show_modal: Boolean,
      onclose: Function,
      download_all_base_url: String,
      session: Object
    },
    data: () => ({
      choiceId: 'certificats',
      loading: false
    }),
    computed: {
      participantsHasCertificats() {
        const { participants } = this.session;

        return (
          participants &&
          participants.length &&
          participants.filter((p) => p.certificat && p.certificat.id).length > 1
        );
      },
      participantsHasConventions() {
        const { participants } = this.session;

        return (
          participants &&
          participants.length &&
          participants.filter(
            (p) =>
              p.convention &&
              (p.convention.signeeFileName || p.convention.valideeFileName)
          ).length > 1
        );
      }
    },
    async created() {
      if (!this.participantsHasCertificats && this.participantsHasConventions) {
        this.choiceId = 'conventions';
      }
    },
    methods: {
      updateValue(value) {
        this.choiceId = value;
      },
      close() {
        this.onclose();
      },
      async download(url, nom) {
        const fileLink = document.createElement('a');

        fileLink.href = url;
        fileLink.setAttribute('download', 'certificats');
        document.body.appendChild(fileLink);
        const simulateClick = function (elem) {
          // bubbles: false to avoid closing modal on download
          const evt = new MouseEvent('click', { bubbles: false });

          elem.dispatchEvent(evt);
        };

        simulateClick(fileLink);
        document.body.removeChild(fileLink);
      },
      async downloadConventions() {
        this.download(
          `${import.meta.env.VITE_API_SAFIR}${this.download_all_base_url}/conventions`,
          'conventions'
        );
      },
      async downloadCertificats() {
        this.download(
          `${import.meta.env.VITE_API_SAFIR}${this.download_all_base_url}/certificats`,
          'certificats'
        );
      },
      downloadDocuments() {
        if (this.choiceId == 'certificats') {
          this.downloadCertificats();
        }

        if (this.choiceId == 'conventions') {
          this.downloadConventions();
        }
      }
    }
  };
</script>
<style lang="scss" scoped></style>
