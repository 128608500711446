<template>
  <div
    class="refonte-item-convention"
    v-bind:class="`${props.selectable && !props.icon && isRowChecked ? 'item-checked' : ''} ${props.class}`">
    <refonte-checkbox
      v-if="props.selectable && !props.icon"
      v-bind:value="isRowChecked"
      v-on:input="toggleIsRowChecked" />
    <div
      v-if="props.icon"
      class="item-convention-icon">
      <component v-bind:is="props.icon" />
    </div>
    <div class="item-convention-content">
      <div class="item-convention-content-title">
        <div
          v-if="props.participant_status"
          class="item-convention-content-title-status">
          <refonte-pastille v-bind:status="props.participant_status" />
        </div>
        <div class="item-convention-content-title-text">
          <div class="item-convention-content-title-text-top">
            {{ props.title }}
          </div>
          <div class="item-convention-content-title-text-bottom">
            {{ props.subtitle }}
          </div>
        </div>
      </div>
      <refonte-button
        v-if="props.on_click_download"
        class="item-convention-content-download"
        variant="secondary"
        v-bind:icon_left="IconDownload"
        v-on:click="props.on_click_download" />
      <IconSeparatorVertical
        v-if="!props.no_status"
        class="item-convention-content-download-separator" />
      <div
        v-if="!props.no_status"
        class="item-convention-content-status">
        <div
          class="item-convention-content-status-text"
          v-bind:class="{ 'convention-signed': props.convention_signed_date }">
          <div class="item-convention-content-status-text-top">
            <div class="item-convention-content-status-text-top-icon">
              <IconValidate20 v-if="props.convention_signed_date" />
              <IconTime v-else />
            </div>
            <span>{{
              props.convention_signed_date ? 'Signée' : 'En attente'
            }}</span>
          </div>
          <div class="item-convention-content-status-text-bottom">
            {{
              props.convention_signed_date ? props.convention_signed_date : '-'
            }}
          </div>
        </div>
        <refonte-button
          v-if="props.on_click_download_signed"
          class="item-convention-content-download"
          variant="secondary"
          v-bind:icon_left="IconDownload"
          v-on:click="props.on_click_download_signed" />
      </div>
      <IconSeparatorVertical
        v-if="props.on_select"
        class="shrink-0" />
      <refonte-button
        v-if="props.on_select"
        class="item-convention-content-upload"
        variant="secondary"
        v-bind:icon_left="IconUpload"
        v-on:click="props.on_select"
        >{{ props.select_label }}</refonte-button
      >
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import IconDownload from '@/assets/img/refonte/icons/display/icon-download.svg?component';
  import IconSeparatorVertical from '@/assets/img/refonte/icons/display/icon-separator-vertical.svg?component';
  import IconTime from '@/assets/img/refonte/icons/information/icon-time.svg?component';
  import IconUpload from '@/assets/img/refonte/icons/display/icon-upload.svg?component';
  import IconValidate20 from '@/assets/img/refonte/icons/information/icon-validate-20.svg?component';
  import RefonteCheckbox from '@/components/refonte/inputs/refonte-checkbox.vue';
  import RefontePastille from '@/components/refonte/pastille/refonte-pastille.vue';
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';

  const props = withDefaults(
    defineProps<{
      icon?: Element;
      selectable?: boolean;
      participant_status?: string;
      title: string;
      subtitle: string;
      on_click_download?: Function;
      convention_signed_date?: string;
      on_click_download_signed?: Function;
      on_select?: Function;
      select_label?: string;
      no_status?: boolean;
      class?: string;
    }>(),
    {
      selectable: false,
      select_label: 'Sélectionner',
      no_status: false,
      class: ''
    }
  );

  const isRowChecked = ref<boolean>(false);

  function toggleIsRowChecked(newValue: boolean) {
    isRowChecked.value = !newValue;
  }
</script>

<style lang="scss" scoped>
  .refonte-item-convention {
    @apply flex items-center bg-refonte-white;
    gap: 8px;
    border-radius: 8px;
    padding: 6px 6px 6px 12px;
    max-width: 640px;

    :deep(.refonte-checkbox) {
      height: 36px;
      padding: 6px;

      .checkbox-background {
        @apply hidden;
      }
      .checkbox-checkmark {
        top: 12px;
        left: 0;
      }
    }
    .item-convention-icon {
      @apply flex shrink-0 items-center justify-center;
      width: 14px;
      height: 14px;

      :deep(svg) {
        @apply text-refonte-black;
        width: 14px;
        height: 14px;
      }
    }

    .item-convention-content {
      @apply flex flex-grow items-center justify-between;
      gap: 4px;

      .item-convention-content-title {
        @apply flex items-start;
        gap: 2px;
        width: 100%;
        max-width: 240px;

        .item-convention-content-title-status {
          @apply flex items-start justify-center;
          padding: 4px 0;
          width: 16px;
          min-height: 32px;
        }
        .item-convention-content-title-text {
          @apply flex flex-grow flex-col items-start;
          gap: 2px;

          .item-convention-content-title-text-top {
            @apply self-stretch overflow-hidden text-ellipsis text-refonte-black;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            font-size: 13px;
            font-weight: 400;
            line-height: 16px;
          }
          .item-convention-content-title-text-bottom {
            @apply self-stretch overflow-hidden text-ellipsis text-refonte-black-40;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
          }
        }
      }
      .item-convention-content-download {
        padding: 3px;
        border-radius: 4px;
      }
      .item-convention-content-download-separator {
        @apply shrink-0;
      }
      .item-convention-content-status {
        @apply flex items-center;
        gap: 4px;
        width: 100%;
        min-width: 170px;
        max-width: 170px;

        .item-convention-content-status-text {
          @apply flex flex-grow flex-col items-start justify-center;

          .item-convention-content-status-text-top {
            @apply flex items-center;
            gap: 2px;

            .item-convention-content-status-text-top-icon {
              @apply flex shrink-0 items-center justify-center;
              width: 20px;
              height: 20px;

              :deep(svg) {
                @apply text-refonte_blue-400;
                width: 18px;
                height: 18px;
              }
            }
            span {
              @apply text-refonte_blue-400;
              font-size: 14px;
              font-weight: 400;
              line-height: 18px;
            }
          }
          .item-convention-content-status-text-bottom {
            @apply whitespace-nowrap text-refonte-black-40;
            padding: 0 4px;
            font-size: 11px;
            font-weight: 400;
            line-height: 14px;
          }

          &.convention-signed {
            .item-convention-content-status-text-top {
              .item-convention-content-status-text-top-icon {
                :deep(svg) {
                  width: 20px;
                  height: 20px;
                }
              }
              span {
                @apply text-refonte_blue-600;
                font-size: 13px;
                line-height: 16px;
              }
            }
            .item-convention-content-status-text-bottom {
              @apply text-refonte_blue-200;
            }
          }
        }
      }
      .item-convention-content-upload {
        @media (min-width: 425px) {
          :deep(svg) {
            @apply hidden;
          }
        }
        @media (max-width: 424.98px) {
          :deep(span) {
            @apply hidden;
          }
        }
      }

      @media (max-width: 499.98px) {
        @apply flex-wrap;

        .item-convention-content-title {
          @apply flex-grow;
          flex-grow: 1;
          max-width: calc(100% - 28px);
        }
        .item-convention-content-download-separator {
          @apply hidden;
        }
      }
    }

    &.item-checked {
      @apply border-2 border-refonte_blue-600;
      padding: 4px 4px 4px 10px;
    }
  }
</style>
