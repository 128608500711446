<template>
  <div
    class="refonte-number"
    v-bind:class="`number-${props.variant} ${props.class}`">
    <span><slot></slot></span>
  </div>
</template>

<script setup lang="ts">
  const props = withDefaults(
    defineProps<{
      variant?: string;
      class?: string;
    }>(),
    {
      variant: 'count',
      class: ''
    }
  );
</script>

<style lang="scss" scoped>
  .refonte-number {
    @apply inline-flex shrink-0 items-center justify-center text-center text-refonte_blue-600;
    gap: 6px;
    padding: 2px 2px 1px 2px;
    height: 18px;

    &.number-count {
      span {
        font-size: 12px;
        font-weight: 400;
        line-height: 120%;
      }
    }
    &.number-date {
      span {
        font-size: 11px;
        font-weight: 600;
        line-height: 125%;
        text-transform: uppercase;
      }
    }
    &.number-filters {
      span {
        font-size: 12px;
        font-weight: 400;
        line-height: 120%;

        &:before {
          content: '(';
        }
        &:after {
          content: ')';
        }
      }
    }
    &.number-segment {
      padding: 0;

      span {
        font-size: 13px;
        font-weight: 700;
        line-height: 120%;
      }
    }
  }
</style>
