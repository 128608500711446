<template>
  <div
    v-if="!loading"
    class="flex w-full">
    <div class="w-full">
      <div
        class="mb-16 flex w-full flex-wrap rounded-lg border border-core-300 bg-white p-4 shadow-lg">
        <div class="flex w-full md:w-1/2">
          <section class="flex w-full flex-wrap">
            <h1 class="section__title">{{ profile.nomPrenom }}</h1>
            <div class="section__body">
              <div
                v-if="profile.identifiantCAC"
                class="form__item">
                <form-label
                  label="Identifiant AGLAE"
                  class="font-bold leading-none" />
                <p class="pb-2 pl-6 pt-3 uppercase text-core-700">
                  {{ profile.identifiantCAC }}
                </p>
              </div>
              <div
                v-if="profile.crcc"
                class="form__item">
                <form-label
                  label="CRCC"
                  class="font-bold leading-none" />
                <p class="pb-2 pl-6 pt-3 uppercase text-core-700">
                  {{ profile.crcc }}
                </p>
              </div>
              <div
                v-if="profile.statut"
                class="form__item">
                <form-label
                  label="Statut"
                  class="font-bold leading-none" />
                <p class="pb-2 pl-6 pt-3 uppercase text-core-700">
                  {{ profile.statut }}
                </p>
              </div>
              <div
                v-if="profile.cycle"
                class="form__item">
                <form-label
                  label="Cycle"
                  class="font-bold leading-none" />
                <p class="pb-2 pl-6 pt-3 uppercase text-core-700">
                  {{ profile.cycle }}
                </p>
              </div>
              <div
                v-if="profile.controleurCACDeStage"
                class="form__item">
                <form-label
                  label="Contrôleur de stage"
                  class="font-bold leading-none" />
                <p class="pb-2 pl-6 pt-3 uppercase text-core-700">
                  {{ profile.controleurCACDeStage }}
                </p>
              </div>
              <div
                v-if="profile.controleurDeStageCAC"
                class="form__item">
                <form-label
                  label="Contrôleur de stage CAC"
                  class="font-bold leading-none" />
                <p class="pb-2 pl-6 pt-3 uppercase text-core-700">
                  {{ profile.controleurDeStageCAC }}
                </p>
              </div>
              <div
                v-if="profile.cabinet"
                class="form__item">
                <form-label
                  label="Cabinet"
                  class="font-bold leading-none" />
                <p class="pb-2 pl-6 pt-3 uppercase text-core-700">
                  {{ profile.cabinet }}
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
  <spinner
    sizes="w-20 h-20"
    v-else
    thickness="border-8" />
</template>

<script>
  import formLabel from '@/components/utils/form-label.vue';
  import spinner from '@/components/utils/spinner.vue';
  import { API } from '@/http-common';
  import { createNamespacedHelpers } from 'vuex';
  const { mapState: mapStateAccount } = createNamespacedHelpers('account');

  export default {
    components: {
      spinner,
      formLabel
    },
    data: () => ({
      loading: false,
      profile: {}
    }),
    computed: { ...mapStateAccount(['stagiaire']) },
    created() {
      this.loading = true;
      this.refreshStagiaireProfile();
      this.loading = false;
    },
    methods: {
      async refreshStagiaireProfile() {
        const response = await API.get('stage/stagiaire-cac/profile');

        if (response && response.data) {
          this.profile = response.data;
        }
      }
    }
  };
</script>

<style lang="scss" scoped></style>
