<template>
  <div
    class="refonte-time"
    v-bind:class="props.class">
    <div class="refonte-time-icon">
      <IconTime />
    </div>
    <span>{{ props.start_time }}-{{ props.end_time }}</span>
  </div>
</template>

<script setup lang="ts">
  import IconTime from '@/assets/img/refonte/icons/information/icon-time.svg?component';

  const props = withDefaults(
    defineProps<{
      start_time: string;
      end_time: string;
      class?: string;
    }>(),
    {
      class: ''
    }
  );
</script>

<style lang="scss" scoped>
  .refonte-time {
    @apply inline-flex shrink-0 items-center;
    gap: 2px;

    .refonte-time-icon {
      @apply flex shrink-0 items-center justify-center;
      width: 20px;
      height: 20px;

      :deep(svg) {
        @apply text-refonte_blue-800;
        width: 20px;
        height: 20px;
      }
    }
    span {
      @apply text-refonte_blue-800;
      font-size: 12px;
      font-weight: 400;
      line-height: 120%;
    }
  }
</style>
