<template>
  <div class="w-full space-y-12">
    <message-box v-bind:source="map_decription_admin" />
    <div class="mb-4">
      <p class="text-lg">
        En remplissant le formulaire ci-joint, vous &laquo;<strong
          >déclarez sur l’honneur</strong
        >&raquo;
        <font-awesome-icon
          v-bind:icon="['fas', 'circle-info']"
          v-tooltip="{
            content: `<div class='max-w-xl'><p class='italic text-sm'>En application de l'article 441-1 du code pénal, la constitution de fausse déclaration est punie de trois ans d'emprisonnement et de 45000 euros d'amende.)</p></div>`
          }" />
        être habilité à détenir les droits d'aministration pour
        {{ entite.rna ? `l'association` : `l'entreprise` }} ci-dessous&nbsp;:
      </p>
      <display-entite-card v-bind:entite="entite" />

      <input-checkbox
        v-on:input="updateValueDeclaration"
        label="Je m'engage sur l'honneur à être habilité à détenir les droits d'aministration pour cette entité" />
    </div>
  </div>
</template>

<script>
  import { map_decription_admin } from '@/assets/data/data_maps';
  import displayEntiteCard from '@/components/utils/display-entite-card.vue';
  import inputCheckbox from '@/components/utils/input-checkbox.vue';
  import messageBox from '@/components/utils/message-box.vue';

  export default {
    components: {
      messageBox,
      inputCheckbox,
      displayEntiteCard
    },
    props: ['entite'],
    emits: ['changed_declaration'],
    data() {
      return {
        map_decription_admin,
        declaration_confirmation: false
      };
    },
    methods: {
      updateValueDeclaration(value) {
        this.$emit('changed_declaration', value);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .ressources__download__link__container {
    @apply flex h-16 w-full cursor-pointer items-center justify-between rounded bg-accent-100 text-core-600 shadow-md hover:bg-core-100 hover:text-core-600 disabled:cursor-not-allowed disabled:opacity-75 lg:text-lg;

    .ressources__download__link__button {
      @apply flex h-full items-center px-8;
    }

    .ressources__download__link__label {
      @apply flex h-full w-full items-center justify-center text-center text-sm font-bold uppercase leading-tight;
    }

    &:hover {
      .ressources__download__link__label {
        @apply text-primary-800;
      }
    }
  }

  :deep(#file-upload-declaration) {
    @apply w-full;
    .ressources__standalone_button__content {
      @apply flex w-full items-center justify-center;
    }
  }
</style>
