<template>
  <template v-if="!loading && detail">
    <commandes-details-main v-bind:detail="detail" />
    <refonte-collapse
      v-if="detail"
      class="mt-[12px]"
      v-bind:title_icon="IconFormations"
      title="Inscriptions"
      v-bind:start_open="true">
      <template v-if="detail.inscriptions && detail.inscriptions.length > 0">
        <div
          v-bind:key="`inscription-${index}`"
          v-for="(inscription, index) in detail.inscriptions"
          class="pb-[12px]">
          <refonte-separator
            v-if="index > 0 && detail.inscriptions.length > 1" />
          <refonte-table
            v-bind:key="`inscription-${index}`"
            v-bind:table_index="`inscription-${index}`"
            v-bind:columns="columnsInscriptions"
            v-bind:data="[formatInscription(inscription)]">
          </refonte-table>
          <div class="mt-[4px]">
            <refonte-label label="Participants" />
            <refonte-collapse
              v-bind:key="`participant-${index}-${indexParticipant}`"
              v-for="(
                participant, indexParticipant
              ) in inscription.participants"
              class="!bg-refonte-pale-violet-2"
              v-bind:title="`${participant.nom} (${participant.statut})`"
              v-bind:start_open="true"
              v-bind:header_blue="false"
              v-bind:class="indexParticipant > 0 ? 'mt-[12px]' : ''"
              v-bind:content_class="participant.prestations ? '!p-0' : ''">
              <template v-if="participant.prestations">
                <refonte-table
                  v-bind:key="`prestations-${index}-${indexParticipant}`"
                  v-bind:table_index="`prestations-${index}-${indexParticipant}`"
                  v-bind:columns="columnsPrestations"
                  v-bind:data="participant.prestations">
                </refonte-table>
              </template>
              <refonte-search-results-empty v-else />
            </refonte-collapse>
          </div>
          <div class="mt-[12px]">
            <refonte-label label="Frais fixes" />
            <refonte-card class="!bg-refonte-pale-violet-2 !p-0">
              <template v-if="inscription.prestations">
                <refonte-table
                  v-bind:key="`prestations-${index}-fixe`"
                  v-bind:table_index="`prestations-${index}-fixe`"
                  v-bind:columns="columnsPrestations"
                  v-bind:data="inscription.prestations">
                </refonte-table>
              </template>
              <refonte-search-results-empty v-else />
            </refonte-card>
          </div>
        </div>
        <refonte-separator />
        <refonte-label label="Synthèse" />
        <refonte-card class="!bg-refonte-pale-violet-2 !p-0">
          <template v-if="detail.synthese">
            <refonte-table
              key="prestations-synthese"
              table_index="prestations-synthese"
              v-bind:columns="columnsPrestations"
              v-bind:data="[detail.synthese]">
            </refonte-table>
          </template>
          <refonte-search-results-empty v-else />
        </refonte-card>
      </template>
      <refonte-search-results-empty v-else />
    </refonte-collapse>
    <refonte-collapse
      v-if="detail"
      class="mt-[12px]"
      v-bind:title_icon="IconFactures"
      title="Factures"
      v-bind:start_open="true">
      <template v-if="detail.factures && detail.factures.length > 0">
        <refonte-table
          key="factures-1"
          table_index="factures-1"
          v-bind:columns="columnsFactures"
          v-bind:data="detail.factures">
        </refonte-table>
      </template>
      <refonte-search-results-empty v-else />
    </refonte-collapse>
    <refonte-collapse
      v-if="detail"
      class="mt-[12px]"
      v-bind:title_icon="IconFactures"
      title="Règlements"
      v-bind:start_open="true">
      <template v-if="detail.reglements && detail.reglements.length > 0">
        <refonte-table
          key="reglements-1"
          table_index="reglements-1"
          v-bind:columns="columnsReglements"
          v-bind:data="detail.reglements"
          class="table-no-space-between">
        </refonte-table>
      </template>
      <refonte-search-results-empty v-else />
    </refonte-collapse>
  </template>
  <spinner
    v-else
    sizes="w-20 h-20"
    thickness="border-8" />
</template>
<script setup lang="ts">
  import Spinner from '@/components/utils/spinner.vue';
  import { h, onMounted, ref } from 'vue';
  import RefonteCollapse from '@/components/refonte/collapse/refonte-collapse.vue';
  import IconFactures from '@/assets/img/refonte/icons/menu/icon-bills.svg?component';
  import IconDownload from '@/assets/img/refonte/icons/display/icon-download.svg?component';
  import IconFormations from '@/assets/img/refonte/icons/menu/icon-training.svg?component';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';
  import RefonteTable from '@/components/refonte/tables/refonte-table.vue';
  import number_utils from '@/mixin/number_utils';
  import CommandesDetailsMain from '@/views/_espace/_refonte/gestion/_commandes-details/commandes-details-main.vue';
  import RefonteLabel from '@/components/refonte/inputs/refonte-label.vue';
  import RefonteCard from '@/components/refonte/cards/refonte-card.vue';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';
  import { PrestationPrice } from '@/types/gestion/inscriptions-details-types';
  import { useRoute } from 'vue-router';
  import { API } from '@/http-common';
  import { Facture } from '@/types/gestion/factures-types';
  import {
    buildProduitTypeLabel,
    buildProduitTypeShortcut
  } from '@/stores/utils';

  const detail = ref();
  const loading = ref<boolean>(false);

  const route = useRoute();
  const { reference } = route.query;

  const fetchCommande = async () => {
    const response = await API.get(`client/v2/commande/${reference}/details`);
    if (response && response.data) {
      detail.value = response.data;
    }
  };

  onMounted(async () => {
    loading.value = true;
    await fetchCommande();
    loading.value = false;
  });

  const formatInscription = (i) => {
    return {
      ...i,
      typeShortcut: buildProduitTypeShortcut(i.type.id, i.lienElearning),
      typeLabel: buildProduitTypeLabel(i.type.label, i.lienElearning)
    };
  };

  const columnsInscriptions = [
    {
      content_index: 'titre',
      tags_type: 'typeShortcut',
      tags_type_label: 'typeLabel',
      date: 'date',
      content_sub_text: (row: any) =>
        `${row.reference ? `Réf : ${row.reference}` : ''}`,
      width: 360
    },
    {
      content_index: 'site',
      label: 'Site',
      width: 360
    }
  ];

  const columnsFactures = [
    {
      content_index: 'reference',
      label: 'Référence',
      width: 308
    },
    {
      content_index: 'type',
      label: 'Type',
      width: 180
    },
    {
      content_index: 'date',
      label: 'Date',
      width: 120
    },
    {
      content_index: 'montant',
      label: 'Montant',
      width: 120,
      content_custom: (row: any) =>
        h('span', {
          innerHTML: number_utils.methods.formatCurrency(row.montant)
        })
    },
    {
      content_index: 'montantRegle',
      label: 'Payé',
      width: 120,
      content_custom: (row: any) =>
        h('span', {
          innerHTML: number_utils.methods.formatCurrency(row.montantRegle)
        })
    },
    {
      content_index: 'factureNom',
      label: 'Facture',
      width: 140,
      on_click: (rowIndex: number) => {},
      content_icon: () => IconDownload,
      class: (row: Facture) => '!flex-grow-0'
    }
  ];

  const columnsReglements = [
    {
      content_index: 'date',
      label: 'Date',
      width: 308
    },
    {
      content_index: 'reference',
      label: 'Référence',
      width: 180
    },
    {
      content_index: 'montant',
      label: 'Montant',
      width: 120,
      content_custom: (row: any) =>
        h('span', {
          innerHTML: number_utils.methods.formatCurrency(row.montant)
        })
    },
    {
      content_index: 'mode',
      label: 'Mode',
      width: 180
    }
  ];

  const columnsPrestations = [
    {
      content_index: 'libelle',
      label: 'Libellé',
      width: 308
    },
    {
      content_index: 'montantHT',
      label: 'Montant HT',
      width: 120,
      content_custom: (row: PrestationPrice) =>
        h('span', {
          innerHTML: number_utils.methods.formatCurrency(row.montantHT)
        })
    },
    {
      content_index: 'montantTVA',
      label: 'Montant TVA',
      width: 120,
      content_custom: (row: PrestationPrice) =>
        h('span', {
          innerHTML: number_utils.methods.formatCurrency(row.montantTVA)
        })
    },
    {
      content_index: 'montantTTC',
      label: 'Montant TTC',
      width: 120,
      content_custom: (row: PrestationPrice) =>
        h('span', {
          innerHTML: number_utils.methods.formatCurrency(row.montantTTC)
        })
    }
  ];
</script>

<style lang="scss" scoped>
  .table-no-space-between {
    :deep(.table-row-content) {
      justify-content: flex-start !important;

      .refonte-table-item {
        flex-grow: 0 !important;
      }
    }
  }
</style>
