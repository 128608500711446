<template>
  <div
    class="refonte-collapse-section"
    v-bind:class="props.class">
    <div
      class="collapse-section-header"
      v-on:click="() => toggleIsOpen()">
      <div class="collapse-section-header-title">
        <span>
          {{ props.title }}
        </span>
      </div>
      <div class="collapse-section-header-chevron">
        <IconChevronDown v-if="!isOpen" />
        <IconChevronUp v-else />
      </div>
    </div>
    <div
      v-if="isOpen"
      class="collapse-section-content"
      v-bind:class="props.content_class"
      v-bind:style="props.max_width ? `max-width: ${props.max_width}px` : ''">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
  import IconChevronDown from '@/assets/img/refonte/icons/display/icon-chevron-down.svg?component';
  import IconChevronUp from '@/assets/img/refonte/icons/display/icon-chevron-up.svg?component';
  import { ref } from 'vue';

  const props = withDefaults(
    defineProps<{
      title: string;
      max_width?: number;
      class?: string;
      content_class?: string;
    }>(),
    {
      class: '',
      content_class: ''
    }
  );

  const isOpen = ref<boolean>(false);

  function toggleIsOpen() {
    isOpen.value = !isOpen.value;
  }
</script>

<style lang="scss" scoped>
  .refonte-collapse-section {
    @apply flex flex-col self-stretch;
    gap: 4px;

    .collapse-section-header {
      @apply flex cursor-pointer items-center self-start;
      gap: 8px;
      padding: 8px 8px 8px 12px;

      .collapse-section-header-title {
        @apply text-refonte_blue-600;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
      }
      .collapse-section-header-chevron {
        @apply flex shrink-0 items-center justify-center;
        width: 16px;
        height: 16px;

        :deep(svg) {
          @apply text-refonte_blue-600;
          width: 16px;
          height: 16px;
        }
      }
    }
    .collapse-section-content {
      @apply flex flex-col items-start bg-refonte-white;
      gap: 12px;
      padding: 4px 8px;
      border-radius: 8px;
      max-width: 672px;
      width: 100%;
    }
  }
</style>
