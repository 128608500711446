<template>
  <div
    class="refonte-separator"
    v-bind:class="props.class">
    <div class="separator-content">
      <div class="separator-content-line" />
    </div>
  </div>
</template>

<script setup lang="ts">
  const props = withDefaults(
    defineProps<{
      class?: string;
    }>(),
    {
      class: ''
    }
  );
</script>

<style lang="scss" scoped>
  .refonte-separator {
    @apply flex items-center justify-center self-stretch;
    border-radius: 4px;

    .separator-content {
      @apply flex flex-grow items-start;
      padding: 3px;

      .separator-content-line {
        @apply bg-refonte_blue-300;
        opacity: 0.3;
        min-width: 24px;
        width: 100%;
        height: 2px;
        border-radius: 4px;
      }
    }
  }
</style>
