import IconFlagParcours from '@/assets/img/refonte/icons/information/icon-flag-parcours.svg?component';
import IconTestPositionnement from '@/assets/img/refonte/icons/information/icon-test-positionnement.svg?component';
import IconTestValidation from '@/assets/img/refonte/icons/information/icon-test-validation.svg?component';
import IconFactures from '@/assets/img/refonte/icons/menu/icon-bills.svg?component';
import IconDashboard from '@/assets/img/refonte/icons/menu/icon-dashboard.svg?component';
import IconEstablishments from '@/assets/img/refonte/icons/menu/icon-entities.svg?component';
import IconCommandes from '@/assets/img/refonte/icons/menu/icon-orders.svg?component';
import IconParticipants from '@/assets/img/refonte/icons/menu/icon-trainees.svg?component';
import IconFormations from '@/assets/img/refonte/icons/menu/icon-training.svg?component';
import IconInterlocuteurs from '@/assets/img/refonte/icons/menu/icon-user.svg?component';

export const ICONS = {
    IconDashboard,
    IconFormations,
    IconFactures,
    IconCommandes,
    IconParticipants,
    IconInterlocuteurs,
    IconEstablishments,
    IconTestPositionnement,
    IconTestValidation,
    IconFlagParcours
};
