<template>
  <label
    class="refonte-checkbox-rights"
    v-bind:class="props.class">
    <input
      type="checkbox"
      class="checkbox-rights-input"
      v-bind:checked="props.value"
      v-on:change="updateValue(value)" />
    <span class="checkbox-rights-checkmark"></span>
    <div class="checkbox-rights-content">
      <span class="checkbox-rights-label">
        {{ props.label }}
      </span>
      <div
        v-if="props.rights && props.rights.length > 0"
        class="checkbox-rights-list">
        <span
          v-for="(right, index) in rights"
          v-bind:key="index">
          {{ right }}
        </span>
      </div>
    </div>
  </label>
</template>

<script setup lang="ts">
  const props = withDefaults(
    defineProps<{
      value?: boolean;
      label: string;
      rights: string[];
      class?: string;
    }>(),
    {
      value: false,
      class: ''
    }
  );

  const emit = defineEmits(['input']);

  function updateValue(value: boolean) {
    emit('input', value);
  }
</script>

<style lang="scss" scoped>
  .refonte-checkbox-rights {
    @apply relative flex cursor-pointer items-start self-stretch;
    padding: 4px 8px 4px 28px;
    gap: 8px;
    user-select: none;

    .checkbox-rights-input {
      @apply absolute h-0 w-0 cursor-pointer opacity-0;
    }
    .checkbox-rights-checkmark {
      @apply absolute border border-refonte_blue-100 bg-refonte-white;
      top: 8px;
      left: 8px;
      height: 12px;
      width: 12px;
      border-radius: 3px;

      &:after {
        @apply absolute;
        content: '';
        display: none;
        left: 3px;
        top: 1px;
        width: 4px;
        height: 7px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
    input:checked ~ .checkbox-rights-checkmark {
      @apply absolute border-refonte_blue-400 bg-refonte_blue-400;

      &:after {
        display: block;
      }
    }
    .checkbox-rights-content {
      @apply flex flex-grow flex-col items-start justify-center text-refonte-black;
      gap: 4px;

      .checkbox-rights-label {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
      }
      .checkbox-rights-list {
        @apply flex flex-col items-start;
        gap: 2px;

        span {
          font-size: 13px;
          font-weight: 400;
          line-height: 16px;
        }
      }
    }
  }
</style>
