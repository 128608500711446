import { IdLabel } from '@/types/gestion/entreprise';

export enum InscriptionType {
    SESSION = 'SESSION',
    ELEARNING = 'ELEARNING',
    CURSUS = 'CURSUS'
}

export type InscriptionFormation = {
    inscriptionId: number;
    produitId: number;
    inscriptionType: InscriptionType;
};

export type Inscription = {
    id: string;
    commandeReference?: string;
    clientId: number;
    clientDenomination: string;
    clientSiret?: string;
    typeShortcut: string[];
    typeLabel: string[];
    statutLabel: string;
    modeLabel?: string;
    titre: string;
    startTime?: string;
    endTime?: string;
    convention?: Document;
    pif?: Document;
    conventionStatut?: string;
    pifStatut?: string;
    datesFormations: string;
    hasCertificats?: boolean;
    certificatLabel?: string;
    produit: Produit;
    participantNomPrenom: string;
    lieu?: string;
    testPositionnementStatut?: string;
    dateInscription?: string;
    organisateur?: string;
    dureeMinutes?: number;
    inscriptionType: InscriptionType;
};

export type InscriptionResponse = {
    id: string;
    nomPrenom: string;
    dateDebut: string;
    dateFin: string;
    produit: Produit;
    statut: IdLabel;
    mode: IdLabel;
    client: {
        id: number;
        denomination: string;
        siret?: string;
    };
    convention?: Document;
    pif?: Document;
    hasCertificats?: boolean;

    familleStageEC?: boolean;
    familleStageCAC?: boolean;
    testPositionnement?: {
        disponible?: boolean;
        realise?: boolean;
    };
    dateInscription?: string;
    commandeReference?: string;
    organisateur?: string;
    lieu?: string;
    inscriptionType: InscriptionType;
};

export type Participant = {
    id: number;
    nomPrenom: string;
};

export type Produit = {
    id: number;
    titre: string;
    type: IdLabel;
    dureeMinutes?: number;
    lienElearning?: boolean;
};

export type Document = {
    id: number;
    disponible: boolean;
    signee: boolean;
    validee: boolean;
};

export const typeMapping: { [key: number]: string } = {
    1: 'presentiel',
    2: 'e-learning',
    3: 'webinar',
    4: 'parcours',
    5: 'classe-virtuelle',
    6: 'conference',
    7: 'atelier',
    8: 'cursus'
};
