<template>
  <div class="flex h-full items-center justify-center">
    <div>{{ params.noRowsMessageFunc() }}</div>
  </div>
</template>
<script>
  export default {
    data() {
      return {};
    },
    beforeMount() {},
    mounted() {},
    methods: {}
  };
</script>
<style lang="scss" scoped></style>
