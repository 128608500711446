<template>
  <div v-bind:class="`flex w-full flex-wrap lg:${size} p-4`">
    <div class="flex w-full flex-wrap rounded-lg bg-core-200 shadow">
      <div
        class="my-4 flex w-full justify-center text-center text-2xl font-bold text-core-900">
        {{ label }}
      </div>
      <div
        class="flex w-full justify-center py-4 text-6xl font-bold text-jinius-green">
        {{ value }}
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      label: String,
      value: Number,
      size: String
    }
  };
</script>
<style lang="scss" scoped></style>
