<template>
  <template v-if="!loading && detail">
    <inscriptions-details-main v-bind:detail="detail" />
    <refonte-collapse
      v-if="detail.titre"
      class="mt-[12px]"
      v-bind:title_icon="IconMyAccount"
      title="Participant"
      v-bind:start_open="true">
      <template v-if="detail.participant">
        <refonte-table
          key="participant-1"
          table_index="participant-1"
          v-bind:columns="columnsParticipant"
          v-bind:data="[detail.participant]">
        </refonte-table>
      </template>
      <refonte-search-results-empty v-else />
    </refonte-collapse>
    <refonte-collapse
      v-if="detail.titre"
      class="mt-[12px]"
      v-bind:title_icon="IconTestValidation"
      title="Documents"
      v-bind:start_open="true">
      <div class="flex flex-wrap gap-[8px] pb-[12px]">
        <refonte-item-file
          id="convention"
          v-if="detail.convention?.id"
          title="Convention de formation"
          v-bind:on_click_download="
            () => downloadConvention(detail?.clientId, detail?.convention)
          "
          v-bind:file_signed="
            detail.convention.signee || detail.convention.validee
          "
          v-bind:upload_url="
            getConventionUploadUrl(detail?.clientId, detail?.convention)
          "
          v-bind:on_click_upload_refresh="fetchInscription" />
        <refonte-item-file
          id="convention-indiv"
          title="Convention individuelle"
          v-if="detail?.conventionIndividuelle?.id"
          v-bind:on_click_download="
            () =>
              downloadConvention(
                detail?.clientId,
                detail?.conventionIndividuelle
              )
          "
          v-bind:file_signed="
            detail.conventionIndividuelle.signee ||
            detail.conventionIndividuelle.validee
          "
          v-bind:upload_url="
            getConventionUploadUrl(
              detail?.clientId,
              detail?.conventionIndividuelle
            )
          "
          v-bind:on_click_upload_refresh="fetchInscription" />
        <refonte-item-file
          id="pif"
          title="PIF"
          v-if="detail.pif?.id"
          v-bind:on_click_download="
            () => downloadPif(detail?.clientId, detail?.pif)
          "
          signed_label="Signé"
          v-bind:file_signed="detail.pif?.signee || detail.pif.validee"
          v-bind:upload_url="getPifUploadUrl(detail?.clientId, detail?.pif)"
          v-bind:on_click_upload_refresh="fetchInscription" />
        <refonte-item-file
          id="certificat"
          title="Certificat de réalisation"
          v-bind:no_download_text="detail.termine ? '-' : 'À venir'"
          v-bind:on_click_download="
            detail.certificatId
              ? () => downloadCertificat(detail?.clientId, detail?.certificatId)
              : undefined
          "
          v-bind:no_signature_needed="true" />
        <refonte-item-file
          id="bon-commande"
          title="Bon de commande"
          v-bind:on_click_download="
            () => downloadBonCommande(detail?.clientId, detail?.commandeId)
          "
          v-bind:no_signature_needed="true" />
      </div>
    </refonte-collapse>
    <refonte-collapse
      v-if="detail.titre && detail.factures && detail.factures.length > 0"
      class="mt-[12px]"
      v-bind:title_icon="IconFactures"
      title="Factures"
      v-bind:start_open="true">
      <template v-if="detail.factures && detail.factures.length > 0">
        <refonte-table
          key="factures-1"
          table_index="factures-1"
          v-bind:columns="columnsFactures"
          v-bind:data="detail.factures">
        </refonte-table>
      </template>
      <refonte-search-results-empty v-else />
    </refonte-collapse>
    <refonte-collapse
      v-if="detail.titre && detail.prestations && detail.prestations.length > 0"
      class="mt-[12px]"
      v-bind:title_icon="IconList"
      title="Prestations"
      v-bind:start_open="true">
      <template v-if="detail.prestations">
        <refonte-table
          key="prestations-1"
          table_index="prestations-1"
          v-bind:columns="columnsPrestationsPrices"
          v-bind:data="detail.prestations">
        </refonte-table>
      </template>
      <refonte-search-results-empty v-else />
    </refonte-collapse>
    <refonte-collapse
      v-if="
        detail.titre &&
        detail.otherParticipants &&
        detail.otherParticipants.length > 0
      "
      class="mt-[12px]"
      v-bind:title_icon="IconConventionCollective"
      v-bind:title="`Autres participants (${detail.otherParticipants.length})`">
      <template v-if="detail.otherParticipants">
        <refonte-table
          v-bind:on_details="goToDetailsParticipant"
          key="otherParticipants-1"
          table_index="otherParticipants-1"
          v-bind:columns="columnsParticipants"
          v-bind:data="detail.otherParticipants"
          class="table-no-space-between">
        </refonte-table>
      </template>
      <refonte-search-results-empty v-else />
    </refonte-collapse>
  </template>
  <spinner
    v-else
    sizes="w-20 h-20"
    thickness="border-8" />
  <inscriptions-details-participant-satisfaction-modal
    v-if="selectedParticipant"
    v-bind:show="isParticipantSatisfactionModalOpen"
    v-bind:on_close="closeParticipantSatisfactionModal"
    v-bind:participant="selectedParticipant"
    v-bind:session_reference="detail.catalogueReference"
    v-bind:client_id="detail.clientId" />
</template>
<script setup lang="ts">
  import Spinner from '@/components/utils/spinner.vue';
  import { computed, h, onMounted, ref, watch } from 'vue';
  import InscriptionsDetailsMain from '@/views/_espace/_refonte/gestion/_inscriptions-details/inscriptions-details-main.vue';
  import RefonteCollapse from '@/components/refonte/collapse/refonte-collapse.vue';
  import IconTestValidation from '@/assets/img/refonte/icons/information/icon-test-validation.svg?component';
  import IconList from '@/assets/img/refonte/icons/information/icon-list.svg?component';
  import IconMyAccount from '@/assets/img/refonte/icons/users/icon-my-account.svg?component';
  import IconConventionCollective from '@/assets/img/refonte/icons/users/icon-convention-collective.svg?component';
  import IconFactures from '@/assets/img/refonte/icons/menu/icon-bills.svg?component';
  import IconDownload from '@/assets/img/refonte/icons/display/icon-download.svg?component';
  import IconEye from '@/assets/img/refonte/icons/display/icon-eye.svg?component';
  import {
    Detail,
    Participant,
    PrestationPrice
  } from '@/types/gestion/inscriptions-details-types';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';
  import RefonteTable from '@/components/refonte/tables/refonte-table.vue';
  import RefonteItemFile from '@/components/refonte/items/refonte-item-file.vue';
  import number_utils from '@/mixin/number_utils';
  import { Facture } from '@/types/gestion/factures-types';
  import { useRoute, useRouter } from 'vue-router';
  import { API } from '@/http-common';
  import {
    downloadBonCommande,
    downloadCertificat,
    downloadConvention,
    downloadFacture,
    downloadPif,
    getConventionUploadUrl,
    getPifUploadUrl
  } from '@/mixin/client_download__functions';
  import InscriptionsDetailsParticipantSatisfactionModal from '@/views/_espace/_refonte/gestion/_inscriptions-details/inscriptions-details-modal-participant-satisfaction.vue';

  const detail = ref<Detail>();
  const route = useRoute();
  const loading = ref<boolean>(false);
  const id = computed(() => route.query.id);
  const isParticipantSatisfactionModalOpen = ref<boolean>(false);
  const selectedParticipant = ref<Participant>();

  watch(
    () => route.query.id,
    () => fetchInscription()
  );

  const fetchInscription = async () => {
    loading.value = true;
    const response = await API.get(
      `client/v2/inscriptions/elearning/${id.value}/details`
    );

    if (response && response.data) {
      detail.value = mapInscriptionDetail(response.data);
    }
    loading.value = false;
  };

  const mapInscriptionDetail = (detail) => {
    return { ...detail };
  };
  onMounted(async () => {
    await fetchInscription();
  });

  function closeParticipantSatisfactionModal() {
    selectedParticipant.value = undefined;
    isParticipantSatisfactionModalOpen.value = false;
  }

  const router = useRouter();
  const goToDetailsParticipant = (row: Participant) => {
    router.push(`/refonte/gestion/inscriptions-elearning?id=${row.id}`);
  };

  const getParticipantInscriptionStatus = (status: string) => {
    switch (status) {
      case 'En attente':
        return 'En attente';
      case 'Confirmé':
        return 'Confirmée';
      case 'Annulé':
        return 'Annulée';
      case 'Transférée':
        return 'Annulée';
      default:
        return '-';
    }
  };

  const columnsParticipant = [
    {
      content_index: 'nomPrenom',
      label: 'Nom',
      width: 308
    },
    {
      content_index: 'inscription',
      label: 'Inscription',
      width: 120,
      content_custom: (row: Participant) =>
        h('span', {
          innerHTML: getParticipantInscriptionStatus(row.inscription.label)
        })
    },
    {
      content_index: 'etablissement',
      label: 'Etablissement',
      width: 120
    },
    {
      content_index: 'satisfaction',
      label: 'Satisfaction',
      width: 120,
      content_custom: (row: Participant) =>
        h('span', {
          innerHTML: row.satisfaction ? row.satisfaction.label : '-'
        }),
      content_icon: (row: Participant) =>
        row.satisfaction?.label === 'Réalisé' ? IconEye : undefined,
      on_click_disabled: (row: Participant) =>
        row.satisfaction && row.satisfaction.label !== 'Réalisé',
      class: () => '!flex-grow-0',
      on_click: (row: Participant) => {
        selectedParticipant.value = row;
        isParticipantSatisfactionModalOpen.value = true;
      }
    },
    {
      content_index: 'elearning',
      label: 'E-learning',
      width: 140,
      content_custom: (row: Participant) =>
        h('span', {
          innerHTML: row.elearning ? row.elearning.label : '-'
        })
    }
  ];

  const columnsPrestationsPrices = [
    {
      content_index: 'libelle',
      label: 'Libellé',
      width: 308
    },
    {
      content_index: 'montantHT',
      label: 'Montant HT',
      width: 120,
      content_custom: (row: PrestationPrice) =>
        h('span', {
          innerHTML: number_utils.methods.formatCurrency(row.montantHT)
        })
    },
    {
      content_index: 'montantTVA',
      label: 'Montant TVA',
      width: 120,
      content_custom: (row: PrestationPrice) =>
        h('span', {
          innerHTML: number_utils.methods.formatCurrency(row.montantTVA)
        })
    },
    {
      content_index: 'montantTTC',
      label: 'Montant TTC',
      width: 120,
      content_custom: (row: PrestationPrice) =>
        h('span', {
          innerHTML: number_utils.methods.formatCurrency(row.montantTTC)
        })
    },
    {
      content_index: 'montantRegle',
      label: 'Montant réglé',
      width: 120,
      content_custom: (row: PrestationPrice) =>
        h('span', {
          innerHTML: number_utils.methods.formatCurrency(row.montantRegle)
        })
    }
  ];

  const columnsParticipants = [
    {
      content_index: 'nomPrenom',
      label: 'Nom',
      width: 308
    },
    {
      content_index: 'inscription',
      label: 'Inscription',
      width: 120,
      content_custom: (row: Participant) =>
        h('span', {
          innerHTML: getParticipantInscriptionStatus(row.statut)
        })
    },
    {
      content_index: 'commandeReference',
      label: 'Référence',
      width: 308
    }
  ];

  const columnsFactures = [
    {
      content_index: 'reference',
      label: 'Référence',
      width: 308
    },
    {
      content_index: 'type',
      label: 'Type',
      width: 180
    },
    {
      content_index: 'date',
      label: 'Date',
      width: 120
    },
    {
      content_index: 'montant',
      label: 'Montant',
      width: 120,
      content_custom: (row: any) =>
        h('span', {
          innerHTML: number_utils.methods.formatCurrency(row.montant)
        })
    },
    {
      content_index: 'montantRegle',
      label: 'Payé',
      width: 120,
      content_custom: (row: any) =>
        h('span', {
          innerHTML: number_utils.methods.formatCurrency(row.montantRegle)
        })
    },
    {
      content_index: 'factureNom',
      label: 'Facture',
      width: 140,
      on_click: (row: Facture) => {
        downloadFacture(detail.value?.clientId, row.id);
      },
      content_icon: () => IconDownload,
      class: (row: Facture) => '!flex-grow-0'
    }
  ];
</script>

<style lang="scss" scoped>
  .table-no-space-between {
    :deep(.table-row-content) {
      justify-content: flex-start !important;

      .refonte-table-item {
        flex-grow: 0 !important;
      }
    }
  }
</style>
