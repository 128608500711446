const map_unsaved_forms = [
    {
        space_id: 'account',
        sub_space_id: 'informations',
        savedStateName: 'savedAccountForm',
        stateName: 'accountForm'
    },
    {
        space_id: 'account',
        sub_space_id: 'preferences',
        savedStateName: 'savedPreferencesForm',
        stateName: 'preferencesForm'
    },
    {
        space_id: 'entreprise',
        sub_space_id: 'entreprise',
        sub_children_id: 'informations',
        savedStateName: 'savedEntiteForm',
        stateName: 'entiteForm'
    },
    {
        space_id: 'formateur',
        sub_space_id: 'cv',
        savedStateName: 'savedCvForm',
        stateName: 'cvForm'
    }
];

export { map_unsaved_forms };
