<template>
  <modal-box
    v-if="modal_show"
    v-bind:width_class="'w-full max-w-screen-md'"
    v-on:close="onclose">
    <template v-slot:modal-header>
      <h1 class="text-xl font-bold text-core-900">
        Enregistrer les modifications
      </h1>
    </template>

    <template v-slot:modal-body>
      <div class="my-8 flex w-full items-center justify-center px-4 font-bold">
        <input
          type="checkbox"
          class="mx-4 flex cursor-pointer"
          id="checkbox-consent"
          v-model="consent" />
        <label
          for="checkbox-consent"
          class="flex cursor-pointer items-center justify-center px-6 text-sm leading-tight hover:text-core-900 lg:text-center lg:text-base">
          {{ consent_text }}
        </label>
      </div>
    </template>

    <template v-slot:modal-footer>
      <div class="inline-flex w-full justify-center space-x-4">
        <button
          class="submit__button"
          v-on:click.prevent="submit"
          v-bind:disabled="!consent">
          {{ submit_text }}
        </button>
      </div>
    </template>
  </modal-box>
</template>
<script>
  import modalBox from '@/components/utils/modal-box.vue';
  export default {
    components: { modalBox },
    props: ['modal_show', 'onclose', 'submit', 'consent_text', 'submit_text'],
    data: () => ({ consent: false }),
    watch: {
      modal_show() {
        this.consent = false;
      }
    }
  };
</script>
<style lang="scss" scoped></style>
