<template>
  <div
    v-if="params.value"
    class="flex h-full w-full flex-wrap items-center justify-center">
    <button
      class="btn"
      v-bind:class="[
        { 'btn--available': is_open },
        { 'btn--finished': is_done },
        { 'btn--outdated': is_closed }
      ]"
      v-on:click="is_open ? params.onclick($event, params) : null"
      v-bind:disabled="is_aucun">
      {{ display_label }}
    </button>
  </div>
</template>
<script>
  export default {
    computed: {
      is_dispo() {
        const is_dispo =
          'testPositionnementDisponible' in this.params.value
            ? this.params.value.testPositionnementDisponible
            : null;

        return is_dispo;
      },
      is_realise() {
        return this.params.value.testPositionnementRealise;
      },
      is_aucun() {
        return this.is_dispo === null;
      },
      is_open() {
        return this.is_dispo && !this.is_realise;
      },
      is_done() {
        return this.is_realise;
      },
      is_closed() {
        return this.is_dispo === false && !this.is_realise;
      },
      display_label() {
        let label = 'Aucun';

        if (this.is_open) {
          label = 'Répondre au test';
        } else if (this.is_done) {
          label = 'Réalisé';
        } else if (this.is_closed) {
          label = 'Non Réalisé';
        }

        return label;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .btn {
    @apply mx-2 w-64 max-w-full items-center justify-center rounded py-2 text-sm font-bold leading-none disabled:cursor-not-allowed disabled:bg-transparent disabled:text-accent-400;
    &.btn--available {
      @apply bg-jinius-dark-green text-white hover:bg-jinius-green;
    }
    &.btn--finished {
      @apply cursor-auto bg-transparent text-jinius-green;
    }
    &.btn--outdated {
      @apply cursor-auto bg-transparent text-jinius-red;
    }
  }
</style>
