<template>
  <section class="section__primary">
    <h1 class="section__title">Informations générales</h1>
    <div class="section__body relative">
      <div
        class="form__item"
        v-if="nature">
        <form-label
          label="Nature"
          class="font-bold leading-none" />
        <p class="pb-2 pl-6 pt-3 uppercase text-core-700">
          {{ nature.label }}
        </p>
      </div>

      <div
        class="form__item"
        v-if="entite_form.reference">
        <form-label
          label="Référence"
          class="font-bold leading-none" />
        <p class="pb-2 pl-6 pt-3 uppercase text-core-700">
          {{ entite_form.reference }}
        </p>
      </div>

      <template v-for="inputItem in inputs">
        <div
          v-bind:key="inputItem.name"
          v-if="!inputItem.type || inputItem.type === nature.shortcut"
          class="form__item">
          <input-box
            v-bind:key="
              inputItem.name === 'siret' && savedEntiteForm[inputItem.name]
            "
            class_name="no-box"
            v-bind:not_required_label="inputItem.facultatif"
            v-bind:name="inputItem.name"
            v-bind:placeholder="inputItem.placeholder"
            v-bind:label="inputItem.placeholder"
            v-bind:maxlength="inputItem.maxLength"
            v-bind:value="entite_form[inputItem.name]"
            v-on:input="set_attribute($event, [inputItem.name])"
            v-bind:required="inputItem.required"
            v-bind:mask="inputItem.mask"
            v-bind:class="{
              'input-error':
                v$.entiteForm[inputItem.name] &&
                v$.entiteForm[inputItem.name].$error
            }"
            v-bind:disabled="
              disabled ||
              (inputItem.name === 'siret' &&
                !entite_form.valideSansSiret &&
                savedEntiteForm[inputItem.name] &&
                savedEntiteForm[inputItem.name].length === 14)
            " />
        </div>
      </template>

      <template v-if="nature && nature.shortcut === 'siret'">
        <div class="form__item">
          <div class="flex w-full">
            <div class="flex w-1/12 items-center justify-center">
              <font-awesome-icon
                size="lg"
                icon="building" />
            </div>
            <div class="flex w-11/12 flex-wrap px-4 py-3">
              <div class="flex w-full font-bold">Etablissement siège</div>
              <yes-no-radio-buttons
                input_name="etablissementSiege"
                v-bind:input_value="entite_form.etablissementSiege"
                v-bind:disabled="disabled"
                v-bind:update_value="
                  (value) => set_attribute(value, 'etablissementSiege')
                " />
            </div>
          </div>
        </div>
        <div class="form__item">
          <div class="flex w-full">
            <div class="flex w-11/12">
              <select-box
                v-bind:value="
                  trancheEffectifs.find(
                    (trancheEffectif) =>
                      entite_form.trancheEffectifs === trancheEffectif.id
                  )
                "
                v-on:input="
                  (value) => set_attribute(value.id, 'trancheEffectifs')
                "
                v-bind:categories="trancheEffectifs"
                label="Tranche d'effectif"
                placeholder="Tranche d'effectif"
                no_capitalize_label
                tracking="id"
                class_name="no-box"
                v-bind:disabled="disabled" />
            </div>
            <div class="flex w-1/12 items-center justify-center">
              <font-awesome-icon
                size="lg"
                class="text-core-900"
                v-tooltip="{
                  content: `<ul>
          <li>Unités non employeuses: pas de salarié au cours de l'année de référence et pas d'effectif au 31/12</li>
          <li>0 salarié : n'ayant pas d'effectif au 31/12 mais ayant employé des salariés au cours de l'année de référence</li>
        </ul>`
                }"
                icon="question-circle" />
            </div>
          </div>
        </div>
        <div class="form__item">
          <div class="flex w-full items-center">
            <div class="flex w-full sm:w-1/4">
              <input-box
                class_name="no-box w-full flex"
                name="activite"
                placeholder="Activité"
                label="Activité"
                not_required_label
                v-bind:maxlength="6"
                v-bind:value="entite_form.activite"
                v-on:input="set_attribute($event, 'activite')"
                v-bind:disabled="disabled" />
            </div>

            <div class="flex h-full w-full pl-6 sm:w-3/4">
              <div
                v-if="entite_form.activite"
                v-bind:class="`flex w-full items-center justify-center self-end rounded px-3 pb-0.5 pt-1 text-center text-xs font-bold uppercase italic leading-none shadow-inner ${activites[entite_form.activite] ? 'bg-jinius-green text-white' : 'bg-core-200 text-jinius-red'}`">
                {{
                  activites[entite_form.activite]
                    ? activites[entite_form.activite]
                    : 'Code inexistant dans la nomenclature'
                }}
              </div>
            </div>
          </div>
        </div>

        <div class="form__item">
          <div class="flex w-full items-center">
            <div class="flex w-full sm:w-1/4">
              <input-box
                class_name="no-box w-full flex"
                name="categorieJuridique"
                placeholder="Catégorie juridique"
                label="Catégorie juridique"
                not_required_label
                v-bind:maxlength="4"
                v-bind:value="entite_form.categorieJuridique"
                v-on:input="set_attribute($event, 'categorieJuridique')"
                v-bind:disabled="disabled" />
            </div>
            <div class="flex h-full w-full pl-6 sm:w-3/4">
              <div
                v-if="entite_form.categorieJuridique"
                v-bind:class="`flex w-full items-center justify-center self-end rounded px-3 pb-1 pt-1.5 text-center text-xs font-bold uppercase italic shadow-inner ${categoriesJuridiques[entite_form.categorieJuridique] ? 'bg-jinius-green text-white' : 'bg-core-200 text-jinius-red'}`">
                {{
                  categoriesJuridiques[entite_form.categorieJuridique]
                    ? categoriesJuridiques[entite_form.categorieJuridique]
                    : 'Catégorie inexistante'
                }}
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="form__item">
        <select-box
          v-bind:value="
            territorialites.find(
              (territorialite) =>
                entite_form.territorialiteId === territorialite.id
            )
          "
          v-on:input="(value) => set_attribute(value.id, 'territorialiteId')"
          v-bind:categories="territorialites"
          label="Territorialité"
          placeholder="Territorialité"
          no_capitalize_label
          tracking="id"
          class_name="no-box"
          disabled />
      </div>
    </div>
  </section>
</template>
<script>
  import {
    civilites,
    natureList,
    territorialites,
    trancheEffectifs
  } from '@/assets/data/data_form';
  import formLabel from '@/components/utils/form-label.vue';
  import inputBox from '@/components/utils/input-box.vue';
  import selectBox from '@/components/utils/select-box';
  import yesNoRadioButtons from '@/components/utils/yes-no-radio-buttons';
  import { ACTION_TYPES } from '@/store/constants/ActionTypes';
  import { createNamespacedHelpers } from 'vuex';

  const { mapState: mapStateClient, mapActions: mapActionsClient } =
    createNamespacedHelpers('client');

  export default {
    components: {
      formLabel,
      inputBox,
      selectBox,
      yesNoRadioButtons
    },
    props: ['v$', 'set_attribute', 'entite_form', 'disabled'],
    computed: {
      civilites: () => civilites,
      trancheEffectifs: () => trancheEffectifs,
      territorialites: () => territorialites,
      ...mapStateClient([
        'activites',
        'categoriesJuridiques',
        'savedEntiteForm'
      ]),
      nature() {
        if (this.entite_form && this.entite_form.natureId) {
          return natureList.find((n) => n.id === this.entite_form.natureId);
        }

        return undefined;
      },
      inputs() {
        return [
          {
            name: 'siret',
            placeholder: this.entite_form.valideSansSiret
              ? 'Équivalent SIRET'
              : 'SIRET',
            type: 'siret',
            required: !this.entite_form.valideSansSiret,
            mask: this.entite_form.valideSansSiret
              ? undefined
              : '000 000 000 00000'
          },
          {
            name: 'rna',
            placeholder: 'RNA',
            type: 'rna'
          },
          {
            name: 'denomination',
            placeholder: 'Dénomination',
            required: true,
            maxLength: 100
          },
          {
            name: 'numeroTVAIntra',
            placeholder: 'Numéro de TVA Intra'
          }
        ];
      }
    },
    async created() {
      await this.fetchDataForm();
    },
    methods: {
      ...mapActionsClient([ACTION_TYPES.FETCH_INSEE_INFOS]),
      async fetchDataForm() {
        this[ACTION_TYPES.FETCH_INSEE_INFOS]();
      }
    }
  };
</script>
<style lang="scss" scoped></style>
