<template>
  <details-view
    v-if="!loading"
    v-bind:session="session"
    espace="formateur"
    v-bind:go_to_details="goToDetails"
    v-bind:fetch_session="fetchSession"
    v-bind:ressource_base_url="`formateur/session/${session_reference}`"
    back_base_url="formateur/session" />
  <spinner
    sizes="w-20 h-20"
    v-else
    thickness="border-8" />
</template>

<script>
  import spinner from '@/components/utils/spinner';
  import { API } from '@/http-common';
  import detailsView from '@/views/_espace/details';
  export default {
    components: {
      detailsView,
      spinner
    },
    props: {},
    data() {
      return {
        loading: false,
        session: {}
      };
    },
    computed: {
      session_reference() {
        return this.$route.query.reference;
      }
    },
    watch: {
      async session_reference() {
        // Nécessaire dans un sous produit de parcours
        await this.fetchSession();
      }
    },
    async created() {
      await this.fetchSession();
    },
    methods: {
      async fetchSession() {
        this.loading = true;
        const response = await API.get(
          `formateur/session/${this.session_reference}/details`,
          this.$header_skip_redirection
        );

        if (response && response.data) {
          this.session = response.data;
        }
        this.loading = false;
      },
      goToDetails(reference) {
        if (reference) {
          this.$router.push(`/formateur/details?reference=${reference}`);
        }
      }
    }
  };
</script>
