<template>
  <div class="w-[30rem]">
    <div
      class="mt-2 flex w-full flex-wrap space-y-3 rounded border border-accent-200 p-4">
      <button
        class="ressources__download__link__container flex w-full"
        v-on:click="
          downloadFile('/stage/stagiaire/pif/file', 'modele_pif.pdf')
        ">
        <div class="ressources__download__link__label">
          Télécharger le modèle du PIF
        </div>
        <div class="ressources__download__link__button">
          <div
            class="ressources__standalone_button__content"
            title="Télécharger le PIF à signer">
            <font-awesome-icon
              class="text-xl"
              icon="download" />
          </div>
        </div>
      </button>

      <div class="ressources__download__link__container flex w-full">
        <div class="ressources__download__link__label w-full">
          <file-upload
            id="pif-upload"
            title="Téléverser le PIF signé"
            v-bind:is_file_uploaded="pif_stage.pif"
            v-bind:upload_url="'/stage/stagiaire/pif/file'"
            v-bind:refresh_session="refresh">
            <div class="w-full">
              Téléverser mon PIF signé
              <p
                class="text-sm font-normal italic leading-tight text-accent-500">
                (signé par le participant, seuls les documents de type PDF sont
                acceptés)
              </p>
            </div>
          </file-upload>
        </div>
      </div>
      <button
        v-if="pif_stage.pif"
        class="ressources__download__link__container flex w-full"
        v-on:click="
          downloadFile('/stage/stagiaire/pif/file/signee', 'pif.pdf')
        ">
        <div class="ressources__download__link__label">
          Consulter mon PIF actuel
        </div>
        <div class="ressources__download__link__button">
          <div
            class="ressources__standalone_button__content"
            title="Télécharger le PIF à signer">
            <font-awesome-icon
              class="text-xl"
              icon="download" />
          </div>
        </div>
      </button>
    </div>
    <a
      target="_blank"
      v-on:click="openUrlLms"
      class="mt-2 flex h-16 w-full cursor-pointer items-center justify-center space-x-4 rounded bg-jinius-dark-green px-5 py-3 text-center text-xl font-bold text-white shadow hover:opacity-75 disabled:cursor-not-allowed disabled:bg-core-200 disabled:text-core-400"
      v-bind:disabled="!hasAccessLms"
      v-tooltip="
        !hasAccessLms
          ? {
              content: `<div class='text-lg p-4'>Merci de télécharger le gabarit du PIF (Protocole individuel de formation), de le remplir puis de téléverser</div>`,
              placement: 'top'
            }
          : {}
      ">
      <span>Accéder au module e-learning</span>
      <font-awesome-icon
        class="text-xl"
        icon="external-link-alt" />
    </a>
  </div>
</template>

<script>
  import fileUpload from '@/components/utils/file-upload.vue';
  import { API } from '@/http-common';

  export default {
    components: { fileUpload },
    props: ['pif_stage', 'refresh'],
    computed: {
      hasAccessLms() {
        return (
          this.pif_stage && this.pif_stage.accesElearning && this.pif_stage.pif
        );
      }
    },
    methods: {
      async openUrlLms() {
        const response = await API.get(
          '/stage/stagiaire/lms/lien',
          this.$header_skip_redirection
        );

        if (response && response.data && response.data.url) {
          window.open(response.data.url);
        }
      },
      async downloadFile(url, nom) {
        const response = await API.get(url, {
          responseType: 'blob',
          ...this.$header_skip_redirection
        });
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', nom);
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .ressources__download__link__container {
    @apply flex cursor-pointer items-center justify-between rounded bg-accent-100 p-4 text-core-600 shadow-md hover:bg-accent-200 hover:text-core-600 disabled:cursor-not-allowed disabled:opacity-75 lg:text-lg;

    .ressources__download__link__button {
      @apply flex items-center;
    }

    .ressources__download__link__label {
      @apply leading-tight;
    }

    &:hover {
      .ressources__download__link__label {
        @apply text-primary-800;
      }
    }
  }

  :deep(.ressources__standalone_button__content) {
    @apply flex h-8 w-full items-center justify-center text-center text-sm font-bold uppercase;
  }
</style>
