<template>
  <div>
    <modal-box
      v-if="modal_show"
      ref="modal_test_validation"
      width_class="w-full max-w-6xl"
      overflow_body
      fill_height
      is_survey
      survey_label="Test de validation des acquis"
      v-on:close="close"
      v-on:close-survey="openModalAlertClose"
      no_outside_click>
      <template v-slot:modal-header>
        <h1 class="px-6 text-3xl font-bold text-core-600">
          {{ session.titre }}
          <div
            class="mt-2 w-full border-l-4 pl-3 text-lg font-normal leading-none">
            {{ displayDates(session.sessionDates).join(', ') }}
          </div>
        </h1>
      </template>

      <template v-slot:modal-body>
        <template v-if="loading">
          <spinner
            sizes="w-20 h-20"
            thickness="border-8" />
        </template>
        <template v-else>
          <div
            v-if="result"
            class="p-4">
            <message-box
              source="Votre attestation de fin de formation sera disponible pour téléchargement dès que celle-ci aura été générée par l'organisateur de la session."
              border_color="w-full border-yellow-500"
              font_style="font-bold" />

            <div class="flex">
              <hr class="mx-auto mt-4 w-full max-w-md border-core-300" />
            </div>

            <template v-if="result && result.reussi && result.texteSiAcquis">
              <div class="my-4 text-2xl font-bold">Résultat</div>
              <message-box
                v-bind:source="result.texteSiAcquis"
                border_color="w-full border-jinius-green"
                font_style="text-lg" />
            </template>

            <template
              v-if="result && !result.reussi && result.texteSiNonAcquis">
              <div class="my-4 text-2xl font-bold">Résultat</div>
              <message-box
                v-bind:source="result.texteSiNonAcquis"
                border_color="w-full border-jinius-red"
                font_style="text-lg" />
              <message-box
                v-html="
                  `Vous avez la possibilité de refaire le test encore ${result.nbreTentatives - (result.nbreTentativeSupplementaire ? result.nbreTentativeSupplementaire : 0) - 1} fois.`
                "
                v-if="
                  result.nbreTentatives &&
                  (!result.nbreTentativeSupplementaire ||
                    result.nbreTentativeSupplementaire + 1 <
                      result.nbreTentatives)
                "
                border_color="w-full border-yellow-500"
                font_style="font-bold" />
            </template>

            <template
              v-if="
                (result.nbreBonnesReponses ||
                  result.nbreBonnesReponses === 0) &&
                result.nbreQuestions
              ">
              <div class="my-4 text-2xl font-bold">Note</div>
              <message-box
                v-bind:source="`${result.nbreBonnesReponses}/${result.nbreQuestions}`"
                v-bind:border_color="
                  result.reussi
                    ? 'w-full border-jinius-green'
                    : 'w-full border-jinius-red'
                "
                font_style="text-lg tracking-wider font-bold" />
            </template>

            <div v-if="reponse && reponse.reponsesJson">
              <div class="my-4 text-2xl font-bold">Corrigé</div>

              <surveyjs-wrapper
                v-bind:json="reponse.survey"
                v-bind:data="reponse.reponsesJson" />
            </div>
          </div>

          <template v-else>
            <surveyjs-wrapper
              ref="surveyjs_wrapper"
              v-on:complete="complete"
              v-on:scroll_action="scrollModalToTop"
              v-bind:json="surveyText" />
          </template>
        </template>
      </template>

      <template v-slot:modal-footer>
        <div
          v-if="surveyCatcher"
          class="inline-flex w-full justify-end space-x-4 text-lg">
          <div
            v-if="!surveyCatcher.isFirstPage && !completed"
            class="w-64 cursor-pointer rounded bg-teal-500 px-5 py-3 text-center font-bold text-white shadow hover:bg-teal-400"
            v-on:click="surveyWrapper.prevPage()">
            Précédent
          </div>

          <div
            v-if="!surveyCatcher.isLastPage"
            class="w-64 cursor-pointer rounded bg-teal-500 px-5 py-3 text-center font-bold text-white shadow hover:bg-teal-400"
            v-on:click="surveyWrapper.nextPage()">
            Suivant
          </div>

          <div
            v-if="surveyCatcher.isLastPage && !completed"
            class="w-64 cursor-pointer rounded bg-yellow-500 px-5 py-3 text-center font-bold text-white shadow hover:bg-yellow-400"
            v-on:click="surveyWrapper.completeLastPage()">
            Terminer
          </div>
          <div
            v-else-if="completed"
            class="w-64 cursor-pointer rounded bg-core-200 px-5 py-3 text-center font-bold text-core-600 shadow hover:bg-core-100"
            v-on:click="close()">
            Fermer
          </div>
        </div>
      </template>
    </modal-box>

    <modal-box
      v-if="modal_alert_close"
      v-bind:width_class="'w-full max-w-3xl'"
      overflow_body
      v-bind:header="true"
      no_outside_click
      no_close_btn>
      <template v-slot:modal-header>
        <h1 class="text-xl font-bold text-core-600">Fermer</h1>
      </template>
      <template v-slot:modal-body>
        <div class="p-6 text-lg">
          <p class="mb-2">Etes-vous sûr de vouloir fermer ce questionnaire ?</p>
          <p class="font-bold">Vos réponses ne seront pas enregistrées.</p>
        </div>
      </template>
      <template v-slot:modal-footer>
        <div class="flex w-full justify-between">
          <div
            class="w-64 cursor-pointer rounded bg-core-200 px-5 py-3 text-center font-bold text-core-600 shadow hover:bg-core-100"
            v-on:click="closeModalAlertClose()">
            Annuler et poursuivre
          </div>
          <div
            class="w-64 cursor-pointer rounded bg-jinius-red px-5 py-3 text-center font-bold text-white shadow hover:opacity-75"
            v-on:click="close()">
            Fermer le questionnaire
          </div>
        </div>
      </template>
    </modal-box>
  </div>
</template>
<script>
  import { convertStringToDate } from '@/assets/data/ag_grid_fields_map';
  import messageBox from '@/components/utils/message-box';
  import modalBox from '@/components/utils/modal-box.vue';
  import Spinner from '@/components/utils/spinner.vue';
  import SurveyjsWrapper from '@/components/utils/surveyjs-wrapper';
  import { API } from '@/http-common';

  export default {
    components: {
      SurveyjsWrapper,
      modalBox,
      Spinner,
      messageBox
    },
    props: ['modal_show', 'onclose', 'session', 'fetch_session'],
    data: () => ({
      modal_alert_close: false,
      surveyText: {},
      loading: false,
      result: null,
      reponse: null,
      completed: false,
      surveyCatcher: null,
      surveyWrapper: null,
      multiple: false
    }),
    watch: {
      async ['session.participantId']() {
        await this.fetchEvaluation();
      }
    },
    async created() {
      await this.fetchEvaluation();
      this.fetchSurveyWrapper();
    },
    methods: {
      fetchSurveyWrapper() {
        this.surveyCatcher = this.$refs.surveyjs_wrapper
          ? this.$refs.surveyjs_wrapper.survey
          : null;
        this.surveyWrapper = this.$refs.surveyjs_wrapper
          ? this.$refs.surveyjs_wrapper
          : null;
      },
      async close() {
        if (this.completed) {
          await this.fetch_session();
        }
        this.completed = false;
        this.onclose();
      },
      async complete(result) {
        this.loading = true;
        const produitId = this.session.produitId;
        const participantId = this.session.participantId;
        const isElearning = this.session.type && this.session.type.id === 2;
        const isCursus = this.session.type && this.session.type.id === 8;

        let participants;

        if (isCursus) {
          participants = 'cursusInscriptionId';
        } else if (isElearning) {
          participants = 'elearningParticipantId';
        } else {
          participants = 'sessionParticipantId';
        }

        const data = this.surveyCatcher.data;

        const response = await API.post(
          `participant/evaluation${this.multiple ? '/multiple' : ''}?parcoursProduitId=${produitId}&${participants}=${participantId}&typeId=2`,
          data,
          this.$header_skip_redirection
        );

        if (response && response.data) {
          this.result = response.data;
        }
        const responseCorrige = await API.get(
          `participant/evaluation/reponse?parcoursProduitId=${produitId}&${participants}=${participantId}&typeId=2`,
          this.$header_skip_redirection
        );

        if (responseCorrige && responseCorrige.data) {
          this.reponse = responseCorrige.data;
        }
        this.completed = true;
        this.loading = false;
      },

      async fetchEvaluation() {
        this.loading = true;
        if (this.session) {
          const produitId = this.session.produitId;
          const participantId = this.session.participantId;
          const isElearning = this.session.type && this.session.type.id === 2;
          const isCursus = this.session.type && this.session.type.id === 8;

          let participants;

          if (isCursus) {
            participants = 'cursusInscriptionId';
          } else if (isElearning) {
            participants = 'elearningParticipantId';
          } else {
            participants = 'sessionParticipantId';
          }
          const response = await API.get(
            `participant/evaluation?parcoursProduitId=${produitId}&${participants}=${participantId}&typeId=2`,
            this.$header_skip_redirection
          );

          if (response && response.data) {
            this.surveyText = response.data.survey;
            this.multiple = response.data.multiple;
          }
        }
        this.loading = false;
      },
      displayDates(dates) {
        const sortedDates = dates
          .slice()
          .sort(
            (a, b) =>
              convertStringToDate(a.date).getTime() -
              convertStringToDate(b.date).getTime()
          );

        return sortedDates.map(
          (date) =>
            `${date.date}${date.matin && !date.aprem ? ' (matin)' : ''}${!date.matin && date.aprem ? ' (après-midi)' : ''}`
        );
      },
      openModalAlertClose() {
        this.modal_alert_close = true;
      },
      closeModalAlertClose() {
        this.modal_alert_close = false;
      },
      scrollModalToTop() {
        const target =
          this.$refs.modal_test_validation.$el.getElementsByClassName(
            'modal_body--is-survey'
          );

        return (target[0].scrollTop = 0);
      }
    }
  };
</script>
<style lang="scss" scoped>
  .label {
    @apply cursor-pointer px-4 font-normal hover:font-bold hover:text-jinius-green;
    &.active {
      @apply font-bold text-jinius-green;
    }
  }
</style>
