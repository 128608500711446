<template>
  <section class="section__primary">
    <h1 class="section__title">Formation initiale et diplômes</h1>
    <div class="section__body">
      <div class="flex w-full">
        <ag-grid-wrapper
          editable="true"
          no_filters="true"
          description="Indiquez votre formation initiale"
          v-bind:column_defs="[
            {
              headerName: 'Année',
              field: 'annee',
              type: 'annees62',
              cellRenderer: 'textCellRendererEditable',
              width: 100
            },
            {
              headerName: 'Diplômes obtenus',
              field: 'diplome',
              type: 'textWithMax',
              cellEditorParams: { maxLength: 150 },
              cellRenderer: 'textCellRendererEditable',
              flex: 1,
              minWidth: 200
            },
            {
              headerName: 'Lieu',
              field: 'lieu',
              type: 'textWithMax',
              cellEditorParams: { maxLength: 30 },
              cellRenderer: 'textCellRendererEditable',
              width: 200
            }
          ]"
          v-bind:initial_data="cv_form.diplomes"
          v-on:cell-value-changed="set_attribute($event, 'diplomes')" />
      </div>
    </div>
  </section>
</template>
<script>
  import agGridWrapper from '@/components/utils/ag-grid/ag-grid-wrapper';
  export default {
    components: { agGridWrapper },
    props: ['v$', 'set_attribute', 'cv_form']
  };
</script>
