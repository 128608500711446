import { API } from '@/http-common';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useAccountStore = defineStore('account', () => {
    const profile = ref();

    const getProfile = async () => {
        const response = await API.get('participant/profile');
        if (response?.data) {
            profile.value = response?.data;
        }
    };

    return {
        profile,
        getProfile
    };
});
