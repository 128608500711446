<template>
  <modal-box
    v-if="show_modal"
    v-bind:width_class="'w-full max-w-screen-lg'"
    v-on:close="onclose"
    v-bind:footer="false">
    <template v-slot:modal-header>
      <h1 class="text-xl font-bold text-core-900">Création impossible</h1>
    </template>

    <template v-slot:modal-body>
      <div class="py-2">
        Vous êtes déjà affecté à une session de formation sur ce créneau.
      </div>
    </template>
  </modal-box>
</template>
<script>
  import modalBox from '@/components/utils/modal-box.vue';
  export default {
    components: { modalBox },
    props: ['show_modal', 'onclose'],
    data: () => ({}),
    methods: {
      close() {
        this.onclose();
      }
    }
  };
</script>
<style lang="scss" scoped></style>
