<template>
  <section class="section__primary">
    <h1 class="section__title">Adresse</h1>
    <div class="section__body">
      <div
        v-for="inputItem in informationsInputs"
        v-bind:key="inputItem.name"
        class="form__item">
        <input-box
          class_name="no-box"
          v-bind:not_required_label="inputItem.facultatif"
          v-bind:name="inputItem.name"
          v-bind:label="inputItem.placeholder"
          v-bind:placeholder="inputItem.placeholder"
          v-bind:maxlength="inputItem.maxLength"
          v-bind:value="entite_form[inputItem.name]"
          v-on:input="set_attribute($event, [inputItem.name])"
          v-bind:format="inputItem.format"
          v-bind:required="
            v$.entiteForm[inputItem.name] &&
            Object.prototype.hasOwnProperty.call(
              v$.entiteForm[inputItem.name],
              'required'
            )
          "
          v-bind:class="{
            'input-error':
              v$.entiteForm[inputItem.name] &&
              v$.entiteForm[inputItem.name].$error
          }"
          v-bind:disabled="disabled" />
      </div>
      <div class="form__item">
        <select-box
          v-bind:value="pays.find((item) => entite_form.pays === item.id)"
          v-on:input="(value) => set_attribute(value.id, 'pays')"
          v-bind:categories="pays"
          label="Pays"
          placeholder="Pays"
          no_capitalize_label
          name="pays"
          tracking="id"
          class_name="no-box"
          v-bind:disabled="disabled" />
      </div>
    </div>
  </section>
</template>
<script>
  import { pays } from '@/assets/data/data_form';
  import inputBox from '@/components/utils/input-box.vue';
  import selectBox from '@/components/utils/select-box.vue';

  const inputs = [
    {
      name: 'identification',
      placeholder: 'Identification',
      maxLength: 100,
      facultatif: true
    },
    {
      name: 'destService',
      placeholder: 'Dest./Service',
      maxLength: 100,
      facultatif: true
    },
    {
      name: 'complement',
      placeholder: "Complément d'adresse",
      maxLength: 38,
      facultatif: true
    },
    {
      name: 'numeroVoie',
      placeholder: 'N° et voie',
      maxLength: 38
    },
    {
      name: 'mentionSpeciale',
      placeholder: 'Mention spéciale',
      maxLength: 38,
      facultatif: true
    },
    {
      name: 'ville',
      placeholder: 'Ville',
      maxLength: 100,
      facultatif: true
    },
    {
      name: 'codePostal',
      placeholder: 'Code postal',
      maxLength: 5,
      format: 'numeric'
    }
  ];

  export default {
    components: {
      selectBox,
      inputBox
    },
    props: ['v$', 'set_attribute', 'entite_form', 'disabled'],
    computed: {
      informationsInputs: () => inputs,
      pays: () => pays
    }
  };
</script>
<style lang="scss" scoped></style>
