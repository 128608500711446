<template>
  <modal-box
    v-if="show_modal"
    v-on:close="close"
    v-bind:width_class="'w-full max-w-screen-sm'"
    v-bind:overflow_class="'overflow-visible'"
    v-bind:overflow_body="!firstStep">
    <template v-slot:modal-header>
      <h1 class="text-xl font-bold text-primary-900">
        {{
          active_interlocutor
            ? 'Modifier un interlocuteur'
            : 'Ajouter un interlocuteur'
        }}
      </h1>
    </template>

    <template v-slot:modal-body>
      <div v-if="firstStep">
        <select-box
          no_capitalize_label
          class_name="no-box"
          label="Depuis l'annuaire"
          placeholder="Contact"
          name="contact"
          v-bind:value="
            available_participants.find(
              (participant) => contact === participant.id
            )
          "
          v-on:input="setAvailableParticipant($event)"
          v-bind:categories="available_participants" />
        <div
          class="my-4 flex w-full justify-center text-lg font-bold text-primary-900">
          Ou
        </div>
        <button
          class="w-full cursor-pointer rounded bg-primary-900 px-5 py-3 text-center font-bold text-white shadow hover:bg-primary-800"
          v-on:click="setFirstStep(false)">
          Créer un nouvel interlocuteur
        </button>
      </div>

      <form
        v-else
        ref="interlocutorForm"
        class="flex w-full flex-wrap space-y-4 px-2 py-2"
        v-on:submit.prevent="submit">
        <input-box
          v-bind:disabled="!!active_interlocutor || !!contact"
          class_name="no-box"
          label="Nom"
          placeholder="Nom"
          name="nom"
          v-bind:required="true"
          v-bind:maxlength="100"
          v-bind:value="interlocutorForm.nom"
          v-on:input="setInterlocutorForm($event, 'nom')" />
        <input-box
          v-bind:disabled="!!active_interlocutor || !!contact"
          class_name="no-box"
          label="Prénom"
          placeholder="Prénom"
          name="prenom"
          v-bind:required="true"
          v-bind:maxlength="100"
          v-bind:value="interlocutorForm.prenom"
          v-on:input="setInterlocutorForm($event, 'prenom')" />
        <input-box
          v-bind:disabled="!!active_interlocutor || !!contact"
          class_name="no-box"
          label="Adresse email"
          placeholder="Email"
          name="email"
          v-bind:required="true"
          v-bind:class="{ 'input-error': v$.interlocutorForm.email.$error }"
          v-bind:maxlength="100"
          v-bind:value="interlocutorForm.email"
          v-on:input="setInterlocutorForm($event, 'email')" />
        <select-box
          class_name="no-box"
          label="Fonction"
          placeholder="Fonction"
          name="fonctionId"
          v-bind:required="true"
          v-bind:class="{
            'select-error': v$.interlocutorForm.fonctionId.$error
          }"
          v-bind:value="
            roles.find((role) => interlocutorForm.fonctionId === role.id)
          "
          v-on:input="setInterlocutorForm($event.id, 'fonctionId')"
          v-bind:categories="roles" />

        <div
          class="flex w-full flex-wrap py-2"
          v-bind:class="{
            'radio-error': v$.interlocutorForm.droitAdministrateur.$error
          }">
          <div
            class="flex w-3/4 text-sm font-bold leading-none text-accent-500">
            <form-label
              label="Administrateur de la société ?"
              v-bind:required="true"
              class="header__label font-bold leading-none" />
            <div
              class="ml-2 flex items-center"
              v-tooltip="
                !interlocutorForm.droitAdministrateur
                  ? {
                      content: `
            <div>
                L'administrateur possède l'intégralité des droits sur la société.
            </div>
            <div>Plusieurs administrateurs peuvent être nommés sur la même société.</div>`
                    }
                  : null
              ">
              <font-awesome-icon
                v-if="!interlocutorForm.droitAdministrateur"
                icon="info-circle"
                size="lg" />
            </div>
          </div>
          <div class="flex w-1/4">
            <yes-no-radio-buttons
              input_name="droitAdministrateur"
              v-bind:input_value="interlocutorForm.droitAdministrateur"
              v-bind:update_value="
                (value) => setInterlocutorForm(value, 'droitAdministrateur')
              " />
          </div>
        </div>
        <template v-if="interlocutorForm.droitAdministrateur">
          <div class="flex w-full text-justify text-sm italic text-accent-500">
            L'administrateur possède l'intégralité des droits sur la société.
          </div>
          <div class="flex w-full text-justify text-sm italic text-accent-500">
            En plus de pouvoir procéder à des inscriptions au nom de la société
            et de voir l'intégralité des commandes, inscriptions et documents
            liés, il peut également gérer les droits d'accès et les informations
            administratives de la société (adresse, entités de facturation...).
            Plusieurs administrateurs peuvent être nommés sur la même société.
          </div>
        </template>
        <template v-else>
          <div
            class="flex w-full flex-wrap py-2"
            v-bind:class="{
              'radio-error': v$.interlocutorForm.droitInscription.$error
            }">
            <div
              class="flex w-3/4 text-sm font-bold leading-none text-accent-500">
              <form-label
                label="Droit de procéder à des inscriptions pour le compte de la société ?"
                v-bind:required="true"
                class="header__label font-bold leading-none" />
            </div>
            <div class="flex w-1/4">
              <yes-no-radio-buttons
                input_name="droitInscription"
                v-bind:input_value="interlocutorForm.droitInscription"
                v-bind:update_value="
                  (value) => setInterlocutorForm(value, 'droitInscription')
                " />
            </div>
          </div>
          <div
            class="flex w-full flex-wrap py-2"
            v-bind:class="{
              'radio-error': v$.interlocutorForm.droitAccesDocuments.$error
            }">
            <div
              class="flex w-3/4 text-sm font-bold leading-none text-accent-500">
              <form-label
                label="Droit d'accéder à l'ensemble des documents (bons de commandes, factures, conventions, certificats de réalisation...) de la société ?"
                v-bind:required="true"
                class="header__label font-bold leading-none" />
            </div>
            <div class="flex w-1/4">
              <yes-no-radio-buttons
                input_name="droitAccesDocuments"
                v-bind:input_value="interlocutorForm.droitAccesDocuments"
                v-bind:update_value="
                  (value) => setInterlocutorForm(value, 'droitAccesDocuments')
                " />
            </div>
          </div>
        </template>
      </form>
    </template>
    <template v-slot:modal-footer>
      <div class="inline-flex w-full justify-center space-x-4">
        <button
          v-if="!firstStep"
          class="primary__button"
          v-on:click="submit">
          {{ active_interlocutor ? 'Modifier' : 'Ajouter' }}
        </button>
        <button
          class="secondary__button"
          v-on:click="close">
          Annuler
        </button>
      </div>
    </template>
  </modal-box>
</template>
<script>
  import { roles } from '@/assets/data/data_form';
  import formLabel from '@/components/utils/form-label.vue';
  import inputBox from '@/components/utils/input-box.vue';
  import modalBox from '@/components/utils/modal-box.vue';
  import selectBox from '@/components/utils/select-box.vue';
  import yesNoRadioButtons from '@/components/utils/yes-no-radio-buttons';
  import { useVuelidate } from '@vuelidate/core';
  import { email, required, requiredIf } from '@vuelidate/validators';

  const initialForm = {
    nom: null,
    prenom: null,
    email: null,
    fonctionId: null,
    droitAdministrateur: null,
    droitInscription: null,
    droitAccesDocuments: null
  };

  export default {
    components: {
      modalBox,
      inputBox,
      selectBox,
      yesNoRadioButtons,
      formLabel
    },
    props: [
      'show_modal',
      'onclose',
      'active_interlocutor',
      'available_participants'
    ],
    emits: ['update-interlocutor', 'add-interlocutor'],
    setup() {
      return { v$: useVuelidate() };
    },
    data() {
      return {
        contact: null,
        interlocutorForm: { ...initialForm },
        firstStep: this.available_participants && !this.active_interlocutor,
        roles
      };
    },
    validations() {
      return {
        interlocutorForm: {
          email: {
            email,
            required
          },
          fonctionId: { required },
          droitAdministrateur: { required },
          droitInscription: {
            required: requiredIf(function () {
              return !this.interlocutorForm.droitAdministrateur;
            })
          },
          droitAccesDocuments: {
            required: requiredIf(function () {
              return !this.interlocutorForm.droitAdministrateur;
            })
          }
        }
      };
    },
    watch: {
      active_interlocutor(value) {
        if (value) {
          this.contact = null;
          this.firstStep = false;
          this.interlocutorForm = {
            ...initialForm,
            ...this.active_interlocutor
          };
        } else {
          this.contact = null;
          this.firstStep = !!this.available_participants;
          this.interlocutorForm = { ...initialForm };
        }
      }
    },
    methods: {
      close() {
        this.onclose();
        this.contact = null;
        this.firstStep =
          this.available_participants && !this.active_interlocutor;
        this.interlocutorForm = { ...initialForm };
        this.v$.$reset();
      },
      setInterlocutorForm(value, name) {
        this.interlocutorForm[name] = value;
      },
      setAvailableParticipant(value) {
        this.contact = value.id;
        this.setInterlocutorForm(value.nom, 'nom');
        this.setInterlocutorForm(value.prenom, 'prenom');
        this.setInterlocutorForm(value.email, 'email');
        this.setFirstStep(false);
      },
      setFirstStep(value) {
        this.firstStep = value;
      },
      async submit(e) {
        e.preventDefault();
        this.loading = true;
        this.v$.$touch();
        if (!this.v$.$invalid) {
          if (this.active_interlocutor) {
            this.$emit('update-interlocutor', this.interlocutorForm);
          } else {
            this.$emit('add-interlocutor', this.interlocutorForm);
          }
          this.v$.$reset();
          this.close();
        }
        this.loading = false;
      }
    }
  };
</script>
<style lang="scss" scoped>
  .primary__button {
    @apply w-64 cursor-pointer rounded bg-primary-900 px-5 py-3 text-center font-bold text-white shadow hover:bg-primary-800;
  }
  .secondary__button {
    @apply w-64 cursor-pointer rounded bg-core-300 px-5 py-3 text-center font-bold text-core-700 shadow hover:bg-core-200;
  }
  .radio-error {
    @apply text-jinius-red;

    .form-label {
      @apply text-jinius-red;
    }
  }
</style>
