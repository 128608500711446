<template>
  <div
    v-bind:style="cssVars"
    class="flex w-full flex-col justify-start">
    <div
      v-if="
        title ||
        exportable ||
        editable ||
        description ||
        buttons ||
        treeData ||
        !hide_navigation
      "
      class="flex w-full flex-shrink flex-wrap items-start justify-end py-1">
      <div
        v-if="title"
        class="aggrid__title">
        {{ title }}
      </div>
      <div
        v-if="description"
        class="description">
        {{ description }}
      </div>

      <div class="flex w-full space-x-1">
        <div class="flex justify-start space-x-1 sm:space-x-2">
          <template v-if="treeData && rowData.some((d) => d.grpRef.length > 1)">
            <button
              class="aggrid__button"
              v-on:click.prevent.stop="expandAll">
              <font-awesome-icon
                class="button__icon"
                icon="plus-circle" />
              <span
                v-if="$breakpoints.is !== 'all'"
                class="button__text"
                >Tout Déplier</span
              >
            </button>
            <button
              class="aggrid__button"
              v-on:click.prevent.stop="collapseAll">
              <font-awesome-icon
                class="button__icon"
                icon="minus-circle" />
              <span
                v-if="$breakpoints.is !== 'all'"
                class="button__text"
                >Tout Replier</span
              >
            </button>
          </template>

          <template
            v-if="buttons && buttons.length > 0"
            v-for="(button, index) in buttons">
            <button
              class="aggrid__button"
              v-bind:class="{ 'aggrid__button--large': button.largeButton }"
              v-if="!button.displayBottom"
              v-bind:key="`${button.label}-${index}`"
              v-on:click.prevent.stop="(e) => onButtonClick(e, button.onClick)"
              v-bind:disabled="button.disabled">
              <font-awesome-icon
                v-if="button.icon"
                v-bind:icon="button.icon"
                v-bind:size="button.icon_size ? button.icon_size : '1x'"
                class="button__icon" />
              <span
                v-if="$breakpoints.w >= $breakpoints.screens.lg"
                class="button__text"
                >{{ button.label }}</span
              >
            </button>
          </template>
        </div>

        <div class="flex flex-1 justify-end space-x-1 sm:space-x-2">
          <div
            v-if="!no_filters"
            class="inline-flex">
            <button
              class="aggrid__button"
              v-bind:disabled="!isFiltersActive"
              v-on:click.prevent.stop="reset">
              <font-awesome-icon
                class="button__icon"
                icon="times-circle" />
              <span
                v-if="$breakpoints.is !== 'all'"
                class="button__text">
                Annuler filtre(s)
              </span>
              <font-awesome-icon
                v-else
                class="button__icon"
                icon="filter" />
            </button>
          </div>

          <div
            v-if="exportable"
            class="inline-flex">
            <button
              v-on:click.prevent.stop="exportToExcel"
              class="aggrid__button">
              <font-awesome-icon
                class="button__icon"
                icon="download" />
              <span
                v-if="$breakpoints.is !== 'all'"
                class="button__text">
                Exporter
              </span>
            </button>
          </div>

          <div
            v-if="editable"
            class="inline-flex">
            <button
              v-on:click.prevent.stop="addRow"
              class="aggrid__button">
              <font-awesome-icon
                class="button__icon"
                icon="plus-circle" />
              <span class="button__text">Ajouter un élément</span>
            </button>
          </div>

          <template v-if="!hide_navigation">
            <div class="w-0 border-r border-core-400">&nbsp;</div>
            <div class="inline-flex">
              <button
                class="aggrid__button"
                v-on:click.prevent.stop="toggleAutoSize"
                v-tooltip="
                  `${!is_autosize_toggle ? 'Optimiser' : 'Maximiser'} la taille des colonnes`
                ">
                <font-awesome-icon
                  v-bind:icon="
                    !is_autosize_toggle ? 'compress-alt' : 'expand-alt'
                  " />
              </button>
            </div>

            <div class="inline-flex space-x-0.5">
              <button
                class="aggrid__button"
                v-on:click.prevent.stop="goToColumn('left')"
                v-tooltip="'Faire défiler à gauche'">
                <font-awesome-icon icon="chevron-left" />
              </button>
              <button
                class="aggrid__button"
                v-on:click.prevent.stop="goToColumn('right')"
                v-tooltip="'Faire défiler à droite'">
                <font-awesome-icon icon="chevron-right" />
              </button>
            </div>
          </template>
        </div>
      </div>
    </div>

    <slot name="headline_info"></slot>

    <div
      class="flex w-full flex-grow"
      v-bind:style="grid_height ? `height: ${grid_height}` : 'height: 100%'">
      <ag-grid-vue
        ref="ag_grid_wrapper"
        class="ag-theme-alpine h-full max-h-full w-full overscroll-none"
        v-on:grid-ready="onGridReady"
        v-on:first-data-rendered="onFirstDataRendered"
        v-on:grid-size-changed="onGridSizeChanged"
        v-on:filter-changed="onFilterChanged"
        v-on:cell-value-changed="onCellChanged"
        v-on:row-index-changed="onCellChanged"
        v-on:selection-changed="onSelectionChanged"
        v-bind:popup-parent="popupParent"
        v-bind:auto-group-column-def="autoGroupColumnDef"
        v-bind:get-data-path="getDataPath"
        v-bind:group-default-expanded="groupDefaultExpanded"
        v-bind:tree-data="treeData"
        v-bind:grid-options="gridOptions"
        v-bind:dom-layout="domLayout"
        v-bind:default-col-def="defaultColumnDef"
        v-bind:column-defs="columnDefs"
        v-bind:row-data="rowData"
        v-bind:context="context"
        v-bind:column-types="columnTypes"
        v-bind:stop-editing-when-grid-loses-focus="true"
        v-bind:locale-text="localeText"
        v-bind:no-rows-overlay-component="noRowsOverlayComponent"
        v-bind:no-rows-overlay-component-params="noRowsOverlayComponentParams"
        v-bind:excel-styles="excelStyles"
        v-bind:row-selection="row_selection"
        v-bind:row-multi-select-with-click="!!row_selection"
        v-bind:get-row-class="getRowClass" />
    </div>

    <div
      v-if="buttons && buttons.length > 0"
      class="mt-2 flex justify-start space-x-1 sm:space-x-2">
      <template v-for="(button, index) in buttons">
        <button
          class="aggrid__button"
          v-bind:class="{ 'aggrid__button--large': button.largeButton }"
          v-if="button.displayBottom"
          v-bind:key="`${button.label}-${index}`"
          v-on:click.prevent.stop="(e) => onButtonClick(e, button.onClick)"
          v-bind:disabled="button.disabled">
          <font-awesome-icon
            v-if="button.icon"
            v-bind:icon="button.icon"
            v-bind:size="button.icon_size ? button.icon_size : '1x'"
            class="button__icon" />
          <span
            v-if="$breakpoints.w >= $breakpoints.screens.lg"
            class="button__text"
            >{{ button.label }}</span
          >
        </button>
      </template>
    </div>

    <delete-with-confirm-modal
      v-bind:show_modal="deleteWithConfirmModal"
      v-bind:onclose="closeDeleteWithConfirmModal"
      v-bind:onconfirm="deleteRowWithConfirm"
      v-bind:content="delete_with_confirm_modal_content" />
  </div>
</template>

<script>
  import { ag_grid_fields } from '@/assets/data/ag_grid_fields_map.js';
  import actionCellRenderer from '@/components/utils/ag-grid/action-cell-renderer';
  import amountCellRenderer from '@/components/utils/ag-grid/amount-cell-renderer';
  import buttonCellRenderer from '@/components/utils/ag-grid/button-cell-renderer';
  import coloredTextCenterCellRenderer from '@/components/utils/ag-grid/colored-text-center-cell-renderer';
  import dateCellRenderer from '@/components/utils/ag-grid/date-cell-renderer';
  import deleteCellRenderer from '@/components/utils/ag-grid/delete-cell-renderer';
  import deleteWithConfirmCellRenderer from '@/components/utils/ag-grid/delete-with-confirm-cell-renderer';
  import DeleteWithConfirmModal from '@/components/utils/ag-grid/delete-with-confirm-modal';
  import existCellRenderer from '@/components/utils/ag-grid/exist-cell-renderer';
  import labelCellRenderer from '@/components/utils/ag-grid/label-cell-renderer';
  import { AG_GRID_LOCALE_FR } from '@/components/utils/ag-grid/locale.fr';
  import locationCellRenderer from '@/components/utils/ag-grid/location-cell-renderer';
  import noRowsOverlay from '@/components/utils/ag-grid/no-rows-overlay';
  import numericCellEditor from '@/components/utils/ag-grid/numeric-cell-editor';
  import pdfDownloadCellRenderer from '@/components/utils/ag-grid/pdf-download-cell-renderer';
  import positionnementCellRenderer from '@/components/utils/ag-grid/positionnement-cell-renderer';
  import reglementCellRenderer from '@/components/utils/ag-grid/reglement-cell-renderer';
  import statutCellRenderer from '@/components/utils/ag-grid/statut-cell-renderer';
  import supportTitreCellRenderer from '@/components/utils/ag-grid/support-titre-cell-renderer';
  import textCellRenderer from '@/components/utils/ag-grid/text-cell-renderer';
  import textCellRendererEditable from '@/components/utils/ag-grid/text-cell-renderer-editable';
  import textCellRendererSelect from '@/components/utils/ag-grid/text-cell-renderer-select';
  import textCenterCellRenderer from '@/components/utils/ag-grid/text-center-cell-renderer';
  import textWithMaxCellEditor from '@/components/utils/ag-grid/text-with-max-cell-editor';
  import titreCellRenderer from '@/components/utils/ag-grid/titre-cell-renderer';
  import typeCellRenderer from '@/components/utils/ag-grid/type-cell-renderer';
  import updateCellRenderer from '@/components/utils/ag-grid/update-cell-renderer';
  import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
  import { ModuleRegistry } from '@ag-grid-community/core';
  import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
  // Init ag-grid with modules import
  // cf. https://www.ag-grid.com/vue-data-grid/modules/
  import { AgGridVue } from '@ag-grid-community/vue3';
  import { GridChartsModule } from '@ag-grid-enterprise/charts';
  import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
  import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
  import { LicenseManager } from '@ag-grid-enterprise/core';
  import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
  import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
  import { MenuModule } from '@ag-grid-enterprise/menu';
  import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
  import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
  import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
  import { SetFilterModule } from '@ag-grid-enterprise/set-filter';

  const currentYear = new Date().getFullYear();
  const annees62Values = Array.from({ length: 62 }, (v, i) => currentYear - i);
  const annees7Values = Array.from(
    { length: 7 },
    (v, i) => currentYear + 1 - i
  );

  const LICENSE_KEY = `${import.meta.env.VITE_AG_GRID_KEY}`;

  LicenseManager.setLicenseKey(LICENSE_KEY);

  ModuleRegistry.registerModules([
    InfiniteRowModelModule,
    ClientSideRowModelModule,
    ServerSideRowModelModule,
    MenuModule,
    ExcelExportModule,
    ClipboardModule,
    GridChartsModule,
    RowGroupingModule,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    SetFilterModule,
    RichSelectModule
  ]);

  export default {
    components: {
      AgGridVue,
      DeleteWithConfirmModal,
      /* eslint-disable vue/no-unused-components, quote-props, object-shorthand */
      // Following named components below is mandatory for AgGrid integration
      amountCellRenderer: amountCellRenderer,
      labelCellRenderer: labelCellRenderer,
      deleteCellRenderer: deleteCellRenderer,
      numericCellEditor: numericCellEditor,
      textWithMaxCellEditor: textWithMaxCellEditor,
      pdfDownloadCellRenderer: pdfDownloadCellRenderer,
      actionCellRenderer: actionCellRenderer,
      textCellRenderer: textCellRenderer,
      textCellRendererSelect: textCellRendererSelect,
      textCenterCellRenderer: textCenterCellRenderer,
      typeCellRenderer: typeCellRenderer,
      titreCellRenderer: titreCellRenderer,
      textCellRendererEditable: textCellRendererEditable,
      buttonCellRenderer: buttonCellRenderer,
      positionnementCellRenderer: positionnementCellRenderer,
      supportTitreCellRenderer: supportTitreCellRenderer,
      noRowsOverlay: noRowsOverlay,
      locationCellRenderer: locationCellRenderer,
      dateCellRenderer: dateCellRenderer,
      statutCellRenderer: statutCellRenderer,
      reglementCellRenderer: reglementCellRenderer,
      deleteWithConfirmCellRenderer: deleteWithConfirmCellRenderer,
      existCellRenderer: existCellRenderer,
      updateCellRenderer: updateCellRenderer,
      coloredTextCenterCellRenderer: coloredTextCenterCellRenderer
      /* eslint-enable vue/no-unused-components, quote-props, object-shorthand */
    },
    props: [
      'initial_data',
      'column_defs',
      'title',
      'description',
      'editable',
      'exportable',
      'tree_data',
      'filename',
      'no_filters',
      'grid_height',
      'with_delete_button',
      'buttons',
      'row_selection',
      'force_dom_layout',
      'hide_navigation',
      'delete_with_confirm_button',
      'delete_with_confirm_modal_content',
      'update_button',
      'grid_options'
    ],
    emits: [
      'cell-value-changed',
      'grid-selection-changed',
      'delete-row',
      'update-row',
      'change-delete-modal-content'
    ],
    data() {
      const columnDefs = this.column_defs.map((def) => {
        if (ag_grid_fields[def.field]) {
          def = {
            ...ag_grid_fields[def.field],
            ...def
          };
        } else {
          def = {
            minWidth: 150,
            ...def
          };
        }

        return def;
      });

      if (this.update_button) {
        columnDefs.push({
          headerName: '',
          field: 'updateButton',
          cellRenderer: 'updateCellRenderer',
          maxWidth: 50,
          editable: false,
          sortable: false,
          suppressHeaderMenuButton: true
        });
      }

      if (this.editable || this.with_delete_button) {
        columnDefs.push({
          headerName: '',
          field: 'deleteButton',
          cellRenderer: 'deleteCellRenderer',
          maxWidth: 50,
          editable: false,
          sortable: false,
          suppressHeaderMenuButton: true
        });
      }

      if (this.delete_with_confirm_button) {
        columnDefs.push({
          headerName: '',
          field: 'deleteButton',
          cellRenderer: 'deleteWithConfirmCellRenderer',
          maxWidth: 50,
          editable: false,
          sortable: false,
          suppressHeaderMenuButton: true
        });
      }

      return {
        is_max_height: false,
        is_autosize_toggle: true,
        ag_header_height: null,
        localeText: AG_GRID_LOCALE_FR,
        gridOptions: null,
        isFiltersActive: false,
        context: { componentParent: this },
        groupDefaultExpanded: -1,
        rowData: this.initial_data,
        treeData: this.tree_data !== undefined,
        columnDefs,
        defaultColumnDef: {
          wrapText: true,
          resizable: true,
          editable: !!this.editable,
          singleClickEdit: true,
          suppressHeaderMenuButton: true,
          sortable: true,
          lockVisible: true
          // suppressMovable:true,
        },
        noRowsOverlayComponent: 'noRowsOverlay',
        noRowsOverlayComponentParams: {
          noRowsMessageFunc() {
            return AG_GRID_LOCALE_FR.noRowsToShow;
          }
        },
        popupParent: null,
        autoGroupColumnDef: this.tree_data
          ? {
              headerName: this.tree_data.headerName,
              ...ag_grid_fields.reference_treedata,
              cellRendererParams: { suppressCount: true }
            }
          : null,
        columnTypes: {
          annees62: {
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: { values: annees62Values }
          },
          annees7: {
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: { values: annees7Values }
          },
          number: { cellEditor: 'numericCellEditor' },
          textWithMax: { cellEditor: 'textWithMaxCellEditor' }
        },
        deleteWithConfirmModal: false,
        deleteWithConfirmRowIndex: null,
        deleteWithConfirmData: null
      };
    },
    computed: {
      domLayout() {
        const value =
          this.is_max_height || this.force_dom_layout ? 'normal' : 'autoHeight';

        return value;
      },
      cssVars() {
        return { '--ag-header-height-offset': this.ag_header_height + 'px' };
      },
      excelStyles() {
        return [
          {
            id: 'string',
            dataType: 'string'
          }
        ];
      }
    },
    beforeMount() {
      this.gridOptions = this.grid_options ? this.grid_options : {};
      this.popupParent = document.querySelector('body');
    },
    methods: {
      goToColumn(direction) {
        const wrap = this.$refs.ag_grid_wrapper.$el;
        const el = wrap.querySelector(
          ".ag-body-horizontal-scroll-viewport[ref='eViewport']"
        );

        if (el) {
          const offset_ratio = Math.floor(el.scrollWidth * 0.33);

          if (direction === 'left') {
            el.scroll({
              left: el.scrollLeft - offset_ratio,
              behavior: 'smooth'
            });
          } else {
            el.scroll({
              left: el.scrollLeft + offset_ratio,
              behavior: 'smooth'
            });
          }
        } else {
          // eslint-disable-next-line no-console
          console.error('AgGrid horizontal scrolling element is undefined');
        }
      },
      autoSizeAll(skipHeader) {
        const allColumnIds = [];

        this.gridApi.getColumns().forEach((column) => {
          allColumnIds.push(column.colId);
        });
        this.gridApi.autoSizeColumns(allColumnIds, skipHeader);
      },
      toggleAutoSize() {
        if (!this.is_autosize_toggle) {
          this.gridApi.sizeColumnsToFit();
          this.is_autosize_toggle = true;
        } else {
          this.autoSizeAll(false);
          this.is_autosize_toggle = false;
        }
      },
      getRowClass(params) {
        // if (params.data?.readonly) {
        //     return 'ag-row--jinius-readonly';
        // }
      },
      fetchIsMaxHeight() {
        const wrap = this.$refs.ag_grid_wrapper.$el;

        this.ag_header_height =
          wrap.querySelector("[ref='gridHeader']").clientHeight;

        const header_height =
          this.$parent.$refs.header_height &&
          this.$parent.$refs.header_height.clientHeight;
        const viewport_height =
          wrap.querySelector("[ref='eViewport']").clientHeight;
        const wrapper_height =
          this.$parent.$parent.$parent.$refs.scrollable_current_component &&
          this.$parent.$parent.$parent.$refs.scrollable_current_component
            .clientHeight;

        this.is_max_height =
          viewport_height >
          wrapper_height - header_height - this.ag_header_height - 40;

        // console.log(this.is_max_height, viewport_height, wrapper_height, header_height, this.ag_header_height, wrapper_height - header_height - this.ag_header_height - 40);
      },
      setDomLayoutValue() {
        // console.log(this.domLayout);
        this.gridApi && this.gridApi.setGridOption('domLayout', this.domLayout);
      },
      onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        this.fetchIsMaxHeight();
        this.setDomLayoutValue();
      },
      onFilterChanged(params) {
        const filters_state = this.gridApi.getFilterModel();

        this.$nextTick(() => {
          this.isFiltersActive =
            filters_state && Object.keys(filters_state).length;
        });
      },
      onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
      },
      onGridSizeChanged(params) {
        if (!this.is_autosize_toggle) {
          params.api.sizeColumnsToFit();
        }
      },
      getDataPath(data) {
        if (this.tree_data) {
          return data[this.tree_data.treeData];
        }
      },
      exportToExcel() {
        const fileName = this.filename ? this.filename : 'export';
        const columns = this.gridColumnApi.getColumns().map((c) => c.colId);
        const params = {
          columnWidth: 100,
          rowHeight: 30,
          headerRowHeight: 40,
          fileName: fileName.replace(/ /g, '_').replace(/[!?,;'"]/g, ''),
          processCellCallback: (p) => this.formatValue(p),
          shouldRowBeSkipped: (p) =>
            p.node.data && p.node.data.type === 'Parcours',
          columnKeys: columns.filter((c) => c !== 'details')
        };

        this.gridApi.exportDataAsExcel(params);
      },
      addRow(e) {
        e.preventDefault();
        this.rowData = [...this.rowData, {}];
        this.$emit('cell-value-changed', this.rowData);
      },
      deleteRow(rowIndex, data) {
        if (rowIndex < this.rowData.length) {
          if (data && data.id) {
            const rowDeleted = this.rowData.find((item) => item.id === data.id);

            this.$emit('delete-row', rowDeleted);
            this.rowData = this.rowData.filter((item) => item.id !== data.id);
            this.$emit('cell-value-changed', this.rowData);
          } else {
            const rowDeleted = this.rowData[rowIndex];

            this.$emit('delete-row', rowDeleted);
            this.rowData = this.rowData.filter(
              (formation, index) => index !== rowIndex
            );
            this.$emit('cell-value-changed', this.rowData);
          }
        }
      },
      onSelectionChanged() {
        this.$emit('grid-selection-changed', this.gridApi.getSelectedRows());
      },
      onCellChanged() {
        this.$emit('cell-value-changed', this.rowData);
      },
      reset() {
        this.gridApi.setFilterModel(null);
      },
      formatValue(params) {
        if (params.column.colId === 'sessionDates') {
          return params.value.map((session) => {
            const { date, matin, aprem } = session;

            return `${date}${matin && !aprem ? ' (matin)' : ''}${
              !matin && aprem ? ' (après-midi)' : ''
            }`;
          });
        }
        if (['support', 'convocation'].includes(params.column.colId)) {
          return undefined;
        }

        return params.value;
      },
      expandAll() {
        this.gridApi.expandAll();
      },
      collapseAll() {
        this.gridApi.collapseAll();
      },
      onButtonClick(e, onClick) {
        onClick(e, this.gridApi);
      },
      updateAction(rowIndex, data) {
        if (rowIndex < this.rowData.length) {
          const rowToUpdate =
            data && data.id
              ? this.rowData.find((item) => item.id === data.id)
              : this.rowData[rowIndex];

          this.$emit('update-row', rowToUpdate);
        }
      },
      openDeleteWithConfirmModal(rowIndex, data) {
        this.deleteWithConfirmRowIndex = rowIndex;
        this.deleteWithConfirmData = data;
        this.$emit('change-delete-modal-content', data);
        this.deleteWithConfirmModal = true;
      },
      closeDeleteWithConfirmModal() {
        this.deleteWithConfirmModal = false;
        this.$emit('change-delete-modal-content');
        this.deleteWithConfirmRowIndex = null;
        this.deleteWithConfirmData = null;
      },
      deleteRowWithConfirm() {
        this.deleteRow(
          this.deleteWithConfirmRowIndex,
          this.deleteWithConfirmData
        );
        this.closeDeleteWithConfirmModal();
      }
    }
  };
</script>

<style lang="scss">
  // SCSS Community
  @use '@ag-grid-community/styles' as ag;

  // Choose alpine over default quartz
  @include ag.grid-styles(
    (
      theme: alpine
    )
  );
  /*=============================================>>>>>
= AgGrid override default z-index on filter's popup =
===============================================>>>>>*/
  .ag-popup-child {
    z-index: 999 !important;
  }
</style>

<style lang="scss" scoped>
  :deep(.ag-theme-alpine) {
    .ag-root-wrapper {
      @apply rounded-md border-none shadow-lg;
    }
    .ag-body-horizontal-scroll-viewport,
    .ag-body-horizontal-vertical-viewport {
      @apply z-0;
    }
    .ag-overlay-no-rows-wrapper.ag-layout-auto-height {
      padding-top: var(--ag-header-height-offset);
    }

    .ag-checkbox {
      @apply mx-4;
      .ag-checkbox-input {
        @apply cursor-pointer;
      }
    }

    .ag-header {
      border: none;
      .ag-header-viewport {
        // @apply bg-core-700 border-b border-accent-100;
      }

      .ag-header-cell-filtered {
        @apply bg-jinius-dark-green text-white;
      }

      .ag-header-cell-filtered span {
        @apply text-white;
      }

      .ag-header-row {
        &.ag-header-row-column {
          @apply bg-core-700;
        }

        .ag-header-cell-resize:after {
          width: 1px;
        }

        .ag-header-cell {
          @apply px-0;
          .ag-header-group-cell {
            @apply px-0;
          }
          .ag-header-cell-label {
            @apply items-center justify-center px-1 leading-normal text-white;

            .ag-header-icon.ag-header-label-icon {
              @apply text-white;
            }
            .ag-header-cell-text {
              @apply whitespace-normal text-center font-normal leading-tight tracking-wide lg:text-base lg:leading-tight;
              // @apply lowercase first-letter:uppercase;
            }
          }
        }
      }

      .ag-header-row.ag-header-row-column-filter {
        @apply bg-accent-100;
      }

      .ag-header-row.ag-header-row-column-filter {
        .ag-header-cell {
          @apply pl-1.5 pr-0.5;

          .ag-floating-filter-button {
            @apply ml-1;
          }
        }
      }
    }

    .ag-center-cols-clipper,
    .ag-center-cols-container {
      min-height: 42px !important;
    }

    .ag-center-cols-container {
      @apply border-t border-core-400;
      .ag-row {
        &.ag-row--jinius-readonly {
          @apply bg-accent-100 font-bold text-jinius-dark-green;
        }
        @apply w-full;
        border-top: 0;
        border-left: transparent;
        border-right: transparent;

        .ag-cell {
          @apply flex items-center break-normal border-core-400 px-0;
          border-left-color: transparent;
          border-top-color: transparent;
          border-bottom-color: transparent;
        }
      }
    }

    .ag-row-group-expanded,
    .ag-row-hover.ag-row-group-expanded {
      @apply bg-accent-200 font-bold;
    }
    .ag-row-group-contracted,
    .ag-row-hover.ag-row-group-contracted {
      @apply bg-accent-100 font-bold;
    }

    .ag-row-level-1,
    .ag-row-hover.ag-row-level-1 {
      @apply bg-core-100;
    }

    .ag-row-hover {
      @apply bg-transparent;
    }

    .ag-cell-wrapper {
      @apply w-full #{!important};
    }

    .ag-cell-wrapper .ag-selection-checkbox {
      @apply mr-0 #{!important};
    }

    .ag-cell-wrapper.ag-row-group.ag-row-group-indent-0 {
      @apply pl-2;

      .ag-group-expanded,
      .ag-group-contracted {
        @apply mr-2 #{!important};
      }
    }
    .ag-cell-wrapper.ag-row-group-leaf-indent.ag-row-group-indent-1 {
      @apply ml-0 pl-10 text-jinius-dark-green #{!important};
    }

    .ag-cell-wrapper.ag-row-group-leaf-indent.ag-row-group-indent-0 {
      @apply ml-0 w-full px-4;
    }
    .ag-group-value {
      @apply overflow-ellipsis;
    }
  }

  .aggrid__button {
    @apply flex items-center space-x-2 rounded-sm border border-accent-400 px-3 py-2 text-accent-500 transition duration-150 ease-in-out hover:text-white disabled:cursor-not-allowed disabled:bg-white disabled:text-core-500 disabled:opacity-50 sm:px-5;

    &.aggrid__button--large {
      @apply space-x-4 px-6;

      .button__text {
        @apply text-lg leading-snug;
      }
    }

    &:not([disabled='disabled']) {
      @apply outline-none hover:border-jinius-dark-green hover:bg-jinius-dark-green;
    }

    .button__icon {
      // @apply ;
    }

    .button__text {
      @apply align-middle text-sm font-bold leading-none lg:text-base lg:leading-none;
    }
  }

  .aggrid__title {
    @apply mb-3 flex w-full items-center text-xl font-bold text-core-900;
  }
</style>
