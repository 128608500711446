<template>
  <div class="w-full lg:h-full">
    <spinner
      v-if="loading"
      sizes="w-20 h-20"
      thickness="border-8" />
    <div
      v-if="!loading"
      class="my-4">
      <ag-grid-wrapper
        v-bind:buttons="[
          {
            label: 'Ajouter un contact',
            icon: 'user-plus',
            onClick: openModal
          }
        ]"
        v-bind:column_defs="[
          {
            headerName: 'Compte existant',
            field: 'compteOidcExistant',
            cellRenderer: 'existCellRenderer',
            maxWidth: 150,
            minWidth: 150
          },
          {
            headerName: 'Nom',
            field: 'nom',
            sort: 'asc',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'Prénom',
            field: 'prenom',
            filter: 'agTextColumnFilter'
          },
          { headerName: 'Email', field: 'email', filter: 'agTextColumnFilter' },
          {
            headerName: 'Poste occupé',
            field: 'poste',
            filter: 'agTextColumnFilter'
          }
        ]"
        v-bind:initial_data="
          participants.map((participant) => ({
            ...participant,
            readonly: participant.clientContact
          }))
        "
        v-on:delete-row="deleteRow"
        v-bind:delete_with_confirm_button="true"
        v-bind:delete_with_confirm_modal_content="deleteModalContent"
        v-on:change-delete-modal-content="changeDeleteModalContent" />
      <add-modal
        v-bind:add_modal="addModal"
        v-bind:onclose="closeModal"
        v-on:create-user="addParticipant" />
      <info-modal
        v-bind:show_modal="showAlreadyExist"
        v-bind:onclose="closeshowAlreadyExistModal" />
    </div>
  </div>
</template>
<script>
  import AgGridWrapper from '@/components/utils/ag-grid/ag-grid-wrapper';
  import Spinner from '@/components/utils/spinner.vue';
  import { ACTION_TYPES } from '@/store/constants/ActionTypes';
  import AddModal from '@/views/_espace/_entreprise/_participants/add-modal';
  import InfoModal from '@/views/_espace/_entreprise/_participants/info-modal';
  import { createNamespacedHelpers } from 'vuex';

  const { mapState: mapStateClient, mapActions: mapActionsClient } =
    createNamespacedHelpers('client');

  export default {
    components: {
      InfoModal,
      AgGridWrapper,
      Spinner,
      AddModal
    },
    data: () => ({
      loading: false,
      addModal: false,
      showAlreadyExist: false,
      deleteModalContent: null,
      activeEntrepriseLabel: null
    }),
    computed: { ...mapStateClient(['profile', 'participants']) },
    watch: {
      async ['profile.activeEntrepriseId']() {
        await this.fetchParticipants();
      }
    },
    async created() {
      await this.fetchParticipants();
      this.getEntrepriseLabel();
      this.changeDeleteModalContent();
    },
    methods: {
      ...mapActionsClient([
        ACTION_TYPES.GET_CLIENT_PARTICIPANTS,
        ACTION_TYPES.DELETE_CLIENT_PARTICIPANT,
        ACTION_TYPES.ADD_CLIENT_PARTICIPANT
      ]),
      openModal() {
        this.addModal = true;
      },
      closeModal() {
        this.addModal = false;
      },
      closeshowAlreadyExistModal() {
        this.showAlreadyExist = false;
      },
      async addParticipant(participant) {
        this.loading = true;
        const clientId = this.profile.activeEntrepriseId;
        const response = await this[ACTION_TYPES.ADD_CLIENT_PARTICIPANT]({
          clientId,
          participant
        });

        if (response.status === 200) {
          this.showAlreadyExist = true;
        }
        await this[ACTION_TYPES.GET_CLIENT_PARTICIPANTS]({ clientId });
        this.loading = false;
      },
      async fetchParticipants() {
        this.loading = true;
        const clientId = this.profile.activeEntrepriseId;

        if (clientId) {
          await this[ACTION_TYPES.GET_CLIENT_PARTICIPANTS]({ clientId });
        }
        this.loading = false;
      },
      async deleteRow(value) {
        this.loading = true;
        const clientId = this.profile.activeEntrepriseId;

        if (clientId) {
          await this[ACTION_TYPES.DELETE_CLIENT_PARTICIPANT]({
            clientId,
            contactId: value.id
          });
          await this[ACTION_TYPES.GET_CLIENT_PARTICIPANTS]({ clientId });
        }
        this.loading = false;
      },
      changeDeleteModalContent(data) {
        this.deleteModalContent = {
          title: 'Supprimer le participant',
          description: data
            ? `Vous êtes sur le point de supprimer ${data.prenom} ${data.nom} en tant que participant${this.activeEntrepriseLabel ? ` rattaché à ${this.activeEntrepriseLabel}` : ''}. Voulez-vous continuer ?`
            : 'Vous êtes sur le point de supprimer ce participant. Voulez-vous continuer ?'
        };
      },
      getEntrepriseLabel() {
        const activeEntreprise = this.profile.entreprises.find(
          (entreprise) =>
            entreprise.entrepriseId === this.profile.activeEntrepriseId
        );

        this.activeEntrepriseLabel = activeEntreprise
          ? activeEntreprise.denomination
          : null;
      }
    }
  };
</script>
<style lang="scss" scoped></style>
