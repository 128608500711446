<template>
  <div class="h-full w-full">
    <spinner
      v-if="loading"
      sizes="w-20 h-20"
      thickness="border-8" />

    <div
      v-if="!loading"
      class="flex h-full flex-col">
      <h1 class="section__title mb-8 mt-0">
        Synthèse des appréciations participants par campagne
      </h1>
      <ag-grid-wrapper
        v-bind:column_defs="[
          { headerName: 'Campagne', field: 'campagne' },
          {
            headerName: 'Détails',
            field: 'details',
            cellRendererParams: {
              alwaysDisplay: true,
              label: 'DETAILS',
              onclick: (e, params) => openDetailsModal(e, params.data)
            }
          }
        ]"
        v-bind:initial_data="campagnes" />
    </div>
    <details-modal
      v-if="campagne"
      v-bind:show_modal="showDetailModal"
      v-bind:campagne="campagne"
      v-bind:onclose="closeDetailsModal" />
  </div>
</template>
<script>
  import agGridWrapper from '@/components/utils/ag-grid/ag-grid-wrapper';
  import spinner from '@/components/utils/spinner.vue';
  import { API } from '@/http-common';
  import detailsModal from '@/views/_espace/_formateur/_appreciations/_modal/campagne-details-modal';

  export default {
    components: {
      agGridWrapper,
      spinner,
      detailsModal
    },
    data: () => ({
      loading: false,
      campagnes: [],
      campagne: null,
      showDetailModal: false
    }),
    created() {
      this.fetchCampagnes();
    },
    methods: {
      openDetailsModal(e, campagne) {
        this.campagne = campagne;
        this.showDetailModal = true;
      },
      closeDetailsModal() {
        this.campagne = null;
        this.showDetailModal = false;
      },
      async fetchCampagnes() {
        this.loading = true;
        const response = await API.get(
          'formateur/satisfaction/campagnes',
          this.$header_skip_redirection
        );

        this.campagnes = response.data;
        this.loading = false;
      }
    }
  };
</script>
<style lang="scss" scoped></style>
