<template>
  <div v-bind:class="`select-box ${class_name ? class_name : 'input-block'}`">
    <div class="select-box__header">
      <form-label
        v-if="label"
        v-bind:label="label"
        v-bind:icon="label_icon"
        v-bind:required="required"
        v-bind:not_required_label="!required && not_required_label"
        class="header__label font-bold leading-none" />
      <div
        v-if="selected.length && multiple"
        class="header__btn-clear"
        v-on:click.prevent.stop="updateValue([])">
        <span class="clear">Effacer tout</span>
      </div>
    </div>
    <div class="select-box__body">
      <font-awesome-icon
        size="lg"
        v-if="left_icon"
        class="left__icon flex"
        v-bind:icon="left_icon" />
      <span
        v-if="left_icon && required && !label"
        class="flex font-bold text-jinius-red"
        >*</span
      >
      <vue-multiselect
        v-bind:model-value="selected"
        v-bind:loading="is_loading"
        v-bind:internal-search="internal_search"
        v-bind:class="class_name"
        v-bind:disabled="disabled"
        v-bind:multiple="multiple"
        v-on:search-change="onSearchChange"
        v-on:select="onSelect"
        v-on:update:model-value="updateValue"
        v-bind:options="categories"
        v-bind:placeholder="placeholder ? placeholder : `Sélectionnez`"
        v-bind:preserve-search="true"
        label="label"
        track-by="id"
        v-bind:allow-empty="multiple"
        select-label=""
        selected-label=""
        v-bind:deselect-label="multiple ? 'Retirer' : ''"
        v-bind:close-on-select="!multiple"
        v-bind:open-direction="open_direction ? open_direction : ''"
        v-bind:show-no-results="show_no_results"
        v-bind:show-no-options="show_no_options"
        v-bind:show-labels="show_labels"
        v-bind:custom-label="custom_label">
        <template v-slot:selection="{ values, isOpen }">
          <span
            class="multiselect__single"
            v-if="values.length && !isOpen">
            <strong>{{ values.length }} {{ label }}</strong> sélectioné(e){{
              values.length >= 2 ? 's' : null
            }}
          </span>
        </template>

        <template v-slot:tag>
          <span>&nbsp;</span>
        </template>

        <template
          v-for="slot_name in ['singleLabel', 'option']"
          v-slot:[slot_name]="{ option }"
          v-bind:key="`label-${slot_name}`">
          <template v-if="!custom_label">
            <font-awesome-icon
              v-if="option.icon"
              v-bind:icon="option.icon"
              class="thematique__icon" />
            <span>
              <template v-if="no_capitalize_label">
                {{ option.label }}
              </template>
              <template v-else>
                {{ firstLetterCapitalize(option.label) }}
              </template>
            </span>
          </template>
        </template>
        <template v-slot:noOptions>
          {{ `Aucun(e) ${name} disponible` }}
        </template>
      </vue-multiselect>
    </div>
  </div>
</template>

<script>
  import formLabel from '@/components/utils/form-label.vue';
  import helpers_filters from '@/mixin/helpers_filters';
  import VueMultiselect from 'vue-multiselect';

  export default {
    components: {
      formLabel,
      VueMultiselect
    },
    mixins: [helpers_filters],
    props: {
      value: {
        type: [Object, Array, String, Number],
        default: () => {
          const array = [];

          return array;
        }
      },
      open_direction: { type: String },
      name: { type: String },
      placeholder: { type: String },
      multiple: {
        type: Boolean,
        default: false
      },
      is_update_method: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      not_required_label: {
        type: Boolean,
        default: false
      },
      no_capitalize_label: {
        type: Boolean,
        default: false
      },
      label: { type: String },
      label_icon: { type: [String, Array] },
      categories: {
        type: Array,
        default() {
          return [
            {
              $isDisabled: true,
              label: 'Aucune catégorie disponible'
            }
          ];
        }
      },
      required: { type: Boolean },
      class_name: { type: String },
      left_icon: { type: [String, Array] },
      show_no_results: { type: Boolean },
      show_no_options: { type: Boolean },
      show_labels: { type: Boolean },
      custom_label: { type: Function },
      internal_search: {
        type: Boolean,
        default: true
      },
      is_loading: { type: Boolean }
    },
    emits: ['input', 'search-change', 'select'],
    data() {
      return {
        selected: this.value,
        is_focused: false
      };
    },
    watch: {
      value(newVal) {
        this.selected = newVal;
      }
    },
    methods: {
      updateValue(value) {
        if (this.is_update_method) {
          this.$emit('input', {
            value,
            name: this.name
          });
        } else {
          this.selected = value;
          this.$emit('input', this.selected);
        }
      },
      onSearchChange(value) {
        this.$emit('search-change', value);
      },
      onSelect(value) {
        this.$emit('select', value);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .select-box {
    @apply w-full;

    .left__icon {
      @apply text-core-900;
    }

    .thematique__icon {
      @apply mr-3;
    }

    :deep(.multiselect) {
      .multiselect__option {
        @apply h-10;
      }
      .multiselect__tags {
        @apply px-6;
        .multiselect__placeholder {
          @apply truncate;
        }
      }
    }

    .select-box__header {
      @apply flex w-full leading-none;

      .header__label {
        @apply inline-flex flex-grow font-bold;
      }

      .header__btn-clear {
        @apply inline-flex flex-shrink items-center justify-end;

        .clear {
          @apply z-10 inline-flex h-4 w-full cursor-pointer items-center justify-center rounded bg-jinius-red px-2 text-xs font-bold text-white shadow;

          &:hover {
            @apply bg-jinius-red;
          }
        }
      }
    }

    &.select-error {
      .select-box__header {
        .header__label {
          @apply text-jinius-red;
        }
      }
      .select-box__body {
        .left__icon {
          @apply text-jinius-red;
        }

        :deep(.multiselect) {
          .multiselect__tags {
            .multiselect__placeholder {
              @apply text-danger-200;
            }
          }

          .multiselect__select:before {
            border-color: red transparent transparent transparent;
          }
        }
      }
    }

    &.select-error .no-box {
      @apply border-b-2 border-jinius-red;
    }

    :deep(.no-box) {
      @apply flex-grow border-0 border-b-2 border-core-300 bg-transparent transition duration-1000 ease-in-out;

      .multiselect {
        .multiselect__tags {
          .multiselect__placeholder {
            @apply text-core-700;
          }
        }

        .multiselect__select:before {
          border-color: red transparent transparent transparent;
        }
      }

      .multiselect__tags {
        @apply bg-transparent;
      }

      .multiselect__single {
        @apply bg-transparent;
      }

      .multiselect__input {
        @apply bg-transparent;
      }

      .multiselect__content-wrapper {
        @apply border-none;
      }

      .multiselect__spinner {
        background: theme('colors.accent.100');
        z-index: 999;
        width: 2rem;
      }
    }
  }
</style>
