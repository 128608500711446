<template>
  <div>
    <div
      v-if="authenticated"
      class="h-full">
      <div
        v-on-click-outside="closeOpener"
        v-on:click="toggleOpener"
        v-bind:class="displayCustomStyles"
        class="ease flex h-full w-full cursor-pointer px-2 transition duration-200 lg:px-4">
        <span class="inline-flex items-center">
          <text-button
            v-bind:label="username"
            size="lg"
            icon="user"
            width="2rem"
            height="2rem"
            label_margin="5"
            v-bind:responsive="true"
            v-bind:dark="false"
            class="max-w-[15rem] lg:mr-3 2xl:max-w-[20rem]"
            v-bind:class="{ active: opener }" />
        </span>
        <span
          class="pointer-events-none inset-y-0 right-0 inline-flex items-center justify-end">
          <svg
            class="h-4 w-4 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20">
            <path
              d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </span>
      </div>

      <transition name="slide-up">
        <div
          v-if="opener"
          class="absolute right-0 z-20 mr-3 mt-1 max-w-xs overflow-hidden rounded-lg bg-core-100 font-normal text-core-600 shadow-lg lg:shadow">
          <text-button
            v-bind:label="username"
            size="lg"
            icon="user"
            width="2rem"
            height="2rem"
            label_margin="5"
            v-bind:dark="true"
            class="bg-accent-700 py-4 pl-4 pr-8 text-core-100" />

          <text-button
            v-bind:href="{ path: '/account' }"
            v-on:click="opener = false"
            v-bind:label="'Mon compte'"
            size="lg"
            icon="cog"
            width="2rem"
            height="2rem"
            v-bind:dark="true"
            label_margin="5"
            class="ease py-4 pl-4 pr-8 transition duration-150 hover:bg-accent-200 hover:text-core-900 active:shadow-inner" />

          <text-button
            v-bind:href="{ path: '/rgpd' }"
            v-on:click="opener = false"
            v-bind:label="'RGPD / Mentions légales'"
            size="lg"
            icon="landmark"
            width="2rem"
            height="2rem"
            v-bind:dark="true"
            label_margin="5"
            class="ease py-4 pl-4 pr-8 transition duration-150 hover:bg-accent-200 hover:text-core-900 active:shadow-inner" />

          <text-button
            v-bind:href="{ path: '/aide-en-ligne' }"
            v-on:click="opener = false"
            v-bind:label="'Aide en ligne'"
            size="lg"
            icon="circle-question"
            width="2rem"
            height="2rem"
            v-bind:dark="true"
            label_margin="5"
            class="ease py-4 pl-4 pr-8 transition duration-150 hover:bg-accent-200 hover:text-core-900 active:shadow-inner" />

          <logout-button
            classes="hover:bg-accent-200 hover:text-core-900 active:shadow-inner py-4 pl-4 pr-8 transition duration-150 ease" />

          <div
            class="my-1 w-full px-2 text-center text-xs normal-case text-accent-400">
            <code>version:&nbsp;{{ version }}</code>
          </div>
        </div>
      </transition>
    </div>

    <div
      v-else
      class="h-full"
      v-on-click-outside="closeOpener">
      <div
        v-on:click="toggleOpener"
        v-bind:class="displayCustomStyles"
        class="duration-800 ease flex h-full w-full cursor-pointer px-2 transition lg:px-4">
        <span class="w-full">
          <text-button
            label="Se connecter"
            size="lg"
            icon="user"
            width="2rem"
            height="2rem"
            class="lg:mr-3"
            v-bind:responsive="true"
            v-bind:dark="false"
            label_margin="5"
            v-bind:class="{ active: opener }" />
        </span>
        <span
          class="pointer-events-none inset-y-0 right-0 flex flex-1 items-center justify-end">
          <svg
            class="h-4 w-4 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20">
            <path
              d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </span>
      </div>
      <transition name="slide-up">
        <div
          v-if="opener"
          class="absolute right-0 z-20 mr-3 mt-1 max-w-xs rounded-lg bg-core-100 py-2 text-center font-normal text-core-600 shadow-lg lg:shadow">
          <login-form
            v-on:logged-in="closeOpener"
            is_navbar />

          <div
            class="my-1 w-full px-2 text-center text-xs normal-case text-accent-400">
            <code>version:&nbsp;{{ version }}</code>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup>
  import loginForm from '@/components/utils/login-form.vue';
  import logoutButton from '@/components/utils/logout-button.vue';
  import textButton from '@/components/utils/text-button.vue';
  import { vOnClickOutside } from '@vueuse/components';
  import { createNamespacedHelpers } from 'vuex';
</script>
<script>
  const {
    mapState: mapStateAuth,
    mapGetters: mapGettersAuth,
    mapMutations: mapMutationsAuth
  } = createNamespacedHelpers('auth');

  const { mapState: mapStateObserver } = createNamespacedHelpers('observer');

  export default {
    components: {
      loginForm,
      logoutButton,
      textButton
    },
    data() {
      return {
        opener: false,
        redirect_path: null
      };
    },
    computed: {
      ...mapStateAuth({
        user: (state) => state.user,
        authenticated: (state) => state.authenticated
      }),
      ...mapGettersAuth(['username']),
      ...mapStateObserver({ scrolled: (state) => state.scrolled }),
      accountLink() {
        return `https://${import.meta.env.VITE_HOST_ESPACE}/account/informations`;
      },
      version() {
        return import.meta.env.VITE_VERSION;
      },
      displayCustomStyles() {
        const opener = this.opener ? 'bg-accent-100 text-core-700' : '';

        return `hover:bg-accent-100 hover:text-core-900 ${opener}`;
      }
    },
    created() {
      this.redirect_path = this.$route.fullPath;
    },
    beforeUpdate() {
      this.redirect_path = this.$route.fullPath;
    },
    methods: {
      ...mapMutationsAuth({ setRedirectUrlPath: 'setRedirectUrlPath' }),
      closeOpener() {
        this.opener = false;
      },
      toggleOpener() {
        this.opener = !this.opener;
      },
      triggerCGVActions() {
        this.setRedirectUrlPath(this.redirect_path);
        this.closeOpener();
      }
    }
  };
</script>

<style lang="scss" scoped>
  // https://vue-loader.vuejs.org/guide/scoped-css.html#deep-selectors
  // Must use prefix :deep(<inner-selector>) for SASS ( instead of prefix >>> ) to make vue-loader properly styling the children component within a scoped state.
  :deep(.text-link) {
    @apply justify-start;

    &.text-link--conditions {
      @apply justify-center px-6 py-4 transition hover:text-secondary-700 active:shadow-inner;

      .text-link__name {
        @apply flex-none;
      }
    }

    .fill-current,
    .text-link__name {
      @apply flex-1;
      color: inherit;
    }
    &.active.alternative,
    &:hover {
      .text-link__name {
        // @apply text-white;
      }
    }
  }
</style>
