<template>
  <div
    class="refonte-page-title"
    v-bind:class="props.class">
    <h1 v-if="props.title">
      {{ props.title }}
    </h1>
    <div
      v-if="!!slots['default']"
      class="page-description"
      v-bind:class="description_in_card ? 'page-description-carded' : ''">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useSlots } from 'vue';

  const props = withDefaults(
    defineProps<{
      title?: string;
      description?: string;
      description_in_card?: boolean;
      class?: string;
    }>(),
    {
      description_in_card: false,
      class: ''
    }
  );

  const slots = useSlots();
</script>

<style lang="scss" scoped>
  .refonte-page-title {
    @apply flex flex-col items-start self-stretch text-refonte-black;
    gap: 8px;
    margin-bottom: 24px;

    h1 {
      padding: 0 12px;
    }
    .page-description {
      padding: 0 12px;
      margin: 0;
      font-size: 16px;
      font-weight: 400;
      line-height: 125%;

      &.page-description-carded {
        @apply w-full bg-refonte_blue-100;
        border-radius: 8px;
        padding: 16px;
      }
    }
  }
</style>
