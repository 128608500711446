<template>
  <div
    v-bind:class="`radio-button mr-4 flex items-center ${disabled ? 'disabled' : ''}`">
    <input
      v-bind:id="id"
      hidden
      type="radio"
      v-bind:checked="checked"
      v-bind:name="name"
      v-bind:value="value"
      v-bind:disabled="disabled"
      v-on:change="updateValue(value)" />
    <label
      v-bind:for="id"
      class="flex cursor-pointer items-center justify-between font-bold leading-none">
      <span
        class="radio-check border-grey mr-1 inline-flex h-4 w-4 rounded-full border"></span>
      <span class="inline-flex w-full flex-1 justify-center px-2">{{
        label
      }}</span>
      <span
        v-if="show_as_required"
        class="text-xl font-bold leading-none text-jinius-red"
        >*</span
      >
    </label>
  </div>
</template>
<script>
  export default {
    props: {
      id: String,
      input_name: String,
      checked: Boolean,
      update_value: Function,
      label: String,
      value: [Boolean, String],
      name: String,
      disabled: Boolean,
      show_as_required: Boolean
    },
    emits: ['change'],
    methods: {
      updateValue(value) {
        this.$emit('change', value);
      }
    }
  };
</script>
<style lang="scss" scoped>
  input[type='radio'] + label span {
    transition:
      background 0.2s,
      transform 0.2s;
  }

  input[type='radio'] + label span:hover,
  input[type='radio'] + label:hover span {
    @apply text-core-900;
  }

  input[type='radio']:checked + label .radio-check {
    @apply bg-jinius-green;
    box-shadow: 0px 0px 0px 2px white inset;
  }

  .disabled {
    @apply pointer-events-none cursor-not-allowed text-core-500;
  }
</style>
