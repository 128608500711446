import { API, AUTH } from '@/http-common';
import { router } from '@/router/index';
import Cookies from 'js-cookie';

const STORED_ROUTE_PATH = sessionStorage.getItem('redirect_route_path')
    ? sessionStorage.getItem('redirect_route_path')
    : null;

const state = {
    user: false,
    authenticated: false,
    redirect_route_path: STORED_ROUTE_PATH,
    access_token: null,
    id_token: null
};

const mutations = {
    async setUser(state, userinfo) {
        state.user = userinfo;
    },
    setAuthenticationStatus(state) {
        if (!state.user) {
            state.authenticated = false;
        } else {
            state.authenticated = true;
        }

        setTimeout(() => {
            const getDomain = () => {
                const hostname = import.meta.env.VITE_HOST_API.split(
                    '.'
                ).reverse();

                return `${hostname[1]}.${hostname[0]}`;
            };

            Cookies.set('auth_status', state.authenticated, {
                domain: `.${getDomain()}`,
                sameSite: 'Strict'
            });
        }, 100);
    },
    setRedirectUrlPath(state, route_path) {
        if (route_path) {
            state.redirect_route_path = route_path;
            sessionStorage.setItem(
                'redirect_route_path',
                state.redirect_route_path
            );
        }
    }
};

const actions = {
    async checAuth({ commit }) {
        const fetched_user_info = await AUTH.get('/userinfo');

        if (fetched_user_info) {
            const body = {
                sub: fetched_user_info.data.sub,
                prenom: fetched_user_info.data.given_name,
                nomUsage: fetched_user_info.data.family_name
            };

            try {
                await API.post('/compte', body);
            } catch (e) {
                if (e.response.status === 403) {
                    router.push({ name: 'error-account' });
                }
            }
        }
        commit('setUser', fetched_user_info && fetched_user_info.data);
    },
    async applyAuthenticationStatus({ dispatch, commit }) {
        await dispatch('checAuth');
        await commit('setAuthenticationStatus');
    },
    async setUser({ dispatch, commit, state }) {
        await dispatch('applyAuthenticationStatus');
    }
};

// Define a getter in order to get your current username from your state
const getters = {
    username: (state) => {
        return state.user?.name;
    },
    firstName: (state) => {
        return state.user?.given_name;
    },
    lastName: (state) => {
        return state.user?.family_name;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
