<template>
  <refonte-card>
    <refonte-filters-tabs>
      <template v-slot:default>
        <refonte-filters-tabs-item
          label="Toutes"
          v-bind:number="2490"
          number_right
          selected />
        <refonte-filters-tabs-item
          label="Aujourd’hui / En cours"
          v-bind:number="3" />
        <refonte-filters-tabs-item
          label="À venir"
          v-bind:number="89" />
        <refonte-filters-tabs-item
          label="Terminées"
          v-bind:number="2380" />
      </template>
      <template v-slot:rightContent>
        <refonte-dropdown dropdown_align_right>
          <refonte-button
            variant="secondary"
            v-bind:icon_left="IconDate"
            v-bind:icon_right="IconChevronDown">
            Toute la période
          </refonte-button>
          <template v-slot:dropdownContent>
            <refonte-dropdown-item> Toute la période </refonte-dropdown-item>
            <refonte-dropdown-item> Année en cours </refonte-dropdown-item>
            <refonte-dropdown-item> Année précédente </refonte-dropdown-item>
          </template>
        </refonte-dropdown>
      </template>
    </refonte-filters-tabs>
    <refonte-filters>
      <template v-slot:default>
        <refonte-filters-item
          v-bind:on_search="(value) => console.log(value)"
          v-bind:footer_button_on_click="() => console.log('Appliquer')"
          v-bind:footer_button_disabled="true">
          Formation
          <template v-slot:dropdownContent>
            <refonte-checkbox
              label="Cursus"
              v-bind:number="2"
              v-on:click="(e: Event) => e.stopPropagation()" />
            <refonte-checkbox
              label="Conférence"
              v-bind:number="1"
              v-on:click="(e: Event) => e.stopPropagation()" />
            <refonte-checkbox
              label="E-learning"
              v-bind:number="2"
              v-on:click="(e: Event) => e.stopPropagation()" />
            <refonte-checkbox
              label="Présentiel"
              v-bind:number="12"
              v-on:click="(e: Event) => e.stopPropagation()" />
            <refonte-checkbox
              label="Parcours"
              v-bind:number="1"
              v-on:click="(e: Event) => e.stopPropagation()" />
            <refonte-checkbox
              label="Webinar"
              v-bind:number="3"
              v-on:click="(e: Event) => e.stopPropagation()" />
            <refonte-checkbox
              label="Classe virtuelle"
              v-bind:number="4"
              v-on:click="(e: Event) => e.stopPropagation()" />
          </template>
        </refonte-filters-item>
        <refonte-filters-item v-bind:on_search="(value) => console.log(value)">
          Date formation
          <template v-slot:dropdownContent>
            <refonte-search-results-empty />
          </template>
        </refonte-filters-item>
        <refonte-filters-item v-bind:on_search="(value) => console.log(value)">
          Participant
          <template v-slot:dropdownContent>
            <refonte-search-results-empty />
          </template>
        </refonte-filters-item>
        <refonte-filters-item v-bind:on_search="(value) => console.log(value)">
          Type
          <template v-slot:dropdownContent>
            <refonte-search-results-empty />
          </template>
        </refonte-filters-item>
        <refonte-filters-item v-bind:on_search="(value) => console.log(value)">
          Statut
          <template v-slot:dropdownContent>
            <refonte-search-results-empty />
          </template>
        </refonte-filters-item>
        <refonte-filters-item v-bind:on_search="(value) => console.log(value)">
          Cursus
          <template v-slot:dropdownContent>
            <refonte-search-results-empty />
          </template>
        </refonte-filters-item>
        <refonte-filters-item v-bind:on_search="(value) => console.log(value)">
          Nature
          <template v-slot:dropdownContent>
            <refonte-search-results-empty />
          </template>
        </refonte-filters-item>
        <refonte-filters-item v-bind:on_search="(value) => console.log(value)">
          Dispensé par
          <template v-slot:dropdownContent>
            <refonte-search-results-empty />
          </template>
        </refonte-filters-item>
        <refonte-filters-item v-bind:on_search="(value) => console.log(value)">
          Mode
          <template v-slot:dropdownContent>
            <refonte-search-results-empty />
          </template>
        </refonte-filters-item>
      </template>
    </refonte-filters>
  </refonte-card>
  <refonte-card class="mt-[8px] !gap-0 !p-0">
    <div
      class="flex flex-wrap items-center gap-[8px] px-[12px] pb-[10px] pt-[12px]">
      <refonte-checkbox
        label="Tout sélectionner"
        class="!self-center" />
      <IconSeparatorVertical />
      <refonte-dropdown>
        <refonte-button
          variant="sort"
          v-bind:icon_left="IconSortDecreasing"
          v-bind:icon_right="IconChevronDown">
          Date de session
        </refonte-button>
        <template v-slot:dropdownContent>
          <refonte-dropdown-item v-bind:icon="IconSortDecreasing">
            Statut de formation
          </refonte-dropdown-item>
          <refonte-dropdown-item v-bind:icon="IconSortIncreasing">
            6 derniers mois
          </refonte-dropdown-item>
          <refonte-dropdown-item v-bind:icon="IconSortDecreasing">
            Année en cours
          </refonte-dropdown-item>
          <refonte-dropdown-item v-bind:icon="IconSortIncreasing">
            Année précédente
          </refonte-dropdown-item>
        </template>
      </refonte-dropdown>
      <refonte-switch label="Vue Cursus/Parcours" />
      <IconSeparatorVertical />
      <div class="flex flex-wrap items-start gap-[8px]">
        <refonte-button
          disabled
          v-bind:icon_left="IconExport">
          Exporter
        </refonte-button>
        <refonte-button disabled>Conventions</refonte-button>
        <refonte-button
          disabled
          v-bind:icon_left="IconDownload">
          Certificats
        </refonte-button>
      </div>
    </div>
    <refonte-separator class="px-[12px]" />
    <div class="p-[8px]">
      <refonte-table-category-checkbox label="Aujourd'hui / en cours" />
      <refonte-table
        table_index="formations-1"
        v-bind:selectable="true"
        v-bind:columns="columns"
        v-bind:data="data1">
        <template v-slot:itemTags="{ rowIndex }">
          <refonte-tag v-bind:icon="IconFlagParcours">
            <refonte-number variant="count">{{ rowIndex }}</refonte-number>
          </refonte-tag>
        </template>
      </refonte-table>
      <refonte-separator class="px-[9px] py-[5px]" />
      <refonte-table-category-checkbox label="à venir" />
      <refonte-table
        table_index="formations-2"
        v-bind:selectable="true"
        v-bind:columns="columns"
        v-bind:data="data2">
        <template v-slot:itemTags="{ rowIndex }">
          <refonte-tag v-bind:icon="IconFlagParcours">
            <refonte-number variant="count">{{ rowIndex }}</refonte-number>
          </refonte-tag>
        </template>
      </refonte-table>
    </div>
  </refonte-card>
</template>

<script setup lang="ts">
  import IconExport from '@/assets/img/refonte/icons/action/icon-export.svg?component';
  import IconSortDecreasing from '@/assets/img/refonte/icons/data/icon-sort-decreasing.svg?component';
  import IconSortIncreasing from '@/assets/img/refonte/icons/data/icon-sort-increasing.svg?component';
  import IconChevronDown from '@/assets/img/refonte/icons/display/icon-chevron-down.svg?component';
  import IconDownload from '@/assets/img/refonte/icons/display/icon-download.svg?component';
  import IconSeparatorVertical from '@/assets/img/refonte/icons/display/icon-separator-vertical.svg?component';
  import IconDate from '@/assets/img/refonte/icons/information/icon-date.svg?component';
  import IconFlagParcours from '@/assets/img/refonte/icons/information/icon-flag-parcours.svg?component';
  import IconTime from '@/assets/img/refonte/icons/information/icon-time.svg?component';
  import IconValidate20 from '@/assets/img/refonte/icons/information/icon-validate-20.svg?component';
  import IconWarning20 from '@/assets/img/refonte/icons/information/icon-warning-sign-20.svg?component';
  import IconParticipants from '@/assets/img/refonte/icons/menu/icon-trainees.svg?component';
  import RefonteCard from '@/components/refonte/cards/refonte-card.vue';
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import RefonteTag from '@/components/refonte/tags/refonte-tag.vue';
  import RefonteNumber from '@/components/refonte/numbers/refonte-number.vue';
  import RefonteFiltersItem from '@/components/refonte/filters/refonte-filters-item.vue';
  import RefonteFilters from '@/components/refonte/filters/refonte-filters.vue';
  import RefonteDropdown from '@/components/refonte/dropdowns/refonte-dropdown.vue';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';
  import RefonteDropdownItem from '@/components/refonte/dropdowns/refonte-dropdown-item.vue';
  import RefonteCheckbox from '@/components/refonte/inputs/refonte-checkbox.vue';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';
  import RefonteFiltersTabs from '@/components/refonte/filters/refonte-filters-tabs.vue';
  import RefonteFiltersTabsItem from '@/components/refonte/filters/refonte-filters-tabs-item.vue';
  import RefonteSwitch from '@/components/refonte/inputs/refonte-switch.vue';
  import RefonteTable from '@/components/refonte/tables/refonte-table.vue';
  import RefonteTableCategoryCheckbox from '@/components/refonte/tables/refonte-table-category-checkbox.vue';

  const columns = [
    {
      content_index: 'title',
      tags_count: 'count',
      tags_type: 'type',
      tags_type_label: 'typeLabel',
      dates: 'dates',
      start_time: 'startTime',
      end_time: 'endTime',
      status: (row: any) => row.status,
      width: 308
    },
    {
      content_index: 'participants',
      label_icon: IconParticipants,
      size: 'sm',
      width: 64,
      is_pending: (row: any) =>
        row['participants'] === undefined || row['participants'].startsWith('-')
    },
    {
      content_index: 'tests',
      label: 'Tests',
      size: 'sm',
      width: 64
    },
    {
      content_index: 'convention',
      content_icon: (row: any) => {
        if (row['convention'] === '90' || row['convention'] === '23/23') {
          return IconValidate20;
        } else if (row['convention'] === '128/350') {
          return IconWarning20;
        } else {
          return undefined;
        }
      },
      label: 'Convention/pif',
      width: 120,
      // eslint-disable-next-line no-console
      on_click: (rowIndex: number) => console.log(`Conventions ${rowIndex}`)
    },
    {
      content_index: 'certificats',
      content_icon: (row: any) =>
        row['certificats'] === 'En attente' ? IconTime : undefined,
      label: 'Certificats',
      width: 120,
      is_pending: (row: any) =>
        row['certificats'] === undefined || row['certificats'] === 'En attente'
    },
    {
      content_index: 'evaluation',
      label: 'Évaluation',
      width: 120
    },
    {
      content_index: 'categorie',
      label: 'catégorie',
      width: 120
    },
    {
      content_index: 'inter',
      label: 'Inter - dispensé par',
      width: 160
    }
  ];
  const data1 = [
    {
      title: 'Évaluation financières : les indispensables',
      status: 'active',
      type: 'presentiel',
      typeLabel: 'Présentiel',
      count: '1/5',
      startTime: '8h30',
      endTime: '18h',
      participants: '299',
      tests: '299',
      convention: '338/351',
      certificats: 'En attente',
      evaluation: '320/334',
      categorie: ' Formation professionnelle',
      inter: 'CNCC Formations'
    },
    {
      title: 'Actifs immobilisés en normes IFRS',
      type: 'e-learning',
      typeLabel: 'E-learning',
      participants: '90',
      tests: '88/90',
      convention: '90',
      certificats: '88/90',
      evaluation: '320/334',
      categorie: ' Formation professionnelle',
      inter: 'CNCC Formations'
    },
    {
      title: 'Actualité du secteur ESSMS',
      type: 'e-learning',
      typeLabel: 'E-learning',
      participants: '23',
      tests: '12/23',
      convention: '23/23',
      certificats: 'En attente',
      evaluation: '320/334',
      categorie: ' Formation professionnelle',
      inter: 'CNCC Formations'
    }
  ];
  const data2 = [
    {
      title: 'Actifs immobilisés en normes IFRS',
      status: 'active',
      type: 'presentiel',
      typeLabel: 'Présentiel',
      dates: [
        new Date('12/11/2024'),
        new Date('12/15/2024'),
        new Date('12/30/2024'),
        new Date('12/31/2024')
      ],
      participants: '347/350',
      convention: '128/350',
      certificats: 'En attente',
      evaluation: '320/334',
      categorie: ' Formation professionnelle',
      inter: 'CNCC Formations'
    },
    {
      title: 'Actifs immobilisés en normes IFRS',
      status: 'active',
      type: 'presentiel',
      typeLabel: 'Présentiel',
      dates: [new Date('12/11/2024')],
      participants: '27/27',
      tests: '78/80',
      convention: '129/350',
      certificats: 'En attente',
      evaluation: '320/334',
      categorie: ' Formation professionnelle',
      inter: 'CNCC Formations'
    },
    {
      title: 'Actifs immobilisés en normes IFRS',
      status: 'active',
      type: 'presentiel',
      typeLabel: 'Présentiel',
      dates: [new Date('12/11/2024')],
      participants: '- /36',
      evaluation: '320/334',
      categorie: ' Formation professionnelle',
      inter: 'CNCC Formations'
    }
  ];
</script>

<style lang="scss" scoped></style>
