<template>
  <div>
    <div class="relative flex items-center lg:justify-end">
      <div
        v-bind:key="scrolled_body"
        class="save__button"
        v-bind:class="{ 'save__button--is-scrolled': scrolled_body }"
        v-on:click="openCheckModal">
        <font-awesome-icon v-bind:icon="['fas', 'save']" />
        <span class="ml-4">Enregistrer vos modifications</span>
      </div>
    </div>

    <check-form-modal
      v-bind:submit="onSubmit"
      v-bind:modal_show="modal_check"
      v-bind:onclose="closeCheckModal"
      v-bind:consent_text="check_consent_text"
      v-bind:submit_text="check_submit_text" />

    <confirmed-form-modal
      v-bind:modal_show="modal_confirmed"
      v-bind:onclose="closeConfirmedModal"
      v-bind:additional_text="confirmed_additional_text" />

    <unsaved-form-modal
      v-bind:modal_show="modal_route"
      v-bind:onclose="closeModalRoute"
      v-bind:navigate="navigate"
      v-bind:save="save" />
  </div>
</template>

<script>
  import { ACTION_TYPES } from '@/store/constants/ActionTypes';
  import checkFormModal from '@/views/_espace/check-form-modal';
  import confirmedFormModal from '@/views/_espace/confirmed-form-modal';
  import unsavedFormModal from '@/views/_espace/unsaved-form-modal';
  import { createNamespacedHelpers } from 'vuex';
  const { mapState: mapStateAccount, mapActions: mapActionsAccount } =
    createNamespacedHelpers('account');
  const { mapState: mapStateObserver } = createNamespacedHelpers('observer');

  export default {
    components: {
      unsavedFormModal,
      checkFormModal,
      confirmedFormModal
    },
    props: {
      is_espace_entreprise: { type: Boolean },
      submit: { type: Function },
      v$: { type: Object },
      check_consent_text: { type: String },
      check_submit_text: {
        type: String,
        default: 'Valider mes informations'
      },
      confirmed_additional_text: {
        type: String,
        default: ''
      },
      submit_error_text: {
        type: String,
        default: 'Merci de remplir tous les champs obligatoires'
      }
    },
    computed: {
      ...mapStateAccount([
        'modal_confirmed',
        'modal_check',
        'modal_route',
        'next_route'
      ]),
      ...mapStateObserver(['scrolled_body'])
    },
    methods: {
      ...mapActionsAccount([
        ACTION_TYPES.SET_MODAL_CONFIRMED,
        ACTION_TYPES.SET_MODAL_CHECK,
        ACTION_TYPES.SET_MODAL_ROUTE
      ]),
      async onSubmit(e) {
        e.preventDefault();
        this.closeCheckModal();
        if (this.v$) {
          this.v$.$touch();
          if (!this.v$.$invalid) {
            if (this.is_espace_entreprise) {
              let response = false;

              response = await this.submit();

              if (response && !response.isError) {
                this.openConfirmedModal();
                this.v$.$reset();
              } else {
                const { message } = response.data || 'Une erreur est survenu';

                this.$toast.open({
                  type: 'error',
                  message
                });
              }
            } else {
              this.submit();
              this.openConfirmedModal();
              this.v$.$reset();
            }
          } else {
            this.$toast.open({
              type: 'error',
              message: this.submit_error_text
            });
          }
        } else {
          this.submit();
          this.openConfirmedModal();
        }
      },
      closeCheckModal() {
        this[ACTION_TYPES.SET_MODAL_CHECK]({ modal_check: false });
      },
      openCheckModal() {
        this[ACTION_TYPES.SET_MODAL_CHECK]({ modal_check: true });
      },
      closeConfirmedModal() {
        this[ACTION_TYPES.SET_MODAL_CONFIRMED]({ modal_confirmed: false });
      },
      openConfirmedModal() {
        this[ACTION_TYPES.SET_MODAL_CONFIRMED]({ modal_confirmed: true });
      },
      navigate() {
        this.closeModalRoute();
        this.next_route();
      },
      save() {
        this.closeModalRoute();
        this.next_route(false);
        this.openCheckModal();
      },
      closeModalRoute() {
        this[ACTION_TYPES.SET_MODAL_ROUTE]({
          modal_route: false,
          next_route: null
        });
      }
    }
  };
</script>
<style lang="scss" scoped>
  :deep(.save__button) {
    @apply z-10 mt-0 flex w-full cursor-pointer items-center justify-center rounded bg-jinius-dark-green px-6 py-3 leading-tight text-core-200 shadow hover:bg-jinius-green hover:text-white sm:w-auto lg:fixed lg:text-lg;
  }
</style>
