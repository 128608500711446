<template>
  <refonte-modal
    v-bind:show="props.show"
    v-on:close="close"
    title="Désactiver cette entité">
    <template v-slot:modalBody>
      <div class="flex flex-col gap-[12px]">
        <span>
          Désactiver permet d'empêcher toute future commande/facture au nom de
          cette entité.<br />
          Vous pouvez désactiver une société par exemple lors d'une liquidation
          ou d'une fusion/absorption.<br />
          Vous ne perdrez pas l'historique des commandes passées.<br />
          Cette action est réversible.
        </span>
        <span>Souhaitez-vous désactiver cette entité ?</span>
        <form v-on:submit.prevent="submit">
          <refonte-textarea
            placeholder="Motif"
            input_class="w-full"
            v-bind:value="motif"
            v-on:input="setMotif($event)" />
        </form>
      </div>
    </template>
    <template v-slot:modalFooter>
      <refonte-button
        variant="text"
        v-bind:on_click="close">
        Annuler
      </refonte-button>
      <refonte-button
        variant="danger"
        v-bind:on_click="submit">
        Désactiver
      </refonte-button>
    </template>
  </refonte-modal>
</template>

<script setup lang="ts">
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import RefonteModal from '@/components/refonte/modals/refonte-modal.vue';
  import RefonteTextarea from '@/components/refonte/inputs/refonte-textarea.vue';
  import { ref } from 'vue';

  const props = withDefaults(
    defineProps<{
      show: boolean;
      on_close: Function;
      on_submit: Function;
      class?: string;
    }>(),
    {
      class: ''
    }
  );

  const motif = ref<string>();

  function close() {
    props.on_close();
    motif.value = undefined;
  }
  function setMotif(value: string) {
    motif.value = value;
  }
  function submit(e: Event) {
    e.preventDefault();
    props.on_submit && props.on_submit(motif.value);
    close();
  }
</script>

<style lang="scss" scoped></style>
