<template>
  <sessions-list-wrapper
    v-bind:key="loading"
    v-bind:sessions_list="sessionsToAnimate"
    title="Prochaines sessions"
    no_result="Vous n'avez aucune session à venir."
    has_positionnement_modal
    v-bind:current_space="props.space_id"
    v-on:fetch-sessions="fetchSessions"
    v-bind:loading="loading" />
</template>
<script>
  import sessionsListWrapper from '@/components/utils/sessions-list-wrapper.vue';
  import { API } from '@/http-common';

  export default {
    components: { sessionsListWrapper },
    props: ['props'],
    data: () => ({
      loading: false,
      sessionsToAnimate: null
    }),
    computed: {
      stagiaire_famille_context() {
        return this.props.space_id === 'stagiaire'
          ? 'familleStage'
          : 'familleStageCAC';
      }
    },
    created() {
      this.fetchSessions();
    },
    methods: {
      async fetchSessions() {
        this.loading = true;
        const response = await API.get(
          'participant/prochaines-sessions',
          this.$header_skip_redirection
        );

        this.sessionsToAnimate = response.data
          ? response.data.filter((s) => s[this.stagiaire_famille_context])
          : [];
        this.loading = false;
      }
    }
  };
</script>
