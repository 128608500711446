<template>
  <modal-box
    v-if="modal_show"
    ref="modal_test_positionnement"
    v-bind:width_class="'w-full max-w-6xl'"
    overflow_body
    is_survey
    no_close_btn
    survey_label="Test de positionnement"
    v-bind:header="true"
    v-bind:footer="true">
    <template v-slot:modal-header>
      <h1 class="px-6 text-3xl font-bold text-core-600">
        {{ inscription.titre }}
        <div
          class="mt-2 w-full border-l-4 pl-3 text-lg font-normal leading-none">
          {{ displayDates(inscription.sessionDates).join(', ') }}
        </div>
      </h1>
    </template>

    <template v-slot:modal-body>
      <template v-if="loading">
        <spinner
          sizes="w-20 h-20"
          thickness="border-8" />
      </template>
      <template v-else>
        <div
          v-if="result"
          class="px-4">
          <div class="my-4 text-2xl font-bold">Résultat</div>

          <template v-if="result && result.reussi">
            <message-box
              v-bind:source="
                result.texteSiAcquis
                  ? result.texteSiAcquis
                  : 'Niveau de connaissance suffisant'
              "
              border_color="w-full border-yellow-500"
              font_style="text-lg" />
          </template>

          <template v-if="result && !result.reussi">
            <message-box
              v-bind:source="
                result.texteSiNonAcquis
                  ? result.texteSiNonAcquis
                  : 'Niveau de connaissance insuffisant'
              "
              border_color="w-full border-jinius-red"
              font_style="text-lg" />
            <p class="mb-2">
              J'ai compris que je ne disposais pas, à priori, des connaissances
              suffisantes pour suivre cette formation.
            </p>

            <radio-button
              id="radio-continuer"
              class="mb-3"
              label="Je souhaite maintenir ma demande d'inscription à la formation, et je m'engage à acquérir les compétences nécessaires pour suivre cette formation."
              v-bind:checked="demande_annulation === false"
              v-on:change="demande_annulation = false" />
            <radio-button
              id="radio-annuler"
              class="mb-3"
              label="Je souhaite annuler ma demande d'inscription à la formation."
              v-bind:checked="demande_annulation === true"
              v-on:change="demande_annulation = true" />
          </template>
        </div>

        <template v-else>
          <surveyjs-wrapper
            ref="surveyjs_wrapper"
            v-on:complete="complete"
            v-on:scroll_action="scrollModalToTop"
            v-bind:json="surveyText" />
        </template>
      </template>
    </template>
    <template v-slot:modal-footer>
      <div
        v-if="surveyCatcher"
        class="inline-flex w-full justify-end space-x-4 text-lg">
        <div
          v-if="!surveyCatcher.isFirstPage && !result"
          class="w-64 cursor-pointer rounded bg-teal-500 px-5 py-3 text-center font-bold text-white shadow hover:bg-teal-400"
          v-on:click="surveyWrapper.prevPage()">
          Précédent
        </div>

        <div
          v-if="!surveyCatcher.isLastPage"
          class="w-64 cursor-pointer rounded bg-teal-500 px-5 py-3 text-center font-bold text-white shadow hover:bg-teal-400"
          v-on:click="surveyWrapper.nextPage()">
          Suivant
        </div>

        <div
          v-if="surveyCatcher.isLastPage && !result"
          class="w-64 cursor-pointer rounded bg-yellow-500 px-5 py-3 text-center font-bold text-white shadow hover:bg-yellow-400"
          v-on:click="surveyWrapper.completeLastPage()">
          Terminer
        </div>
        <template v-else-if="result">
          <button
            class="w-64 cursor-pointer rounded bg-yellow-500 px-5 py-3 text-center font-bold text-white shadow hover:bg-yellow-400 disabled:cursor-not-allowed disabled:bg-core-300"
            type="button"
            v-on:click="handleValidation"
            v-bind:disabled="demande_annulation === null && !result.reussi">
            Valider et fermer
          </button>
        </template>
      </div>
    </template>
  </modal-box>
</template>
<script>
  import { convertStringToDate } from '@/assets/data/ag_grid_fields_map';
  import messageBox from '@/components/utils/message-box';
  import modalBox from '@/components/utils/modal-box.vue';
  import RadioButton from '@/components/utils/radio-button.vue';
  import Spinner from '@/components/utils/spinner.vue';
  import SurveyjsWrapper from '@/components/utils/surveyjs-wrapper';
  import { API } from '@/http-common';

  export default {
    components: {
      RadioButton,
      SurveyjsWrapper,
      modalBox,
      Spinner,
      messageBox
    },
    props: ['modal_show', 'onclose', 'inscription'],
    data: () => ({
      surveyText: {},
      loading: false,
      demande_annulation: null,
      result: null,
      surveyCatcher: null,
      surveyWrapper: null
    }),
    watch: {
      async ['inscription.participantId']() {
        await this.fetchEvaluation();
      },
      async ['inscription.testPositionnement.produitId']() {
        await this.fetchEvaluation();
      }
    },
    async created() {
      await this.fetchEvaluation();
      this.fetchSurveyWrapper();
    },
    methods: {
      fetchSurveyWrapper() {
        this.surveyCatcher = this.$refs.surveyjs_wrapper
          ? this.$refs.surveyjs_wrapper.survey
          : null;
        this.surveyWrapper = this.$refs.surveyjs_wrapper
          ? this.$refs.surveyjs_wrapper
          : null;
      },
      close() {
        this.onclose();
      },
      async handleValidation() {
        if (this.demande_annulation != null) {
          const { participantId, isCursus } = this.inscription;

          let participant_type;

          if (isCursus) {
            participant_type = 'cursusInscriptionId';
          } else {
            participant_type = 'sessionParticipantId';
          }

          await API.put(
            `participant/demande-annulation?${participant_type}=${participantId}`,
            this.demande_annulation
          );
        }
        this.close();
      },
      async complete(result) {
        this.loading = true;
        const { produitId, participantId, isElearning, isCursus } =
          this.inscription;

        let participant_type;

        if (isCursus) {
          participant_type = 'cursusInscriptionId';
        } else if (isElearning) {
          participant_type = 'elearningParticipantId';
        } else {
          participant_type = 'sessionParticipantId';
        }
        const data = this.surveyCatcher.data;

        const response = await API.post(
          `participant/evaluation?parcoursProduitId=${produitId}&${participant_type}=${participantId}&typeId=1`,
          data,
          this.$header_skip_redirection
        );

        if (response && response.data) {
          this.result = response.data;
        }
        this.loading = false;
      },

      async fetchEvaluation() {
        this.loading = true;
        if (this.inscription) {
          const { produitId, participantId, isElearning, isCursus } =
            this.inscription;

          let participant_type;

          if (isCursus) {
            participant_type = 'cursusInscriptionId';
          } else if (isElearning) {
            participant_type = 'elearningParticipantId';
          } else {
            participant_type = 'sessionParticipantId';
          }

          // typeId=1 correspond au test de positionnement
          const response = await API.get(
            `participant/evaluation?parcoursProduitId=${produitId}&${participant_type}=${participantId}&typeId=1`,
            this.$header_skip_redirection
          );

          if (response && response.data) {
            this.surveyText = response.data.survey;
          }
        }
        this.loading = false;
      },
      displayDates(dates) {
        const sortedDates = dates
          .slice()
          .sort(
            (a, b) =>
              convertStringToDate(a.date).getTime() -
              convertStringToDate(b.date).getTime()
          );

        return sortedDates.map(
          (date) =>
            `${date.date}${date.matin && !date.aprem ? ' (matin)' : ''}${!date.matin && date.aprem ? ' (après-midi)' : ''}`
        );
      },
      scrollModalToTop() {
        const target =
          this.$refs.modal_test_positionnement.$el.getElementsByClassName(
            'modal_body--is-survey'
          );

        return (target[0].scrollTop = 0);
      }
    }
  };
</script>
<style lang="scss" scoped>
  .label {
    @apply cursor-pointer px-4 font-normal hover:font-bold hover:text-jinius-green;
    &.active {
      @apply font-bold text-jinius-green;
    }
  }
</style>
