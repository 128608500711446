<template>
  <refonte-modal
    v-bind:show="props.show"
    v-on:close="close"
    v-bind:title="props.title">
    <template v-slot:modalBody>
      <div class="flex flex-col gap-[12px]">
        <span>
          {{ props.description }}
        </span>
      </div>
    </template>
    <template v-slot:modalFooter>
      <refonte-button
        variant="text"
        v-bind:on_click="close">
        Annuler
      </refonte-button>
      <refonte-button
        variant="danger"
        v-bind:on_click="submit">
        Supprimer
      </refonte-button>
    </template>
  </refonte-modal>
</template>

<script setup lang="ts">
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import RefonteModal from '@/components/refonte/modals/refonte-modal.vue';

  const props = withDefaults(
    defineProps<{
      show: boolean;
      on_close: Function;
      on_submit: Function;
      title?: string;
      description?: string;
      class?: string;
    }>(),
    {
      title: 'Supprimer cet élément',
      description:
        'Vous êtes sur le point de supprimer cet élément. Voulez-vous continuer ?',
      class: ''
    }
  );

  function close() {
    props.on_close();
  }
  function submit(e: Event) {
    e.preventDefault();
    props.on_submit && props.on_submit();
    close();
  }
</script>

<style lang="scss" scoped></style>
