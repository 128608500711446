<template>
  <modal-box
    v-if="show_modal"
    v-bind:width_class="'w-full max-w-screen-lg'"
    v-on:close="onclose">
    <template v-slot:modal-header>
      <h1 class="text-xl font-bold text-core-900">Pour votre règlement</h1>
    </template>

    <template v-slot:modal-body>
      <div class="py-2">
        <span class="mb-2 font-bold text-primary-800">
          Rappel du mode de règlement choisi&nbsp;:
        </span>
        <span
          class="mx-1.5 rounded bg-accent-100 px-2 pb-0.5 pt-1 text-sm font-bold shadow">
          {{ reglement.label }}
        </span>
      </div>
      <div
        class="mb-2 py-2"
        v-html="reglement.instruction"></div>
    </template>
    <template v-slot:modal-footer>
      <div
        class="w-full cursor-pointer rounded bg-core-200 px-5 py-3 text-center font-bold text-core-600 shadow hover:bg-core-100"
        v-on:click="close()">
        Fermer
      </div>
    </template>
  </modal-box>
</template>
<script>
  import modalBox from '@/components/utils/modal-box.vue';
  export default {
    components: { modalBox },
    props: ['show_modal', 'onclose', 'reglement'],
    data: () => ({}),
    methods: {
      close() {
        this.onclose();
      }
    }
  };
</script>
<style lang="scss" scoped></style>
