import { API } from '@/http-common.js';
import { Filters } from '@/types/gestion/satisfaction-filters-types';
import {
    Satisfaction,
    SatisfactionResponse
} from '@/types/gestion/satisfaction-types';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { LocationQuery } from 'vue-router';

const noFilterState: Filters = {
    clientIds: [],
    repondu: undefined
};

const filterInitialState: Filters = {
    clientIds: [],
    repondu: undefined
};

export const SATISFACTION_FILTERS_CONFIGURATION = {
    clientIds: {
        name: 'clientIds',
        isArray: true
    },
    repondu: {
        name: 'repondu',
        isArray: false
    }
};

export const useSatisfactionFilterStore = defineStore(
    'satisfaction-filter',
    () => {
        const filters = ref<Filters>(filterInitialState);

        const setFilter = (name: string, value: any) => {
            filters.value = { ...filters.value, [name]: value };
        };
        const resetFilters = () => {
            filters.value = noFilterState;
        };

        const parsePayload = (payload: LocationQuery): Filters => {
            const newPayload = { ...payload };

            Object.keys(newPayload).forEach((key) => {
                const filter = SATISFACTION_FILTERS_CONFIGURATION[key] as any;

                if (filter && filter.isArray) {
                    newPayload[key] = newPayload[key].split(',');
                }
                if (
                    [SATISFACTION_FILTERS_CONFIGURATION.repondu.name].includes(
                        key
                    )
                ) {
                    newPayload[key] = newPayload[key] === 'true';
                }
            });

            return newPayload;
        };

        const initFiltersFromQuery = (payload: LocationQuery) => {
            const parsedPayload = parsePayload(payload) as Filters;
            if (payload && Object.keys(payload).length > 0) {
                filters.value = {
                    ...noFilterState,
                    ...parsedPayload
                };
            } else {
                filters.value = {
                    ...filterInitialState,
                    ...parsedPayload
                };
            }
        };

        return {
            resetFilters,
            setFilter,
            filters,
            initFiltersFromQuery
        };
    }
);

export const useSatisfactionStore = defineStore('satisfaction', () => {
    const satisfaction = ref<Satisfaction[]>([]);

    const fetchSatisfaction = async (query: Filters) => {
        const response = await API.get(`client/v2/satisfactions`, {
            params: query
        });
        if (response?.data) {
            satisfaction.value = response?.data.map((s: SatisfactionResponse) =>
                mapSatisfaction(s)
            );
        }
    };

    const mapSatisfaction = (s: SatisfactionResponse): Satisfaction => {
        return {
            ...s,
            clientDenomination: s.client.denomination,
            clientSiret: s.client.siret ?? undefined,
            organismeNom: s.organisme.nom
        };
    };

    return {
        satisfaction,
        fetchSatisfaction
    };
});
