<template>
  <sessions-list-wrapper
    v-bind:key="loading"
    v-bind:sessions_list="sessionsToAnimate"
    title="Prochaines sessions"
    no_result="Vous n'avez aucune session à venir."
    current_space="formateur"
    v-bind:loading="loading" />
</template>
<script>
  import sessionsListWrapper from '@/components/utils/sessions-list-wrapper.vue';
  import { API } from '@/http-common';
  import dates_helpers from '@/mixin/dates_helpers';
  import http_functions from '@/mixin/http_functions';
  import navigation_helpers from '@/mixin/navigation_helpers';

  export default {
    components: { sessionsListWrapper },
    mixins: [navigation_helpers, http_functions, dates_helpers],
    data: () => ({
      loading: false,
      sessionsToAnimate: []
    }),
    created() {
      this.fetchSessions();
    },
    methods: {
      async fetchSessions() {
        this.loading = true;
        const response = await API.get(
          'formateur/sessions-a-animer',
          this.$header_skip_redirection
        );

        this.sessionsToAnimate = response.data;
        this.loading = false;
      },
      goToFiche(event, params) {
        this.openFiche(event, params);
      },
      goToDetails(event, params) {
        const { reference } = params.data;

        if (reference) {
          this.$router.push(`/formateur/details?reference=${reference}`);
        }
      }
    }
  };
</script>
<style lang="scss" scoped></style>
