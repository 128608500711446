<template>
  <div
    v-if="catalogueList && catalogueList.length > 0"
    class="catalogue-dropdown">
    <div class="h-full">
      <div
        v-on-click-outside="closeOpener"
        v-on:click="toggleOpener"
        class="catalogue-dropdown__button"
        v-bind:class="[
          {
            'catalogue-dropdown__button--open':
              opener && catalogueList.length > 0
          }
        ]">
        <span class="button__label">
          <text-button
            icon="layer-group"
            size="lg"
            width="2rem"
            height="2rem"
            label_margin="2"
            label="MES CATALOGUES DE FORMATION"
            v-bind:class="{ active: opener }" />
        </span>
        <span class="button__caret">
          <svg
            class="caret__svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20">
            <path
              d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </span>
      </div>

      <div class="catalogue-dropdown__list">
        <transition name="slide-up">
          <div
            v-show="opener"
            class="list__wrapper">
            <template
              v-for="catalogue in catalogueList"
              v-bind:key="catalogue.id">
              <div
                class="list__item"
                v-bind:title="catalogue.nom"
                v-on:click="openCatalogue(catalogue.catalogueUrl)">
                <div class="item__img">
                  <img
                    v-bind:src="getCatalogueUrl(catalogue.code)"
                    alt="..." />
                </div>
                <text-button
                  v-bind:label="catalogue.nom"
                  size="lg"
                  width="2rem"
                  height="2rem"
                  class="item__link" />
              </div>
            </template>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { map_domain_per_env } from '@/assets/data/data_maps.js';
  import textButton from '@/components/utils/text-button.vue';
  import { vOnClickOutside } from '@vueuse/components';
  import { createNamespacedHelpers } from 'vuex';
</script>
<script>
  const { mapState: mapStateCatalogue } = createNamespacedHelpers('catalogue');

  const { mapState: mapStateAuth, mapGetters: mapGettersAuth } =
    createNamespacedHelpers('auth');

  const { mapState: mapStateObserver } = createNamespacedHelpers('observer');

  export default {
    components: { textButton },
    data() {
      return { opener: false };
    },
    computed: {
      ...mapStateCatalogue(['catalogueList']),
      ...mapStateAuth({
        user: (state) => state.user,
        authenticated: (state) => state.authenticated
      }),
      ...mapGettersAuth(['username']),
      ...mapStateObserver({ scrolled: (state) => state.scrolled }),
      current_cdn_domain() {
        return `https://cdn.${map_domain_per_env[import.meta.env.VITE_CONTEXT]}`;
      }
    },
    methods: {
      closeOpener() {
        this.opener = false;
      },
      toggleOpener() {
        if (this.catalogueList.length > 0) {
          this.opener = !this.opener;
        }
      },
      openCatalogue(url) {
        this.opener = false;
        window.open(url);
      },
      getCatalogueUrl(code_of) {
        return `${this.current_cdn_domain}/logos/${code_of}.png`;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .catalogue-dropdown {
    @apply flex h-full w-full max-w-sm flex-wrap items-center;

    .catalogue-dropdown__button {
      @apply flex h-full w-full cursor-pointer px-3 transition duration-200 ease-in-out hover:bg-accent-100;

      &.catalogue-dropdown__button--open {
        @apply bg-accent-100 shadow-inner;
      }

      .button__label {
        @apply flex w-[calc(100%-1rem)] items-center;

        :deep(.text-link__name) {
          @apply hidden lg:flex;
        }
      }

      .button__caret {
        @apply pointer-events-none flex w-4 items-center justify-center;

        .caret__svg {
          @apply flex h-4 w-4 fill-current;
        }
      }
    }

    .catalogue-dropdown__list {
      @apply relative h-0 w-full;

      .list__wrapper {
        @apply absolute left-0 right-0 top-0 z-20 mt-1 flex max-h-120 min-w-[250px] flex-wrap space-y-1 overflow-auto rounded-lg bg-core-100 font-normal text-core-600 shadow;

        .list__item {
          @apply flex h-12 w-full hover:bg-accent-200 hover:text-core-900;

          .item__img {
            @apply w-1/4 max-w-full px-3 py-1.5;
            img {
              @apply h-full w-full object-contain;
            }
          }

          .item__link {
            @apply w-3/4 cursor-pointer px-4 py-1 transition duration-150 ease-in-out active:shadow-inner;
          }
        }
      }
    }
  }

  :deep(.text-link) {
    @apply justify-start;

    .fill-current,
    .text-link__name {
      @apply flex-1 pr-3;
      color: inherit;
    }
  }
</style>
