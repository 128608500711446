<template>
  <div
    class="flex h-full w-full flex-wrap items-center justify-center"
    v-if="!params.data || (params.data && !params.data?.readOnly)">
    <button
      class="btn"
      v-bind:class="{ 'btn--cancel': params.label == 'Renoncer' }"
      v-on:click="params.onclick($event, params)">
      {{ params.label }}
    </button>
  </div>
</template>

<script>
  export default {};
</script>

<style lang="scss" scoped>
  .btn {
    @apply mx-2 w-64 max-w-full items-center justify-center rounded bg-jinius-dark-green py-2 text-sm font-bold leading-none text-white hover:bg-jinius-green;

    &.btn--cancel {
      @apply bg-jinius-red hover:bg-jinius-red;
    }
  }
</style>
