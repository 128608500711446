<template>
  <div class="w-full lg:h-full">
    <spinner
      v-if="loading"
      sizes="w-20 h-20"
      thickness="border-8" />
    <div v-if="!loading">
      <div class="section__description">
        Les commandes ci-dessous correspondent à vos paniers en cours rattachés
        à votre compte personnel.
      </div>
      <ag-grid-wrapper
        v-bind:column_defs="[
          {
            headerName: 'Référence',
            field: 'reference',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'Formation',
            field: 'formation',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'Date session',
            field: 'dateSession',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'Vendeur',
            field: 'vendeur',
            filter: 'agTextColumnFilter'
          },
          { headerName: 'Total TTC', field: 'montantTtc' },
          {
            headerName: 'Panier',
            field: 'reference',
            cellRenderer: 'buttonCellRenderer',
            cellRendererParams: {
              label: 'Consulter',
              onclick: (e, params) => openWindow(e, params)
            }
          },
          { headerName: 'Stage', field: 'familleStage' },
          {
            headerName: 'Formation professionnelle',
            field: 'familleFormationContinue'
          },
          {
            headerName: 'Préparation aux examens',
            field: 'famillePreparationExamen'
          }
        ]"
        v-bind:initial_data="paniers" />
    </div>
  </div>
</template>
<script>
  import AgGridWrapper from '@/components/utils/ag-grid/ag-grid-wrapper';
  import Spinner from '@/components/utils/spinner.vue';
  import { API } from '@/http-common';

  export default {
    components: {
      AgGridWrapper,
      Spinner
    },
    data: () => ({
      loading: false,
      paniers: []
    }),

    created() {
      this.fetchPaniers();
    },
    methods: {
      openWindow(e, params) {
        if (params.data?.inscriptionUrl) {
          window.open(`${params.data?.inscriptionUrl}/panier`);
        }
      },
      async fetchPaniers() {
        this.loading = true;
        const response = await API.get(
          'participant/paniers',
          this.$header_skip_redirection
        );

        this.paniers = response.data.map((p) => ({
          ...p,
          familleStage: p.familleStage ? 'Oui' : 'Non',
          familleFormationContinue: p.familleFormationContinue ? 'Oui' : 'Non',
          famillePreparationExamen: p.famillePreparationExamen ? 'Oui' : 'Non'
        }));
        this.loading = false;
      }
    }
  };
</script>
<style lang="scss" scoped></style>
