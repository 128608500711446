<template>
  <sub-component-wrapper
    default_component="commandes"
    v-bind:props="props"
    v-bind:tabs="tabs"
    v-bind:sub_components_list="sub_components_list" />
</template>
<script>
  import navigation_utils from '@/mixin/navigation_utils';
  import subComponentWrapper from '@/views/_espace/sub-component-wrapper.vue';
  import commandes from '@/views/_espace/_participant/_commandes/commandes.vue';
  import paniers from '@/views/_espace/_participant/_commandes/paniers.vue';

  export default {
    components: { subComponentWrapper },
    mixins: [navigation_utils],
    props: ['props'],
    computed: {
      sub_components_list() {
        return {
          paniers,
          commandes
        };
      },
      tabs() {
        const sub_components = this.fetchNavigationSubComponents();

        return { ...sub_components };
      }
    }
  };
</script>
