import { API } from '@/http-common';
import { ACTION_TYPES } from '@/store/constants/ActionTypes';
import { MUTATION_TYPES } from '@/store/constants/MutationTypes';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';

export const formInitialState = {
    estInscritCROEC: false,
    estInscritCRCC: false,
    rattachementAnecsCjec: false,
    pays: null
};
export const preferencesFormInitialState = {
    email: { value: null },
    courrier: { value: null },
    telephone: { value: null },
    organismes: []
};

const state = {
    savedAccountForm: {},
    accountForm: formInitialState,
    savedPreferencesForm: {},
    preferencesForm: preferencesFormInitialState,
    croec: [],
    crcc: [],
    societes: [],
    hasValidateContactInfo: false,
    hasAnecsCjecEndDateToValidate: false,
    clubs: [],
    modal_check: false,
    modal_confirmed: false,
    modal_route: false,
    next_route: null
};

const determineValue = (array, val) => {
    if (array.some((cur) => cur[val])) {
        return true;
    }
    if (array.every((cur) => cur[val] === false)) {
        return false;
    }

    return null;
};

const mutations = {
    async [MUTATION_TYPES.SET_ACCOUNT_FORM_ATTRIBUTE](state, { name, value }) {
        state.accountForm = {
            ...state.accountForm,
            [name]: value
        };
    },
    async [MUTATION_TYPES.SET_CONTACT](state, payload) {
        const { dateDeNaissance, dateFinAdhesionAnecsCjec } = payload;

        if (dateDeNaissance) {
            payload.dateDeNaissance = new Date(dateDeNaissance);
        }
        if (dateFinAdhesionAnecsCjec) {
            payload.dateFinAdhesionAnecsCjec = new Date(
                dateFinAdhesionAnecsCjec
            );
        }
        state.accountForm = {
            ...formInitialState,
            ...payload
        };
        state.savedAccountForm = { ...state.accountForm };
    },
    async [MUTATION_TYPES.SET_RATTACHEMENT](state, payload) {
        state.croec = payload.croec;
        state.crcc = payload.crcc;
    },
    async [MUTATION_TYPES.SET_CONTACT_PROFILE](state, payload) {
        // We extend state object with boolean values for the different available spaces (ex: "Mon espace ...")
        for (const key in payload) {
            if (Object.hasOwnProperty.call(payload, key)) {
                state[key] = payload[key];
            }
        }
    },
    async [MUTATION_TYPES.FILL_ACCOUNT_FORM_IF_EMPTY](state, payload) {
        state.accountForm = {
            ...payload,
            ...state.accountForm
        };
    },
    async [MUTATION_TYPES.SET_CLUBS](state, payload) {
        state.clubs = [...payload];
    },
    async [MUTATION_TYPES.SET_STAGIAIRE_PROFILE](state, payload) {
        state.pif_stage = payload;
    },
    async [MUTATION_TYPES.SET_PREFERENCES_FORM_ORGANISMES](
        state,
        { id, valueKey, value }
    ) {
        const organismes = state.preferencesForm.organismes.slice(0);
        const index = organismes.findIndex((organisme) => organisme.id === id);

        if (index !== -1) {
            organismes[index] = {
                ...organismes[index],
                [valueKey]: value
            };
            state.preferencesForm = {
                ...state.preferencesForm,
                organismes
            };
        }
    },
    async [MUTATION_TYPES.SET_PREFERENCES_FORM_SECTIONS_VALUE](
        state,
        { key, value }
    ) {
        state.preferencesForm[key] = { value };
    },
    async [MUTATION_TYPES.SET_PREFERENCES_FORM_ALL_PREFERENCES](
        state,
        { key, value }
    ) {
        state.preferencesForm.organismes = state.preferencesForm.organismes.map(
            (organisme) => ({
                ...organisme,
                [key]: value
            })
        );
    },
    async [MUTATION_TYPES.SET_PREFERENCES](state, payload) {
        const { organismes } = payload;

        payload.email = { value: determineValue(organismes, 'okCourriel') };
        payload.courrier = { value: determineValue(organismes, 'okCourrier') };
        payload.telephone = {
            value: determineValue(organismes, 'okTelephone')
        };
        state.preferencesForm = {
            ...preferencesFormInitialState,
            ...payload
        };
        state.savedPreferencesForm = { ...state.preferencesForm };
    },
    async [MUTATION_TYPES.SET_MODAL_CHECK](state, payload) {
        state.modal_check = payload.modal_check;
    },
    async [MUTATION_TYPES.SET_MODAL_CONFIRMED](state, payload) {
        state.modal_confirmed = payload.modal_confirmed;
    },
    async [MUTATION_TYPES.SET_MODAL_ROUTE](state, payload) {
        state.modal_route = payload.modal_route;
        if (payload.next_route) {
            state.next_route = payload.next_route;
        }
    }
};

const actions = {
    [ACTION_TYPES.SET_ACCOUNT_FORM_ATTRIBUTE]({ commit }, payload) {
        commit(MUTATION_TYPES.SET_ACCOUNT_FORM_ATTRIBUTE, payload);
    },
    async [ACTION_TYPES.GET_CONTACT]({ commit }) {
        const response = await API.get('participant/contact');

        commit(MUTATION_TYPES.SET_CONTACT, response.data);
    },
    async [ACTION_TYPES.GET_CONTACT_PROFILE]({ commit }) {
        const response = await API.get('participant/profile');

        commit(MUTATION_TYPES.SET_CONTACT_PROFILE, response.data);
    },
    async [ACTION_TYPES.GET_RATTACHEMENT]({ commit }) {
        const response = await API.get('participant/rattachement_list');

        commit(MUTATION_TYPES.SET_RATTACHEMENT, response.data);
    },
    async [ACTION_TYPES.GET_CLUBS]({ commit }) {
        const response = await API.get('participant/clubs');

        commit(MUTATION_TYPES.SET_CLUBS, response.data);
    },
    async [ACTION_TYPES.GET_STAGIAIRE_PROFILE]({ commit }) {
        const response_profile = await API.get('stage/stagiaire/profile');

        commit(MUTATION_TYPES.SET_STAGIAIRE_PROFILE, response_profile.data);
    },
    async [ACTION_TYPES.SEND_CONTACT]({ commit }, payload) {
        const newPayload = { ...payload };

        delete newPayload.modificationDateUser;
        if (newPayload.dateDeNaissance) {
            newPayload.dateDeNaissance = format(
                newPayload.dateDeNaissance,
                'yyyy-MM-dd',
                { locale: fr }
            );
        }
        if (newPayload.dateFinAdhesionAnecsCjec) {
            newPayload.dateFinAdhesionAnecsCjec = format(
                newPayload.dateFinAdhesionAnecsCjec,
                'yyyy-MM-dd',
                { locale: fr }
            );
        }
        if (newPayload.numeroSupra) {
            newPayload.numeroSupra = newPayload.numeroSupra.replaceAll(' ', '');
        }

        return API.post('participant/contact', newPayload);
    },
    async [ACTION_TYPES.FILL_ACCOUNT_FORM_IF_EMPTY]({ commit }, payload) {
        commit(MUTATION_TYPES.FILL_ACCOUNT_FORM_IF_EMPTY, payload);
    },
    [ACTION_TYPES.SET_PREFERENCES_FORM_ORGANISMES]({ commit }, payload) {
        commit(MUTATION_TYPES.SET_PREFERENCES_FORM_ORGANISMES, payload);
    },
    [ACTION_TYPES.SET_PREFERENCES_FORM_SECTIONS_VALUE]({ commit }, payload) {
        commit(MUTATION_TYPES.SET_PREFERENCES_FORM_SECTIONS_VALUE, payload);
    },
    [ACTION_TYPES.SET_PREFERENCES_FORM_ALL_PREFERENCES]({ commit }, payload) {
        commit(MUTATION_TYPES.SET_PREFERENCES_FORM_ALL_PREFERENCES, payload);
    },
    async [ACTION_TYPES.GET_PREFERENCES]({ commit }) {
        const response = await API.get('participant/contact-proprietes');

        commit(MUTATION_TYPES.SET_PREFERENCES, { organismes: response.data });
    },
    async [ACTION_TYPES.SEND_PREFERENCES]({ commit }, payload) {
        const newPayload = { participantContactProprietes: payload.organismes };

        return API.put('participant/contact-proprietes', newPayload);
    },
    [ACTION_TYPES.SET_MODAL_CHECK]({ commit }, payload) {
        commit(MUTATION_TYPES.SET_MODAL_CHECK, payload);
    },
    [ACTION_TYPES.SET_MODAL_CONFIRMED]({ commit }, payload) {
        commit(MUTATION_TYPES.SET_MODAL_CONFIRMED, payload);
    },
    [ACTION_TYPES.SET_MODAL_ROUTE]({ commit }, payload) {
        commit(MUTATION_TYPES.SET_MODAL_ROUTE, payload);
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
