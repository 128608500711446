<template>
  <transition
    v-if="props.show"
    name="modal"
    appear>
    <div
      class="modal-mask"
      v-on:click="
        (e) => {
          e.stopPropagation();
          if (!props.no_outside_click) {
            closeModal();
          }
        }
      ">
      <div
        class="refonte-modal"
        v-bind:class="props.class"
        v-on:click.stop>
        <div
          class="modal-header"
          v-bind:class="{ '!pb-0': !(props.title || !!slots['modalHeader']) }">
          <h2 v-if="props.title">{{ props.title }}</h2>
          <slot
            v-if="!!slots['modalHeader']"
            name="modalHeader"></slot>
          <div
            v-if="!props.no_close_btn"
            class="modal-header-close"
            v-on:click.prevent.stop="closeModal">
            <IconClose />
          </div>
        </div>

        <div
          v-if="!!slots['modalBody']"
          class="modal-body">
          <slot name="modalBody"></slot>
        </div>

        <div
          v-if="!!slots['modalFooter']"
          class="modal-footer">
          <slot name="modalFooter"> </slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
  import IconClose from '@/assets/img/refonte/icons/display/icon-close.svg?component';
  import { onBeforeMount, onBeforeUnmount, useSlots } from 'vue';

  const props = withDefaults(
    defineProps<{
      show: boolean;
      title?: string;
      no_outside_click?: boolean;
      no_close_btn?: boolean;
      class?: string;
    }>(),
    {
      no_outside_click: false,
      no_close_btn: false,
      class: ''
    }
  );

  const slots = useSlots();

  const emit = defineEmits(['close']);

  function closeModal() {
    emit('close');
  }
  function handleEscapeKey(e: any) {
    if (e.key === 'Escape') {
      closeModal();
    }
  }

  onBeforeMount(() => {
    window.addEventListener('keyup', handleEscapeKey);
  });
  onBeforeUnmount(() => {
    window.removeEventListener('keyup', handleEscapeKey);
  });
</script>

<style lang="scss" scoped>
  @import '@/assets/css/utils/mixins.scss';

  .modal-mask {
    @apply fixed inset-0 flex h-full w-full items-center justify-center bg-black bg-opacity-30 lg:transition-opacity lg:delay-200 lg:ease-in-out;
    -webkit-transform: translateZ(999px);
    -moz-transform: translateZ(999px);
    -o-transform: translateZ(999px);
    transform: translateZ(999px);
    z-index: 999;
  }
  .modal-lg {
    max-width: 992px !important;
  }

  .refonte-modal {
    @apply mx-auto bg-refonte-white;
    @include inflate-transition();
    padding: 32px;
    border-radius: 8px;
    box-shadow: 0 4px 24px 0 rgba(182, 182, 182, 0.25);
    max-width: 464px;
    width: 100%;
    max-height: 100vh;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none !important;
    }

    .modal-header {
      @apply relative;
      padding-bottom: 16px;

      .modal-header-close {
        @apply absolute flex cursor-pointer items-center justify-center;
        top: -26px;
        right: -26px;
        width: 26px;
        height: 26px;

        :deep(svg) {
          @apply text-refonte-black;
          width: 10px;
          height: 10px;
        }

        &:hover {
          :deep(svg) {
            @apply text-refonte-black-60;
          }
        }
      }
    }
    .modal-body {
      @apply text-refonte-black-60;
      font-size: 13px;
      font-weight: 400;
      line-height: 125%;
    }
    .modal-footer {
      @apply flex flex-wrap items-center justify-end self-stretch;
      padding-top: 32px;
      gap: 8px 32px;
    }

    @media (max-width: 767.98px) {
      padding: 32px 16px;

      .modal-header {
        .modal-header-close {
          @apply absolute flex cursor-pointer items-center justify-center;
          top: -26px;
          right: -10px;
          width: 26px;
          height: 26px;

          :deep(svg) {
            @apply text-refonte-black;
            width: 10px;
            height: 10px;
          }
        }
      }
    }
  }

  /* Modal specific Vuejs animation on 'leave' w/ @include inflate-transition() */
  .modal-leave-to {
    opacity: 0;
  }
  .modal-leave-active .modal-container {
    transform: scale(0.7);
  }
</style>
