<template>
  <modal-box
    v-if="add_modal"
    v-on:close="close"
    v-bind:width_class="'md:w-1/4'">
    <template v-slot:modal-header>
      <h1 class="text-xl font-bold text-primary-900">Ajouter un contact</h1>
    </template>

    <template v-slot:modal-body>
      <form
        ref="userForm"
        class="flex w-full flex-wrap space-y-4 px-2 py-2"
        v-on:submit.prevent="submit">
        <input-box
          class_name="no-box"
          label="Nom"
          placeholder="Nom"
          name="nom"
          v-bind:maxlength="100"
          v-bind:value="userForm.nom"
          v-on:input="setUserForm($event, 'nom')" />
        <input-box
          class_name="no-box"
          label="Prénom"
          placeholder="Prénom"
          name="prenom"
          v-bind:maxlength="100"
          v-bind:value="userForm.prenom"
          v-on:input="setUserForm($event, 'prenom')" />
        <input-box
          class_name="no-box"
          label="Email"
          placeholder="Email"
          name="email"
          v-bind:required="true"
          v-bind:class="{ 'input-error': v$.userForm.email.$error }"
          v-bind:maxlength="100"
          v-bind:value="userForm.email"
          v-on:input="setUserForm($event, 'email')" />
      </form>
    </template>
    <template v-slot:modal-footer>
      <div class="inline-flex w-full justify-center space-x-4">
        <button
          class="primary__button"
          v-on:click="submit">
          Ajouter
        </button>
        <button
          class="secondary__button"
          v-on:click="close">
          Annuler
        </button>
      </div>
    </template>
  </modal-box>
</template>
<script>
  import inputBox from '@/components/utils/input-box.vue';
  import modalBox from '@/components/utils/modal-box.vue';
  import { useVuelidate } from '@vuelidate/core';
  import { email, required } from '@vuelidate/validators';

  export default {
    components: {
      modalBox,
      inputBox
    },
    props: ['add_modal', 'onclose'],
    emits: ['create-user'],
    setup() {
      return { v$: useVuelidate() };
    },
    data: () => ({
      userForm: {
        nom: null,
        prenom: null,
        email: null
      }
    }),
    validations() {
      return {
        userForm: {
          email: {
            email,
            required
          }
        }
      };
    },
    methods: {
      close() {
        this.onclose();
        this.userForm = {};
        this.v$.$reset();
      },
      setUserForm(value, name) {
        this.userForm[name] = value;
      },
      async submit(e) {
        e.preventDefault();
        this.loading = true;
        this.v$.$touch();
        if (!this.v$.$invalid) {
          this.$emit('create-user', this.userForm);
          this.v$.$reset();
          this.close();
        }
        this.loading = false;
      }
    }
  };
</script>
<style lang="scss" scoped>
  .primary__button {
    @apply w-64 cursor-pointer rounded bg-primary-900 px-5 py-3 text-center font-bold text-white shadow hover:bg-primary-800;
  }
  .secondary__button {
    @apply w-64 cursor-pointer rounded bg-core-300 px-5 py-3 text-center font-bold text-core-700 shadow hover:bg-core-200;
  }
</style>
