<template>
  <sub-component-wrapper
    v-if="hasNavettes"
    default_component="sessions"
    v-bind:props="props"
    v-bind:tabs="tabs"
    v-bind:sub_components_list="sub_components_list" />
  <div
    v-else
    class="flex w-full justify-center pt-5 text-lg font-bold">
    Vous n'êtes affecté(e) à aucune navette.
  </div>
</template>
<script>
  import navigation_utils from '@/mixin/navigation_utils';
  import subComponentWrapper from '@/views/_espace/sub-component-wrapper.vue';
  import candidatures from '@/views/_espace/_formateur/_candidatures/candidatures.vue';
  import sessions from '@/views/_espace/_formateur/_candidatures/sessions.vue';
  import { createNamespacedHelpers } from 'vuex';
  const { mapState: mapStateFormateur } = createNamespacedHelpers('formateur');

  export default {
    components: { subComponentWrapper },
    mixins: [navigation_utils],
    props: ['props'],
    computed: {
      ...mapStateFormateur(['hasNavettes']),
      sub_components_list() {
        return {
          sessions,
          candidatures
        };
      },
      tabs() {
        const sub_components = this.fetchNavigationSubComponents();

        return { ...sub_components };
      }
    }
  };
</script>
<style lang="scss" scoped></style>
