<template>
  <div
    class="refonte-side-menu-catalogue-item"
    v-bind:class="props.class"
    v-on:click="props.on_click">
    <div class="side-menu-catalogue-item-img">
      <img
        v-bind:src="props.src"
        alt="Logo catalogue" />
    </div>
    <div class="side-menu-catalogue-item-content">
      <span class="side-menu-catalogue-item-content-text">
        {{ props.name }}
      </span>
      <div class="side-menu-catalogue-item-content-icon">
        <IconOpenNewTab />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import IconOpenNewTab from '@/assets/img/refonte/icons/action/icon-open-new-tab.svg?component';

  const props = withDefaults(
    defineProps<{
      on_click?: Function;
      src: string;
      name: string;
      class?: string;
    }>(),
    {
      class: ''
    }
  );
</script>

<style lang="scss" scoped>
  .refonte-side-menu-catalogue-item {
    @apply flex cursor-pointer items-center self-stretch bg-refonte-white text-refonte_blue-600;
    gap: 12px;
    border-radius: 8px;
    padding: 8px;

    .side-menu-catalogue-item-img {
      @apply flex flex-col items-center justify-center;
      min-width: 64px;
      max-width: 64px;
      height: 40px;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .side-menu-catalogue-item-content {
      @apply flex flex-grow items-center justify-between;

      .side-menu-catalogue-item-content-text {
        color: #000;
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
      }
      .side-menu-catalogue-item-content-icon {
        @apply flex shrink-0 items-center justify-center;
        width: 16px;
        height: 16px;

        :deep(svg) {
          @apply text-refonte_blue-600;
          width: 16px;
          height: 16px;
        }
      }
    }

    &:hover {
      @apply bg-refonte-pale-violet-3;
    }
  }
</style>
