import { typeMapping } from '@/types/gestion/inscriptions-types';

export const buildProduitTypeShortcut = (
    produitTypeId: number,
    lienElearning?: boolean
) => {
    const typeShortcut = [typeMapping[produitTypeId]];
    if (lienElearning) {
        typeShortcut.push('e-learning');
    }
    return typeShortcut;
};

export const buildProduitTypeLabel = (
    produitTypeLabel: string,
    lienElearning?: boolean
) => {
    const typeLabel = [produitTypeLabel];
    if (lienElearning) {
        typeLabel.push('e-learning');
    }
    return typeLabel;
};

export const buildDocumentStatut = (
    disponible?: boolean,
    signee?: boolean,
    validee?: boolean
) => {
    if (disponible && !signee && !validee) {
        return 'A signer';
    } else if (signee) {
        return 'Signée';
    } else if (validee) {
        return 'Validée';
    }
};

export const buildDate = (dateDebut: string, dateFin: string): string => {
    if (dateDebut === dateFin) {
        return dateDebut;
    } else {
        return `${dateDebut} - ${dateFin}`;
    }
};
