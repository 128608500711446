<template>
  <section class="section__primary">
    <h1 class="section__title">Parcours professionnel</h1>
    <div class="section__body">
      <div class="mb-6 flex w-full">
        <ag-grid-wrapper
          editable="true"
          no_filters="true"
          title="Parcours - Résumé"
          description="Indiquez votre parcours professionnel"
          v-bind:column_defs="[
            {
              headerName: 'De',
              field: 'debut',
              type: 'annees62',
              cellRenderer: 'textCellRendererEditable',
              width: 100
            },
            {
              headerName: 'A',
              field: 'fin',
              type: 'annees62',
              cellRenderer: 'textCellRendererEditable',
              width: 100
            },
            {
              headerName: 'Employeur',
              field: 'employeur',
              type: 'textWithMax',
              cellEditorParams: { maxLength: 150 },
              cellRenderer: 'textCellRendererEditable',
              flex: 1,
              minWidth: 200
            },
            {
              headerName: 'Fonction',
              field: 'fonction',
              type: 'textWithMax',
              cellEditorParams: { maxLength: 150 },
              cellRenderer: 'textCellRendererEditable',
              flex: 1,
              minWidth: 200
            }
          ]"
          v-bind:initial_data="cv_form.parcoursFormation"
          v-on:cell-value-changed="
            set_attribute($event, 'parcoursFormation')
          " />
      </div>

      <div class="mb-6 flex w-full">
        <ag-grid-wrapper
          editable="true"
          no_filters="true"
          title="Participation à la vie des instances professionnelles"
          description="Indiquez si vous avez participé à la vie des instances
            professionnelles (fonctions électives CSOEC/CRO/CNCC/CRCC, membres de commissions/comités/groupes de travail…)"
          v-bind:column_defs="[
            {
              headerName: 'De',
              field: 'annee',
              type: 'annees62',
              cellRenderer: 'textCellRendererEditable',
              width: 100
            },
            {
              headerName: 'A',
              field: 'anneeFin',
              type: 'annees62',
              cellRenderer: 'textCellRendererEditable',
              width: 100
            },
            {
              headerName: 'Nom de l\'instance professionnelle',
              field: 'instance',
              type: 'textWithMax',
              cellEditorParams: { maxLength: 150 },
              cellRenderer: 'textCellRendererEditable',
              flex: 1,
              minWidth: 300
            },
            {
              headerName: 'Fonction',
              field: 'fonction',
              type: 'textWithMax',
              cellEditorParams: { maxLength: 150 },
              cellRenderer: 'textCellRendererEditable',
              width: 200
            }
          ]"
          v-bind:initial_data="cv_form.instances"
          v-on:cell-value-changed="set_attribute($event, 'instances')" />
      </div>

      <div class="mb-6 flex w-full">
        <ag-grid-wrapper
          editable="true"
          no_filters="true"
          title="Compétences"
          description="Indiquez vos compétences mises en œuvre dans votre activité professionnelle actuelle"
          v-bind:column_defs="[
            {
              headerName:
                'Compétences principales mises en oeuvre dans votre activité professionnelle actuelle',
              flex: 1,
              field: 'competence',
              type: 'textWithMax',
              cellEditorParams: { maxLength: 150 },
              cellRenderer: 'textCellRendererEditable',
              minWidth: 300
            }
          ]"
          v-bind:initial_data="cv_form.competences"
          v-on:cell-value-changed="set_attribute($event, 'competences')" />
      </div>

      <div class="my-4 flex w-full flex-wrap">
        <div class="question mb-4">
          Êtes-vous à jour dans votre obligation de formation de 120h ?
        </div>
        <div class="description">
          En qualité de commissaire aux comptes ou expert-comptable, vous devez
          répondre à une obligation de formation de 120 heures, afin de
          maintenir, tout au long de votre carrière, le degré de compétence et
          de qualité élevé qu'exigent vos missions.
        </div>
        <div class="flex h-16 px-8">
          <yes-no-radio-buttons
            input_name="obligationFormation"
            v-bind:input_value="cv_form.obligationFormation"
            v-on:change="set_attribute($event, 'obligationFormation')" />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  import agGridWrapper from '@/components/utils/ag-grid/ag-grid-wrapper';
  import yesNoRadioButtons from '@/components/utils/yes-no-radio-buttons';

  export default {
    components: {
      agGridWrapper,
      yesNoRadioButtons
    },
    props: ['cv_form', 'set_attribute']
  };
</script>
<style lang="scss" scoped></style>
