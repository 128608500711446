<template>
  <modal-box
    v-if="show_modal"
    v-bind:width_class="'w-full max-w-screen-lg'"
    v-on:close="onclose">
    <template v-slot:modal-header>
      <h1 class="text-xl font-bold text-core-900">
        Références internes affichées sur la facture
      </h1>
    </template>

    <template v-slot:modal-body>
      <div class="flex justify-between py-2">
        <span class="mb-2 font-bold text-primary-800">
          Référence commande globale&nbsp;:
        </span>
        <input
          type="text"
          v-model="refInterneGlobalInput"
          v-bind:class="`mx-1.5 mb-2 w-2/3 rounded bg-accent-100 px-2 pb-0.5 pt-1 text-sm font-bold shadow ${!references_non_modifiable ? 'border' : ''}`"
          v-bind:disabled="references_non_modifiable"
          v-bind:maxlength="50" />
      </div>
      <div v-if="reference_internes_participant.length">
        <span class="mb-2 font-bold text-primary-800">
          Référence(s) participant&nbsp;:
        </span>
        <ul>
          <template
            v-for="referenceParticipant in reference_internes_participant"
            v-bind:key="`reference-participant-${referenceParticipant.referenceInterne}`">
            <li v-bind:class="'flex justify-between'">
              <span class="ml-2 font-bold">
                {{ referenceParticipant.nomPrenom }} -
                {{ referenceParticipant.titreFormation }}&nbsp;:
              </span>
              <input
                type="text"
                v-if="referenceParticipant.sessionParticipantId"
                v-model="
                  refInternesSessionParticipantsInput[
                    referenceParticipant.sessionParticipantId
                  ]
                "
                v-bind:class="`mx-1.5 mb-2 w-1/2 justify-between rounded bg-accent-100 px-2 pb-0.5 pt-1 text-sm font-bold shadow ${!references_non_modifiable ? 'border' : ''}`"
                v-bind:disabled="references_non_modifiable"
                v-bind:maxlength="50" />
              <input
                type="text"
                v-if="referenceParticipant.cursusInscriptionId"
                v-model="
                  refInternesCursusInscriptionsInput[
                    referenceParticipant.cursusInscriptionId
                  ]
                "
                v-bind:class="`mx-1.5 mb-2 w-1/2 justify-between rounded bg-accent-100 px-2 pb-0.5 pt-1 text-sm font-bold shadow ${!references_non_modifiable ? 'border' : ''}`"
                v-bind:disabled="references_non_modifiable"
                v-bind:maxlength="50" />
              <input
                type="text"
                v-else-if="referenceParticipant.elearningParticipantId"
                v-model="
                  refInternesElearningParticipantsInput[
                    referenceParticipant.elearningParticipantId
                  ]
                "
                v-bind:class="`mx-1.5 mb-2 w-1/2 justify-between rounded bg-accent-100 px-2 pb-0.5 pt-1 text-sm font-bold shadow ${!references_non_modifiable ? 'border' : ''}`"
                v-bind:disabled="references_non_modifiable"
                v-bind:maxlength="50" />
            </li>
          </template>
        </ul>
      </div>
    </template>
    <template v-slot:modal-footer>
      <div class="flex w-full flex-row">
        <div
          v-if="!references_non_modifiable"
          class="w-full cursor-pointer rounded bg-jinius-blue px-5 py-3 text-center font-bold text-white shadow"
          v-on:click="submit()">
          Valider
        </div>
        <div
          class="w-full cursor-pointer rounded bg-core-200 px-5 py-3 text-center font-bold text-core-600 shadow hover:bg-core-100"
          v-on:click="close()">
          Fermer
        </div>
      </div>
    </template>
  </modal-box>
</template>
<script>
  import modalBox from '@/components/utils/modal-box.vue';

  export default {
    components: { modalBox },
    props: [
      'show_modal',
      'onclose',
      'onsubmit',
      'reference_interne_global',
      'reference_internes_participant',
      'references_non_modifiable'
    ],
    data: () => ({
      refInterneGlobalInput: null,
      refInternesSessionParticipantsInput: null,
      refInternesCursusInscriptionsInput: null,
      refInternesElearningParticipantsInput: null
    }),
    created() {
      this.refInterneGlobalInput = this.reference_interne_global;
      this.refInternesSessionParticipantsInput =
        this.reference_internes_participant
          ?.filter(
            (referenceParticipant) => referenceParticipant.sessionParticipantId
          )
          .reduce((map, referenceParticipant) => {
            map[referenceParticipant.sessionParticipantId] =
              referenceParticipant.referenceInterne;

            return map;
          }, {});
      this.refInternesCursusInscriptionsInput =
        this.reference_internes_participant
          ?.filter(
            (referenceParticipant) => referenceParticipant.cursusInscriptionId
          )
          .reduce((map, referenceParticipant) => {
            map[referenceParticipant.cursusInscriptionId] =
              referenceParticipant.referenceInterne;

            return map;
          }, {});
      this.refInternesElearningParticipantsInput =
        this.reference_internes_participant
          ?.filter(
            (referenceParticipant) =>
              referenceParticipant.elearningParticipantId
          )
          .reduce((map, referenceParticipant) => {
            map[referenceParticipant.elearningParticipantId] =
              referenceParticipant.referenceInterne;

            return map;
          }, {});
    },
    methods: {
      close() {
        this.onclose();
      },
      submit() {
        const updatedRefInterneGlobal = this.refInterneGlobalInput;
        const updatedRefInternesSessionParticipants =
          this.refInternesSessionParticipantsInput;
        const updatedRefInternesCursusInscriptions =
          this.refInternesCursusInscriptionsInput;
        const updatedRefInternesElearningParticipants =
          this.refInternesElearningParticipantsInput;

        this.onsubmit({
          referenceInterneClient: updatedRefInterneGlobal,
          referenceSessionParticipants: updatedRefInternesSessionParticipants,
          referenceCursusInscriptions: updatedRefInternesCursusInscriptions,
          referenceElearningParticipants:
            updatedRefInternesElearningParticipants
        });
      }
    }
  };
</script>
<style lang="scss" scoped></style>
