<template>
  <div
    class="refonte-card"
    v-bind:class="props.class">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
  const props = withDefaults(
    defineProps<{
      class?: string;
    }>(),
    {
      class: ''
    }
  );
</script>

<style lang="scss" scoped>
  .refonte-card {
    @apply flex flex-col bg-refonte-white;
    border-radius: 8px;
    padding: 8px;
    gap: 16px;
  }
</style>
