<template>
  <div
    class="error-404"
    v-bind:class="`${width}`">
    <div
      class="error-404__content"
      v-bind:class="{ 'mt-16': no_return_home }">
      <div class="content__title">Erreur 404</div>
      <div class="content__text">
        La page que vous essayez d'atteindre n'existe pas...
      </div>

      <img
        class="content__img"
        src="@/assets/img/not-found-404.png"
        alt="Image Erreur 404" />

      <router-link
        v-if="!no_return_home"
        class="content__btn-back"
        v-bind:to="{ path: '/' }">
        <font-awesome-icon
          icon="home"
          class="btn-back__icon" />
        <span class="btn-back__text">Retour à la page d'accueil</span>
      </router-link>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      no_return_home: {
        type: Boolean,
        default: false
      },
      width: {
        type: String,
        default: 'max-w-xl'
      }
    }
  };
</script>

<style lang="scss" scoped>
  .error-404 {
    @apply mx-auto flex h-full flex-wrap items-center;

    .error-404__content {
      @apply flex flex-wrap justify-center;

      .content__title {
        @apply mb-4 text-center text-8xl font-bold text-primary-900;
      }

      .content__text {
        @apply text-center text-primary-900;
      }

      .content__img {
        @apply w-full max-w-md;
        transform: scaleX(-1);
      }

      .content__btn-back {
        @apply flex items-center justify-center rounded border border-accent-300 bg-white px-8 py-2 text-core-700 transition hover:border-accent-400 hover:text-core-900;

        .btn-back__icon {
          @apply mr-4;
        }

        .btn-back__text {
          @apply inline-flex align-middle;
        }
      }
    }
  }
</style>
