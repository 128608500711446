<template>
  <div
    class="flex h-full w-full items-center justify-center text-sm"
    v-if="loading">
    <spinner />
  </div>
  <div
    class="flex w-full flex-col items-center justify-center"
    v-else>
    <div
      class="mb-0.5 flex cursor-pointer"
      v-on:click="ouvrir">
      <span class="pl-1 text-sm underline hover:text-jinius-dark-green"
        >Ouvrir</span
      >
    </div>
    <div class="flex max-w-full flex-wrap">
      <div
        v-if="params.data?.nouvelleVersion && !params.data?.dateOuverture"
        class="status-label bg-jinius-dark-green">
        Nouvelle version
      </div>
      <div
        v-if="params.data?.dateOuverture"
        class="status-label bg-jinius-blue">
        Ouvert le {{ formatDate(params.data?.dateOuverture) }}
      </div>
    </div>
  </div>
</template>
<script>
  import spinner from '@/components/utils/spinner.vue';
  import { API } from '@/http-common';
  import http_functions from '@/mixin/http_functions';

  export default {
    components: { spinner },
    mixins: [http_functions],
    data: () => ({ loading: false }),
    methods: {
      async ouvrir() {
        this.loading = true;
        const {
          data,
          session,
          espace,
          openAppreciationModal,
          ignoreDisponibilite
        } = this.params;

        if (data.lien) {
          await this.openVideo(
            data.lien,
            data.id,
            session,
            espace,
            openAppreciationModal,
            ignoreDisponibilite
          );
        } else if (data.url && data.nomUrl) {
          await this.downloadPdf(
            data.url,
            data.nomUrl,
            session,
            espace,
            openAppreciationModal,
            ignoreDisponibilite
          );
        }
        this.loading = false;
      },
      async openLink(url) {
        const urlHttp = this.formatUrlProtocol(url);

        window.open(urlHttp, '_blank');
      },
      async openFile(url, params, nom) {
        const downloadUrl = `${import.meta.env.VITE_API_SAFIR}${url}${params}`;
        const fileLink = document.createElement('a');

        fileLink.href = downloadUrl;
        fileLink.setAttribute('download', nom);
        document.body.appendChild(fileLink);
        const simulateClick = function (elem) {
          // bubbles: false to avoid closing modal on download
          const evt = new MouseEvent('click', { bubbles: false });

          elem.dispatchEvent(evt);
        };

        simulateClick(fileLink);
        document.body.removeChild(fileLink);
      },
      async downloadPdf(
        url,
        nom,
        session,
        espace,
        openAppreciationModal,
        ignoreDisponibilite
      ) {
        const {
          sessionId,
          produitId,
          participantId,
          type,
          produit,
          reference,
          commandeId
        } = session;

        let params = `?reference=${reference}${sessionId ? `&sessionId=${sessionId}` : ''}${commandeId ? `&commandeId=${commandeId}` : ''}`;

        if (participantId) {
          const isElearning = type && type.id === 2 && !produit;
          const participants = isElearning
            ? 'elearningParticipantId'
            : 'sessionParticipantId';

          params += `&parcoursProduitId=${produitId}&${participants}=${participantId}`;
        }
        if (espace === 'participant' && !ignoreDisponibilite) {
          // Check if support is available
          const response = await API.get(
            `${url}/disponibilite${params}`,
            this.$header_skip_redirection
          );

          if (
            response &&
            response.data &&
            response.data.message === 'Appreciation requise'
          ) {
            openAppreciationModal();
          } else {
            await this.openFile(url, params, nom);
          }
        } else {
          await this.openFile(url, params, nom);
        }
      },
      async openVideo(
        url,
        id,
        session,
        espace,
        openAppreciationModal,
        ignoreDisponibilite
      ) {
        const {
          sessionId,
          produitId,
          participantId,
          type,
          produit,
          reference,
          commandeId
        } = session;

        let params = `?reference=${reference}${sessionId ? `&sessionId=${sessionId}` : ''}${commandeId ? `&commandeId=${commandeId}` : ''}`;

        if (participantId) {
          const isElearning = type && type.id === 2 && !produit;
          const participants = isElearning
            ? 'elearningParticipantId'
            : 'sessionParticipantId';

          params += `&parcoursProduitId=${produitId}&${participants}=${participantId}`;
        }
        if (espace === 'participant' && !ignoreDisponibilite) {
          // Check if support is available
          const response = await API.get(
            `${espace}/${id}/support/file/disponibilite${params}`,
            this.$header_skip_redirection
          );

          if (
            response &&
            response.data &&
            response.data.message === 'Appreciation requise'
          ) {
            openAppreciationModal();
          } else {
            await this.openLink(url);
          }
        } else {
          await this.openLink(url);
        }
      },
      formatDate(date) {
        if (date) {
          return date.split(' ')[0];
        }

        return '';
      }
    }
  };
</script>
<style lang="scss" scoped>
  .btn {
    @apply cursor-pointer items-center justify-center rounded bg-success-500 px-4 py-2 font-bold leading-none text-white hover:bg-success-400;
  }
  .status-label {
    @apply mx-2 w-auto max-w-full flex-1 truncate rounded-sm px-2 text-xs font-bold text-core-100 shadow;
  }
</style>
