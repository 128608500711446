<template>
  <div
    class="refonte-details-header-item"
    v-bind:class="props.class">
    <div
      v-if="props.icon_left"
      class="details-header-item-icon-left">
      <component v-bind:is="props.icon_left" />
    </div>
    <span>{{ props.text }}</span>
    <div
      v-if="props.additional_text"
      class="details-header-item-separator">
      <IconSeparatorVertical />
    </div>
    <span v-if="props.additional_text">{{ props.additional_text }}</span>
    <div
      v-if="!!slots['default']"
      class="details-header-item-icons">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
  import IconSeparatorVertical from '@/assets/img/refonte/icons/display/icon-separator-vertical.svg?component';
  import { useSlots } from 'vue';

  const props = withDefaults(
    defineProps<{
      text: string;
      additional_text?: string;
      icon_left?: Element;
      class?: string;
    }>(),
    {
      class: ''
    }
  );

  const slots = useSlots();
</script>

<style lang="scss" scoped>
  .refonte-details-header-item {
    @apply inline-flex flex-wrap items-center justify-start;
    gap: 6px;
    padding: 10px;

    .details-header-item-icon-left {
      @apply flex shrink-0 items-center justify-center;
      width: 16px;
      height: 16px;

      :deep(svg) {
        @apply text-refonte-black;
        width: 16px;
        height: 16px;
      }
    }
    span {
      @apply text-refonte-black;
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
    }
    .details-header-item-separator {
      @apply flex items-center justify-center;
      height: 16px;

      :deep(svg) {
        @apply text-refonte-black-30;
      }
    }
    .details-header-item-icons {
      @apply inline-flex items-center justify-center;
      gap: 6px;
      height: 16px;

      :deep(svg) {
        width: 16px;
        height: 16px;
      }
    }
  }
</style>
