<template>
  <modal-box
    v-if="modal_event"
    v-bind:width_class="'w-full max-w-screen-lg'"
    v-on:close="onclose">
    <template v-slot:modal-header>
      <h1 class="text-xl font-bold text-core-900">Rendez-vous</h1>
    </template>

    <template v-slot:modal-body>
      <div class="relative my-2 flex w-full flex-wrap p-8 text-core-900">
        <div
          class="card__container--anchor"
          v-if="agenda_type"
          v-bind:class="type_anchor_color(agenda_type.id)"></div>
        <div
          class="content__type"
          v-if="agenda_type"
          v-bind:class="type_bg_color(agenda_type.id)">
          <span>{{ agenda_type.label }}</span>
        </div>
        <div class="mb-8 w-full text-lg">
          <div
            class="mb-8 flex w-full items-center justify-center"
            v-if="agenda_event.organisateur">
            <div class="flex w-full">
              <span class="font-bold uppercase">Organisateur&nbsp;:&nbsp;</span>
              {{ agenda_event.organisateur }}
            </div>
          </div>
          <div
            class="mb-8 flex w-full items-center justify-center"
            v-if="agenda_event.titre">
            <font-awesome-icon
              icon="tags"
              fixed-width />
            <div class="ml-3 flex w-full">
              <span class="font-bold">Titre&nbsp;:&nbsp;</span>
              {{ agenda_event.titre }}
            </div>
          </div>
          <div
            class="mb-8 flex w-full items-center justify-center"
            v-if="agenda_event.description">
            <font-awesome-icon
              icon="comment-alt"
              fixed-width />
            <div class="ml-3 flex w-full">
              <span class="font-bold">Description&nbsp;:&nbsp;</span>
              <span v-html="agenda_event.description"></span>
            </div>
          </div>
          <div
            class="mb-8 flex w-full items-center justify-center space-x-4"
            v-if="agenda_event.journeeEntiere && agenda_event.dateDebut">
            <font-awesome-icon
              icon="calendar-alt"
              fixed-width />
            <div class="w-full">
              <span class="font-bold">Date&nbsp;:&nbsp;</span>
              {{ agenda_event.dateDebut }} (Journée entière)
            </div>
          </div>
          <template v-else>
            <div class="mb-8 flex w-full items-center justify-center space-x-4">
              <font-awesome-icon
                icon="calendar-alt"
                fixed-width />
              <div class="w-full">
                <span class="font-bold">Début&nbsp;:&nbsp;</span>
                {{ agenda_event.dateDebut }}
              </div>
              <font-awesome-icon
                icon="calendar-alt"
                fixed-width />
              <div class="w-full">
                <span class="font-bold">Fin&nbsp;:&nbsp;</span>
                {{ agenda_event.dateFin }}
              </div>
            </div>
          </template>
          <div
            class="mb-8 flex w-full items-center justify-center"
            v-if="agenda_event.lieu">
            <font-awesome-icon
              icon="map-marker-alt"
              fixed-width />
            <div class="ml-3 flex w-full">
              <span class="font-bold">Lieu&nbsp;:&nbsp;</span>
              {{ agenda_event.lieu }}
            </div>
          </div>
          <div
            class="mb-8 flex w-full items-center justify-center"
            v-if="agenda_event.adresse">
            <font-awesome-icon
              icon="building"
              fixed-width />
            <div class="ml-3 flex w-full">
              <span class="font-bold">Adresse&nbsp;:&nbsp;</span>
              {{ agenda_event.adresse }}
            </div>
          </div>

          <div
            class="mb-8 flex w-full flex-wrap items-center justify-center"
            v-if="agenda_event.site">
            <div class="mb-2 flex w-full items-center justify-center">
              <font-awesome-icon
                icon="building"
                fixed-width />
              <div class="ml-3 flex w-full">
                <span class="font-bold">Site</span>
              </div>
            </div>
            <div
              class="my-3 flex w-full items-center rounded-lg bg-accent-100 p-8 pr-12 shadow">
              <div class="mr-4">
                <font-awesome-icon
                  class="text-core-500"
                  icon="map-marker-alt"
                  size="2x" />
              </div>
              <div class="flex-grow">
                <div
                  v-if="agenda_event.site.denomination"
                  class="font-bold">
                  {{ agenda_event.site.denomination }}
                </div>
                <div
                  v-if="agenda_event.site.adresse"
                  class="text-sm uppercase">
                  {{ agenda_event.site.adresse }}
                </div>
              </div>
              <div
                v-if="agenda_event.site.coordinates"
                class="ml-4">
                <font-awesome-icon
                  v-on:click="openMapModal"
                  class="cursor-pointer text-jinius-blue hover:text-jinius-dark-green"
                  icon="map-marked-alt"
                  size="2x" />
              </div>
            </div>
          </div>
        </div>
        <details-map-modal
          v-if="agenda_event.site && agenda_event.site.coordinates"
          v-on:onclose="closeMapModal"
          v-bind:modal_show="map_modal"
          v-bind:coordinates="agenda_event.site.coordinates.split(',')"
          v-bind:adresse="agenda_event.adresse" />
        <connexion-modal
          v-bind:modal_show="connexion_modal"
          v-on:onclose="closeConnexionModal"
          v-bind:session_url="agenda_event.sessionUrl" />
      </div>
    </template>
    <template v-slot:modal-footer>
      <div class="inline-flex w-full justify-center space-x-4">
        <div
          class="w-full cursor-pointer rounded bg-core-900 px-5 py-3 text-center font-bold text-white shadow hover:bg-jinius-dark-green"
          v-on:click="edit"
          v-if="current_event && current_event.editable">
          <font-awesome-icon icon="edit" />
          <span class="ml-2 align-middle">Modifier</span>
        </div>
        <div
          class="w-full cursor-pointer rounded bg-orange-500 px-5 py-3 text-center font-bold text-white shadow hover:bg-orange-600"
          v-on:click="() => goToUrl(agenda_event.url)"
          v-if="agenda_event.url">
          <font-awesome-icon icon="external-link-alt" />
          <span class="ml-2 align-middle">Accéder au lien</span>
        </div>
        <div
          class="w-full cursor-pointer rounded bg-orange-500 px-5 py-3 text-center font-bold text-white shadow hover:bg-orange-600"
          v-on:click="() => goToUrl(agenda_event.sessionUrl[0].url)"
          v-if="
            agenda_event.sessionUrl && agenda_event.sessionUrl.length === 1
          ">
          <font-awesome-icon icon="external-link-alt" />
          <span class="ml-2 align-middle">Accéder à la session</span>
        </div>
        <div
          class="w-full cursor-pointer rounded bg-orange-500 px-5 py-3 text-center font-bold text-white shadow hover:bg-orange-600"
          v-on:click="openConnexionModal"
          v-if="agenda_event.sessionUrl && agenda_event.sessionUrl.length > 1">
          <font-awesome-icon icon="external-link-alt" />
          <span class="ml-2 align-middle">Informations de connexion</span>
        </div>
        <button
          class="w-full cursor-pointer rounded bg-jinius-red px-5 py-3 text-center font-bold text-white shadow hover:bg-danger-300"
          type="button"
          v-if="current_event && current_event.editable"
          v-on:click="deleteIndispo">
          <font-awesome-icon icon="trash-alt" />
          <span class="ml-2 align-middle">Supprimer</span>
        </button>
        <div
          class="w-full cursor-pointer rounded bg-core-300 px-5 py-3 text-center font-bold text-core-700 shadow hover:bg-core-200"
          v-on:click="closeModal">
          Fermer
        </div>
      </div>
    </template>
  </modal-box>
</template>

<script>
  import { map_type_colors } from '@/assets/data/data_maps';
  import modalBox from '@/components/utils/modal-box.vue';
  import { API } from '@/http-common';
  import http_functions from '@/mixin/http_functions';
  import connexionModal from '@/views/_espace/connexion-modal';
  import detailsMapModal from '@/views/_espace/details-map-modal';
  export default {
    components: {
      modalBox,
      detailsMapModal,
      connexionModal
    },
    mixins: [http_functions],
    props: {
      modal_event: Boolean,
      onclose: Function,
      agenda_list: Array,
      current_event: Object
    },
    emits: ['edit-indisponibilite', 'event-delete'],
    data() {
      return {
        map_modal: false,
        connexion_modal: false
      };
    },
    computed: {
      agenda_event() {
        if (
          this.agenda_list &&
          this.agenda_list.length &&
          this.current_event &&
          this.current_event.eventId
        ) {
          return this.agenda_list.find(
            (a) => a.id === this.current_event.eventId
          );
        }

        return {};
      },
      agenda_type() {
        if (
          this.agenda_list &&
          this.agenda_list.length &&
          this.current_event &&
          this.current_event.eventId
        ) {
          const agenda = this.agenda_list.find(
            (a) => a.id === this.current_event.eventId
          );

          if (agenda.classeVirtuelle) {
            return {
              label: 'Classe virtuelle',
              id: 5
            };
          }

          return agenda.type;
        }

        return {};
      }
    },
    methods: {
      type_bg_color(id) {
        return `bg-${map_type_colors[id]}`;
      },
      type_anchor_color(id) {
        return `border-${map_type_colors[id]}`;
      },
      closeModal() {
        this.onclose();
      },
      openConnexionModal(e) {
        e.preventDefault();
        this.connexion_modal = true;
      },
      closeConnexionModal() {
        this.connexion_modal = false;
      },
      openMapModal(e) {
        e.preventDefault();
        this.map_modal = true;
      },
      closeMapModal() {
        this.map_modal = false;
      },
      goToUrl(url) {
        if (url) {
          window.open(this.formatUrlProtocol(url), '_blank');
        }
      },
      edit() {
        this.$emit('edit-indisponibilite', this.current_event.eventId);
      },
      async deleteIndispo() {
        const id = this.current_event.eventId;

        if (id) {
          await API.delete(
            `formateur/agenda/indisponibilite/${id}`,
            this.$header_skip_redirection
          );
          this.$emit('event-delete');
          this.onclose();
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .card__container--anchor {
    @apply absolute left-0 top-0 z-10 h-10 w-12 border-l-8 border-t-8;
  }

  .content__type {
    @apply absolute right-0 top-0 mr-2 mt-2 rounded px-3 py-0.5 text-xs font-bold lowercase text-core-100 shadow;
  }
</style>
