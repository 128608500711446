<template>
  <modal-box
    v-if="modal_show"
    v-bind:width_class="'w-full max-w-screen-xl'"
    v-on:close="close"
    overflow_body
    v-bind:footer="false">
    <template v-slot:modal-header>
      <h1 class="text-xl">Convocation</h1>
    </template>

    <template v-slot:modal-body>
      <template v-if="loading">
        <spinner
          sizes="w-20 h-20"
          thickness="border-8" />
      </template>
      <div
        class="flex h-full w-full flex-wrap"
        v-else>
        <div
          class="flex w-full flex-wrap space-y-2 px-4"
          v-if="convocations.length > 1">
          <span class="flex w-full">Liste des convocations</span>
          <ul class="list-inside list-disc">
            <li
              v-for="(convocation, index) in convocations"
              v-bind:class="`label ${index === currentConvocationIndex ? 'active' : ''}`"
              v-bind:key="`convocation-${index}`"
              v-on:click="currentConvocationIndex = index">
              {{ `Convocation ${index + 1}` }}
            </li>
          </ul>
        </div>
        <hr
          class="my-6 w-full text-core-900 opacity-50"
          v-if="convocations.length > 1" />
        <div
          v-if="convocations.length"
          class="flex w-full flex-wrap space-y-2 px-4">
          <div
            class="flex w-full flex-wrap"
            id="convocation">
            <div class="flex w-full flex-wrap">
              <span class="font-bold">Objet : &nbsp;</span
              >{{ convocations[currentConvocationIndex].objet }}
            </div>
            <div class="flex w-full flex-wrap">
              <div class="flex w-full font-bold">Message :</div>
              <div
                v-html="convocations[currentConvocationIndex].texte"
                class="mx-10 my-4 w-full border-2 border-core-300 bg-core-100 p-10 shadow"></div>
            </div>
          </div>
          <div
            class="flex w-full flex-wrap"
            v-if="
              convocations[currentConvocationIndex].piecesJointe &&
              convocations[currentConvocationIndex].piecesJointe.length > 0
            ">
            <div class="flex w-full font-bold">
              <font-awesome-icon
                class="mr-1"
                icon="paperclip"
                size="lg" />
              Pièces jointes
            </div>
            <ul class="list-inside list-disc">
              <li
                v-for="(pieceJointe, index) in convocations[
                  currentConvocationIndex
                ].piecesJointe"
                class="label"
                v-bind:key="`pj-${index}`"
                v-on:click="
                  () => downloadPieceJointe(pieceJointe.url, pieceJointe.nom)
                ">
                {{ pieceJointe.nom }}
              </li>
            </ul>
          </div>
          <div class="flex w-full justify-end">
            <button
              class="mb-2 ml-0 rounded-md border border-core-500 bg-core-900 p-3 font-bold text-white hover:bg-jinius-dark-green"
              type="button"
              v-on:click="() => printConvocation()">
              <font-awesome-icon icon="print" />
              Imprimer la convocation
            </button>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:modal-footer> </template>
  </modal-box>
</template>
<script>
  import modalBox from '@/components/utils/modal-box.vue';
  import spinner from '@/components/utils/spinner.vue';
  import { API } from '@/http-common';

  export default {
    components: {
      modalBox,
      spinner
    },
    props: {
      modal_show: Boolean,
      ressource_base_url: String,
      onclose: Function
    },
    data: () => ({
      currentConvocationIndex: 0,
      loading: false,
      convocations: []
    }),
    created() {
      this.fetchConvocations();
    },
    methods: {
      async fetchConvocations() {
        this.loading = true;
        const downloadUrl = `${import.meta.env.VITE_API_SAFIR}${this.ressource_base_url}/convocation`;
        const response = await API.get(
          downloadUrl,
          this.$header_skip_redirection
        );

        this.convocations =
          response.data && response.data.convocations
            ? response.data.convocations
            : [];
        this.loading = false;
      },
      close() {
        this.onclose();
        this.currentConvocationIndex = 0;
      },
      async downloadPieceJointe(url, nom) {
        const response = await API.get(`${url}`, {
          responseType: 'blob',
          ...this.$header_skip_redirection
        });
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', nom);
        document.body.appendChild(fileLink);
        fileLink.click();
      },
      printConvocation() {
        this.$htmlToPaper('convocation');
      }
    }
  };
</script>
<style lang="scss" scoped>
  #convocation {
    :deep(a[href]) {
      @apply cursor-pointer text-jinius-dark-green hover:underline #{!important};
    }
  }

  .label {
    @apply cursor-pointer px-4 font-normal hover:font-bold hover:text-jinius-green;
    &.active {
      @apply font-bold text-jinius-green;
    }
  }
</style>
