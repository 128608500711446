<template>
  <sub-component-wrapper
    default_component="campagnes"
    v-bind:props="props"
    v-bind:tabs="tabs"
    v-bind:sub_components_list="sub_components_list" />
</template>
<script>
  import navigation_utils from '@/mixin/navigation_utils';
  import subComponentWrapper from '@/views/_espace/sub-component-wrapper.vue';
  import campagnes from '@/views/_espace/_formateur/_appreciations/campagnes.vue';
  import formations from '@/views/_espace/_formateur/_appreciations/formations.vue';
  import sessions from '@/views/_espace/_formateur/_appreciations/sessions.vue';

  export default {
    components: { subComponentWrapper },
    mixins: [navigation_utils],
    props: ['props'],
    computed: {
      sub_components_list() {
        return {
          campagnes,
          formations,
          sessions
        };
      },
      tabs() {
        const sub_components = this.fetchNavigationSubComponents();

        return { ...sub_components };
      }
    }
  };
</script>
