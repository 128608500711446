import axios from 'axios';

export default {
    methods: {
        isSiretFormat(value) {
            return (
                value &&
                value !== '' &&
                /^\d{14}$/.test(value.replace(/\s/g, ''))
            );
        },
        isSirenFormat(value) {
            return (
                value &&
                value !== '' &&
                /^\d{9}$/.test(value.replace(/\s/g, ''))
            );
        },
        async searchInsee(searchText, perPage, page) {
            const url = `https://aesa.cncc.fr/v1/etablissement?q=${searchText}&per_page=${perPage ? perPage : 100}${page ? `&page=${page}` : '&page=1'}`;
            const response = await axios.get(url);

            if (response && response.data) {
                // console.log('response FULLTEXT ', response.data);
                return response.data[0];
            }
        },
        async getBySiret(siret) {
            const url = `https://aesa.cncc.fr/v1/siret?q=${siret}&page=1`;
            const response = await axios.get(url);

            if (response && response.data) {
                // console.log('response SIRET', response.data);
                return response.data[0];
            }
        },
        async getBySiren(siren) {
            const url = ` https://aesa.cncc.fr/v1/siren?q=${siren}&page=1`;
            const response = await axios.get(url);

            if (response && response.data) {
                // console.log('response SIREN', response.data);
                return response.data[0];
            }
        },
        async searchAssociations(searchText, perPage, page) {
            const url = `https://entreprise.data.gouv.fr/api/rna/v1/full_text/
            ${searchText}?per_page=${perPage ? perPage : 100}${page ? `&page=${page}` : ''}`;
            const response = await axios.get(url);

            if (response && response.data) {
                return response.data;
            }
        },
        async searchAddress(searchText) {
            const url = `https://api-adresse.data.gouv.fr/search/?q=${searchText}`;
            const response = await axios.get(url);

            if (response && response.data) {
                return response.data;
            }
        }
    }
};
