<template>
  <div
    v-on:click="$emit('clicked')"
    class="hamburger-button">
    <font-awesome-icon
      v-bind:icon="icon"
      v-bind:size="size" />
  </div>
</template>

<script>
  export default {
    props: {
      icon: { type: [String, Array] },
      size: {
        type: String,
        default: 'lg'
      }
    },
    emits: ['clicked']
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/css/utils/mixins.scss';

  .hamburger-button {
    @apply z-50 inline-flex h-full w-12 cursor-pointer items-center justify-around hover:text-core-900 lg:w-20;
  }
</style>
