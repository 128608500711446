<template>
  <div>
    <div
      id="surveyElement"
      style="display: inline-block; width: 100%">
      <SurveyComponent v-bind:model="survey" />
    </div>
    <div id="surveyResult"></div>
  </div>
</template>

<script setup lang="ts">
  import { Model, StylesManager } from 'survey-core';
  import 'survey-core/modern.min.css';
  import { tailwindsColors } from '@/assets/data/tailwind-config-resolver';
  import { watch } from 'vue';

  StylesManager.applyTheme('modern');
  // Survey.locale = 'fr';
  const props = withDefaults(
    defineProps<{
      json: [String, Object];
      data: Object;
      hide_correction: {
        type: Boolean;
        default: false;
      };
    }>(),
    {}
  );

  const emit = defineEmits(['scroll_action', 'complete']);

  const survey = new Model(props.json);
  survey.showNavigationButtons = false;

  survey.onComplete.add((result) => {
    emit('complete', result);
  });
  if (props.data) {
    survey.data = props.data;
    survey.mode = 'display';

    if (!props.hide_correction) {
      survey.onAfterRenderQuestion.add((survey, options) => {
        const isCorrect = options.question.isAnswerCorrect();

        const header = options.htmlElement.querySelector('h5');

        const radio = options.htmlElement.querySelectorAll('input');

        const answer = options.question.correctAnswer;
        if (header) {
          if (isCorrect) {
            header.style.backgroundColor = tailwindsColors['jinius-green'];
          } else {
            header.style.backgroundColor = tailwindsColors['jinius-red'];
          }
        }

        if (radio) {
          radio.forEach((r) => {
            if (r.parentElement) {
              r.parentElement.style.fontWeight = 'bold';
              if (
                r.getAttribute('value') == answer ||
                (Array.isArray(answer) &&
                  answer
                    .map((a) => a.toString())
                    .includes(r.getAttribute('value')))
              ) {
                r.parentElement.style.color = tailwindsColors['jinius-green'];
              } else {
                r.parentElement.style.color = tailwindsColors['jinius-red'];
              }
            }
          });
        }
      });
    }
  }

  const nextPage = () => {
    survey.nextPage();
  };
  const prevPage = () => {
    survey.prevPage();
  };
  const completeLastPage = () => {
    survey.completeLastPage();
  };

  watch(
    () => survey.currentPageNo,
    async () => {
      emit('scroll_action');
    }
  );

  defineExpose({ survey, completeLastPage, prevPage, nextPage });
</script>
