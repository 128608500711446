<template>
  <div class="flex w-full flex-wrap space-y-6 sm:space-x-8">
    <h1
      class="mb-2 flex w-full items-center border-l-2 border-jinius-green pl-5 text-center text-2xl font-bold leading-none text-core-900">
      Adresse
    </h1>
    <div
      class="flex w-full max-w-full rounded bg-accent-100 px-4 pt-3 shadow"
      v-if="!disabledAll">
      <select-box
        v-bind:model="value"
        v-bind:is_loading="loading_address"
        v-bind:internal_search="false"
        v-on:search-change="onChange"
        v-on:select="onSelect"
        v-bind:categories="options"
        v-bind:placeholder="
          value ? customLabel(value) : 'Commencez à rechercher une adresse'
        "
        label="Rechercher une adresse"
        no_capitalize_label
        v-bind:show_no_results="false"
        v-bind:show_no_options="false"
        v-bind:show_labels="false"
        class_name="no-box"
        tracking="id"
        v-bind:custom_label="customLabel" />
    </div>
    <div
      v-for="inputItem in informationsInputs"
      v-bind:key="inputItem.name"
      class="flex w-full">
      <input-box
        class_name="no-box"
        v-bind:name="inputItem.name"
        v-bind:label="inputItem.placeholder"
        v-bind:placeholder="inputItem.placeholder"
        v-bind:maxlength="inputItem.maxLength"
        v-bind:value="client_form[inputItem.name]"
        v-bind:disabled="
          inputItem.name === 'identification'
            ? !(
                v$.clientForm[inputItem.name] &&
                'maxLength' in v$.clientForm[inputItem.name] &&
                v$.clientForm[inputItem.name].$dirty
              )
            : ['numeroVoie', 'codePostal', 'ville'].includes(inputItem.name) &&
                v$.clientForm[inputItem.name].$dirty
              ? false
              : disabledAll
        "
        v-on:input="set_attribute($event, [inputItem.name])"
        v-bind:format="inputItem.format"
        v-bind:required="
          v$.clientForm[inputItem.name] &&
          'required' in v$.clientForm[inputItem.name]
        "
        v-bind:class="{
          'input-error':
            v$.clientForm[inputItem.name] &&
            v$.clientForm[inputItem.name].$error
        }" />
    </div>
    <div class="flex w-full">
      <select-box
        v-bind:value="pays.find((item) => client_form.pays === item.id)"
        v-on:input="(value) => set_attribute(value.id, 'pays')"
        v-bind:categories="pays"
        label="Pays"
        placeholder="Pays"
        no_capitalize_label
        name="pays"
        tracking="id"
        class_name="no-box"
        v-bind:disabled="disabledAll" />
    </div>
  </div>
</template>
<script>
  import { pays } from '@/assets/data/data_form';
  import inputBox from '@/components/utils/input-box.vue';
  import selectBox from '@/components/utils/select-box.vue';
  import api_insee_helpers from '@/mixin/api_insee_helpers';
  import { createNamespacedHelpers } from 'vuex';

  const { mapState: mapStateClient } = createNamespacedHelpers('client');

  export default {
    components: {
      inputBox,
      selectBox
    },
    mixins: [api_insee_helpers],
    props: {
      set_attribute: Function,
      client_form: Object,
      client_type: String,
      disabled_list: {
        type: Array,
        default: () => []
      },
      v$: Object
    },
    data() {
      return {
        options: [],
        value: undefined,
        timeout: null,
        loading_address: false
      };
    },
    computed: {
      ...mapStateClient(['clientForm']),
      informationsInputs() {
        return [
          {
            name: 'identification',
            placeholder: 'Identification',
            maxLength: 100
          },
          {
            name: 'destService',
            placeholder: 'Dest./Service',
            maxLength: 100
          },
          {
            name: 'complement',
            placeholder: "Complément d'adresse",
            maxLength: 38
          },
          {
            name: 'numeroVoie',
            placeholder: 'N° et voie',
            maxLength: 38
          },
          {
            name: 'mentionSpeciale',
            placeholder: 'Mention spéciale',
            maxLength: 38
          },
          {
            name: 'ville',
            placeholder: 'Ville',
            maxLength: 100
          },
          {
            name: 'codePostal',
            placeholder: 'Code postal',
            format: this.clientForm.pays ? '' : 'numeric',
            maxLength: this.clientForm.pays ? 25 : 5
          }
        ];
      },
      disabledAll() {
        return this.disabled_list.includes(this.client_type);
      },
      pays: () => pays
    },
    methods: {
      async onChange(value) {
        this.options = [];
        this.loading_address = true;

        if (this.timeout) {
          await clearTimeout(this.timeout);
        }
        this.timeout = await setTimeout(async () => {
          if (value && value !== '') {
            const response = await this.searchAddress(value);

            if (response && response.features && response.features.length) {
              const feats = response.features;
              const adresses = feats.map((feat) => ({
                id: feat.properties.id,
                label: feat.properties.name,
                context: feat.properties.context,
                codePostal: feat.properties.postcode,
                ville: feat.properties.city,
                coordinates: feat.geometry.coordinates.join(',')
              }));

              this.options = [...adresses];
            } else {
              this.options = [];
            }
          } else {
            this.options = [];
          }
          this.loading_address = false;
        }, 500);
      },
      onSelect(value) {
        this.value = value;
        this.set_attribute(value.label, 'numeroVoie');
        this.set_attribute(value.codePostal, 'codePostal');
        this.set_attribute(value.ville, 'ville');
        this.set_attribute(value.coordinates, 'coordinates');
      },
      customLabel({ label, context }) {
        const custom_label = `${label} ${context}`;

        return custom_label;
      }
    }
  };
</script>
<style lang="scss" scoped></style>
