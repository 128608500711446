<template>
  <div class="tab-view">
    <template
      v-for="tab in tabs"
      v-bind:key="tab.id">
      <div
        class="tab__item"
        v-bind:class="[
          { 'tab__item--active': props.sub_children_id === tab.id },
          `tab__item--${displayEspaceColor(props.space_id)}`
        ]"
        v-on:click="navigateToChildrenComponent(tab.id)">
        {{ tab.label }}
      </div>
    </template>
  </div>
</template>
<script>
  import { ACTION_TYPES } from '@/store/constants/ActionTypes';
  import { createNamespacedHelpers } from 'vuex';
  const { mapState: mapStateEspace } = createNamespacedHelpers('espace');
  const { mapActions: mapActionsAccount } = createNamespacedHelpers('account');

  export default {
    props: ['props', 'tabs', 'default_component', 'check_is_unsaved'],
    computed: { ...mapStateEspace(['espaces_list']) },
    created() {
      this.loadSubChildren();
    },
    beforeUpdate() {
      this.loadSubChildren();
    },
    methods: {
      ...mapActionsAccount([ACTION_TYPES.SET_MODAL_ROUTE]),
      openModalRoute(next) {
        this[ACTION_TYPES.SET_MODAL_ROUTE]({
          modal_route: true,
          next_route: next
        });
      },
      navigateToChildrenComponent(sub_children_id) {
        // check unsaved changes
        if (this.check_is_unsaved) {
          this.openModalRoute((to) => {
            if (to !== false) {
              this.$router.push(
                `/${this.props.space_id}/${this.props.sub_space_id}/${sub_children_id}`
              );
            }
          });
        } else {
          this.$router.push(
            `/${this.props.space_id}/${this.props.sub_space_id}/${sub_children_id}`
          );
        }
      },
      loadSubChildren() {
        if (!this.props.sub_children_id) {
          this.navigateToChildrenComponent(this.default_component);
        }
      },
      displayEspaceColor(id) {
        return this.espaces_list[id] && this.espaces_list[id].color
          ? this.espaces_list[id].color
          : 'jinius-green';
      }
    }
  };
</script>
<style lang="scss" scoped>
  .tab-view {
    @apply flex w-full flex-wrap justify-between space-x-0.5 sm:space-x-2;

    .tab__item {
      @apply inline-flex flex-1 cursor-pointer items-center justify-center overflow-hidden rounded-t-lg border-l border-r border-t border-core-300 bg-core-300 pb-1.5 pt-2 text-center text-xs font-bold uppercase leading-none tracking-wide transition duration-200 ease-in-out hover:bg-core-200 sm:text-sm;

      &.tab__item--active {
        @apply relative bg-core-100 hover:bg-core-100;
        &:before {
          content: '';
          @apply absolute top-0 h-2 w-full border-t-2 border-jinius-green;
        }
      }

      &.tab__item--jinius-red:before {
        @apply border-jinius-red;
      }

      &.tab__item--jinius-blue:before {
        @apply border-jinius-blue;
      }

      &.tab__item--jinius-green:before {
        @apply border-jinius-green;
      }

      &.tab__item--jinius-purple:before {
        @apply border-jinius-purple;
      }

      &.tab__item--jinius-yellow:before {
        @apply border-jinius-yellow;
      }

      &.tab__item--jinius-pink:before {
        @apply border-jinius-pink;
      }
    }
  }
</style>
