<template>
  <default-renderer
    v-bind:params="params"
    select
    centered />
</template>

<script>
  import defaultRenderer from '@/components/utils/ag-grid/default-renderer';
  export default { components: { defaultRenderer } };
</script>
