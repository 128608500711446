<template>
  <section class="section__primary">
    <h1 class="section__title">Certifications</h1>

    <div class="section__body">
      <div class="flex w-full flex-wrap">
        <div class="question">
          Disposez-vous d'un numéro de déclaration d'activité de formateur ?
        </div>
        <div class="flex h-16 px-8 pb-8 pt-4">
          <yes-no-radio-buttons
            input_name="estDeclare"
            v-bind:input_value="cv_form.estDeclare"
            v-on:change="set_attribute($event, 'estDeclare')"
            class="flex-shrink" />
          <transition name="fade">
            <div
              v-if="cv_form.estDeclare"
              class="flex items-center pl-8">
              <div class="flex items-center px-6 font-bold text-jinius-green">
                N° de déclaration d'activité de formateur * :
              </div>
              <div class="flex">
                <input-box
                  class_name="no-box"
                  name="numeroDeclaration"
                  placeholder="Renseignez le numéro"
                  title="N° de déclaration d'activité de formateur*"
                  v-bind:class="{
                    'input-error': v$.cvForm.numeroDeclaration.$error
                  }"
                  v-bind:maxlength="30"
                  v-bind:value="cv_form.numeroDeclaration"
                  v-on:input="set_attribute($event, 'numeroDeclaration')" />
              </div>
            </div>
          </transition>
        </div>
      </div>

      <transition name="slide-up">
        <div
          v-if="cv_form.estDeclare"
          class="flex w-full flex-wrap">
          <div class="question">Avez-vous la certification Qualiopi ?</div>
          <div class="flex h-16 px-8 pb-8 pt-4">
            <template
              v-for="qualiopiItem in [
                { id: 'oui', label: 'Oui' },
                { id: 'encours', label: 'En cours' },
                { id: 'non', label: 'Non' }
              ]"
              v-bind:key="qualiopiItem.id">
              <radio-button
                v-bind:id="`${qualiopiItem.id}Qualiopi`"
                name="estCerfieQualiopi"
                type="radio"
                v-bind:checked="cv_form.estCerfieQualiopi === qualiopiItem.id"
                v-bind:label="qualiopiItem.label"
                v-bind:value="qualiopiItem.id"
                v-on:change="
                  set_attribute(qualiopiItem.id, 'estCerfieQualiopi')
                " />
            </template>

            <transition name="fade">
              <div
                v-if="cv_form.estCerfieQualiopi === 'oui'"
                class="flex items-center pl-8">
                <div class="flex items-center px-6 font-bold text-jinius-green">
                  Valable jusqu'au :
                </div>
                <div class="flex w-1/2">
                  <datepicker-box
                    class_name="no-box"
                    format="dd/MM/yyyy"
                    show_clear_button
                    placeholder="Sélectionner ou saisir"
                    v-bind:compare_date="new Date(1900, 1, 1)"
                    v-bind:value="cv_form.dateQualiopi"
                    v-on:input="set_attribute($event, 'dateQualiopi')" />
                </div>
              </div>
            </transition>
          </div>
        </div>
      </transition>

      <div class="flex w-full flex-wrap">
        <div class="question">
          Depuis quelle année êtes-vous formateur/enseignant ?
        </div>
        <div class="flex h-16 w-72 px-8 pb-8 pt-4">
          <select-box
            v-bind:value="
              annees.find((item) => cv_form.anneeFormateur === item.id)
            "
            v-on:input="(value) => set_attribute(value.id, 'anneeFormateur')"
            v-bind:categories="annees"
            placeholder="Saissisez une année"
            no_capitalize_label
            name="formateurAnnee"
            tracking="id"
            class_name="no-box" />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  import datepickerBox from '@/components/utils/datepicker-box';
  import inputBox from '@/components/utils/input-box';
  import RadioButton from '@/components/utils/radio-button';
  import selectBox from '@/components/utils/select-box';
  import yesNoRadioButtons from '@/components/utils/yes-no-radio-buttons';
  export default {
    components: {
      RadioButton,
      datepickerBox,
      yesNoRadioButtons,
      inputBox,
      selectBox
    },
    props: ['v$', 'set_attribute', 'cv_form'],
    computed: {
      annees: () => {
        const currentYear = new Date().getFullYear();

        return Array.from({ length: 62 }, (v, i) => ({
          id: currentYear - i,
          label: currentYear - i
        }));
      }
    }
  };
</script>
<style lang="scss" scoped></style>
