<template>
  <refonte-modal
    v-bind:show="props.show"
    class="!max-w-[1200px]"
    v-on:close="close">
    <template v-slot:modalHeader>
      <h1 class="text-2xl font-bold leading-none text-core-900 lg:text-3xl">
        {{ `Appréciation de ${participant.nomPrenom}` }}
      </h1>
    </template>

    <template v-slot:modalBody>
      <div
        v-if="loading"
        class="h-full w-full">
        <spinner
          sizes="w-20 h-20"
          thickness="border-8" />
      </div>

      <div
        v-else
        class="flex w-full flex-wrap space-x-4 px-8 py-4 md:space-x-0 md:space-y-4">
        <div
          class="flex w-full flex-wrap"
          v-if="detail?.surveyText && detail?.reponses">
          <div class="flex w-full text-lg leading-none text-core-900">
            Les réponses du participant au questionnaire sont les suivantes :
          </div>
          <div class="block w-full">
            <surveyjs-wrapper
              v-bind:hide_correction="true"
              v-bind:json="detail?.surveyText"
              v-bind:data="detail?.reponses"
              ref="surveyWrapper" />
          </div>
        </div>
        <div
          class="flex w-full text-lg text-core-900"
          v-else>
          Le participant n'a pas saisi son appréciation.
        </div>
      </div>
    </template>

    <template v-slot:modalFooter>
      <div
        v-if="surveyCatcher"
        class="inline-flex w-full justify-end space-x-4 text-lg">
        <refonte-button
          v-if="!surveyCatcher.isFirstPage"
          v-bind:on_click="() => surveyWrapper.prevPage()">
          Précédent
        </refonte-button>
        <refonte-button
          v-if="!surveyCatcher.isLastPage"
          v-bind:on_click="() => surveyWrapper.nextPage()">
          Suivant
        </refonte-button>
      </div>
    </template>
  </refonte-modal>
</template>
<script setup lang="ts">
  import RefonteModal from '@/components/refonte/modals/refonte-modal.vue';
  import SurveyjsWrapper from '@/components/utils/surveyjs-wrapper';
  import spinner from '@/components/utils/spinner';
  import { onMounted, ref, watchEffect } from 'vue';
  import { API } from '@/http-common';
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  const props = withDefaults(
    defineProps<{
      show: boolean;
      on_close: Function;
      session_reference: string;
      participant: Object;
      client_id: number;
    }>(),
    {}
  );

  const detail = ref(null);
  const surveyCatcher = ref(null);
  const surveyWrapper = ref(null);

  onMounted(() => {
    fetchDetail();
  });

  watchEffect(() => {
    if (surveyWrapper.value) {
      surveyCatcher.value = surveyWrapper.value.survey;
    } else {
      // not mounted yet, or the element was unmounted (e.g. by v-if)
    }
  });
  const loading = ref<boolean>(false);
  const fetchDetail = async () => {
    loading.value = true;
    if (props.session_reference && props.participant.id) {
      const response = await API.get(
        `client/satisfaction/formation/${props.session_reference}/participant/${props.participant.id}?clientId=${props.client_id}`
      );
      if (response && response.data) {
        detail.value = response.data;
      }
    }
    loading.value = false;
  };

  const close = () => {
    detail.value = null;
    props.on_close();
  };
</script>
<style lang="scss" scoped></style>
