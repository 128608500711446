<template>
  <div class="p-[8px]">
    <template v-if="factures.length">
      <refonte-table
        key="factures-1"
        table_index="factures-1"
        v-bind:selectable="true"
        v-bind:select_row="(row, value: boolean) => selectRow(row.id, value)"
        v-bind:data_etablissement="
          activeEntrepriseIds.length !== 1 ? 'clientDenomination' : undefined
        "
        v-bind:data_etablissement_siret="
          activeEntrepriseIds.length !== 1 ? 'clientSiret' : undefined
        "
        v-bind:columns="columns"
        v-bind:data="factures" />
    </template>
    <refonte-search-results-empty v-else />
  </div>
</template>

<script setup lang="ts">
  import RefonteTable from '@/components/refonte/tables/refonte-table.vue';
  import { useFactureStore } from '@/stores/factures';
  import { storeToRefs } from 'pinia';
  import IconDownload from '@/assets/img/refonte/icons/display/icon-download.svg?component';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';
  import { useEntrepriseStore } from '@/stores/entreprise';
  import { Facture } from '@/types/gestion/factures-types';
  import { h } from 'vue';
  import CommandesTableItemFormations from '@/views/_espace/_refonte/gestion/_commandes/commandes-table-item-formations.vue';
  import { downloadFacture } from '@/mixin/client_download__functions';

  const selectRow = (id: string, value: boolean) => {
    factureStore.selectFactureItem(id, value);
  };

  const columns = [
    {
      content_index: 'referenceEtType',
      label: 'Type',
      tags_type: 'statutVariant',
      tags_type_label: 'statutLabel',
      content_sub_text: (row: Facture) =>
        row.date && row.date !== '-' ? row.date : undefined,
      width: 200
    },
    {
      content_index: 'montantHt',
      label: 'Montant HT',
      min_width: 90,
      width: 120
    },
    {
      content_index: 'montantTtc',
      label: 'Montant TTC',
      content_sub_text: (row: Facture) =>
        row.montantRegle ? `Réglé : ${row.montantRegle}` : undefined,
      min_width: 100,
      width: 120
    },
    {
      content_index: 'organisateur',
      label: 'Organisme de formation',
      min_width: 110,
      width: 170
    },
    {
      content_index: 'formation',
      label: 'Formations',
      content_custom: (row: Facture) =>
        h(CommandesTableItemFormations, {
          formations: row.formations
        }),
      min_width: 240,
      width: 360
    },
    {
      content_index: 'factureNom',
      label: 'Facture',
      min_width: 140,
      width: 170,
      on_click: (row: Facture) => {
        downloadFacture(row.clientId, row.id);
      },
      content_icon: () => IconDownload,
      content_sub_text: (row: Facture) => {
        return row.telechargementDate || row.telechargementPar
          ? `Téléchargé${row.telechargementDate ? ` le ${row.telechargementDate}` : ''}${row.telechargementPar ? ` par ${row.telechargementPar}` : ''}`
          : undefined;
      },
      class: (row: Facture) => '!flex-grow-0'
    }
  ];

  const factureStore = useFactureStore();

  const { factures } = storeToRefs(factureStore);

  const entrepriseStore = useEntrepriseStore();

  const { activeEntrepriseIds } = storeToRefs(entrepriseStore);
</script>

<style lang="scss" scoped></style>
