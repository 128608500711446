<template>
  <refonte-filters
    v-if="metadataLoaded"
    v-bind:on_delete_click="() => resetFilters()"
    v-bind:show_filters_applied="
      filters_utils.methods.hasValue(filters.typeIds) ||
      filters_utils.methods.hasValue(filters.date) ||
      filters_utils.methods.hasValue(filters.datePeriodeFin) ||
      filters_utils.methods.hasValue(filters.datePeriodeDebut) ||
      filters_utils.methods.hasValue(filters.datePeriodeId) ||
      filters_utils.methods.hasValue(filters.references) ||
      filters_utils.methods.hasValue(filters.commandes) ||
      filters_utils.methods.hasValue(filters.vendeurId) ||
      filters_utils.methods.hasValue(filters.contactId) ||
      filters_utils.methods.hasValue(filters.formationId) ||
      filters_utils.methods.hasValue(filters.dateFormation) ||
      filters_utils.methods.hasValue(filters.dateFormationPeriodeFin) ||
      filters_utils.methods.hasValue(filters.dateFormationPeriodeDebut) ||
      filters_utils.methods.hasValue(filters.dateFormationPeriodeId)
    ">
    <template v-slot:default>
      <refonte-filters-item
        v-bind:on_open="() => resetTypes()"
        v-bind:footer_button_on_click="() => updateTypesFilter()"
        v-bind:footer_button_disabled="
          !(
            temporaryTypes &&
            (temporaryTypes.length !==
              (filters.typeIds ? filters.typeIds.length : 0) ||
              temporaryTypes.sort().join(', ') !==
                filters.typeIds?.sort().join(', '))
          )
        "
        v-bind:number="filters.typeIds?.length ?? undefined">
        Type
        <template v-slot:dropdownContent>
          <refonte-checkbox
            v-if="typesList.length"
            v-for="t in typesList"
            v-bind:key="t.id"
            v-bind:label="t.label"
            v-bind:value="!!temporaryTypes?.find((type) => type === t.id)"
            v-on:click="(e: Event) => e.stopPropagation()"
            v-on:input="() => updateTypes(t.id)" />
        </template>
      </refonte-filters-item>
      <refonte-filters-item
        v-bind:footer_button_on_click="() => updateDateFilter()">
        Date d'émission
        <template v-slot:dropdownContent>
          <refonte-dropdown-item
            v-for="periode in PERIOD_IDS"
            v-bind:on_click="
              () => {
                resetDateInputFilters();
                updateFilter(
                  FACTURES_FILTERS_CONFIGURATION.datePeriodeId.name,
                  periode.id
                );
              }
            "
            v-bind:key="periode.id">
            {{ periode.label }}
          </refonte-dropdown-item>
          <div class="p-2">
            <refonte-label label="Date" />
            <refonte-datepicker
              v-on:input="(value) => updateDate('date', value)"
              class="p-1"
              placeholder="Date exacte (JJ/MM/YYYY)"
              v-bind:value="date?.date" />
          </div>
          <refonte-separator />
          <div class="p-2">
            <refonte-label label="Période" />
            <refonte-datepicker
              class="p-1"
              placeholder="Début (JJ/MM/YYYY)"
              v-on:input="(value) => updateDate('debut', value)"
              v-bind:value="date?.debut" />
            <refonte-datepicker
              class="p-1"
              placeholder="Fin (JJ/MM/YYYY)"
              v-on:input="(value) => updateDate('fin', value)"
              v-bind:value="date?.fin" />
          </div>
        </template>
      </refonte-filters-item>
      <refonte-filters-item
        v-bind:on_search="(value: string) => updateReferenceValue(value)"
        v-bind:on_open="() => resetReferences()"
        v-bind:footer_button_on_click="() => updateReferencesFilter()"
        v-bind:footer_button_disabled="
          !(
            temporaryReferences &&
            (temporaryReferences.length !==
              (filters.references ? filters.references.length : 0) ||
              temporaryReferences.sort().join(', ') !==
                filters.references?.sort().join(', '))
          )
        "
        v-bind:number="filters.references?.length ?? undefined">
        Référence
        <template v-slot:dropdownContent>
          <refonte-checkbox
            v-if="referencesList.length"
            v-for="r in referencesList"
            v-bind:key="r.reference"
            v-bind:label="r.reference"
            v-bind:sub_label="r.date"
            v-bind:value="!!temporaryReferences?.includes(r.reference)"
            v-on:click="(e: Event) => e.stopPropagation()"
            v-on:input="() => updateReferences(r.reference)" />
          <refonte-search-results-empty v-else />
        </template>
      </refonte-filters-item>
      <refonte-filters-item
        v-bind:on_search="(value: string) => updateCommandeValue(value)"
        v-bind:on_open="() => resetCommandes()"
        v-bind:footer_button_on_click="() => updateCommandesFilter()"
        v-bind:footer_button_disabled="
          !(
            temporaryCommandes &&
            (temporaryCommandes.length !==
              (filters.commandes ? filters.commandes.length : 0) ||
              temporaryCommandes.sort().join(', ') !==
                filters.commandes?.sort().join(', '))
          )
        "
        v-bind:number="filters.commandes?.length ?? undefined">
        Commande
        <template v-slot:dropdownContent>
          <refonte-checkbox
            v-if="commandesList.length"
            v-for="c in commandesList"
            v-bind:key="c.reference"
            v-bind:label="c.reference"
            v-bind:sub_label="c.date"
            v-bind:value="!!temporaryCommandes?.includes(c.reference)"
            v-on:click="(e: Event) => e.stopPropagation()"
            v-on:input="() => updateCommandes(c.reference)" />
          <refonte-search-results-empty v-else />
        </template>
      </refonte-filters-item>
      <refonte-filters-item
        v-bind:on_search="(value: string) => updateVendeurValue(value)">
        Organisme de formation
        <template v-slot:dropdownContent>
          <refonte-dropdown-item
            v-if="vendeurList.length"
            v-for="vendeur in vendeurList"
            v-bind:on_click="
              () =>
                updateFilter(
                  FACTURES_FILTERS_CONFIGURATION.vendeurId.name,
                  vendeur.id
                )
            "
            v-bind:key="vendeur.id">
            {{ vendeur.nom }}
          </refonte-dropdown-item>
          <refonte-search-results-empty v-else />
        </template>
      </refonte-filters-item>
      <refonte-filters-item
        v-bind:on_search="(value: string) => updateParticipantValue(value)">
        Participant
        <template v-slot:dropdownContent>
          <refonte-dropdown-item
            v-if="participantList.length"
            v-for="participant in participantList"
            v-bind:on_click="
              () =>
                updateFilter(
                  FACTURES_FILTERS_CONFIGURATION.contactId.name,
                  participant.id
                )
            "
            v-bind:key="participant.id">
            <div>
              {{ participant.nomPrenom }}
            </div>
            <div>
              {{ participant.email }}
            </div>
          </refonte-dropdown-item>
          <refonte-search-results-empty v-else />
        </template>
      </refonte-filters-item>
      <refonte-filters-item
        v-bind:on_search="(value) => updateFormationValue(value)">
        Formation
        <template v-slot:dropdownContent>
          <refonte-dropdown-item
            v-if="formationList.length"
            v-for="formation in formationList"
            v-bind:on_click="
              () =>
                updateFilter(
                  FACTURES_FILTERS_CONFIGURATION.formationId.name,
                  formation.id
                )
            "
            v-bind:key="formation.id">
            {{ formation.titre }}
          </refonte-dropdown-item>

          <refonte-search-results-empty v-else />
        </template>
      </refonte-filters-item>
      <refonte-filters-item
        v-bind:footer_button_on_click="() => updateDateFormationFilter()">
        Date de formation
        <template v-slot:dropdownContent>
          <refonte-dropdown-item
            v-for="periode in PERIOD_IDS"
            v-bind:on_click="
              () => {
                resetDateFormationInputFilters();
                updateFilter(
                  FACTURES_FILTERS_CONFIGURATION.dateFormationPeriodeId.name,
                  periode.id
                );
              }
            "
            v-bind:key="periode.id">
            {{ periode.label }}
          </refonte-dropdown-item>
          <div class="p-2">
            <refonte-label label="Date" />
            <refonte-datepicker
              v-on:input="(value) => updateDateFormation('date', value)"
              class="p-1"
              placeholder="Date exacte (JJ/MM/YYYY)"
              v-bind:value="dateFormation?.date" />
          </div>
          <refonte-separator />
          <div class="p-2">
            <refonte-label label="Période" />
            <refonte-datepicker
              class="p-1"
              placeholder="Début (JJ/MM/YYYY)"
              v-on:input="(value) => updateDateFormation('debut', value)"
              v-bind:value="dateFormation?.debut" />
            <refonte-datepicker
              class="p-1"
              placeholder="Fin (JJ/MM/YYYY)"
              v-on:input="(value) => updateDateFormation('fin', value)"
              v-bind:value="dateFormation?.fin" />
          </div>
        </template>
      </refonte-filters-item>
    </template>
    <template v-slot:filtersApplied>
      <template v-for="filterApplied in filtersAppliedConfiguration">
        <refonte-filters-applied-item
          v-bind:key="filterApplied.id"
          v-bind:on_close="
            () => {
              filters_utils.methods.hasValue(filters[filterApplied.id]) &&
                updateFilter(filterApplied.id, undefined);
              updateTemporaryDates(filterApplied.id);
            }
          "
          v-if="filters_utils.methods.hasValue(filters[filterApplied.id])">
          {{ filterApplied?.value }}
        </refonte-filters-applied-item>
      </template>
    </template>
  </refonte-filters>
</template>
<script setup lang="ts">
  import RefonteFilters from '@/components/refonte/filters/refonte-filters.vue';
  import RefonteFiltersItem from '@/components/refonte/filters/refonte-filters-item.vue';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';
  import RefonteDropdownItem from '@/components/refonte/dropdowns/refonte-dropdown-item.vue';
  import RefonteDatepicker from '@/components/refonte/inputs/refonte-datepicker.vue';
  import RefonteLabel from '@/components/refonte/inputs/refonte-label.vue';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';
  import RefonteCheckbox from '@/components/refonte/inputs/refonte-checkbox.vue';
  import { computed, onMounted, ref, watch } from 'vue';
  import {
    FACTURES_FILTERS_CONFIGURATION,
    useFactureFilterStore
  } from '@/stores/factures';
  import RefonteFiltersAppliedItem from '@/components/refonte/filters/refonte-filters-applied-item.vue';
  import { storeToRefs } from 'pinia';
  import { format, Locale } from 'date-fns';
  import fr from 'date-fns/locale/fr';
  import {
    FactureMetadata,
    FactureMetadataCommande,
    FactureMetadataFormation,
    FactureMetadataParticipant,
    FactureMetadataReference,
    FactureMetadataType,
    FactureMetadataVendeur
  } from '@/types/gestion/factures-filters-types';
  import { useEntrepriseStore } from '@/stores/entreprise';
  import { API } from '@/http-common';
  import filters_utils from '@/mixin/utils/filters_utils';

  const referenceSearchValue = ref<string>('');
  const updateReferenceValue = (value: string) => {
    referenceSearchValue.value = value;
  };

  const commandeSearchValue = ref<string>('');
  const updateCommandeValue = (value: string) => {
    commandeSearchValue.value = value;
  };

  const vendeurSearchValue = ref<string>('');
  const updateVendeurValue = (value: string) => {
    vendeurSearchValue.value = value;
  };

  const participantSearchValue = ref<string>('');
  const updateParticipantValue = (value: string) => {
    participantSearchValue.value = value;
  };

  const formationSearchValue = ref<string>('');
  const updateFormationValue = (value: string) => {
    formationSearchValue.value = value;
  };

  type DateFilter = {
    debut?: Date;
    fin?: Date;
    date?: Date;
    periodeId?: string;
  };

  const updateDate = (name: string, value: undefined | Date | string) => {
    date.value[name] = value;
  };

  const updateDateFormation = (
    name: string,
    value: undefined | Date | string
  ) => {
    dateFormation.value[name] = value;
  };

  const getTypesLabel = (ids: number[] | undefined) => {
    return ids
      ? filters_utils.methods.formatFilter(
          `Type${ids.length > 1 ? 's' : ''}`,
          ids
            .map(
              (id) =>
                typeFilterList.value &&
                typeFilterList.value.length &&
                typeFilterList.value.find((type) => type.id == id)?.label
            )
            .join(', ')
        )
      : undefined;
  };

  const getVendeurNom = (id: number | undefined) => {
    const item =
      vendeurFilterList.value &&
      vendeurFilterList.value.length &&
      vendeurFilterList.value.find((v) => v.id == id);
    return item
      ? filters_utils.methods.formatFilter('Organisme de formation', item.nom)
      : '';
  };
  const getParticipantNomPrenom = (id: number | undefined) => {
    const item =
      participantFilterList.value &&
      participantFilterList.value.length &&
      participantFilterList.value.find((i) => i.id == id);
    return item
      ? filters_utils.methods.formatFilter('Participant', item.nomPrenom)
      : '';
  };
  const getProduitTitre = (id: number | undefined) => {
    const item =
      formationFilterList.value &&
      formationFilterList.value.length &&
      formationFilterList.value.find((i) => i.id == id);
    return item
      ? filters_utils.methods.formatFilter('Formation', item.titre)
      : '';
  };

  const PERIOD_IDS = computed(() => [
    {
      id: 'ANNEE_EN_COURS',
      label: 'Année en cours'
    },
    {
      id: 'ANNEE_PRECEDENTE',
      label: 'Année précédente'
    }
  ]);
  const getPeriodFilterApply = (id: string | undefined) => {
    const period = PERIOD_IDS.value.find((period) => period.id == id);
    return period ? period.label : '';
  };

  const factureFilterStore = useFactureFilterStore();
  const { filters } = storeToRefs(factureFilterStore);
  const updateFilter = (
    name: string,
    value: number | string | undefined | Date | boolean | string[] | number[]
  ) => {
    factureFilterStore.setFilter(name, value);
  };
  const date = ref<DateFilter>({
    date: filters.value.date,
    debut: filters.value.datePeriodeDebut,
    fin: filters.value.datePeriodeFin,
    periodeId: filters.value.datePeriodeId
  });
  const dateFormation = ref<DateFilter>({
    date: filters.value.dateFormation,
    debut: filters.value.dateFormationPeriodeDebut,
    fin: filters.value.dateFormationPeriodeFin,
    periodeId: filters.value.dateFormationPeriodeId
  });

  const resetFilters = () => {
    factureFilterStore.resetFilters();
    const dateFilterDefaut = {
      date: undefined,
      debut: undefined,
      fin: undefined,
      periodeId: undefined
    };
    date.value = { ...dateFilterDefaut };
    dateFormation.value = { ...dateFilterDefaut };
  };
  const resetDateInputFilters = () => {
    updateFilter(FACTURES_FILTERS_CONFIGURATION.date.name, undefined);
    updateFilter(
      FACTURES_FILTERS_CONFIGURATION.datePeriodeDebut.name,
      undefined
    );
    updateFilter(FACTURES_FILTERS_CONFIGURATION.datePeriodeFin.name, undefined);
    date.value = {
      ...date.value,
      date: undefined,
      debut: undefined,
      fin: undefined
    };
  };
  const resetDateFormationInputFilters = () => {
    updateFilter(FACTURES_FILTERS_CONFIGURATION.dateFormation.name, undefined);
    updateFilter(
      FACTURES_FILTERS_CONFIGURATION.dateFormationPeriodeDebut.name,
      undefined
    );
    updateFilter(
      FACTURES_FILTERS_CONFIGURATION.dateFormationPeriodeFin.name,
      undefined
    );
    dateFormation.value = {
      ...dateFormation.value,
      date: undefined,
      debut: undefined,
      fin: undefined
    };
  };
  const updateDateFilter = () => {
    const dateValue = date.value;
    updateFilter(FACTURES_FILTERS_CONFIGURATION.datePeriodeId.name, undefined);
    updateFilter(FACTURES_FILTERS_CONFIGURATION.date.name, dateValue.date);
    updateFilter(
      FACTURES_FILTERS_CONFIGURATION.datePeriodeDebut.name,
      dateValue.debut
    );
    updateFilter(
      FACTURES_FILTERS_CONFIGURATION.datePeriodeFin.name,
      dateValue.fin
    );
  };
  const updateDateFormationFilter = () => {
    const dateValue = dateFormation.value;
    updateFilter(
      FACTURES_FILTERS_CONFIGURATION.dateFormationPeriodeId.name,
      undefined
    );
    updateFilter(
      FACTURES_FILTERS_CONFIGURATION.dateFormation.name,
      dateValue.date
    );
    updateFilter(
      FACTURES_FILTERS_CONFIGURATION.dateFormationPeriodeDebut.name,
      dateValue.debut
    );
    updateFilter(
      FACTURES_FILTERS_CONFIGURATION.dateFormationPeriodeFin.name,
      dateValue.fin
    );
  };
  const updateTemporaryDates = (filterId: string) => {
    switch (filterId) {
      case FACTURES_FILTERS_CONFIGURATION.date.name:
        updateDate('date', undefined);
        break;
      case FACTURES_FILTERS_CONFIGURATION.datePeriodeDebut.name:
        updateDate('debut', undefined);
        break;
      case FACTURES_FILTERS_CONFIGURATION.datePeriodeFin.name:
        updateDate('fin', undefined);
        break;
      case FACTURES_FILTERS_CONFIGURATION.dateFormation.name:
        updateDateFormation('date', undefined);
        break;
      case FACTURES_FILTERS_CONFIGURATION.dateFormationPeriodeDebut.name:
        updateDateFormation('debut', undefined);
        break;
      case FACTURES_FILTERS_CONFIGURATION.dateFormationPeriodeFin.name:
        updateDateFormation('fin', undefined);
        break;
      default:
        break;
    }
  };

  const temporaryTypes = ref<number[] | undefined>(filters.value.typeIds);

  const updateTypesFilter = () => {
    updateFilter(
      FACTURES_FILTERS_CONFIGURATION.typeIds.name,
      temporaryTypes.value && temporaryTypes.value.length > 0
        ? temporaryTypes.value
        : undefined
    );
  };

  const resetTypes = () => {
    temporaryTypes.value = filters.value.typeIds;
  };

  const updateTypes = (value: number) => {
    if (temporaryTypes.value?.find((t) => t === value)) {
      temporaryTypes.value = temporaryTypes.value.filter((t) => t !== value);
    } else {
      temporaryTypes.value = temporaryTypes.value
        ? [...temporaryTypes.value, value]
        : [value];
    }
  };

  const temporaryReferences = ref<string[] | undefined>(
    filters.value.references
  );

  const updateReferencesFilter = () => {
    updateFilter(
      FACTURES_FILTERS_CONFIGURATION.references.name,
      temporaryReferences.value && temporaryReferences.value.length > 0
        ? temporaryReferences.value
        : undefined
    );
  };

  const resetReferences = () => {
    temporaryReferences.value = filters.value.references;
  };

  const updateReferences = (value: string) => {
    if (temporaryReferences.value?.includes(value)) {
      temporaryReferences.value = temporaryReferences.value.filter(
        (r) => r !== value
      );
    } else {
      temporaryReferences.value = temporaryReferences.value
        ? [...temporaryReferences.value, value]
        : [value];
    }
  };

  const temporaryCommandes = ref<string[] | undefined>(filters.value.commandes);

  const updateCommandesFilter = () => {
    updateFilter(
      FACTURES_FILTERS_CONFIGURATION.commandes.name,
      temporaryCommandes.value && temporaryCommandes.value.length > 0
        ? temporaryCommandes.value
        : undefined
    );
  };

  const resetCommandes = () => {
    temporaryCommandes.value = filters.value.commandes;
  };

  const updateCommandes = (value: string) => {
    if (temporaryCommandes.value?.includes(value)) {
      temporaryCommandes.value = temporaryCommandes.value.filter(
        (c) => c !== value
      );
    } else {
      temporaryCommandes.value = temporaryCommandes.value
        ? [...temporaryCommandes.value, value]
        : [value];
    }
  };

  const formatDate = (date: Date | undefined) => {
    return date
      ? format(date.toString(), 'dd/MM/yyyy', {
          locale: fr as unknown as Locale
        })
      : '';
  };

  const filtersAppliedConfiguration = computed(() => [
    {
      id: FACTURES_FILTERS_CONFIGURATION.typeIds.name,
      value: getTypesLabel(filters.value.typeIds)
    },
    {
      id: FACTURES_FILTERS_CONFIGURATION.date.name,
      value: filters_utils.methods.formatFilter(
        "Date d'émission",
        formatDate(filters.value.date)
      )
    },
    {
      id: FACTURES_FILTERS_CONFIGURATION.datePeriodeId.name,
      value: filters_utils.methods.formatFilter(
        "Date d'émission",
        getPeriodFilterApply(filters.value.datePeriodeId)
      )
    },
    {
      id: FACTURES_FILTERS_CONFIGURATION.datePeriodeDebut.name,
      value: filters_utils.methods.formatFilter(
        "Date d'émission",
        `Après le
          ${formatDate(filters.value.datePeriodeDebut)}`
      )
    },
    {
      id: FACTURES_FILTERS_CONFIGURATION.datePeriodeFin.name,
      value: filters_utils.methods.formatFilter(
        "Date d'émission",
        `Avant le
          ${formatDate(filters.value.datePeriodeFin)}`
      )
    },
    {
      id: FACTURES_FILTERS_CONFIGURATION.references.name,
      value: filters.value.references
        ? filters_utils.methods.formatFilter(
            `Référence${filters.value.references.length > 1 ? 's' : ''}`,
            filters.value.references.join(', ')
          )
        : undefined
    },
    {
      id: FACTURES_FILTERS_CONFIGURATION.commandes.name,
      value: filters.value.commandes
        ? filters_utils.methods.formatFilter(
            `Commande${filters.value.commandes.length > 1 ? 's' : ''}`,
            filters.value.commandes.join(', ')
          )
        : undefined
    },
    {
      id: FACTURES_FILTERS_CONFIGURATION.vendeurId.name,
      value: getVendeurNom(filters.value.vendeurId)
    },
    {
      id: FACTURES_FILTERS_CONFIGURATION.contactId.name,
      value: getParticipantNomPrenom(filters.value.contactId)
    },
    {
      id: FACTURES_FILTERS_CONFIGURATION.formationId.name,
      value: getProduitTitre(filters.value.formationId)
    },
    {
      id: FACTURES_FILTERS_CONFIGURATION.dateFormation.name,
      value: filters_utils.methods.formatFilter(
        'Date de formation',
        formatDate(filters.value.dateFormation)
      )
    },
    {
      id: FACTURES_FILTERS_CONFIGURATION.dateFormationPeriodeId.name,
      value: filters_utils.methods.formatFilter(
        'Date de formation',
        getPeriodFilterApply(filters.value.dateFormationPeriodeId)
      )
    },
    {
      id: FACTURES_FILTERS_CONFIGURATION.dateFormationPeriodeDebut.name,
      value: filters_utils.methods.formatFilter(
        'Date de formation',
        `Après le
          ${formatDate(filters.value.dateFormationPeriodeDebut)}`
      )
    },
    {
      id: FACTURES_FILTERS_CONFIGURATION.dateFormationPeriodeFin.name,
      value: filters_utils.methods.formatFilter(
        'Date de formation',
        `Avant le
          ${formatDate(filters.value.dateFormationPeriodeFin)}`
      )
    }
  ]);

  const typeFilterList = ref<FactureMetadataType[]>([]);

  const typesList = computed(() => typeFilterList.value);

  const referenceFilterList = ref<FactureMetadataReference[]>([]);

  const referencesList = computed(() =>
    referenceFilterList.value && referenceFilterList.value
      ? referenceFilterList.value.filter(
          (r) =>
            referenceSearchValue.value.length < 3 ||
            r.reference
              .toLowerCase()
              .includes(referenceSearchValue.value.toLowerCase())
        )
      : []
  );

  const commandeFilterList = ref<FactureMetadataCommande[]>([]);

  const commandesList = computed(() =>
    commandeFilterList.value && commandeFilterList.value.length
      ? commandeFilterList.value.filter(
          (c) =>
            commandeSearchValue.value.length < 3 ||
            c.reference
              .toLowerCase()
              .includes(referenceSearchValue.value.toLowerCase())
        )
      : []
  );

  const vendeurFilterList = ref<FactureMetadataVendeur[]>([]);

  const vendeurList = computed(() =>
    vendeurFilterList.value && vendeurFilterList.value.length
      ? vendeurFilterList.value.filter(
          (v) =>
            vendeurSearchValue.value.length < 3 ||
            v.nom.toLowerCase().includes(vendeurSearchValue.value.toLowerCase())
        )
      : []
  );

  const participantFilterList = ref<FactureMetadataParticipant[]>([]);

  const participantList = computed(() =>
    participantFilterList.value && participantFilterList.value.length
      ? participantFilterList.value.filter(
          (p) =>
            participantSearchValue.value.length < 3 ||
            p.nomPrenom
              .toLowerCase()
              .includes(participantSearchValue.value.toLowerCase())
        )
      : []
  );

  const formationFilterList = ref<FactureMetadataFormation[]>([]);

  const formationList = computed(() =>
    formationFilterList.value && formationFilterList.value.length
      ? formationFilterList.value.filter(
          (f) =>
            formationSearchValue.value.length < 3 ||
            f.titre
              .toLowerCase()
              .includes(formationSearchValue.value.toLowerCase())
        )
      : []
  );

  const entrepriseStore = useEntrepriseStore();
  const { activeClientIds } = storeToRefs(entrepriseStore);
  const metadataLoaded = ref<boolean>(false);
  const fetchMetadata = async () => {
    metadataLoaded.value = false;
    const response = await API.get(
      `client/v2/factures/metadata${activeClientIds.value.length ? '?clientIds=' + activeClientIds.value : ''}`
    );

    if (response && response.data) {
      const data: FactureMetadata = response.data;
      referenceFilterList.value = data.references ?? [];
      commandeFilterList.value = data.commandes ?? [];
      vendeurFilterList.value = data.vendeurs ?? [];
      typeFilterList.value = data.types ?? [];
      participantFilterList.value = data.participants ?? [];
      formationFilterList.value = data.formations ?? [];
    }
    metadataLoaded.value = true;
  };

  watch(activeClientIds, () => {
    resetFilters();
    fetchMetadata();
  });

  onMounted(async () => {
    resetFilters();
    await fetchMetadata();
  });
</script>

<style lang="scss" scoped></style>
