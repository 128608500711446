<template>
  <div className="w-full lg:h-full">
    <spinner
      v-if="loading"
      sizes="w-20 h-20"
      thickness="border-8" />
    <div v-if="!loading">
      <div class="mb-2 flex w-full justify-end">
        <div>
          <a
            target="_blank"
            v-on:click="openUrlLms"
            class="mb-2 mt-2 flex h-12 w-full cursor-pointer items-center justify-center space-x-4 rounded bg-jinius-blue px-8 py-4 text-center text-xl font-bold text-white shadow hover:opacity-75 disabled:cursor-not-allowed disabled:bg-core-200 disabled:text-core-400">
            <span>Accéder au module e-learning</span>
            <font-awesome-icon
              size="sm"
              icon="external-link-alt" />
          </a>
        </div>
      </div>
      <ag-grid-wrapper
        v-bind:column_defs="columnDefs"
        v-bind:initial_data="stagiairesControleur" />
      <stagiaire-details-modal
        v-bind:show_modal="showDetailsModal"
        v-bind:onclose="closeDetailsModal"
        v-bind:content="detailsModalContent"
        v-bind:current_space="props.space_id" />
    </div>
  </div>
</template>
<script>
  import AgGridWrapper from '@/components/utils/ag-grid/ag-grid-wrapper';
  import Spinner from '@/components/utils/spinner.vue';
  import { API } from '@/http-common';
  import stagiaireDetailsModal from '@/views/_espace/_stage/_modal/stagiaireDetailsModal.vue';

  export default {
    components: {
      AgGridWrapper,
      Spinner,
      stagiaireDetailsModal
    },
    props: ['props'],
    data: () => ({
      loading: false,
      stagiairesControleur: null,
      showDetailsModal: false,
      detailsModalContent: null
    }),
    computed: {
      controleur_context() {
        return this.props.space_id === 'controleurDeStageEC'
          ? 'controleur-ec'
          : 'controleur-cac';
      },
      columnDefs() {
        const anneeField =
          this.controleur_context === 'controleur-ec'
            ? 'cycleIstya'
            : 'anneeStageCAC';
        const shared_columns = [
          {
            headerName: 'Nom',
            field: 'nom',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'Prénom',
            field: 'prenom',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'E-mail',
            field: 'email',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'Année de stage',
            field: anneeField,
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'Statut',
            field: 'statut',
            filter: 'agTextColumnFilter',
            cellRenderer: 'statutCellRenderer'
          },

          {
            headerName: 'Accès e-learning',
            field: 'accesElearning',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'Suivi stagiaire',
            field: 'id',
            cellRenderer: 'buttonCellRenderer',
            cellRendererParams: {
              label: 'Détails',
              onclick: (e, params) => this.openDetailsModal(params)
            },
            sortable: false
          }
        ];

        if (this.controleur_context === 'controleur-ec') {
          const extra_columns = [
            {
              headerName: 'Groupe',
              field: 'groupe',
              filter: 'agTextColumnFilter'
            },
            {
              headerName: 'Maître de stage',
              field: 'maitre',
              filter: 'agTextColumnFilter'
            }
          ];

          shared_columns.splice(4, 0, ...extra_columns);
        }

        return shared_columns;
      }
    },
    async created() {
      await this.fetchStagiaires();
    },
    methods: {
      async openUrlLms() {
        const response = await API.get(
          `/stage/${this.controleur_context}/lms/lien`,
          this.$header_skip_redirection
        );

        if (response && response.data && response.data.url) {
          window.open(response.data.url);
        }
      },
      async fetchStagiaires() {
        this.loading = true;
        const response = await API.get(
          `stage/${this.controleur_context}/stagiaires`,
          this.$header_skip_redirection
        );

        this.stagiairesControleur = response.data.map((s) => ({
          ...s,
          accesElearning: s.accesElearning ? 'Oui' : 'Non'
        }));
        this.loading = false;
      },
      async openDetailsModal(params) {
        const response = await API.get(
          `/stage/${this.controleur_context}/stagiaires/${params.node.data.contactId}/sessions`,
          this.$header_skip_redirection
        );

        this.detailsModalContent = {
          ...params.node.data,
          sessions: response.data
        };
        this.showDetailsModal = true;
      },
      closeDetailsModal() {
        this.showDetailsModal = false;
        this.detailsModalContent = null;
      }
    }
  };
</script>
<style lang="scss" scoped></style>
