<template>
  <div
    class="information__container"
    role="info">
    <div class="flex flex-wrap justify-between px-3">
      <div class="mb-3 flex w-full items-center space-x-3">
        <font-awesome-icon
          v-bind:icon="icon"
          size="lg" />
        <p class="information__title">{{ title }}</p>
      </div>
      <div class="w-full pl-2">
        <p
          class="information__description"
          v-html="description"></p>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: [
      'title',
      'description',
      'icon',
      'button_onclick',
      'button_label',
      'button_icon'
    ]
  };
</script>
<style lang="scss" scoped>
  .information__container {
    @apply mb-8 rounded border-l-8 border-jinius-blue bg-white px-4 py-6 text-sm text-jinius-blue shadow sm:text-base;

    .information__title {
      @apply w-full text-xl font-bold;
    }

    .information__description {
      @apply w-full;
    }

    .informmation__button {
      @apply flex items-center justify-center rounded bg-core-900 p-4 text-white hover:bg-jinius-dark-green;
    }
  }
</style>
