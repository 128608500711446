<template>
  <div class="session-container">
    <template v-if="!parcours">
      <session-stagiaire-card
        v-bind:session="{
          ...session,
          type: getTypeList(
            session.classeVirtuelle,
            session.type,
            session.ficheType
          )
        }" />
    </template>
    <template v-else>
      <session-stagiaire-card
        v-bind:session="{
          ...session,
          type: getTypeList(
            session.classeVirtuelle,
            session.type,
            session.ficheType
          )
        }"
        v-bind:parcours="true"
        v-bind:collapsed="collapsed"
        v-bind:toggle_collapse="toggleCollapse" />

      <div
        class="-mt-3 ml-2 border-l border-accent-300 pl-4 pt-4 transition lg:ml-6 lg:pl-6"
        v-bind:class="`${collapsed ? 'collapsed' : ''}`">
        <transition-group name="slide-up">
          <session-stagiaire-card
            v-if="!collapsed"
            v-for="produit in produits"
            v-bind:key="`produit-${produit.id}`"
            v-bind:session="{
              ...produit,
              type: getTypeList(
                produit.classeVirtuelle,
                produit.type,
                produit.ficheType
              ),
              organisateur: session.organisateur,
              participantStatut: session.participantStatut,
              ...(produit.type &&
                produit.type.id === 1 && {
                  lieu: produit.lieu || session.lieu
                })
            }"
            v-bind:produit="true" />
        </transition-group>
      </div>
    </template>
  </div>
</template>

<script>
  import { map_statut_session_stagiaire_labels } from '@/assets/data/data_maps';
  import sessionStagiaireCard from '@/components/utils/session-stagiaire-card';
  export default {
    components: { sessionStagiaireCard },
    props: {
      session: {
        type: Object,
        default: null
      },
      produits: {
        type: Array,
        default: () => []
      },
      parcours: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      collapsed: false,
      map_statut_session_stagiaire_labels
    }),
    methods: {
      getTypeList(classe_virtuelle, type, ficheType) {
        const mainType = ficheType
          ? {
              id: type.id,
              label: ficheType.label
            }
          : type;

        return classe_virtuelle && [1, 4].includes(type.id)
          ? [
              mainType,
              {
                label: 'Classe virtuelle',
                id: 5
              }
            ]
          : [mainType];
      },
      toggleCollapse() {
        this.collapsed = !this.collapsed;
      },
      openCollapse() {
        // method utilisée par un component parent
        this.collapsed = false;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .collapsed {
    @apply border-transparent;
  }
</style>
