<template>
  <div class="flex items-center justify-center">
    <form
      method="post"
      class="flex flex-col px-6 pt-6">
      <div class="flex items-center justify-between">
        <a
          v-bind:href="connectWithOidcProvider"
          v-on:click="triggerRedirectPath"
          class="flex w-full items-center rounded bg-white px-8 py-5 font-bold uppercase text-core-900 shadow transition duration-150 ease-in-out hover:text-primary-900 hover:shadow-md focus:outline-none">
          <span class="ml-3 inline-flex">Me connecter à </span>
          <img
            v-bind:src="fetchLogoJinius"
            alt="Jinius"
            class="mx-2 inline-flex w-14" />
        </a>
      </div>

      <div class="mt-4 flex items-center justify-center">
        <a
          v-bind:href="createNewAccount"
          class="inline-block text-sm normal-case hover:text-primary-900 hover:underline"
          v-tooltip="
            is_navbar
              ? {
                  placement: 'bottom',
                  distance: '10',
                  /* show:true, */
                  /* autoHide:false, */
                  content: safirInfo()
                }
              : null
          ">
          <span class="mx-2">Vous n'avez pas de compte Jinius ?</span>
          <font-awesome-icon
            v-if="is_navbar"
            size="lg"
            v-bind:icon="['fas', 'info-circle']" />
        </a>
      </div>
    </form>

    <div
      v-if="!is_navbar"
      v-html="safirInfo()"
      class="ml-2 flex flex-col border-l-1 border-core-400 pl-6 text-left"></div>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex';
  const { mapState: mapStateAuth, mapMutations: mapMutationsAuth } =
    createNamespacedHelpers('auth');

  export default {
    props: {
      is_navbar: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapStateAuth({ authenticated: (state) => state.authenticated }),
      connectWithOidcProvider() {
        return `${import.meta.env.VITE_API_AUTH}/login?to=${import.meta.env.VITE_AUTH_REDIRECT}`;
      },
      createNewAccount() {
        return `${import.meta.env.VITE_API_AUTH}/registration/redirect?to=${import.meta.env.VITE_AUTH_REDIRECT}`;
      },
      fetchLogoJinius() {
        return 'https://cdn.jinius.fr/logos/jinius.svg';
      }
    },
    methods: {
      ...mapMutationsAuth({ setRedirectUrlPath: 'setRedirectUrlPath' }),
      triggerRedirectPath() {
        if (
          !this.$route.meta.requiresAuth &&
          this.$route.name !== 'connexion'
        ) {
          this.setRedirectUrlPath(this.$route.fullPath);
        }
      },
      safirInfo() {
        return `<section class='w-84 py-4 px-2 mb-0'>
          <h6 class='m-0 mb-4'>Jinius Connect qu'est-ce que c'est ?</h6>
          <p class='m-0 text-sm text-justify'>Jinius Connect est le dispositif d'authentification sécurisé de la plateforme Jinius.</p>
          </section>`;
      }
    }
  };
</script>

<style lang="scss" scoped></style>
