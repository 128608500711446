<template>
  <section class="section__primary mb-16">
    <h1 class="section__title">Contact</h1>
    <div class="section__body">
      <div
        v-for="inputItem in inputs"
        v-bind:key="inputItem.name"
        class="form__item">
        <input-box
          class_name="no-box"
          v-bind:label="inputItem.placeholder"
          v-bind:name="inputItem.name"
          v-bind:placeholder="inputItem.placeholder"
          v-bind:maxlength="100"
          v-bind:value="entite_form[inputItem.name]"
          v-bind:not_required_label="inputItem.facultatif"
          v-on:input="set_attribute($event, [inputItem.name])"
          v-bind:disabled="disabled" />
      </div>
    </div>
  </section>
</template>
<script>
  import inputBox from '@/components/utils/input-box.vue';
  const inputs = [
    {
      name: 'telephone',
      placeholder: 'Téléphone (standard)',
      facultatif: true
    },
    {
      name: 'email',
      placeholder: 'E-mail contact',
      facultatif: true
    },
    {
      name: 'siteInternet',
      placeholder: 'Site internet',
      facultatif: true
    }
  ];

  export default {
    components: { inputBox },
    props: ['v$', 'set_attribute', 'entite_form', 'disabled'],
    computed: { inputs: () => inputs }
  };
</script>
<style lang="scss" scoped></style>
