<template>
  <div
    class="refonte-item-order"
    v-on:click="props.order.status !== 'deactivated' && props.on_click"
    v-bind:class="`${props.order.status === 'deactivated' ? 'item-deactivated' : ''} ${props.class}`">
    <div class="item-order-status">
      <refonte-pastille v-bind:status="props.order.status" />
    </div>
    <div class="item-order-infos">
      <div class="item-order-infos-name">
        {{ props.order.name }}
      </div>
      <div class="item-order-infos-entity">
        <div class="item-order-infos-entity-name">
          {{ props.order.entity_name }}
        </div>
        <div class="item-order-infos-entity-siret">
          {{ props.order.entity_siret }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import RefontePastille from '@/components/refonte/pastille/refonte-pastille.vue';

  export interface Order {
    status: string;
    name: string;
    entity_name: string;
    entity_siret: string;
  }

  const props = withDefaults(
    defineProps<{
      order: Order;
      on_click?: Function;
      class?: string;
    }>(),
    {
      class: ''
    }
  );
</script>

<style lang="scss" scoped>
  .refonte-item-order {
    @apply flex flex-grow cursor-pointer items-center self-stretch bg-refonte-white;
    gap: 2px;
    padding: 8px;
    border-radius: 8px;

    &:hover {
      @apply bg-refonte-pale-violet-2;
    }

    .item-order-status {
      @apply flex items-start justify-center self-stretch;
      min-width: 16px;
      width: 16px;
      padding: 4px 0;
    }
    .item-order-infos {
      @apply flex flex-grow flex-col items-start;
      gap: 2px;

      .item-order-infos-name {
        @apply self-stretch overflow-hidden text-ellipsis text-refonte-black;
        font-size: 13px;
        font-weight: 400;
        line-height: 125%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
      .item-order-infos-entity {
        @apply flex flex-grow flex-col items-start;
        gap: 2px;

        .item-order-infos-entity-name {
          @apply self-stretch overflow-hidden text-ellipsis text-refonte-black;
          font-size: 13px;
          font-weight: 400;
          line-height: 125%;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }
        .item-order-infos-entity-siret {
          @apply text-refonte-black-40;
          font-size: 12px;
          font-weight: 400;
          line-height: 120%;
        }
      }
    }

    &.item-deactivated {
      &:hover {
        @apply cursor-not-allowed bg-refonte-white;
      }

      .item-order-infos {
        .item-order-infos-name {
          @apply text-refonte_blue-200;
        }
      }
    }
  }
</style>
