<template>
  <div class="h-full w-full">
    <spinner
      v-if="loading"
      sizes="w-20 h-20"
      thickness="border-8" />

    <div
      v-if="!loading"
      class="flex h-full flex-col">
      <h1 class="section__title mb-8 mt-0">Mes ressources</h1>
    </div>
  </div>
</template>
<script>
  import spinner from '@/components/utils/spinner';

  export default {
    components: { spinner },
    props: ['props'],
    data: () => ({ loading: false })
  };
</script>
<style lang="scss" scoped></style>
