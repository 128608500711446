<template>
  <modal-box
    v-if="show_modal"
    v-on:close="closeModal"
    v-bind:width_class="'w-full max-w-xl'"
    overflow_body>
    <template v-slot:modal-header>
      <h1 class="text-xl font-bold text-core-900">
        Votre entreprise est déjà existante
      </h1>
    </template>

    <template v-slot:modal-body>
      <div class="flex w-full flex-wrap py-2 text-justify font-bold">
        <p>Une entreprise avec ce SIRET existe déjà.</p>
        <p>Les informations liées à cette entreprise vont être utilisées.</p>
      </div>
      <div class="flex w-full py-2 font-bold">Souhaitez-vous continuer ?</div>
    </template>
    <template v-slot:modal-footer>
      <div class="inline-flex w-full justify-center space-x-4">
        <button
          class="primary__button"
          v-on:click="submit">
          Oui
        </button>
        <button
          class="secondary__button"
          v-on:click.prevent.stop="closeModal">
          Non
        </button>
      </div>
    </template>
  </modal-box>
</template>
<script>
  import modalBox from '@/components/utils/modal-box.vue';

  export default {
    components: { modalBox },
    props: ['show_modal', 'onclose', 'submit'],
    data: () => ({}),
    methods: {
      closeModal() {
        this.onclose();
      }
    }
  };
</script>
<style lang="scss" scoped>
  .primary__button {
    @apply w-64 cursor-pointer rounded bg-jinius-dark-green px-5 py-3 text-center font-bold text-white shadow hover:bg-jinius-green;
  }

  .secondary__button {
    @apply w-64 cursor-pointer rounded bg-core-300 px-5 py-3 text-center font-bold text-core-700 shadow hover:bg-core-200;
  }
</style>
