<template>
  <modal-box
    v-if="show_modal"
    v-bind:width_class="'w-full max-w-screen-sm'"
    v-on:close="onclose">
    <template v-slot:modal-header>
      <h1 class="text-xl font-bold text-core-900">
        {{ content && content.title ? content.title : "Supprimer l'élément" }}
      </h1>
    </template>

    <template v-slot:modal-body>
      <div>
        {{
          content && content.description
            ? content.description
            : 'Vous êtes sur le point de supprimer cet élément. Voulez-vous continuer ?'
        }}
      </div>
    </template>

    <template v-slot:modal-footer>
      <div class="inline-flex w-full justify-center space-x-4">
        <button
          class="primary__button"
          v-on:click="onconfirm">
          Confirmer
        </button>
        <button
          class="secondary__button"
          v-on:click="close">
          Annuler
        </button>
      </div>
    </template>
  </modal-box>
</template>
<script>
  import modalBox from '@/components/utils/modal-box.vue';
  export default {
    components: { modalBox },
    props: ['show_modal', 'onclose', 'onconfirm', 'content'],
    data: () => ({}),
    methods: {
      close() {
        this.onclose();
      }
    }
  };
</script>
<style lang="scss" scoped>
  .primary__button {
    @apply w-64 cursor-pointer rounded bg-primary-900 px-5 py-3 text-center font-bold text-white shadow hover:bg-primary-800;
  }
  .secondary__button {
    @apply w-64 cursor-pointer rounded bg-core-300 px-5 py-3 text-center font-bold text-core-700 shadow hover:bg-core-200;
  }
</style>
