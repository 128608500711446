<template>
  <div
    class="session-card group"
    v-bind:class="[{ 'session-card--head-list cursor-pointer': parcours }]"
    v-on:click="cardAction">
    <div class="card__container">
      <div
        class="card__container--anchor"
        v-bind:class="type_anchor_color(session.type[0].id)"></div>

      <div class="container__content">
        <div class="content__type">
          <div
            v-for="{ id, label } in session.type"
            v-bind:key="id"
            class="type__label"
            v-bind:class="type_bg_color(id)">
            <span>{{ label }}</span>
          </div>
        </div>

        <div class="content__description">
          <div class="description__left">
            <div class="content__title">
              <font-awesome-icon
                v-if="parcours"
                v-bind:icon="
                  collapsed ? 'chevron-circle-right' : 'chevron-circle-down'
                "
                class="mr-2.5"
                size="1x" />
              <span class="content__title__text">
                <span data_id="titre">
                  {{ session.titre }}
                </span>
              </span>
            </div>

            <div class="flex flex-col space-y-1">
              <div
                v-if="
                  (session.sessionDates && !session.plateformeDureeAcces) ||
                  (session.moduleElearning &&
                    session.moduleElearning.accessible)
                ">
                <span
                  class="inline-flex w-4 items-center justify-center align-middle">
                  <font-awesome-icon
                    v-bind:icon="['far', 'calendar-alt']"
                    size="1x" />
                </span>

                <span
                  v-if="session.sessionDates && session.sessionDates.length"
                  class="mx-2">
                  <em class="text-accent-500"
                    >Date{{
                      session.sessionDates.length > 1 ? 's' : null
                    }}&nbsp;:
                  </em>
                  {{ displayDates(session.sessionDates).join(', ') }}
                </span>

                <span
                  v-if="
                    !session.sessionDates &&
                    session.dateDebut &&
                    session.dateFin
                  "
                  class="mx-2">
                  {{
                    `Accessible du ${session.dateDebut} ${session.dateFin ? `au ${session.dateFin}` : ''}`
                  }}
                </span>

                <span
                  v-if="
                    session.plateformeDureeAcces &&
                    session.moduleElearning &&
                    session.moduleElearning.accessible
                  ">
                  {{
                    `Accessible pendant ${session.plateformeDureeAcces} jour${session.plateformeDureeAcces > 1 ? 's' : ''}`
                  }}

                  {{
                    session.moduleElearning.dateDebutAcces
                      ? `(du ${session.moduleElearning.dateDebutAcces} ${session.moduleElearning.dateFinAcces ? `au ${session.moduleElearning.dateFinAcces}` : ''})`
                      : ''
                  }}
                </span>
              </div>

              <div
                v-if="(session.lieu || session.lieuIntra) && !parcours"
                class="flex items-center">
                <span
                  class="inline-flex w-4 items-center justify-center align-middle">
                  <font-awesome-icon
                    icon="map-marker-alt"
                    size="1x" />
                </span>
                <span class="ml-2.5 mr-1.5">
                  <em class="text-accent-500">Lieu&nbsp;: </em>
                  <span data_id="lieu">
                    {{ session.lieu || session.lieuIntra }}
                  </span>
                </span>
                <span
                  v-if="session.mode && session.mode.id === 2"
                  class="bg-jinius-dark-green px-1.5 py-0.5 align-text-top text-xs leading-none text-white shadow-sm">
                  {{ session.mode.label }}
                </span>
              </div>

              <div v-if="session.organisateur && !parcours">
                <span
                  class="inline-flex w-4 items-center justify-center align-middle">
                  <font-awesome-icon
                    icon="tag"
                    size="1x" />
                </span>

                <span class="mx-2 align-middle">
                  <em class="text-accent-500">Organisateur&nbsp;: </em>
                  <span data_id="organisateur">
                    {{ session.organisateur }}
                  </span>
                </span>
              </div>

              <div v-if="session.presence && !parcours && !session.isElearning">
                <span
                  class="inline-flex w-4 items-center justify-center align-middle">
                  <font-awesome-icon
                    icon="graduation-cap"
                    size="1x" />
                </span>

                <span class="mx-2 align-middle">
                  <em class="text-accent-500">Présence&nbsp;: </em>
                  <span data_id="presence">
                    {{ session.presence }}
                  </span>
                </span>
              </div>
              <div>
                <span
                  class="inline-flex w-4 items-center justify-center align-middle">
                  <font-awesome-icon
                    icon="envelope"
                    size="1x" />
                </span>

                <span class="mx-2 align-middle">
                  <em class="text-accent-500">Convocation envoyée&nbsp;: </em>
                  <span data_id="mailConvocEnvoye">
                    {{ session.mailConvocEnvoye ? 'oui' : 'non' }}
                  </span>
                </span>
              </div>
              <div v-if="session.produitLienElearningTitre">
                <span
                  class="inline-flex w-4 items-center justify-center align-middle">
                  <font-awesome-icon
                    icon="chalkboard"
                    size="1x" />
                </span>

                <span class="mx-2 align-middle">
                  <em class="text-accent-500">E-learning&nbsp;: </em>
                  <span data_id="produitLienElearningTitre">
                    {{ session.produitLienElearningTitre }}
                  </span>
                </span>
              </div>
              <div
                v-if="
                  session.produitLienElearningTitre &&
                  session.produitLienElearningPrerequisPourcentage
                ">
                <span
                  class="inline-flex w-4 items-center justify-center align-middle">
                  <font-awesome-icon
                    icon="info"
                    size="1x" />
                </span>

                <span class="mx-2 align-middle">
                  <em class="text-accent-500"
                    >Avancement min. requis&nbsp;:
                  </em>
                  <span data_id="organisateur">
                    {{
                      session.produitLienElearningPrerequisPourcentage
                    }}&nbsp;%
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div class="description__right">
            <div>
              <div
                v-if="session.statutId && !parcours"
                class="mb-3 flex items-center justify-center space-x-2 rounded px-3 py-2 leading-none text-core-100 shadow"
                v-bind:class="
                  map_statut_session_stagiaire_colors[session.statutId]
                    ? map_statut_session_stagiaire_colors[session.statutId]
                        .color
                    : map_statut_session_stagiaire_colors[0]
                ">
                <font-awesome-icon
                  v-if="map_statut_session_stagiaire_colors[session.statutId]"
                  v-bind:icon="
                    map_statut_session_stagiaire_colors[session.statutId].icon
                  "
                  size="sm" />
                <span class="px-0.5">
                  {{ map_statut_session_stagiaire_labels[session.statutId] }}
                </span>
              </div>
            </div>

            <div v-if="session.noteValidationAcquis">
              <span class="mt-2 text-base">
                <em class="text-accent-500"
                  >Note de validation des acquis&nbsp;:
                </em>
                <span data_id="noteValidation">
                  {{ session.noteValidationAcquis }}
                </span>
              </span>
            </div>

            <div
              v-if="session.produitLienElearningId && !parcours"
              class="flex justify-end">
              <div class="progress-wrapper">
                <div
                  class="mb-1 flex w-full items-center justify-between text-base">
                  <em class="text-accent-500">Avancement : </em>
                  <span data_id="noteValidation">
                    {{ session.avancement ? session.avancement : 0 }}%
                  </span>
                </div>
                <div class="progress-container">
                  <div
                    class="progress-bar"
                    v-bind:style="`width: ${session.avancement ? session.avancement : 0}%;`"></div>
                </div>
              </div>
            </div>

            <div>
              <div
                v-if="
                  session.catalogueReference && session.catalogueUrl && !produit
                "
                v-on:click="
                  () =>
                    goToFiche(session.catalogueReference, session.catalogueUrl)
                "
                class="mt-4 inline-flex cursor-pointer flex-wrap items-center justify-end text-base text-core-500 hover:text-core-600">
                <font-awesome-icon icon="eye" />
                <span class="ml-2.5">Voir la fiche pédagogique</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { convertStringToDate } from '@/assets/data/ag_grid_fields_map';
  import {
    map_statut_session_stagiaire_colors,
    map_statut_session_stagiaire_labels,
    map_type_colors
  } from '@/assets/data/data_maps.js';
  import dates_helpers from '@/mixin/dates_helpers';
  import http_functions from '@/mixin/http_functions';

  export default {
    mixins: [dates_helpers, http_functions],
    props: {
      session: {
        type: Object,
        default: null
      },
      parcours: {
        type: Boolean,
        default: false
      },
      collapsed: {
        type: Boolean,
        default: null
      },
      toggle_collapse: {
        type: Function,
        default: null
      },
      produit: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        map_type_colors,
        map_statut_session_stagiaire_colors,
        map_statut_session_stagiaire_labels
      };
    },
    computed: {
      getFormationStatusKey() {
        const key = ['isValid', 'isNotStarted', 'isInProgress'].find(
          (key) => this.session.formationStatut[key] === true
        );

        return key;
      }
    },
    methods: {
      type_anchor_color(id) {
        return `border-${map_type_colors[id]}`;
      },
      type_bg_color(id) {
        return `bg-${map_type_colors[id]}`;
      },
      goToFiche(reference, url) {
        if (reference && url) {
          window.open(`${url}/fiche/${reference}`);
        }
      },
      displayDates(dates) {
        const sortedDates = dates
          .slice()
          .sort(
            (a, b) =>
              convertStringToDate(a.date).getTime() -
              convertStringToDate(b.date).getTime()
          );

        return sortedDates.map(
          (date) =>
            `${date.date}${date.matin && !date.aprem ? ' (matin)' : ''}${!date.matin && date.aprem ? ' (après-midi)' : ''}`
        );
      },
      cardAction() {
        if (this.toggle_collapse) {
          this.toggle_collapse();
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/css/utils/mixins.scss';

  .session-card {
    @apply mb-4 flex overflow-hidden rounded-lg bg-white shadow transition;

    &.session-card--head-list {
      @apply shadow-lg;
      &:hover .content__title {
        @apply text-primary-700;
      }
    }

    .card__container {
      @apply relative flex w-full items-center;

      .card__container--anchor {
        @apply absolute left-0 top-0 z-10 h-10 w-12 border-l-8 border-t-8;
      }

      .container__content {
        @apply relative h-full w-full py-5 pl-4 pr-3 tracking-tight shadow;

        .content__type {
          @apply absolute right-0 top-0 mr-2 mt-2 flex space-x-2;
          .type__label {
            @apply rounded-full px-3 py-0.5 text-xs font-bold lowercase text-core-100 shadow first-letter:capitalize;
          }
        }

        .content__title {
          @apply mb-4 text-xl font-bold leading-tight;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          @supports (-webkit-line-clamp: 2) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }

        .content__description {
          @apply mt-6 flex flex-wrap items-end justify-between text-sm md:mt-4 md:flex-nowrap;

          .description__left {
            @apply w-full flex-grow self-start pl-5 pr-3;
          }

          .description__right {
            @apply mt-6 flex w-full flex-wrap px-4 md:w-120 md:justify-end;
            > div {
              @apply flex w-full justify-end;
            }

            .progress-wrapper {
              @apply mt-2 flex w-64 flex-col;

              .progress-container {
                @apply h-6 w-full rounded bg-core-300;

                .progress-bar {
                  @apply h-full rounded bg-blue-600;
                }
              }
            }
          }
        }
      }
    }
  }
</style>
