<template>
  <div
    class="refonte-side-menu-links-category"
    v-bind:class="props.class">
    <div class="side-menu-links-category-title">
      <span class="side-menu-links-category-title-full">{{ props.title }}</span>
      <span class="side-menu-links-category-title-reduced">{{
        props.reduced_title
      }}</span>
    </div>
    <div class="side-menu-links-category-content">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
  const props = withDefaults(
    defineProps<{
      title: string;
      reduced_title: string;
      class?: string;
    }>(),
    {
      class: ''
    }
  );
</script>

<style lang="scss" scoped>
  .refonte-side-menu-links-category {
    @apply flex flex-col items-start self-stretch;
    gap: 4px;

    .side-menu-links-category-title {
      @apply flex items-center self-stretch whitespace-nowrap text-refonte-white-30;
      height: 20px;
      gap: 10px;
      padding: 0 11px;
      font-size: 12px;
      line-height: 120%;
      font-weight: 500;
      transition: padding 0.2s;

      .side-menu-links-category-title-reduced {
        @apply hidden;
      }
    }
    .side-menu-links-category-content {
      @apply flex flex-col items-center self-stretch;
    }
  }
  @media (min-width: 768px) {
    .side-menu--reduced {
      .refonte-side-menu-links-category {
        .side-menu-links-category-title {
          @apply justify-center;
          padding: 0;

          .side-menu-links-category-title-full {
            @apply hidden;
          }
          .side-menu-links-category-title-reduced {
            @apply inline;
          }
        }
      }
    }
  }
  @media (max-width: 767.98px) {
    .refonte-side-menu-links-category {
      gap: 0;

      .side-menu-links-category-title {
        height: auto;
      }
    }
  }
</style>
