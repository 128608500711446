<template>
  <details-view
    v-if="!loading"
    v-bind:session="session"
    espace="participant"
    v-bind:go_to_details="goToDetails"
    v-bind:fetch_session="fetchFormation"
    v-bind:refresh_session="refreshFormation"
    v-bind:open_elearning_module="openElearningModule"
    v-bind:ressource_base_url="`participant/commande/${commande_id}/formation/${reference}`"
    convention_base_url="participant/convention"
    back_base_url="participant/session" />
  <spinner
    sizes="w-20 h-20"
    v-else
    thickness="border-8" />
</template>

<script>
  import spinner from '@/components/utils/spinner';
  import { API } from '@/http-common';
  import detailsView from '@/views/_espace/details';

  export default {
    components: {
      detailsView,
      spinner
    },
    props: {},
    data() {
      return {
        loading: false,
        session: {}
      };
    },
    computed: {
      reference() {
        return this.$route.query.reference;
      },
      commande_id() {
        return this.$route.query.commande;
      },
      fiche() {
        return this.$route.query.fiche;
      }
    },
    watch: {
      async reference() {
        // Nécessaire dans un sous produit de parcours
        await this.fetchFormation();
      }
    },
    async created() {
      await this.fetchFormation();
    },
    methods: {
      async fetchFormation() {
        this.loading = true;
        await this.refreshFormation();
        this.loading = false;
      },
      async refreshFormation() {
        const response = await API.get(
          `participant/commande/${this.commande_id}/formation/${this.reference}/details`,
          this.$header_skip_redirection
        );

        if (response && response.data) {
          this.session = response.data;
        }
      },
      async openElearningModule() {
        const response = await API.get(
          `participant/commande/${this.commande_id}/formation/${this.reference}/elearning`,
          this.$header_skip_redirection
        );

        if (response && response.data && response.data.url) {
          window.open(response.data.url);
        }
      },
      goToDetails(reference) {
        if (reference) {
          this.$router.push(
            `/participant/details?reference=${reference}&commande=${this.commande_id}`
          );
        }
      }
    }
  };
</script>
<style lang="scss" scoped></style>
