<template>
  <modal-box
    v-if="modal_show"
    v-bind:width_class="'w-full max-w-screen-md'"
    v-on:close="onclose">
    <template v-slot:modal-header>
      <h1 class="text-xl font-bold text-core-900">Avertissement</h1>
    </template>

    <template v-slot:modal-body>
      <div
        class="my-8 flex w-full items-center justify-center px-4 text-center font-bold">
        Attention : vous n'avez pas enregistré vos dernières modifications.<br />Êtes-vous
        sûr de vouloir quitter cette page ?
      </div>
    </template>
    <template v-slot:modal-footer>
      <div class="inline-flex w-full justify-center space-x-4">
        <button
          class="primary__button"
          type="button"
          v-on:click="navigate">
          Quitter la page
        </button>
        <button
          class="secondary__button"
          type="button"
          v-on:click="save">
          Enregistrer les modifications
        </button>
      </div>
    </template>
  </modal-box>
</template>
<script>
  import modalBox from '@/components/utils/modal-box.vue';
  export default {
    components: { modalBox },
    props: ['modal_show', 'onclose', 'navigate', 'save']
  };
</script>
<style lang="scss" scoped>
  .primary__button {
    @apply cursor-pointer rounded bg-jinius-red px-8 py-3 text-center text-lg text-white shadow transition hover:opacity-75;
  }
  .secondary__button {
    @apply cursor-pointer rounded bg-jinius-dark-green px-8 py-3 text-center text-lg text-white shadow transition hover:bg-jinius-green;
  }
</style>
