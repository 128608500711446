<template>
  <div
    class="update-cell"
    v-if="!params.data?.readonly">
    <button
      v-on:click="updateAction"
      class="update-cell__button">
      <font-awesome-icon
        icon="pen"
        size="1x" />
    </button>
  </div>
</template>
<script>
  export default {
    methods: {
      updateAction(e) {
        e.preventDefault();
        this.params.context.componentParent.updateAction(
          this.params.node.rowIndex,
          this.params.node.data
        );
      }
    }
  };
</script>
<style lang="scss" scoped>
  .update-cell {
    @apply flex w-full items-center justify-center;
    .update-cell__button {
      @apply w-full text-core-500 hover:text-jinius-green;
    }
  }
</style>
