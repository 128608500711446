<template>
  <section class="section__primary mb-16">
    <interlocutors-modal
      v-bind:modal_show="interlocutorsModal"
      v-bind:onclose="closeInterlocutorsModal" />
    <h1 class="section__title">Information d'inscription</h1>
    <div class="section__body relative">
      <div class="mb-8 lg:absolute lg:right-0 lg:top-10 lg:mb-0">
        <a
          target="_blank"
          href=""
          v-on:click.prevent="openInterlocutorsModal"
          class="inline-flex w-full items-center justify-center rounded border border-accent-200 bg-white px-3 pb-1.5 pt-2 text-xs font-bold leading-none text-jinius-dark-green shadow-sm transition duration-150 ease-in-out hover:bg-accent-100 active:shadow-inner sm:w-auto sm:px-6 sm:text-base lg:w-full">
          <font-awesome-icon icon="eye" />
          <span class="ml-2 pr-1.5">
            Voir la liste des personnes autorisées
          </span>
        </a>
      </div>
      <div class="form__item">
        <select-box
          v-bind:value="
            droitsCommande.find(
              (item) => entite_form.commandeProfilId === item.id
            )
          "
          v-on:input="(value) => set_attribute(value.id, 'commandeProfilId')"
          v-bind:categories="droitsCommande"
          label="Qui a le droit de passer commande au nom de la société ? "
          no_capitalize_label
          placeholder="Sélectionnez un droit"
          required
          name="commandeProfilId"
          tracking="id"
          class_name="no-box"
          v-bind:disabled="disabled" />
      </div>
    </div>
  </section>
</template>
<script>
  import { droitsCommande } from '@/assets/data/data_form';
  import selectBox from '@/components/utils/select-box.vue';
  import InterlocutorsModal from '@/views/_espace/_entreprise/_entreprise/_informations/interlocutors-modal';
  export default {
    components: {
      InterlocutorsModal,
      selectBox
    },
    props: ['v$', 'set_attribute', 'entite_form', 'disabled'],
    data: () => ({ interlocutorsModal: false }),
    computed: { droitsCommande: () => droitsCommande },
    methods: {
      openInterlocutorsModal() {
        this.interlocutorsModal = true;
      },
      closeInterlocutorsModal() {
        this.interlocutorsModal = false;
      }
    }
  };
</script>
<style lang="scss" scoped></style>
