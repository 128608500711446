<template>
  <input
    v-bind:ref="'input'"
    v-model="value"
    class="ag-input-field-input ag-text-field-input"
    v-on:keypress="onKeyPress($event)" />
</template>
<script>
  export default {
    data() {
      return { value: '' };
    },
    async mounted() {
      await this.$nextTick();
      if (this.$refs.input) {
        this.$refs.input.focus();
      }
    },
    methods: {
      getValue() {
        return this.value;
      },
      onKeyPress(event) {
        const keyCode = event.keyCode ? event.keyCode : event.which;

        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
          // 46 is dot
          event.preventDefault();
        }
        if (keyCode === 46 && this.value && this.value.includes('.')) {
          event.preventDefault();
        }
        const selection = window.getSelection()
          ? window.getSelection().toString()
          : '';
        const max = this.params && this.params.max;

        if (max && this.value + event.key > max) {
          event.preventDefault();
          this.value = max;
        }
        const maxLength = this.params && this.params.maxLength;

        if (maxLength && this.value.length + 1 - selection.length > maxLength) {
          event.preventDefault();
        }
      }
    }
  };
</script>
<style lang="scss" scoped></style>
