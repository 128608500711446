//import doughnutlabel from 'chartjs-plugin-doughnutlabel';
import { Doughnut } from 'vue-chartjs';

import { Chart } from 'chart.js';
//Chart.register(doughnutlabel);
Chart.register();

export default {
    extends: Doughnut,
    props: ['data', 'options'],
    mounted() {
        // this.chartData is created in the mixin.
        // If you want to pass options please create a local options object
        this.renderChart(this.data, {
            responsive: true,
            ...this.options
        });
    }
};
