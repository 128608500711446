<template>
  <div class="mentions-legales_content">
    <div class="mentions-legales_content__title">RÉALISATION ÉDITORIALE</div>
    <div class="mentions-legales_content__block">
      Le site public
      <a
        target="_blank"
        class="cursor-pointer underline"
        href="https://connect.jinius.fr/"
        >https://connect.jinius.fr/</a
      >
      est un outil de gestion de la formation à destination des professions
      d’expert-comptable et de commissaire aux comptes édité par le Groupement
      d’Intérêt Economique (GIE) SAFIR (ci-après « l’Editeur »).
    </div>
    <div class="mentions-legales_content__block">
      Cet outil de formation est mis à disposition des entités juridiques afin
      qu’elles puissent proposer leurs formations aux utilisateurs.
    </div>
    <div class="mentions-legales_content__block">
      Ces entités juridiques sont :
      <ul>
        <li>
          le Centre de Formation de la Profession Comptable (CFPC) dont le siège
          social est situé Immeuble Le Jour - 200-216 rue Raymond Losserand -
          75680 Paris cedex 14,
        </li>
        <li>
          CNCC services, dont le siège social est situé Immeuble Le Jour -
          200-216 rue Raymond Losserand - 75680 Paris cedex 14,
        </li>
        <li>
          les Instituts régionaux de formations (IRF) des Conseils régionaux de
          l’ordre des experts-comptables (CROEC),
        </li>
        <li>les Compagnies régionales des commissaires aux comptes.</li>
      </ul>
    </div>
    <div class="mentions-legales_content__block">
      Adresse du siège social :<br />
      SAFIR<br />
      200-216 Rue Raymond Losserand<br />
      75680 Paris cedex 14<br />
      Numéro de téléphone : + 33 (0)1 59 03 12 61<br />
      E-mail : assistance@jinius.fr
    </div>

    <div class="mentions-legales_content__title">HÉBERGEUR</div>
    <div class="mentions-legales_content__block">
      Le présent site est hébergé par : <br />
      LINK BY NET / ACCENTURE<br />
      118 AVENUE DE France 75013 PARIS<br />
      SIREN 732075312<br />
      RCS PARIS B 732 075 312
    </div>
    <div class="mentions-legales_content__title">DROITS DE REPRODUCTION</div>
    <div class="mentions-legales_content__block">
      Tous les contenus présents sur le site sont la propriété du GIE SAFIR et
      sont donc couverts par le droit d'auteur.
      <br />
      Les marques du GIE SAFIR ainsi que les logos figurant sur le site ont été
      déposées auprès de l’INPI ou sont la propriété de ses partenaires.
      <br />
      La reproduction ou la représentation de tout ou partie de ce site, sur
      quelque support que ce soit, est formellement interdite sauf accord
      préalable écrit du Directeur de la publication.
      <br />
      En cas d’autorisation par le Directeur de la publication, la source
      www.connect.jinius.fr et la date de la copie devront être indiquées.
    </div>
    <div class="mentions-legales_content__title">RESPONSABILITE</div>
    <div class="mentions-legales_content__block">
      Les documents, informations et services diffusés sur ce site sont fournis
      en l'état sans aucune garantie expresse ou tacite de quelque sorte que ce
      soit. L'Editeur du site se réserve le droit de modifier ou de corriger le
      contenu de son site à tout moment, sans préavis.
    </div>
    <div class="mentions-legales_content__block">
      Les documents, informations et services diffusés sur ce site sont fournis
      en l'état sans aucune garantie expresse ou tacite de quelque sorte que ce
      soit. L'Editeur du site se réserve le droit de modifier ou de corriger le
      contenu de son site à tout moment, sans préavis.
    </div>
    <div class="mentions-legales_content__block">
      La responsabilité de l'Editeur, à raison d'un préjudice résultant de
      l'utilisation d'un service édité par une entité tierce, est expressément
      exclue.
    </div>
    <div class="mentions-legales_content__block">
      L'Editeur ne saurait être tenu responsable des dommages qui pourraient
      résulter de l'accès ou de l'utilisation du site ou des informations qui y
      figurent, quelle qu'en soit la nature. L'Editeur décline toute
      responsabilité concernant l'opportunité des décisions prises par les
      utilisateurs sur la seule foi des informations publiées sur le site, et
      les modalités d'exécution desdites décisions.
    </div>
    <div class="mentions-legales_content__block">
      L'utilisateur du site reconnaît que, nonobstant l’ensemble des moyens
      techniques mis en œuvre par l’Editeur en cause, le réseau Internet
      présente des spécificités techniques ne mettant pas en mesure l’Editeur de
      garantir la continuité absolue de l’accès aux services. L’utilisateur
      reconnaît que les informations et services proposés par l’Editeur pourront
      être interrompus en raison de cas de force majeure, de cas indépendants de
      la volonté dudit Editeur ou de faits ne relevant pas de sa responsabilité.
      Dans ces cas, la responsabilité de l’Editeur ne pourra être recherchée.
    </div>
    <div class="mentions-legales_content__block">
      Il appartient à l'utilisateur de prendre toutes les mesures appropriées de
      façon à protéger ses données ainsi que son équipement, contre la
      contamination par des virus comme des tentatives d’intrusion dans votre
      système informatique par des tiers. L’utilisateur reconnaît que son
      équipement est connecté au réseau Internet sous son entière responsabilité
      et qu’en conséquence l’Editeur n’est en rien responsable de tout dommage
      pouvant survenir durant sa connexion.
    </div>
    <div class="mentions-legales_content__block">
      En conséquence, l’Editeur du site décline toute responsabilité en cas de
      dommages subis à raison notamment de la perte, de la détérioration ou de
      l'altération de fichiers, de la transmission de virus qui pourraient
      infecter l'équipement informatique de l'utilisateur ou tout autre bien à
      l'occasion de la connexion et/ou de la consultation et/ou de l'utilisation
      du portail.
    </div>

    <div class="mentions-legales_content__title">
      PROTECTION DES DONNEES PERSONNELLES
    </div>
    <div class="mentions-legales_content__block">
      Le GIE SAFIR et les entités juridiques précitées sont engagées dans une
      démarche continue de protection des données personnelles de ses
      internautes, en conformité avec la Loi Informatique et Libertés du 6
      janvier 1978 modifiée et du règlement n° 2016/679, dit règlement général
      sur la protection des données. Les traitements sur les données à caractère
      personnel réalisés sont précisés dans la politique de données personnelles
      accessible
      <span
        class="cursor-pointer underline"
        v-on:click="(e) => scroll_to_section(e, 'politique-rgpd')"
        >ici</span
      >
    </div>

    <div class="mentions-legales_content__title">FONCTIONNEMENT</div>
    <div class="mentions-legales_content__block">
      Pour toute question relative au site internet du GIE SAFIR, l’internaute
      peut adresser un message au webmaster : assistance@jinius.fr
    </div>
  </div>
</template>

<script>
  export default { props: { scroll_to_section: Function } };
</script>

<style lang="scss" scoped>
  .mentions-legales_content {
    @apply w-full lg:p-6;
  }
  .mentions-legales_content__block {
    @apply mb-4 mt-8 pl-3 text-justify text-xl first-letter:uppercase;
  }
  .mentions-legales_content__title {
    @apply mb-4 mt-8 text-justify text-xl font-bold first-letter:uppercase;
  }
</style>
