const search_filter_def = {
    floatingFilter: true,
    filter: true
};

export const convertStringToDate = (string) => {
    const dateParts = string.split('/');
    const day = Number(dateParts[0]);
    const month = Number(dateParts[1]) - 1;
    const year = Number(dateParts[2]);

    return new Date(year, month, day);
};

const ag_grid_fields = {
    attestationDisponible: {
        cellRenderer: 'textCenterCellRenderer',
        minWidth: 120,
        ...search_filter_def
    },
    pifDisponible: {
        cellRenderer: 'textCenterCellRenderer',
        minWidth: 120,
        ...search_filter_def
    },
    testValidationAcquisDisponible: {
        cellRenderer: 'textCenterCellRenderer',
        minWidth: 200,
        ...search_filter_def
    },
    testValidationAcquisRealise: {
        cellRenderer: 'textCenterCellRenderer',
        minWidth: 200,
        ...search_filter_def
    },
    examenDisponible: {
        cellRenderer: 'textCenterCellRenderer',
        minWidth: 200,
        ...search_filter_def
    },
    examenRealise: {
        cellRenderer: 'textCenterCellRenderer',
        minWidth: 200,
        ...search_filter_def
    },
    satisfactionRealise: {
        cellRenderer: 'textCenterCellRenderer',
        minWidth: 200,
        ...search_filter_def
    },
    conventionDisponible: {
        cellRenderer: 'textCenterCellRenderer',
        minWidth: 200,
        ...search_filter_def
    },
    conventionsIndividuellesDisponible: {
        cellRenderer: 'textCenterCellRenderer',
        minWidth: 200,
        ...search_filter_def
    },

    participantStatut: {
        cellRenderer: 'textCellRenderer',
        minWidth: 200,
        ...search_filter_def
    },
    commandeStatut: {
        cellRenderer: 'textCellRenderer',
        minWidth: 200,
        ...search_filter_def
    },
    sessionStatut: {
        cellRenderer: 'textCellRenderer',
        minWidth: 200,
        ...search_filter_def
    },
    formateurStatut: {
        cellRenderer: 'textCellRenderer',
        minWidth: 200,
        ...search_filter_def
    },
    elearningStatut: {
        cellRenderer: 'textCellRenderer',
        minWidth: 100,
        ...search_filter_def
    },
    dateDebutAcces: {
        minWidth: 100,
        cellRenderer: 'textCellRenderer',
        ...search_filter_def
    },
    dateFinAcces: {
        minWidth: 100,
        cellRenderer: 'textCellRenderer',
        ...search_filter_def
    },
    sessionDateDebut: {
        minWidth: 100,
        cellRenderer: 'textCellRenderer',
        ...search_filter_def
    },
    sessionDateFin: {
        minWidth: 100,
        cellRenderer: 'textCellRenderer',
        ...search_filter_def
    },
    cycleStageCAC: {
        minWidth: 230,
        cellRenderer: 'textCellRenderer',
        ...search_filter_def
    },
    formationLibre: {
        minWidth: 140,
        cellRenderer: 'textCellRenderer',
        ...search_filter_def
    },
    dateSession: {
        minWidth: 120,
        cellRenderer: 'textCellRenderer',
        ...search_filter_def
    },
    dateCreation: {
        minWidth: 120,
        cellRenderer: 'textCenterCellRenderer',
        ...search_filter_def
    },
    montantTtc: {
        minWidth: 120,
        cellRenderer: 'amountCellRenderer',
        filter: 'agNumberColumnFilter',
        floatingFilter: true
    },
    montantPayeTtc: {
        minWidth: 120,
        cellRenderer: 'amountCellRenderer',
        filter: 'agNumberColumnFilter',
        floatingFilter: true
    },
    reference_treedata: {
        minWidth: 140,
        valueGetter: (params) => {
            return params.data?.reference;
        },
        ...search_filter_def
    },
    reference: {
        minWidth: 120,
        cellRenderer: 'textCellRenderer',
        ...search_filter_def
    },
    commandesReference: {
        minWidth: 120,
        cellRenderer: 'textCellRenderer',
        ...search_filter_def
    },
    vendeur: {
        minWidth: 130,
        cellRenderer: 'textCellRenderer',
        ...search_filter_def
    },
    payeur: {
        minWidth: 130,
        cellRenderer: 'textCellRenderer',
        ...search_filter_def
    },
    titre: {
        minWidth: 250,
        cellRenderer: 'titreCellRenderer',
        ...search_filter_def
    },
    role: {
        minWidth: 250,
        cellRenderer: 'titreCellRenderer',
        ...search_filter_def
    },
    campagne: {
        minWidth: 250,
        cellRenderer: 'titreCellRenderer',
        ...search_filter_def
    },
    formation: {
        minWidth: 250,
        cellRenderer: 'titreCellRenderer',
        ...search_filter_def
    },
    navetteNom: {
        minWidth: 150,
        cellRenderer: 'textCenterCellRenderer',
        ...search_filter_def
    },
    familleStage: {
        minWidth: 150,
        cellRenderer: 'textCenterCellRenderer',
        ...search_filter_def
    },
    familleFormationContinue: {
        minWidth: 150,
        cellRenderer: 'textCenterCellRenderer',
        ...search_filter_def
    },
    famillePreparationExamen: {
        minWidth: 150,
        cellRenderer: 'textCenterCellRenderer',
        ...search_filter_def
    },
    parcoursTitre: {
        minWidth: 130,
        cellRenderer: 'textCellRenderer',
        ...search_filter_def
    },
    type: {
        minWidth: 120,
        cellRenderer: 'typeCellRenderer',
        ...search_filter_def
    },
    mode: {
        minWidth: 120,
        cellRenderer: 'textCenterCellRenderer',
        ...search_filter_def
    },
    organisateur: {
        minWidth: 180,
        cellRenderer: 'textCellRenderer',
        ...search_filter_def
    },
    sessionDates: {
        minWidth: 200,
        cellRenderer: 'dateCellRenderer',
        floatingFilter: true,
        filter: 'agDateColumnFilter',
        filterParams: {
            inRangeInclusive: true,
            comparator: (text, sessionDates) => {
                if (sessionDates.length <= 0) {
                    return -1;
                }
                const dateA = text.getTime();
                const dates = sessionDates.map((sessionDate) =>
                    convertStringToDate(sessionDate.date).getTime()
                );

                if (dates.includes(dateA)) {
                    return 0;
                }
                if (dates.some((date) => dateA < date)) {
                    return 1;
                }
                if (dates.some((date) => dateA > date)) {
                    return -1;
                }

                return 0;
            }
        },
        comparator: (valueA, valueB) => {
            if (valueA[0] && valueB[0]) {
                const valueATime = convertStringToDate(
                    valueA[0].date
                ).getTime();
                const valueBTime = convertStringToDate(
                    valueB[0].date
                ).getTime();

                if (valueATime === valueBTime) {
                    return 0;
                }

                return valueATime > valueBTime ? 1 : -1;
            }

            return -1;
        }
    },
    effectif: {
        minWidth: 80,
        cellRenderer: 'textCenterCellRenderer',
        cellRendererParams: {
            defaultNATooltip:
                "Veuillez contacter l'organisateur de la session pour connaitre cette information",
            defaultNAValue: 'NC'
        },
        ...search_filter_def
    },
    lieu: {
        minWidth: 120,
        cellRenderer: 'locationCellRenderer',
        ...search_filter_def
    },
    lieuNom: {
        minWidth: 120,
        cellRenderer: 'locationCellRenderer',
        ...search_filter_def
    },
    statut: {
        minWidth: 120,
        cellRenderer: 'statutCellRenderer',
        ...search_filter_def
    },
    convention: {
        minWidth: 120,
        cellRenderer: 'buttonCellRenderer',
        ...search_filter_def
    },
    pif: {
        minWidth: 120,
        cellRenderer: 'buttonCellRenderer',
        ...search_filter_def
    },
    details: {
        minWidth: 120,
        cellRenderer: 'buttonCellRenderer'
    },
    support: {
        minWidth: 130,
        cellRenderer: 'buttonCellRenderer'
    },
    appreciation: {
        minWidth: 130,
        cellRenderer: 'buttonCellRenderer',
        ...search_filter_def
    },
    attestation: {
        minWidth: 130,
        cellRenderer: 'buttonCellRenderer',
        ...search_filter_def
    },
    convocation: {
        minWidth: 130,
        cellRenderer: 'buttonCellRenderer'
    },
    mailConvocEnvoye: {
        minWidth: 120,
        cellRenderer: 'textCenterCellRenderer',
        ...search_filter_def
    },
    testValidationAcquisReussi: {
        minWidth: 120,
        cellRenderer: 'textCenterCellRenderer',
        ...search_filter_def
    },
    presence: {
        minWidth: 120,
        cellRenderer: 'textCenterCellRenderer',
        ...search_filter_def
    },
    avancement: {
        minWidth: 120,
        cellRenderer: 'textCenterCellRenderer',
        ...search_filter_def
    },
    produitLienElearningPrerequisPourcentage: {
        minWidth: 120,
        cellRenderer: 'textCenterCellRenderer',
        ...search_filter_def
    },
    postuler: { minWidth: 150 },
    annuler: { minWidth: 150 },
    poste: {
        minWidth: 120,
        cellRenderer: 'textCellRenderer',
        ...search_filter_def
    },
    email: {
        minWidth: 120,
        cellRenderer: 'textCellRenderer',
        ...search_filter_def
    },
    prenom: {
        minWidth: 120,
        cellRenderer: 'textCellRenderer',
        ...search_filter_def
    },
    nom: {
        minWidth: 120,
        cellRenderer: 'textCellRenderer',
        ...search_filter_def
    },
    reglement: {
        minWidth: 200,
        cellRenderer: 'reglementCellRenderer'
    },
    denomination: {
        minWidth: 120,
        cellRenderer: 'textCellRenderer',
        ...search_filter_def
    },
    siret: {
        minWidth: 120,
        cellRenderer: 'textCellRenderer',
        ...search_filter_def
    },
    adresse: {
        minWidth: 120,
        cellRenderer: 'textCellRenderer',
        ...search_filter_def
    },
    cycleIstya: {
        minWidth: 120,
        cellRenderer: 'textCellRenderer',
        ...search_filter_def
    },
    anneeStageCAC: {
        minWidth: 120,
        cellRenderer: 'textCenterCellRenderer',
        ...search_filter_def
    },
    groupe: {
        minWidth: 120,
        cellRenderer: 'textCellRenderer',
        ...search_filter_def
    },
    maitreDeStage: {
        minWidth: 120,
        cellRenderer: 'textCellRenderer',
        ...search_filter_def
    },
    maitre: {
        minWidth: 120,
        cellRenderer: 'textCellRenderer',
        ...search_filter_def
    },
    accesElearning: {
        minWidth: 120,
        cellRenderer: 'textCenterCellRenderer',
        ...search_filter_def
    },
    formationStatut: {
        cellRenderer: 'textCellRenderer',
        minWidth: 150,
        ...search_filter_def
    }
};

export { ag_grid_fields, search_filter_def };
