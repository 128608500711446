<template>
  <div
    class="refonte-formation-number"
    v-bind:class="on_click ? 'formation-number-clickable' : ''"
    v-on:click="
      () => {
        on_click && on_click();
      }
    ">
    <refonte-number
      variant="segment"
      class="refonte-formation-number-number">
      {{ props.number }}
    </refonte-number>
    <span class="refonte-formation-number-text">
      {{ props.label }}
    </span>
  </div>
</template>

<script setup lang="ts">
  import RefonteNumber from '@/components/refonte/numbers/refonte-number.vue';

  const props = withDefaults(
    defineProps<{
      label: string;
      number?: number;
      on_click?: Function;
    }>(),
    {
      number: 0
    }
  );
</script>

<style lang="scss" scoped>
  .refonte-formation-number {
    @apply inline-flex shrink-0 items-center justify-center bg-transparent text-refonte_blue-600;
    gap: 6px;
    border-radius: 8px;
    padding: 10px 12px;

    .refonte-formation-number-text {
      font-size: 13px;
      font-weight: 500;
      line-height: 16px;
    }
    .refonte-formation-number-number {
      height: 16px;
      line-height: 16px;
      padding: 0;

      :deep(span) {
        font-size: 13px;
        line-height: 16px;
      }
    }
    &.formation-number-clickable {
      @apply cursor-pointer bg-refonte-pale-violet-2;

      &:hover {
        @apply bg-refonte-pale-violet-3;
      }
    }
  }
</style>
