<template>
  <div class="autocomplete">
    <div
      class="autocomplete__searchbar"
      tabindex="-1"
      v-on:keydown.backspace="backspacePreventDefault">
      <font-awesome-icon
        icon="search"
        size="lg"
        class="searchbar__icon" />
      <input
        class="searchbar__input"
        type="text"
        tabindex="0"
        v-on:click="onInit"
        v-on:focus="onInit"
        v-on:input="onChange"
        v-model.trim="search"
        placeholder="Rechercher pour filtrer"
        v-on:keyup.down="onArrowDown"
        v-on:keyup.up="onArrowUp"
        v-on:keyup.enter="onEnter"
        v-on:keyup.escape="closeDropdown"
        v-on:keydown.tab.prevent.stop="resetTabIndex($event)" />
      <transition name="fade">
        <font-awesome-icon
          v-if="search.length"
          icon="times"
          size="sm"
          class="searchbar__icon searchbar__icon--cancel"
          v-on:click="cleanValue" />
      </transition>
    </div>

    <transition name="slide-up">
      <ul
        v-show="isOpen"
        ref="autocomplete__list"
        class="autocomplete__list">
        <li
          v-if="isLoading"
          class="loading">
          Loading results...
        </li>
        <li
          v-else-if="results"
          v-for="(result, i) in results"
          v-bind:key="result.id"
          v-on:click="setResultOnUnique(result)"
          class="list__item"
          v-bind:class="{ 'is-active': i === arrowCounter }">
          <span class="item__titre">{{ result.titre }}</span>
          <span class="item__desc">
            ({{ result.reference }}{{ result.lieu && ` - ${result.lieu}`
            }}{{ result.organisateur && ` - ${result.organisateur}` }})
          </span>
          <ul
            v-if="result.produits"
            class="item__sub-list">
            <li
              v-for="sub_list in getSousProduits(result)"
              v-bind:key="`sub_${sub_list.id}`">
              <span class="item__titre">{{ sub_list.titre }}</span>
              <span class="item__desc">
                (
                {{ sub_list.reference }}
                {{ sub_list.lieu && ` - ${sub_list.lieu}` }}
                {{ sub_list.organisateur && ` - ${sub_list.organisateur}` }}
                )
              </span>
            </li>
          </ul>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
  export default {
    props: {
      items: {
        type: Array,
        required: false,
        default: () => []
      },
      is_async: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    emits: ['setted_result'],
    data() {
      return {
        isOpen: false,
        results: [],
        setted_results: null,
        current_search_type: null,
        search: '',
        isLoading: false,
        arrowCounter: -1,
        list: null
      };
    },
    watch: {
      items: {
        handler(newValue, oldValue) {
          if (newValue.length !== oldValue.length) {
            this.results = newValue;
            this.isLoading = false;
          }
        },
        deep: true
      },
      isOpen() {
        this.list.scrollTop = 0;
      },
      search: {
        handler(newValue) {
          if (newValue.length === 0) {
            this.cleanValue();
          }
        },
        deep: true
      }
    },
    created() {
      this.setResultOnUnique('');
    },
    mounted() {
      document.addEventListener('click', this.handleClickOutside);
      this.list = this.$refs.autocomplete__list;
    },
    beforeUnmount() {
      document.removeEventListener('click', this.handleClickOutside);
    },
    methods: {
      getSousProduits(session) {
        return session.produits.filter((p) => p.id !== session.id);
      },
      setResultOnUnique(result) {
        if (Object.keys(result).length) {
          this.current_search_type = 'titre';
          this.search = result.titre;
          this.setResult([result]);
        } else {
          this.current_search_type = null;
          this.setResult(result);
        }
      },
      setResult(value) {
        this.setted_results = {
          value,
          type: this.current_search_type,
          search_string: this.search
        };

        this.$emit('setted_result', this.setted_results);

        if (!Array.isArray(value) && Object.keys(value).length) {
          this.search = value && Object.keys(value).length ? value.titre : '';
        } else if (typeof value === 'string' && !value.length) {
          this.search = value;
        }

        this.isOpen = false;
      },
      uniqueArray(array) {
        return [...new Set(array.map((obj) => JSON.stringify(obj)))].map(
          (output_string) => JSON.parse(output_string)
        );
      },
      filterResults() {
        const tested = this.search.toLowerCase();
        const escapeRegExp = (str) =>
          str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const regex = new RegExp(escapeRegExp(tested), 'gi');

        const match_search = (entry, key) => {
          const is_regex = regex.test(entry);

          if (is_regex) {
            const types_array = ['titre', 'reference', 'organisateur', 'lieu'];

            this.current_search_type = types_array[key];
          }

          return is_regex;
        };

        const filtered_list = this.items.filter(
          ({ titre, reference, organisateur, lieu, produits, type }) => {
            const list = [];
            const sublists = [];

            [titre, reference, organisateur, lieu].map((i) => {
              return typeof i !== 'undefined' && list.push(i);
            });

            if (produits && produits.length) {
              produits.map((sub) => {
                const sublist = [];

                [sub.titre, sub.reference].map((j) => {
                  return typeof j !== 'undefined' && sublist.push(j);
                });

                return sublists.push(sublist);
              });
            }

            return (
              list.some(match_search) ||
              sublists.some((sub) => sub.some(match_search))
            );
          }
        );

        this.results = this.uniqueArray(filtered_list);
      },
      selectValue(e) {
        e.target.select();
      },
      cleanValue() {
        this.setResultOnUnique('');
      },
      onInit(e) {
        this.selectValue(e);
        this.onChange();
      },
      onChange() {
        // this.$emit('input', this.search);
        this.filterResults();
        this.setResult(this.results);
        this.isOpen = true;
      },
      handleClickOutside(event) {
        if (!this.$el.contains(event.target)) {
          this.isOpen = false;
          this.arrowCounter = -1;
        }
      },
      onArrowDown() {
        if (this.arrowCounter < this.results.length - 1) {
          this.arrowCounter = this.arrowCounter + 1;
          const current_item = this.list.children[this.arrowCounter];

          this.list.scrollTop = current_item.offsetTop - 100;
        }
      },
      onArrowUp() {
        if (this.arrowCounter > 0) {
          this.arrowCounter = this.arrowCounter - 1;
          const current_item = this.list.children[this.arrowCounter];

          this.list.scrollTop = current_item.offsetTop - 100;
        }
      },
      onEnter(e) {
        if (this.results[this.arrowCounter]) {
          this.setResultOnUnique(this.results[this.arrowCounter]);
        }
        this.resetArrowCounter();
      },
      resetArrowCounter(e) {
        this.arrowCounter = -1;
      },
      closeDropdown(e) {
        this.resetTabIndex(e);
        this.resetArrowCounter();
        this.isOpen = false;
      },
      resetTabIndex(e) {
        e.target.parentElement.focus();
        e.target.focus();

        if (!this.search || !this.search.length) {
          this.setResultOnUnique('');
        }
      },
      backspacePreventDefault(e) {
        if (!this.isOpen) {
          e.preventDefault();
        }
      }
    }
  };
</script>

<style lang="scss">
  .autocomplete {
    @apply relative z-30 flex h-10 w-full max-w-xs flex-wrap;

    .autocomplete__searchbar {
      @apply flex w-full items-center space-x-3 outline-none;

      .searchbar__icon {
        @apply text-accent-500;

        &.searchbar__icon--cancel {
          margin-left: -1.5rem;
          @apply h-full w-2.5 cursor-pointer hover:text-core-900;
        }
      }
      .searchbar__input {
        @apply h-full w-[calc(100%-2rem)] truncate rounded-sm border border-accent-300 pl-4 pr-8 leading-none shadow-sm;
      }
    }

    .autocomplete__list {
      @apply absolute left-9 right-[1px] top-11 m-0 max-h-[calc(100vh/2)] list-none overflow-auto overscroll-none rounded-b-md bg-white p-0 shadow-md;

      .list__item {
        @apply px-4 py-2 leading-tight;

        .item__titre {
          @apply flex w-full;
        }
        .item__desc {
          @apply flex w-full text-xs italic text-accent-500;
        }
        .item__sub-list {
          @apply my-0.5 ml-2 list-none space-y-0.5 border-l-1 border-accent-300 pl-2 text-xs;
        }
      }

      .list__item.is-active,
      .list__item:hover {
        @apply cursor-pointer bg-jinius-dark-green text-white;

        .item__desc {
          @apply text-white;
        }
      }
    }
  }
</style>
