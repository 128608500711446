<template>
  <div
    v-if="!$route.meta.refonte"
    id="app">
    <header-layout
      ref="header"
      class="sticky-top" />

    <div
      ref="wrapper"
      class="layout-wrapper"
      v-bind:class="{
        'layout-wrapper--modal-open': is_modal_open
      }">
      <div
        v-if="!mounted"
        class="absolute inset-0 flex items-center justify-center">
        <spinner
          sizes="w-20 h-20"
          thickness="border-8" />
      </div>
      <main
        class="main-content"
        v-bind:class="{
          'container mx-auto max-w-screen-2xl py-16':
            !$route.meta.no_container_wrapper
        }">
        <router-view v-slot:default="{ Component }">
          <transition
            name="fade-router"
            mode="out-in">
            <component v-bind:is="Component" />
          </transition>
        </router-view>
      </main>

      <!-- Scroll top en vue mobile ou pages annexes -->
      <template
        v-if="
          $breakpoints.w < $breakpoints.screens.lg ||
          $route.name === 'aide-en-ligne'
        ">
        <div
          class="fixed bottom-0 right-0 z-30 mb-4 mr-4 flex items-center justify-center">
          <transition name="fade">
            <div
              v-if="scrolled_body"
              v-on:click="scrollToTop"
              class="flex h-16 w-16 cursor-pointer items-center justify-center rounded-full bg-accent-700 text-core-100 opacity-25 transition duration-200 ease-in hover:opacity-75">
              <font-awesome-icon
                icon="chevron-up"
                size="xl" />
            </div>
          </transition>
        </div>
      </template>
    </div>
  </div>
  <div
    v-else
    id="refonte-app">
    <div
      ref="wrapper"
      class="refonte-layout-wrapper">
      <refonte-layout>
        <router-view v-slot:default="{ Component }">
          <component v-bind:is="Component" />
        </router-view>
      </refonte-layout>
    </div>
  </div>
</template>

<script>
  import refonteLayout from '@/components/refonte/layout/refonte-layout.vue';
  import appCheckUpdate from '@/components/utils/app-check-update.vue';
  import spinner from '@/components/utils/spinner.vue';
  import { computed } from 'vue';
  import { createNamespacedHelpers } from 'vuex';
  import headerLayout from './components/header/header-layout.vue';

  const { mapMutations: mapMutationsObserver, mapState: mapStateObserver } =
    createNamespacedHelpers('observer');
  const { mapState: mapStateAuth } = createNamespacedHelpers('auth');

  export default {
    components: {
      appCheckUpdate,
      headerLayout,
      spinner,
      refonteLayout
    },
    provide() {
      return { target_wrapper: computed(() => this.ref_wrapper) };
    },
    data() {
      return {
        mounted: false,
        is_modal_open: false
      };
    },
    computed: {
      ...mapStateObserver({ scrolled_body: (state) => state.scrolled_body }),
      ...mapStateAuth({ authenticated: (state) => state.authenticated }),
      ref_wrapper() {
        return this.$refs.wrapper;
      }
    },
    watch: {
      $route(to, from) {
        if (from.name !== null) {
          // Prevent from triggering on manual browser refresh (ex: F5)
          this.scrollToTop();
        }
        this.$nextTick(() => {
          this.updateTemplate();
        });
      },
      '$breakpoints.is'() {
        this.$nextTick(() => {
          // on update le template à chaque changement de breakpoint
          this.updateTemplate();
          this.setIsOpenModal();
        });
      },
      authenticated(newVal, oldVal) {
        if (newVal === false && this.$route.meta.requiresAuth) {
          window.location.reload();
        }
      }
    },
    beforeUnmount() {
      //do something before destroying vue instance
      this.$refs.wrapper.removeEventListener('scroll', this.handleScrolling);
    },
    mounted() {
      // this.trackWindowResize();
      // window.addEventListener('resize', this.trackWindowResize);
      this.$refs.wrapper.addEventListener('scroll', this.handleScrolling);
      // Waiting for the CSS header's height animation to end first.
      this.$nextTick(() => {
        this.updateTemplate();
      });

      document.onreadystatechange = () => {
        if (document.readyState == 'complete') {
          this.mounted = true;
        }
      };

      this.$options.$on('modal-open', () => {
        setTimeout(() => {
          this.setIsOpenModal();
        }, 300);
      });
      this.$options.$on('modal-close', () => {
        setTimeout(() => {
          this.setIsOpenModal();
        }, 300);
      });
    },
    methods: {
      ...mapMutationsObserver({
        setScroll: 'setScroll',
        setSidebar: 'setSidebar',
        setFixedOffset: 'setFixedOffset'
      }),
      handleScrolling(evt) {
        const scrolled = this.$refs.wrapper.scrollTop >= 50;

        this.setScroll(scrolled);

        setTimeout(() => {
          // Waiting for the CSS header's height animation to end first.
          this.updateTemplate();
        }, 200);
      },
      updateTemplate() {
        const header = this.$refs.header;
        const offset = header ? header.$el.clientHeight : 0;
        const wrapper = this.$refs.wrapper;

        wrapper.style.height = `calc(${window.innerHeight} - ${offset}px)`;
        wrapper.style.marginTop = `${offset}px`;

        this.setFixedOffset(offset);
      },
      scrollToTop() {
        this.$refs.wrapper.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      },
      setIsOpenModal() {
        const list = document.getElementsByClassName('modal-mask');

        this.is_modal_open = list ? list.length > 0 : false;
      }
    }
  };
</script>

<style lang="scss">
  #app,
  body,
  html {
    @apply h-screen bg-core-100 font-sans text-core-600 antialiased;
    font-size: 14px;
  }

  #app {
    @apply relative flex flex-col;
  }

  .sticky-top {
    @apply fixed left-0 right-0 top-0 z-50 shadow;
  }

  .layout-wrapper {
    -webkit-overflow-scrolling: touch;

    @apply flex h-full flex-grow flex-col overflow-y-auto overflow-x-hidden;

    &.layout-wrapper--modal-open {
      @apply overflow-hidden;
    }
  }

  .main-content {
    // flex-1 is mandatory for the footer sticking to bottom
    @apply mx-auto h-full w-full flex-1;
  }

  #refonte-app {
    @apply relative flex h-screen flex-col overflow-hidden bg-refonte-background-2 font-refonte text-refonte-black antialiased;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;

    h1 {
      @apply text-refonte-black;
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
    }
    h2 {
      @apply text-refonte-black;
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
    }
    h3 {
      @apply text-refonte-black;
      font-size: 18px;
      font-weight: 600;
      line-height: 26px;
    }
    h4,
    h5,
    h6 {
      @apply text-refonte-black;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }

    .refonte-layout-wrapper {
      @apply flex flex-grow flex-col;
    }
  }

  .v-popper__popper {
    .v-popper__wrapper {
      .v-popper__inner {
        @apply bg-refonte-black text-refonte-white #{!important};
        border-radius: 4px;
        padding: 10px;
        max-width: 397px;
        font-size: 13px;
        font-weight: 400;
        line-height: 125%;

        .tooltip-content {
          @apply flex flex-col items-start justify-center;
          gap: 4px;

          .tooltip-content-title {
            @apply uppercase text-refonte-white-60;
            font-size: 11px;
            font-weight: 400;
            line-height: 125%;
          }
          .tooltip-content-text {
            @apply text-refonte-white;
            font-size: 13px;
            font-weight: 400;
            line-height: 125%;
          }
        }
      }
    }
    .v-popper__arrow-container {
      .v-popper__arrow-outer {
        @apply border-t-refonte-black #{!important};
      }
    }
  }
</style>
