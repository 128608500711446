<template>
  <sub-component-wrapper
    v-bind:default_component="
      getClientContact().droitAdministrateur &&
      !getClientContact().administrateurEnAttente
        ? 'participants'
        : 'informations'
    "
    v-bind:props="props"
    v-bind:tabs="tabs"
    v-bind:sub_components_list="sub_components_list" />
</template>
<script>
  import { createNamespacedHelpers } from 'vuex';

  import navigation_utils from '@/mixin/navigation_utils';

  import participants from '@/views/_espace/_entreprise/_entreprise/participants.vue';
  import facturation from '@/views/_espace/_entreprise/_entreprise/facturation.vue';
  import informations from '@/views/_espace/_entreprise/_entreprise/informations.vue';
  import interlocutors from '@/views/_espace/_entreprise/_entreprise/interlocutors.vue';
  import satisfaction from '@/views/_espace/_entreprise/_entreprise/satisfaction.vue';
  import subComponentWrapper from '@/views/_espace/sub-component-wrapper.vue';
  const { mapState: mapStateClient } = createNamespacedHelpers('client');

  export default {
    components: { subComponentWrapper },
    mixins: [navigation_utils],
    props: ['props'],
    computed: {
      ...mapStateClient(['profile']),
      sub_components_list() {
        return {
          participants,
          facturation,
          informations,
          interlocutors,
          satisfaction
        };
      },
      tabs() {
        const clientContact = this.getClientContact();
        const is_admin =
          clientContact.droitAdministrateur &&
          !clientContact.administrateurEnAttente;

        const sub_components = this.fetchNavigationSubComponents();

        return Object.values(sub_components).filter(
          (component) => component.isAdmin === is_admin || !component.isAdmin
        );
      }
    },
    methods: {
      getClientContact() {
        const { activeEntrepriseId, entreprises } = this.profile;

        return entreprises.find((e) => e.entrepriseId === activeEntrepriseId);
      }
    }
  };
</script>
