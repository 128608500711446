<template>
  <div>
    <spinner
      v-if="loading || loading_switch"
      sizes="w-20 h-20"
      thickness="border-8" />

    <div v-else>
      <template v-if="has_sessions">
        <div class="mb-8 mt-4 flex w-full items-center justify-end">
          <div
            class="flex items-stretch rounded-md bg-core-200 text-accent-500 shadow-inner hover:bg-accent-200">
            <button
              v-on:click="toggleIsGrid"
              class="flex w-full items-center justify-center rounded-l px-3 py-1.5 transition"
              v-bind:class="[
                !is_grid[current_space]
                  ? `bg-${espaces_list[current_space].color} text-white shadow-md`
                  : ''
              ]">
              <font-awesome-icon
                icon="list"
                size="lg"
                fixed-width />
            </button>
            <button
              v-on:click="toggleIsGrid"
              class="flex w-full items-center justify-center rounded-r px-3 py-1.5 transition"
              v-bind:class="[
                is_grid[current_space]
                  ? `bg-${espaces_list[current_space].color} text-white shadow-md`
                  : ''
              ]">
              <font-awesome-icon
                icon="table"
                size="lg"
                fixed-width />
            </button>
          </div>
        </div>

        <template v-if="!is_grid[current_space]">
          <session-stagiaire-container
            ref="session_stagiaire_container"
            v-for="session in sessionsToDisplay"
            v-bind:key="`session-${session.id}`"
            v-bind:session="session"
            v-bind:parcours="session.isParcours"
            v-bind:produits="session.produits" />
        </template>

        <div
          v-else-if="is_grid[current_space]"
          class="mb-4">
          <ag-grid-wrapper
            v-bind:tree_data="{
              headerName: 'Ref.',
              treeData: 'grpRef'
            }"
            v-bind:column_defs="columnDefs"
            v-bind:initial_data="grid_sessions" />
        </div>
      </template>
      <template v-else>
        <div class="py-8 text-center text-xl font-bold">
          Le stagiaire n'a aucune session.
        </div>
      </template>
    </div>
  </div>
</template>
<script>
  import { map_statut_session_stagiaire_labels } from '@/assets/data/data_maps';
  import agGridWrapper from '@/components/utils/ag-grid/ag-grid-wrapper';
  import sessionStagiaireContainer from '@/components/utils/session-stagiaire-container';
  import spinner from '@/components/utils/spinner.vue';
  import { createNamespacedHelpers } from 'vuex';
  const { mapState: mapStateEspace } = createNamespacedHelpers('espace');
  const { mapMutations: mapMutationsObserver, mapState: mapStateObserver } =
    createNamespacedHelpers('observer');

  export default {
    components: {
      spinner,
      agGridWrapper,
      sessionStagiaireContainer
    },
    props: {
      sessions_list: { type: Array },
      loading: { type: Boolean },
      current_space: { type: String }
    },
    data() {
      return {
        loading_switch: false,
        sessionsToDisplay: null,
        map_statut_session_stagiaire_labels
      };
    },
    computed: {
      ...mapStateEspace(['espaces_list']),
      ...mapStateObserver({ is_grid: (state) => state.is_grid }),
      has_sessions() {
        return this.is_grid[this.current_space]
          ? this.sessions_list && this.sessions_list.length
          : this.sessionsToDisplay && this.sessionsToDisplay.length;
      },
      columnDefs() {
        if (this.has_sessions) {
          return [
            {
              headerName: 'Titre',
              field: 'titre',
              cellRendererParams: {
                onclick: (e, params) => this.goToFiche(e, params)
              }
            },
            {
              headerName: 'Type',
              field: 'type'
            },
            {
              headerName: 'Organisateur',
              field: 'organisateur'
            },
            {
              headerName: 'Date(s)',
              field: 'sessionDates'
            },
            {
              headerName: 'Lieu',
              field: 'lieu'
            },
            {
              headerName: 'Statut',
              field: 'formationStatut'
            },
            {
              headerName: 'Convocation',
              field: 'mailConvocEnvoye'
            },
            {
              headerName: 'Avancement min. requis',
              field: 'produitLienElearningPrerequisPourcentage'
            },
            {
              headerName: 'Avancement',
              field: 'avancement'
            },
            {
              headerName: 'Présence',
              field: 'presence'
            },
            {
              headerName: 'Test de validation des acquis réussi',
              field: 'testValidationAcquisReussi'
            }
          ];
        } else {
          return [];
        }
      },
      grid_sessions() {
        const sub_sessions = [];
        const format_for_grid = this.sessions_list.map((item) => {
          const formated_item = {};

          Object.keys(item).forEach((key) => {
            if (['mode'].includes(key)) {
              formated_item[key] = item[key].label;
            } else if (key === 'type') {
              formated_item[key] = item['ficheType']
                ? {
                    ...item[key],
                    label: item['ficheType'].label
                  }
                : item[key];
              formated_item['typeId'] = item[key].id;
            } else if (
              ['mailConvocEnvoye', 'testValidationAcquisReussi'].includes(key)
            ) {
              formated_item[key] = item[key] ? 'Oui' : 'Non';
            } else if (['statutId'].includes(key)) {
              formated_item['formationStatut'] =
                map_statut_session_stagiaire_labels[item[key]];
            } else {
              formated_item[key] = item[key];
            }
          });

          return formated_item;
        });

        format_for_grid.push(...sub_sessions);

        return format_for_grid;
      }
    },
    created() {
      this.fetchSessionsToDisplay();
    },
    methods: {
      ...mapMutationsObserver({ setIsGrid: 'setIsGrid' }),
      toggleIsGrid() {
        this.loading_switch = true;
        this.setIsGrid({
          is_grid: !this.is_grid[this.current_space],
          current_space: this.current_space
        });

        setTimeout(() => {
          this.loading_switch = false;
        }, 300);
      },
      goToFiche(event, params) {
        this.openFiche(event, params);
      },
      fetchSessionsToDisplay() {
        if (this.sessions_list) {
          const format_list = this.sessions_list.map((session) => {
            const produits =
              !session.isSousProduit &&
              this.sessions_list.filter(
                (s) => s.parcoursReference === session.reference
              );

            return {
              ...session,
              produits
            };
          });

          this.sessionsToDisplay = format_list.filter(
            (session) => !session.isSousProduit
          );
        }

        if (this.$refs.session_stagiaire_container) {
          this.$refs.session_stagiaire_container.forEach((el) => {
            if (el.parcours) {
              el.openCollapse();
            }
          });
        }
      }
    }
  };
</script>

<style lang="scss" scoped></style>
