<template>
  <div class="relative flex w-full flex-col">
    <tab-view
      v-bind:default_component="props.default_component"
      v-bind:props="props.props"
      v-bind:tabs="props.tabs" />
    <div
      ref="scrollable_current_component"
      class="flex h-full w-full rounded-b border-b border-l border-r border-core-300 bg-core-100 lg:max-h-[calc(100%-2rem)]"
      v-bind:style="cssVars"
      v-bind:key="
        is_init &&
        $breakpoints.w >= $breakpoints.screens.lg &&
        `current_component_${fetchCurrentComponent}`
      ">
      <div class="w-full">
        <component
          v-if="is_component_available"
          v-bind:is="
            defineComponent(available_components[fetchCurrentComponent])
          "
          v-bind:props="props.props"
          class="overflow-auto px-4 lg:p-6" />
        <error
          v-else
          no_return_home />
        <div>&nbsp;</div>
      </div>
    </div>
    <simplebar-scroll
      v-if="is_init && $breakpoints.w >= $breakpoints.screens.lg"
      colors="bg-core-700"
      target="scrollable_current_component"
      v-bind:key="`scroll_component_${fetchCurrentComponent}`" />
  </div>
</template>

<script setup>
  import simplebarScroll from '@/components/utils/simplebar-scroll.vue';
  import tabView from '@/components/utils/tab-view.vue';
  import error from '@/views/errors/error-404.vue';
  import {
    computed,
    defineComponent,
    inject,
    nextTick,
    onBeforeMount,
    onMounted,
    ref
  } from 'vue';

  const components_list = inject('components_list');
  const props = defineProps([
    'default_component',
    'tabs',
    'props',
    'sub_components_list'
  ]);

  const is_init = ref(false);
  const init_height = ref(0);
  const scrollable_current_component = ref(null);

  const available_components = computed(() => {
    return {
      ...components_list,
      ...props.sub_components_list
    };
  });

  const is_component_available = computed(() => {
    return available_components.value[fetchCurrentComponent.value]
      ? true
      : false;
  });

  const fetchCurrentComponent = computed(() => {
    return props.props?.sub_children_id;
  });
  const cssVars = computed(() => {
    return { '--wrapper-height': `${init_height.value}px` };
  });

  onBeforeMount(() => {
    nextTick(() => {
      is_init.value = true;
    });
  });

  onMounted(() => {
    init_height.value = scrollable_current_component.value.clientHeight;
  });
</script>
