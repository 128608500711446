<template>
  <modal-box
    v-if="show_modal"
    width_class="w-full max-w-2xl"
    overflow_body
    v-bind:header="true"
    v-bind:footer="false"
    v-on:close="onclose">
    <template v-slot:modal-header>
      <h1 class="text-3xl font-bold text-core-600">Documents</h1>
    </template>

    <template v-slot:modal-body>
      <div
        class="my-4 flex w-full flex-wrap"
        v-if="contrat.fichierSigneValide">
        <div
          class="ressources__download__link__container flex w-full"
          v-if="contrat.fichierSigneDisponible">
          <h3 class="ressources__download__link__label">- Contrat validé</h3>
          <div
            class="ressources__download__link__button"
            v-on:click="
              () =>
                download_contrat(
                  contrat.id,
                  'signe',
                  contrat.fichierRefSigneNom
                )
            ">
            <div
              class="ressources__standalone_button__content"
              title="Télécharger le contrat signé">
              <font-awesome-icon
                class="text-xl"
                icon="download" />
            </div>
          </div>
        </div>
      </div>
      <div
        class="my-4 flex w-full flex-wrap space-y-4"
        v-else>
        <div
          class="ressources__download__link__container flex w-full"
          v-if="contrat.fichierDisponible">
          <h3 class="ressources__download__link__label">- Contrat à signer</h3>
          <div
            class="ressources__download__link__button"
            v-on:click="
              () => download_contrat(contrat.id, '', contrat.fichierRefNom)
            ">
            <div
              class="ressources__standalone_button__content"
              title="Télécharger le contrat à signer">
              <font-awesome-icon
                class="text-xl"
                icon="download" />
            </div>
          </div>
        </div>
        <div class="ressources__download__link__container flex w-full">
          <h3 class="ressources__download__link__label">- Contrat signé</h3>
          <div
            class="ressources__download__link__button"
            v-on:click="
              () =>
                download_contrat(
                  contrat.id,
                  'signe',
                  contrat.fichierRefSigneNom
                )
            "
            v-if="contrat.fichierSigneDisponible">
            <div
              class="ressources__standalone_button__content"
              title="Télécharger le contrat téléversé">
              <font-awesome-icon
                class="text-xl"
                icon="download" />
            </div>
          </div>
          <file-upload
            title="Téléverser le contrat signé"
            v-bind:upload_url="`${contrat_base_url}/${contrat.id}/file`"
            v-bind:refresh_session="refresh_session"
            id="contrat-upload" />
        </div>
      </div>
    </template>

    <template v-slot:modal-footer> </template>
  </modal-box>
</template>
<script>
  import modalBox from '@/components/utils/modal-box.vue';
  import FileUpload from '@/components/utils/file-upload';

  export default {
    components: {
      modalBox,
      FileUpload
    },
    props: {
      show_modal: Boolean,
      contrat: Object,
      onclose: Function,
      refresh_session: Function,
      contrat_base_url: String,
      download_contrat: Function
    },
    data: () => ({ loading: false }),
    methods: {
      close() {
        this.onclose();
      }
    }
  };
</script>
<style lang="scss" scoped>
  .ressources__download__link__container {
    @apply mb-3 flex cursor-pointer items-center;

    .ressources__download__link__button {
      @apply ml-3 flex items-center;

      .ressources__download__link__button__content {
        @apply flex h-8 w-full items-center justify-center rounded bg-accent-200 p-4 text-center text-sm font-bold uppercase text-core-500 shadow-md disabled:cursor-not-allowed disabled:opacity-75;
      }
    }

    .ressources__download__link__label {
      @apply ml-8 text-xl font-bold leading-none text-core-700;
    }

    &:hover {
      .ressources__download__link__button {
        .ressources__download__link__button__content {
          @apply bg-accent-300 text-core-600;
        }
      }

      .ressources__download__link__label {
        @apply text-primary-800;
      }
    }
  }

  :deep(.ressources__standalone_button__content) {
    @apply m-auto flex h-8 w-full items-center justify-center rounded bg-accent-200 p-4 text-center text-sm font-bold uppercase text-core-500 shadow-md disabled:cursor-not-allowed disabled:opacity-75;
    &:hover {
      @apply bg-accent-300 text-core-600;
    }
  }
</style>
