<template>
  <div
    class="flex h-full w-full flex-wrap items-center justify-center"
    v-if="params.value && params.value.modeReglementId">
    <button
      class="btn"
      v-on:click="params.onclick($event, reglement)"
      v-if="reglement && params.value.modeReglementId !== 3">
      <font-awesome-icon
        class="mr-2"
        icon="search" />
      Rappel des instructions
    </button>
    <div v-else>
      {{ params.value.modeReglementLabel }}
    </div>
  </div>
  <div
    class="flex h-full w-full flex-wrap items-center justify-center"
    v-else-if="params.data?.origineId === 2"></div>
  <div
    class="flex h-full w-full flex-wrap items-center justify-center"
    v-else>
    Pas de règlement
  </div>
</template>
<script>
  export default {
    data() {
      return { showModal: false };
    },
    computed: {
      reglement() {
        const { value } = this.params;

        if (value && value.modeReglementId) {
          const payment = this.payments.find(
            (payment) => payment.id == value.modeReglementId
          );

          if (payment && value[payment.show] && value[payment.instruction]) {
            return {
              instruction: value[payment.instruction],
              label: value.modeReglementLabel
            };
          }
        }

        return null;
      },
      payments: () => [
        {
          id: 3,
          show: 'cbAccept',
          instruction: 'cbInstruction'
        },
        {
          id: 2,
          show: 'virementAccept',
          instruction: 'virementInstruction'
        },
        {
          id: 5,
          show: 'sepaAccept',
          instruction: 'sepaInstruction'
        },
        {
          id: 1,
          show: 'chequeAccept',
          instruction: 'chequeInstruction'
        }
      ]
    },
    methods: {
      openModal() {
        this.showModal = true;
      },
      closeModal() {
        this.showModal = false;
      }
    }
  };
</script>
<style lang="scss" scoped>
  .btn {
    @apply mx-2 w-64 max-w-full items-center justify-center rounded bg-jinius-dark-green py-2 text-sm font-bold leading-none text-white hover:bg-jinius-green disabled:cursor-not-allowed disabled:border-0 disabled:bg-accent-300;
  }
</style>
