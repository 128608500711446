<template>
  <div class="simplebar-scroll">
    <transition name="fade">
      <font-awesome-icon
        v-if="scrolled"
        v-on:click="scrollTop"
        class="simplebar-scroll__icon"
        v-bind:class="colors ? colors : 'bg-accent-700'"
        fixed-width
        icon="chevron-up" />
    </transition>
  </div>
</template>

<script>
  import SimpleBar from 'simplebar';
  import 'simplebar/dist/simplebar.min.css';
  import { createNamespacedHelpers } from 'vuex';

  const { mapMutations: mapMutationsObserver } =
    createNamespacedHelpers('observer');

  export default {
    props: {
      target: {},
      colors: { type: String }
    },
    data() {
      return {
        scrolled: false,
        simplebar: null,
        scroll_spy: null
      };
    },
    mounted() {
      const current_target = this.$parent.$refs[this.target];

      this.simplebar = new SimpleBar(current_target);
      this.scroll_spy = this.simplebar.getScrollElement();

      if (this.scroll_spy) {
        this.scroll_spy.addEventListener('scroll', this.onScroll);
      }
    },
    beforeUnmount() {
      if (this.scroll_spy) {
        this.scroll_spy.removeEventListener('scroll', this.onScroll);
      }
      this.simplebar.unMount();
      SimpleBar.removeObserver();
    },
    methods: {
      ...mapMutationsObserver({ setScroll: 'setScroll' }),
      async onScroll(event) {
        if (event.target.scrollTop > 45) {
          this.scrolled = true;
        } else {
          this.scrolled = false;
        }
        if (this.target === 'scrollable_body__main') {
          await setTimeout(() => {
            this.setScroll(this.scrolled);
          }, 100);
        }
      },
      scrollTop() {
        const scroller = this.simplebar.getScrollElement();

        scroller.scroll({
          top: 0,
          behavior: 'smooth'
        });
      }
    }
  };
</script>

<style lang="scss">
  /*=============================================>>>>>
= Simplebar's default z-index override to match
with modal's mask superposition =
===============================================>>>>>*/

  .simplebar-mask {
    z-index: auto !important;
  }
</style>

<style lang="scss" scoped>
  .simplebar-scroll {
    @apply absolute bottom-1 right-4 z-10;

    .simplebar-scroll__icon {
      @apply h-6 w-6 cursor-pointer rounded-full p-4 text-white opacity-25 shadow transition duration-200 ease-in-out;

      &:hover {
        @apply opacity-75;
      }
    }
  }
  .svg-inline--fa {
    @apply w-full;
  }
</style>
