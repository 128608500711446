<template>
  <sessions-list-wrapper
    v-bind:key="loading"
    v-bind:sessions_list="sessionsToAnimate"
    title="Prochaines sessions"
    no_result="Vous n'avez aucune session à venir."
    current_space="entreprise"
    v-bind:loading="loading" />
</template>
<script>
  import sessionsListWrapper from '@/components/utils/sessions-list-wrapper.vue';
  import { API } from '@/http-common';
  import { createNamespacedHelpers } from 'vuex';
  const { mapState: mapStateClient } = createNamespacedHelpers('client');

  export default {
    components: { sessionsListWrapper },
    data: () => ({
      loading: false,
      sessionsToAnimate: []
    }),
    computed: { ...mapStateClient(['profile']) },
    watch: {
      async ['profile.activeEntrepriseId']() {
        await this.fetchSessions();
      }
    },
    created() {
      this.fetchSessions();
    },
    methods: {
      async fetchSessions() {
        this.loading = true;
        const clientId = this.profile.activeEntrepriseId;

        if (clientId) {
          const response = await API.get(
            `client/${clientId}/prochaines-inscriptions`,
            this.$header_skip_redirection
          );

          this.sessionsToAnimate = response.data;
        }
        this.loading = false;
      }
    }
  };
</script>
