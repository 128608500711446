import { natureId } from '@/assets/data/data_form';
import { API } from '@/http-common';
import { ACTION_TYPES } from '@/store/constants/ActionTypes';
import { MUTATION_TYPES } from '@/store/constants/MutationTypes';

export const formInitialState = {
    valideSansSiret: null,
    siret: null,
    denomination: null,
    raisonSociale: null,
    etablissementSiege: null,
    activite: null,
    trancheEffectifs: null,
    categorieJuridique: null,
    identification: null,
    destService: null,
    numeroVoie: null,
    mentionSpeciale: null,
    ville: null,
    codePostal: null,
    pays: null,
    telephone: null,
    email: null,
    siteInternet: null,
    search: ''
};

const state = {
    savedEntiteForm: {},
    entiteForm: formInitialState,
    tempClientForm: null, //Stocke les valeurs si il y a une correspondance siret
    clientForm: formInitialState,
    clientType: 'siret', //siret, particulier, rna
    forceEnableClientForm: false, //Creation d'une entreprise autorisée
    withClientForm: false, //affiche le formulaire client
    activites: [],
    categoriesJuridiques: [],
    profile: {
        entreprises: [],
        activeEntrepriseId: null
    },
    participants: [],
    entitesFacturation: [],
    interlocutors: []
};
const truncateValue = (value, max) => {
    if (value && value.length > max) {
        return value.slice(0, max);
    } else {
        return value;
    }
};

export const mapInseeResult = (payload) => {
    const etab = payload;

    return {
        siret: truncateValue(etab.siret, 20),
        coordinates: sanitize(etab.longitude)
            ? [sanitize(etab.longitude), sanitize(etab.latitude)].join(',')
            : '',
        denomination:
            sanitize(etab.sexe) !== null
                ? truncateValue(
                      `${sanitize(etab.sexe) === 'F' ? 'MADAME' : sanitize(etab.sexe) === 'M' ? 'MONSIEUR' : ''} ${sanitize(etab.prenom) ? sanitize(etab.prenom) : ''} ${sanitize(etab.nom) ? sanitize(etab.nom) : ''}`,
                      100
                  )
                : sanitize(etab.sigle) ||
                  sanitize(etab.enseigne) ||
                  sanitize(etab.nom_raison_sociale),
        etablissementSiege: sanitize(etab.is_siege),
        adresseSiege: truncateValue(
            sanitize(etab.siege_geo_adresse)
                ? sanitize(etab.siege_geo_adresse)
                : '',
            100
        ),
        activite: truncateValue(sanitize(etab.activite_principale), 10),
        trancheEffectif: truncateValue(
            sanitize(etab.tranche_effectif_salarie) ||
                sanitize(etab.tranche_effectif_salarie_entreprise),
            10
        ),
        categorieJuridique: truncateValue(etab.nature_juridique_entreprise, 10),
        identification: truncateValue(
            sanitize(etab.nom_raison_sociale) ||
                sanitize(etab.enseigne) ||
                sanitize(etab.sigle),
            100
        ),
        destService: null,
        complement: truncateValue(sanitize(etab.complement_adresse), 100),
        numeroVoie: truncateValue(
            sanitize(etab.geo_l4) ||
                [
                    sanitize(etab.numero_voie),
                    sanitize(etab.indice_repetition),
                    sanitize(etab.type_voie),
                    sanitize(etab.libelle_voie)
                ]
                    .join(' ')
                    .trim()
                    .replace(/\s+/gm, ' '),
            100
        ),
        mentionSpeciale: truncateValue(etab.geo_l5, 100),
        ville: truncateValue(
            sanitize(etab.libelle_commune) ||
                sanitize(etab.commune_etranger) ||
                '',
            100
        ),
        codePostal: truncateValue(sanitize(etab.code_postal) || '', 100),
        pays: sanitize(etab.code_pays_etranger),
        numeroTVAIntra: sanitize(etab.numero_tva_intra)
    };
};

const sanitize = (value) => (value === '[ND]' ? undefined : value);

const mutations = {
    async [MUTATION_TYPES.SET_CLIENT_PARTICIPANTS](state, payload) {
        state.participants = payload;
    },
    async [MUTATION_TYPES.SET_CLIENT_ENTITES_FACTURATION](state, payload) {
        state.entitesFacturation = payload;
    },
    async [MUTATION_TYPES.SET_ACTIVE_ENTREPRISE](state, payload) {
        state.profile.activeEntrepriseId = payload.id;
    },
    async [MUTATION_TYPES.SET_ENTREPRISE_PROFILE](state, payload) {
        state.profile.entreprises = payload.entreprises;
        if (
            !state.profile.activeEntrepriseId &&
            payload.entreprises.length > 0
        ) {
            state.profile.activeEntrepriseId =
                payload.entreprises[0].entrepriseId;
        }
    },
    async [MUTATION_TYPES.COPY_TEMP_CLIENT_FORM](state) {
        if (state.tempClientForm) {
            state.clientForm = {
                ...state.clientForm,
                ...state.tempClientForm,
                denomination: state.tempClientForm.denomination
                    ? state.tempClientForm.denomination
                    : state.clientForm.denomination
            };
        }
    },
    async [MUTATION_TYPES.SET_TEMP_CLIENT_FORM](state, payload) {
        state.tempClientForm = {
            ...state.tempClientForm,
            ...payload
        };
    },
    async [MUTATION_TYPES.FORCE_ENABLE_CLIENT_FORM](state, payload) {
        state.forceEnableClientForm = payload;
        if (payload) {
            state.clientForm = formInitialState;
            state.withClientForm = true;
        }
    },
    async [MUTATION_TYPES.FETCH_INSEE_INFOS](
        state,
        { activites, categoriesJuridiques }
    ) {
        state.activites = activites;
        state.categoriesJuridiques = categoriesJuridiques;
    },
    async [MUTATION_TYPES.RESET_CLIENT_FORM](state, payload) {
        state.clientForm = formInitialState;
        if (state.clientType !== 'particulier') {
            state.withClientForm = false;
        }
    },
    async [MUTATION_TYPES.SET_CLIENT_FORM](state, payload) {
        state.clientForm = {
            ...state.clientForm,
            valideSansSiret: payload.valideSansSiret,
            siret: payload.siret,
            ...payload
        };
    },
    async [MUTATION_TYPES.SET_CLIENT_TYPE](state, payload) {
        state.clientType = payload;
        state.withClientForm = payload === 'particulier';
        state.clientForm = formInitialState;
        state.forceEnableClientForm = false;
    },
    async [MUTATION_TYPES.SET_CLIENT_FORM_ATTRIBUTE](state, { name, value }) {
        state.clientForm = {
            ...state.clientForm,
            [name]: value
        };
    },
    async [MUTATION_TYPES.VALIDATE_CLIENT](state) {
        state.withClientForm = true;
    },
    async [MUTATION_TYPES.SET_CLIENT_FORM_ADDRESS_SIRET](state, payload) {
        const newPayload = mapInseeResult(payload);

        state.clientForm = {
            ...state.clientForm,
            ...newPayload
        };
    },

    async [MUTATION_TYPES.SET_CLIENT_FORM_ADDRESS_RNA](state, row) {
        const newPayload = {
            rna: row.id_association,
            denomination: row.titre_court || row.titre,
            identification: row.titre,
            destService: row.l2_adresse_import || row.adresse_gestion_nom,
            complement:
                row.l3_adresse_import ||
                row.adresse_gestion_format_postal ||
                row.adresse_siege,
            numeroVoie:
                row.adresse_gestion_libelle_voie ||
                [
                    row.adresse_numero_voie,
                    row.adresse_repetition,
                    row.adresse_type_voie,
                    row.adresse_libelle_voie
                ]
                    .join(' ')
                    .trim()
                    .replace(/\s+/gm, ' '),
            mentionSpeciale: row.adresse_gestion_distribution,
            codePostal: row.adresse_code_postal,
            ville: row.adresse_libelle_commune,
            pays: null
        };

        state.clientForm = {
            ...state.clientForm,
            ...newPayload
        };
    },
    async [MUTATION_TYPES.SET_ENTREPRISE_INFORMATIONS](state, payload) {
        state.entiteForm = {
            ...state.entiteForm,
            valideSansSiret: payload.valideSansSiret,
            siret: payload.siret,
            desactivationMotif: payload.desactivationMotif,
            ...payload
        };
        state.savedEntiteForm = { ...state.entiteForm };
    },
    async [MUTATION_TYPES.SET_ENTITE_FORM_ATTRIBUTE](state, { name, value }) {
        state.entiteForm = {
            ...state.entiteForm,
            [name]: value
        };
    },
    async [MUTATION_TYPES.SET_CLIENT_INTERLOCUTORS](state, payload) {
        state.interlocutors = payload.map((payload) => ({
            ...payload,
            droitAdministrateurLabel: payload.droitAdministrateur
                ? 'Oui'
                : 'Non',
            droitInscriptionLabel: payload.droitInscription ? 'Oui' : 'Non',
            droitAccesDocumentsLabel: payload.droitAccesDocuments
                ? 'Oui'
                : 'Non'
        }));
    }
};

const actions = {
    [ACTION_TYPES.COPY_TEMP_CLIENT_FORM]({ commit }) {
        commit(MUTATION_TYPES.COPY_TEMP_CLIENT_FORM);
    },
    [ACTION_TYPES.SET_TEMP_CLIENT_FORM]({ commit }, payload) {
        commit(MUTATION_TYPES.SET_TEMP_CLIENT_FORM, payload);
    },
    [ACTION_TYPES.FORCE_ENABLE_CLIENT_FORM]({ commit }, payload) {
        commit(MUTATION_TYPES.FORCE_ENABLE_CLIENT_FORM, payload);
    },
    [ACTION_TYPES.VALIDATE_CLIENT]({ commit }, payload) {
        commit(MUTATION_TYPES.VALIDATE_CLIENT, payload);
    },
    [ACTION_TYPES.RESET_CLIENT_FORM]({ commit }, payload) {
        commit(MUTATION_TYPES.RESET_CLIENT_FORM, payload);
    },
    [ACTION_TYPES.SET_CLIENT_FORM_ATTRIBUTE]({ commit }, payload) {
        commit(MUTATION_TYPES.SET_CLIENT_FORM_ATTRIBUTE, payload);
    },
    [ACTION_TYPES.SET_CLIENT_FORM]({ commit }, payload) {
        commit(MUTATION_TYPES.SET_CLIENT_FORM, payload);
    },
    [ACTION_TYPES.SET_CLIENT_FORM_ADDRESS_SIRET]({ commit }, payload) {
        commit(MUTATION_TYPES.SET_CLIENT_FORM_ADDRESS_SIRET, payload);
    },
    [ACTION_TYPES.SET_CLIENT_FORM_ADDRESS_RNA]({ commit }, payload) {
        commit(MUTATION_TYPES.SET_CLIENT_FORM_ADDRESS_RNA, payload);
    },
    [ACTION_TYPES.SET_CLIENT_TYPE]({ commit }, payload) {
        commit(MUTATION_TYPES.SET_CLIENT_TYPE, payload);
    },
    async [ACTION_TYPES.TEST_CLIENT]({ commit }, { clientType, siret, rna }) {
        commit(MUTATION_TYPES.VALIDATE_CLIENT);
        const params = {
            siret,
            rna,
            natureId: natureId[clientType]
        };

        return API.get('client/societe/recherche', { params });
    },
    async [ACTION_TYPES.SEND_CLIENT_FORM](
        { commit },
        { clientForm, clientType }
    ) {
        const body = {
            natureId: natureId[clientType],
            ...clientForm,
            siret: clientForm.siret ? clientForm.siret.replace(/\s/g, '') : null
        };

        return API.post('client/societe', body);
    },
    async [ACTION_TYPES.FETCH_INSEE_INFOS]({ commit }, payload) {
        const aResponse = await API.get('gestion/insee/nafv2.json');
        const cjResponse = await API.get('gestion/insee/cj.json');

        commit(MUTATION_TYPES.FETCH_INSEE_INFOS, {
            activites: aResponse.data,
            categoriesJuridiques: cjResponse.data
        });
    },
    async [ACTION_TYPES.GET_ENTREPRISE_PROFILE]({ commit }) {
        const response = await API.get('client/profile');

        commit(MUTATION_TYPES.SET_ENTREPRISE_PROFILE, response.data);

        return response;
    },
    async [ACTION_TYPES.SET_ACTIVE_ENTREPRISE]({ commit }, payload) {
        commit(MUTATION_TYPES.SET_ACTIVE_ENTREPRISE, payload);
    },
    async [ACTION_TYPES.GET_CLIENT_PARTICIPANTS]({ commit }, { clientId }) {
        const response = await API.get(
            `client/${clientId}/participants`,
            this.$header_skip_redirection
        );

        commit(MUTATION_TYPES.SET_CLIENT_PARTICIPANTS, response.data);
    },
    async [ACTION_TYPES.DELETE_CLIENT_PARTICIPANT](
        { commit },
        { clientId, contactId }
    ) {
        await API.delete(
            `client/${clientId}/participant/contact/${contactId}`,
            this.$header_skip_redirection
        );
    },
    async [ACTION_TYPES.ADD_CLIENT_PARTICIPANT](
        { commit },
        { clientId, participant }
    ) {
        const body = { ...participant };

        return API.post(
            `client/${clientId}/participant`,
            body,
            this.$header_skip_redirection
        );
    },
    async [ACTION_TYPES.GET_CLIENT_ENTITES_FACTURATION](
        { commit },
        { clientId }
    ) {
        const response = await API.get(`client/${clientId}/etablissements`);

        commit(MUTATION_TYPES.SET_CLIENT_ENTITES_FACTURATION, response.data);
    },
    async [ACTION_TYPES.DELETE_CLIENT_ENTITES_FACTURATION](
        { commit },
        { clientId, entiteId }
    ) {
        await API.delete(`client/${clientId}/etablissements/${entiteId}`);
    },
    async [ACTION_TYPES.ADD_CLIENT_ENTITES_FACTURATION](
        { commit },
        { clientId, entiteId }
    ) {
        return API.post(`client/${clientId}/etablissements/${entiteId}`, {});
    },
    async [ACTION_TYPES.GET_ENTREPRISE_INFORMATIONS]({ commit }, { clientId }) {
        const response = await API.get(`client/${clientId}/informations`);

        await commit(MUTATION_TYPES.SET_ENTREPRISE_INFORMATIONS, response.data);
    },
    [ACTION_TYPES.SET_ENTITE_FORM_ATTRIBUTE]({ commit }, payload) {
        commit(MUTATION_TYPES.SET_ENTITE_FORM_ATTRIBUTE, payload);
    },
    async [ACTION_TYPES.SEND_ENTREPRISE_INFORMATIONS](
        { commit },
        { entiteForm, clientId }
    ) {
        const body = {
            ...entiteForm,
            siret: entiteForm.siret ? entiteForm.siret.replace(/\s/g, '') : null
        };

        return API.put(`client/${clientId}/informations`, body);
    },
    async [ACTION_TYPES.POST_ENTREPRISE_DESACTIVER](
        { commit },
        { body, clientId }
    ) {
        return API.post(
            `client/${clientId}/desactiver`,
            body,
            this.$header_skip_redirection
        );
    },
    async [ACTION_TYPES.POST_ENTREPRISE_REACTIVER]({ commit }, { clientId }) {
        return API.post(
            `client/${clientId}/reactiver`,
            this.$header_skip_redirection
        );
    },
    async [ACTION_TYPES.GET_CLIENT_INTERLOCUTORS]({ commit }, { clientId }) {
        const response = await API.get(
            `client/${clientId}/contacts`,
            this.$header_skip_redirection
        );

        commit(MUTATION_TYPES.SET_CLIENT_INTERLOCUTORS, response.data);
    },
    async [ACTION_TYPES.DELETE_CLIENT_INTERLOCUTOR](
        { commit },
        { clientId, clientContactId }
    ) {
        await API.delete(
            `client/${clientId}/contact/${clientContactId}`,
            this.$header_skip_redirection
        );
    },
    async [ACTION_TYPES.ADD_CLIENT_INTERLOCUTOR](
        { commit },
        { clientId, interlocutor }
    ) {
        const body = { ...interlocutor };

        return API.post(
            `client/${clientId}/contact`,
            body,
            this.$header_skip_redirection
        );
    },
    async [ACTION_TYPES.UPDATE_CLIENT_INTERLOCUTOR](
        { commit },
        { clientId, interlocutor }
    ) {
        const body = { ...interlocutor };

        return API.put(
            `client/${clientId}/contact/${interlocutor.id}`,
            body,
            this.$header_skip_redirection
        );
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
