<template>
  <div class="w-full lg:h-full">
    <spinner
      v-if="loading"
      sizes="w-20 h-20"
      thickness="border-8" />
    <div
      v-if="!loading"
      class="my-4">
      <ag-grid-wrapper
        v-bind:buttons="[
          {
            label: 'Ajouter un interlocuteur entreprise',
            icon: 'user-plus',
            onClick: openAddInterlocutorModal
          }
        ]"
        v-bind:column_defs="[
          {
            headerName: 'Nom',
            field: 'nom',
            sort: 'asc',
            filter: 'agTextColumnFilter',
            cellRenderer: 'textCellRenderer'
          },
          {
            headerName: 'Prénom',
            field: 'prenom',
            filter: 'agTextColumnFilter',
            cellRenderer: 'textCellRenderer'
          },
          {
            headerName: 'Administrateur',
            field: 'droitAdministrateurLabel',
            filter: 'agTextColumnFilter',
            cellRenderer: 'coloredTextCenterCellRenderer'
          },
          {
            headerName: 'Droit d\'inscription',
            field: 'droitInscriptionLabel',
            filter: 'agTextColumnFilter',
            cellRenderer: 'coloredTextCenterCellRenderer'
          },
          {
            headerName: 'Accès aux documents',
            field: 'droitAccesDocumentsLabel',
            filter: 'agTextColumnFilter',
            cellRenderer: 'coloredTextCenterCellRenderer'
          },
          {
            headerName: 'Fonction',
            field: 'fonction',
            filter: 'agTextColumnFilter',
            cellRenderer: 'textCellRenderer'
          },
          {
            headerName: 'Téléphone',
            field: 'telephone',
            filter: 'agTextColumnFilter',
            cellRenderer: 'textCellRenderer'
          },
          {
            headerName: 'Email',
            field: 'email',
            filter: 'agTextColumnFilter',
            cellRenderer: 'textCellRenderer'
          }
        ]"
        v-bind:initial_data="interlocutors"
        v-on:delete-row="deleteRow"
        v-on:update-row="updateRow"
        v-bind:update_button="true"
        v-bind:delete_with_confirm_button="true"
        v-bind:delete_with_confirm_modal_content="deleteModalContent"
        v-on:change-delete-modal-content="changeDeleteModalContent" />
      <interlocutor-modal
        v-bind:show_modal="interlocutorModal"
        v-bind:onclose="closeInterlocutorModal"
        v-bind:active_interlocutor="activeInterlocutor"
        v-bind:available_participants="availableParticipants"
        v-on:add-interlocutor="addInterlocutor"
        v-on:update-interlocutor="updateInterlocutor" />
      <message-modal
        v-bind:show_modal="messageModal"
        v-bind:message="message"
        v-bind:onclose="closeMessageModal" />
    </div>
  </div>
</template>
<script>
  import AgGridWrapper from '@/components/utils/ag-grid/ag-grid-wrapper';
  import Spinner from '@/components/utils/spinner.vue';
  import { ACTION_TYPES } from '@/store/constants/ActionTypes';
  import InterlocutorModal from '@/views/_espace/_entreprise/_entreprise/_interlocutors/interlocutor-modal';
  import MessageModal from '@/views/_espace/_entreprise/_entreprise/_interlocutors/message-modal';
  import { createNamespacedHelpers } from 'vuex';

  const { mapState: mapStateClient, mapActions: mapActionsClient } =
    createNamespacedHelpers('client');

  export default {
    components: {
      AgGridWrapper,
      Spinner,
      InterlocutorModal,
      MessageModal
    },
    data() {
      return {
        loading: false,
        interlocutorModal: false,
        messageModal: false,
        deleteModalContent: null,
        activeEntrepriseLabel: null,
        activeInterlocutor: null,
        availableParticipants: null,
        message: null
      };
    },
    computed: {
      ...mapStateClient(['profile', 'interlocutors', 'participants'])
    },
    watch: {
      async ['profile.activeEntrepriseId']() {
        await this.fetchInterlocutors();
      }
    },
    async created() {
      await this.fetchInterlocutors();
      this.getEntrepriseLabel();
      this.changeDeleteModalContent();
    },
    methods: {
      ...mapActionsClient([
        ACTION_TYPES.GET_CLIENT_INTERLOCUTORS,
        ACTION_TYPES.DELETE_CLIENT_INTERLOCUTOR,
        ACTION_TYPES.ADD_CLIENT_INTERLOCUTOR,
        ACTION_TYPES.UPDATE_CLIENT_INTERLOCUTOR,
        ACTION_TYPES.GET_CLIENT_PARTICIPANTS
      ]),
      openAddInterlocutorModal() {
        this.interlocutorModal = true;
        this.activeInterlocutor = null;
      },
      closeInterlocutorModal() {
        this.interlocutorModal = false;
      },
      openMessageModal() {
        this.messageModal = true;
      },
      closeMessageModal() {
        this.messageModal = false;
      },
      async addInterlocutor(interlocutor) {
        this.loading = true;
        const clientId = this.profile.activeEntrepriseId;

        if (
          this.interlocutors.findIndex((i) => i.email === interlocutor.email) >
          -1
        ) {
          this.openMessageModal();
          this.message =
            'Un interlocuteur entreprise existe déjà avec cette adresse e-mail.';
        } else {
          const response = await this[ACTION_TYPES.ADD_CLIENT_INTERLOCUTOR]({
            clientId,
            interlocutor
          });

          if (response.status === 200) {
            this.openMessageModal();
            this.message =
              'Cet interlocuteur entreprise existe déjà comme contact avec cette adresse mail, ses informations ont été remplies automatiquement.';
          }

          if (response.status === 403) {
            this.message = response.data.message;
            this.openMessageModal();
          }
          await this[ACTION_TYPES.GET_CLIENT_INTERLOCUTORS]({ clientId });
          await this[ACTION_TYPES.GET_CLIENT_PARTICIPANTS]({ clientId });
          await this.getAvailableParticipants();
        }

        this.loading = false;
      },
      async updateInterlocutor(interlocutor) {
        this.loading = true;
        const clientId = this.profile.activeEntrepriseId;

        try {
          await this[ACTION_TYPES.UPDATE_CLIENT_INTERLOCUTOR]({
            clientId,
            interlocutor
          });
        } catch (e) {
          if (e.response.status === 403) {
            this.message = e.response.data.message;
            this.openMessageModal();
          }
        }

        await this[ACTION_TYPES.GET_CLIENT_INTERLOCUTORS]({ clientId });
        await this[ACTION_TYPES.GET_CLIENT_PARTICIPANTS]({ clientId });
        await this.getAvailableParticipants();
        this.loading = false;
      },
      async fetchInterlocutors() {
        this.loading = true;
        const clientId = this.profile.activeEntrepriseId;

        if (clientId) {
          await this[ACTION_TYPES.GET_CLIENT_INTERLOCUTORS]({ clientId });
          await this[ACTION_TYPES.GET_CLIENT_PARTICIPANTS]({ clientId });
          await this.getAvailableParticipants();
        }
        this.loading = false;
      },
      async deleteRow(value) {
        this.loading = true;
        const clientId = this.profile.activeEntrepriseId;

        if (clientId) {
          try {
            await this[ACTION_TYPES.DELETE_CLIENT_INTERLOCUTOR]({
              clientId,
              clientContactId: value.id
            });
          } catch (e) {
            if (e.response.status === 403) {
              this.message = e.response.data.message;
              this.openMessageModal();
            }
          }

          await this[ACTION_TYPES.GET_CLIENT_INTERLOCUTORS]({ clientId });
          await this[ACTION_TYPES.GET_CLIENT_PARTICIPANTS]({ clientId });
          await this.getAvailableParticipants();
        }
        this.loading = false;
      },
      updateRow(value) {
        this.interlocutorModal = true;
        const interlocutor = this.interlocutors.find((i) => i.id == value.id);

        this.activeInterlocutor = { ...interlocutor };
      },
      changeDeleteModalContent(data) {
        this.deleteModalContent = {
          title: "Supprimer l'interlocuteur entreprise",
          description: data
            ? `Vous êtes sur le point de supprimer ${data.prenom} ${data.nom} en tant qu'interlocuteur entreprise${this.activeEntrepriseLabel ? ` de ${this.activeEntrepriseLabel}` : ''}. Voulez-vous continuer ?`
            : 'Vous êtes sur le point de supprimer cet interlocuteur entreprise. Voulez-vous continuer ?'
        };
      },
      getEntrepriseLabel() {
        const activeEntreprise = this.profile.entreprises.find(
          (entreprise) =>
            entreprise.entrepriseId === this.profile.activeEntrepriseId
        );

        this.activeEntrepriseLabel = activeEntreprise
          ? activeEntreprise.denomination
          : null;
      },
      async getAvailableParticipants() {
        const filteredParticipants = this.participants.filter(
          (participant) =>
            !this.interlocutors.find(
              (interlocutor) => interlocutor.email === participant.email
            )
        );

        if (filteredParticipants.length) {
          this.availableParticipants = filteredParticipants.map(
            (participant) => ({
              ...participant,
              label: `${participant.prenom} ${participant.nom}`
            })
          );
        } else {
          this.availableParticipants = null;
        }
      }
    }
  };
</script>
<style lang="scss" scoped></style>
