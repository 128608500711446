<template>
  <modal-box
    v-if="modal_show"
    v-bind:width_class="'w-full max-w-screen-md'"
    v-bind:fill_height="true"
    v-on:close="close"
    v-bind:footer="withClientForm"
    overflow_body>
    <template v-slot:modal-header>
      <h1 class="text-xl">{{ title }}</h1>
    </template>

    <template v-slot:modal-body>
      <div class="h-full w-full lg:px-6">
        <confirm-modal
          v-bind:show_modal="showConfirmModal"
          v-bind:onclose="closeConfirmModal"
          v-bind:submit="onConfirmModalSubmit" />
        <div class="flex w-full flex-wrap">
          <client-search v-bind:hide_particulier="hide_particulier" />
        </div>

        <transition name="fade">
          <div
            v-if="withClientForm"
            class="flex w-full flex-wrap">
            <client-form
              ref="client_form"
              v-bind:onclose="close"
              v-bind:on_client_form_submit="on_client_form_submit" />
          </div>
        </transition>
      </div>
    </template>
    <template v-slot:modal-footer>
      <div class="flex w-full justify-end">
        <button
          v-if="
            clientType === 'siret' && forceEnableClientForm && withClientForm
          "
          class="w-56 rounded bg-jinius-dark-green px-4 py-3 font-bold uppercase text-white hover:bg-jinius-green"
          v-on:click.stop="onSubmitCreateEntreprise">
          Enregistrer
        </button>
        <button
          v-else-if="withClientForm"
          class="w-56 rounded bg-jinius-dark-green px-4 py-3 font-bold uppercase text-white hover:bg-jinius-green"
          v-on:click.stop="onSubmit">
          Enregistrer
        </button>
      </div>
    </template>
  </modal-box>
</template>
<script>
  import modalBox from '@/components/utils/modal-box.vue';
  import api_insee_helpers from '@/mixin/api_insee_helpers';
  import { ACTION_TYPES } from '@/store/constants/ActionTypes';
  import { mapInseeResult } from '@/store/modules/client';
  import ClientForm from '@/views/_espace/_account/_informations/_client/client-form';
  import ClientSearch from '@/views/_espace/_account/_informations/_client/client-search';
  import ConfirmModal from '@/views/_espace/_account/_informations/_modal/confirm-modal';
  import { createNamespacedHelpers } from 'vuex';

  const { mapState: mapStateClient, mapActions: mapActionsClient } =
    createNamespacedHelpers('client');

  export default {
    components: {
      modalBox,
      ClientSearch,
      ClientForm,
      ConfirmModal
    },
    mixins: [api_insee_helpers],
    props: [
      'modal_show',
      'onclose',
      'title',
      'on_client_form_submit',
      'hide_particulier'
    ],
    data() {
      return { showConfirmModal: false };
    },
    computed: {
      ...mapStateClient([
        'clientType',
        'withClientForm',
        'forceEnableClientForm',
        'clientForm'
      ])
    },
    methods: {
      ...mapActionsClient([
        ACTION_TYPES.RESET_CLIENT_FORM,
        ACTION_TYPES.SET_TEMP_CLIENT_FORM,
        ACTION_TYPES.TEST_CLIENT,
        ACTION_TYPES.COPY_TEMP_CLIENT_FORM
      ]),
      closeConfirmModal() {
        this.showConfirmModal = false;
      },
      close() {
        if (!this.showConfirmModal) {
          this[ACTION_TYPES.RESET_CLIENT_FORM]();
          this.onclose();
        }
      },
      async onConfirmModalSubmit(e) {
        e.preventDefault();
        await this[ACTION_TYPES.COPY_TEMP_CLIENT_FORM]();
        this.closeConfirmModal();
        this.$refs.client_form.submit(e);
      },
      onSubmit(e) {
        e.preventDefault();
        this.$refs.client_form.submit(e);
      },
      // Creer une entreprise
      async onSubmitCreateEntreprise(e) {
        e.preventDefault();
        const siret =
          this.clientForm.siret && this.clientForm.siret.replace(/\s/g, '');

        if (siret) {
          // Existe base safir ?
          const response = await this[ACTION_TYPES.TEST_CLIENT]({
            siret,
            clientType: this.clientType
          });

          if (response && response.data) {
            this[ACTION_TYPES.SET_TEMP_CLIENT_FORM](response.data);
            this.showConfirmModal = true;

            return;
          }

          // Existe base insee ?
          const fetchResponseInsee = await this.getBySiret(siret);
          const responseInsee =
            fetchResponseInsee && fetchResponseInsee.etablissement;

          if (responseInsee) {
            this[ACTION_TYPES.SET_TEMP_CLIENT_FORM](
              mapInseeResult(responseInsee[0])
            );
            this.showConfirmModal = true;

            return;
          }
        }

        this.$refs.client_form.submit(e);
      }
    }
  };
</script>
<style lang="scss" scoped>
  :deep(.primary_button) {
    @apply mb-2 ml-0 rounded-md border border-core-500 bg-core-900 p-3 font-bold text-white hover:bg-jinius-dark-green;
    &:disabled {
      @apply pointer-events-none border-none bg-core-300 text-core-500;
    }
  }
</style>
