<template>
  <div
    v-bind:class="`flex w-full flex-wrap ${class_name ? class_name : 'input-block'} ${disabled ? 'disabled' : ''}`">
    <form-label
      v-if="label"
      v-bind:label="label"
      v-bind:icon="label_icon"
      v-bind:required="required"
      v-bind:not_required_label="not_required_label"
      class="font-bold leading-none" />
    <div class="flex w-full items-center space-x-2">
      <font-awesome-icon
        size="lg"
        v-if="left_icon"
        class="left__icon flex"
        v-bind:icon="left_icon" />
      <span
        v-if="left_icon && required && !label"
        class="required__icon"
        >*</span
      >

      <input
        v-bind="$attrs"
        v-model="input_value"
        v-imask="imask"
        v-on:accept="updateValueUnmasked"
        v-bind:unmask="true"
        v-bind:class="class_name ? class_name : 'input-block'"
        v-bind:type="type ? type : 'text'"
        v-on:input="!imask && updateValue($event)"
        v-bind:maxlength="maxlength"
        v-bind:placeholder="placeholderComputed"
        v-on:keypress="format === 'numeric' && onlyNumber($event)"
        v-bind:disabled="disabled"
        v-on:click="onclick"
        v-on:keydown.enter.stop.prevent="on_keydown_enter" />
    </div>
  </div>
</template>

<script>
  import formLabel from '@/components/utils/form-label.vue';
  import { IMaskDirective } from 'vue-imask';

  export default {
    directives: { imask: IMaskDirective },
    components: { formLabel },
    inheritAttrs: false,
    props: {
      mask: { type: [Boolean, String] },
      format: { type: String },
      maxlength: { type: Number },
      value: { type: String },
      name: { type: String },
      type: { type: String },
      label: { type: String },
      label_icon: { type: [String, Array] },
      placeholder: { type: String },
      required: {
        type: Boolean,
        default: false
      },
      class_name: { type: String },
      input_wrapper_class: { type: String },
      left_icon: { type: [String, Array] },
      disabled: { type: Boolean },
      not_required_label: { type: Boolean },
      onclick: {
        type: Function,
        default: () => {}
      },
      on_keydown_enter: {
        type: Function,
        default: () => {}
      }
    },
    emits: ['emit-name', 'input'],
    data() {
      return {
        input_value: this.value,
        imask: this.mask ? { mask: `${this.mask}` } : null
      };
    },
    computed: {
      placeholderComputed() {
        return this.placeholder || `Saisir ${this.label.toLowerCase()}`;
      }
    },
    watch: {
      value(newVal, oldVal) {
        if (!this.imask) {
          if (!newVal) {
            this.input_value = '';
          } else {
            const formattedValue = this.formatValue(newVal, this.format);

            this.input_value = formattedValue;
          }
        }
      }
    },
    methods: {
      formatValue(value, format) {
        if (format === 'uppercase') {
          return value && value.toUpperCase();
        }
        if (format === 'capitalizeFirstLetter') {
          return (
            value &&
            value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
          );
        }

        return value;
      },
      updateValue(e) {
        this.$emit('emit-name', this.name);
        this.$emit('input', this.input_value);
      },
      updateValueUnmasked(e) {
        const maskRef = e.detail;

        this.input_value = maskRef.unmaskedValue;
        this.updateValue(e);
      },
      onlyNumber($event) {
        const keyCode = $event.keyCode ? $event.keyCode : $event.which;

        if ([32, 46].includes(keyCode)) {
          // 46 is dot, 322 is space
          return;
        }
        if (keyCode < 48 || keyCode > 57) {
          $event.preventDefault();
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .input-block {
    input {
      @apply flex w-full appearance-none rounded border border-core-700 bg-white px-4 py-3 text-core-700;

      &:focus {
        @apply border-core-500 outline-none;
      }
      &:disabled {
        @apply bg-core-300;
      }
    }
  }

  .no-box {
    .required__icon {
      @apply flex font-bold text-jinius-red;
    }
    input {
      @apply flex w-full flex-grow appearance-none truncate border-b-2 border-core-400 bg-transparent px-6 pb-2 pt-3 text-core-700 transition duration-1000 ease-in-out;
      &:focus {
        @apply border-jinius-green bg-gradient-to-r from-white via-white to-white outline-none;
      }

      &:disabled {
        @apply pointer-events-none cursor-not-allowed;
      }
    }
    &.disabled {
      @apply cursor-not-allowed;
      .left__icon {
        @apply pointer-events-none text-core-400;
      }
      .required__icon {
        @apply pointer-events-none text-core-400;
      }
    }
    .left__icon {
      @apply text-core-900;
    }
  }

  .input-error {
    @apply w-full border border-jinius-red text-jinius-red #{!important};
    .left__icon {
      @apply text-jinius-red;
    }

    label {
      @apply text-jinius-red;
    }
    input::placeholder {
      @apply text-jinius-red;
    }
  }
</style>
