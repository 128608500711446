<template>
  <div
    class="refonte-filters-applied-item"
    v-bind:class="props.class"
    v-on:click="props.on_close">
    <span>
      <slot></slot>
    </span>
    <div class="filters-applied-item-icon">
      <IconClose />
    </div>
  </div>
</template>

<script setup lang="ts">
  import IconClose from '@/assets/img/refonte/icons/display/icon-close.svg?component';

  const props = withDefaults(
    defineProps<{
      on_close?: Function;
      class?: string;
    }>(),
    {
      class: ''
    }
  );
</script>

<style lang="scss" scoped>
  .refonte-filters-applied-item {
    @apply inline-flex cursor-pointer items-center justify-center bg-refonte_blue-400 text-refonte-white;
    gap: 4px;
    border-radius: 26px;
    padding: 5px 10px;

    span {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
    .filters-applied-item-icon {
      @apply flex items-center justify-center;
      padding: 5px;

      :deep(svg) {
        @apply text-refonte-white;
        min-width: 8px;
        width: 8px;
        height: 8px;
      }
    }

    &:hover,
    &:active,
    &:focus {
      @apply bg-refonte_blue-600;
    }
  }
</style>
