export const CLIENT_ACTION_TYPES = {
    SET_CLIENT_FORM: 'setClientFormPayload',
    SET_CLIENT_FORM_ATTRIBUTE: 'setClientFormAttribute',
    SET_CLIENT_FORM_ADDRESS_SIRET: 'setClientFormAddressSiret',
    SET_CLIENT_FORM_ADDRESS_RNA: 'setClientFormAddressRna',
    SET_CLIENT_TYPE: 'setClientType',
    RESET_CLIENT_FORM: 'resetClientForm',
    TEST_CLIENT: 'testClient',
    SEND_CLIENT_FORM: 'sendClientForm',
    FETCH_INSEE_INFOS: 'fetchInseeInfos',
    VALIDATE_CLIENT: 'validateClientForm',
    FORCE_ENABLE_CLIENT_FORM: 'forceEnableClientForm',
    SET_TEMP_CLIENT_FORM: 'setTempClientForm',
    COPY_TEMP_CLIENT_FORM: 'copyTempClientForm',
    GET_ENTREPRISE_PROFILE: 'getEntrepriseProfile',
    SET_ACTIVE_ENTREPRISE: 'setActiveEntreprise',
    GET_CLIENT_PARTICIPANTS: 'getClientParticipant',
    DELETE_CLIENT_PARTICIPANT: 'deleteClientParticipant',
    ADD_CLIENT_PARTICIPANT: 'addClientParticipant',
    GET_CLIENT_ENTITES_FACTURATION: 'getClientEntitesFacturation',
    DELETE_CLIENT_ENTITES_FACTURATION: 'deleteClientEntitesFacturation',
    ADD_CLIENT_ENTITES_FACTURATION: 'addClientEntitesFacturation',
    GET_ENTREPRISE_INFORMATIONS: 'getEntrepriseInformations',
    SET_ENTITE_FORM_ATTRIBUTE: 'setEntiteFormAttribute',
    SEND_ENTREPRISE_INFORMATIONS: 'sendEntrepriseInformations',
    POST_ENTREPRISE_DESACTIVER: 'postEntrepriseDesactiver',
    POST_ENTREPRISE_REACTIVER: 'postEntrepriseReactiver',
    GET_CLIENT_INTERLOCUTORS: 'getClientInterlocutors',
    DELETE_CLIENT_INTERLOCUTOR: 'deleteClientInterlocutor',
    ADD_CLIENT_INTERLOCUTOR: 'addClientInterlocutor',
    UPDATE_CLIENT_INTERLOCUTOR: 'updateClientInterlocutor'
};

export const ACTION_TYPES = {
    SET_SPACE: 'setSpace',
    SET_SUB_SPACE: 'setSubSpace',
    GET_PARTICIPANT_CATALOGUE: 'getParticipantCatalogue',
    SET_CATALOGUE_LIST: 'setCatalogueList',
    SET_MODAL: 'setModal',
    SET_FORM_ATTRIBUTE: 'setFormAttribute',
    SEND_CV: 'sendCV',
    GET_CV: 'getCV',
    GET_CV_CONSETEMENT: 'getCvConsentement',
    GET_THEMES: 'getThemes',
    SET_RUBRIQUE: 'setRubrique',
    SET_ESPACE: 'setEspace',
    SET_ACCOUNT_FORM_ATTRIBUTE: 'setAccountFormAttribute',
    FILL_ACCOUNT_FORM_IF_EMPTY: 'fillAccountFormIfEmpty',
    GET_CONTACT: 'getContact',
    GET_RATTACHEMENT: 'getRattachement',
    GET_CLUBS: 'getClubs',
    GET_STAGIAIRE_PROFILE: 'getStagiaireProfile',
    SEND_CONTACT: 'sendContact',
    GET_CONTACT_PROFILE: 'getContactProfile',
    GET_FORMATEUR_PROFILE: 'getFormateurProfile',
    SET_PREFERENCES_FORM_ORGANISMES: 'setPreferencesFormOrganismes',
    SET_PREFERENCES_FORM_SECTIONS_VALUE: 'setPreferencesFormSectionsValue',
    SET_PREFERENCES_FORM_ALL_PREFERENCES: 'setPreferencesFormAllPreferences',
    GET_PREFERENCES: 'getPreferences',
    SEND_PREFERENCES: 'sendPreferences',
    SET_MODAL_CHECK: 'setModalCheck',
    SET_MODAL_CONFIRMED: 'setModalConfirmed',
    SET_MODAL_ROUTE: 'setModalRoute',
    ...CLIENT_ACTION_TYPES
};
