<template>
  <div
    class="refonte-datepicker"
    v-bind:class="props.class">
    <vue-date-picker
      ref="datepicker"
      v-bind:teleport="true"
      v-bind:id="id"
      v-model="datepicker_value"
      v-on:update:model-value="updateValue"
      calendar-class="datepicker-calendar"
      input-class="datepicker-input"
      v-bind:auto-apply="true"
      v-bind:placeholder="props.placeholder"
      v-bind:class="`datepicker ${props.datepicker_class}`"
      v-bind:clear-button="props.show_clear_button"
      v-bind:typeable="props.typeable"
      v-bind:disabled="props.disabled"
      v-bind:min-date="props.min_date"
      locale="fr"
      v-bind:format="customFormatter"
      v-bind:parser="customParser"
      v-bind:monday-first="true"
      v-on:opened="toggleOpen(true)"
      v-on:closed="toggleOpen(false)">
      <template v-slot:input-icon>
        <div class="datepicker-input-icon">
          <IconDate v-if="!datepicker_value" />
        </div>
      </template>
      <template v-slot:clear-icon="{ clear }">
        <div
          v-on:click="clear"
          class="datepicker-clear-icon">
          <IconClose />
        </div>
      </template>
    </vue-date-picker>
  </div>
</template>

<script setup lang="ts">
  import VueDatePicker from '@vuepic/vue-datepicker';
  import { ref, watch } from 'vue';
  import { format, Locale, parse } from 'date-fns';
  import frLocale from 'date-fns/locale/fr';
  import IconDate from '@/assets/img/refonte/icons/information/icon-date.svg?component';
  import IconClose from '@/assets/img/refonte/icons/display/icon-close.svg?component';

  const props = withDefaults(
    defineProps<{
      id?: string;
      value: undefined | Date;
      placeholder?: string;
      date_format?: string;
      min_date?: Date;
      disabled?: boolean;
      show_clear_button?: boolean;
      typeable?: boolean;
      class?: string;
      datepicker_class?: string;
    }>(),
    {
      id: 'datepicker_input',
      placeholder: 'Date exacte (JJ/MM/YYYY)',
      date_format: 'dd/MM/yyyy',
      disabled: false,
      show_clear_button: true,
      typeable: true,
      class: '',
      datepicker_class: ''
    }
  );

  const opener = ref<boolean>(false);
  const datepicker_value = ref<Date | undefined>(props.value);

  const emit = defineEmits(['input']);

  function toggleOpen(bool: boolean) {
    opener.value = bool;

    // Select input's value on focus after event "opened"
    const input: any = document.getElementById(props.id);
    const hasFocus = input === document.activeElement;

    opener.value && hasFocus && input && input.select();
  }

  const updateValue = () => {
    emit('input', datepicker_value);
  };

  function customParser(date: string) {
    return parse(date, props.date_format, new Date(), {
      locale: frLocale as unknown as Locale
    });
  }

  function customFormatter(date: Date) {
    return format(date, props.date_format, {
      locale: frLocale as unknown as Locale
    });
  }

  watch(
    () => props.value,
    (newValue, oldValue) => {
      if (!newValue) {
        datepicker_value.value = oldValue;
      } else {
        datepicker_value.value = newValue;
      }
    }
  );
</script>

<style lang="scss" scoped>
  .refonte-datepicker {
    min-width: 201px;

    .datepicker-input-icon {
      @apply flex shrink-0 items-center justify-center;
      width: 28px;
      height: 28px;

      :deep(svg) {
        @apply text-refonte_blue-600;
        width: 16px;
        height: 16px;
      }
    }
    .datepicker-clear-icon {
      @apply flex shrink-0 items-center justify-center;
      width: 28px;
      height: 28px;

      :deep(svg) {
        @apply text-refonte_blue-600;
        width: 10px;
        height: 10px;
      }
    }
    :deep(.datepicker) {
      input {
        @apply border-0 text-refonte_blue-600;
        padding: 4px 38px 4px 8px;
        border-radius: 4px;
        min-height: 36px;
        font-size: 12px;
        font-weight: 400;
        line-height: 120%;

        &::placeholder {
          @apply text-refonte_blue-200;
        }
      }
      .dp__input_icon {
        inset-inline-start: unset;
        inset-inline-end: 4px;
      }
      .dp__clear_icon {
        inset-inline-end: 4px;
      }
    }
  }
</style>
