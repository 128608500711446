<template>
  <refonte-modal
    v-bind:show="props.show"
    v-on:close="props.on_close"
    title="Information">
    <template v-slot:modalBody>
      <span>
        {{ props.content }}
      </span>
    </template>
  </refonte-modal>
</template>

<script setup lang="ts">
  import RefonteModal from '@/components/refonte/modals/refonte-modal.vue';

  const props = withDefaults(
    defineProps<{
      show: boolean;
      on_close: Function;
      content: string;
      class?: string;
    }>(),
    {
      class: ''
    }
  );
</script>

<style lang="scss" scoped></style>
