<template>
  <div
    class="refonte-date-range"
    v-bind:class="props.class">
    <div
      class="refonte-date-range-icon"
      v-bind:class="{ 'date-range-icon-between': currentDateBetween() }">
      <IconDateRange />
    </div>
    <div class="refonte-date-range-text">
      <span
        v-bind:class="{
          'date-today':
            parseToFrDate(props.start_date) === parseToFrDate(todayDate)
        }"
        >{{ parseToFrDate(props.start_date) }}</span
      >
      -
      <span
        v-bind:class="{
          'date-today':
            parseToFrDate(props.end_date) === parseToFrDate(todayDate)
        }"
        >{{ parseToFrDate(props.end_date) }}</span
      >
    </div>
  </div>
</template>

<script setup lang="ts">
  import { format, Locale } from 'date-fns';
  import fr from 'date-fns/locale/fr';
  import IconDateRange from '@/assets/img/refonte/icons/information/icon-date-range.svg?component';

  const props = withDefaults(
    defineProps<{
      start_date: Date;
      end_date: Date;
      class?: string;
    }>(),
    {
      class: ''
    }
  );

  const todayDate: Date = new Date();

  function parseToFrDate(date: Date) {
    return format(date.toString(), 'dd/MM/yyyy', {
      locale: fr as unknown as Locale
    });
  }

  function currentDateBetween() {
    todayDate.setHours(0, 0, 0, 0);
    props.start_date.setHours(0, 0, 0, 0);
    props.end_date.setHours(0, 0, 0, 0);
    return todayDate >= props.start_date && todayDate <= props.end_date;
  }
</script>

<style lang="scss" scoped>
  .refonte-date-range {
    @apply inline-flex shrink-0 items-center;
    gap: 2px;

    .refonte-date-range-icon {
      @apply flex shrink-0 items-center justify-center;
      width: 20px;
      height: 20px;

      :deep(svg) {
        @apply text-refonte_blue-400;
        width: 16px;
        height: 16px;
      }

      &.date-range-icon-between {
        :deep(svg) {
          @apply text-refonte_blue-800;
        }
      }
    }
    .refonte-date-range-text {
      @apply text-refonte_blue-400;
      font-size: 12px;
      font-weight: 400;
      line-height: 120%;

      span {
        &.date-today {
          @apply text-refonte_blue-800;
        }
      }
    }
  }
</style>
