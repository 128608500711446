<template>
  <doughnut-chart
    v-bind:data="chartData"
    v-bind:options="chartOptions"
    v-bind:styles="
      $breakpoints.w >= $breakpoints.screens.sm
        ? { width: '600px', height: '180px' }
        : { width: '300px', height: '180px' }
    " />
</template>

<script>
  import { tailwindsColors } from '@/assets/data/tailwind-config-resolver';
  import DoughnutChart from '@/components/utils/chartjs/DoughnutChart';

  export default {
    components: { DoughnutChart },
    props: {
      value: Number,
      title: String
    },
    data() {
      return {
        chartOptions: {
          maintainAspectRatio: false,
          hover: { mode: null },
          tooltips: { enabled: false },
          title: {
            text: this.title,
            display: true
          },
          cutoutPercentage: 70,
          plugins: {
            doughnutlabel: {
              labels: [
                {
                  text: this.value + '%',
                  font: { size: '50' },
                  color: tailwindsColors['jinius-green']
                }
              ]
            }
          }
        },
        chartData: {
          datasets: [
            {
              backgroundColor: [tailwindsColors['jinius-green'], 'lightGray'],
              data: [this.value, 100 - this.value]
            }
          ]
        }
      };
    }
  };
</script>

<style lang="scss" scoped></style>
