<template>
  <div class="h-full w-full">
    <spinner
      v-if="loading"
      sizes="w-20 h-20"
      thickness="border-8" />

    <div
      v-if="!loading"
      class="flex h-full flex-col">
      <h1 class="section__title mb-8 mt-0">
        Synthèse des appréciations participants par formation
      </h1>
      <ag-grid-wrapper
        v-bind:column_defs="[
          { headerName: 'Ref.', field: 'reference' },
          { headerName: 'Campagne', field: 'campagne' },
          { headerName: 'Titre', field: 'titre' },
          {
            headerName: 'Détails',
            field: 'details',
            cellRendererParams: {
              alwaysDisplay: true,
              label: 'DETAILS',
              onclick: (e, params) => openDetailsModal(e, params.data)
            }
          }
        ]"
        v-bind:initial_data="formations" />
    </div>
    <details-modal
      v-if="formation"
      v-bind:show_modal="showDetailModal"
      v-bind:formation="formation"
      v-bind:onclose="closeDetailsModal" />
  </div>
</template>
<script>
  import agGridWrapper from '@/components/utils/ag-grid/ag-grid-wrapper';
  import spinner from '@/components/utils/spinner.vue';
  import { API } from '@/http-common';
  import detailsModal from '@/views/_espace/_formateur/_appreciations/_modal/formation-details-modal';

  export default {
    components: {
      agGridWrapper,
      spinner,
      detailsModal
    },
    data: () => ({
      loading: false,
      formations: [],
      formation: null,
      showDetailModal: false
    }),
    created() {
      this.fetchFormations();
    },
    methods: {
      openDetailsModal(e, formation) {
        this.formation = formation;
        this.showDetailModal = true;
      },
      closeDetailsModal() {
        this.formation = null;
        this.showDetailModal = false;
      },
      async fetchFormations() {
        this.loading = true;
        const response = await API.get(
          'formateur/satisfaction/formations',
          this.$header_skip_redirection
        );

        this.formations = response.data;
        this.loading = false;
      }
    }
  };
</script>
<style lang="scss" scoped></style>
