<template>
  <div
    class="centered"
    v-if="(params.value && params.label) || params.withValue"
    v-on:click="params.onclick($event, params)">
    {{ params.withValue ? params.value : params.label }}
  </div>
</template>
<script>
  export default {};
</script>
<style lang="scss" scoped>
  .centered {
    @apply flex w-full cursor-pointer items-center justify-center;
  }
</style>
