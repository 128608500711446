<template>
  <div
    v-if="entite"
    class="mb-8 mt-4 flex w-full flex-wrap items-center rounded border-none bg-core-300 px-10 py-8 shadow-md"
    v-bind:class="{ 'min-h-[200px]': context_facturation }">
    <div
      v-if="entite.denomination"
      class="mb-2 flex w-full items-center text-xl font-bold">
      <font-awesome-icon
        size="sm"
        v-bind:icon="`${entite.natureId == 3 ? 'user' : 'building'}`" />
      <span class="mx-3 space-x-1">
        <span>{{ entite.denomination }}</span>
        <span
          v-if="entite.natureId == 3 && optional_email"
          class="font-normal text-accent-500">
          (&nbsp;{{ entite.email || user.email }}&nbsp;)
        </span>
      </span>
    </div>

    <div class="w-full px-6">
      <div v-if="entite.adresse">
        {{ entite.adresse }}
      </div>
      <div v-if="entite.numeroVoie">
        {{ entite.numeroVoie }}
      </div>
      <div v-if="entite.codePostal || entite.ville">
        {{
          `${entite.codePostal ? entite.codePostal : ''} ${entite.ville ? entite.ville : ''}`
        }}
      </div>
      <div
        v-if="entite.siret"
        class="my-2 italic">
        (SIRET : {{ entite.siret }})
      </div>
    </div>

    <slot></slot>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex';
  const { mapState: mapStateAuth } = createNamespacedHelpers('auth');

  export default {
    props: {
      entite: { type: Object },
      optional_email: { type: Boolean },
      context_facturation: { type: Boolean }
    },
    computed: { ...mapStateAuth(['user']) }
  };
</script>

<style lang="scss" scoped></style>
