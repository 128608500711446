<template>
  <modal-box
    v-if="modal_show"
    v-bind:width_class="'w-full max-w-screen-md'"
    v-bind:footer="false"
    v-on:close="onclose"
    overflow_body>
    <template v-slot:modal-header>
      <h1 class="text-xl font-bold text-core-900">
        Mise à jour des informations personnelles du compte
      </h1>
    </template>

    <template v-slot:modal-body>
      <div class="my-3">
        En tant que membre ANECS & CJEC, veuillez renseigner votre date de fin
        d'adhésion.
      </div>
    </template>
  </modal-box>
</template>
<script>
  import modalBox from '@/components/utils/modal-box.vue';

  export default {
    components: { modalBox },
    props: ['modal_show', 'onclose']
  };
</script>
<style lang="scss" scoped>
  .primary__button {
    @apply w-64 cursor-pointer rounded bg-primary-900 px-5 py-3 text-center font-bold text-white shadow hover:bg-primary-800;
  }
  .secondary__button {
    @apply w-64 cursor-pointer rounded bg-core-300 px-5 py-3 text-center font-bold text-core-700 shadow hover:bg-core-200;
  }
</style>
