<template>
  <input
    v-bind:ref="'input'"
    v-model="value"
    class="ag-input-field-input ag-text-field-input"
    v-on:keypress="onKeyPress($event)" />
</template>
<script>
  export default {
    data() {
      return { value: '' };
    },
    created() {
      this.setInitialState(this.params);
    },
    async mounted() {
      await this.$nextTick();
      if (this.$refs.input) {
        this.$refs.input.focus();
      }
    },
    methods: {
      setInitialState(params) {
        this.value = params.value ? params.value : '';
      },
      getValue() {
        return this.value;
      },
      onKeyPress(event) {
        const selection = window.getSelection()
          ? window.getSelection().toString()
          : '';
        const maxLength = this.params && this.params.maxLength;

        if (maxLength && this.value.length + 1 - selection.length > maxLength) {
          event.preventDefault();
        }
      }
    }
  };
</script>
<style lang="scss" scoped></style>
