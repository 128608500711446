<template>
  <img
    src="https://cdn.jinius.fr/logos/jinius.svg"
    class="inline-flex p-1"
    v-bind:class="size"
    alt="Jinius" />
</template>

<script>
  export default {
    props: {
      size: {
        type: [String],
        default: 'w-16'
      }
    }
  };
</script>
