<template>
  <div
    class="refonte-filters-tabs"
    v-bind:class="props.class">
    <div class="filters-tabs-list">
      <slot></slot>
    </div>
    <slot name="rightContent"></slot>
  </div>
</template>

<script setup lang="ts">
  const props = withDefaults(
    defineProps<{
      class?: string;
    }>(),
    {
      class: ''
    }
  );
</script>

<style lang="scss" scoped>
  .refonte-filters-tabs {
    @apply flex flex-wrap items-center justify-end self-stretch;
    gap: 4px;
    border-radius: 8px;

    .filters-tabs-list {
      @apply flex flex-grow flex-wrap items-center self-stretch;
      gap: 4px;
      padding: 2px;
    }
  }
</style>
