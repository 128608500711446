import { API } from '@/http-common.js';
import {
    Indicateurs,
    IndicateursResponse
} from '@/types/gestion/accueil-types';
import { Filters } from '@/types/gestion/annuaire-filters-types';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { LocationQuery } from 'vue-router';

const noFilterState: Filters = {
    clientIds: []
};

const filterInitialState: Filters = {
    clientIds: []
};

export const ACCUEIL_FILTERS_CONFIGURATION = {
    clientIds: {
        name: 'clientIds',
        isArray: true
    }
};

export const useAccueilFilterStore = defineStore('accueil-filter', () => {
    const filters = ref<Filters>(filterInitialState);

    const setFilter = (name: string, value: any) => {
        filters.value = { ...filters.value, [name]: value };
    };
    const resetFilters = () => {
        filters.value = noFilterState;
    };

    const parsePayload = (payload: LocationQuery): Filters => {
        const newPayload = { ...payload };

        Object.keys(newPayload).forEach((key) => {
            const filter = ACCUEIL_FILTERS_CONFIGURATION[key] as any;

            if (filter && filter.isArray) {
                newPayload[key] = newPayload[key].split(',');
            }
        });

        return newPayload;
    };

    const initFiltersFromQuery = (payload: LocationQuery) => {
        const parsedPayload = parsePayload(payload) as Filters;
        if (payload && Object.keys(payload).length > 0) {
            filters.value = {
                ...noFilterState,
                ...parsedPayload
            };
        } else {
            filters.value = {
                ...filterInitialState,
                ...parsedPayload
            };
        }
    };

    return {
        resetFilters,
        setFilter,
        filters,
        initFiltersFromQuery
    };
});

export const useAccueilStore = defineStore('accueil', () => {
    const indicateurs = ref<Indicateurs>({
        nombreInscriptionsEnAttente: 0,
        nombreInscriptionsConventionsASigner: 0,
        nombreInscriptionsPifsASigner: 0,
        nombreContratsASigner: 0,
        nombreInscriptionsTestsPositionnementNonRealise: 0,
        nombreFacturesARegler: 0
    });

    const fetchIndicateurs = async (query: Filters) => {
        const response = await API.get(`client/v2/indicateurs`, {
            params: query
        });
        if (response?.data) {
            indicateurs.value = mapIndicateur(response.data);
        }
    };

    const mapIndicateur = (i: IndicateursResponse): Indicateurs => {
        return {
            ...i
        };
    };

    return {
        indicateurs,
        fetchIndicateurs
    };
});
