<template>
  <div class="absolute inset-0 flex items-center justify-center">
    <spinner
      sizes="w-20 h-20"
      thickness="border-8" />
  </div>
</template>

<script>
  import spinner from '@/components/utils/spinner.vue';
  import { createNamespacedHelpers } from 'vuex';
  const { mapState: mapStateAuth } = createNamespacedHelpers('auth');

  export default {
    components: { spinner },
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.triggerRedirect();
      });
    },
    computed: {
      ...mapStateAuth({
        redirect_route_path: (state) => state.redirect_route_path
      })
    },
    methods: {
      triggerRedirect() {
        this.$router.push(this.redirect_route_path || '/');
      }
    }
  };
</script>

<style lang="scss" scoped></style>
