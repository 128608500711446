<template>
  <div class="flex h-full w-full flex-wrap items-center justify-center px-4">
    <spinner
      v-if="loading"
      sizes="w-20 h-20"
      thickness="border-8" />

    <div
      class="flex h-full w-full flex-col"
      v-if="!loading">
      <template
        v-if="preferencesForm.organismes && preferencesForm.organismes.length">
        <div class="my-6 flex flex-wrap">
          <div class="w-full sm:w-1/2">
            <h1
              class="m-0 flex h-full items-center text-2xl font-bold uppercase text-accent-500 lg:text-5xl">
              Préférences de contact
            </h1>
          </div>
          <div class="my-4 w-full sm:my-0 sm:w-1/2">
            <save-form-button
              class="!justify-end"
              v-bind:submit="submit"
              check_consent_text="Vous acceptez que les informations recueillies soient traitées dans le but de gérer vos demandes et commandes et de constituer un fichier clientèle." />
          </div>
        </div>

        <div>
          <template
            v-for="section in map_preferences_sections"
            v-bind:key="section.name">
            <section class="section__primary">
              <h1
                v-if="section.label"
                class="section__title">
                <font-awesome-icon
                  v-if="section.icon"
                  v-bind:icon="section.icon"
                  v-bind:flip="section.flip ? section.flip : null"
                  class="rounded-full bg-jinius-green p-2 text-white"
                  size="sm" />
                <span class="ml-2.5">{{ section.label }}</span>
              </h1>
              <div class="section__body">
                <div class="mb-4 flex w-full">
                  <radio-button
                    v-bind:id="`${section.name}-negative`"
                    v-bind:label="`Je ne souhaite pas recevoir d'informations par ${section.word}`"
                    v-bind:checked="
                      preferencesForm[section.name].value === false
                    "
                    v-bind:name="section.name"
                    v-bind:value="false"
                    v-on:change="
                      (value) =>
                        setFalseData(section.name, value, section.valueKey)
                    " />
                </div>
                <div class="mb-4 flex w-full flex-wrap">
                  <radio-button
                    v-bind:id="`${section.name}-positive`"
                    v-bind:label="`J'accepte de recevoir des informations par ${section.word} de la part ${preferencesForm[section.name].value ? 'des organismes de formation suivants :' : 'd\'organismes de formation'}`"
                    v-bind:checked="preferencesForm[section.name].value"
                    v-bind:name="section.name"
                    v-bind:value="true"
                    v-on:change="
                      (value) =>
                        setTrueData(section.name, value, section.valueKey)
                    " />

                  <template
                    v-if="preferencesForm[section.name].value"
                    v-for="(organisme, i) in preferencesForm.organismes"
                    v-bind:key="organisme.id">
                    <div
                      class="my-3 flex w-full flex-wrap items-center space-x-4"
                      v-bind:class="{ 'mt-5': i === 0 }">
                      <div
                        class="mb-4 flex w-full flex-shrink items-center lg:mb-0 lg:w-40">
                        <span
                          v-tooltip.left="organisme.perimetreNom"
                          class="cursor-pointer truncate rounded-sm bg-jinius-blue px-2 py-1 text-xs font-bold leading-none text-white"
                          >{{ organisme.perimetreNom }}</span
                        >
                      </div>

                      <div class="flex flex-grow items-center">
                        <yes-no-radio-buttons
                          v-bind:input_name="`${organisme.id}-${section.name}`"
                          class="radio__buttons"
                          v-bind:input_value="organisme[section.valueKey]"
                          v-bind:update_value="
                            (value) =>
                              setPreferencesOrganismes(
                                organisme.id,
                                section.valueKey,
                                value
                              )
                          " />
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </section>
          </template>
        </div>
      </template>
      <template v-else>
        <div class="flex w-full justify-center pt-5 text-lg font-bold">
          Vous n'êtes rattaché(e) à aucun périmètre. Merci de contacter votre
          gestionnaire de formation.
        </div>
      </template>
    </div>
  </div>
</template>
<script>
  import { map_preferences_sections } from '@/assets/data/data_maps.js';
  import RadioButton from '@/components/utils/radio-button';
  import saveFormButton from '@/components/utils/save-form-button.vue';
  import Spinner from '@/components/utils/spinner.vue';
  import YesNoRadioButtons from '@/components/utils/yes-no-radio-buttons';
  import { ACTION_TYPES } from '@/store/constants/ActionTypes';
  import { createNamespacedHelpers } from 'vuex';
  const { mapState: mapStateAccount, mapActions: mapActionsAccount } =
    createNamespacedHelpers('account');
  const { mapState: mapStateObserver } = createNamespacedHelpers('observer');

  export default {
    components: {
      Spinner,
      saveFormButton,
      RadioButton,
      YesNoRadioButtons
    },
    data: () => ({
      loading: false,
      map_preferences_sections
    }),
    computed: {
      ...mapStateAccount(['preferencesForm']),
      ...mapStateObserver(['scrolled_body'])
    },
    created() {
      this.fetchPreferences();
    },
    methods: {
      ...mapActionsAccount([
        ACTION_TYPES.SET_PREFERENCES_FORM_ORGANISMES,
        ACTION_TYPES.GET_PREFERENCES,
        ACTION_TYPES.SEND_PREFERENCES,
        ACTION_TYPES.SET_PREFERENCES_FORM_SECTIONS_VALUE,
        ACTION_TYPES.SET_PREFERENCES_FORM_ALL_PREFERENCES
      ]),
      setPreferencesOrganismes(id, valueKey, value) {
        this[ACTION_TYPES.SET_PREFERENCES_FORM_ORGANISMES]({
          id,
          valueKey,
          value
        });
      },
      async fetchPreferences() {
        this.loading = true;
        await this[ACTION_TYPES.GET_PREFERENCES]();
        this.loading = false;
      },
      async setAllPreferences(key, value) {
        this[ACTION_TYPES.SET_PREFERENCES_FORM_ALL_PREFERENCES]({
          key,
          value
        });
      },
      setData(key, value) {
        this[ACTION_TYPES.SET_PREFERENCES_FORM_SECTIONS_VALUE]({
          key,
          value
        });
      },
      async setTrueData(key, value, valueKey) {
        this.setData(key, value);
        if (value) {
          await this.setAllPreferences(valueKey, null);
        }
      },
      async setFalseData(key, value, valueKey) {
        this.setData(key, value);
        await this.setAllPreferences(valueKey, false);
      },
      async submit() {
        this.loading = true;
        await this[ACTION_TYPES.SEND_PREFERENCES](this.preferencesForm);
        await this[ACTION_TYPES.GET_PREFERENCES]();
        this.loading = false;
      }
    }
  };
</script>
<style lang="scss" scoped>
  h1.section__title {
    @apply ml-8 mt-6 flex w-full items-center text-center text-2xl font-bold leading-none text-core-900;
  }
</style>
