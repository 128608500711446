<template>
  <div class="flex h-full w-full items-center justify-center">
    <form
      v-if="!loading && cvForm.retrieved"
      ref="cvForm"
      class="h-full">
      <div class="mb-12 flex w-full flex-wrap px-4 lg:flex-nowrap lg:px-0">
        <cv-header
          v-bind:cv_form="cvForm"
          v-bind:set_attribute="setAttribute"
          v-bind:account_civilite="accountForm.civilite"
          v-bind:account_societe="accountForm.societeLabel"
          class="mb-8 lg:mb-0" />

        <div
          class="flex w-full flex-col space-y-2 lg:w-1/2 lg:justify-between lg:space-y-0">
          <save-form-button
            class="mt-4"
            v-bind:v$="v$"
            v-bind:submit="submit"
            check_consent_text="J'atteste sur l'honneur l'exactitude des informations renseignées"
            check_submit_text="Soumettre votre demande" />

          <cv-consent
            v-if="cv_consent.length > 0"
            v-bind:list_consent="cv_consent"
            class="flex h-full justify-end" />

          <div
            v-if="cvForm.modifiedBy || cvForm.modifiedDate"
            class="flex w-full text-xs italic text-core-500 lg:justify-end">
            Dernière modification
            <span v-if="cvForm.modifiedDate">
              &nbsp;le&nbsp;{{ cvForm.modifiedDate }}
            </span>
          </div>
        </div>
      </div>

      <certifications
        v-bind:v$="v$"
        v-bind:cv_form="cvForm"
        v-bind:set_attribute="setAttribute" />

      <formation-initiale
        v-bind:cv_form="cvForm"
        v-bind:set_attribute="setAttribute" />

      <parcours-professionnel
        v-bind:cv_form="cvForm"
        v-bind:set_attribute="setAttribute" />

      <animation-et-enseignement
        v-bind:cv_form="cvForm"
        v-bind:set_attribute="setAttribute" />

      <informations-complementaires
        v-bind:cv_form="cvForm"
        v-bind:set_attribute="setAttribute" />
    </form>
    <spinner
      v-if="loading"
      sizes="w-20 h-20"
      thickness="border-8" />

    <div v-if="!loading && !cvForm.retrieved">CV non disponible</div>
  </div>
</template>

<script>
  import saveFormButton from '@/components/utils/save-form-button.vue';
  import spinner from '@/components/utils/spinner.vue';
  import { ACTION_TYPES } from '@/store/constants/ActionTypes';
  import animationEtEnseignement from '@/views/_espace/_formateur/_cv/animation-et-enseignement';
  import certifications from '@/views/_espace/_formateur/_cv/certifications';
  import cvConsent from '@/views/_espace/_formateur/_cv/cv-consent';
  import cvHeader from '@/views/_espace/_formateur/_cv/cv-header';
  import formationInitiale from '@/views/_espace/_formateur/_cv/formation-initiale';
  import informationsComplementaires from '@/views/_espace/_formateur/_cv/informations-complementaires';
  import parcoursProfessionnel from '@/views/_espace/_formateur/_cv/parcours-professionnel';
  import { useVuelidate } from '@vuelidate/core';
  import { requiredIf } from '@vuelidate/validators';
  import { createNamespacedHelpers } from 'vuex';

  const { mapState: mapStateFormateur, mapActions: mapActionsFormateur } =
    createNamespacedHelpers('formateur');

  const { mapState: mapStateAccount, mapActions: mapActionsAccount } =
    createNamespacedHelpers('account');

  const { mapState: mapStateObserver, mapMutations: mapMutationsObserver } =
    createNamespacedHelpers('observer');

  export default {
    components: {
      formationInitiale,
      spinner,
      saveFormButton,
      cvHeader,
      cvConsent,
      animationEtEnseignement,
      certifications,
      parcoursProfessionnel,
      informationsComplementaires
    },
    setup() {
      return { v$: useVuelidate() };
    },
    data() {
      return {
        loading: false,
        consent: false
      };
    },
    computed: {
      ...mapStateObserver(['scrolled_body']),
      ...mapStateFormateur(['cvForm', 'cv_consent']),
      ...mapStateAccount(['accountForm', 'societes']),
      retrieved() {
        return this.loading;
      }
    },
    beforeUnmount() {
      this.setScroll(false);
    },
    validations() {
      return {
        cvForm: {
          numeroDeclaration: {
            required: requiredIf(function () {
              return this.cvForm.estDeclare;
            })
          }
        }
      };
    },
    async created() {
      await this.fetchCV();
    },
    methods: {
      ...mapMutationsObserver({ setScroll: 'setScroll' }),
      ...mapActionsFormateur([
        ACTION_TYPES.GET_CV,
        ACTION_TYPES.GET_CV_CONSETEMENT,
        ACTION_TYPES.SEND_CV,
        ACTION_TYPES.GET_THEMES,
        ACTION_TYPES.SET_FORM_ATTRIBUTE
      ]),
      ...mapActionsAccount([ACTION_TYPES.GET_CONTACT]),
      async fetchCV() {
        this.loading = true;
        await this[ACTION_TYPES.GET_THEMES]();

        await this[ACTION_TYPES.GET_CV]();
        await this[ACTION_TYPES.GET_CV_CONSETEMENT]();

        await this[ACTION_TYPES.GET_CONTACT]();
        this.loading = false;
      },
      setAttribute(value, name) {
        this[ACTION_TYPES.SET_FORM_ATTRIBUTE]({
          name,
          value
        });
      },
      async submit() {
        this.loading = true;
        await this[ACTION_TYPES.SEND_CV](this.cvForm);
        await this[ACTION_TYPES.GET_THEMES]();
        await this[ACTION_TYPES.GET_CV]();
        await this[ACTION_TYPES.GET_CV_CONSETEMENT]();
        await this[ACTION_TYPES.GET_CONTACT]();
        this.loading = false;
      }
    }
  };
</script>
<style lang="scss" scoped></style>
