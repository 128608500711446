<template>
  <div
    v-if="params.value || params.value === 0"
    v-on:click="clickable ? params.onclick($event, params) : null"
    class="default-renderer"
    v-bind:class="[
      { 'justify-center': centered },
      { 'justify-end font-bold': amount },
      { 'cursor-pointer text-jinius-dark-green hover:underline': clickable }
    ]">
    <!-- <pre>{{status_class}}</pre> -->
    <span
      class="default-renderer__text"
      v-bind:class="[
        {
          'w-40 max-w-full rounded-sm px-1.5 py-0.5 text-center text-xs font-bold text-white shadow-inner':
            hasStatusClass
        },
        `${hasStatusClass}`,
        params.className
      ]"
      v-tooltip="{
        content: params.displayValue
          ? params.value[params.displayValue]
          : (params.value || params.value === 0) && `${params.value}`,
        placement: 'right',
        offset: 10,
        delay: { show: 100, hide: 0 }
      }">
      <font-awesome-icon
        v-if="displayParamsValue(params.value) === 'display_icon'"
        v-bind:icon="params.value === 'Oui' ? 'circle-check' : 'circle-xmark'"
        v-bind:class="
          params.value === 'Oui' ? 'text-jinius-green' : 'text-jinius-red'
        " />
      <span v-else>{{ displayParamsValue(params.value) }}</span>
    </span>
  </div>
  <div
    v-else-if="editable"
    class="default-renderer default-renderer--add-icon"
    v-bind:class="[{ 'justify-center': centered }]">
    <font-awesome-icon icon="plus-circle" />
  </div>
  <div
    v-else-if="dates_field"
    class="default-renderer default-renderer--empty-dates"
    v-bind:class="[{ 'justify-center': centered }]">
    {{ params.typeId === 2 ? 'N/A' : 'Date(s) indisponible(s)' }}
  </div>
  <div
    v-else-if="location"
    class="default-renderer default-renderer--empty-dates"
    v-bind:class="[{ 'justify-center': centered }]"></div>
  <div
    v-else-if="select"
    class="default-renderer"
    v-bind:class="[{ 'justify-center': centered }]">
    {{ selectPlaceholder }}
  </div>
  <div
    v-else
    class="default-renderer default-renderer--empty-dates"
    v-tooltip="{
      content: params.defaultNATooltip,
      placement: 'right',
      distance: 10
    }"
    v-bind:class="[{ 'justify-center': centered }]">
    {{ params.defaultNAValue ? params.defaultNAValue : 'N/A' }}
  </div>
</template>

<script>
  import number_utils from '@/mixin/number_utils';

  export default {
    mixins: [number_utils],
    props: {
      params: { type: Object },
      centered: { type: Boolean },
      amount: { type: Boolean },
      clickable: { type: Boolean },
      editable: { type: Boolean },
      dates_field: { type: Boolean },
      location: { type: Boolean },
      colored: { type: Boolean },
      status_class: { type: Array },
      display_status_label: { type: Boolean },
      select: { type: Boolean }
    },
    computed: {
      hasStatusClass() {
        if (this.status_class) {
          const classes = this.status_class.join(' ');

          return classes;
        } else {
          return '';
        }
      },
      selectPlaceholder() {
        return this.params.defautlSelectValue
          ? this.params.defautlSelectValue
          : 'Aucun';
      }
    },
    methods: {
      displayParamsValue(value) {
        let formated_value = value;

        if (this.amount) {
          formated_value = this.formatCurrency(value);
        } else if (['Oui', 'Non'].includes(value)) {
          formated_value = 'display_icon';
        }

        return formated_value;
      }
    }
  };
</script>
<style lang="scss" scoped>
  .default-renderer {
    @apply flex h-full w-full items-center px-2 leading-none;

    .default-renderer__text {
      @apply truncate leading-normal;
    }

    &.default-renderer--add-icon {
      @apply cursor-pointer justify-center text-core-400 duration-200 hover:bg-accent-100 hover:text-core-900;
    }

    &.default-renderer--empty-dates {
      @apply italic text-core-500;
    }
  }
</style>
