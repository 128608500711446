<template>
  <div class="flex h-full w-full flex-wrap">
    <div class="mb-10 w-full">
      <div
        class="mb-5 mt-6 flex w-full flex-wrap justify-between space-y-2 sm:flex-nowrap sm:space-x-4 sm:space-y-0">
        <radio-button
          id="radio-entreprise"
          label="Une entreprise"
          show_as_required
          v-bind:checked="clientType === 'siret'"
          name="client"
          value="siret"
          v-on:change="updateValue"
          class="radio-pills"
          v-bind:class="{ 'radio-pills--active': clientType === 'siret' }" />
        <radio-button
          id="radio-association"
          label="Une association"
          v-bind:checked="clientType === 'rna'"
          name="client"
          value="rna"
          v-on:change="updateValue"
          class="radio-pills"
          v-bind:class="{ 'radio-pills--active': clientType === 'rna' }" />
        <radio-button
          v-if="!hide_particulier"
          id="radio-particulier"
          label="Un particulier"
          v-bind:checked="clientType === 'particulier'"
          name="client"
          value="particulier"
          v-on:change="updateValue"
          class="radio-pills"
          v-bind:class="{
            'radio-pills--active': clientType === 'particulier'
          }" />
      </div>
      <message-box
        v-bind:source="`Professions libérales ou Entreprises individuelles, sélectionnez <span class='bg-core-100 shadow-sm mx-1 sm:py-1 px-2.5 not-italic text-jinius-green font-bold whitespace-nowrap'>Une entreprise</span> ci-dessus.`"
        font_style="text-xs sm:text-base italic text-accent-500"
        border_color="border-jinius-green" />
    </div>

    <transition name="slide-up">
      <div
        v-if="['siret', 'rna'].includes(clientType)"
        class="mb-12 flex w-full space-x-4">
        <div
          class="flex w-3/4"
          v-bind:ref="clientType">
          <input-box
            class_name="no-box"
            name="search"
            label="Recherche"
            v-bind:placeholder="`Recherchez par nom, adresse${clientType === 'siret' ? ', SIRET' : ''}`"
            v-bind:value="clientForm.search"
            v-bind:on_keydown_enter="openSearchModal"
            v-on:input="setClientForm($event, 'search')" />
        </div>
        <div class="flex h-full w-1/4">
          <button
            class="flex h-10 w-full items-center justify-center self-end rounded-sm bg-jinius-dark-green px-4 text-white shadow transition duration-150 hover:bg-jinius-green disabled:cursor-not-allowed disabled:bg-core-400 disabled:opacity-50"
            v-on:click="openSearchModal"
            type="button"
            v-bind:disabled="!clientForm.search.length">
            <font-awesome-icon icon="search" />
            <span class="mx-2 hidden sm:inline">Rechercher</span>
          </button>
        </div>
      </div>
    </transition>

    <search-modal
      v-bind:modal_is_search="showSearchModal"
      v-bind:onclose="closeSearchModal" />
  </div>
</template>
<script>
  import InputBox from '@/components/utils/input-box';
  import MessageBox from '@/components/utils/message-box';
  import RadioButton from '@/components/utils/radio-button';
  import api_insee_helpers from '@/mixin/api_insee_helpers';
  import { ACTION_TYPES } from '@/store/constants/ActionTypes';
  import SearchModal from '@/views/_espace/_account/_informations/_modal/search-modal';
  import { createNamespacedHelpers } from 'vuex';
  const { mapState: mapStateClient, mapActions: mapActionsClient } =
    createNamespacedHelpers('client');
  const { mapState: mapStateObserver, mapActions: mapActionsObserver } =
    createNamespacedHelpers('observer');

  export default {
    components: {
      SearchModal,
      RadioButton,
      InputBox,
      MessageBox
    },
    mixins: [api_insee_helpers],
    props: { hide_particulier: Boolean },
    data: () => ({ value: '' }),
    computed: {
      ...mapStateClient(['clientForm', 'clientType']),
      ...mapStateObserver(['showSearchModal'])
    },
    created() {
      if (this.clientType === 'particulier') {
        this.fetchParticulier();
      }
    },
    methods: {
      ...mapActionsObserver([ACTION_TYPES.SET_MODAL]),
      ...mapActionsClient([
        ACTION_TYPES.TEST_CLIENT,
        ACTION_TYPES.SET_CLIENT_FORM_ATTRIBUTE,
        ACTION_TYPES.SET_CLIENT_TYPE,
        ACTION_TYPES.FORCE_ENABLE_CLIENT_FORM,
        ACTION_TYPES.SET_CLIENT_FORM
      ]),
      setClientForm(value, name) {
        this[ACTION_TYPES.SET_CLIENT_FORM_ATTRIBUTE]({
          name,
          value
        });
      },
      async updateValue(value) {
        this[ACTION_TYPES.SET_CLIENT_TYPE](value);
        this.value = '';
        if (value === 'particulier') {
          await this.fetchParticulier();
        }
      },
      async fetchParticulier() {
        const response = await this[ACTION_TYPES.TEST_CLIENT]({
          clientType: this.clientType
        });

        if (response && response.data) {
          this[ACTION_TYPES.SET_CLIENT_FORM](response.data);
        }
      },
      openSearchModal() {
        this[ACTION_TYPES.SET_MODAL]({
          name: 'showSearchModal',
          value: true
        });
        this[ACTION_TYPES.FORCE_ENABLE_CLIENT_FORM](false);
      },
      closeSearchModal() {
        this[ACTION_TYPES.SET_MODAL]({
          name: 'showSearchModal',
          value: false
        });
      }
    }
  };
</script>
<style lang="scss" scoped>
  :deep(.card__separator) {
    @apply my-8 flex w-full text-core-900 opacity-50;
  }

  .radio-pills {
    margin: 0;
    @apply w-full rounded bg-core-300 shadow-sm transition;
    :deep(label) {
      @apply w-full px-6 py-3;
    }

    &.radio-pills--active {
      @apply bg-jinius-green text-white shadow-md;
      :deep(input[type='radio'] + label span:hover),
      :deep(input[type='radio'] + label:hover span) {
        @apply text-white;
      }
    }
    :deep(label) {
      @apply justify-center;
      .radio-check {
        @apply bg-white;
      }
    }
  }
</style>
