<template>
  <modal-box
    v-if="show_modal"
    v-bind:footer="false"
    overflow_body
    fill_height
    v-on:close="onclose">
    <template v-slot:modal-header>
      <h1 class="text-2xl font-bold leading-none text-core-900 lg:text-3xl">
        Consolidation des appréciations des participants pour la
        {{ campagne.campagne }}
      </h1>
    </template>

    <template v-slot:modal-body>
      <div
        v-if="loading"
        class="h-full w-full">
        <spinner
          sizes="w-20 h-20"
          thickness="border-8" />
      </div>

      <div
        v-else
        class="flex w-full flex-wrap space-x-4 px-8 py-4 md:space-x-0 md:space-y-4">
        <div class="flex w-full flex-wrap">
          <stat-card
            size="w-1/3"
            label="Nombre de sessions animées"
            v-bind:value="detail.nbSessions" />
          <stat-card
            size="w-1/3"
            label="Nombre de participants présents"
            v-bind:value="detail.nbParticipantsPresents" />
          <stat-card
            size="w-1/3"
            label="Nombre d'appréciations fournies par les participants"
            v-bind:value="detail.nbAppreciations" />
        </div>
        <template v-if="detail && detail.reponses && detail.reponses.length">
          <div class="flex w-full flex-wrap">
            <ag-grid-wrapper
              v-bind:no_filters="true"
              v-bind:column_defs="[
                {
                  headerName: 'Note globale (%)',
                  field: 'noteGlobale',
                  cellRenderer: 'textCenterCellRenderer'
                },
                {
                  headerName: 'Maîtrise du sujet (% TB)',
                  field: 'maitriseSujet',
                  cellRenderer: 'textCenterCellRenderer'
                },
                ...(detail.technique !== undefined
                  ? [
                      {
                        headerName: 'Technique (% TB)',
                        field: 'technique',
                        cellRenderer: 'textCenterCellRenderer'
                      }
                    ]
                  : []),
                ...(detail.explication !== undefined
                  ? [
                      {
                        headerName: 'Explication (% TP)',
                        field: 'explication',
                        cellRenderer: 'textCenterCellRenderer'
                      }
                    ]
                  : []),
                ...(detail.reponsesQuestions !== undefined
                  ? [
                      {
                        headerName: 'Réponses aux questions (% TP)',
                        field: 'reponsesQuestions',
                        cellRenderer: 'textCenterCellRenderer'
                      }
                    ]
                  : []),
                ...(detail.explicationReponsesQuestions !== undefined
                  ? [
                      {
                        headerName: 'Explication et réponses (% TP)',
                        field: 'explicationReponsesQuestions',
                        cellRenderer: 'textCenterCellRenderer'
                      }
                    ]
                  : []),
                {
                  headerName: 'Apport expérience (% TE)',
                  field: 'apportExperience',
                  cellRenderer: 'textCenterCellRenderer'
                },
                {
                  headerName: 'Respect du Prog. (% Oui)',
                  field: 'respectProg',
                  cellRenderer: 'textCenterCellRenderer'
                }
              ]"
              v-bind:initial_data="[{ ...detail }]" />
            <div
              class="mt-1 flex w-full justify-end text-sm italic text-core-500">
              TB = Très bien, TP = Très pertinentes, TE = Très enrichissant
            </div>
          </div>
          <h1
            class="flex w-full text-3xl font-bold leading-none text-jinius-green">
            Graphiques des appréciations des participants
          </h1>
          <div class="flex w-full">
            <surveyjs-analytics-wrapper
              v-if="detail && detail.surveyText"
              v-bind:json="detail.surveyText"
              v-bind:data="detail.reponses"
              ref="surveyjs-analytics-wrapper" />
          </div>
        </template></div
    ></template>
  </modal-box>
</template>
<script>
  import agGridWrapper from '@/components/utils/ag-grid/ag-grid-wrapper';
  import modalBox from '@/components/utils/modal-box.vue';
  import spinner from '@/components/utils/spinner';
  import statCard from '@/components/utils/stat-card.vue';
  import SurveyjsAnalyticsWrapper from '@/components/utils/surveyjs-analytics-wrapper';
  import { API } from '@/http-common';
  export default {
    components: {
      SurveyjsAnalyticsWrapper,
      modalBox,
      statCard,
      agGridWrapper,
      spinner
    },
    props: {
      show_modal: Boolean,
      campagne: Object,
      onclose: Function
    },
    data: () => ({
      loading: false,
      detail: null
    }),
    async created() {
      await this.fetchCampagne();
    },
    methods: {
      async fetchCampagne() {
        this.loading = true;
        if (this.campagne) {
          const response = await API.get(
            `formateur/satisfaction/campagne/${this.campagne.id}`,
            this.$header_skip_redirection
          );

          this.detail = response.data;
        }

        this.loading = false;
      },
      close() {
        this.detail = null;
        this.onclose();
      }
    }
  };
</script>
<style lang="scss" scoped></style>
