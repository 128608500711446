<template>
  <div
    class="refonte-table-item"
    v-bind:class="`table-item-${props.size} ${props.on_click ? 'table-item-clickable' : ''} ${props.pending ? 'table-item-pending' : ''} ${props.class}`"
    v-bind:style="props.width ? `flex: 1 0 ${props.width}px` : ''"
    v-on:click="onItemClick"
    v-bind:title="
      props.tooltip ? props.tooltip : props.on_click ? ' ' : undefined
    ">
    <div
      v-if="!props.no_label"
      class="table-item-label">
      <refonte-pastille
        v-if="props.status"
        v-bind:status="props.status" />
      <div
        v-if="props.label_icon"
        class="table-item-label-icon">
        <component v-bind:is="props.label_icon" />
      </div>
      <div
        v-if="props.label"
        class="table-item-label-text">
        {{ props.label }}
      </div>
      <slot
        v-if="!!slots['itemTags']"
        name="itemTags"></slot>
      <slot
        v-if="!!slots['itemDates']"
        name="itemDates"></slot>
    </div>
    <div class="table-item-content">
      <div
        v-if="props.icon"
        class="table-item-content-icon"
        v-bind:class="props.icon_black ? 'table-item-content-icon-black' : ''">
        <component v-bind:is="props.icon" />
      </div>
      <div class="table-item-content-text">
        <slot name="content"></slot>
      </div>
    </div>
    <div
      v-if="props.sub_text && typeof props.sub_text === 'string'"
      class="table-item-content-sub-text">
      {{ props.sub_text }}
    </div>
    <div
      v-else-if="props.sub_text"
      class="table-item-content-sub-text">
      <component v-bind:is="props.sub_text" />
    </div>
    <div
      v-if="props.sub_link_text"
      class="table-item-content-sub-text"
      v-bind:class="props.sub_link_class">
      <span v-if="props.sub_link_prefix">
        {{ props.sub_link_prefix }}
      </span>
      <router-link
        v-if="props.sub_link_path"
        v-bind:to="{ path: props.sub_link_path }"
        class="!text-refonte-black hover:underline"
        title=" "
        v-on:click="
          (e) => {
            e.stopPropagation();
          }
        ">
        {{ props.sub_link_text }}
      </router-link>
      <span
        v-else
        class="!text-refonte-black">
        {{ props.sub_link_text }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
  import RefontePastille from '@/components/refonte/pastille/refonte-pastille.vue';
  import { useSlots } from 'vue';

  const props = withDefaults(
    defineProps<{
      width?: number;
      size?: 'sm' | 'md';
      pending?: boolean;
      on_click?: Function;
      tooltip?: string;
      no_label?: boolean;
      label?: string;
      label_icon?: Element;
      status?: string;
      icon?: Element;
      icon_black?: boolean;
      sub_text?: string | Element;
      sub_link_text?: string;
      sub_link_class?: string;
      sub_link_prefix?: string;
      sub_link_path?: string;
      class?: string;
    }>(),
    {
      no_label: false,
      icon_black: false,
      size: 'md',
      class: ''
    }
  );

  const slots = useSlots();

  function onItemClick(e: Event) {
    if (props.on_click) {
      props.on_click();
      e.stopPropagation();
    }
  }
</script>

<style lang="scss" scoped>
  .refonte-table-item {
    @apply inline-flex flex-col items-start;
    gap: 2px;
    border-radius: 4px;
    max-width: 100%;

    &.table-item-sm {
      padding: 7px 4px;
    }
    &.table-item-md {
      padding: 7px 8px;
    }

    .table-item-label {
      @apply flex flex-wrap items-center justify-start self-stretch overflow-hidden;
      gap: 4px;

      .table-item-label-icon {
        @apply flex shrink-0 items-center justify-center text-refonte_blue-300;
        width: 20px;
        height: 20px;

        :deep(svg) {
          @apply text-refonte_blue-200;
          width: 20px;
          height: 20px;
        }
      }
      .table-item-label-text {
        @apply flex items-center uppercase text-refonte_blue-300;
        padding: 3px 0;
        gap: 2px;
        font-size: 11px;
        font-weight: 400;
        line-height: 14px;
      }
    }
    .table-item-content {
      @apply flex items-center;
      min-height: 20px;
      gap: 2px;

      .table-item-content-icon {
        @apply flex shrink-0 items-center justify-center;
        width: 20px;
        height: 20px;

        :deep(svg) {
          @apply text-refonte_blue-200;
          width: 20px;
          height: 20px;
        }

        &.table-item-content-icon-black {
          :deep(svg) {
            @apply text-refonte-black;
          }
        }
      }
      .table-item-content-text {
        @apply flex-grow text-refonte-black;
        font-size: 14px;
        font-weight: 400;
        line-height: 125%;
      }
    }
    .table-item-content-sub-text {
      @apply text-refonte-black-50;
      font-size: 12px;
      font-weight: 400;
      line-height: 120%;
    }

    &.table-item-clickable {
      @apply cursor-pointer bg-refonte-pale-violet-2;

      &:hover {
        @apply bg-refonte-pale-violet-3;

        .table-item-label {
          @apply text-refonte_blue-600;
        }
      }
    }
    &.table-item-pending {
      .table-item-content {
        .table-item-content-icon {
          &.table-item-content-icon-black {
            :deep(svg) {
              @apply text-refonte_blue-200;
            }
          }
        }
        .table-item-content-text {
          @apply text-refonte_blue-300;
        }
      }
      .table-item-content-sub-text {
        @apply text-refonte_blue-300;
      }
      &.table-item-clickable {
        @apply cursor-default bg-transparent;

        &:hover {
          @apply bg-transparent;

          .table-item-label {
            @apply text-refonte_blue-300;
          }
        }
      }
    }
    &.table-item-with-content-word-break {
      .table-item-content {
        .table-item-content-text {
          word-break: break-all;
        }
      }
    }
  }
</style>
