<template>
  <modal-box
    v-if="modal_show"
    v-bind:width_class="'w-full max-w-screen-lg'"
    v-on:close="onclose"
    v-bind:footer="false">
    <template v-slot:modal-header>
      <h1 class="text-xl font-bold text-core-900">Information</h1>
    </template>

    <template v-slot:modal-body>
      <div class="py-4 text-lg">
        <div v-if="!elearning">
          Veuillez saisir le questionnaire de satisfaction pour accéder aux
          supports :
        </div>
        <div v-else>
          Veuillez finir votre module e-learning et saisir le questionnaire de
          satisfaction pour accéder aux supports :
        </div>
        <ul v-if="supports && supports.length">
          <template
            v-for="(support, index) in supports"
            v-bind:key="`support-${index}`">
            <li>{{ support.titre }}</li>
          </template>
        </ul>
      </div>
    </template>
  </modal-box>
</template>
<script>
  import modalBox from '@/components/utils/modal-box.vue';
  export default {
    components: { modalBox },
    props: {
      modal_show: Boolean,
      onclose: Function,
      supports: Array,
      elearning: Boolean
    },
    data: () => ({}),
    methods: {
      close() {
        this.onclose();
      }
    }
  };
</script>
<style lang="scss" scoped></style>
