<template>
  <div
    v-if="params.value"
    class="type__label"
    v-bind:class="type_bg_color(params.value.id)">
    <span>{{ params.value.label }}</span>
  </div>
</template>

<script>
  import { map_type_colors } from '@/assets/data/data_maps.js';

  export default {
    data() {
      return {};
    },
    methods: {
      type_bg_color(id) {
        return `bg-${map_type_colors[id]}`;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .type__label {
    @apply mx-3 flex w-full items-center justify-center rounded-full px-3 py-0.5 text-center text-xs font-bold lowercase text-core-100 shadow first-letter:capitalize;
  }
</style>
