export default {
    methods: {
        formatMinutesToHours(totalMinutes) {
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;

            return `${hours}h${minutes < 10 ? '0' : ''}${minutes}`;
        },
        formatNumberToHours(numberHours) {
            const hours = Math.trunc(numberHours);
            const minutes = 60 * Number(numberHours - hours);

            return `${hours}h${minutes < 10 ? '0' : ''}${minutes}`;
        }
    }
};
