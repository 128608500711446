<template>
  <div class="programme-list">
    <template
      v-for="(dates, i) in programme"
      v-bind:key="i">
      <div class="dates__container">
        <div
          class="mb-4 flex flex-wrap-reverse items-center justify-between sm:flex-nowrap">
          <div class="dates__title">
            <font-awesome-icon
              v-bind:icon="['far', 'calendar-alt']"
              size="1x" />
            <span class="title__text">{{ dates.date }}</span>
          </div>
          <div
            v-if="
              classe_virtuelle &&
              session_url &&
              session_url.length > 1 &&
              i === 0
            "
            class="my-2">
            <div
              v-on:click="open_connexion_modal"
              class="cursor-pointer rounded bg-orange-500 px-4 py-2 text-center font-bold uppercase leading-none text-white shadow-sm hover:bg-orange-600">
              <font-awesome-icon icon="external-link-alt" />
              <span class="ml-2 align-middle">Informations de connexion</span>
            </div>
          </div>
          <div
            v-if="
              classe_virtuelle &&
              session_url &&
              session_url.length === 1 &&
              i === 0
            ">
            <div
              v-on:click="() => goToSession(session_url[0].url)"
              class="cursor-pointer rounded bg-orange-500 px-4 py-2 text-center font-bold uppercase leading-none text-white shadow-sm hover:bg-orange-600">
              <font-awesome-icon icon="external-link-alt" />
              <span class="ml-2 align-middle">Accéder à la session</span>
            </div>
          </div>
          <div v-if="classe_virtuelle && session_url_webinaire && i === 0">
            <div
              v-on:click="() => goToSession(session_url_webinaire)"
              class="cursor-pointer rounded bg-orange-500 px-4 py-2 text-center font-bold uppercase leading-none text-white shadow-sm hover:bg-orange-600">
              <font-awesome-icon icon="external-link-alt" />
              <span class="ml-2 align-middle">Accéder à la session</span>
            </div>
          </div>
        </div>

        <div class="dates__prestations">
          <template
            v-for="prestation in dates.prestations"
            v-bind:key="prestation.id">
            <template v-if="prestation.type.id && prestation.type.id !== 103">
              <div
                v-if="prestation?.type?.id === 1 || prestation.type.id === 104"
                class="prestation__container">
                <div
                  v-if="prestation.heureDebut || prestation.heureFin"
                  class="prestation__time">
                  <div v-if="prestation.heureDebut">
                    {{ formatMinutesToHours(prestation.heureDebut) }}
                  </div>
                  <div v-if="prestation.heureFin">
                    {{ formatMinutesToHours(prestation.heureFin) }}
                  </div>
                </div>

                <div
                  v-else-if="prestation.type.id === 104"
                  class="prestation__time">
                  <div class="leading-none text-jinius-red">
                    Non<br />dispensé
                  </div>
                </div>

                <div class="prestation__content">
                  <div>
                    <div class="prestation__title">
                      <span
                        v-if="prestation.dureeMinutes"
                        class="font-bold">
                        (&nbsp;{{
                          formatMinutesToHours(prestation.dureeMinutes)
                        }}&nbsp;)
                      </span>
                      <span>{{ prestation.nom || prestation.titre }}</span>
                    </div>
                    <div
                      v-if="
                        is_formateur &&
                        prestation.formateurs &&
                        prestation.formateurs.length
                      "
                      class="mt-2 text-sm leading-none text-accent-500">
                      Formateur{{ prestation.formateurs.length > 1 ? 's' : '' }}
                      :
                      {{ displayFormateurs(prestation.formateurs).join(', ') }}
                    </div>
                  </div>
                </div>

                <div
                  v-if="!classe_virtuelle && prestation.site"
                  class="prestation__address__container">
                  <div class="prestation__address">
                    <div>{{ prestation.site.denomination }}</div>

                    <template
                      v-if="
                        isCoordonatesAvailable(
                          prestation.site.coordinates,
                          prestation.id
                        )
                      ">
                      <div
                        v-on:click="openMapModal(prestation.id)"
                        v-tooltip.top="
                          `Consulter l'emplacement sur une carte dynamique`
                        "
                        class="group inline-flex h-10 w-10 cursor-pointer items-center justify-center text-jinius-blue transition-colors hover:text-jinius-dark-green">
                        <font-awesome-icon
                          class="pb-0.5"
                          icon="map-marked-alt"
                          size="2x" />
                      </div>
                      <details-map-modal
                        v-bind:modal_show="map_modal[`key-${prestation.id}`]"
                        v-bind:coordinates="
                          prestation.site.coordinates.split(',')
                        "
                        v-bind:adresse="concatAdress(prestation.site)"
                        v-on:onclose="closeMapModal(prestation.id)" />
                    </template>
                  </div>
                </div>
              </div>

              <div
                v-else
                class="prestation__container bg-accent-100">
                <div
                  v-if="prestation.heureDebut || prestation.heureFin"
                  class="prestation__time">
                  <div v-if="prestation.heureDebut">
                    {{ formatMinutesToHours(prestation.heureDebut) }}
                  </div>
                  <div v-if="prestation.heureFin">
                    {{ formatMinutesToHours(prestation.heureFin) }}
                  </div>
                </div>
                <div class="prestation__content justify-center">
                  <font-awesome-icon
                    v-if="
                      !(classe_virtuelle && prestation.type.id === 2) &&
                      map_prestations_type_icons[prestation.type.id]
                    "
                    v-bind:icon="map_prestations_type_icons[prestation.type.id]"
                    size="sm" />
                  <span class="mx-2 font-bold">
                    {{
                      classe_virtuelle && prestation.type.id === 2
                        ? 'Temps libre'
                        : map_prestations_type_labels[prestation.type.id]
                    }}
                  </span>
                </div>

                <div
                  v-if="!classe_virtuelle && prestation.site"
                  class="prestation__address__container">
                  <div class="prestation__address">
                    <div>{{ prestation.site.denomination }}</div>

                    <template
                      v-if="
                        isCoordonatesAvailable(
                          prestation.site.coordinates,
                          prestation.id
                        )
                      ">
                      <div
                        v-on:click="openMapModal(prestation.id)"
                        v-tooltip.top="
                          `Consulter l'emplacement sur une carte dynamique`
                        "
                        class="group inline-flex h-10 w-10 cursor-pointer items-center justify-center text-jinius-blue transition-colors hover:text-jinius-dark-green">
                        <font-awesome-icon
                          class="pb-0.5"
                          icon="map-marked-alt"
                          size="2x" />
                      </div>
                      <details-map-modal
                        v-bind:modal_show="map_modal[`key-${prestation.id}`]"
                        v-bind:coordinates="
                          prestation.site.coordinates.split(',')
                        "
                        v-bind:adresse="concatAdress(prestation.site)"
                        v-on:onclose="closeMapModal(prestation.id)" />
                    </template>
                  </div>
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import {
    map_prestations_type_icons,
    map_prestations_type_labels
  } from '@/assets/data/data_maps.js';
  import dates_helpers from '@/mixin/dates_helpers';
  import http_functions from '@/mixin/http_functions';
  import detailsMapModal from '@/views/_espace/details-map-modal';

  export default {
    components: { detailsMapModal },
    mixins: [dates_helpers, http_functions],
    props: {
      programme: {
        type: Array,
        default: null
      },
      classe_virtuelle: {
        type: Boolean,
        default: false
      },
      is_formateur: {
        type: Boolean,
        default: false
      },
      session_url: {
        type: Array,
        default: null
      },
      session_url_webinaire: {
        type: String,
        default: null
      },
      open_connexion_modal: Function
    },
    data() {
      return {
        map_modal: {},
        map_prestations_type_icons,
        map_prestations_type_labels
      };
    },
    methods: {
      openMapModal(key) {
        this.map_modal[`key-${key}`] = true;
      },
      closeMapModal(key) {
        this.map_modal[`key-${key}`] = false;
      },
      isCoordonatesAvailable(coordinates, key) {
        if (coordinates) {
          typeof this.map_modal[`key-${key}`] === 'undefined'
            ? (this.map_modal[`key-${key}`] = false)
            : null;

          return true;
        } else {
          return false;
        }
      },
      concatAdress(site) {
        return `${site.denomination} - ${site.numeroVoie}, ${site.codePostal} ${site.ville}`;
      },
      displayFormateurs(formateurs) {
        return formateurs.map(
          (formateur) => `${formateur.prenom} ${formateur.nom}`
        );
      },
      goToSession(url) {
        window.open(this.formatUrlProtocol(url), '_blank');
      }
    }
  };
</script>

<style lang="scss" scoped>
  .programme-list {
    @apply text-accent-600;

    .dates__container {
      @apply mb-6;
      .dates__title {
        @apply space-x-2 text-xl;
        .title__text {
          @apply align-middle underline decoration-jinius-green decoration-[3px] underline-offset-8;
        }
      }

      .dates__prestations {
        @apply border-1 border-b-0 border-core-400;

        .prestation__container {
          @apply flex min-h-full items-stretch justify-center border-b-1 border-core-400 text-base leading-tight;

          .prestation__time {
            @apply flex flex-col items-center justify-center border-r-1 border-core-400 px-2 py-4 text-center font-bold leading-relaxed;
            min-width: 5rem;
            max-width: 5rem;
          }

          .prestation__content {
            @apply flex flex-grow items-center px-4 py-2;

            .prestation__title {
              @apply space-x-1.5 leading-tight tracking-tight;
            }
          }

          .prestation__address__container {
            @apply flex items-center justify-center border-l-1 border-core-400 px-4 py-2;
            min-width: 13rem;
            max-width: 13rem;

            .prestation__address {
              @apply flex items-center text-xs font-bold leading-tight;
            }
          }
        }
      }
    }
  }
</style>
