const map_tag_colors = {
    metier: 'bg-accent-600 text-white',
    type_formation: 'bg-accent-500 text-white',
    thematique: 'bg-accent-700 text-white',
    public_concerne: 'bg-accent-800 text-white'
};

const map_statut = [
    {
        id: 'accepted',
        label: 'Accepté',
        statut: ['Affecté'],
        extraClass: ['bg-jinius-dark-green']
    },
    {
        id: 'refused',
        label: 'Refusé',
        statut: ['Refusé'],
        extraClass: ['bg-jinius-red']
    },
    {
        id: 'renonced',
        label: 'Renoncé',
        statut: ['Renoncé'],
        extraClass: ['bg-orange-500']
    },
    {
        id: 'waiting',
        label: 'En attente',
        statut: ['Souhaité'],
        extraClass: ['bg-core-500']
    },
    {
        id: 'ouverte',
        label: 'Ouverte',
        statut: ['Ouverte'],
        extraClass: ['bg-jinius-blue']
    },
    {
        id: 'wip',
        label: 'En cours de traitement',
        statut: ['Pressenti', 'Sollicité', 'Planifié'],
        extraClass: ['bg-jinius-blue']
    },
    {
        id: 'confirmed',
        label: 'Confirmée',
        statut: ['Confirmée'],
        extraClass: ['bg-jinius-green']
    },
    {
        id: 'canceled',
        label: 'Annulée',
        statut: ['Annulée'],
        extraClass: ['bg-jinius-red']
    }
];

const map_type_colors = {
    1: 'teal-600',
    2: 'blue-600',
    3: 'cyan-600',
    4: 'orange-600',
    5: 'purple-600',
    6: 'green-600',
    7: 'jinius-red',
    8: 'rose-600'
};

const map_prestations_type_icons = {
    2: 'utensils',
    4: 'comments',
    100: 'bed',
    101: 'bed',
    103: 'box-open',
    3: 'car'
};

const map_prestations_type_labels = {
    2: 'Repas',
    4: 'Animation',
    100: 'Hébergement',
    101: 'Hébergement',
    103: 'Réservation de matériel',
    3: 'Covoiturage'
};

const map_statut_session_colors = {
    1: 'accent-600',
    2: 'blue-600',
    3: 'jinius-green',
    4: 'jinius-dark-green',
    5: 'jinius-red'
};

const map_statut_inscription_colors = {
    isPending: {
        icon: 'clock',
        color: 'bg-accent-600'
    },
    isValid: {
        icon: 'check',
        color: 'bg-jinius-green'
    },
    isNotValid: {
        icon: 'times',
        color: 'bg-jinius-red'
    }
};

const map_statut_inscription_labels = {
    0: 'Inscription potentielle',
    1: 'Inscription en attente',
    2: 'Inscription confirmée',
    3: 'Inscription refusée',
    4: 'Inscription annulée',
    5: 'Inscription transférée'
};

const map_statut_inscription_elearning_labels = {
    1: 'Inscription en attente',
    2: 'Inscription confirmée',
    3: 'Inscription suspendue',
    4: 'Inscription annulée'
};

const map_statut_session_stagiaire_colors = {
    3: {
        icon: 'times',
        color: 'bg-jinius-red'
    },
    2: {
        icon: 'check',
        color: 'bg-jinius-green'
    },
    1: {
        icon: 'clock',
        color: 'bg-jinius-yellow'
    }
};

const map_statut_session_stagiaire_labels = {
    1: 'Formation en cours',
    2: 'Formation validée',
    3: 'Formation non validée'
};

const map_statut_session_labels = {
    1: 'Inscriptions non ouvertes',
    2: 'Inscriptions ouvertes',
    3: 'Confirmée',
    4: 'En attente',
    5: 'Annulée'
};

const map_statut_fiche_colors = { 1: 'jinius-green' };

const map_statut_fiche_labels = { 1: 'Confirmée' };

const map_domain_per_env = {
    local: 'local.test',
    dev: 'safir.dev',
    pilote: 'safir-pilote.fr',
    preprod: 'safir-preprod.fr',
    prod: 'jinius.fr'
};

const map_preferences_sections = [
    {
        name: 'courrier',
        label: 'Courrier',
        icon: 'envelope',
        word: 'courrier',
        valueKey: 'okCourrier'
    },
    {
        name: 'email',
        label: 'E-mail',
        word: 'e-mail',
        icon: 'at',
        valueKey: 'okCourriel'
    },
    {
        name: 'telephone',
        label: 'Téléphone',
        word: 'téléphone',
        icon: 'phone-alt',
        flip: 'horizontal',
        valueKey: 'okTelephone'
    }
];

const map_decription_admin = `<div class='p-4'>
<p class='font-bold text-lg'>Qu'est-ce que le statut &laquo; Administrateur &raquo; ?</p>
    <ul class='list-disc list-outside py-3 px-6'>
        <li class='mb-2'>
        L'administrateur possède l'intégralité des droits d’administration de la société dans l’&laquo; <strong>Espace Entreprise JINIUS</strong> &raquo;.
        </li>
        <li class='mb-2'>
        L’administrateur peut inscrire des participants au nom de la société et voir l'intégralité des commandes, inscriptions et documents liés.
        </li>
        <li class='mb-2'>
        L’administrateur peut également gérer les droits des &laquo; interlocuteurs entrerprise &raquo; de la société et les informations administratives de la société (adresse, entités de facturation, etc.).
        </li>
        <li class='mb-2'>
        Plusieurs administrateurs peuvent être nommés pour une même société.
        </li>
    </ul>
</div>`;

export {
    map_decription_admin,
    map_domain_per_env,
    map_preferences_sections,
    map_prestations_type_icons,
    map_prestations_type_labels,
    map_statut,
    map_statut_fiche_colors,
    map_statut_fiche_labels,
    map_statut_inscription_colors,
    map_statut_inscription_elearning_labels,
    map_statut_inscription_labels,
    map_statut_session_colors,
    map_statut_session_labels,
    map_statut_session_stagiaire_colors,
    map_statut_session_stagiaire_labels,
    map_tag_colors,
    map_type_colors
};
