<template>
  <div>
    <div class="flex items-center lg:justify-end">
      <div
        v-on:click="modal_check = true"
        class="flex cursor-pointer items-center rounded bg-accent-700 px-6 py-2 text-white hover:bg-accent-800">
        <font-awesome-icon icon="eye" />
        <span class="ml-4">Qui peut voir mon CV ?</span>
      </div>
    </div>

    <modal-box
      v-if="modal_check"
      v-bind:width_class="'w-full max-w-screen-xl'"
      v-on:close="modal_check = false"
      v-bind:footer="false">
      <template v-slot:modal-header>
        <h1 class="text-xl font-bold text-core-900">Qui peut voir mon CV ?</h1>
      </template>

      <template v-slot:modal-body>
        <div class="flex flex-wrap items-center pb-4">
          <div class="mb-4 w-full">
            <div
              class="my-4 flex items-center border-l-4 border-accent-500 bg-accent-100 px-2 py-4 leading-none">
              <span class="pl-3 text-sm">
                Renseignez les organismes de formation ayant le droit de
                consulter votre CV depuis leur espace de gestion sécurisé.
              </span>
              <font-awesome-icon
                icon="lock"
                size="1x"
                class="mx-2 mb-0.5 text-accent-500" />
            </div>
          </div>
          <div
            class="flex w-full"
            v-bind:key="item.id"
            v-for="item in list">
            <label
              v-tooltip="{
                content: !item.acces ? 'Activer' : 'Désactiver'
              }"
              class="switch">
              <input
                v-model="item.acces"
                type="checkbox"
                v-on:click="(e) => updateConsent(e, item)" />
              <span class="slider round"></span>
            </label>

            <span
              v-bind:title="item.label"
              class="truncate pl-2"
              >{{ item.perimetreNom }}</span
            >
          </div>
        </div>
      </template>
    </modal-box>
  </div>
</template>

<script>
  import modalBox from '@/components/utils/modal-box.vue';
  import { API } from '@/http-common';

  export default {
    components: { modalBox },
    props: ['list_consent'],
    data() {
      return {
        modal_check: false,
        list: this.list_consent
      };
    },
    methods: {
      async updateConsent(e, item) {
        e.preventDefault();
        const checked = e.target.checked;
        const body = {
          acces: checked,
          id: item.id
        };

        try {
          await API.put(
            'formateur/cv_consentement',
            body,
            this.$header_skip_redirection
          );
          const index = this.list.findIndex(
            (listItem) => listItem.id === item.id
          );

          if (index !== -1) {
            const dummyList = [...this.list];

            dummyList[index] = {
              ...dummyList[index],
              acces: checked
            };
            this.list = dummyList;
          }
        } catch (e) {
          // TODO Handler error
        }
      }
    }
  };
</script>
<style lang="scss" scoped>
  /* The switch - the box around the slider */
  .switch {
    @apply relative ml-1 mt-1 inline-flex h-4 w-8 items-center justify-center;
  }

  /* Hide default HTML checkbox */
  .switch input {
    min-width: 25px;
    @apply h-0 w-0 opacity-0;
  }

  /* The slider */
  .slider {
    @apply absolute inset-0 cursor-pointer bg-accent-500 transition duration-300 ease-in-out;
  }

  .slider:before {
    content: '';
    @apply absolute inset-0 h-3 w-3 bg-white transition duration-300 ease-in-out;
    left: 3px;
    top: 1px;
  }

  input:checked + .slider {
    @apply bg-jinius-dark-green;
  }

  input:checked + .slider:before {
    @apply translate-x-3 transform;
  }

  /* Rounded sliders */
  .slider.round {
    min-width: 25px;
    @apply rounded-lg;
  }

  .slider.round:before {
    @apply rounded-full;
  }
</style>
