<template>
  <div class="w-full lg:h-full">
    <spinner
      v-if="loading"
      sizes="w-20 h-20"
      thickness="border-8" />
    <div
      v-if="!loading"
      class="flex h-full flex-col">
      <h1 class="section__title mb-8 mt-0">
        Synthèse des appréciations participants par session
      </h1>

      <ag-grid-wrapper
        v-bind:column_defs="[
          { headerName: 'Ref.', field: 'reference' },
          { headerName: 'Titre', field: 'titre' },
          { headerName: 'Lieu', field: 'lieuNom' },
          { headerName: 'Date(s)', field: 'sessionDates' },
          { headerName: 'Statut', field: 'statut' },
          {
            headerName: 'DETAILS',
            field: 'details',
            cellRendererParams: {
              alwaysDisplay: true,
              label: 'DETAILS',
              onclick: (e, params) => openDetailsModal(e, params.data)
            }
          }
        ]"
        v-bind:initial_data="sessions" />
    </div>
    <details-modal
      v-if="session"
      v-bind:show_modal="showDetailModal"
      v-bind:session="session"
      v-bind:onclose="closeDetailsModal" />
  </div>
</template>
<script>
  import agGridWrapper from '@/components/utils/ag-grid/ag-grid-wrapper';
  import spinner from '@/components/utils/spinner.vue';
  import { API } from '@/http-common';
  import detailsModal from '@/views/_espace/_formateur/_appreciations/_modal/session-details-modal';

  export default {
    components: {
      agGridWrapper,
      spinner,
      detailsModal
    },
    data: () => ({
      loading: false,
      sessions: [],
      session: null,
      showDetailModal: false
    }),
    created() {
      this.fetchSessions();
    },
    methods: {
      async fetchSessions() {
        this.loading = true;
        const response = await API.get(
          'formateur/satisfaction/sessions',
          this.$header_skip_redirection
        );

        this.sessions = response.data;
        this.loading = false;
      },
      openDetailsModal(e, session) {
        this.session = session;
        this.showDetailModal = true;
      },
      closeDetailsModal() {
        this.session = null;
        this.showDetailModal = false;
      }
    }
  };
</script>
<style lang="scss" scoped></style>
