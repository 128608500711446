<template>
  <header class="header">
    <!-- Header's top section -->
    <div class="header__inner header__inner--start relative">
      <div class="header__wrap header__wrap--top-left">
        <div class="header__item">
          <header-hamburger-button
            v-if="$route.name !== 'aide-en-ligne' && showSidebar"
            v-on:clicked="toggleSidebar()"
            v-bind:icon="sidebar ? 'times' : 'bars'"
            size="lg" />
        </div>
        <div class="header__item">
          <header-catalogue-button
            class="header__link w-full"
            v-if="
              isEspaceParticipant ||
              isEspaceEntreprise ||
              (!isEspaceFormateur &&
                !isFormateur &&
                $route.name !== 'aide-en-ligne')
            " />
        </div>
      </div>

      <div class="header__wrap header__wrap--top-center">
        <div class="header__item z-0 py-1 xl:absolute xl:inset-0">
          <header-logo />
        </div>
      </div>
      <div class="header__wrap header__wrap--top-right">
        <div class="header__item max-w-screen-md">
          <div
            v-if="!$route.meta.no_account_button"
            class="header__item">
            <header-account-button class="header__link" />
          </div>
        </div>

        <template v-if="$breakpoints.w < $breakpoints.screens.lg">
          <header-sidebar
            v-if="showSidebar && sidebar"
            v-on:close="closeSideBar" />
        </template>
      </div>
    </div>
    <div
      v-if="
        before_alert_end &&
        ['participant', 'formateur'].includes($route.params.space_id)
      "
      class="header__inner header__inner--center">
      <div class="w-full bg-white py-2">
        <message-box
          v-bind:source="`Nous vous informons que <span class='text-jinius-red font-bold'>la plateforme e-learning sera indisponible le jeudi 15/2 de 12h à 14h pour une opération de maintenance</span> applicative.<br/>
              Nous vous conseillons de ne pas entamer une session de formation qui ne pourrait pas se terminer avant 12h le jour de l'intervention technique. <br>Cela occasionnerait une perte dans votre avancement. Nous vous remercions pour votre compréhension.`"
          border_color="border-jinius-red"
          class="!my-0 mx-auto max-w-screen-lg text-xs lg:text-base" />
      </div>
    </div>
  </header>
</template>

<script>
  import headerAccountButton from '@/components/header/header-account-button.vue';
  import headerCatalogueButton from '@/components/header/header-catalogue-button.vue';
  import headerLogo from '@/components/header/header-logo.vue';
  import headerSidebar from '@/components/header/header-sidebar.vue';
  import headerHamburgerButton from '@/components/utils/hamburger-button.vue';
  import messageBox from '@/components/utils/message-box.vue';
  import { isBefore } from 'date-fns';
  import { createNamespacedHelpers } from 'vuex';

  const { mapState: mapStateObserver, mapMutations: mapMutationsObserver } =
    createNamespacedHelpers('observer');
  const { mapState: mapStateFormateur } = createNamespacedHelpers('formateur');

  export default {
    components: {
      headerCatalogueButton,
      headerHamburgerButton,
      headerAccountButton,
      headerLogo,
      headerSidebar,
      messageBox
    },
    computed: {
      ...mapStateFormateur(['isFormateur']),
      ...mapStateObserver(['sidebar', 'showSidebar']),
      isEspaceEntreprise() {
        return this.$route.params.space_id === 'entreprise';
      },
      isEspaceParticipant() {
        return this.$route.params.space_id === 'participant';
      },
      isEspaceFormateur() {
        return this.$route.params.space_id === 'formateur';
      },
      before_alert_end() {
        return isBefore(new Date(), new Date(2024, 1, 16));
      }
    },
    watch: {
      $route(to, from) {
        if (this.$breakpoints.w < this.$breakpoints.screens.lg) {
          this.closeSideBar();
        }
      }
    },
    methods: {
      ...mapMutationsObserver(['setSidebar']),
      toggleSidebar() {
        const toggle = this.sidebar ? false : true;

        this.setSidebar(toggle);
      },
      closeSideBar() {
        setTimeout(() => {
          this.setSidebar(false);
        }, 200);
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/css/utils/mixins.scss';

  .header {
    @apply flex w-full flex-wrap;

    .header__inner {
      @apply flex h-full w-full items-center;

      &.header__inner--start {
        @apply z-10 h-20 justify-between bg-white text-core-700;
      }
    }

    .header__wrap {
      @apply inline-flex h-full;

      &.header__wrap--top-left,
      &.header__wrap--top-right {
        @apply z-50;
      }
      &.header__wrap--top-center {
        @apply max-w-[5rem] flex-1 items-center justify-center;
      }

      .header__item {
        @apply flex h-full flex-wrap items-center;

        .header__link {
          @apply inline-flex h-full w-full items-center text-xs;
          @include text-link();
        }
      }
    }
  }
</style>
