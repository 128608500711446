<template>
  <div
    class="refonte-side-menu-espace-item"
    v-bind:class="props.class"
    v-on:click="props.on_click">
    <refonte-pastille
      v-bind:status="
        props.pastille ? props.pastille : props.label.toLowerCase()
      " />
    <span class="side-menu-espace-item-text">
      {{ props.label }}
    </span>
  </div>
</template>

<script setup lang="ts">
  import RefontePastille from '@/components/refonte/pastille/refonte-pastille.vue';

  const props = withDefaults(
    defineProps<{
      on_click?: Function;
      label: string;
      pastille?: string;
      class?: string;
    }>(),
    {
      class: ''
    }
  );
</script>

<style lang="scss" scoped>
  .refonte-side-menu-espace-item {
    @apply flex cursor-pointer items-center self-stretch bg-transparent text-refonte-black;
    gap: 8px;
    border-radius: 4px;
    padding: 8px;

    .side-menu-espace-item-text {
      @apply whitespace-nowrap;
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
    }

    &:hover {
      @apply bg-refonte-pale-violet-3;
    }
  }
</style>
