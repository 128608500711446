<template>
  <div class="formation-container">
    <formation-libre-card
      v-bind:formation="{
        ...formation,
        type: getTypeList(
          formation.classeVirtuelle,
          formation.type,
          formation.ficheType
        )
      }" />
  </div>
</template>

<script>
  import formationLibreCard from '@/components/utils/formation-libre-card';

  export default {
    components: { formationLibreCard },
    props: {
      formation: {
        type: Object,
        default: null
      }
    },
    methods: {
      getTypeList(classe_virtuelle, type, ficheType) {
        const mergedType = {
          id: type.id,
          label: ficheType ? ficheType.label : type.label
        };

        return classe_virtuelle && [1, 4].includes(type.id)
          ? [
              mergedType,
              {
                label: 'Classe virtuelle',
                id: 5
              }
            ]
          : [mergedType];
      }
    }
  };
</script>

<style lang="scss" scoped>
  .collapsed {
    @apply border-transparent;
  }
</style>
