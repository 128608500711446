<template>
  <div class="w-full lg:h-full">
    <spinner
      v-if="loading"
      sizes="w-20 h-20"
      thickness="border-8" />
    <div
      v-if="!loading"
      class="my-4">
      <div
        ref="header_height"
        class="flex flex-wrap items-center justify-between sm:mb-8 lg:mb-0 lg:flex-nowrap">
        <h1 class="section__title mt-0 sm:mb-8">
          Questionnaires de satisfaction
        </h1>
      </div>
      <ag-grid-wrapper
        v-bind:column_defs="[
          {
            headerName: 'Action',
            field: 'action',
            pinned: 'left',
            minWidth: 150,
            cellRenderer: 'buttonCellRenderer',
            cellRendererParams: (e) => {
              return {
                labelDisabled: () => 'Répondu',
                label: 'Répondre',
                onclick: (e, params) => openSatisfactionModal(e, params)
              };
            }
          },
          {
            headerName: 'Date questionnaire',
            field: 'dateCreation',
            ...defaultColumnSettings
          },
          {
            headerName: 'Organisme',
            field: 'organisme',
            ...defaultColumnSettings
          },
          {
            headerName: 'Formation',
            field: 'formation',
            ...defaultColumnSettings
          },
          {
            headerName: 'Répondu le',
            field: 'dateReponse',
            ...defaultColumnSettings
          },
          {
            headerName: 'Répondu par',
            field: 'reponduPar',
            ...defaultColumnSettings
          }
        ]"
        v-bind:initial_data="satisfactions" />
    </div>
    <satisfaction-modal
      v-if="satisfaction_modal"
      v-bind:titre="satisfaction_titre"
      v-bind:evaluation_show="satisfaction_modal"
      v-bind:client_satisfaction_id="client_satisfaction_id"
      v-bind:onclose="closeSatisfactionModal"
      v-bind:fetch_data="fetchSatisfactions"
      v-bind:entreprise="true" />
  </div>
</template>
<script>
  import AgGridWrapper from '@/components/utils/ag-grid/ag-grid-wrapper';
  import Spinner from '@/components/utils/spinner.vue';

  import { createNamespacedHelpers } from 'vuex';
  import { API } from '@/http-common';
  import satisfactionModal from '@/views/_espace/_formateur/_sessions/_modal/satisfaction-modal.vue';
  const { mapState: mapStateClient } = createNamespacedHelpers('client');

  export default {
    components: {
      satisfactionModal,
      AgGridWrapper,
      Spinner
    },
    data() {
      return {
        loading: false,
        satisfactions: [],
        client_satisfaction_id: null,
        satisfaction_titre: null,
        satisfaction_modal: false
      };
    },
    computed: {
      ...mapStateClient(['profile']),
      defaultColumnSettings() {
        return {
          filter: 'agTextColumnFilter',
          cellRenderer: 'textCenterCellRenderer',
          floatingFilter: true
        };
      }
    },
    watch: {
      async ['profile.activeEntrepriseId']() {
        await this.fetchSatisfactions();
      }
    },
    async created() {
      await this.fetchSatisfactions();
    },
    methods: {
      closeSatisfactionModal() {
        this.satisfaction_modal = false;
      },
      openSatisfactionModal(e, params) {
        const { id, formation } = params.data;

        this.client_satisfaction_id = id;
        this.satisfaction_titre = formation;
        this.satisfaction_modal = true;
      },
      async fetchSatisfactions() {
        this.loading = true;
        const clientId = this.profile.activeEntrepriseId;

        if (clientId) {
          const response = await API.get(`client/${clientId}/satisfactions`);

          this.satisfactions = response.data.map((s) => ({
            ...s,
            action: !s.dateReponse
          }));
        }

        this.loading = false;
      }
    }
  };
</script>
<style lang="scss" scoped></style>
