const CLIENT_MUTATION_TYPES = {
    SET_CLIENT_FORM: 'setClientFormFromPayload',
    SET_CLIENT_FORM_ATTRIBUTE: 'setClientFormAttribute',
    SET_CLIENT_FORM_ADDRESS_SIRET: 'setClientFormAddressSiret',
    SET_CLIENT_FORM_ADDRESS_RNA: 'setClientFormAddressRna',
    SET_CLIENT_TYPE: 'setClientType',
    RESET_CLIENT_FORM: 'resetClientForm',
    VALIDATE_CLIENT: 'validateClientForm',
    FETCH_INSEE_INFOS: 'fetchInseeInfos',
    FORCE_ENABLE_CLIENT_FORM: 'forceEnableClientForm',
    SET_TEMP_CLIENT_FORM: 'setTempClientForm',
    COPY_TEMP_CLIENT_FORM: 'copyTempClientForm',
    SET_ENTREPRISE_PROFILE: 'setEntrepriseProfile',
    SET_ACTIVE_ENTREPRISE: 'setActiveEntreprise',
    SET_CLIENT_PARTICIPANTS: 'setClientParticipants',
    SET_CLIENT_ENTITES_FACTURATION: 'setClientEntitesFacturation',
    SET_ENTREPRISE_INFORMATIONS: 'setEntrepriseInformations',
    SET_ENTITE_FORM_ATTRIBUTE: 'setEntiteFormAttribute',
    SET_CLIENT_INTERLOCUTORS: 'setClientInterlocutors'
};

export const MUTATION_TYPES = {
    SET_SPACE: 'setSpace',
    SET_SUB_SPACE: 'setSubSpace',
    SET_CATALOGUE_LIST: 'setCatalogueList',
    SET_MODAL: 'setModal',
    SET_FORM_ATTRIBUTE: 'setFormAttribute',
    SET_CV: 'setCV',
    SET_CV_CONSENTEMENT: 'setCvConsentement',
    SET_THEMES: 'setThemes',
    SET_RUBRIQUE: 'setRubrique',
    SET_ESPACE: 'setEspace',
    SET_ACCOUNT_FORM_ATTRIBUTE: 'setAccountFormAttribute',
    FILL_ACCOUNT_FORM_IF_EMPTY: 'fillAccountFormIfEmpty',
    SET_CONTACT: 'setContact',
    SET_RATTACHEMENT: 'setRattachement',
    SET_CLUBS: 'setClubs',
    SET_STAGIAIRE_PROFILE: 'setStagiaireProfile',
    SET_CONTACT_PROFILE: 'setContactProfile',
    SET_FORMATEUR_PROFILE: 'setFormateurProfile',
    SET_PREFERENCES_FORM_ORGANISMES: 'setPreferencesFormOrganismes',
    SET_PREFERENCES_FORM_SECTIONS_VALUE: 'setPreferencesFormSectionsValue',
    SET_PREFERENCES_FORM_ALL_PREFERENCES: 'setPreferencesFormAllPreferences',
    SET_PREFERENCES: 'setPreferences',
    SET_MODAL_CHECK: 'setModalCheck',
    SET_MODAL_CONFIRMED: 'setModalConfirmed',
    SET_MODAL_ROUTE: 'setModalRoute',
    ...CLIENT_MUTATION_TYPES
};
