export const civilites = [
    {
        id: 0,
        label: 'Choisissez',
        $isDisabled: true
    },
    {
        id: 1,
        label: 'M.'
    },
    {
        id: 2,
        label: 'Mme'
    }
];

export const postes = [
    {
        id: 1,
        label: 'Assistant(e) communication'
    },
    {
        id: 2,
        label: 'Assistant(e) comptable'
    },
    {
        id: 3,
        label: 'Assistant(e) facturation'
    },
    {
        id: 4,
        label: 'Assistant(e) formation'
    },
    {
        id: 5,
        label: 'Assistant(e) RH'
    },
    {
        id: 6,
        label: 'Commercial(e)'
    },
    {
        id: 7,
        label: 'Directeur(trice) formation'
    },
    {
        id: 8,
        label: 'Intérimaire'
    },
    {
        id: 9,
        label: 'Responsable admin. et fin. (DAF)'
    },
    {
        id: 10,
        label: 'Responsable formation'
    },
    {
        id: 11,
        label: 'Responsable RH'
    },
    {
        id: 50,
        label: 'Autre'
    }
];

export const situations = [
    {
        id: 1,
        label: 'Salarié'
    },
    {
        id: 2,
        label: 'Indépendant'
    },
    {
        id: 3,
        label: 'Dirigeant'
    },
    {
        id: 4,
        label: 'Etudiant'
    }
];
export const niveauFormations = [
    {
        id: 1,
        label: 'CAP, BEP'
    },
    {
        id: 2,
        label: 'BAC - Baccalauréat'
    },
    {
        id: 3,
        label: 'BAC + 2 - DEUG, BTS, DUT, DEUST'
    },
    {
        id: 4,
        label: 'BAC + 3 - Licence, Licence LMD, Licence professionnelle'
    },
    {
        id: 5,
        label: 'BAC + 4 - Maîtrise'
    },
    {
        id: 6,
        label: "BAC + 5 - Master, DEA, DESS, Diplôme d'ingénieur"
    },
    {
        id: 7,
        label: 'BAC + 8 - Doctorat, habilitation à diriger des recherches'
    }
];
export const territorialites = [
    {
        id: 1,
        label: 'France métropolitaine'
    },
    {
        id: 2,
        label: 'France métropolitaine (taux réduit)'
    },
    {
        id: 3,
        label: 'DOM-TOM'
    },
    {
        id: 4,
        label: 'DOM-TOM (taux réduit)'
    },
    {
        id: 5,
        label: 'Export'
    },
    {
        id: 6,
        label: 'Exonéré'
    }
];

export const natureId = {
    siret: 1,
    rna: 2,
    particulier: 3
};

export const natureList = [
    {
        id: 1,
        label: 'Entreprise',
        shortcut: 'siret'
    },
    {
        id: 2,
        label: 'Association',
        shortcut: 'rna'
    },
    {
        id: 3,
        label: 'Particulier',
        shortcut: 'particulier'
    }
];

export const pays = [
    {
        id: '99212',
        label: 'AFGHANISTAN'
    },
    {
        id: '99303',
        label: 'AFRIQUE DU SUD'
    },
    {
        id: '99125',
        label: 'ALBANIE'
    },
    {
        id: '99352',
        label: 'ALGERIE'
    },
    {
        id: '99109',
        label: 'ALLEMAGNE'
    },
    {
        id: '99130',
        label: 'ANDORRE'
    },
    {
        id: '99395',
        label: 'ANGOLA'
    },
    {
        id: '99441',
        label: 'ANTIGUA-ET-BARBUDA'
    },
    {
        id: '99201',
        label: 'ARABIE SAOUDITE'
    },
    {
        id: '99415',
        label: 'ARGENTINE'
    },
    {
        id: '99252',
        label: 'ARMENIE'
    },
    {
        id: '99501',
        label: 'AUSTRALIE'
    },
    {
        id: '99110',
        label: 'AUTRICHE'
    },
    {
        id: '99253',
        label: 'AZERBAIDJAN'
    },
    {
        id: '99436',
        label: 'BAHAMAS'
    },
    {
        id: '99249',
        label: 'BAHREIN'
    },
    {
        id: '99246',
        label: 'BANGLADESH'
    },
    {
        id: '99434',
        label: 'BARBADE'
    },
    {
        id: '99131',
        label: 'BELGIQUE'
    },
    {
        id: '99429',
        label: 'BELIZE'
    },
    {
        id: '99327',
        label: 'BENIN'
    },
    {
        id: '99214',
        label: 'BHOUTAN'
    },
    {
        id: '99148',
        label: 'BIELORUSSIE'
    },
    {
        id: '99224',
        label: 'BIRMANIE'
    },
    {
        id: '99418',
        label: 'BOLIVIE'
    },
    {
        id: '99118',
        label: 'BOSNIE-HERZEGOVINE'
    },
    {
        id: '99347',
        label: 'BOTSWANA'
    },
    {
        id: '99416',
        label: 'BRESIL'
    },
    {
        id: '99225',
        label: 'BRUNEI'
    },
    {
        id: '99111',
        label: 'BULGARIE'
    },
    {
        id: '99331',
        label: 'BURKINA'
    },
    {
        id: '99321',
        label: 'BURUNDI'
    },
    {
        id: '99234',
        label: 'CAMBODGE'
    },
    {
        id: '99322',
        label: 'CAMEROUN'
    },
    {
        id: '99401',
        label: 'CANADA'
    },
    {
        id: '99396',
        label: 'CAP-VERT'
    },
    {
        id: '99323',
        label: 'CENTRAFRIQUE'
    },
    {
        id: '99417',
        label: 'CHILI'
    },
    {
        id: '99216',
        label: 'CHINE'
    },
    {
        id: '99254',
        label: 'CHYPRE'
    },
    {
        id: '99419',
        label: 'COLOMBIE'
    },
    {
        id: '99397',
        label: 'COMORES'
    },
    {
        id: '99324',
        label: 'CONGO'
    },
    {
        id: '99312',
        label: 'REPUBLIQUE DEMOCRATIQUE DU CONGO'
    },
    {
        id: '99502',
        label: 'ILES COOK'
    },
    {
        id: '99238',
        label: 'COREE DU NORD'
    },
    {
        id: '99239',
        label: 'COREE DU SUD'
    },
    {
        id: '99406',
        label: 'COSTA RICA'
    },
    {
        id: '99326',
        label: "COTE D'IVOIRE"
    },
    {
        id: '99119',
        label: 'CROATIE'
    },
    {
        id: '99407',
        label: 'CUBA'
    },
    {
        id: '99101',
        label: 'DANEMARK'
    },
    {
        id: '99399',
        label: 'DJIBOUTI'
    },
    {
        id: '99408',
        label: 'REPUBLIQUE DOMINICAINE'
    },
    {
        id: '99438',
        label: 'DOMINIQUE'
    },
    {
        id: '99301',
        label: 'EGYPTE'
    },
    {
        id: '99247',
        label: 'EMIRATS ARABES UNIS'
    },
    {
        id: '99420',
        label: 'EQUATEUR'
    },
    {
        id: '99317',
        label: 'ERYTHREE'
    },
    {
        id: '99134',
        label: 'ESPAGNE'
    },
    {
        id: '99106',
        label: 'ESTONIE'
    },
    {
        id: '99391',
        label: 'ESWATINI'
    },
    {
        id: '99404',
        label: 'ETATS-UNIS'
    },
    {
        id: '99315',
        label: 'ETHIOPIE'
    },
    {
        id: '99508',
        label: 'FIDJI'
    },
    {
        id: '99105',
        label: 'FINLANDE'
    },
    {
        id: null,
        label: 'FRANCE'
    },
    {
        id: '99328',
        label: 'GABON'
    },
    {
        id: '99304',
        label: 'GAMBIE'
    },
    {
        id: '99255',
        label: 'GEORGIE'
    },
    {
        id: '99329',
        label: 'GHANA'
    },
    {
        id: '99126',
        label: 'GRECE'
    },
    {
        id: '99435',
        label: 'GRENADE'
    },
    {
        id: '99409',
        label: 'GUATEMALA'
    },
    {
        id: '99330',
        label: 'GUINEE'
    },
    {
        id: '99314',
        label: 'GUINEE EQUATORIALE'
    },
    {
        id: '99392',
        label: 'GUINEE-BISSAO'
    },
    {
        id: '99428',
        label: 'GUYANA'
    },
    {
        id: '99410',
        label: 'HAITI'
    },
    {
        id: '99411',
        label: 'HONDURAS'
    },
    {
        id: '99112',
        label: 'HONGRIE'
    },
    {
        id: '99223',
        label: 'INDE'
    },
    {
        id: '99231',
        label: 'INDONESIE'
    },
    {
        id: '99203',
        label: 'IRAK'
    },
    {
        id: '99204',
        label: 'IRAN'
    },
    {
        id: '99136',
        label: 'IRLANDE'
    },
    {
        id: '99102',
        label: 'ISLANDE'
    },
    {
        id: '99207',
        label: 'ISRAEL'
    },
    {
        id: '99127',
        label: 'ITALIE'
    },
    {
        id: '99426',
        label: 'JAMAIQUE'
    },
    {
        id: '99217',
        label: 'JAPON'
    },
    {
        id: '99222',
        label: 'JORDANIE'
    },
    {
        id: '99256',
        label: 'KAZAKHSTAN'
    },
    {
        id: '99332',
        label: 'KENYA'
    },
    {
        id: '99257',
        label: 'KIRGHIZSTAN'
    },
    {
        id: '99513',
        label: 'KIRIBATI'
    },
    {
        id: '99157',
        label: 'KOSOVO'
    },
    {
        id: '99240',
        label: 'KOWEIT'
    },
    {
        id: '99241',
        label: 'LAOS'
    },
    {
        id: '99348',
        label: 'LESOTHO'
    },
    {
        id: '99107',
        label: 'LETTONIE'
    },
    {
        id: '99205',
        label: 'LIBAN'
    },
    {
        id: '99302',
        label: 'LIBERIA'
    },
    {
        id: '99316',
        label: 'LIBYE'
    },
    {
        id: '99113',
        label: 'LIECHTENSTEIN'
    },
    {
        id: '99108',
        label: 'LITUANIE'
    },
    {
        id: '99137',
        label: 'LUXEMBOURG'
    },
    {
        id: '99156',
        label: 'MACEDOINE DU NORD'
    },
    {
        id: '99333',
        label: 'MADAGASCAR'
    },
    {
        id: '99227',
        label: 'MALAISIE'
    },
    {
        id: '99334',
        label: 'MALAWI'
    },
    {
        id: '99229',
        label: 'MALDIVES'
    },
    {
        id: '99335',
        label: 'MALI'
    },
    {
        id: '99144',
        label: 'MALTE'
    },
    {
        id: '99350',
        label: 'MAROC'
    },
    {
        id: '99515',
        label: 'ILES MARSHALL'
    },
    {
        id: '99390',
        label: 'MAURICE'
    },
    {
        id: '99336',
        label: 'MAURITANIE'
    },
    {
        id: '99405',
        label: 'MEXIQUE'
    },
    {
        id: '99516',
        label: 'MICRONESIE'
    },
    {
        id: '99151',
        label: 'MOLDAVIE'
    },
    {
        id: '99138',
        label: 'MONACO'
    },
    {
        id: '99242',
        label: 'MONGOLIE'
    },
    {
        id: '99120',
        label: 'MONTENEGRO'
    },
    {
        id: '99393',
        label: 'MOZAMBIQUE'
    },
    {
        id: '99311',
        label: 'NAMIBIE'
    },
    {
        id: '99507',
        label: 'NAURU'
    },
    {
        id: '99215',
        label: 'NEPAL'
    },
    {
        id: '99412',
        label: 'NICARAGUA'
    },
    {
        id: '99337',
        label: 'NIGER'
    },
    {
        id: '99338',
        label: 'NIGERIA'
    },
    {
        id: '99502',
        label: 'NIUE'
    },
    {
        id: '99103',
        label: 'NORVEGE'
    },
    {
        id: '99502',
        label: 'NOUVELLE-ZELANDE'
    },
    {
        id: '99250',
        label: 'OMAN'
    },
    {
        id: '99339',
        label: 'OUGANDA'
    },
    {
        id: '99258',
        label: 'OUZBEKISTAN'
    },
    {
        id: '99213',
        label: 'PAKISTAN'
    },
    {
        id: '99517',
        label: 'PALAOS'
    },
    {
        id: '99413',
        label: 'PANAMA'
    },
    {
        id: '99510',
        label: 'PAPOUASIE-NOUVELLE-GUINEE'
    },
    {
        id: '99421',
        label: 'PARAGUAY'
    },
    {
        id: '99135',
        label: 'PAYS-BAS'
    },
    {
        id: '99422',
        label: 'PEROU'
    },
    {
        id: '99220',
        label: 'PHILIPPINES'
    },
    {
        id: '99122',
        label: 'POLOGNE'
    },
    {
        id: '99139',
        label: 'PORTUGAL'
    },
    {
        id: '99248',
        label: 'QATAR'
    },
    {
        id: '99114',
        label: 'ROUMANIE'
    },
    {
        id: '99132',
        label: 'ROYAUME-UNI'
    },
    {
        id: '99123',
        label: 'RUSSIE'
    },
    {
        id: '99340',
        label: 'RWANDA'
    },
    {
        id: '99442',
        label: 'SAINT-CHRISTOPHE-ET-NIEVES'
    },
    {
        id: '99439',
        label: 'SAINTE-LUCIE'
    },
    {
        id: '99128',
        label: 'SAINT-MARIN'
    },
    {
        id: '99440',
        label: 'SAINT-VINCENT-ET-LES-GRENADINES'
    },
    {
        id: '99512',
        label: 'SALOMON'
    },
    {
        id: '99414',
        label: 'SALVADOR'
    },
    {
        id: '99506',
        label: 'SAMOA'
    },
    {
        id: '99394',
        label: 'SAO TOME-ET-PRINCIPE'
    },
    {
        id: '99341',
        label: 'SENEGAL'
    },
    {
        id: '99121',
        label: 'SERBIE'
    },
    {
        id: '99398',
        label: 'SEYCHELLES'
    },
    {
        id: '99342',
        label: 'SIERRA LEONE'
    },
    {
        id: '99226',
        label: 'SINGAPOUR'
    },
    {
        id: '99117',
        label: 'SLOVAQUIE'
    },
    {
        id: '99145',
        label: 'SLOVENIE'
    },
    {
        id: '99318',
        label: 'SOMALIE'
    },
    {
        id: '99343',
        label: 'SOUDAN'
    },
    {
        id: '99349',
        label: 'SOUDAN DU SUD'
    },
    {
        id: '99235',
        label: 'SRI LANKA'
    },
    {
        id: '99104',
        label: 'SUEDE'
    },
    {
        id: '99140',
        label: 'SUISSE'
    },
    {
        id: '99437',
        label: 'SURINAME'
    },
    {
        id: '99206',
        label: 'SYRIE'
    },
    {
        id: '99259',
        label: 'TADJIKISTAN'
    },
    {
        id: '99309',
        label: 'TANZANIE'
    },
    {
        id: '99344',
        label: 'TCHAD'
    },
    {
        id: '99116',
        label: 'TCHEQUIE'
    },
    {
        id: '99219',
        label: 'THAILANDE'
    },
    {
        id: '99262',
        label: 'TIMOR ORIENTAL'
    },
    {
        id: '99345',
        label: 'TOGO'
    },
    {
        id: '99509',
        label: 'TONGA'
    },
    {
        id: '99433',
        label: 'TRINITE-ET-TOBAGO'
    },
    {
        id: '99351',
        label: 'TUNISIE'
    },
    {
        id: '99260',
        label: 'TURKMENISTAN'
    },
    {
        id: '99208',
        label: 'TURQUIE'
    },
    {
        id: '99511',
        label: 'TUVALU'
    },
    {
        id: '99155',
        label: 'UKRAINE'
    },
    {
        id: '99423',
        label: 'URUGUAY'
    },
    {
        id: '99514',
        label: 'VANUATU'
    },
    {
        id: '99129',
        label: 'VATICAN'
    },
    {
        id: '99424',
        label: 'VENEZUELA'
    },
    {
        id: '99243',
        label: 'VIETNAM'
    },
    {
        id: '99251',
        label: 'YEMEN'
    },
    {
        id: '99346',
        label: 'ZAMBIE'
    },
    {
        id: '99310',
        label: 'ZIMBABWE'
    }
];
export const droitsCommande = [
    {
        id: 1,
        label: 'Tout le monde'
    },
    {
        id: 2,
        label: 'Les administrateurs et les personnes autorisées (choix par défaut)'
    }
];
export const trancheEffectifs = [
    {
        id: 'NN',
        label: 'Unités non employeuses'
    },
    {
        id: '00',
        label: '0 salarié'
    },
    {
        id: '01',
        label: '1 ou 2 salariés'
    },
    {
        id: '02',
        label: '3 à 5 salariés'
    },
    {
        id: '03',
        label: '6 à 9 salariés'
    },
    {
        id: '11',
        label: '10 à 19 salariés'
    },
    {
        id: '12',
        label: '20 à 49 salariés'
    },
    {
        id: '21',
        label: '50 à 99 salariés'
    },
    {
        id: '22',
        label: '100 à 199 salariés'
    },
    {
        id: '31',
        label: '200 à 249 salariés'
    },
    {
        id: '32',
        label: '250 à 499 salariés'
    },
    {
        id: '41',
        label: '500 à 999 salariés'
    },
    {
        id: '42',
        label: '1 000 à 1 999 salariés'
    },
    {
        id: '51',
        label: '2 000 à 4 999 salariés'
    },
    {
        id: '52',
        label: '5 000 à 9 999 salariés'
    },
    {
        id: '53',
        label: '10 000 salariés et plus'
    }
];

export const roles = [
    {
        id: 1,
        label: 'Contact principal'
    },
    {
        id: 2,
        label: 'Contact commercial'
    },
    {
        id: 3,
        label: 'Contact facturation'
    },
    {
        id: 4,
        label: 'Responsable formation'
    },
    {
        id: 5,
        label: 'Dirigeant'
    },
    {
        id: 6,
        label: 'Salarié'
    }
];
