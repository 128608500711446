// Official Fontawesome library for VueJS
// https://github.com/FortAwesome/vue-fontawesome

// import {library} from '@fortawesome/fontawesome-svg-core';
import { dom, library } from '@fortawesome/fontawesome-svg-core';
// DEVELOPEMENT (Load all icons)
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import {
    FontAwesomeIcon,
    FontAwesomeLayers,
    FontAwesomeLayersText
} from '@fortawesome/vue-fontawesome';

library.add(fab, fas, far);

// PRODUCTION (Pick up each necessary icons manually to reduce bundle size)
// import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
// import {faCheckCircle} from '@fortawesome/free-solid-svg-icons';
// import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
// import {faShare} from '@fortawesome/free-solid-svg-icons';

// library.add(faExclamationTriangle, faCheckCircle, faInfoCircle, faShare);

// Dynamically replacing <i> tags with <svg> !important in use with vue-toasted
dom.watch();

const VueFontAwesome = [
    'font-awesome-icon',
    FontAwesomeIcon,
    'font-awesome-layers',
    FontAwesomeLayers,
    'font-awesome-layers-text',
    FontAwesomeLayersText
];

export default VueFontAwesome;
