<template>
  <modal-box
    v-if="modal_show"
    v-bind:width_class="'w-full max-w-screen-md'"
    v-on:close="onclose"
    v-bind:footer="false">
    <template v-slot:modal-header>
      <h1 class="text-xl font-bold text-core-900">Enregistrement confirmé</h1>
    </template>

    <template v-slot:modal-body>
      <div
        class="my-8 flex w-full items-center justify-center px-4 text-center font-bold">
        Vos modifications ont bien été enregistrées.<br />{{ additional_text }}
      </div>
    </template>
  </modal-box>
</template>
<script>
  import modalBox from '@/components/utils/modal-box.vue';
  export default {
    components: { modalBox },
    props: ['modal_show', 'onclose', 'additional_text']
  };
</script>
<style lang="scss" scoped></style>
