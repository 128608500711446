<template>
  <div
    class="refonte-details-header"
    v-bind:class="props.class">
    <div class="details-header-title-section">
      <div class="details-header-title-section-top">
        <div class="details-header-title-section-top-tags">
          <slot name="tags"></slot>
        </div>
        <div class="details-header-title-section-top-content">
          <h1 class="details-header-title-section-top-content-text">
            {{ props.title }}
          </h1>
          <refonte-details-header-item
            v-if="!!slots['accessibility']"
            text="Accessibilité">
            <slot name="accessibility"></slot>
          </refonte-details-header-item>
        </div>
      </div>
      <div
        class="details-header-title-section-bottom"
        v-if="
          props.reference ||
          props.other_reference ||
          props.on_click_fiche_programme
        ">
        <div
          v-if="props.reference"
          class="details-header-title-section-bottom-reference">
          {{ props.reference }}
        </div>
        <IconSeparatorVertical
          v-if="props.reference && props.other_reference" />
        <div
          v-if="props.other_reference"
          class="details-header-title-section-bottom-reference">
          {{ props.other_reference }}
        </div>
        <IconSeparatorVertical
          v-if="
            (props.reference || props.other_reference) &&
            props.on_click_fiche_programme
          " />
        <refonte-button
          v-if="props.on_click_fiche_programme"
          variant="secondary"
          v-bind:icon_right="IconOpenNewTab"
          v-bind:on_click="props.on_click_fiche_programme">
          Fiche programme
        </refonte-button>
      </div>
    </div>
    <div
      v-if="props.session"
      class="details-header-infos-section">
      <refonte-card class="details-header-infos-section-left">
        <div class="details-header-infos-section-left-items-container">
          <div class="details-header-infos-section-left-container-items">
            <refonte-details-header-item
              v-bind:icon_left="IconDate"
              text="Le 20/12/2023"
              additional_text="Journée entière" />
            <refonte-details-header-item
              v-bind:icon_left="IconParticipants"
              text="7" />
            <refonte-details-header-item
              v-bind:icon_left="IconHourglass"
              text="7h" />
          </div>
          <div class="details-header-infos-section-left-container-items">
            <refonte-details-header-item
              v-bind:icon_left="IconTime"
              text="8h30 - 12h30 / 14h - 17h30" />
            <refonte-details-header-item
              v-bind:icon_left="IconLunch"
              text="Repas non compris" />
          </div>
        </div>
        <refonte-separator
          class="details-header-infos-section-left-separator" />
        <div class="details-header-infos-section-left-address">
          <div class="details-header-infos-section-left-address-icon">
            <IconLocation />
          </div>
          <div class="details-header-infos-section-left-address-text">
            <span class="font-bold">CRCC de Versailles et du Centre</span>
            <span>28 av. Jean-Jacques ROUSSEAU</span>
            <span>92000 La Défense, Paris</span>
          </div>
        </div>
      </refonte-card>
      <refonte-card class="details-header-infos-section-right">
        <refonte-details-header-item
          v-bind:icon_left="IconCertification"
          text="Formation non certifiante" />
        <div class="details-header-infos-section-right-row">
          <refonte-details-header-item
            v-bind:icon_left="IconTrainingProvider"
            text="en INTER"
            additional_text="Dispensé par CNCC Formation" />
          <refonte-button variant="secondary">
            Contacter l’organisateur
          </refonte-button>
        </div>
      </refonte-card>
    </div>
    <refonte-card class="details-header-convention-section">
      <refonte-item-convention
        v-bind:icon="IconConventionCollective"
        title="Convention collective"
        subtitle="Tous les participants"
        v-bind:on_click_download="
          () => console.log('Téléchargement convention')
        "
        v-bind:on_select="() => console.log('Selectionner')"
        convention_signed_date="Chargée le 31/09/2023"
        v-bind:on_click_download_signed="
          () => console.log('Téléchargement convention signée')
        " />
    </refonte-card>
  </div>
</template>

<script setup lang="ts">
  import { useSlots } from 'vue';
  import IconCertification from '@/assets/img/refonte/icons/information/icon-certification.svg?component';
  import IconDate from '@/assets/img/refonte/icons/information/icon-date.svg?component';
  import IconConventionCollective from '@/assets/img/refonte/icons/users/icon-convention-collective.svg?component';
  import IconHourglass from '@/assets/img/refonte/icons/information/icon-hourglass.svg?component';
  import IconLocation from '@/assets/img/refonte/icons/information/icon-location.svg?component';
  import IconLunch from '@/assets/img/refonte/icons/information/icon-lunch.svg?component';
  import IconOpenNewTab from '@/assets/img/refonte/icons/action/icon-open-new-tab.svg?component';
  import IconParticipants from '@/assets/img/refonte/icons/menu/icon-trainees.svg?component';
  import IconSeparatorVertical from '@/assets/img/refonte/icons/display/icon-separator-vertical.svg?component';
  import IconTime from '@/assets/img/refonte/icons/information/icon-time.svg?component';
  import IconTrainingProvider from '@/assets/img/refonte/icons/users/icon-training-provider.svg?component';
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import RefonteDetailsHeaderItem from '@/components/refonte/details/refonte-details-header-item.vue';
  import RefonteCard from '@/components/refonte/cards/refonte-card.vue';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';
  import RefonteItemConvention from '@/components/refonte/items/refonte-item-convention.vue';

  const props = withDefaults(
    defineProps<{
      title: string;
      reference?: string;
      other_reference?: string;
      on_click_fiche_programme?: Function;
      session?: Object;
      class?: string;
    }>(),
    {
      class: ''
    }
  );

  const slots = useSlots();
</script>

<style lang="scss" scoped>
  .refonte-details-header {
    @apply flex flex-col items-start self-stretch;
    gap: 12px;

    .details-header-title-section {
      @apply flex flex-col items-start self-stretch;
      color: #000;
      gap: 4px;
      padding: 0 12px;

      .details-header-title-section-top {
        @apply flex flex-col items-start justify-center self-stretch;
        gap: 2px;

        .details-header-title-section-top-tags {
          @apply flex flex-wrap items-center;
          gap: 2px;
        }
        .details-header-title-section-top-content {
          @apply flex flex-wrap items-center justify-end self-stretch;
          gap: 12px;

          .details-header-title-section-top-content-text {
            @apply flex-grow;
          }
        }
      }
      .details-header-title-section-bottom {
        @apply flex flex-wrap items-center self-stretch;
        gap: 8px;

        .details-header-title-section-bottom-reference {
          font-size: 13px;
          font-weight: 400;
          line-height: 16px;
        }
      }
    }
    .details-header-infos-section {
      @apply flex items-stretch self-stretch;
      gap: 12px;

      .details-header-infos-section-left {
        @apply flex-row items-start gap-0;

        .details-header-infos-section-left-items-container {
          @apply flex flex-col items-start;

          .details-header-infos-section-left-container-items {
            @apply flex flex-wrap items-start justify-items-start;
          }
        }
        .details-header-infos-section-left-separator {
          @apply items-stretch;

          :deep(.separator-content) {
            padding: 4px 3px;

            .separator-content-line {
              @apply bg-refonte_blue-300;
              opacity: 0.3;
              min-width: 2px;
              max-width: 2px;
              height: 100%;
            }
          }
        }
        .details-header-infos-section-left-address {
          @apply flex items-start;
          gap: 6px;
          padding: 10px;

          .details-header-infos-section-left-address-icon {
            @apply flex shrink-0 items-center justify-center;
            width: 16px;
            height: 16px;

            :deep(svg) {
              @apply text-refonte-black;
              width: 16px;
              height: 16px;
            }
          }
          .details-header-infos-section-left-address-text {
            @apply flex flex-col items-start;
            gap: 4px;

            span {
              font-size: 13px;
              font-weight: 400;
              line-height: 16px;

              &.font-bold {
                font-weight: 600;
              }
            }
          }
        }
      }
      .details-header-infos-section-right {
        @apply flex-grow items-start gap-0;

        .details-header-infos-section-right-row {
          @apply flex flex-wrap items-center self-stretch;
        }
      }
      @media (max-width: 999.98px) {
        @apply flex-wrap;

        .details-header-infos-section-left {
          @apply flex-grow justify-between;
        }
      }
      @media (max-width: 499.98px) {
        .details-header-infos-section-left {
          @apply flex-col;

          .details-header-infos-section-left-separator {
            @apply items-stretch;

            :deep(.separator-content) {
              padding: 3px;

              .separator-content-line {
                min-width: 24px;
                width: 100%;
                max-width: 100%;
                height: 2px;
              }
            }
          }
        }
      }
    }
    .details-header-convention-section {
      @apply self-stretch;
    }
  }
</style>
