<template>
  <div
    class="refonte-tag"
    v-bind:class="`tag-${props.variant} ${props.class}`">
    <component
      v-if="props.icon"
      v-bind:is="props.icon" />
    <span v-if="!!slots['default']">
      <slot></slot>
    </span>
  </div>
</template>

<script setup lang="ts">
  import { useSlots } from 'vue';

  const props = withDefaults(
    defineProps<{
      variant?: string;
      icon?: Element;
      class?: string;
    }>(),
    {
      class: ''
    }
  );

  const slots = useSlots();
</script>

<style lang="scss" scoped>
  .refonte-tag {
    @apply inline-flex shrink-0 items-center bg-refonte_blue-100 text-refonte_blue-600;
    border-radius: 4px;
    padding: 0 4px;
    height: 20px;

    :deep(svg) {
      @apply text-refonte_blue-600;
      min-width: 16px;
      width: 16px;
      height: 16px;
    }
    span {
      font-size: 11px;
      font-weight: 400;
      line-height: 125%;
      text-transform: uppercase;
    }

    &.tag-presentiel {
      @apply bg-refonte-presentiel text-refonte-white;
      gap: 2px;

      :deep(svg) {
        @apply text-refonte-white;
      }
    }
    &.tag-cursus {
      @apply bg-refonte-cursus text-refonte-white;
      gap: 2px;

      :deep(svg) {
        @apply text-refonte-white;
      }
    }
    &.tag-e-learning {
      @apply bg-refonte-e-learning text-refonte-white;
      gap: 2px;

      :deep(svg) {
        @apply text-refonte-white;
      }
    }
    &.tag-webinar {
      @apply bg-refonte-webinar text-refonte-white;
      gap: 2px;

      :deep(svg) {
        @apply text-refonte-white;
      }
    }
    &.tag-classe-virtuelle {
      @apply bg-refonte-classe-virtuelle text-refonte-white;
      gap: 2px;

      :deep(svg) {
        @apply text-refonte-white;
      }
    }
    &.tag-conference {
      @apply bg-refonte-conference text-refonte-white;
      gap: 2px;

      :deep(svg) {
        @apply text-refonte-white;
      }
    }
    &.tag-parcours {
      @apply bg-refonte-e-learning text-refonte-white;
      gap: 2px;

      :deep(svg) {
        @apply text-refonte-white;
      }
    }
    &.tag-new {
      @apply text-refonte-white;
      gap: 2px;
      background-color: #6635f1;

      :deep(svg) {
        @apply text-refonte-white;
      }
    }
    &.tag-active {
      @apply text-refonte-white;
      gap: 2px;
      background-color: #26e087;

      :deep(svg) {
        @apply text-refonte-white;
      }
    }
    &.tag-deactivated {
      @apply text-refonte-white;
      gap: 2px;
      background-color: #464865;

      :deep(svg) {
        @apply text-refonte-white;
      }
    }
    &.tag-success {
      @apply bg-jinius-green text-refonte-white;
      gap: 2px;

      :deep(svg) {
        @apply text-refonte-white;
      }
    }
    &.tag-warning {
      @apply bg-refonte-secondary text-refonte-white;
      gap: 2px;

      :deep(svg) {
        @apply text-refonte-white;
      }
    }
    &.tag-danger {
      @apply bg-jinius-red text-refonte-white;
      gap: 2px;

      :deep(svg) {
        @apply text-refonte-white;
      }
    }
  }
</style>
