<template>
  <default-renderer
    centered
    v-bind:params="value"
    v-bind:status_class="extraClass" />
</template>
<script>
  import { map_statut } from '@/assets/data/data_maps';
  import defaultRenderer from '@/components/utils/ag-grid/default-renderer';

  export default {
    components: { defaultRenderer },
    computed: {
      extraClass() {
        const statut = map_statut.find((s) =>
          s.statut.includes(this.params.value)
        );

        return statut ? statut.extraClass : ['bg-core-500'];
      },
      value() {
        const statut = map_statut.find((s) =>
          s.statut.includes(this.params.value)
        );

        return {
          value: statut
            ? statut.label
            : 'label' in this.params.value
              ? this.params.value.label
              : this.params.value
        };
      }
    }
  };
</script>
