// (C) Copyright 2018 CNCC Services <https://www.cncc.fr/>
// This program is the sole property of CNCC Services and is not free software.

import { default as util } from '@/mixin/utils/functions_filters.js';

export default {
    methods: {
        toFrDate(date_str) {
            return util.toFrDate(date_str);
        },
        toFrDateTime(date_str) {
            return util.toFrDateTime(date_str);
        },
        toFrNumber(value) {
            if (value == 0) {
                value = util.padNumber(value, 1);
            }

            return util.toFrNumber(value);
        },
        firstLetterCapitalize(value) {
            if (!value) {
                return '';
            }
            // Turn value toString and trim all white spaces before the first letter
            value = value.toString().replace(/^\s\s*/, '');

            return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
        },
        readMore(value, length, suffix) {
            return value.substring(0, length) + suffix;
        }
    }
};
