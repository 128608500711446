<template>
  <div class="flex w-full flex-wrap justify-center py-4 lg:flex-nowrap lg:py-0">
    <div class="w-full max-w-[180px]">
      <picture-upload
        class="box-content flex flex-wrap items-center justify-center rounded-lg p-2"
        image_ref="image"
        max_width="160"
        max_height="200"
        name="photo"
        v-bind:default_image="imageSrc"
        v-on:load-image="loadImage"
        v-on:cancel-image="cancelImage" />
    </div>

    <div
      class="flex w-full flex-col justify-center py-2 text-center leading-none text-core-900 lg:justify-between lg:pl-10 lg:text-left">
      <div class="w-full text-3xl text-core-600">MON CV</div>
      <div>
        <div
          v-if="user && user.name"
          class="mb-2 w-full text-5xl font-bold text-jinius-dark-green">
          {{ user.name }}
        </div>
        <div class="mb-2 ml-1 w-full text-3xl text-accent-400">
          {{ formateurGender }}
        </div>
        <div
          v-if="account_societe"
          class="ml-1 w-full text-accent-500">
          <font-awesome-icon
            icon="building"
            class="align-baseline text-2xl leading-none" />
          <span class="px-1.5 align-baseline text-3xl leading-none">
            {{ account_societe }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import pictureUpload from '@/components/utils/picture-upload';
  import { API } from '@/http-common';
  import { createNamespacedHelpers } from 'vuex';

  const { mapState: mapStateAuth } = createNamespacedHelpers('auth');

  export default {
    components: { pictureUpload },
    props: ['cv_form', 'set_attribute', 'account_civilite', 'account_societe'],
    data() {
      return {
        imageSrc: null
        // user_img:
      };
    },
    computed: {
      ...mapStateAuth(['user']),
      formateurGender() {
        return this.account_civilite && this.account_civilite == 2
          ? 'Formatrice'
          : 'Formateur';
      }
    },
    created() {
      this.fetchImage();
    },
    methods: {
      async fetchImage() {
        const response = await API.get('formateur/cv_photo', {
          responseType: 'arraybuffer',
          ...this.$header_skip_redirection
        });

        const image = btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ''
          )
        );

        this.imageSrc = `data:${response.headers['content-type'].toLowerCase()};base64,${image}`;
      },
      loadImage(value) {
        this.set_attribute(value, 'photo');
      },
      cancelImage() {
        this.set_attribute(null, 'photo');
      }
    }
  };
</script>
<style lang="scss" scoped></style>
