<template>
  <refonte-modal
    v-bind:show="props.show"
    v-on:close="props.on_close"
    title="Modifications non enregistrées"
    v-bind:no_close_btn="true"
    v-bind:no_outside_click="true">
    <template v-slot:modalBody>
      <div class="flex flex-col gap-[12px]">
        <span>
          En quittant sans enregistrer, les modifications ne seront pas
          appliquées.
        </span>
        <span>Que souhaitez-vous faire ?</span>
      </div>
    </template>
    <template v-slot:modalFooter>
      <refonte-button
        variant="text"
        v-bind:on_click="props.on_close">
        Revenir
      </refonte-button>
      <refonte-button
        variant="text"
        v-bind:on_click="props.on_save">
        Enregistrer et quitter
      </refonte-button>
      <refonte-button v-bind:on_click="props.on_ok">
        Quitter sans enregistrer
      </refonte-button>
    </template>
  </refonte-modal>
</template>

<script setup lang="ts">
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import RefonteModal from '@/components/refonte/modals/refonte-modal.vue';

  const props = withDefaults(
    defineProps<{
      show: boolean;
      on_close: Function;
      on_save: Function;
      on_ok: Function;
      class?: string;
    }>(),
    {
      class: ''
    }
  );
</script>

<style lang="scss" scoped>
  :deep(.refonte-modal) {
    .modal-footer {
      gap: 8px;
    }
  }
</style>
