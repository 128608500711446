<template>
  <div class="w-full lg:h-full">
    <spinner
      v-if="loading"
      sizes="w-20 h-20"
      thickness="border-8" />
    <div v-if="!loading">
      <ag-grid-wrapper
        v-bind:column_defs="[
          { headerName: 'Période', field: 'periode', ...defaultColumnSettings },
          {
            headerName: 'Nature',
            field: 'typeContrat',
            ...defaultColumnSettings
          },
          { headerName: 'Type', field: 'perimetre', ...defaultColumnSettings },
          { headerName: 'Titre', field: 'titre', ...defaultColumnSettings },
          {
            headerName: 'Organisme',
            field: 'organisme',
            ...defaultColumnSettings
          },
          { headerName: 'Statut', field: 'statut', ...defaultColumnSettings },
          {
            headerName: 'Formateur',
            field: 'formateur',
            ...defaultColumnSettings
          },
          {
            headerName: 'Action',
            field: 'documents',
            cellRenderer: 'buttonCellRenderer',
            cellRendererParams: {
              label: 'Documents',
              labelDisabled: () => 'Non disponible',
              onclick: (e, params) => onOpenContratModal(e, params.data)
            }
          },
          {
            headerName: 'Formation',
            field: 'formation',
            ...defaultColumnSettings
          },
          {
            headerName: 'Date',
            field: 'dateDebutString',
            ...defaultColumnSettings
          },
          { headerName: 'Lieu', field: 'lieu', ...defaultColumnSettings }
        ]"
        v-bind:initial_data="contrats" />
    </div>
    <contrat-modal
      v-bind:show_modal="showContratModal"
      v-bind:contrat="selectedContrat"
      v-bind:onclose="closeContratModal"
      v-bind:download_contrat="downloadContrat"
      v-bind:contrat_base_url="`client/${profile.activeEntrepriseId}/contrats`"
      v-bind:refresh_session="fetchContrats" />
  </div>
</template>
<script>
  import AgGridWrapper from '@/components/utils/ag-grid/ag-grid-wrapper';
  import Spinner from '@/components/utils/spinner.vue';

  import { API } from '@/http-common';
  import ContratModal from '@/views/_espace/_entreprise/_contrats/_modal/contrat-modal.vue';
  import { createNamespacedHelpers } from 'vuex';
  import http_functions from '@/mixin/http_functions';

  const { mapState: mapStateClient } = createNamespacedHelpers('client');

  export default {
    components: {
      ContratModal,
      AgGridWrapper,
      Spinner
    },
    mixins: [http_functions],
    data: () => ({
      loading: false,
      contrats: [],
      showContratModal: false,
      selectedContrat: null
    }),
    computed: {
      ...mapStateClient(['profile']),
      defaultColumnSettings() {
        return {
          filter: 'agTextColumnFilter',
          cellRenderer: 'textCenterCellRenderer',
          floatingFilter: true
        };
      }
    },
    created() {
      this.fetchContrats();
    },
    methods: {
      async fetchContrats() {
        this.loading = true;
        const clientId = this.profile.activeEntrepriseId;

        if (clientId) {
          const response = await API.get(
            `client/${clientId}/contrats`,
            this.$header_skip_redirection
          );

          this.contrats = response.data;
        }

        this.loading = false;
      },
      onOpenContratModal(e, selectedContrat) {
        this.selectedContrat = selectedContrat;
        this.showContratModal = true;
      },

      closeContratModal() {
        this.selectedContrat = null;
        this.showContratModal = false;
      },
      async downloadContrat(id, type, nom) {
        const clientId = this.profile.activeEntrepriseId;

        if (clientId) {
          await this.downloadFile(
            `client/${clientId}/contrats/${id}/file/${type}`,
            nom
          );
        }
      }
    }
  };
</script>
<style lang="scss" scoped></style>
