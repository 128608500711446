import { API } from '@/http-common.js';
import { Filters } from '@/types/gestion/interlocuteurs-filters-types';
import {
    Interlocuteur,
    InterlocuteurResponse
} from '@/types/gestion/interlocuteurs-types';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { LocationQuery } from 'vue-router';

const noFilterState: Filters = {
    clientIds: [],
    fonctionIds: undefined,
    administrateur: undefined,
    droitInscription: undefined,
    accesDocuments: undefined,
    contactId: undefined
};

const filterInitialState: Filters = {
    clientIds: [],
    fonctionIds: undefined,
    administrateur: undefined,
    droitInscription: undefined,
    accesDocuments: undefined,
    contactId: undefined
};

export const INTERLOCUTEURS_FILTERS_CONFIGURATION = {
    clientIds: {
        name: 'clientIds',
        isArray: true
    },
    fonctionIds: {
        name: 'fonctionIds',
        isArray: true
    },
    administrateur: {
        name: 'administrateur',
        isArray: false
    },
    droitInscription: {
        name: 'droitInscription',
        isArray: false
    },
    accesDocuments: {
        name: 'accesDocuments',
        isArray: false
    },
    contactId: {
        name: 'contactId',
        isArray: false
    }
};

export const useInterlocuteurFilterStore = defineStore(
    'interlocuteurs-filter',
    () => {
        const filters = ref<Filters>(filterInitialState);

        const setFilter = (name: string, value: any) => {
            filters.value = { ...filters.value, [name]: value };
        };
        const resetFilters = () => {
            filters.value = noFilterState;
        };

        const parsePayload = (payload: LocationQuery): Filters => {
            const newPayload = { ...payload };

            Object.keys(newPayload).forEach((key) => {
                const filter = INTERLOCUTEURS_FILTERS_CONFIGURATION[key] as any;

                if (filter && filter.isArray) {
                    newPayload[key] = newPayload[key].split(',');
                }
                if (
                    [
                        INTERLOCUTEURS_FILTERS_CONFIGURATION.administrateur
                            .name,
                        INTERLOCUTEURS_FILTERS_CONFIGURATION.droitInscription
                            .name,
                        INTERLOCUTEURS_FILTERS_CONFIGURATION.accesDocuments.name
                    ].includes(key)
                ) {
                    newPayload[key] = newPayload[key] === 'true';
                }
            });

            return newPayload;
        };

        const initFiltersFromQuery = (payload: LocationQuery) => {
            const parsedPayload = parsePayload(payload) as Filters;
            if (payload && Object.keys(payload).length > 0) {
                filters.value = {
                    ...noFilterState,
                    ...parsedPayload
                };
            } else {
                filters.value = {
                    ...filterInitialState,
                    ...parsedPayload
                };
            }
        };

        return {
            resetFilters,
            setFilter,
            filters,
            initFiltersFromQuery
        };
    }
);

export const useInterlocuteurStore = defineStore('interlocuteurs', () => {
    const interlocuteurs = ref<Interlocuteur[]>([]);

    const fetchInterlocuteurs = async (query: Filters) => {
        const response = await API.get(`client/v2/interlocuteurs`, {
            params: query
        });
        if (response?.data) {
            interlocuteurs.value = response?.data.map(
                (i: InterlocuteurResponse) => mapInterlocuteur(i)
            );
        }
    };

    const mapInterlocuteur = (i: InterlocuteurResponse): Interlocuteur => {
        return {
            ...i,
            clientDenomination: i.client.denomination,
            clientId: i.client.id,
            clientSiret: i.client.siret ?? undefined,
            interlocuteurNom: i.nom,
            interlocuteurPrenom: i.prenom,
            interlocuteurNomPrenom: `${i.nom} ${i.prenom}`,
            fonctionId: i.fonction.id,
            fonctionLabel: i.fonction.label,
            administrateurLabel: i.administrateur ? 'Oui' : 'Non',
            droitInscriptionLabel: i.droitInscription ? 'Oui' : 'Non',
            accesDocumentsLabel: i.accesDocuments ? 'Oui' : 'Non'
        };
    };

    return {
        interlocuteurs,
        fetchInterlocuteurs
    };
});
