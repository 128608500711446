<template>
  <modal-box
    v-if="modal_show"
    v-bind:footer="false"
    v-on:close="close"
    overflow_body
    width_class="w-full max-w-screen-lg">
    <template v-slot:modal-header>
      <h1 class="text-xl">Contact</h1>
    </template>
    <template v-slot:modal-body>
      <contact-card v-bind:current_session="current_contact" />
    </template>
  </modal-box>
</template>
<script>
  import contactCard from '@/components/utils/contact-card.vue';
  import modalBox from '@/components/utils/modal-box.vue';

  export default {
    components: {
      modalBox,
      contactCard
    },
    props: ['modal_show', 'current_contact'],
    emits: ['onclose'],
    methods: {
      close() {
        this.$emit('onclose');
      }
    }
  };
</script>
<style lang="scss" scoped>
  :deep(.modal-container) {
    .modal-body {
      @apply p-8;
    }
    .modal-footer {
      @apply p-0;
    }
  }
</style>
