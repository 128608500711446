<template>
  <div
    class="message-box"
    v-bind:class="`${border_color} ${padding} ${font_style}`">
    <font-awesome-icon
      v-if="icon"
      v-bind:icon="icon"
      class="message-box__icon"
      size="lg" />
    <span
      class="message-box__text"
      v-html="source"></span>
  </div>
</template>
<script>
  export default {
    props: {
      icon: { type: [String, Array] },
      source: { type: String },
      padding: {
        type: String,
        default: 'p-4'
      },
      border_color: {
        type: String,
        default: 'border-primary-800'
      },
      font_style: {
        type: String,
        default: 'text-base text-accent-800'
      }
    },
    mounted() {
      const links = this.$el.querySelectorAll('a[href]');

      links.forEach((link) => {
        link.addEventListener('click', (e) => e.stopImmediatePropagation());
      });
    }
  };
</script>
<style lang="scss" scoped>
  .message-box {
    @apply my-4 flex items-center rounded border-l-4 bg-accent-100 shadow;
    word-break: break-word;

    .message-box__icon {
      @apply mr-2;
    }
    :deep(.message-box__text) {
      @apply pl-2 leading-tight;

      a[href] {
        @apply text-jinius-dark-green hover:underline;
      }
    }
  }
</style>
