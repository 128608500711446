<template>
  <modal-box
    v-if="modal_is_search"
    ref="modal_is_search"
    width_class="w-full max-w-6xl"
    overflow_body
    fill_height
    v-bind:footer="true"
    v-bind:copyright="false"
    v-on:close="close">
    <template v-slot:modal-header>
      <h1 class="text-xl text-jinius-blue">
        Résultat de la recherche
        <span v-if="clientType === 'siret' && isSiretFormat(clientForm.search)">
          par SIRET</span
        >
        <span v-if="clientType === 'siret' && isSirenFormat(clientForm.search)">
          par SIREN</span
        >
      </h1>
    </template>

    <template v-slot:modal-body>
      <div class="h-full w-full">
        <template v-if="clientType === 'siret'">
          <div
            v-if="!showAlternativeSearch"
            class="mb-4 flex w-full justify-end">
            <button
              class="search_button"
              type="button"
              v-on:click="enableAlternativeSearch">
              Je ne trouve pas mon entreprise
            </button>
          </div>
          <div
            v-if="showAlternativeSearch"
            class="mb-4 flex w-full justify-end">
            <button
              class="search_button"
              type="button"
              v-on:click="createEntreprise">
              Saisir manuellement mon entreprise
            </button>
          </div>
        </template>

        <template v-if="!loading">
          <template
            v-if="
              showAlternativeSearch &&
              clientType === 'siret' &&
              list_fulltext &&
              list_fulltext.clients
            ">
            <template
              v-for="elem in list_fulltext.clients"
              v-bind:key="elem.id">
              <div
                v-on:click="() => setClient(elem)"
                class="sm:flex-no-wrap my-2 flex cursor-pointer flex-wrap rounded bg-core-100 px-5 py-4 text-xs shadow hover:bg-core-200 sm:text-base">
                <div class="w-full sm:w-2/4">
                  <h5
                    v-if="elem.denomination"
                    class="font-bold text-jinius-dark-green">
                    {{ elem.denomination }}
                  </h5>
                  <p
                    v-if="elem.numeroVoie || elem.codePostal || elem.ville"
                    class="flex items-center pr-4">
                    <font-awesome-icon
                      icon="map-marker-alt"
                      size="sm"
                      class="mx-2 text-core-500" />
                    <span>{{
                      `${elem.numeroVoie ? elem.numeroVoie : ''} ${elem.codePostal ? elem.codePostal : elem.codePostal} ${elem.ville ? elem.ville : elem.ville}`
                    }}</span>
                  </p>
                </div>
                <div class="w-full sm:w-1/4">
                  <p>
                    <span class="mr-2 font-bold"> SIRET </span>
                    <span v-if="elem.siret">
                      {{ elem.siret }}
                    </span>
                    <span v-else> N/A </span>
                  </p>
                  <p>
                    <span class="mr-2 font-bold"> Activité </span>
                    {{ elem.activite }} ({{ getActiviteLabel(elem.activite) }})
                  </p>
                </div>
                <div class="w-full sm:w-1/4">
                  <p>
                    <span class="mr-2 font-bold"> </span>
                  </p>
                  <p>
                    <span class="mr-2 font-bold"> Établissement siège </span>
                    {{ elem.etablissementSiege ? 'oui' : 'non' }}
                  </p>
                </div>
              </div>
            </template>
          </template>
          <template
            v-else-if="
              clientType === 'siret' &&
              list_fulltext &&
              list_fulltext.etablissement
            ">
            <template
              v-for="elem in list_fulltext.etablissement"
              v-bind:key="elem.id">
              <div
                v-on:click="() => validateClient(elem)"
                class="sm:flex-no-wrap my-2 flex cursor-pointer flex-wrap rounded bg-core-100 px-5 py-4 shadow hover:bg-core-200">
                <div class="w-full sm:w-2/4">
                  <h5
                    v-if="
                      (elem.sexe === null && elem.enseigne) ||
                      elem.nom_raison_sociale
                    "
                    class="mb-2 font-bold text-jinius-dark-green">
                    {{ elem.enseigne || elem.nom_raison_sociale }}
                  </h5>
                  <h5
                    v-else-if="elem.sexe"
                    class="mb-2 font-bold text-jinius-dark-green">
                    {{ elem.sexe === 'F' ? 'MADAME' : 'MONSIEUR' }}
                    {{ elem.prenom }} {{ elem.nom }}
                  </h5>
                  <!-- <h5 v-else
                      class="text-jinius-dark-green font-bold leading-tight space-y-2">
                    <p class="uppercase">Attention :</p>
                    <p>Cet établissement a choisi de rendre ses informations "non-diffusibles" dans la base nationale de l'INSEE.
                      <span>
                        <font-awesome-icon class="text-core-900"
                                           v-tooltip="{
                                             content:`
                                             <div class='max-w-sm p-2'>
                                              <p class='mb-2'>
                                                La loi permet aux <strong>personnes physiques</strong> de s'opposer à la diffusion de leurs données: les SIREN et SIRET correspondants sont alors qualifiés de «non-diffusibles».
                                              </p>
                                              <p>
                                                Cette classification est réversible.
                                                </p>
                                            </div>
                                          `}"
                                           icon="question-circle"/>
                      </span>
                    </p>
                    <p>Par conséquent, nous ne pouvons pas récupérer ses coordonnées de manière automatique.</p>
                    <p>Vous pourrez néanmoins les saisir manuellement en cliquant.</p>
                  </h5> -->
                  <p
                    v-if="elem.geo_adresse"
                    class="flex items-center space-x-2">
                    <font-awesome-icon
                      icon="map-marker-alt"
                      size="sm"
                      class="text-core-500" />
                    <span>{{ elem.geo_adresse }}</span>
                  </p>
                </div>
                <div class="w-full sm:w-1/4">
                  <p>
                    <span class="mr-2 font-bold"> SIRET </span>
                    {{ elem.siret }}
                  </p>
                  <p>
                    <span class="mr-2 font-bold"> Activité </span>
                    {{ elem.activite_principale }} ({{
                      getActiviteLabel(elem.activite_principale)
                    }})
                  </p>
                </div>
                <div class="w-full sm:w-1/4">
                  <p>
                    <span class="mr-2 font-bold"> SIREN </span>
                    {{ elem.siren }}
                  </p>
                  <p>
                    <span class="mr-2 font-bold"> Établissement siège </span>
                    {{ elem.is_siege ? 'oui' : 'non' }}
                  </p>
                </div>
              </div>
            </template>
          </template>

          <template
            v-else-if="
              clientType === 'rna' && list_fulltext && list_fulltext.association
            ">
            <template
              v-for="elem in list_fulltext.association"
              v-bind:key="elem.id">
              <div
                v-on:click="() => validateClient(elem)"
                class="my-2 flex cursor-pointer flex-wrap rounded bg-core-100 px-5 py-4 shadow hover:bg-core-200 sm:flex-nowrap">
                <div class="w-full pr-4 sm:w-4/6">
                  <h5 class="font-bold text-jinius-blue">
                    {{ elem.titre_court || elem.titre }}
                  </h5>
                  <p v-if="elem.adresse_gestion_libelle_voie">
                    <font-awesome-icon
                      icon="map-marker-alt"
                      size="sm"
                      class="mx-2 text-core-500" />
                    <span>{{ elem.adresse_gestion_libelle_voie }}</span>
                    <span>{{ elem.adresse_code_postal }}</span>
                    <span>{{ elem.adresse_libelle_commune }}</span>
                  </p>
                </div>
                <div class="flex w-full items-center sm:w-2/6">
                  <p>
                    <span class="mr-2 font-bold"> Numéro RNA </span>
                    {{ elem.id_association }}
                  </p>
                </div>
              </div></template
            >
          </template>
        </template>

        <template v-if="isResultEmpty">
          <div class="my-2 flex rounded bg-core-100 px-5 py-4 shadow">
            Aucun résultat...
          </div>
        </template>
        <template v-else-if="loading">
          <div class="flex h-full items-center justify-center self-stretch">
            <spinner />
          </div>
        </template>
      </div>
    </template>

    <template v-slot:modal-footer>
      <div
        v-if="
          list_fulltext &&
          (list_fulltext.totalResults || list_fulltext.totalPages)
        "
        class="flex w-full flex-wrap items-center justify-between text-sm text-jinius-blue sm:flex-nowrap">
        <div class="flex flex-shrink">
          <template v-if="list_fulltext && list_fulltext.totalResults">
            <span class="mx-1 font-bold">{{ list_fulltext.totalResults }}</span>
            <span>résultats</span>
          </template>
        </div>

        <div class="flex flex-grow justify-end sm:justify-center">
          <template v-if="list_fulltext && list_fulltext.totalPages">
            Page <span class="mx-1 font-bold">{{ list_fulltext.page }}</span> /
            {{ list_fulltext.totalPages }}
          </template>
        </div>

        <div
          v-if="list_fulltext && list_fulltext.totalPages"
          class="flex w-full flex-shrink items-center justify-center sm:w-auto">
          <template v-if="list_fulltext.page > 1">
            <button
              v-on:click.prevent="reachPreviousPage(list_fulltext.page)"
              class="focus:outline-none">
              <font-awesome-icon
                icon="chevron-left"
                size="xs" />
              <span class="ml-1 align-middle hover:underline"
                >page précédente</span
              >
            </button>
          </template>
          <template v-else>
            <button
              disabled="disabled"
              class="disabled:cursor-not-allowed disabled:text-core-500">
              <font-awesome-icon
                icon="chevron-left"
                size="xs" />
              <span class="ml-1 align-middle">page précédente</span>
            </button>
          </template>

          <span class="px-1">/</span>

          <template v-if="list_fulltext.page < list_fulltext.totalPages">
            <button
              v-on:click.prevent="reachNextPage(list_fulltext.page)"
              class="focus:outline-none">
              <span class="mr-1 align-middle hover:underline"
                >page suivante</span
              >
              <font-awesome-icon
                icon="chevron-right"
                size="xs" />
            </button>
          </template>
          <template v-else>
            <button
              disabled="disabled"
              class="disabled:cursor-not-allowed disabled:text-core-500">
              <span class="ml-1 align-middle">page suivante</span>
              <font-awesome-icon
                icon="chevron-right"
                size="xs" />
            </button>
          </template>
        </div>
      </div>
    </template>
  </modal-box>
</template>

<script>
  import modalBox from '@/components/utils/modal-box.vue';
  import spinner from '@/components/utils/spinner.vue';
  import { API } from '@/http-common';
  import api_insee_helpers from '@/mixin/api_insee_helpers';
  import { ACTION_TYPES } from '@/store/constants/ActionTypes';
  import { createNamespacedHelpers } from 'vuex';

  const { mapState: mapStateClient, mapActions: mapActionsClient } =
    createNamespacedHelpers('client');

  export default {
    components: {
      modalBox,
      spinner
    },
    mixins: [api_insee_helpers],
    props: ['modal_is_search', 'onclose'],
    data: () => ({
      loading: true,
      list_fulltext: null,
      showAlternativeSearch: false //Recherche base SAFIR
    }),
    computed: {
      ...mapStateClient(['clientForm', 'clientType', 'activites']),
      isResultEmpty() {
        const noResult = !this.list_fulltext;
        const emptyResult =
          this.list_fulltext &&
          !this.list_fulltext.etablissement &&
          !this.list_fulltext.association &&
          !this.list_fulltext.clients;

        return !this.loading && (noResult || emptyResult);
      }
    },
    watch: {
      async modal_is_search(value) {
        if (value) {
          await this.fetchData(1);
        }
      }
    },
    created() {
      this[ACTION_TYPES.FETCH_INSEE_INFOS]();
    },
    methods: {
      ...mapActionsClient([
        ACTION_TYPES.SET_CLIENT_FORM_ADDRESS_SIRET,
        ACTION_TYPES.SET_CLIENT_FORM_ADDRESS_RNA,
        ACTION_TYPES.TEST_CLIENT,
        ACTION_TYPES.SET_CLIENT_FORM_ATTRIBUTE,
        ACTION_TYPES.SET_CLIENT_TYPE,
        ACTION_TYPES.SET_CLIENT_FORM,
        ACTION_TYPES.FETCH_INSEE_INFOS,
        ACTION_TYPES.SEND_CLIENT_FORM,
        ACTION_TYPES.VALIDATE_CLIENT,
        ACTION_TYPES.FORCE_ENABLE_CLIENT_FORM
      ]),
      close() {
        this.showAlternativeSearch = false;
        this.onclose();
      },
      async fetchData(page) {
        this.loading = true;

        const perPage = 20;
        const value = this.clientForm.search;

        if (value && value !== '') {
          if (this.clientType === 'siret') {
            if (this.showAlternativeSearch) {
              this.list_fulltext = null;
              const response = await API.get(
                `client/entreprise/recherche?query=${value}&perPage=${perPage}&page=${page}`,
                this.$header_skip_redirection
              );
              const data = response.data;

              if (data.clients && data.clients.length) {
                this.list_fulltext = data;
              } else {
                this.list_fulltext = { empty: true };
              }
            } else {
              try {
                if (this.isSirenFormat(value)) {
                  const response = await this.getBySiren(
                    value.replace(/\s/g, '')
                  );

                  const etablissements =
                    response &&
                    response.unite_legale[0] &&
                    response.unite_legale[0].etablissements;

                  if (etablissements) {
                    this.list_fulltext = {
                      etablissement: etablissements,
                      totalResults: etablissements.length
                    };
                  }
                } else if (this.isSiretFormat(value)) {
                  const response = await this.getBySiret(
                    value.replace(/\s/g, '')
                  );

                  const etablissement = response && response.etablissement;

                  if (etablissement) {
                    this.list_fulltext = {
                      etablissement,
                      totalResults: etablissement.length
                    };
                  }
                } else {
                  const response = await this.searchInsee(value, perPage, page);

                  if (response) {
                    this.list_fulltext = this.mapResult(response);
                  }
                }
              } catch (e) {
                if (e.response.status === 404) {
                  this.list_fulltext = false;
                }
              }
            }
          }
          if (this.clientType === 'rna') {
            try {
              const response = await this.searchAssociations(
                value,
                perPage,
                page
              );

              this.list_fulltext = this.mapResult(response);
            } catch (e) {
              if (e.response.status === 404) {
                this.list_fulltext = { empty: true };
              }
            }
          }
        }

        this.loading = false;
      },
      reachPreviousPage(page) {
        const previous_page = parseInt(page) - parseInt(1);

        this.fetchData(previous_page);
      },
      reachNextPage(page) {
        const next_page = parseInt(page) + parseInt(1);

        this.fetchData(next_page);
      },
      setClientForm(value, name) {
        this[ACTION_TYPES.SET_CLIENT_FORM_ATTRIBUTE]({
          name,
          value
        });
      },
      mapResult(result) {
        return {
          ...result,
          totalPages: result.total_pages,
          totalResults: result.total_results
        };
      },
      createEntreprise() {
        this[ACTION_TYPES.FORCE_ENABLE_CLIENT_FORM](true);
        this.close();
      },
      async setClient(elem) {
        this[ACTION_TYPES.SET_CLIENT_FORM](elem);
        this[ACTION_TYPES.VALIDATE_CLIENT]();
        this.close();
      },
      async validateClient(elem) {
        if (['siret', 'rna'].includes(this.clientType)) {
          const key = this.clientType === 'rna' ? 'id_association' : 'siret';

          this.setClientForm(elem[key], this.clientType);
          let response = null;

          try {
            response = await this[ACTION_TYPES.TEST_CLIENT]({
              [this.clientType]: this.clientForm[this.clientType],
              clientType: this.clientType
            });
          } catch (e) {
            //TODO handle
          }

          if (response && response.data) {
            const payload = response.data;

            this[ACTION_TYPES.SET_CLIENT_FORM](payload);
          } else {
            if (this.clientType === 'siret') {
              let payload;
              const is_search_siret = this.isSiretFormat(
                this.clientForm.search
              );

              if (is_search_siret) {
                payload = elem;
              } else {
                const fetch_payload = await this.getBySiret(elem.siret);

                payload = fetch_payload.etablissement[0];
              }
              this[ACTION_TYPES.SET_CLIENT_FORM_ADDRESS_SIRET](payload);
            } else if (this.clientType === 'rna') {
              this[ACTION_TYPES.SET_CLIENT_FORM_ADDRESS_RNA](elem);
            }
          }
        }
        this.close();
      },
      getActiviteLabel(activitePrincipale) {
        let activite = activitePrincipale;
        // Activite format XX.XXZ

        if (activitePrincipale && activitePrincipale.charAt(2) !== '.') {
          activite = `${activitePrincipale.slice(0, 2)}.${activitePrincipale.slice(2, activitePrincipale.length)}`;
        }

        return this.activites[activite]
          ? this.activites[activite]
          : 'Code inexistant dans la nomenclature';
      },
      enableAlternativeSearch() {
        this.showAlternativeSearch = true;
        this.fetchData(1);
      }
    }
  };
</script>
<style lang="scss" scoped>
  .search_button {
    @apply flex w-full items-center justify-center self-end rounded bg-jinius-dark-green p-2 text-sm font-bold text-white shadow transition duration-150 hover:bg-jinius-green disabled:cursor-not-allowed disabled:bg-core-400 disabled:opacity-50 sm:w-1/3;
  }
</style>
