<template>
  <div v-bind:class="`flex w-full flex-wrap ${input_class}`">
    <div class="question">
      <form-label
        v-if="title"
        v-bind:required="required"
        class="font-bold leading-none"
        v-bind:label="title" />
    </div>
    <div class="mx-6 mb-0 mt-6 flex w-144 flex-wrap items-baseline">
      <yes-no-radio-buttons
        v-bind:input_name="input_name"
        class="radio__buttons w-1/3"
        v-bind:disabled="disabled"
        v-bind:input_value="inputValue"
        v-bind:update_value="updateValue" />
      <transition name="fade">
        <div
          v-if="inputValue && list"
          class="w-2/3">
          <select-box
            v-bind:value="list.find((item) => listValue === item.id)"
            v-bind:disabled="disabled"
            v-on:input="updateListValue"
            v-bind:categories="list"
            v-bind:required="required"
            v-bind:label="yes_title"
            no_capitalize_label
            v-bind:class_name="`no-box ${select_class}`"
            tracking="id" />
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
  import formLabel from '@/components/utils/form-label.vue';
  import selectBox from '@/components/utils/select-box';
  import yesNoRadioButtons from '@/components/utils/yes-no-radio-buttons';

  export default {
    components: {
      yesNoRadioButtons,
      selectBox,
      formLabel
    },
    props: [
      'title',
      'input_name',
      'yes_title',
      'list',
      'value',
      'required',
      'input_class',
      'id',
      'select_class',
      'select_value',
      'disabled'
    ],
    emits: ['select', 'input'],
    data() {
      return {
        inputValue: this.value,
        listValue: this.select_value
      };
    },
    watch: {
      select_value(val) {
        this.listValue = val;
      }
    },
    methods: {
      updateValue(value) {
        this.inputValue = value;
        this.$emit('input', value);
      },
      updateListValue(value) {
        this.$emit('select', value);
      }
    }
  };
</script>
<style lang="scss" scoped>
  .question {
    :deep(.form-label) {
      @apply text-base text-core-900;
    }
  }
</style>
