// @ts-nocheck
// Init Vue 3 app instance
import { createApp } from 'vue';

import App from './App.vue';
const app = createApp(App);

// Init Vue router
import { router } from '@/router';
app.use(router);

// Init Vuex store
import { store } from '@/store';
app.use(store);

// Import Tailwind styles
import './assets/css/tailwind.scss';

// Add SVG Logo Jinius as a global component
import logoJinius from '@/components/utils/logo-jinius.vue';
app.component('logo-jinius', logoJinius);

// Init FontAwesome for Vue
import VueFontAwesome from './vue-fontawesome.js';
app.component(...VueFontAwesome);

import { createPinia } from 'pinia';
const pinia = createPinia();
app.use(pinia);
// Init FloatingVue, v-tooltip components
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
const VTooltipOptions = {
    themes: {
        tooltip: { html: true },
        triggers:
            breakpoints.w >= breakpoints.screens.lg
                ? ['hover', 'focus']
                : ['click']
    }
};

app.use(FloatingVue, VTooltipOptions);

// Init Vue HMTL to Paper (Print plugin)
import VueHtmlToPaper from 'vue-html-to-paper';
const VueHtmlToPaperOptions = {
    name: '_blank',
    specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
    styles: []
};

app.use(VueHtmlToPaper, VueHtmlToPaperOptions);

// Init Toast Notification plugin
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
app.use(ToastPlugin);

// Sync Auth status with through "auth_status" value
import Cookies from 'js-cookie';
function listenCookieChange(callback, interval = 500) {
    let lastCookie = Cookies.get('auth_status');

    setInterval(() => {
        const cookie = Cookies.get('auth_status');

        if (cookie !== lastCookie) {
            try {
                callback({
                    oldValue: lastCookie,
                    newValue: cookie
                });
            } finally {
                lastCookie = cookie;
            }
        }
    }, interval);
}
listenCookieChange(() => {
    // eslint-disable-next-line no-console
    console.log('Synchronizing Auth');
    store.dispatch('auth/applyAuthenticationStatus');
});

// Initialize a global event emitter (manual backport from Vue2 behaviour)
// cf. https://v3-migration.vuejs.org/breaking-changes/events-api.html#event-bus
// can be acessed globally using => this.$options[emmiter_method]
// (ex: this.$options.$on('event_string_name', prop_value))
import emitter from './eventBus.js';
app.mixin(emitter);

// Polyfill pour la gestion moderne en JS de scroll() dans Edge/IE/Safari/Opera
// https://developer.mozilla.org/en-US/docs/Web/CSS/scroll-behavior
// https://github.com/iamdustan/smoothscroll
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();
const { globalProperties } = app.config;

globalProperties.productionTip = false;

globalProperties.$current_app_type = 'inscription';
globalProperties.$current_app_context = import.meta.env.VITE_CONTEXT;

import breakpoints from '@/vue-tailwind-breakpoints';
globalProperties.$breakpoints = breakpoints;

globalProperties.$header_skip_redirection = {
    headers: { 'SKIP-REDIRECTION': true }
};
store.$header_skip_redirection = globalProperties.$header_skip_redirection;

// ROUTER BEFORE EACH -> BEFORE VM INSTANCE
router.beforeEach(async (to, from, next) => {
    // Check the authentication status (OIDC Client)
    if (!store.state.auth.authenticated) {
        await store.dispatch('auth/applyAuthenticationStatus');
    }

    const isAuthenticated = store.state.auth.authenticated;

    const isLoginPage = from.matched.some(
        (record) => record.name === 'connexion'
    );
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    // console.log(requiresAuth, isLoginPage, isAuthenticated);

    // console.log('REDIRECT STATE', store.state.auth.redirect_route_path, '\n FULLPATH', to.fullPath);
    if (requiresAuth && !isAuthenticated) {
        if (!isLoginPage) {
            store.commit('auth/setRedirectUrlPath', to.fullPath);
            next({ name: 'connexion' });
        } else {
            next();
        }
    } else {
        next();
    }
});

import { surveyPlugin } from 'survey-vue3-ui';
app.use(surveyPlugin);

app.mount('#app');
