<template>
  <modal-box
    v-if="modal_show"
    v-bind:width_class="'w-full max-w-screen-lg'"
    v-on:close="onclose"
    v-bind:footer="false">
    <template v-slot:modal-header>
      <h1 class="text-xl font-bold text-core-900">Information</h1>
    </template>

    <template v-slot:modal-body>
      <div
        class="py-4 text-lg"
        v-if="!elearning">
        Veuillez saisir le questionnaire de satisfaction pour accéder au
        support.
      </div>
      <div
        class="py-4 text-lg"
        v-else>
        Veuillez finir votre module e-learning et saisir le questionnaire de
        satisfaction pour accéder au support.
      </div>
    </template>
  </modal-box>
</template>
<script>
  import modalBox from '@/components/utils/modal-box.vue';
  export default {
    components: { modalBox },
    props: ['modal_show', 'onclose', 'elearning'],
    data: () => ({}),
    methods: {
      close() {
        this.onclose();
      }
    }
  };
</script>
<style lang="scss" scoped></style>
