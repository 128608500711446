<template>
  <modal-box
    v-if="show_modal"
    v-bind:footer="true"
    width_class="max-w-screen-2xl"
    overflow_body
    fill_height
    v-on:close="onclose">
    <template v-slot:modal-header>
      <h1 class="text-2xl font-bold leading-none text-core-900 lg:text-3xl">
        Appréciations des participants pour la session
      </h1>
    </template>

    <template v-slot:modal-body>
      <div
        v-if="loading"
        class="h-full w-full">
        <spinner
          sizes="w-20 h-20"
          thickness="border-8" />
      </div>

      <div
        v-else-if="!error"
        class="flex w-full flex-wrap lg:px-8 lg:py-4">
        <div class="flex w-full flex-wrap">
          <div class="mb-4 flex w-full flex-wrap space-y-4 lg:w-2/3">
            <!-- <div class="flex w-full items-center">
              <div v-if="detail.statutId"
                   class="text-sm uppercase text-center font-bold text-white py-0.5 px-3 shadow-sm justify-end"
                   v-bind:class="statut_bg_color(detail.statutId)">
                <span class="align-middle">
                  {{statut_label(detail.statutId)}}
                </span>
              </div>
            </div> -->

            <div class="flex w-full flex-wrap items-center space-y-4">
              <div class="flex flex-wrap space-y-2">
                <div
                  class="flex w-full items-center text-lg font-bold text-core-900">
                  <div>Référence&nbsp;:&nbsp;</div>
                  <div class="font-normal">{{ session.reference }}</div>
                </div>
                <div class="flex w-full text-2xl font-bold text-jinius-blue">
                  {{ session.titre }}
                </div>
              </div>

              <div class="flex flex-wrap space-y-2">
                <div
                  class="flex w-full space-x-2 text-lg font-bold leading-none text-core-900">
                  <font-awesome-icon
                    v-bind:icon="['far', 'calendar-alt']"
                    size="1x"
                    fixed-width />

                  <div>
                    Date(s)&nbsp;:&nbsp;<span class="font-normal">{{
                      displayDates(detail.sessionDates).join(', ')
                    }}</span>
                  </div>
                </div>
                <div
                  class="flex w-full space-x-2 text-lg font-bold leading-none text-core-900">
                  <font-awesome-icon
                    icon="map-marker-alt"
                    size="1x"
                    fixed-width />

                  <div>
                    Lieu&nbsp;:&nbsp;<span class="font-normal">{{
                      detail.lieuNom
                    }}</span>
                  </div>
                </div>
                <div
                  class="flex w-full space-x-2 text-lg font-bold leading-none text-core-900">
                  <font-awesome-icon
                    icon="tag"
                    size="1x"
                    fixed-width />

                  <div>
                    Organisateur&nbsp;:&nbsp;<span class="font-normal">{{
                      detail.organisateur
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex w-full lg:w-1/3">
            <stat-card
              class="p-0"
              size="w-full"
              label="Nombre d'appréciations fournies par les participants"
              v-bind:value="detail.nbAppreciations" />
          </div>
        </div>
        <template v-if="detail && detail.reponses && detail.reponses.length">
          <h1 class="section__title mb-8">
            Graphiques des appréciations des participants
          </h1>
          <div class="flex w-full">
            <surveyjs-analytics-wrapper
              v-if="detail && detail.delay && detail.surveyText"
              v-bind:json="detail.surveyText"
              v-bind:data="detail.reponses"
              ref="surveyjs-analytics-wrapper" />
            <spinner
              v-else
              class="mt-16" />
          </div>
        </template>
      </div>
      <div
        v-else
        class="flex h-full w-full flex-wrap items-center justify-center">
        <div class="mb-6 flex w-full justify-center text-xl">
          {{ statut_map[error] ? statut_map[error] : error }}
        </div>
      </div>
    </template>
  </modal-box>
</template>
<script>
  import { convertStringToDate } from '@/assets/data/ag_grid_fields_map';
  import {
    map_statut_session_colors,
    map_statut_session_labels
  } from '@/assets/data/data_maps.js';
  import modalBox from '@/components/utils/modal-box.vue';
  import spinner from '@/components/utils/spinner';
  import statCard from '@/components/utils/stat-card.vue';
  import SurveyjsAnalyticsWrapper from '@/components/utils/surveyjs-analytics-wrapper';
  import { API } from '@/http-common';

  export default {
    components: {
      SurveyjsAnalyticsWrapper,
      modalBox,
      statCard,
      spinner
    },
    props: {
      show_modal: Boolean,
      session: Object,
      onclose: Function
    },
    data: () => ({
      loading: false,
      detail: null,
      error: null,
      map_statut_session_colors,
      map_statut_session_labels
    }),
    computed: {
      statut_map() {
        return { SATISFACTION_INDISPONIBLE: 'Pas de résultats disponibles.' };
      }
    },
    async created() {
      await this.fetchSession();
    },
    methods: {
      async fetchSession() {
        this.loading = true;
        if (this.session) {
          try {
            const response = await API.get(
              `formateur/satisfaction/session/${this.session.reference}`,
              this.$header_skip_redirection
            );

            this.detail = response.data;
          } catch (e) {
            if (e.response && e.response.data && e.response.data.message) {
              this.error = e.response.data.message;
            }
          }
        }

        // On crée un delay de qq miliseconds pour que lors du rendu de  SurveyAnalytics on n'ai pas d'éléments graphiques qui se chevauchent
        await setTimeout(() => {
          this.detail = Object.assign({ delay: true }, this.detail);
        }, 100);
        this.loading = false;
      },
      close() {
        this.detail = null;
        this.error = null;
        this.onclose();
      },
      displayDates(dates) {
        const sortedDates = dates
          .slice()
          .sort(
            (a, b) =>
              convertStringToDate(a.date).getTime() -
              convertStringToDate(b.date).getTime()
          );

        return sortedDates.map(
          (date) =>
            `${date.date}${date.matin && !date.aprem ? ' (matin)' : ''}${!date.matin && date.aprem ? ' (après-midi)' : ''}`
        );
      },
      statut_bg_color(id) {
        return map_statut_session_colors[id]
          ? `bg-${map_statut_session_colors[id]}`
          : 'bg-accent-500';
      },
      statut_label(id) {
        return map_statut_session_labels[id];
      }
    }
  };
</script>
<style lang="scss" scoped></style>
