<template>
  <modal-box
    v-if="modal_unavailability"
    v-bind:width_class="'w-full max-w-screen-lg'"
    overflow_body
    no_outside_click
    v-on:close="closeModal">
    <template v-slot:modal-header>
      <h1 class="text-xl font-bold text-core-900">
        Ajouter une indisponibilité
      </h1>
    </template>

    <template v-slot:modal-body>
      <form
        ref="form_unavailability"
        class="p-4 text-core-900"
        v-on:submit.prevent="submit">
        <div class="mx-2 my-2 flex w-full flex-wrap">
          <div class="mb-8 w-full flex-wrap">
            <div class="mb-4 flex w-full items-center">
              <font-awesome-icon
                icon="tags"
                size="lg"
                v-bind:class="{ 'input-error': v$.form.title.$error }" />
              <input-box
                class_name="no-box"
                name="title"
                placeholder="Ajouter un titre *"
                v-bind:class="{ 'input-error': v$.form.title.$error }"
                v-bind:value="form.title"
                v-on:input="setFormAttribut($event, 'title')" />
            </div>

            <div class="mb-4 flex w-full items-center">
              <font-awesome-icon
                icon="map-marker-alt"
                size="lg" />
              <input-box
                class_name="no-box"
                name="lieu"
                placeholder="Ajouter un lieu"
                v-bind:value="form.lieu"
                v-on:input="setFormAttribut($event, 'lieu')" />
            </div>
            <div class="mb-4 flex w-full items-center">
              <font-awesome-icon
                icon="building"
                size="lg" />
              <input-box
                class_name="no-box cursor-pointer"
                name="site"
                placeholder="Ajouter un site"
                v-bind:value="form.site"
                v-bind:onclick="openSiteModal"
                v-on:input="setFormAttribut($event, 'site')" />
            </div>
            <div class="mb-8 flex w-full items-center">
              <font-awesome-icon
                icon="building"
                size="lg" />
              <input-box
                class_name="no-box"
                name="site"
                placeholder="Ajouter une adresse"
                v-bind:value="form.adresse"
                v-on:input="setFormAttribut($event, 'adresse')" />
            </div>
            <div class="mb-8 flex w-full flex-wrap items-center space-x-4">
              <div class="font-bold">Journée entière ?</div>
              <div class="flex items-center">
                <label
                  v-tooltip="{
                    content: !form.allDay ? 'Activer' : 'Désactiver'
                  }"
                  class="switch">
                  <input
                    v-model="form.allDay"
                    type="checkbox" />
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div class="mb-8 flex w-full flex-wrap">
              <div class="flex w-full items-center py-2 lg:w-1/2">
                <div class="flex w-1/12">
                  <font-awesome-icon
                    icon="calendar-alt"
                    class="w-full"
                    size="lg" />
                </div>
                <div class="flex w-11/12">
                  <datepicker-box
                    class_name="no-box"
                    id="start"
                    show_clear_button
                    format="dd/MM/yyyy"
                    append_to_body
                    v-bind:compare_date="new Date(1900, 1, 1)"
                    v-bind:value="form.start"
                    v-on:input="setFormAttribut($event, 'start')" />
                </div>
              </div>
              <div class="flex w-full items-center p-2 lg:w-1/2">
                <template v-if="!form.allDay">
                  <div class="flex w-2/12">
                    <font-awesome-icon
                      icon="clock"
                      size="lg" />
                  </div>
                  <div class="flex w-10/12">
                    <dropdown-box
                      v-bind:initial_value="form.startHour"
                      v-bind:options="hours"
                      v-on:select="setFormAttribut($event, 'startHour')" />
                  </div>
                </template>
              </div>
            </div>
            <div class="mb-8 flex w-full flex-wrap">
              <div class="flex w-full items-center py-2 lg:w-1/2">
                <div class="flex w-1/12">
                  <font-awesome-icon
                    icon="calendar-alt"
                    class="w-full"
                    size="lg" />
                </div>
                <div class="flex w-11/12">
                  <datepicker-box
                    id="end"
                    show_clear_button
                    class_name="no-box"
                    append_to_body
                    format="dd/MM/yyyy"
                    v-bind:value="form.end"
                    v-bind:compare_date="form.start"
                    v-on:input="setFormAttribut($event, 'end')" />
                </div>
              </div>
              <div class="flex w-full items-center p-2 lg:w-1/2">
                <template v-if="!form.allDay">
                  <div class="flex w-2/12">
                    <font-awesome-icon
                      icon="clock"
                      size="lg" />
                  </div>
                  <div class="flex w-10/12">
                    <dropdown-box
                      v-bind:initial_value="form.endHour"
                      v-bind:options="hours"
                      v-on:select="setFormAttribut($event, 'endHour')" />
                  </div>
                </template>
              </div>
            </div>
            <div class="mb-4 flex w-full items-center">
              <font-awesome-icon
                icon="comment-alt"
                size="lg" />
              <input-box
                class_name="no-box"
                name="description"
                placeholder="Ajouter une description"
                v-bind:value="form.description"
                v-on:input="setFormAttribut($event, 'description')" />
            </div>
            <div class="mb-4 flex w-full items-center">
              <font-awesome-icon
                icon="link"
                size="lg" />
              <input-box
                class_name="no-box"
                name="url"
                placeholder="Ajouter une url"
                v-bind:value="form.url"
                v-on:input="setFormAttribut($event, 'url')" />
            </div>
          </div>
        </div>
      </form>
      <div
        class="mb-2 flex w-full justify-center font-bold text-jinius-red"
        v-if="errorMessage">
        {{ errorMessage }}
      </div>
      <site-modal
        v-bind:show_modal="showSiteModal"
        v-bind:onclose="closeSiteModal"
        v-bind:set_site="setSite" />
    </template>
    <template v-slot:modal-footer>
      <div class="inline-flex w-full justify-center space-x-4">
        <button
          class="w-64 cursor-pointer rounded bg-core-900 px-5 py-3 text-center font-bold text-white shadow hover:bg-jinius-dark-green"
          type="submit"
          v-on:click="submit">
          <font-awesome-icon icon="save" />
          <span class="ml-2 align-middle">Enregistrer</span>
        </button>
        <div
          class="w-64 cursor-pointer rounded bg-core-300 px-5 py-3 text-center font-bold text-core-700 shadow hover:bg-core-200"
          v-on:click="closeModal">
          Annuler
        </div>
      </div>
    </template>
  </modal-box>
</template>

<script>
  import datepickerBox from '@/components/utils/datepicker-box.vue';
  import dropdownBox from '@/components/utils/dropdown-box.vue';
  import inputBox from '@/components/utils/input-box.vue';
  import modalBox from '@/components/utils/modal-box.vue';
  import { API } from '@/http-common';
  import SiteModal from '@/views/_espace/_formateur/_agenda/site-modal';
  import { useVuelidate } from '@vuelidate/core';
  import { required } from '@vuelidate/validators';

  const START_DEFAULT_DATE = new Date();

  START_DEFAULT_DATE.setHours(START_DEFAULT_DATE.getHours() + 1);
  START_DEFAULT_DATE.setMinutes(0);
  const END_DEFAULT_DATE = new Date();

  END_DEFAULT_DATE.setHours(END_DEFAULT_DATE.getHours() + 2);
  END_DEFAULT_DATE.setMinutes(0);

  const initialForm = {
    title: null,
    start: START_DEFAULT_DATE,
    startHour: `${START_DEFAULT_DATE.getHours()}:00:00`,
    end: END_DEFAULT_DATE,
    endHour: `${END_DEFAULT_DATE.getHours()}:00:00`,
    lieu: '',
    description: '',
    url: '',
    allDay: false,
    site: null,
    siteId: null
  };

  export default {
    components: {
      SiteModal,
      inputBox,
      datepickerBox,
      modalBox,
      dropdownBox
    },
    props: [
      'modal_unavailability',
      'onclose',
      'current_event',
      'open_info_modal'
    ],
    emits: ['event-update', 'event-create'],
    setup() {
      return { v$: useVuelidate() };
    },
    data() {
      return {
        form: initialForm,
        errorMessage: null,
        showSiteModal: false
      };
    },
    computed: {
      hours() {
        const hoursArray = Array.from(
          { length: 24 },
          (v, k) => `${k < 10 ? '0' + k : k}:00`
        );

        return hoursArray.map((hour) => ({
          label: hour,
          value: `${hour}:00`
        }));
      }
    },
    watch: {
      modal_unavailability() {
        this.resetForm();
      },
      current_event(value) {
        if (value) {
          const start = new Date(value.start);
          const offset = start.getTimezoneOffset();
          const startLocaleDate = new Date(
            start.getTime() - offset * 60 * 1000
          );
          const startArray = startLocaleDate.toISOString().split('T');
          const dateStart = startArray[0];

          const end = new Date(value.end);
          const endLocaleDate = new Date(end.getTime() - offset * 60 * 1000);
          const endArray = endLocaleDate.toISOString().split('T');
          const dateEnd = endArray[0];

          this.form = {
            ...initialForm,
            ...this.current_event,
            start: new Date(dateStart),
            startHour: this.formatTime(start.getHours()),
            end: new Date(dateEnd),
            endHour: this.formatTime(end.getHours())
          };
        } else {
          this.form = { ...initialForm };
        }
      }
    },
    validations() {
      return {
        form: {
          title: { required },
          start: { required },
          end: { required }
        }
      };
    },
    methods: {
      setSite(site) {
        this.form.site = site.denomination;
        this.form.siteId = site.id;
      },
      openSiteModal() {
        this.showSiteModal = true;
      },
      closeSiteModal() {
        this.showSiteModal = false;
      },
      formatTime(hour) {
        return `${hour < 10 ? `0${hour}` : hour}:00:00`;
      },
      resetForm() {
        this.form = { ...initialForm };
      },
      setFormAttribut(value, name) {
        this.form[name] = value;
      },
      async submit() {
        this.v$.$touch();
        const { start, startHour, end, endHour } = this.form;
        const startTime = new Date(start);

        startTime.setHours(startHour.split(':')[0], 0, 0);
        const endTime = new Date(end);

        endTime.setHours(endHour.split(':')[0], 0, 0);
        if (startTime.getTime() === endTime.getTime()) {
          this.errorMessage = 'Les dates sont identiques';
        } else if (startTime.getTime() > endTime.getTime()) {
          this.errorMessage = 'La date de début est après la date de fin';
        } else {
          this.errorMessage = null;
        }
        if (this.v$.$invalid || this.errorMessage) {
          this.submitStatus = 'ERROR';
        } else {
          this.errorMessage = null;
          // do your submit logic here
          this.submitStatus = 'PENDING';

          if (this.current_event) {
            const {
              eventId,
              title,
              start,
              startHour,
              end,
              endHour,
              lieu,
              siteId,
              description,
              url,
              allDay,
              adresse
            } = this.form;
            const offset = start.getTimezoneOffset();
            const startLocaleDate = new Date(
              start.getTime() - offset * 60 * 1000
            );
            const endLocaleDate = new Date(end.getTime() - offset * 60 * 1000);
            const body = {
              id: eventId,
              titre: title,
              lieu,
              adresse,
              dateDebut: startLocaleDate.toISOString().split('T')[0],
              heureDebut: startHour.split(':')[0] * 60,
              dateFin: endLocaleDate.toISOString().split('T')[0],
              heureFin: endHour.split(':')[0] * 60,
              siteId,
              description,
              url,
              journeeEntiere: allDay
            };

            try {
              const response = await API.put(
                `formateur/agenda/indisponibilite/${this.current_event.eventId}`,
                body,
                this.$header_skip_redirection
              );

              if (response && response.data) {
                this.$emit('event-update');
              }
            } catch (e) {
              if (e.response.status === 400) {
                this.open_info_modal();
              }
            }
          } else {
            const {
              title,
              start,
              startHour,
              end,
              endHour,
              lieu,
              siteId,
              description,
              url,
              allDay,
              adresse
            } = this.form;
            const offset = start.getTimezoneOffset();
            const startLocaleDate = new Date(
              start.getTime() - offset * 60 * 1000
            );
            const endLocaleDate = new Date(end.getTime() - offset * 60 * 1000);
            const body = {
              titre: title,
              lieu,
              adresse,
              dateDebut: startLocaleDate.toISOString().split('T')[0],
              heureDebut: startHour.split(':')[0] * 60,
              dateFin: endLocaleDate.toISOString().split('T')[0],
              heureFin: endHour.split(':')[0] * 60,
              siteId,
              description,
              url,
              journeeEntiere: allDay
            };

            try {
              const response = await API.post(
                'formateur/agenda/indisponibilite',
                body,
                this.$header_skip_redirection
              );

              if (response && response.data) {
                this.$emit('event-create');
              }
            } catch (e) {
              if (e.response.status === 400) {
                this.open_info_modal();
              }
            }
          }
          this.v$.$reset();
          this.submitStatus = 'OK';
          this.onclose();
        }
      },
      closeModal() {
        this.v$.$reset();
        this.onclose();
      }
    }
  };
</script>

<style lang="scss" scoped>
  /* The switch - the box around the slider */
  .switch {
    @apply relative ml-1 mt-1 inline-flex h-4 w-8 items-center justify-center;
  }

  /* Hide default HTML checkbox */
  .switch input {
    @apply h-0 w-0 opacity-0;
  }

  /* The slider */
  .slider {
    @apply absolute inset-0 cursor-pointer bg-accent-500 transition duration-300 ease-in-out;
  }

  .slider:before {
    content: '';
    @apply absolute inset-0 h-3 w-3 bg-core-200 bg-white transition duration-300 ease-in-out;
    left: 3px;
    top: 1px;
  }

  input:checked + .slider {
    @apply bg-jinius-dark-green;
  }

  input:checked + .slider:before {
    @apply translate-x-3 transform;
  }

  /* Rounded sliders */
  .slider.round {
    @apply rounded-lg;
  }

  .slider.round:before {
    @apply rounded-full;
  }

  .input-error {
    color: red;
  }
</style>
