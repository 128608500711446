<template>
  <label class="form-label">
    <font-awesome-icon
      v-if="icon"
      v-bind:icon="icon"
      v-bind:size="icon_size" />
    <span v-bind:class="{ 'label-offset': icon }">
      {{ label }}
      <span
        v-if="required"
        class="text-xl font-bold text-jinius-red"
        >*</span
      >
      <span
        v-else-if="not_required_label"
        class="italic">
        (facultatif)
      </span>
    </span>
  </label>
</template>
<script>
  export default {
    props: {
      label: String,
      icon: [String, Array],
      icon_size: String,
      required: Boolean,
      not_required_label: Boolean
    }
  };
</script>
<style lang="scss" scoped>
  .form-label {
    @apply text-sm leading-none text-accent-500;

    .label-offset {
      @apply ml-2;
    }
  }
</style>
