<template>
  <div class="w-full lg:h-full">
    <spinner
      v-if="loading"
      sizes="w-20 h-20"
      thickness="border-8" />
    <div v-if="!loading">
      <ag-grid-wrapper
        v-bind:column_defs="[
          {
            headerName: 'Type',
            field: 'type',
            filter: 'agTextColumnFilter',
            cellRenderer: 'textCellRenderer',
            width: 100
          },
          {
            headerName: 'Référence',
            field: 'reference',
            filter: 'agTextColumnFilter',
            width: 100
          },
          {
            headerName: 'Formation',
            field: 'formation',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'Commande(s)',
            field: 'commandesReference',
            filter: 'agTextColumnFilter',
            minWidth: 100
          },
          { headerName: 'Total TTC', field: 'montantTtc' },
          { headerName: 'Montant payé', field: 'montantPayeTtc' },
          {
            headerName: 'Emise le',
            field: 'dateCreation',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'Facture',
            field: 'id',
            cellRenderer: 'buttonCellRenderer',
            cellRendererParams: {
              label: 'Télécharger',
              onclick: (e, params) => downloadFacture(e, params)
            }
          },
          { headerName: 'Stage', field: 'familleStage' },
          {
            headerName: 'Formation professionnelle',
            field: 'familleFormationContinue'
          },
          {
            headerName: 'Préparation aux examens',
            field: 'famillePreparationExamen'
          }
        ]"
        v-bind:initial_data="factures" />
    </div>
  </div>
</template>
<script>
  import AgGridWrapper from '@/components/utils/ag-grid/ag-grid-wrapper';
  import Spinner from '@/components/utils/spinner.vue';
  import { API } from '@/http-common';

  export default {
    components: {
      AgGridWrapper,
      Spinner
    },

    data: () => ({
      loading: false,
      factures: []
    }),
    async created() {
      await this.fetchFactures();
    },
    methods: {
      async downloadFacture(e, params) {
        const { nom, id } = params.data;
        const response = await API.get(`participant/facture/${id}/file`, {
          responseType: 'blob',
          ...this.$header_skip_redirection
        });
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', nom);
        document.body.appendChild(fileLink);
        fileLink.click();
      },
      async fetchFactures() {
        this.loading = true;
        const response = await API.get(
          'participant/factures',
          this.$header_skip_redirection
        );

        if (response && response.data) {
          this.factures = response.data.map((facture) => ({
            ...facture,
            familleStage: facture.familleStage ? 'Oui' : 'Non',
            familleFormationContinue: facture.familleFormationContinue
              ? 'Oui'
              : 'Non',
            famillePreparationExamen: facture.famillePreparationExamen
              ? 'Oui'
              : 'Non'
          }));
        }
        this.loading = false;
      }
    }
  };
</script>
<style lang="scss" scoped></style>
