<template>
  <div class="mb-8 flex w-full">
    <div
      class="flex w-full flex-wrap space-y-4 md:flex-nowrap md:space-x-4 md:space-y-0">
      <div class="stat__card w-full space-y-4 lg:w-1/3">
        <div class="stat__container">
          <div class="stat__title">Nombre de candidatures émises</div>
          <div class="stat__body">
            {{ nbCandidadturesEncours }}
          </div>
        </div>
        <div class="stat__container">
          <div class="stat__title">Nombre de candidatures acceptées</div>
          <div class="stat__body">
            {{ nbCandidadturesEncoursAcceptees }}
          </div>
        </div>
      </div>
      <div class="stat__card w-full lg:w-2/3">
        <div class="stat__container">
          <div class="stat__title">Thématiques demandées</div>
          <div class="stat__body">
            <div class="chart-container">
              <pie-chart
                v-bind:data="chartData"
                v-bind:options="chartOptions"
                v-bind:styles="
                  $breakpoints.w >= $breakpoints.screens.sm
                    ? { width: '600px', height: '180px' }
                    : { width: '300px', height: '180px' }
                " />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { tailwindsColors } from '@/assets/data/tailwind-config-resolver';
  import PieChart from '@/components/utils/chartjs/PieChart';

  export default {
    components: { PieChart },
    props: {
      candidatures: {
        type: Array,
        default: () => []
      }
    },
    data() {
      const themes = this.reduceThemes(this.candidatures);

      return {
        chartOptions: {
          maintainAspectRatio: false,
          legend: {
            position:
              this.$breakpoints.w >= this.$breakpoints.screens.md
                ? 'left'
                : 'top',
            labels: { boxWidth: 10 }
          },
          plugins: {
            datalabels: {
              formatter: (value, ctx) => {
                const datasets = ctx.chart.data.datasets;

                let percentage = 0;

                if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
                  const sum = datasets[0].data.reduce((a, b) => a + b, 0);

                  percentage = Math.round((value / sum) * 100) + '%';

                  return percentage;
                } else {
                  return percentage;
                }
              },
              color: 'white',
              textStrokeColor: 'black',
              textStrokeWidth: 1,
              textAlign: 'center',
              font: {
                weight: 'bold',
                size: 12
              }
            }
          }
        },
        chartData: {
          labels:
            themes &&
            Array.isArray(themes) &&
            themes.map((thematique) => thematique.themeLabel),
          datasets: [
            {
              backgroundColor: [
                tailwindsColors.info[500],
                tailwindsColors.green[500],
                tailwindsColors.purple[500],
                tailwindsColors.orange[500],
                tailwindsColors.danger[500],
                tailwindsColors.black
              ],
              data:
                themes &&
                Array.isArray(themes) &&
                themes.map((thematique) => thematique.count)
            }
          ]
        }
      };
    },
    computed: {
      nbCandidadturesEncours() {
        return this.candidatures.length;
      },
      nbCandidadturesEncoursAcceptees() {
        return this.candidatures.filter(
          (candidature) => candidature.statut === 'Affecté'
        ).length;
      }
    },
    methods: {
      reduceThemes(candidatures) {
        return candidatures.reduce((acc, cur) => {
          const index = acc.findIndex((item) => item.themeId === cur.themeId);

          if (index !== -1) {
            acc[index] = {
              ...acc[index],
              count: acc[index].count + 1
            };
          } else {
            acc = [
              ...acc,
              {
                ...cur,
                count: 1
              }
            ];
          }

          return acc;
        }, []);
      }
    }
  };
</script>
<style lang="scss" scoped>
  .stat__card {
    @apply flex flex-wrap;
    .stat__container {
      @apply flex w-full flex-wrap rounded-lg bg-core-200 px-2 shadow;
      .stat__title {
        @apply my-4 flex w-full justify-center text-center text-xl font-bold text-core-900;
      }
      .stat__body {
        @apply flex w-full justify-center py-4 text-6xl font-bold text-jinius-green;
        .chart-container {
          position: relative w-full h-full;
        }
      }
    }
  }
</style>
