<template>
  <label
    class="refonte-switch"
    v-bind:class="props.class">
    <input
      type="checkbox"
      class="switch-input"
      v-bind:checked="value"
      v-on:change="(e) => onChange(e.target.checked)" />
    <span class="switch-background"></span>
    <span class="switch-slider"></span>
    <span class="switch-label">
      {{ props.label }}
    </span>
  </label>
</template>

<script setup lang="ts">
  const props = withDefaults(
    defineProps<{
      label: string;
      class?: string;
      value?: boolean;
    }>(),
    {
      class: ''
    }
  );
  const emit = defineEmits(['change']);

  const onChange = (value: boolean) => {
    emit('change', value);
  };
</script>

<style lang="scss" scoped>
  .refonte-switch {
    @apply relative flex cursor-pointer items-center;
    padding: 9px 10px 9px 36px;
    border-radius: 4px;
    gap: 8px;
    user-select: none;

    .switch-input {
      @apply absolute h-0 w-0 cursor-pointer opacity-0;
    }
    .switch-background {
      @apply absolute bottom-0 left-0 right-0 top-0 bg-refonte-pale-violet-2;
      border-radius: 4px;
    }
    .switch-slider {
      @apply bg-refonte_blue-100;
      position: absolute;
      cursor: pointer;
      top: 13px;
      left: 8px;
      width: 20px;
      height: 10px;
      border-radius: 10px;

      &:before {
        position: absolute;
        content: '';
        height: 6px;
        width: 6px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        transition: 0.2s;
        border-radius: 50%;
      }
    }
    input:checked ~ .switch-slider {
      @apply bg-refonte_blue-400;

      &:before {
        transform: translateX(10px);
      }
    }
    .switch-label {
      @apply relative text-refonte_blue-600;
      z-index: 1;
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
    }

    &:hover {
      .switch-background {
        @apply bg-refonte-pale-violet-3;
      }
      input:checked ~ .switch-background {
        @apply bg-refonte-pale-violet-3;
      }
      .switch-slider {
        @apply bg-refonte_blue-200;
      }
      input:checked ~ .switch-slider {
        @apply bg-refonte_blue-800;
      }
    }
  }
</style>
