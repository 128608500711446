<template>
  <div
    class="refonte-details-header"
    v-bind:class="props.class">
    <refonte-button
      class="details-back"
      variant="secondary"
      v-bind:icon_left="IconChevronLeft"
      v-on:click="back">
      Retour
    </refonte-button>
    <div class="details-header-title-section">
      <div class="details-header-title-section-top">
        <div
          class="details-header-title-section-top-tags"
          v-if="detail.type?.id">
          <refonte-tag v-bind:variant="typeMapping[detail.type.id]">
            {{
              detail.ficheType ? detail.ficheType?.label : detail.type?.label
            }}
          </refonte-tag>
        </div>
        <h1>
          {{ detail.titre }}
        </h1>
        <div
          class="details-header-title-section-top-sub-title"
          v-if="detail.sousTitre">
          {{ detail.sousTitre }}
        </div>
      </div>
      <div class="details-header-title-section-bottom">
        <div class="details-header-title-section-bottom-left">
          <div class="details-header-title-section-bottom-reference">
            {{ `Session ${detail.sessionReference}` }}
          </div>
          <IconSeparatorVertical />
          <div
            v-on:click="goToDetailsCommande"
            class="details-header-title-section-bottom-reference hover:cursor-pointer hover:underline">
            {{ `Commande ${detail.commandeReference}` }}
          </div>
          <IconSeparatorVertical v-if="detail.cataloguePubliee" />
          <refonte-button
            variant="secondary"
            v-if="detail.cataloguePubliee"
            v-bind:icon_left="IconOpenNewTab"
            v-bind:on_click="() => openFiche(detail)">
            Fiche catalogue
          </refonte-button>
          <IconSeparatorVertical />
          <refonte-button
            variant="secondary"
            v-bind:on_click="() => openProgramme(detail)"
            v-bind:icon_left="IconDownload">
            Programme
          </refonte-button>
        </div>
        <refonte-details-header-item
          v-if="detail.mentionsAccessibilite"
          text="">
          <template v-slot:default>
            <display-accessibilite-icons
              v-bind:mentions_accessibilite="detail.mentionsAccessibilite" />
          </template>
        </refonte-details-header-item>
      </div>
    </div>
    <div class="details-header-infos-section">
      <refonte-card class="details-header-infos-section-left">
        <div class="details-header-infos-section-left-items-container">
          <div class="details-header-infos-section-left-container-items">
            <refonte-details-header-item
              v-bind:icon_left="IconDate"
              v-bind:text="formatDates(detail)"
              v-bind:additional_text="getAdditionalText(detail)" />
          </div>
          <div class="details-header-infos-section-left-container-items">
            <refonte-details-header-item
              v-bind:icon_left="IconHourglass"
              v-bind:text="`${dates_helpers.methods.formatMinutesToHours(detail.dureeMinutes)} (${detail.dureeJours}j)`" />
          </div>
        </div>
        <refonte-separator
          class="details-header-infos-section-left-separator" />
        <div class="details-header-infos-section-left-address">
          <div class="details-header-infos-section-left-address-icon">
            <IconLocation />
          </div>
          <div class="details-header-infos-section-left-address-text">
            <template v-if="detail.site">
              <span class="font-bold"
                >Lieu : {{ detail.site.denomination }}</span
              >
              <span>{{ detail.site.numeroVoie }}</span>
              <span>{{
                `${detail.site.codePostal} ${detail.site.ville}`
              }}</span>
            </template>
            <template v-else-if="detail.lieuNom">
              <span>Lieu : {{ detail.lieuNom }}</span>
            </template>
          </div>
        </div>
      </refonte-card>
      <refonte-card class="details-header-infos-section-right">
        <div class="details-header-infos-section-right-row">
          <refonte-details-header-item
            v-bind:icon_left="IconTrainingProvider"
            v-bind:text="
              detail.organisateur && `Organisé par ${detail.organisateur.nom}`
            " />
          <refonte-button
            variant="secondary"
            v-if="detail.organisateur"
            v-on:click="() => setContactModalOpen(true)">
            Contacter l’organisateur
          </refonte-button>
        </div>
        <refonte-details-header-item
          v-bind:icon_left="IconCertification"
          v-bind:text="
            detail.certifiant
              ? 'Formation certifiante'
              : 'Formation non certifiante'
          "
          v-bind:additional_text="
            detail.mode && detail.mode.label === 'Intra'
              ? detail.mode.label
              : ''
          " />
      </refonte-card>
    </div>
  </div>
  <refonte-modal-contact-organisateur
    v-bind:show="isContactModalOpen"
    v-if="detail.organisateur"
    v-bind:contact="detail.organisateur"
    v-bind:on_close="() => setContactModalOpen(false)" />
</template>

<script setup lang="ts">
  import RefonteModalContactOrganisateur from '@/views/_espace/_refonte/gestion/_inscriptions-details/inscriptions-details-modal-contact-organisateur.vue';
  import { ref } from 'vue';
  import IconCertification from '@/assets/img/refonte/icons/information/icon-certification.svg?component';
  import IconChevronLeft from '@/assets/img/refonte/icons/display/icon-chevron-left.svg?component';
  import IconDate from '@/assets/img/refonte/icons/information/icon-date.svg?component';
  import IconHourglass from '@/assets/img/refonte/icons/information/icon-hourglass.svg?component';
  import IconLocation from '@/assets/img/refonte/icons/information/icon-location.svg?component';
  import IconOpenNewTab from '@/assets/img/refonte/icons/action/icon-open-new-tab.svg?component';
  import IconSeparatorVertical from '@/assets/img/refonte/icons/display/icon-separator-vertical.svg?component';
  import IconTrainingProvider from '@/assets/img/refonte/icons/users/icon-training-provider.svg?component';
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import RefonteDetailsHeaderItem from '@/components/refonte/details/refonte-details-header-item.vue';
  import RefonteCard from '@/components/refonte/cards/refonte-card.vue';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';
  import dates_helpers from '@/mixin/dates_helpers';
  import { Detail } from '@/types/gestion/inscriptions-details-types';
  import RefonteTag from '@/components/refonte/tags/refonte-tag.vue';
  import { typeMapping } from '@/types/gestion/inscriptions-types';
  import { useRouter } from 'vue-router';
  import IconDownload from '@/assets/img/refonte/icons/display/icon-download.svg?component';

  const dureeDateTimeJour = 60 * 60 * 24 * 1000;
  const convertStringToDate = (date: string) => {
    const dateParts = date.split('/');
    const day = Number(dateParts[0]);
    const month = Number(dateParts[1]) - 1;
    const year = Number(dateParts[2]);

    return new Date(year, month, day);
  };
  const props = withDefaults(
    defineProps<{
      detail: Detail;
      class?: string;
    }>(),
    {
      class: ''
    }
  );
  const getAdditionalText = (detail: Detail) => {
    if (detail.dates && detail.dates.length === 1) {
      const date = detail.dates[0];
      if (date.matin && date.aprem) {
        return 'Journée entière';
      } else if (date.matin) {
        return 'Matin';
      } else {
        return 'Après-midi';
      }
    }
  };
  const openFiche = (detail: Detail) => {
    window.open(`${detail.catalogueUrl}/fiche/${detail.catalogueReference}`);
  };

  const openProgramme = (detail: Detail) => {
    if (detail.cataloguePubliee) {
      window.open(
        `${detail.catalogueUrl}/fiche/${detail.catalogueReference}?print=true`
      );
    } else {
      window.open(
        `${detail.catalogueUrl}/fiche/preview/${detail.catalogueHash}?print=true`
      );
    }
  };
  const formatDates = (detail: Detail) => {
    const sortedDates = detail.dates
      ? detail.dates
          .slice()
          .sort(
            (a, b) =>
              convertStringToDate(a.date).getTime() -
              convertStringToDate(b.date).getTime()
          )
      : [];
    if (sortedDates.length > 0) {
      if (sortedDates.length === 1) {
        return `Le ${sortedDates[0].date}`;
      } else if (
        sortedDates.every(
          (date, index) =>
            index === sortedDates.length - 1 ||
            convertStringToDate(sortedDates[index + 1].date).getTime() -
              convertStringToDate(date.date).getTime() ===
              dureeDateTimeJour
        )
      ) {
        return `Du ${detail.dateDebut} au ${detail.dateFin}`;
      } else {
        return sortedDates
          .map(
            (date) =>
              `${date.date}${date.matin && !date.aprem ? ' (matin)' : ''}${!date.matin && date.aprem ? ' (après-midi)' : ''}`
          )
          .join(', ');
      }
    } else if (detail.dateDebut && detail.dateFin) {
      if (detail.dateDebut == detail.dateFin) {
        return `Le ${detail.dateDebut}`;
      } else {
        return `Du ${detail.dateDebut} au ${detail.dateFin}`;
      }
    }
    return undefined;
  };
  const isContactModalOpen = ref(false);
  const setContactModalOpen = (value: boolean) => {
    isContactModalOpen.value = value;
  };

  const router = useRouter();
  const goToDetailsCommande = () => {
    router.push(
      `/refonte/gestion/commandes-details?id=${props.detail.commandeReference}`
    );
  };
  const back = () => {
    router.back();
  };
</script>

<style lang="scss" scoped>
  .refonte-details-header {
    @apply flex flex-col items-start self-stretch;
    gap: 12px;

    .details-back {
      margin-bottom: 12px;
    }

    .details-header-title-section {
      @apply flex flex-col items-start self-stretch;
      color: #000;
      gap: 4px;
      padding: 0 12px;

      .details-header-title-section-top {
        @apply flex flex-col items-start justify-center self-stretch;
        gap: 2px;

        .details-header-title-section-top-tags {
          @apply flex flex-wrap items-center;
          gap: 2px;
        }
        .details-header-title-section-top-sub-title {
          @apply text-refonte-black-60;
        }
      }

      .details-header-title-section-bottom {
        @apply flex flex-wrap items-center justify-end self-stretch;
        gap: 12px;
        margin-top: 8px;

        .details-header-title-section-bottom-left {
          @apply flex grow flex-wrap items-center self-stretch;
          gap: 8px;

          .details-header-title-section-bottom-reference {
            font-size: 13px;
            font-weight: 400;
            line-height: 16px;
          }
        }
      }
    }

    .details-header-infos-section {
      @apply flex items-stretch self-stretch;
      gap: 12px;

      .details-header-infos-section-left {
        @apply flex-row items-start gap-0;
        flex: 1 0 0;

        .details-header-infos-section-left-items-container {
          @apply flex grow flex-col items-start;

          .details-header-infos-section-left-container-items {
            @apply flex flex-wrap items-start justify-items-start;
          }
        }

        .details-header-infos-section-left-separator {
          @apply items-stretch;

          :deep(.separator-content) {
            padding: 4px 3px;

            .separator-content-line {
              @apply bg-refonte_blue-300;
              opacity: 0.3;
              min-width: 2px;
              max-width: 2px;
              height: 100%;
            }
          }
        }

        .details-header-infos-section-left-address {
          @apply flex items-start;
          gap: 6px;
          padding: 10px;

          .details-header-infos-section-left-address-icon {
            @apply flex shrink-0 items-center justify-center;
            width: 16px;
            height: 16px;

            :deep(svg) {
              @apply text-refonte-black;
              width: 16px;
              height: 16px;
            }
          }

          .details-header-infos-section-left-address-text {
            @apply flex flex-col items-start;
            gap: 4px;

            span {
              font-size: 13px;
              font-weight: 400;
              line-height: 16px;

              &.font-bold {
                font-weight: 600;
              }
            }
          }
        }
      }

      .details-header-infos-section-right {
        @apply items-start gap-0;
        flex: 1 0 0;

        .details-header-infos-section-right-row {
          @apply flex flex-wrap items-center self-stretch;
        }
      }

      @media (max-width: 1279.98px) {
        @apply flex-col;
      }
      @media (max-width: 499.98px) {
        .details-header-infos-section-left {
          @apply flex-col;

          .details-header-infos-section-left-separator {
            @apply items-stretch;

            :deep(.separator-content) {
              padding: 3px;

              .separator-content-line {
                min-width: 24px;
                width: 100%;
                max-width: 100%;
                height: 2px;
              }
            }
          }
        }
      }
    }
  }
</style>
