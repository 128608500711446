<template>
  <div
    v-if="!(params.value && params.value.hide) || params.alwaysDisplay"
    class="flex h-full w-full flex-wrap items-center justify-center">
    <button
      class="btn"
      v-on:click="params.onclick($event, params)"
      v-bind:disabled="!(params.value || params.alwaysDisplay)">
      {{
        params.value || params.alwaysDisplay
          ? params.label
          : params.labelDisabled()
      }}
    </button>
  </div>
</template>
<script>
  export default {};
</script>
<style lang="scss" scoped>
  .btn {
    @apply mx-1.5 w-64 max-w-full items-center justify-center truncate whitespace-nowrap rounded bg-jinius-dark-green px-1.5 py-2.5 text-sm font-bold leading-none text-white hover:bg-jinius-green disabled:cursor-not-allowed disabled:border-0 disabled:bg-accent-300;
  }
</style>
