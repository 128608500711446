<template>
  <div class="checkbox-container">
    <input
      type="checkbox"
      class="checkbox__input"
      id="checkbox"
      v-model="checkbox" />
    <label
      for="checkbox"
      class="checkbox__label">
      <span v-html="label"></span>
    </label>
  </div>
</template>

<script>
  export default {
    props: {
      label: { type: String },
      value: { type: Boolean }
    },
    emits: ['input'],
    data() {
      return { checkbox: false };
    },
    watch: {
      checkbox(newValue) {
        this.$emit('input', newValue);
      }
    }
  };
</script>

<style lang="scss" scoped>
  /* CUSTOM "CHECKED" ICON */
  .checkbox-container {
    @apply mb-8 w-full rounded border-l-4 border-primary-700 bg-white shadow-lg hover:text-accent-900;

    &.checkbox-container--disabled,
    &.checkbox-container--disabled span,
    &.checkbox-container--disabled .checkbox__label:before {
      @apply cursor-not-allowed border-accent-300 text-accent-300 #{!important};
    }
  }

  .checkbox-container .checkbox__input ~ .checkbox__label {
    @apply p-5;
  }
  .checkbox-container .checkbox__input:checked ~ .checkbox__label {
    @apply font-bold text-accent-900;

    &:before {
      content: '\2713';
      @apply bg-core-700 text-white;
    }
  }
  .checkbox-container .checkbox__input {
    display: none;
  }

  /* OPTIONAL COSMETICS */
  .checkbox-container .checkbox__label {
    @apply flex cursor-pointer items-center leading-tight;
  }

  /* CUSTOM SQUARE */
  .checkbox-container .checkbox__label:before {
    @apply ml-4 mr-6 flex h-[15px] w-[15px] items-center justify-center rounded border-1 border-core-700 bg-white pl-0.5 shadow-inner;
    content: '\00a0';
    /* Blank space */
  }
  .checkbox-container .checkbox__label > span {
    @apply flex-1;
  }
  :deep(.multiselect__content-wrapper) {
    @apply max-w-full;
  }
</style>
