<template>
  <div class="inline w-full">
    <div
      id="surveyResult"
      class="w-full"></div>
  </div>
</template>

<script>
  import {
    VisualizationManager,
    VisualizationPanel,
    WordCloud
  } from 'survey-analytics';
  import 'survey-analytics/survey.analytics.css';
  import { Model, StylesManager } from 'survey-core';
  import 'survey-core/modern.min.css';

  StylesManager.applyTheme('modern');

  VisualizationManager.unregisterVisualizer('text', WordCloud);
  VisualizationManager.unregisterVisualizer('comment', WordCloud);

  export default {
    props: ['json', 'data'],

    mounted() {
      // On doit attendre pour que le renderContent se fasse avec les marges
      setTimeout(() => {
        const survey = new Model(this.json);
        const allQuestions = survey.getAllQuestions();
        const surveyjsAnalyticsVisPanel = new VisualizationPanel(
          allQuestions,
          this.data,
          {
            haveCommercialLicense: true,
            labelTruncateLength: 27
          }
        );

        const surveyResultNode = document.getElementById('surveyResult');

        if (surveyResultNode) {
          surveyResultNode.innerHTML = '';
          surveyjsAnalyticsVisPanel.showHeader = true;
          surveyjsAnalyticsVisPanel.locale = 'fr';
          surveyjsAnalyticsVisPanel.renderContent(surveyResultNode);
        }
      }, 100);
    }
  };
</script>
