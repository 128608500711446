<template>
  <div class="breadcrumbs">
    <template
      v-for="(breadcrumb, index) in breadcrumbs_list"
      v-bind:key="`breadcrumb-${index}`">
      <router-link
        class="breadcrumbs__link"
        v-bind:to="getPath(index)">
        <div
          v-if="index == 0"
          v-bind:class="`text-${color}`">
          <font-awesome-icon icon="square" />
        </div>
        <div class="breadcrumbs__label">{{ getLabel(index) }}</div>
        <div v-if="breadcrumbs_list.length > index + 1">
          <font-awesome-icon icon="chevron-right" />
        </div>
      </router-link>
    </template>
  </div>
</template>

<script>
  export default {
    props: ['ref_list', 'color'],
    computed: {
      breadcrumbs_list() {
        const breadcrumbs_concat = [];

        this.splitted_path.forEach((item) => {
          if (item.length) {
            breadcrumbs_concat.push(item);
          }
        });

        return breadcrumbs_concat;
      },
      splitted_path() {
        const path = this.$route.fullPath;

        return path.split('/');
      }
    },
    methods: {
      getLabel(index) {
        const s = this.splitted_path;

        if (index === 0) {
          return this.ref_list[s[1]].label;
        } else if (index === 1) {
          const is_details =
            s[s.length - 1] && s[s.length - 1].split('?')[0] === 'details';
          const details_path =
            is_details &&
            `${s[s.length - 1].split('?')[0]}  ( ${s[s.length - 1].split('?')[1].split('&')[0].split('=')[1]} )`;

          return this.ref_list[s[1]].rubriques[s[2]]
            ? this.ref_list[s[1]].rubriques[s[2]].label
            : is_details
              ? details_path
              : s[s.length - 1];
        } else if (index === 2) {
          return this.ref_list[s[1]].rubriques[s[2]].sous_rubriques[s[3]].label;
        }
      },
      getPath(index) {
        let path = '';

        this.breadcrumbs_list.forEach((breadcrumb, i) => {
          if (i <= index) {
            path = path.concat('/', `${breadcrumb}`);
          }
        });

        return path;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .breadcrumbs {
    @apply flex w-full flex-wrap items-center space-x-1.5 font-bold uppercase text-core-700;

    .breadcrumbs__link {
      @apply flex space-x-1.5 text-xs font-bold uppercase leading-tight outline-none;

      .breadcrumbs__label {
        &:hover {
          @apply text-core-700 underline;
        }
      }
    }
  }
</style>
