<template>
  <div
    class="ressources__download__link__button"
    v-on:click="openFileDialog">
    <slot></slot>

    <div class="flex space-x-4">
      <div
        class="ressources__standalone_button__content"
        v-bind:title="title">
        <font-awesome-icon
          class="text-xl"
          icon="upload" />
        <input
          v-bind:id="id"
          type="file"
          v-bind:ref="id"
          v-on:change="handleFileUpload"
          hidden />
      </div>
      <div
        class="ml-3 flex"
        v-if="upload_loading">
        <spinner class="my-0 h-full text-sm" />
      </div>
      <div
        v-if="upload_complete || (!upload_init && is_file_uploaded)"
        class="ml-3 flex items-center">
        <font-awesome-icon
          class="text-xl text-green-500"
          icon="check" />
      </div>
      <div
        v-if="upload_error"
        class="ml-3 flex items-center">
        <font-awesome-icon
          class="text-xl text-danger-500"
          icon="times" />
      </div>
    </div>
  </div>
</template>
<script>
  import spinner from '@/components/utils/spinner.vue';
  import { API } from '@/http-common';
  export default {
    components: { spinner },
    props: {
      title: String,
      id: String,
      is_file_uploaded: Boolean,
      upload_url: String,
      refresh_session: Function
    },
    data: () => ({
      upload_loading: false,
      upload_complete: false,
      upload_error: false,
      upload_init: false
    }),
    methods: {
      openFileDialog() {
        const fileInputElement = this.$refs[this.id];

        fileInputElement.click();
      },
      async handleFileUpload(event) {
        this.upload_loading = true;
        this.upload_error = false;
        this.upload_complete = false;
        this.upload_init = true;
        const file = event.target.files[0];
        const formData = new FormData();

        formData.append('file', file);

        try {
          await API.post(this.upload_url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              ...this.$header_skip_redirection.headers
            }
          });
          this.upload_complete = true;
          await this.refresh_session();
        } catch (e) {
          this.upload_error = true;
        }

        this.upload_loading = false;
      }
    }
  };
</script>
<style lang="scss" scoped></style>
