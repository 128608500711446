<template>
  <div class="w-full lg:h-full">
    <spinner
      v-if="loading"
      sizes="w-20 h-20"
      thickness="border-8" />
    <div
      v-if="!loading"
      class="my-4">
      <form-label
        label="Autoriser d'autres tiers à choisir votre société en tant qu'entité de facturation ?"
        class="!text-base font-bold leading-none !text-core-900" />
      <div class="mx-6 mt-6">
        <yes-no-radio-buttons
          input_name="entiteFacturation"
          v-bind:input_value="entiteForm.entiteFacturation"
          v-bind:update_value="
            (value) => setEntiteFacturationAndSubmit(value)
          " />
      </div>
      <transition name="slide-up">
        <div
          v-if="entiteForm.entiteFacturation"
          class="mt-6">
          <form-label
            label="Tiers à autoriser"
            class="!text-base font-bold leading-none !text-core-900" />
          <div class="mx-6 mt-6">
            <div class="flex w-full flex-1">
              <radio-button
                id="yes-entiteFacturationPourTous"
                label="Tous"
                v-bind:checked="entiteForm.entiteFacturationPourTous"
                name="entiteFacturationPourTous"
                v-bind:value="true"
                v-on:change="setEntiteFacturationPourTousAndSubmit(true)" />
              <radio-button
                id="no-entiteFacturationPourTous"
                label="Uniquement les tiers suivants"
                v-bind:checked="entiteForm.entiteFacturationPourTous === false"
                name="entiteFacturationPourTous"
                v-bind:value="false"
                v-on:change="setEntiteFacturationPourTousAndSubmit(false)" />
            </div>
          </div>
        </div>
      </transition>
      <transition name="slide-up">
        <div
          v-if="
            entiteForm.entiteFacturation &&
            !entiteForm.entiteFacturationPourTous
          "
          class="mt-10">
          <ag-grid-wrapper
            v-bind:buttons="[
              {
                label: 'Ajouter une entité',
                icon: 'user-plus',
                onClick: openClientModal
              }
            ]"
            v-bind:with_delete_button="true"
            v-bind:column_defs="[
              {
                headerName: 'Dénomination',
                field: 'denomination',
                sort: 'asc',
                filter: 'agTextColumnFilter'
              },
              {
                headerName: 'SIRET',
                field: 'siret',
                filter: 'agTextColumnFilter'
              },
              {
                headerName: 'Adresse',
                field: 'adresse',
                filter: 'agTextColumnFilter'
              }
            ]"
            v-bind:initial_data="entitesFacturation"
            v-on:delete-row="deleteRow" />
        </div>
      </transition>
    </div>
    <client-modal
      title="Rechercher une entité à facturer"
      v-bind:onclose="closeClientModal"
      v-bind:on_client_form_submit="onClientFormSubmit"
      v-bind:modal_show="client_modal"
      v-bind:hide_particulier="true" />
    <already-exist-modal
      v-bind:show_modal="alreadyExistModal"
      v-bind:onclose="closeAlreadyExistModal" />
  </div>
</template>
<script>
  import AgGridWrapper from '@/components/utils/ag-grid/ag-grid-wrapper';
  import formLabel from '@/components/utils/form-label';
  import RadioButton from '@/components/utils/radio-button';
  import Spinner from '@/components/utils/spinner.vue';
  import yesNoRadioButtons from '@/components/utils/yes-no-radio-buttons';
  import { ACTION_TYPES } from '@/store/constants/ActionTypes';
  import ClientModal from '@/views/_espace/_account/_informations/_modal/client-modal';
  import AlreadyExistModal from '@/views/_espace/_entreprise/_entreprise/_facturation/already-exist-modal';
  import { createNamespacedHelpers } from 'vuex';
  const { mapState: mapStateClient, mapActions: mapActionsClient } =
    createNamespacedHelpers('client');
  const { mapState: mapStateObserver } = createNamespacedHelpers('observer');

  export default {
    components: {
      ClientModal,
      AgGridWrapper,
      Spinner,
      AlreadyExistModal,
      yesNoRadioButtons,
      formLabel,
      RadioButton
    },
    data: () => ({
      loading: false,
      alreadyExistModal: false,
      client_modal: false
    }),
    computed: {
      ...mapStateObserver(['showSearchModal']),
      ...mapStateClient(['entiteForm', 'profile', 'entitesFacturation'])
    },
    watch: {
      async ['profile.activeEntrepriseId']() {
        await this.fetchEntrepriseAndEntiteFacturation();
      }
    },
    async created() {
      await this.fetchEntrepriseAndEntiteFacturation();
    },
    methods: {
      ...mapActionsClient([
        ACTION_TYPES.GET_ENTREPRISE_INFORMATIONS,
        ACTION_TYPES.SEND_ENTREPRISE_INFORMATIONS,
        ACTION_TYPES.GET_CLIENT_ENTITES_FACTURATION,
        ACTION_TYPES.DELETE_CLIENT_ENTITES_FACTURATION,
        ACTION_TYPES.ADD_CLIENT_ENTITES_FACTURATION
      ]),
      openClientModal(e) {
        e.preventDefault();
        this.client_modal = true;
      },
      closeClientModal() {
        if (!this.showSearchModal) {
          this.client_modal = false;
        }
      },
      openAlreadyExistModal() {
        this.alreadyExistModal = true;
      },
      closeAlreadyExistModal() {
        this.alreadyExistModal = false;
      },
      async onClientFormSubmit(data) {
        if (data && data.id) {
          await this.addEntiteFacturation(data.id);
        }
      },
      async addEntiteFacturation(entiteId) {
        this.loading = true;
        const clientId = this.profile.activeEntrepriseId;

        try {
          await this[ACTION_TYPES.ADD_CLIENT_ENTITES_FACTURATION]({
            clientId,
            entiteId
          });
          await this[ACTION_TYPES.GET_CLIENT_ENTITES_FACTURATION]({ clientId });
        } catch (e) {
          if (e.response.status === 409) {
            this.openAlreadyExistModal();
          }
        }
        this.loading = false;
      },
      async fetchEntrepriseAndEntiteFacturation() {
        this.loading = true;
        const clientId = this.profile.activeEntrepriseId;

        if (clientId) {
          await this[ACTION_TYPES.GET_ENTREPRISE_INFORMATIONS]({ clientId });
          await this[ACTION_TYPES.GET_CLIENT_ENTITES_FACTURATION]({ clientId });
        }
        this.loading = false;
      },
      async deleteRow(value) {
        this.loading = true;
        const clientId = this.profile.activeEntrepriseId;

        if (clientId) {
          await this[ACTION_TYPES.DELETE_CLIENT_ENTITES_FACTURATION]({
            clientId,
            entiteId: value.id
          });
          await this[ACTION_TYPES.GET_CLIENT_ENTITES_FACTURATION]({ clientId });
        }
        this.loading = false;
      },
      async setEntiteFacturationAndSubmit(value) {
        const entiteForm = {
          ...this.entiteForm,
          entiteFacturation: value,
          entiteFacturationPourTous: value ? true : null
        };

        await this.submit(entiteForm);
      },
      async setEntiteFacturationPourTousAndSubmit(value) {
        const entiteForm = {
          ...this.entiteForm,
          entiteFacturationPourTous: value
        };

        await this.submit(entiteForm);
      },
      async submit(entiteForm) {
        this.loading = true;
        const clientId = this.profile.activeEntrepriseId;

        try {
          await this[ACTION_TYPES.SEND_ENTREPRISE_INFORMATIONS]({
            entiteForm,
            clientId
          });

          await this[ACTION_TYPES.GET_ENTREPRISE_INFORMATIONS]({ clientId });
          await this[ACTION_TYPES.GET_CLIENT_ENTITES_FACTURATION]({ clientId });

          this.loading = false;

          return { isError: false };
        } catch (e) {
          this.loading = false;

          await this[ACTION_TYPES.GET_ENTREPRISE_INFORMATIONS]({ clientId });
          await this[ACTION_TYPES.GET_CLIENT_ENTITES_FACTURATION]({ clientId });

          return {
            ...e.response,
            isError: true
          };
        }
      }
    }
  };
</script>
<style lang="scss" scoped></style>
