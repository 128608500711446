<template>
  <div
    class="delete-cell"
    v-if="!params.data?.readonly">
    <button
      v-on:click="deleteRow"
      class="delete-cell__button">
      <font-awesome-icon
        icon="trash"
        size="1x" />
    </button>
  </div>
</template>
<script>
  export default {
    methods: {
      deleteRow(e) {
        e.preventDefault();
        this.params.context.componentParent.deleteRow(
          this.params.node.rowIndex,
          this.params.node.data
        );
      }
    }
  };
</script>
<style lang="scss" scoped>
  .delete-cell {
    @apply flex w-full items-center justify-center;
    .delete-cell__button {
      @apply w-full text-core-500 hover:text-jinius-red;
    }
  }
</style>
