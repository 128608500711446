import { isObject } from '@vueuse/core';

export default {
    methods: {
        formatFilter(name, value) {
            return `${name}: ${value}`;
        },
        hasValue(value) {
            return value || value === false ? true : false;
        },
        isDeepEqual(object1, object2) {
            const objKeys1 = Object.keys(object1);
            const objKeys2 = Object.keys(object2);

            if (objKeys1.length !== objKeys2.length) return false;

            for (const key of objKeys1) {
                const value1 = object1[key];
                const value2 = object2[key];

                const isObjects = isObject(value1) && isObject(value2);

                if (
                    (isObjects && !this.methods.isDeepEqual(value1, value2)) ||
                    (!isObjects && value1 !== value2)
                ) {
                    return false;
                }
            }
            return true;
        }
    }
};
