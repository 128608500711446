import { API } from '@/http-common.js';
import { Entreprise } from '@/types/gestion/entreprise';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useEntrepriseStore = defineStore('entreprise', () => {
    const entreprises = ref([]);

    const activeEntrepriseIds = ref([]);

    function setActiveEntrepriseIds(ids) {
        activeEntrepriseIds.value = ids;
    }

    const profile = ref();

    const getProfile = async () => {
        const response = await API.get('client/v2/profile');
        if (response?.data) {
            profile.value = response?.data;
            entreprises.value = response.data.entreprises;
        }
    };

    const activeClientIds = computed(() =>
        activeEntrepriseIds.value.length > 0
            ? activeEntrepriseIds.value.join(',')
            : ''
    );

    const activeAdminClientIds = computed(() => {
        if (activeEntrepriseIds.value.length > 0) {
            const activeAdminEntrepriseIds = activeEntrepriseIds.value.filter(
                (id) => {
                    const entrepriseFound = entreprises.value.find(
                        (e: Entreprise) => e.entrepriseId == id
                    );
                    return (
                        entrepriseFound &&
                        (entrepriseFound as Entreprise).droitAdministrateur
                    );
                }
            );
            if (
                JSON.stringify(activeEntrepriseIds.value) !=
                JSON.stringify(activeAdminEntrepriseIds)
            ) {
                activeEntrepriseIds.value = activeAdminEntrepriseIds;
            }

            return activeAdminEntrepriseIds.join(',');
        } else {
            return '';
        }
    });

    const initActiveEntrepriseIdFromQuery = (clientIds) => {
        if (clientIds) {
            activeEntrepriseIds.value = clientIds
                .split(',')
                .map((c) => parseInt(c));
        }
    };

    return {
        initActiveEntrepriseIdFromQuery,
        activeAdminClientIds,
        activeClientIds,
        entreprises,
        activeEntrepriseIds,
        setActiveEntrepriseIds,
        profile,
        getProfile
    };
});
