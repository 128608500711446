<template>
  <refonte-modal
    v-bind:show="props.show"
    v-on:close="props.on_close"
    class="!overflow-visible">
    <template v-slot:modalHeader>
      <div class="refonte-export-header">
        <div class="export-header-icon">
          <IconExport />
        </div>
        <div class="export-header-text">
          <h2>Export de données</h2>
          <span>Format excel</span>
        </div>
      </div>
    </template>
    <template v-slot:modalBody>
      <div class="flex flex-col gap-[12px]">
        <div class="refonte-export-scope">
          <refonte-number variant="segment">{{
            props.nb_formations
          }}</refonte-number>
          <span>{{ `Formation${props.nb_formations === 1 ? '' : 's'}` }}</span>
        </div>
        <div class="flex flex-wrap items-center gap-[8px]">
          <refonte-radio
            name="exportType"
            value="standard"
            label="Standard"
            v-bind:checked="checked"
            v-on:input="updateChecked" />
          <refonte-radio
            name="exportType"
            value="personalise"
            label="Personnalisé"
            v-bind:checked="checked"
            v-on:input="updateChecked" />
        </div>
        <span v-if="checked === 'standard'">
          Nom de la formation, Date de formation, Type de formation, Statut,
          Nombre de participants, Organisme de formation, Commandé par
        </span>
        <div
          v-if="checked === 'personalise'"
          class="refonte-export-select">
          <refonte-filters-item
            v-bind:on_search="(value) => console.log(value)"
            v-bind:on_open="() => resetTemporarySelectedOptions()"
            v-bind:footer_button_on_click="() => updateSelectedOptions()"
            v-bind:footer_button_disabled="temporarySelectedOptions.length < 1"
            v-bind:class="selectedOptions.length > 0 ? 'export-selected' : ''">
            <div>
              <refonte-number
                v-if="selectedOptions.length > 0"
                variant="segment">
                {{ selectedOptions.length }}
              </refonte-number>
              {{
                `Attribut${selectedOptions.length === 1 ? '' : 's'} à exporter`
              }}
            </div>
            <template v-slot:dropdownContent>
              <refonte-checkbox
                v-for="option in exportOptions"
                v-bind:key="option.id"
                v-bind:value="
                  !!temporarySelectedOptions.find(
                    (tempOption) => tempOption.id === option.id
                  )
                "
                v-bind:label="option.label"
                v-on:input="updateTemporarySelectedOptions(option)" />
            </template>
          </refonte-filters-item>
        </div>
        <span v-if="checked === 'personalise' && selectedOptions.length > 0">
          {{ selectedOptions.map((option) => option.label).join(', ') }}
        </span>
      </div>
    </template>
    <template v-slot:modalFooter>
      <refonte-button v-bind:on_click="props.on_ok">
        Exporter les données
      </refonte-button>
    </template>
  </refonte-modal>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import IconExport from '@/assets/img/refonte/icons/action/icon-export.svg?component';
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import RefonteModal from '@/components/refonte/modals/refonte-modal.vue';
  import RefonteRadio from '@/components/refonte/inputs/refonte-radio.vue';
  import RefonteNumber from '@/components/refonte/numbers/refonte-number.vue';
  import RefonteCheckbox from '@/components/refonte/inputs/refonte-checkbox.vue';
  import RefonteFiltersItem from '@/components/refonte/filters/refonte-filters-item.vue';

  export interface Option {
    id: string;
    label: string;
  }

  const props = withDefaults(
    defineProps<{
      nb_formations: number;
      show: boolean;
      on_close: Function;
      on_ok: Function;
      class?: string;
    }>(),
    {
      class: ''
    }
  );

  const exportOptions: Option[] = [
    {
      id: 'name',
      label: 'Nom de la formation'
    },
    {
      id: 'date',
      label: 'Date de formation'
    },
    {
      id: 'type',
      label: 'Type de formation'
    },
    {
      id: 'statut',
      label: 'Statut'
    },
    {
      id: 'participants',
      label: 'Nombre de participants'
    },
    {
      id: 'organisme',
      label: 'Organisme de formation'
    },
    {
      id: 'ordered',
      label: 'Commandé par'
    },
    {
      id: 'entity',
      label: 'Entité payeur'
    },
    {
      id: 'time',
      label: 'Horaire de formation'
    },
    {
      id: 'certificat',
      label: 'Certifiante'
    },
    {
      id: 'mode',
      label: 'Mode (INTER, INTRA)'
    }
  ];

  const checked = ref<string>('standard');
  const selectedOptions = ref<Option[]>([]);
  const temporarySelectedOptions = ref<Option[]>([]);

  function updateChecked(newValue: string) {
    checked.value = newValue;
  }
  function updateSelectedOptions() {
    selectedOptions.value = temporarySelectedOptions.value;
  }
  function resetTemporarySelectedOptions() {
    temporarySelectedOptions.value = selectedOptions.value;
  }
  function updateTemporarySelectedOptions(option: Option) {
    if (
      temporarySelectedOptions.value.find(
        (tempOption) => tempOption.id === option.id
      )
    ) {
      temporarySelectedOptions.value = temporarySelectedOptions.value.filter(
        (tempOption) => tempOption.id !== option.id
      );
    } else {
      temporarySelectedOptions.value = [
        ...temporarySelectedOptions.value,
        option
      ];
    }
  }
</script>

<style lang="scss" scoped>
  :deep(.refonte-modal) {
    max-width: 400px;

    .modal-header {
      padding-bottom: 20px;
    }
    .modal-footer {
      @apply justify-center;
      padding-top: 24px;
    }
  }
  .refonte-export-header {
    @apply flex items-center self-stretch;
    gap: 12px;

    .export-header-icon {
      @apply flex items-center justify-center;
      width: 22px;
      height: 22px;

      :deep(svg) {
        @apply text-refonte-black;
        width: 22px;
        height: 22px;
      }
    }
    .export-header-text {
      @apply flex flex-grow flex-col items-start justify-center;

      span {
        @apply self-stretch text-refonte_blue-300;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
      }
    }
  }
  .refonte-export-scope {
    @apply flex items-center self-stretch bg-refonte_blue-100;
    padding: 8px;
    border-radius: 8px;
    gap: 4px;

    span {
      @apply self-stretch overflow-hidden text-ellipsis text-refonte_blue-600;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
    }
  }
  .refonte-export-select {
    :deep(.refonte-filters-item) {
      @apply w-full justify-between border border-refonte_blue-100;

      &.export-selected {
        @apply bg-refonte-pale-violet-2;
      }
      &:hover {
        @apply bg-refonte-pale-violet-3;
      }
    }
    :deep(.refonte-dropdown) {
      &.dropdown-open {
        .refonte-filters-item {
          @apply bg-refonte-pale-violet-3;
        }
      }

      .dropdown-container {
        max-width: unset;
        left: 0;
        right: 0;

        .dropdown-content {
          .dropdown-content-items {
            max-height: 208px;
          }
        }
      }
    }
  }
</style>
