<template>
  <div class="accessibilite">
    <div
      class="accessibilite__container"
      v-if="mentions_accessibilite"
      v-tooltip="{
        content: mentions_accessibilite,
        popperClass: 'v-tooltip--accessibilite',
        distance: 10
      }">
      <div
        v-for="icon in ['low-vision', 'wheelchair', 'deaf']"
        v-bind:key="icon"
        class="accessibilite__item">
        <font-awesome-icon
          v-bind:icon="icon"
          size="lg" />
      </div>
      <div class="accessibilite__item">
        <img
          class="item--img"
          src="@/assets/img/handicap-mental.svg"
          alt="handicap mental" />
      </div>
    </div>
    <div
      v-if="show_legend"
      class="accessibilite__legend">
      Conditions d'accessibilité
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      mentions_accessibilite: { type: String },
      show_legend: { type: Boolean }
    }
  };
</script>
<style lang="scss">
  .v-tooltip--accessibilite {
    @apply max-w-md;
    .tooltip-inner {
      @apply px-4 py-3;
    }
  }
</style>

<style lang="scss" scoped>
  .accessibilite {
    .accessibilite__container {
      @apply flex space-x-1.5;

      .accessibilite__item {
        @apply flex h-10 w-10 cursor-pointer items-center justify-center rounded-xl border-2 text-accent-500;

        .item--img {
          @apply object-fill;
        }
      }
    }

    .accessibilite__legend {
      @apply mt-1.5 text-center font-bold;
    }
  }
</style>
