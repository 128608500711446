<template>
  <div
    class="refonte-search-results-empty"
    v-bind:class="props.class">
    Aucun résultat
  </div>
</template>

<script setup lang="ts">
  const props = withDefaults(
    defineProps<{
      class?: string;
    }>(),
    {
      class: ''
    }
  );
</script>

<style lang="scss" scoped>
  .refonte-search-results-empty {
    @apply flex items-center justify-center self-stretch bg-refonte-white text-refonte_blue-400;
    padding: 5px 8px;
    gap: 6px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
</style>
