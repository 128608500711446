<template>
  <div
    class="refonte-table-category-checkbox"
    v-bind:class="`${props.with_background ? 'table-category-checkbox-with-background' : ''} ${props.class}`">
    <refonte-checkbox
      v-bind:label="props.label"
      v-bind:value="props.value"
      v-on:input="props.toggle_is_checked" />
  </div>
</template>

<script setup lang="ts">
  import RefonteCheckbox from '@/components/refonte/inputs/refonte-checkbox.vue';

  const props = withDefaults(
    defineProps<{
      label: string;
      toggle_is_checked?: (newValue: boolean) => void;
      with_background?: boolean;
      class?: string;
      value?: boolean;
    }>(),
    {
      with_background: false,
      class: ''
    }
  );
</script>

<style lang="scss" scoped>
  .refonte-table-category-checkbox {
    margin-bottom: 8px;
    padding: 0 24px;

    :deep(.refonte-checkbox) {
      padding: 4px 4px 4px 34px;
      border-radius: 0;
      gap: 10px;

      .checkbox-background {
        @apply hidden;
      }
      .checkbox-checkmark {
        top: 4px;
        left: 12px;
      }
      .checkbox-label {
        span {
          @apply uppercase;
          font-size: 10px;
          line-height: 13px;
        }
      }
    }

    &.table-category-checkbox-with-background {
      @apply bg-refonte_blue-100;
      margin-bottom: 0;
      padding: 4px 12px 4px 0;
      border-radius: 4px;
    }
  }
</style>
