<template>
  <template v-if="!loading && detail">
    <refonte-details-header-etablissement
      v-bind:title="detail.titre"
      v-bind:reference="detail.id"
      v-bind:deactivated="!detail.desactivable">
    </refonte-details-header-etablissement>
    <form v-on:submit.prevent="submit">
      <refonte-collapse
        class="mt-[12px]"
        title="Informations générales"
        v-bind:start_open="true"
        content_class="!p-[16px]">
        <div class="modifier-etablissement-content">
          <div v-if="nature">
            <refonte-label label="Nature" />
            <p class="pb-[16px] text-refonte-black">
              {{ nature.label }}
            </p>
          </div>
          <div v-if="etablissementForm.reference">
            <refonte-label label="Référence" />
            <p class="pb-[16px] text-refonte-black">
              {{ etablissementForm.reference }}
            </p>
          </div>
          <refonte-input
            v-if="nature && nature.shortcut === 'siret'"
            v-bind:placeholder="
              etablissementForm.valideSansSiret ? 'Équivalent SIRET' : 'SIRET'
            "
            input_class="w-full"
            v-bind:value="etablissementForm.siret"
            v-on:input="setEtablissmentForm($event, 'siret')"
            v-bind:required="!etablissementForm.valideSansSiret"
            v-bind:error="v$.etablissementForm.siret.$error" />
          <refonte-input
            v-if="nature && nature.shortcut === 'rna'"
            placeholder="RNA"
            input_class="w-full"
            v-bind:value="etablissementForm.rna"
            v-on:input="setEtablissmentForm($event, 'rna')" />
          <refonte-input
            placeholder="Dénomination"
            input_class="w-full"
            v-bind:maxlength="100"
            v-bind:value="etablissementForm.denomination"
            v-on:input="setEtablissmentForm($event, 'denomination')"
            v-bind:required="true"
            v-bind:error="v$.etablissementForm.denomination.$error" />
          <refonte-input
            placeholder="Numéro de TVA Intra"
            input_class="w-full"
            v-bind:value="etablissementForm.numeroTVAIntra"
            v-on:input="setEtablissmentForm($event, 'numeroTVAIntra')" />
          <template v-if="nature && nature.shortcut === 'siret'">
            <div
              class="flex w-full items-center gap-[8px] pb-[16px] text-refonte-black">
              <div class="flex items-center gap-[4px]">
                <label> Etablissement siège </label>
              </div>
              <div class="flex items-center gap-[8px]">
                <refonte-radio
                  name="etablissementSiege"
                  v-bind:value="true"
                  label="Oui"
                  v-bind:checked="etablissementForm.etablissementSiege"
                  v-on:input="setEtablissmentForm($event, 'etablissementSiege')"
                  class="!min-w-0" />
                <refonte-radio
                  name="etablissementSiege"
                  v-bind:value="false"
                  label="Non"
                  v-bind:checked="etablissementForm.etablissementSiege"
                  v-on:input="setEtablissmentForm($event, 'etablissementSiege')"
                  class="!min-w-0" />
              </div>
            </div>
            <div class="flex w-full items-center gap-[8px] pb-[16px]">
              <refonte-select
                placeholder="Tranche d'effectif"
                class="flex-grow"
                input_class="w-full"
                v-bind:options="trancheEffectifs"
                value_label="label"
                v-bind:value="
                  trancheEffectifs.find(
                    (trancheEffectif) =>
                      etablissementForm.trancheEffectifs === trancheEffectif.id
                  )
                "
                v-on:input="
                  setEtablissmentForm($event.id, 'trancheEffectifs')
                " />
              <div
                v-tooltip="{
                  content: `<ul>
                      <li>Unités non employeuses: pas de salarié au cours de l'année de référence et pas d'effectif au 31/12</li>
                      <li>0 salarié : n'ayant pas d'effectif au 31/12 mais ayant employé des salariés au cours de l'année de référence</li>
                    </ul>`
                }">
                <font-awesome-icon
                  icon="info-circle"
                  class="text-refonte_blue-600" />
              </div>
            </div>
            <div class="flex w-full items-center gap-[8px] pb-[16px]">
              <refonte-input
                placeholder="Activité"
                class="!pb-0"
                input_class="w-full"
                v-bind:maxlength="6"
                v-bind:value="etablissementForm.activite"
                v-on:input="setEtablissmentForm($event, 'activite')" />
              <div
                v-if="etablissementForm.activite"
                v-bind:class="`flex-grow rounded bg-core-200 px-[16px] pb-[6px] pt-[8px] text-center text-xs font-bold uppercase italic leading-none ${activites[etablissementForm.activite] ? 'bg-jinius-green text-white' : 'bg-core-200 text-jinius-red'}`">
                {{
                  activites[etablissementForm.activite]
                    ? activites[etablissementForm.activite]
                    : 'Code inexistant dans la nomenclature'
                }}
              </div>
            </div>
            <div class="flex w-full items-center gap-[8px] pb-[16px]">
              <refonte-input
                placeholder="Catégorie juridique"
                class="!pb-0"
                input_class="w-full"
                v-bind:maxlength="4"
                v-bind:value="etablissementForm.categorieJuridique"
                v-on:input="
                  setEtablissmentForm($event, 'categorieJuridique')
                " />
              <div
                v-if="etablissementForm.categorieJuridique"
                v-bind:class="`flex-grow rounded bg-core-200 px-[16px] pb-[6px] pt-[8px] text-center text-xs font-bold uppercase italic leading-none ${categoriesJuridiques[etablissementForm.categorieJuridique] ? 'bg-jinius-green text-white' : 'bg-core-200 text-jinius-red'}`">
                {{
                  categoriesJuridiques[etablissementForm.categorieJuridique]
                    ? categoriesJuridiques[etablissementForm.categorieJuridique]
                    : 'Catégorie inexistante'
                }}
              </div>
            </div>
          </template>
          <refonte-select
            placeholder="Territorialité"
            input_class="w-full"
            v-bind:options="territorialites"
            value_label="label"
            v-bind:value="
              territorialites.find(
                (territorialite) =>
                  etablissementForm.territorialiteId === territorialite.id
              )
            "
            v-on:input="setEtablissmentForm($event.id, 'territorialiteId')"
            v-bind:disabled="true" />
        </div>
      </refonte-collapse>
      <refonte-collapse
        class="mt-[12px]"
        title="Adresse"
        v-bind:start_open="true"
        content_class="!p-[16px]">
        <div class="modifier-etablissement-content">
          <refonte-input
            placeholder="Identification"
            input_class="w-full"
            v-bind:maxlength="100"
            v-bind:value="etablissementForm.identification"
            v-on:input="setEtablissmentForm($event, 'identification')" />
          <refonte-input
            placeholder="Dest./Service"
            input_class="w-full"
            v-bind:maxlength="100"
            v-bind:value="etablissementForm.destService"
            v-on:input="setEtablissmentForm($event, 'destService')" />
          <refonte-input
            placeholder="Complément d'adresse"
            input_class="w-full"
            v-bind:maxlength="38"
            v-bind:value="etablissementForm.complement"
            v-on:input="setEtablissmentForm($event, 'complement')" />
          <refonte-input
            placeholder="N° et voie"
            input_class="w-full"
            v-bind:maxlength="38"
            v-bind:value="etablissementForm.numeroVoie"
            v-on:input="setEtablissmentForm($event, 'numeroVoie')"
            v-bind:required="true"
            v-bind:error="v$.etablissementForm.numeroVoie.$error" />
          <refonte-input
            placeholder="Mention spéciale"
            input_class="w-full"
            v-bind:maxlength="38"
            v-bind:value="etablissementForm.mentionSpeciale"
            v-on:input="setEtablissmentForm($event, 'mentionSpeciale')" />
          <refonte-input
            placeholder="Ville"
            input_class="w-full"
            v-bind:maxlength="100"
            v-bind:value="etablissementForm.ville"
            v-on:input="setEtablissmentForm($event, 'ville')"
            v-bind:required="true"
            v-bind:error="v$.etablissementForm.ville.$error" />
          <refonte-input
            placeholder="Code postal"
            input_class="w-full"
            v-bind:maxlength="5"
            format="numeric"
            v-bind:value="etablissementForm.codePostal"
            v-on:input="setEtablissmentForm($event, 'codePostal')"
            v-bind:required="true"
            v-bind:error="v$.etablissementForm.codePostal.$error" />
          <refonte-select
            placeholder="Pays"
            input_class="w-full"
            v-bind:options="pays"
            value_label="label"
            v-bind:value="
              pays.find((item) => etablissementForm.pays === item.id)
            "
            v-on:input="setEtablissmentForm($event.id, 'pays')" />
        </div>
      </refonte-collapse>
      <refonte-collapse
        class="mt-[12px]"
        title="Informations d'inscription"
        v-bind:start_open="true"
        content_class="!p-[16px]">
        <div class="modifier-etablissement-content">
          <refonte-select
            placeholder="Qui a le droit de passer commande au nom de la société ?"
            input_class="w-full"
            v-bind:options="droitsCommande"
            value_label="label"
            v-bind:value="
              droitsCommande.find(
                (item) => etablissementForm.commandeProfilId === item.id
              )
            "
            v-on:input="setEtablissmentForm($event.id, 'commandeProfilId')"
            v-bind:required="true"
            v-bind:error="v$.etablissementForm.commandeProfilId.$error" />
        </div>
      </refonte-collapse>
      <refonte-collapse
        class="mt-[12px]"
        title="Informations de facturation"
        v-bind:start_open="true"
        content_class="!p-[16px]">
        <div class="modifier-etablissement-content">
          <div
            class="flex w-full items-center justify-end gap-[8px] text-refonte-black">
            <div class="flex flex-grow items-center gap-[4px]">
              <label>
                Autoriser d'autres tiers à choisir votre société en tant
                qu'entité de facturation ?
              </label>
            </div>
            <div class="flex items-center gap-[8px]">
              <refonte-radio
                name="entiteFacturation"
                v-bind:value="true"
                label="Oui"
                v-bind:checked="etablissementForm.entiteFacturation"
                v-on:input="
                  (e: any) => {
                    setEtablissmentForm(e, 'entiteFacturation');
                    if (e) {
                      setEtablissmentForm(true, 'entiteFacturationPourTous');
                    }
                  }
                "
                class="!min-w-0" />
              <refonte-radio
                name="entiteFacturation"
                v-bind:value="false"
                label="Non"
                v-bind:checked="etablissementForm.entiteFacturation"
                v-on:input="
                  (e: any) => {
                    setEtablissmentForm(e, 'entiteFacturation');
                    if (e) {
                      setEtablissmentForm(true, 'entiteFacturationPourTous');
                    }
                  }
                "
                class="!min-w-0" />
            </div>
          </div>
          <div
            v-if="etablissementForm.entiteFacturation"
            class="flex w-full items-center justify-end gap-[8px] pt-[16px] text-refonte-black">
            <div class="flex flex-grow items-center gap-[4px]">
              <label> Tiers à autoriser </label>
            </div>
            <div class="flex items-center gap-[8px]">
              <refonte-radio
                name="entiteFacturationPourTous"
                v-bind:value="true"
                label="Tous"
                v-bind:checked="etablissementForm.entiteFacturationPourTous"
                v-on:input="
                  setEtablissmentForm($event, 'entiteFacturationPourTous')
                "
                class="!min-w-0" />
              <refonte-radio
                name="entiteFacturationPourTous"
                v-bind:value="false"
                label="Uniquement les tiers suivants"
                v-bind:checked="etablissementForm.entiteFacturationPourTous"
                v-on:input="
                  setEtablissmentForm($event, 'entiteFacturationPourTous')
                "
                class="!min-w-0" />
            </div>
          </div>
          <div
            v-if="
              etablissementForm.entiteFacturation &&
              !etablissementForm.entiteFacturationPourTous
            "
            class="pt-[16px]">
            <refonte-button
              size="sm"
              class="mb-[8px]"
              v-bind:on_click="() => setIsClientModalOpen(true)">
              Ajouter une entité
            </refonte-button>
            <client-modal
              title="Rechercher une entité à facturer"
              v-bind:onclose="() => setIsClientModalOpen(false)"
              v-bind:modal_show="isClientModalOpen"
              v-bind:hide_particulier="true" />
            <refonte-table
              key="entites-1"
              table_index="entites-1"
              data_etablissement="denomination"
              data_etablissement_siret="siret"
              v-bind:data_etablissement_status="
                (row: Etablissement) =>
                  row.desactivable ? undefined : 'Désactivé'
              "
              v-bind:data_etablissement_pastille="
                (row: Etablissement) =>
                  row.desactivable ? 'active' : 'deactivated'
              "
              v-bind:data_etablissement_width="350"
              v-bind:columns="columns"
              v-bind:data="etablissements"
              class="modifier-etablissement-table" />
          </div>
        </div>
      </refonte-collapse>
      <refonte-collapse
        class="mt-[12px]"
        title="Contact"
        v-bind:start_open="true"
        content_class="!p-[16px]">
        <div class="modifier-etablissement-content">
          <refonte-input
            placeholder="Téléphone"
            input_class="w-full"
            v-bind:maxlength="100"
            v-bind:value="etablissementForm.telephone"
            v-on:input="setEtablissmentForm($event, 'telephone')" />
          <refonte-input
            placeholder="E-mail"
            input_class="w-full"
            v-bind:maxlength="100"
            v-bind:value="etablissementForm.email"
            v-on:input="setEtablissmentForm($event, 'email')" />
          <refonte-input
            placeholder="Site internet"
            input_class="w-full"
            v-bind:maxlength="100"
            v-bind:value="etablissementForm.siteInternet"
            v-on:input="setEtablissmentForm($event, 'siteInternet')" />
        </div>
      </refonte-collapse>
      <div
        class="mt-[12px] flex flex-wrap items-center justify-end gap-x-[32px] gap-y-[8px] self-stretch">
        <refonte-button variant="text"> Annuler </refonte-button>
        <refonte-button v-bind:on_click="submit"> Modifier </refonte-button>
      </div>
    </form>
  </template>
  <spinner
    v-else
    sizes="w-20 h-20"
    thickness="border-8" />
</template>
<script setup lang="ts">
  import Spinner from '@/components/utils/spinner.vue';
  import { computed, onMounted, ref, watch } from 'vue';
  import { useRoute } from 'vue-router';
  import RefonteDetailsHeaderEtablissement from '@/components/refonte/details/refonte-details-header-etablissement.vue';
  import {
    droitsCommande,
    natureList,
    pays,
    territorialites,
    trancheEffectifs
  } from '@/assets/data/data_form';
  import { Etablissement } from '@/types/gestion/etablissements-types';
  import RefonteLabel from '@/components/refonte/inputs/refonte-label.vue';
  import RefonteTable from '@/components/refonte/tables/refonte-table.vue';
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
  import RefonteInput from '@/components/refonte/inputs/refonte-input.vue';
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import RefonteSelect from '@/components/refonte/inputs/refonte-select.vue';
  import ClientModal from '@/views/_espace/_account/_informations/_modal/client-modal.vue';
  import RefonteRadio from '@/components/refonte/inputs/refonte-radio.vue';
  import {
    email,
    helpers,
    maxLength,
    minLength,
    required,
    requiredIf
  } from '@vuelidate/validators';
  import { useVuelidate } from '@vuelidate/core';
  import { useEtablissementStore } from '@/stores/etablissements';
  import { storeToRefs } from 'pinia';
  import IconClose from '@/assets/img/refonte/icons/display/icon-close.svg?component';
  import RefonteCollapse from '@/components/refonte/collapse/refonte-collapse.vue';

  const detail = ref();
  const route = useRoute();
  const loading = ref<boolean>(false);
  const id = computed(() => route.query.id);

  const etablissementStore = useEtablissementStore();

  const { etablissements } = storeToRefs(etablissementStore);
  const activeEtablissement = ref<Etablissement>();

  const fetchEntreprise = async () => {
    activeEtablissement.value = etablissements.value.find(
      (e) => id.value && e.id.toString() === id.value.toString()
    );
    detail.value = {
      ...activeEtablissement.value,
      titre: activeEtablissement.value && activeEtablissement.value.denomination
    };
  };

  onMounted(async () => {
    loading.value = true;
    await etablissementStore.fetchEtablissements({});
    await fetchEntreprise();
    loading.value = false;
  });

  const etablissementForm = ref<{
    natureId?: number;
    reference?: string;
    siret?: string;
    valideSansSiret?: boolean;
    rna?: string;
    denomination?: string;
    numeroTVAIntra?: string;
    etablissementSiege?: boolean;
    trancheEffectifs?: string;
    activite?: string;
    categorieJuridique?: string;
    territorialiteId?: number;
    identification?: string;
    destService?: string;
    complement?: string;
    numeroVoie?: string;
    mentionSpeciale?: string;
    ville?: string;
    codePostal?: string;
    pays?: string | null;
    commandeProfilId?: number;
    entiteFacturation?: boolean;
    entiteFacturationPourTous?: boolean;
    telephone?: string;
    email?: string;
    siteInternet?: string;
  }>({});

  const activites = [];
  const categoriesJuridiques = [];

  const rules = computed(() => ({
    etablissementForm: {
      siret: {
        minLength: minLength(etablissementForm.value.valideSansSiret ? 0 : 14),
        maxLength: maxLength(etablissementForm.value.valideSansSiret ? 20 : 14),
        required: requiredIf(function () {
          return !etablissementForm.value.valideSansSiret;
        })
      },
      denomination: {
        maxLength: maxLength(100),
        required
      },
      identification: { maxLength: maxLength(100) },
      email: { email },
      numeroVoie: { required },
      ville: { required },
      codePostal: {
        required,
        maxLength: helpers.ref?.pays ? maxLength(25) : maxLength(5)
      },
      commandeProfilId: { required }
    }
  }));

  const v$ = useVuelidate(rules, { etablissementForm });

  watch(activeEtablissement, (value) => {
    if (value) {
      etablissementForm.value = {
        natureId: 1,
        denomination: activeEtablissement.value?.denomination,
        siret: activeEtablissement.value?.siret,
        numeroVoie: '10 rue Auber',
        ville: 'Paris',
        codePostal: '75009',
        pays: null,
        commandeProfilId:
          activeEtablissement.value?.inscriptionLabel === 'Liste' ? 2 : 1,
        entiteFacturation:
          activeEtablissement.value?.facturationAutresTiersLabel !== 'Non',
        entiteFacturationPourTous:
          activeEtablissement.value?.facturationAutresTiersLabel !== 'Non'
      };
    } else {
      etablissementForm.value = {};
    }
  });

  const nature = computed(() => {
    if (etablissementForm.value && etablissementForm.value.natureId) {
      return natureList.find((n) => n.id === etablissementForm.value.natureId);
    }
    return undefined;
  });

  function close() {
    etablissementForm.value = {};
    v$.value.$reset();
  }
  function setEtablissmentForm(value: string | number | boolean, name: any) {
    etablissementForm.value[name] = value;
  }
  function submit(e: Event) {
    e.preventDefault();
    v$.value.$touch();
    if (!v$.value.$invalid) {
      close();
    }
  }

  const columns = [
    {
      content_index: 'adresse',
      label: 'Adresse',
      width: 240
    },
    {
      content_index: 'id',
      content_button_icon: (row: Etablissement) => IconClose,
      content_button_on_click: (row: Etablissement) => {},
      content_button_variant: (row: Etablissement) => 'danger',
      content_button_title: (row: Etablissement) => 'Supprimer',
      width: 40
    }
  ];

  const isClientModalOpen = ref<boolean>(false);

  function setIsClientModalOpen(value: boolean) {
    isClientModalOpen.value = value;
  }
</script>

<style lang="scss" scoped>
  .modifier-etablissement-content {
    max-width: 656px;
  }
  .modifier-etablissement-table {
    :deep(.refonte-table-row) {
      @apply bg-refonte-pale-violet-2;
    }
  }
</style>
