<template>
  <div
    class="refonte-item-details"
    v-bind:class="props.class">
    <label class="item-details-label">{{ props.label }}</label>
    <span class="item-details-value">
      {{ props.value }}
    </span>
  </div>
</template>

<script setup lang="ts">
  const props = withDefaults(
    defineProps<{
      label: string;
      value: string;
      class?: string;
    }>(),
    {
      class: ''
    }
  );
</script>

<style lang="scss" scoped>
  .refonte-item-details {
    @apply flex flex-col items-start justify-center;
    gap: 2px;

    .item-details-label {
      @apply text-refonte_blue-300;
      font-size: 11px;
      font-weight: 400;
      line-height: 14px;
    }
    .item-details-value {
      @apply text-refonte-black;
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
    }
  }
</style>
