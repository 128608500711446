<template>
  <template v-if="!loading">
    <refonte-card>
      <refonte-filters-tabs>
        <template v-slot:default>
          <refonte-filters-tabs-item
            label="Commandes confirmées"
            v-bind:selected="
              filters.statutIds &&
              filters.statutIds.join(', ') ===
                COMMANDES_COMFIRMEES_STATUTS_IDS.join(', ')
            "
            v-bind:on_click="
              () => {
                commandeFilterStore.setFilter(
                  COMMANDES_FILTERS_CONFIGURATION.statutIds.name,
                  COMMANDES_COMFIRMEES_STATUTS_IDS
                );
              }
            " />
          <refonte-filters-tabs-item
            label="Commandes annulées"
            v-bind:selected="
              filters.statutIds &&
              filters.statutIds.join(', ') ===
                COMMANDES_ANNULEES_STATUTS_IDS.join(', ')
            "
            v-bind:on_click="
              () => {
                commandeFilterStore.setFilter(
                  COMMANDES_FILTERS_CONFIGURATION.statutIds.name,
                  COMMANDES_ANNULEES_STATUTS_IDS
                );
              }
            " />
        </template>
      </refonte-filters-tabs>
      <commandes-filters />
    </refonte-card>
    <refonte-card class="mt-[8px] !gap-0 !p-0">
      <div
        class="flex flex-wrap items-center gap-[8px] px-[12px] pb-[10px] pt-[12px]">
        <refonte-table-number
          v-bind:number="commandes.length"
          v-bind:label="`${commandes.length > 1 ? 'Commandes' : 'Commande'}${
            filters.statutIds &&
            filters.statutIds.join(', ') ===
              COMMANDES_ANNULEES_STATUTS_IDS.join(', ')
              ? commandes.length > 1
                ? ' annulées'
                : ' annulée'
              : ''
          }`" />
        <IconSeparatorVertical
          v-if="
            filters.statutIds &&
            filters.statutIds.join(', ') ===
              COMMANDES_COMFIRMEES_STATUTS_IDS.join(', ')
          " />
        <refonte-table-number
          v-if="
            filters.statutIds &&
            filters.statutIds.join(', ') ===
              COMMANDES_COMFIRMEES_STATUTS_IDS.join(', ')
          "
          v-bind:number="nombreCommandesARegler"
          v-bind:label="`${
            nombreCommandesARegler > 1 ? 'Commandes' : 'Commande'
          } à régler`"
          v-bind:on_click="
            () => {
              commandeFilterStore.setFilter(
                COMMANDES_FILTERS_CONFIGURATION.commandeReglee.name,
                false
              );
            }
          " />
      </div>
      <refonte-separator class="px-[12px]" />
      <commandes-content v-if="!dataLoading" />
      <template v-else>
        <spinner
          sizes="w-20 h-20"
          thickness="border-8" />
      </template>
    </refonte-card>
  </template>
  <template v-else>
    <spinner
      sizes="w-20 h-20"
      thickness="border-8" />
  </template>
</template>

<script setup lang="ts">
  import RefonteCard from '@/components/refonte/cards/refonte-card.vue';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';
  import { computed, onMounted, ref, watch } from 'vue';
  import Spinner from '@/components/utils/spinner.vue';
  import CommandesContent from '@/views/_espace/_refonte/gestion/_commandes/commandes-content.vue';
  import {
    COMMANDES_ANNULEES_STATUTS_IDS,
    COMMANDES_COMFIRMEES_STATUTS_IDS,
    COMMANDES_FILTERS_CONFIGURATION,
    useCommandeFilterStore,
    useCommandeStore
  } from '@/stores/commandes';
  import { storeToRefs } from 'pinia';
  import { useEntrepriseStore } from '@/stores/entreprise';
  import RefonteTableNumber from '@/components/refonte/numbers/refonte-table-number.vue';
  import { useRoute, useRouter } from 'vue-router';
  import api_utils from '@/mixin/api_utils';
  import CommandesFilters from '@/views/_espace/_refonte/gestion/_commandes/commandes-filters.vue';
  import RefonteFiltersTabs from '@/components/refonte/filters/refonte-filters-tabs.vue';
  import RefonteFiltersTabsItem from '@/components/refonte/filters/refonte-filters-tabs-item.vue';
  import { Commande } from '@/types/gestion/commandes-types';
  import IconSeparatorVertical from '@/assets/img/refonte/icons/display/icon-separator-vertical.svg?component';
  import filters_utils from '@/mixin/utils/filters_utils';
  import { Filters } from '@/types/gestion/inscriptions-filters-types';

  const loading = ref(false);

  const entrepriseStore = useEntrepriseStore();
  const { activeClientIds } = storeToRefs(entrepriseStore);
  const commandeFilterStore = useCommandeFilterStore();

  const { filters } = storeToRefs(commandeFilterStore);

  const dataLoading = ref<boolean>(false);
  const commandeStore = useCommandeStore();
  const { commandes } = storeToRefs(commandeStore);

  const nombreCommandesARegler = computed(
    () =>
      commandes.value.filter(
        (c: Commande) =>
          c.montantTtc !== c.montantRegle &&
          c.reglementStatutSuiviLabel &&
          (c.reglementStatutSuiviLabel === 'Non' ||
            c.reglementStatutSuiviLabel === 'Partiel')
      ).length
  );

  const fetchData = async () => {
    dataLoading.value = true;
    await commandeStore.fetchCommandes(route.query);
    dataLoading.value = false;
  };

  const fetchDefaultData = async () => {
    const query = getQuery(filters.value);
    if (filters_utils.methods.isDeepEqual(query, route.query)) {
      await fetchData();
    }
  };

  const route = useRoute();
  watch(
    () => route.query,
    async () => {
      if (initFiltersCompleted.value) {
        await fetchData();
      }
    }
  );

  const initFiltersCompleted = ref<boolean>(false);
  const initFilters = async () => {
    commandeFilterStore.initFiltersFromQuery(route.query);
    initFiltersCompleted.value = true;
  };

  onMounted(async () => {
    await initFilters();
    await fetchDefaultData();
  });

  const router = useRouter();
  watch(filters, async () => {
    let query = getQuery(filters.value);
    if (activeClientIds.value && activeClientIds.value != '') {
      query = {
        ...query,
        clientIds: activeClientIds.value
      };
    }
    await router.push({ query });
  });
  const getQuery = (filters: Filters) =>
    api_utils.methods.formatParams(filters, [
      COMMANDES_FILTERS_CONFIGURATION.date.name,
      COMMANDES_FILTERS_CONFIGURATION.datePeriodeDebut.name,
      COMMANDES_FILTERS_CONFIGURATION.datePeriodeFin.name,
      COMMANDES_FILTERS_CONFIGURATION.dateFormation.name,
      COMMANDES_FILTERS_CONFIGURATION.dateFormationPeriodeDebut.name,
      COMMANDES_FILTERS_CONFIGURATION.dateFormationPeriodeFin.name
    ]);
</script>

<style lang="scss" scoped></style>
