<template>
  <div class="w-full lg:h-full">
    <spinner
      v-if="loading"
      sizes="w-20 h-20"
      thickness="border-8" />
    <div v-if="!loading">
      <div class="section__description">
        Les commandes ci-dessous correspondent à toutes les commandes réalisées
        passées pour le compte de votre entreprise.
      </div>

      <message-box
        v-bind:source="`<strong class='text-jinius-red uppercase'>Attention&nbsp;:</strong> <span class='pl-1'>Si vous procédez à un paiement global de vos commandes de formation assurez-vous qu’il s’agisse bien d’un seul et même organisme de formation, dans le cas contraire, nous vous remercions de procéder à des virements distincts sur le compte bancaire de chaque organisme.</span>`"
        border_color="w-full border-jinius-red"
        font_style="text-base" />

      <ag-grid-wrapper
        v-bind:column_defs="[
          {
            headerName: 'Référence',
            field: 'reference',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'Formation',
            field: 'formation',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'Date session',
            field: 'dateSession',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'Vendeur',
            field: 'vendeur',
            filter: 'agTextColumnFilter'
          },
          {
            headerName: 'Payeur',
            field: 'payeur',
            filter: 'agTextColumnFilter'
          },
          { headerName: 'Total TTC', field: 'montantTtc' },
          { headerName: 'Confirmée le', field: 'dateCreation' },
          {
            headerName: 'Règlement',
            field: 'reglement',
            cellRendererParams: {
              onclick: (e, reglement) => openReglementModal(e, reglement)
            }
          },
          {
            headerName: 'Bon de commande',
            field: 'bonCommande',
            cellRenderer: 'buttonCellRenderer',
            cellRendererParams: {
              label: 'Télécharger',
              labelDisabled: 'Indisponible',
              onclick: (e, params) => downloadBonCommande(e, params)
            }
          },
          {
            headerName: 'Références Internes',
            field: 'reference',
            cellRenderer: 'buttonCellRenderer',
            cellRendererParams: {
              label: 'Consulter',
              labelDisabled: 'Indisponible',
              onclick: (e, params) => openReferenceInterneModal(e, params)
            }
          },
          { headerName: 'Stage', field: 'familleStage' },
          {
            headerName: 'Formation professionnelle',
            field: 'familleFormationContinue'
          },
          {
            headerName: 'Préparation aux examens',
            field: 'famillePreparationExamen'
          }
        ]"
        v-bind:initial_data="commandes" />
    </div>
    <reglement-modal
      v-if="showReglementModal && reglement"
      v-bind:show_modal="showReglementModal"
      v-bind:reglement="reglement"
      v-bind:onclose="closeReglementModal" />
    <references-internes-modal
      v-if="showReferenceInterneModal"
      v-bind:show_modal="showReferenceInterneModal"
      v-bind:reference_interne_global="refInterneGlobal"
      v-bind:reference_internes_participant="refInternesParticipants"
      v-bind:references_non_modifiable="refInternesNonModifiable"
      v-bind:onsubmit="modifierReferenceInterne"
      v-bind:onclose="closeReferenceInterneModal" />
  </div>
</template>
<script>
  import AgGridWrapper from '@/components/utils/ag-grid/ag-grid-wrapper';
  import messageBox from '@/components/utils/message-box';
  import Spinner from '@/components/utils/spinner.vue';
  import { API } from '@/http-common';
  import ReferencesInternesModal from '@/views/_espace/_entreprise/_commandes/references-internes-modal.vue';
  import ReglementModal from '@/views/_espace/_entreprise/_commandes/reglement-modal';
  import { createNamespacedHelpers } from 'vuex';
  const { mapState: mapStateClient } = createNamespacedHelpers('client');

  export default {
    components: {
      ReferencesInternesModal,
      ReglementModal,
      AgGridWrapper,
      Spinner,
      messageBox
    },
    data: () => ({
      loading: false,
      showReglementModal: false,
      reglement: null,
      showReferenceInterneModal: false,
      refInterneGlobal: null,
      refInternesParticipants: null,
      refInternesNonModifiable: null,
      commandeIdRefAModifier: null,
      commandes: []
    }),
    computed: { ...mapStateClient(['profile']) },
    watch: {
      async ['profile.activeEntrepriseId']() {
        await this.fetchCommandes();
      }
    },
    created() {
      this.fetchCommandes();
    },
    methods: {
      async fetchCommandes() {
        this.loading = true;
        const clientId = this.profile.activeEntrepriseId;

        if (clientId) {
          const response = await API.get(
            `client/${clientId}/commandes`,
            this.$header_skip_redirection
          );

          this.commandes = response.data.map((commande) => ({
            ...commande,
            familleStage: commande.familleStage ? 'Oui' : 'Non',
            familleFormationContinue: commande.familleFormationContinue
              ? 'Oui'
              : 'Non',
            famillePreparationExamen: commande.famillePreparationExamen
              ? 'Oui'
              : 'Non'
          }));
        }

        this.loading = false;
      },
      openReglementModal(e, reglement) {
        this.reglement = reglement;
        this.showReglementModal = true;
      },
      closeReglementModal() {
        this.reglement = null;
        this.showReglementModal = false;
      },
      async openReferenceInterneModal(e, params) {
        const { existePrestationFacture, id } = params.data;

        this.refInternesNonModifiable = existePrestationFacture;
        this.commandeIdRefAModifier = id;
        await this.fetchReferenceInternes();
        this.showReferenceInterneModal = true;
      },
      closeReferenceInterneModal() {
        this.refInterneGlobal = null;
        this.refInternesParticipants = null;
        this.refInternesNonModifiable = null;
        this.commandeIdRefAModifier = null;
        this.showReferenceInterneModal = false;
      },
      async downloadBonCommande(e, params) {
        const { bonCommandeNom, id } = params.data;
        const clientId = this.profile.activeEntrepriseId;
        const response = await API.get(
          `client/${clientId}/commande/${id}/bon/file`,
          {
            responseType: 'blob',
            ...this.$header_skip_redirection
          }
        );
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', bonCommandeNom);
        document.body.appendChild(fileLink);
        fileLink.click();
      },
      async fetchReferenceInternes() {
        const clientId = this.profile.activeEntrepriseId;
        const commandeId = this.commandeIdRefAModifier;

        const referenceInternes = await API.get(
          `client/${clientId}/commande/${commandeId}/reference-interne`
        );
        const { referenceInterneClient, referenceInterneParticipants } =
          referenceInternes.data;

        this.refInterneGlobal = referenceInterneClient;
        this.refInternesParticipants = referenceInterneParticipants;
      },
      async modifierReferenceInterne(referenceInternesModifiees) {
        const clientId = this.profile.activeEntrepriseId;
        const commandeId = this.commandeIdRefAModifier;

        await API.put(
          `client/${clientId}/commande/${commandeId}/reference-interne`,
          referenceInternesModifiees
        );
        this.closeReferenceInterneModal();
      }
    }
  };
</script>
<style lang="scss" scoped></style>
