<template>
  <modal-box
    v-if="modal_show"
    v-bind:width_class="'w-full max-w-screen-sm'"
    v-on:close="close"
    overflow_body
    v-bind:footer="false">
    <template v-slot:modal-header>
      <h1 class="text-xl">Informations de connexion</h1>
    </template>
    <template v-slot:modal-body>
      <div class="flex h-full w-full flex-wrap">
        <div
          class="my-1 flex w-full flex-wrap"
          v-for="(sessionUrl, index) in session_url"
          v-bind:key="`lien-${index}`">
          <div class="mb-2 flex w-full text-lg font-bold">
            {{ sessionUrl.label }}
          </div>

          <div class="mx-4 flex w-full">
            <div
              v-on:click="goToUrl(sessionUrl.url)"
              class="mb-1 inline-flex h-8 cursor-pointer justify-center rounded bg-orange-500 px-4 py-2 text-center font-bold uppercase leading-none text-white shadow-md hover:bg-orange-600">
              <font-awesome-icon icon="external-link-alt" />
              <span class="ml-2 align-middle">Accéder à la session</span>
            </div>
          </div>
          <hr
            class="card__separator"
            v-if="session_url.length > 0 && index < session_url.length - 1" />
        </div>
      </div>
    </template>
  </modal-box>
</template>
<script>
  import modalBox from '@/components/utils/modal-box.vue';
  import http_functions from '@/mixin/http_functions';

  export default {
    components: { modalBox },
    mixins: [http_functions],
    props: {
      modal_show: Boolean,
      session_url: Array
    },
    emits: ['onclose'],
    methods: {
      close() {
        this.$emit('onclose');
      },
      goToUrl(url) {
        window.open(this.formatUrlProtocol(url), '_blank');
      }
    }
  };
</script>
<style lang="scss" scoped>
  :deep(.modal-container) {
    .modal-body {
      @apply p-8;
    }
    .modal-footer {
      @apply p-0;
    }
  }
  :deep(.card__separator) {
    @apply my-2 flex w-full text-core-900 opacity-50;
  }
</style>
