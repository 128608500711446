import { createStore } from 'vuex';
import account from './modules/account';
import auth from './modules/auth';
import catalogue from './modules/catalogue';
import client from './modules/client';
import espace from './modules/espace';
import formateur from './modules/formateur';
import observer from './modules/observer';

const debug = import.meta.env.NODE_ENV !== 'production';

export const store = createStore({
    strict: debug,
    modules: {
        auth,
        observer,
        formateur,
        espace,
        account,
        client,
        catalogue
    }
});
