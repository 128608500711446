import { API } from '@/http-common';
import { ACTION_TYPES } from '@/store/constants/ActionTypes';
import { MUTATION_TYPES } from '@/store/constants/MutationTypes';

const state = { catalogueList: [] };

const mutations = {
    async [MUTATION_TYPES.SET_CATALOGUE_LIST](state, catalogueList) {
        state.catalogueList = [...catalogueList];
    }
};

const actions = {
    [ACTION_TYPES.SET_CATALOGUE_LIST]({ commit }, payload) {
        commit(MUTATION_TYPES.SET_CATALOGUE_LIST, payload);
    },
    async [ACTION_TYPES.GET_PARTICIPANT_CATALOGUE]({ commit }, payload) {
        try {
            const response = await API.get(
                'participant/catalogues',
                this.$header_skip_redirection
            );

            if (response && response.data) {
                commit(MUTATION_TYPES.SET_CATALOGUE_LIST, response.data);
            }
        } catch (e) {
            return false;
        }
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
