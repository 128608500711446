<template>
  <default-renderer
    v-bind:params="formatParams(params)"
    dates_field />
</template>
<script>
  import { convertStringToDate } from '@/assets/data/ag_grid_fields_map';
  import defaultRenderer from '@/components/utils/ag-grid/default-renderer';

  export default {
    components: { defaultRenderer },
    methods: {
      formatParams(params) {
        if (params.value && params.value.length) {
          return {
            ...params,
            value: `${params.data?.organisateurOutreMer ? '*' : ''} ${this.formatDates(params.value)}`
          };
        } else {
          return { typeId: params.data?.typeId };
        }
      },
      formatDates(sessionDates) {
        sessionDates.sort((a, b) =>
          a.date && b.date
            ? convertStringToDate(a.date).getTime() -
              convertStringToDate(b.date).getTime()
            : false
        );
        if (!sessionDates) {
          return '';
        }
        if (sessionDates && Array.isArray(sessionDates)) {
          return sessionDates
            .filter((s) => s.date)
            .map((value) => {
              const { date, matin, aprem } = value;

              return `${date}${matin && !aprem ? ' (matin)' : ''}${!matin && aprem ? ' (après-midi)' : ''}`;
            })
            .join(', ');
        } else {
          return sessionDates;
        }
      }
    }
  };
</script>
