<template>
  <div
    class="refonte-details-header-etablissement"
    v-bind:class="props.class">
    <div class="details-header-etablissement-top">
      <div class="details-header-etablissement-top-title">
        <refonte-tag
          v-bind:variant="props.deactivated ? 'deactivated' : 'active'">
          {{ props.deactivated ? 'Désactivé' : 'Actif' }}
        </refonte-tag>
        <h1>
          {{ props.title }}
        </h1>
      </div>
      <div class="details-header-etablissement-top-action">
        <refonte-button
          v-bind:variant="props.deactivated ? 'success' : 'danger'"
          v-bind:on_click="
            () => {
              if (!props.deactivated) {
                setIsDesactiverEtablissementModalOpen(true);
              }
            }
          ">
          {{
            props.deactivated
              ? 'Réactiver l’établissement'
              : 'Désactiver l’établissement'
          }}
        </refonte-button>
      </div>
    </div>
    <div
      class="details-header-etablissement-bottom"
      v-if="props.reference">
      <div
        v-if="props.reference"
        class="details-header-etablissement-bottom-reference">
        Entreprise - Référence {{ props.reference }}
      </div>
    </div>
  </div>
  <refonte-modal-desactiver-etablissement
    v-bind:show="isDesactiverEtablissementModalOpen"
    v-bind:on_close="() => setIsDesactiverEtablissementModalOpen(false)"
    v-bind:on_submit="desactiverEtablissement" />
</template>

<script setup lang="ts">
  import RefonteTag from '@/components/refonte/tags/refonte-tag.vue';
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import RefonteModalDesactiverEtablissement from '@/components/refonte/modals/refonte-modal-desactiver-etablissement.vue';
  import { ref } from 'vue';

  const props = withDefaults(
    defineProps<{
      title: string;
      reference?: string;
      deactivated?: boolean;
      class?: string;
    }>(),
    {
      deactivated: false,
      class: ''
    }
  );

  const isDesactiverEtablissementModalOpen = ref<boolean>(false);

  function setIsDesactiverEtablissementModalOpen(value: boolean) {
    isDesactiverEtablissementModalOpen.value = value;
  }
  function desactiverEtablissement(motif: string) {}
</script>

<style lang="scss" scoped>
  .refonte-details-header-etablissement {
    @apply flex flex-col items-start self-stretch;
    gap: 8px;

    .details-header-etablissement-top {
      @apply flex items-center self-stretch;
      gap: 8px;

      .details-header-etablissement-top-title {
        @apply flex flex-col items-start justify-center self-stretch;
        flex: 1 0 0;
        gap: 2px;
        padding: 0 12px;
      }
      .details-header-etablissement-top-action {
      }
    }
    .details-header-etablissement-bottom {
      @apply self-stretch;
      padding: 0 4px;

      .details-header-etablissement-bottom-reference {
        padding: 10px;
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
      }
    }
  }
</style>
