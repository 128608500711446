<template>
  <div
    class="refonte-dropdown-item"
    v-bind:class="props.class"
    v-on:click="props.on_click">
    <div
      v-if="props.icon"
      class="dropdown-item-icon">
      <component v-bind:is="props.icon" />
    </div>
    <span class="dropdown-item-text">
      <slot></slot>
    </span>
    <refonte-number
      v-if="props.number"
      variant="filters"
      class="dropdown-item-number">
      {{ props.number }}
    </refonte-number>
  </div>
</template>

<script setup lang="ts">
  import RefonteNumber from '@/components/refonte/numbers/refonte-number.vue';

  const props = withDefaults(
    defineProps<{
      on_click?: Function;
      number?: number;
      icon?: Element;
      class?: string;
    }>(),
    {
      class: ''
    }
  );
</script>

<style lang="scss" scoped>
  .refonte-dropdown-item {
    @apply flex cursor-pointer items-center self-stretch bg-transparent text-refonte_blue-600;
    gap: 2px;
    border-radius: 4px;
    padding: 8px 8px 8px 6px;

    .dropdown-item-icon {
      @apply flex shrink-0 items-center justify-center;
      width: 16px;
      height: 16px;

      :deep(svg) {
        @apply text-refonte_blue-600;
        width: 16px;
        height: 16px;
      }
    }
    .dropdown-item-text {
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
      margin-left: 2px;
    }

    .dropdown-item-number {
      margin-left: 4px;
    }

    &:hover {
      @apply bg-refonte-pale-violet-3;
    }
  }
</style>
