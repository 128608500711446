<template>
  <div
    class="refonte-pastille-icon"
    v-bind:class="props.class">
    <IconPastilleActive v-if="props.status === 'active'" />
    <IconPastilleWarning v-else-if="props.status === 'warning'" />
    <IconPastilleCanceled v-else-if="props.status === 'canceled'" />
    <IconPastilleUrgent v-else-if="props.status === 'urgent'" />
    <IconPastilleDeactivated v-else-if="props.status === 'deactivated'" />
    <IconPastillePending v-else-if="props.status === 'pending'" />
    <IconPastilleRealised v-else-if="props.status === 'realised'" />
    <IconPastilleEntreprise v-else-if="props.status === 'entreprise'" />
    <IconPastilleParticipant v-else-if="props.status === 'participant'" />
    <IconPastilleFormateur v-else-if="props.status === 'formateur'" />
    <IconPastilleCursus v-else-if="props.status === 'cursus'" />
    <IconPastilleJiniusGreen
      v-else-if="props.status === 'jinius-red'"
      class="pastille-icon-jinius-red" />
    <IconPastilleJiniusGreen
      v-else-if="props.status === 'jinius-blue'"
      class="pastille-icon-jinius-blue" />
    <IconPastilleJiniusGreen v-else-if="props.status === 'jinius-green'" />
    <IconPastilleJiniusGreen
      v-else-if="props.status === 'jinius-purple'"
      class="pastille-icon-jinius-purple" />
    <IconPastilleJiniusGreen
      v-else-if="props.status === 'jinius-rose'"
      class="pastille-icon-jinius-rose" />
    <IconPastilleJiniusGreen
      v-else-if="props.status === 'jinius-yellow'"
      class="pastille-icon-jinius-yellow" />
    <IconPastilleJiniusGreen
      v-else-if="props.status === 'jinius-pink'"
      class="pastille-icon-jinius-pink" />
  </div>
</template>

<script setup lang="ts">
  import IconPastilleActive from '@/assets/img/refonte/icons/states/icon-pastille-active.svg?component';
  import IconPastilleWarning from '@/assets/img/refonte/icons/states/icon-pastille-warning.svg?component';
  import IconPastilleCanceled from '@/assets/img/refonte/icons/states/icon-pastille-canceled.svg?component';
  import IconPastilleUrgent from '@/assets/img/refonte/icons/states/icon-pastille-urgent.svg?component';
  import IconPastilleDeactivated from '@/assets/img/refonte/icons/states/icon-pastille-deactivated.svg?component';
  import IconPastillePending from '@/assets/img/refonte/icons/states/icon-pastille-pending.svg?component';
  import IconPastilleRealised from '@/assets/img/refonte/icons/states/icon-pastille-realised.svg?component';
  import IconPastilleEntreprise from '@/assets/img/refonte/icons/states/icon-pastille-entreprise.svg?component';
  import IconPastilleParticipant from '@/assets/img/refonte/icons/states/icon-pastille-participant.svg?component';
  import IconPastilleFormateur from '@/assets/img/refonte/icons/states/icon-pastille-formateur.svg?component';
  import IconPastilleCursus from '@/assets/img/refonte/icons/states/icon-pastille-cursus.svg?component';
  import IconPastilleJiniusGreen from '@/assets/img/refonte/icons/states/icon-pastille-jinius-green.svg?component';

  const props = withDefaults(
    defineProps<{
      status: string;
      class?: string;
    }>(),
    {
      class: ''
    }
  );
</script>

<style lang="scss" scoped>
  .refonte-pastille-icon {
    @apply flex shrink-0 items-center justify-center;
    width: 8px;
    height: 8px;

    .pastille-icon-jinius-red {
      color: #ea444c;
    }
    .pastille-icon-jinius-blue {
      color: #32537a;
    }
    .pastille-icon-jinius-purple {
      color: #7030a0;
    }
    .pastille-icon-jinius-rose {
      color: #3730a3;
    }
    .pastille-icon-jinius-yellow {
      color: #ffc000;
    }
    .pastille-icon-jinius-pink {
      color: #db2777;
    }
  }
</style>
