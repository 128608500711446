<template>
  <div class="mx-auto flex h-full flex-col overflow-hidden rounded p-4">
    <div class="flex h-full w-full flex-col items-center justify-center">
      <div
        class="w-full max-w-screen-sm rounded bg-white px-12 py-10 shadow-md">
        <h1 class="mb-4 flex text-4xl font-bold text-core-700 md:text-6xl">
          Erreur Serveur
        </h1>
        <h2
          class="mb-10 flex flex-wrap text-2xl leading-none text-accent-500 md:text-4xl">
          Une erreur {{ error_number }} est survenue...
        </h2>

        <p class="mb-6 font-bold">Veuillez ré-essayer ultérieurement.</p>
        <div class="border-l-4 border-primary-900 pl-3">
          <p>Si toutefois le problème persiste, contactez-nous directement :</p>
          <ul class="mt-1 list-inside list-disc pl-3">
            <li>
              à l’adresse e-mail suivante :
              <a
                href="mailto:assistance@jinius.fr"
                class="font-bold text-primary-800 hover:underline"
                >assistance@jinius.fr</a
              >
            </li>
            <li>
              ou par téléphone au
              <span class="font-bold text-primary-800">0825 826 116</span>
              (Numéro Indigo - 0.15€ TTC la minute)
            </li>
          </ul>
        </div>

        <router-link
          v-bind:to="
            hasOrigin ? { path: route_origin.fullPath } : { path: '/' }
          "
          class="mt-8 flex cursor-pointer font-bold text-secondary-600 hover:underline">
          <font-awesome-icon
            icon="chevron-circle-left"
            size="sm"
            class="mt-1.5" />
          <span class="mx-2">
            {{
              hasOrigin
                ? 'Revenir à la page précédente'
                : "Retour à la page d'accueil"
            }}
          </span>
        </router-link>
      </div>
    </div>

    <div class="mt-4 flex self-end text-center">
      <code>Version&nbsp;:&nbsp;{{ version }}</code>
    </div>
  </div>
</template>

<script>
  export default {
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.route_origin = from;
      });
    },
    props: {
      error_number: {
        type: [String, Number],
        default: 'inconnue'
      }
    },
    data() {
      return { route_origin: null };
    },
    computed: {
      version() {
        return import.meta.env.VITE_VERSION;
      },
      hasOrigin() {
        return this.route_origin && this.route_origin.fullPath !== '/';
      }
    }
  };
</script>
