<template>
  <div>
    <modal-box
      v-if="show_modal"
      v-bind:width_class="'w-full max-w-screen-md'"
      is_survey
      survey_label="e-learning"
      survey_color="blue-600"
      overflow_body
      v-bind:footer="false"
      v-on:close-survey="close"
      v-on:close="close">
      <template v-slot:modal-header>
        <h1 class="px-6 text-3xl font-bold text-core-600">
          {{ titre }}
        </h1>
      </template>

      <template v-slot:modal-body>
        <div v-if="loading">
          <spinner
            sizes="w-20 h-20"
            thickness="border-8" />
        </div>
        <div
          v-else
          class="mb-4 flex w-full flex-wrap lg:px-6">
          <div
            class="mb-4 flex w-full border-l-1 pl-3 text-lg leading-none text-accent-500"
            v-if="module_elearning.dateDebutAcces && plateforme_duree_acces">
            {{
              `Accessible pendant ${plateforme_duree_acces} jour${plateforme_duree_acces > 1 ? 's' : ''} ${!module_elearning.showRetractation ? `à compter du ${module_elearning.dateDebutAcces} et jusqu'au ${module_elearning.dateFinAcces}` : ''}`
            }}
          </div>
          <message-box
            class="w-full text-justify font-bold"
            v-if="autoriser_question && questions.length < 2"
            v-bind:source="`
                       <p class='mb-1.5'>Pendant la période de validité de la formation, vous pouvez poser jusqu’à 2 questions sur le contenu du module e-learning. Les réponses apportées seront visibles dans votre espace participant.</p>
                       <p>Attention la question ne doit pas être une consultation sur un problème rencontré dans un dossier client.</p>
                       `" />

          <div
            class="mb-4 flex w-full flex-wrap"
            v-if="autoriser_question && questions.length < 2">
            <div class="mb-2 flex w-full text-lg">
              <textarea-box
                label=""
                class_name="no-box"
                v-bind:maxlength="1000"
                name="question"
                placeholder="Saisir votre question..."
                v-bind:value="question"
                v-on:input="setQuestion"
                autogrow />
            </div>
            <div class="flex w-full justify-between">
              <div class="flex items-center">
                {{ questions.length }}/2 questions
              </div>

              <button
                class="w-56 rounded bg-blue-600 px-4 py-3 font-bold text-white hover:bg-blue-500"
                v-on:click="sendQuestion">
                Enregistrer
              </button>
            </div>
          </div>
          <hr
            class="my-2 mb-4 flex w-full text-lg text-core-900 opacity-50"
            v-if="autoriser_question && questions.length == 1" />
          <div
            class="my-4 flex w-full flex-wrap rounded-lg border border-accent-300 bg-accent-100 px-6 shadow-lg"
            v-if="questions.length">
            <div
              class="flex w-full flex-wrap py-4"
              v-for="(q, index) in questions"
              v-bind:key="q.id">
              <div class="flex w-full flex-wrap space-y-1 py-2">
                <div class="flex w-full font-bold">
                  Question {{ index + 1 }}
                </div>
                <div class="flex w-full pl-2">{{ q.question }}</div>
              </div>
              <div
                v-if="q.reponse"
                class="flex w-full flex-wrap space-y-1 py-2">
                <div class="flex w-full font-bold">Réponse {{ index + 1 }}</div>
                <div class="flex w-full pl-2">{{ q.reponse }}</div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </modal-box>
  </div>
</template>
<script>
  import messageBox from '@/components/utils/message-box';
  import modalBox from '@/components/utils/modal-box.vue';
  import Spinner from '@/components/utils/spinner.vue';
  import textareaBox from '@/components/utils/textarea-box';
  import { API } from '@/http-common';

  export default {
    components: {
      modalBox,
      Spinner,
      textareaBox,
      messageBox
    },
    props: {
      show_modal: { type: Boolean },
      titre: { type: String },
      onclose: { type: Function },
      module_elearning: Object,
      questions: Array,
      autoriser_question: Boolean,
      plateforme_duree_acces: Number,
      participant_id: Number,
      refresh_session: Function
    },
    data: () => ({
      question: '',
      loading: false
    }),

    methods: {
      setQuestion(value) {
        this.question = value;
      },
      close() {
        this.onclose();
      },
      async sendQuestion() {
        this.loading = true;
        await API.post(
          `participant/elearning/${this.participant_id}/question`,
          { question: this.question },
          this.$header_skip_redirection
        );
        await this.refresh_session();
        this.setQuestion('');
        this.loading = false;
      }
    }
  };
</script>
<style lang="scss" scoped></style>
