<template>
  <div
    class="refonte-filters-tabs-item"
    v-bind:class="`${props.selected ? 'filters-tabs-item-selected' : ''} ${props.disabled ? 'filters-tabs-item-disabled' : ''} ${props.class}`"
    v-on:click="() => !props.disabled && props.on_click && props.on_click()">
    <refonte-number
      v-if="props.number && !props.number_right"
      variant="segment"
      class="filters-tabs-item-number">
      {{ props.number }}
    </refonte-number>
    <span class="filters-tabs-item-text">
      {{ props.label }}
    </span>
    <refonte-number
      v-if="props.number && props.number_right"
      variant="filters"
      class="filters-tabs-item-number">
      {{ props.number }}
    </refonte-number>
  </div>
</template>

<script setup lang="ts">
  import RefonteNumber from '@/components/refonte/numbers/refonte-number.vue';

  const props = withDefaults(
    defineProps<{
      selected?: boolean;
      disabled?: boolean;
      label: string;
      number?: number;
      number_right?: boolean;
      on_click?: Function;
      class?: string;
    }>(),
    {
      selected: false,
      disabled: false,
      number_right: false,
      class: ''
    }
  );
</script>

<style lang="scss" scoped>
  .refonte-filters-tabs-item {
    @apply inline-flex shrink-0 cursor-pointer items-center justify-center border border-refonte_blue-200 bg-refonte-white text-refonte_blue-800;
    gap: 6px;
    border-radius: 8px;
    padding: 9px 11px;

    .filters-tabs-item-text {
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
    }
    .filters-tabs-item-number {
      height: 16px;
      line-height: 16px;
      padding: 0;

      :deep(span) {
        font-size: 14px;
        line-height: 18px;
      }
    }

    &:hover,
    &:active,
    &:focus {
      @apply bg-refonte-pale-violet-3;
    }

    &.filters-tabs-item-selected {
      @apply border-refonte_blue-400 bg-refonte_blue-400 text-refonte-white;

      &:hover,
      &:active,
      &:focus {
        @apply border-refonte_blue-400 bg-refonte_blue-400;
      }
    }
    &.filters-tabs-item-disabled {
      @apply cursor-not-allowed border-refonte_blue-100 bg-refonte-white text-refonte_blue-100;

      &.filters-tabs-item-selected {
        @apply cursor-not-allowed border-refonte_blue-100 bg-refonte_blue-100;
      }
    }
  }
</style>
