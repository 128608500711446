<template>
  <default-renderer
    v-bind:params="params"
    v-bind:clickable="
      !!params.data?.catalogueUrl && !!params.data?.catalogueReference
    " />
</template>
<script>
  import defaultRenderer from '@/components/utils/ag-grid/default-renderer';

  export default { components: { defaultRenderer } };
</script>
