<template>
  <select
    v-model="value"
    class="select-block"
    v-on:change="updateValue">
    <template
      v-for="item in options"
      v-bind:key="item.value">
      <option v-bind:value="item.value">{{ item.label }}</option>
    </template>
  </select>
</template>

<script>
  export default {
    props: ['initial_value', 'options'],
    emits: ['select'],
    data() {
      return { value: this.initial_value };
    },
    methods: {
      updateValue(e) {
        this.$emit('select', this.value);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .select-block {
    @apply pointer-events-auto flex w-full cursor-pointer appearance-none rounded border-b-2 border-core-300 bg-white px-4 py-3 text-center text-core-700;
    &:hover {
      @apply bg-core-300;
    }

    &:active {
      @apply border-b-2 border-core-700 outline-none;
    }
  }
</style>
