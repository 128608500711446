<template>
  <div
    class="refonte-item-all-entities"
    v-on:click="props.on_click"
    v-bind:class="props.class">
    <div class="item-all-entities-content">
      <div class="item-all-entities-content-title">{{ props.title }}</div>
      <div
        v-if="props.admin"
        class="item-all-entities-content-rights">
        <div class="item-all-entities-content-rights-icon">
          <IconUser />
        </div>
        <div class="item-all-entities-content-rights-text">Administration</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import IconUser from '@/assets/img/refonte/icons/menu/icon-user.svg?component';

  const props = withDefaults(
    defineProps<{
      title?: string;
      on_click?: Function;
      admin?: boolean;
      class?: string;
    }>(),
    {
      title: 'Tous les établissements',
      admin: false,
      class: ''
    }
  );
</script>

<style lang="scss" scoped>
  .refonte-item-all-entities {
    @apply flex flex-grow cursor-pointer items-center self-stretch;
    border-radius: 8px;
    padding: 8px;

    &:hover {
      @apply bg-refonte-pale-violet-2;
    }

    .item-all-entities-content {
      @apply flex flex-grow flex-col items-start;
      gap: 2px;
      padding: 0 10px;

      .item-all-entities-content-title {
        @apply self-stretch overflow-hidden text-ellipsis text-refonte-black;
        font-size: 13px;
        font-weight: 400;
        line-height: 125%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }

      .item-all-entities-content-rights {
        @apply flex items-center self-stretch;
        gap: 2px;

        .item-all-entities-content-rights-icon {
          @apply flex items-center justify-center;
          width: 16px;
          height: 16px;

          :deep(svg) {
            @apply text-refonte_blue-600;
            width: 16px;
            height: 16px;
          }
        }
        .item-all-entities-content-rights-text {
          @apply flex-grow text-refonte_blue-600;
          font-size: 12px;
          font-weight: 400;
          line-height: 120%;
        }
      }
    }

    &.no-ellipsis {
      .item-all-entities-content {
        .item-all-entities-content-title {
          @apply self-stretch text-refonte-black;
          display: block;
          overflow: unset;
          text-overflow: unset;
        }
      }
    }
  }
  @media (max-width: 767.98px) {
    .item-all-entities-content {
      .item-all-entities-content-title {
        font-size: 12px;
        font-weight: 500;
        line-height: 120%;
      }
      .item-all-entities-content-rights {
        .item-all-entities-content-rights-text {
          font-size: 11px;
          font-weight: 400;
          line-height: 125%;
        }
      }
    }
  }
</style>
